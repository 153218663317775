import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

function GeneralArchieveModal(props) {
  return (
    <div>
      <Dialog
        className="cstm_dialog_modal small text-center"
        open={props.open}
        // onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={props.handleClose}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder">
              <i className="ti ti-exclamation-mark" />
            </div>
            <h2 className="heading">{props.title}</h2>
            <p className="sub_content">{props.text}</p>
          </div>
          {/* <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText> */}
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={props.handleClose} className="secondary_btn" >No, go back!</Button>
          <Button onClick={() => props.handleConfirm()} className="primary_btn">
            {props.actionButtonName ? props.actionButtonName : 'Yes, Proceed!'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default GeneralArchieveModal