import useToast from '@fuse/hooks/useToast'
import {
	Paper,
	makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, Button, Icon, IconButton, TextField, Typography, Drawer, Grid, InputAdornment, MenuItem } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import { Autocomplete } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from 'helper/alertModal'
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import { formatPhoneNumber } from '@fuse/utils/formatPhoneNumber'
import { countryCodes } from '../../super-admin/dashboard/components/constant.js'
const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: '"Inter", sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > span': {
				margin: '10px auto'
			},
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px',
			marginRight: '20px',
			marginBottom: '20px',
			padding: '10px',
			backgroundColor: '#F9FAFB',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		drawerWidth: {
			width: 520,
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			overflowX: 'hidden',
			overflowY: 'hidden'
		},
	}
))

function AddAgentForm(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const [triggerComponent, setTriggerComponent] = useState(new Date())
	const [countryCode, setCountryCode] = useState('+1');
	const openState = useSelector((state) => state.AgentApp.formViewDialog)
	const [loading, setLoading] = useState(false)
	const { data: editFromState } = useSelector((state) => state.AgentApp.formViewDialog);
	const [openAlerModal, setOpenAlertModal] = useState(false)
	const levelOptions = ['L-1', 'L-2', 'L-3'];
	const statusOptions = ['Active', 'Inactive'];
	const roleOptions = [{label: 'Agent', value: 'agent'}, {label: 'Admin', value: 'agent-admin'}]

	const defaultValues = {
		firstName: '',
		lastName: '',
		email: '',
		level: null,
		status: 'Inactive',
		role: null,
		phoneNumber: ''
	};

	const validationSchema =  yup.object({
		firstName: yup.string().trim()
			.required('First name is required!')
			.matches(/^[A-Za-z\s]+$/, 'First name cannot contain numbers, alphanumeric characters, or special characters!')
			.max(30, 'First name must be at most 30 characters!'),
		lastName: yup.string().trim()
			.required('Last name is required!')
			.matches(/^[A-Za-z\s]+$/, 'First name cannot contain numbers, alphanumeric characters, or special characters!')
			.max(30, 'First name must be at most 30 characters!'),
		email: yup.string().trim().email().required("Email is required!"),
		level: yup.string().trim().required("Level is required!"),
		status: yup.string().trim().required("Status is required!"),
		role: yup.object().required("Role is required!"),
		phoneNumber: yup.string().trim()
			.required("Phone number is required!")
			.matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format')
	})

	const { control, formState, handleSubmit, reset, getValues, setValue } = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(validationSchema),
		mode: "onChange"
	});

	const { errors } = formState

	const onSubmit = async(data) => {
		let url = `/agent-management/add`
		if( editFromState ) url = `/agent-management/edit/${editFromState.id}`
		axios.post(url, {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			phoneNumber: `${countryCode} ${data.phoneNumber}`,
			level: data.level,
			status: data.status,
			type: data.role.value
		}).then((res)=>{
			if(res.data.statusCode === 201){
				toast.error(res.data.message)
				return
			}
			toast.success(res.data.message)
			props.getAllAgents()
			onCancel()
		}).catch((err)=>{
			console.log(err)
			toast.error(err.response.data.message)
		})
	}


	function changeNumberFormat(e){
		return formatPhoneNumber(e.target.value)
	}

	const handleAlertModal = (_, reason) => {
		if (reason === 'backdropClick') {
			return;
		}
		setOpenAlertModal(!openAlerModal)
	}
	const handleCloseAlertModal = () => {
		setOpenAlertModal(false)
		onCancel()
	}

	const onCancel = () => {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData())
	}

	useEffect(() => {
		if(!editFromState) return
		setValue('firstName', editFromState.first_name)
		setValue('lastName', editFromState.last_name)
		setValue('email', editFromState.email_address)
		setValue('phoneNumber', editFromState.phone_number?.split(' ')[1] ?? '')
		setCountryCode(editFromState.phone_number?.split(' ')[0] ?? '+1')
		setValue('level', editFromState.level ?? '')
		setValue('status', editFromState.status ?? '')
		if(editFromState.role.toLowerCase() === 'agent'){
			setValue('role', {label: 'Agent', value: 'agent'})
		}else{
			setValue('role', {label: 'Admin', value: 'agent-admin'})
		}
	}, [editFromState])

	useEffect(() => {
		
		const handleBeforeUnload = (event) => {
		  getValues('client') && event.preventDefault();
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};

	}, []);

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>{editFromState ? (editFromState?.cloneItem ? 'Clone ' : 'Update ') : 'Add '} Agent</h4>
					<IconButton onClick={handleAlertModal} style={{ padding: '0px' }}>
						<Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
					</IconButton>
				</div>

				<div className='progressbar_holder'>
					<div className='progress'>
						&nbsp;
					</div>
				</div>

				<div className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder mt-5'>
							<>
								<div style={{paddingTop: '20px'}} className='content_holder'>
									<div className='box_holder'>
										<Box>
											<Grid container spacing={2}>
												<Grid item xs={6}>
													<Box>
														<Typography className='status_text'>
															First Name
															<span className='required_span'>*</span>
														</Typography>
														<Controller
															name='firstName' 
															control={control}
															render={( { field} ) => (
																<TextField 
																	{...field}
																	style={{background: 'white'}}
																	variant='outlined'
																	autoFocus
																	fullWidth   
																	required                     
																	error={!!errors.firstName}
																	helperText={errors?.firstName?.message}
																	FormHelperTextProps={{
																		style: {
																			color: '#f44336',																	
																			margin : 0, 
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																/>
															)}
														/>
													</Box>
												</Grid>
												<Grid item xs={6}>
													<Box>
														<Typography className='status_text'>
															Last Name
															<span className='required_span'>*</span>
														</Typography>
														<Controller
															name='lastName' 
															control={control}
															render={( { field} ) => (
																<TextField 
																	{...field}
																	style={{background: 'white'}}
																	variant='outlined'
																	autoFocus
																	fullWidth   
																	required                     
																	error={!!errors.lastName}
																	helperText={errors?.lastName?.message}
																	FormHelperTextProps={{
																		style: {
																			color: '#f44336',
																			margin : 0, 
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																/>
															)}
														/>
													</Box>
												</Grid>
												<Grid item xs={12}>
													<Box>
														<Typography className='status_text'>
															Email Address
															<span className='required_span'>*</span>
														</Typography>
														<Controller
															name='email' 
															control={control}
															render={( { field} ) => (
																<TextField 
																	{...field}
																	style={{background: 'white'}}
																	variant='outlined'
																	autoFocus
																	fullWidth   
																	required
																	disabled={!!editFromState}                  
																	error={!!errors.email}
																	helperText={errors?.email?.message}
																	FormHelperTextProps={{
																		style: {
																			color: '#f44336',
																			margin : 0, 
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																/>
															)}
														/>
													</Box>
												</Grid>
												<Grid item xs={12}>
													<Box>
														<Typography className='status_text'>
															Phone Number
															<span className='required_span'>*</span>
														</Typography>
														<Controller
															name='phoneNumber' 
															control={control}
															render={( { field} ) => (
																<TextField
																	{ ...field }
																	style={{ background: '#FCFCFD' }}
																	variant="outlined"
																	fullWidth
																	onKeyPress={(event) => {
																		if (!/[0-9/-]+/.test(event.key)) {
																			event.preventDefault();
																		}
																	}}
																	required                     
																	error={!!errors.phoneNumber}
																	helperText={errors?.phoneNumber?.message}
																	FormHelperTextProps={{
																		style: {
																			color: '#f44336',
																			margin : 0, 
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	onChange={(e)=>{
																		field.onChange(changeNumberFormat(e))
																	}}
																	InputProps={{
																		startAdornment: (
																		<InputAdornment position="start">
																			<TextField
																				select
																				className='textfield_select_holder'
																				value={countryCode}
																				onChange={(e) => setCountryCode(e.target.value)}
																				variant="standard"
																				InputProps={{ disableUnderline: true }}
																				SelectProps={{
																					MenuProps: {
																						PaperProps: {
																						style: {
																							maxHeight: 200,
																						},
																						},
																					},
																				}}
																			>
																			{countryCodes.map((option, index) => (
																				<MenuItem key={index} value={option.code}>
																					{option.code}
																				</MenuItem>
																			))}
																			</TextField>
																		</InputAdornment>
																		),
																	}}
																/>								
															)}
														/>
													</Box>
												</Grid>
												<Grid item xs={12}>
													<Box>
														<Typography className='status_text'>Level<span className='required_span'>*</span></Typography>														
															<Controller
																name='level'
																control={control}
																render={({ field: { ref, ...field }, fieldState: { error } }) => (
																	<Autocomplete
																		{...field} 
																		style={{ width: '100%' }}
																		className='main_autocomplete'
																		options={levelOptions}
																		// disablePortal
																		onChange={(event, value) => field.onChange(value)}									
																		getOptionLabel={option => option ?? ''}
																		PaperComponent={({ children }) => (
																			<Paper className="autocomplete_holder extra_styling">{children}</Paper>
																		)}
																		renderInput={(params) => {
																			return (
																				<div ref={params.InputProps.ref}>
																					<TextField
																						{...field}
																						name="level"
																						style={{background: 'white', borderColor: 'red'}}
																						{...params}
																						required
																						error={!!error}
																						helperText={error?.message && "Level is required!"}
																						FormHelperTextProps={{
																							style: {
																								color: '#f44336',
																								margin : 0, 
																								backgroundColor: '#f4f4f4',
																								width: '100%',
																								paddingTop: '2px'
																							}
																						}}
																						variant="outlined"
																						fullWidth
																					/>
																				</div>
																			)
																		}}
																	/>
																)}
															/>
													</Box>
												</Grid>
												<Grid item xs={12}>
													<Box>
														<Typography className='status_text'>Status<span className='required_span'>*</span></Typography>
															<Controller
																name='status'
																control={control}
																render={({ field: { ref, ...field }, fieldState: { error } }) => (
																	<Autocomplete
																		{...field} 
																		style={{ width: '100%' }}
																		className='main_autocomplete'
																		options={statusOptions}
																		disabled={!editFromState || editFromState?.cloneItem}
																		// disablePortal
																		onChange={(event, value) => field.onChange(value)}									
																		getOptionLabel={option => option ?? ''}
																		PaperComponent={({ children }) => (
																			<Paper className="autocomplete_holder extra_styling">{children}</Paper>
																		)}
																		renderInput={(params) => {
																			return (
																				<div ref={params.InputProps.ref}>
																					<TextField
																						name="status"
																						style={{background: 'white'}}
																						{...params}
																						required
																						error={!!error}
																						helperText={error?.message && "Status is required!"}
																						FormHelperTextProps={{
																							style: {
																								color: '#f44336',
																								margin : 0, 
																								backgroundColor: '#f4f4f4',
																								width: '100%',
																								paddingTop: '2px'
																							}
																						}}
																						variant="outlined"
																						fullWidth
																					/>
																				</div>
																			)
																		}}
																	/>
																)}
															/>
													</Box>
												</Grid>
												<Grid item xs={12}>
													<Box>
														<Typography className='status_text'>Role<span className='required_span'>*</span></Typography>
															<Controller
																name='role'
																control={control}
																render={({ field: { ref, ...field }, fieldState: { error } }) => (
																	<Autocomplete
																		{...field} 
																		style={{ width: '100%' }}
																		className='main_autocomplete'
																		options={roleOptions}
																		// disablePortal
																		onChange={(event, value) => {field.onChange(value)}}									
																		getOptionLabel={option => option.label ?? ''}
																		PaperComponent={({ children }) => (
																			<Paper className="autocomplete_holder extra_styling">{children}</Paper>
																		)}
																		renderInput={(params) => {
																			return (
																				<div ref={params.InputProps.ref}>
																					<TextField
																						name="role"
																						style={{background: 'white'}}
																						{...params}
																						required
																						error={!!error}
																						helperText={error?.message && "Role is required!"}
																						FormHelperTextProps={{
																							style: {
																								color: '#f44336',
																								margin : 0, 
																								backgroundColor: '#f4f4f4',
																								width: '100%',
																								paddingTop: '2px'
																							}
																						}}
																						variant="outlined"
																						fullWidth
																					/>
																				</div>
																			)
																		}}
																	/>
																)}
															/>
													</Box>
												</Grid>
											</Grid>
										</Box>
									</div>
								</div>
								<div className='btn_holder'>
									<Button onClick={handleSubmit(onSubmit)} className='next_btn'>
										Proceed
									</Button>
									
								</div>
							</>
						</div>
					</Box>

				</div>
			</Drawer>

			{openAlerModal &&
				<AlertModal openAlerModal module="agent" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal} />
			}

		</div >
	)
}
export default AddAgentForm
