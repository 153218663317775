import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import React,{useState} from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import ColorPicker from '@fuse/components/ColorPicker'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function SubscriptionStatusDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [color, setColor] = useState({name:'black',code:"#000000"});

    const schema = yup.object({
        // status_name: yup.string().required('Status is required!'),
        // status_description: yup.string().required('Description is required!')
    })

    const defaultValues = {
        status_name: '',
        status_description: ''
    }

    const { 
        control, 
        formState:{errors: statusFormErrors}, 
        handleSubmit: statusFormSubmit, 
        reset: statusFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function manageStatusData(formData){
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            status_name: formData.status_name,
            type:'software',
            status_description: formData.status_description || '',
            status_color: color
        }

        axios.post('/asset-status/add', payload).then(response => {
            if(response.status == 201){
                toast.error(response.data.message)
            } else {
                statusFormReset(defaultValues)
                setColor({name:'black',code:"#000000"})
                // response.data.data.new_hardware_asset_status

                //Send newly added status value
                props.handleCloseDialog(response.data.data.new_software_asset_status)
                
                toast.success('Subscription Status added successfully.')
                props.fetchStatus()
            }
        }).catch(err => {
            console.log('234: ',err);
            // toast.error(err.response.data.data)
        })

    }

    function statusFormErrorData(error){
        console.log('statusFormErrorData: ',error);
    }


    return (
        <Modal
            open={props.openStatusDialogState}
            // onClose={()=>{
            //     setColor({name:'black',code:"#000000"})
            //     statusFormReset(defaultValues)
            //     // props.handleCloseDialog()
            // }}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Add Status</h1>
                    <IconButton onClick={()=>{
                        statusFormReset(defaultValues)
                        setColor({name:'black',code:"#000000"})
                        props.handleCloseDialog()
                    }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={statusFormSubmit(manageStatusData, statusFormErrorData)}>
                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
                        
                        <Controller
                            name="status_name"
                            className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Software Status Name'
                                        required
                                        // fullWidth
                                        // error={!!statusFormErrors.status_name}
                                        // helperText={statusFormErrors?.status_name?.message}
                                        // FormHelperTextProps={{
                                        //     style: {marginLeft : 0}
                                        // }}
                                        style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />

                        <Controller
                            name='status_description' 
                            control={control}
                            render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        style={{background: 'white',marginBottom: '10px'}}
                                        variant='outlined'
                                        label='Status Description' 
                                        autoFocus
                                        fullWidth
                                        InputProps={{
                                            inputComponent: TextareaAutosize,
                                        }}
                                        // required
                                        // error={!!statusFormErrors.status_description}
                                        // helperText={statusFormErrors?.status_description?.message}
                                        // FormHelperTextProps={{
                                        //     style: { 
                                        //         margin : 0, 
                                        //         backgroundColor: '#f4f4f4',
                                        //         width: '100%',
                                        //         paddingTop: '2px'
                                        //     }
                                        // }}
                                    />
                                </>
                            )}
						/>

                        <div style={{width: '100%',display:'flex',alignItems:'center' }}>
                            <Typography style={{paddingRight: '10px',fontSize:'16px'}}>Choose Color</Typography>
                            <ColorPicker setColor={setColor} color={color}/>
                        </div>
                    </div>

                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit'
                        >Add</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>{
                                statusFormReset(defaultValues)
                                setColor({name:'black',code:"#000000"})
                                props.handleCloseDialog()
                            }}
                        >Cancel</Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default SubscriptionStatusDialog
