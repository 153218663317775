const initialState = {
    open: false,
    data: null,
    user_form:false,
    sub_data:null,
    sub_id:null,
    edit_user_data:null,
    change_in_user_list: false
}

const actionTypes = {
    OPEN_FORM_VIEWER_DIALOG: 'OPEN_FORM_VIEWER_DIALOG',
    CLOSE_FORM_VIEWER_DIALOG: 'CLOSE_FORM_VIEWER_DIALOG',
    SET_FROM_DATA: 'SET_FROM_DATA',
    OPEN_USER_VIEWER_DIALOG: 'OPEN_USER_VIEWER_DIALOG',
    CLOSE_USER_VIEWER_DIALOG: 'CLOSE_USER_VIEWER_DIALOG',
    SET_SUBSCRIPTION_DATA:'SET_SUBSCRIPTION_DATA',
    SET_SUB_ID:'SET_SUB_ID',
    SET_USER_FORM_DATA:'SET_USER_FORM_DATA',
    CHANGE_USER_LIST : 'CHANGE_USER_LIST'
}

export function openUserFormDialog() {
    return {
        type: actionTypes.OPEN_USER_VIEWER_DIALOG
    }
} 

export function closeUserFormDialog() {
    return {
        type: actionTypes.CLOSE_USER_VIEWER_DIALOG
    }
}

export function openFormViewerDialog() {
    return {
        type: actionTypes.OPEN_FORM_VIEWER_DIALOG
    }
} 
export function setFromData(data=null) {
    return {
        type: actionTypes.SET_FROM_DATA,
        payload: {
            data
		}
    }
} 

export function setUserListChange(data=null) {
    return {
        type: actionTypes.CHANGE_USER_LIST,
        payload: {
            change_in_user_list:data
		}
    }
}

export function setUserFromData(data=null) {
    return {
        type: actionTypes.SET_USER_FORM_DATA,
        payload: {
            edit_user_data:data
		}
    }
} 
export function setSubData(data=null) {
    return {
        type: actionTypes.SET_SUBSCRIPTION_DATA,
        payload: {
            sub_data:data
		}
    }
} 
export function setSubId(data=null) {
    return {
        type: actionTypes.SET_SUB_ID,
        payload: {
            sub_id:data
		}
    }
} 




export function closeFormViewerDialog() {
    return {
        type: actionTypes.CLOSE_FORM_VIEWER_DIALOG
    }
}

export default function (state = initialState, action) {
    switch(action.type) {
        case actionTypes.OPEN_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open: true
            }
        }

        case actionTypes.CLOSE_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open:false
            }
        }

        case actionTypes.CHANGE_USER_LIST: {
            return {
                ...state,
                change_in_user_list:action.payload.change_in_user_list
            }
        }

        case actionTypes.SET_SUBSCRIPTION_DATA: {
            return {
                ...state,
                sub_data: action.payload.sub_data
            }
        }

        case actionTypes.SET_SUB_ID: {
            return {
                ...state,
                sub_id: action.payload.sub_id
            }
        }

        case actionTypes.SET_USER_FORM_DATA: {
            return {
                ...state,
                edit_user_data: action.payload.edit_user_data
            }
        }

        case actionTypes.OPEN_USER_VIEWER_DIALOG: {
            return {
                ...state,
                user_form: true
            }
        }

        case actionTypes.CLOSE_USER_VIEWER_DIALOG: {
            return {
                ...state,
                user_form:false
            }
        }

        case actionTypes.SET_FROM_DATA: {
            return {
                ...state,
                data: action.payload.data
            }
        }

        default:
            return state
    }
}