import React from 'react'
import Icon from '@material-ui/core/Icon';
import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import placeholderImg from "./placeholde-img.webp"
import Utilizationtab from './Utilizationtab';
import IntegrationTab from './IntegrationTab';
import { makeStyles } from '@material-ui/core/styles';
import SeatsTab from './SeatsTab';
import { useDispatch, useSelector } from 'react-redux';
import { openUserFormDialog, setUserListChange } from '../store/formViewDialogReducer';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import axios from "@fuse/utils/axios";
import { CircularProgress } from '@material-ui/core';
import { openCsvDialog } from "../store/csvDialogReducer"
import InformationTab from './InformationTab';
import ActivitieLogs from '@fuse/components/ActivitieLogs';
import {Tooltip} from '@material-ui/core';
import { useRouter } from '@fuse/hooks';

const useStyle = makeStyles({
    activeTab: {

        background: "rgba(107, 44, 87, 0.04);",
        borderRadius: "0",
    },
})


const SubscriptionDailog = ({ fullWidth, maxWidth, open, Close, classes, TabButton, tabState, setTabState, TabContainer }) => {
    const dispatch = useDispatch();
    const router = useRouter()
    const { sub_id: sub_id } = useSelector((state) => state.subscriptionApp.formViewDialog)
    const { change_in_user_list: change_in_user_list } = useSelector((state) => state.subscriptionApp.formViewDialog)
    const [subDetails, setSubDetails] = useState(null);
    const [available_seats, setAvailable_seats] = useState(null);
    const [loading, setLoading] = useState(false)
    const [logs,setLogs] = useState([]);
    const [ isShareLinkCopied, setIsShareLinkCopied ] = useState(false)

    function getRemain() {
        const day1 = new Date(subDetails?.term_start_date)
        const day2 = new Date(subDetails?.term_end_date)
        const diffTime = Math.abs(day1 - day2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays
    }

    async function getSubDetails() {
        setLoading(true)
        axios.get(`subscription/subscription-details/${sub_id}`)
            .then(results => {
                const { available_seats, subDetails } = results.data.data
                setAvailable_seats(available_seats)
                setSubDetails(subDetails)
                dispatch(setUserListChange(false))
            })
            .catch(err => {
                console.log(err);
                if(err?.response?.data?.message === "Invalid request params input" || 
                err?.response?.data?.message === "Subscription not found"){
                router.push('/not-found')
              }
            }).finally(()=>{
                setLoading(false)
            })
    }

    useEffect(() => {
        getSubDetails()
    }, [open])

    useEffect(() => {
        getSubDetails()
    }, [change_in_user_list])

    useEffect(() => {
        if (!Boolean(sub_id)) return
        setLoading(true)
        axios.get(`/subscription/subscription-logs/${sub_id}`)
        .then((res) => {
            setLogs(formatLogs(res.data.data));
        }).catch((err) => {
            console.log('err', err);
        })
    }, [open,change_in_user_list])

    
    function formatLogs(data){
        let length = data.length
        return data.map((item)=>{
            let obj = {}
            if(item.action_field_name == 'Billing Cycle Date' || item.action_field_name == 'Start Date' ||
            item.action_field_name == 'End Date' || item.action_field_name == 'PO Date'){
                obj =  {
                    id:item.id,
                    action_id: `act${length}`,
                    action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
                    name: item.user_name,
                    action: `${item.action_name.toUpperCase()}D`,
                    description: item.comment,
                    previous_value: dateReformatTo_mmm_dd_yyyy(item.previous_data),
                    current_value: dateReformatTo_mmm_dd_yyyy(item.current_data),
                    field_name: item.action_field_name,
                    user_role: item.user_role_name
                }
            }else{
                obj =  {
                    id:item.id,
                    action_id: `act${length}`,
                    action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
                    name: item.user_name,
                    action: `${item.action_name.toUpperCase()}D`,
                    description: item.comment,
                    previous_value: item.previous_data,
                    current_value: item.current_data,
                    field_name: item.action_field_name,
                    user_role: item.user_role_name
                }

            }
            length --;
            return obj
        })
    }

    function shareSubscriptions(subscription_id) {
        let link = `${window.location.origin}/subscriptions/${subscription_id}`
        navigator.clipboard.writeText(link);
        setIsShareLinkCopied(true)
    }

    return (
        <div>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                // onClose={Close}
                aria-labelledby="max-width-dialog-title"
                disableEnforceFocus
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: "right", fontSize: "20px", cursor: "pointer", padding: '0 30px 0' }}>
                    <Icon style={{ transform: "translateY(5px)" }} onClick={Close}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </Icon>
                </DialogTitle>
                <DialogContent >
                    {
                        loading ? <><Box style={{ width: "100%", height: "220px" }} className='flex items-center justify-center '>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                        </Box></> : <> <div style={{ background: '#F5F5F5', padding: "5px" }}>
                            <Grid container spacing={1} className='justify-end gap-2'>
                                <Button
                                    variant="contained"
                                    style={{ background: isShareLinkCopied ? '#90EE90' : '#808080', width: "140px", justifyContent: 'flex-start', paddingLeft: '27px'}}
                                    className={classes.btn}
                                    onClick={()=>shareSubscriptions(sub_id)}>
                                <Icon className="text-white mr-5" fontSize="default"><span className="material-symbols-outlined">{isShareLinkCopied ? 'file_copy' : 'share'}</span></Icon>
                                {isShareLinkCopied ? 'Copied' : 'Share'}
                                </Button>
                                <Grid item lg={12}>
                                    <Card style={{ borderRadius: "0", padding: "15px 20px" }}>
                                        <Grid container>
                                            <Grid item lg={8}>
                                                <Grid container style={{ display: "flex", alignItems: "center" }} >
                                                    <Grid item lg={5}>
                                                        <Box className='flex flex-col'>
                                                            <Typography variant='h6' style={{padding:"0 30px"}}>Manage Seats</Typography>
                                                            <div className='relative w-full h-full text-center flex '>
                                                                <div className='relative ' style={{ width: "200px", height: "120px" }}>
                                                                    <img className='absolute top-0 left-0 w-full h-full object-contain' src={subDetails?.logo_url ? subDetails?.logo_url : placeholderImg} alt="" />
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={7}>
                                                        <Typography color="textSecondary" style={{ display: "flex", marginBottom: "5px" }}>
                                                            {/* <Icon>
                                                                <span class="material-symbols-outlined" className={classes.icon}>list_alt</span>
                                                            </Icon>  */}
                                                            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M2.75 17h12.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 17h12.5h-12.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 13h18.5h-18.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 9h18.5h-18.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 5h18.5h-18.5Z" /></svg>
                                                            <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Subscription Name : </span>
                                                            <Tooltip placement="top" title={subDetails?.subscription_name}>
                                                                <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.subscription_name.length >= 20 ? subDetails?.subscription_name.slice(0, 25) + '...' : subDetails?.subscription_name || 'N/A'}</span>
                                                            </Tooltip>
                                                        </Typography>

                                                        <Typography variant="body2" style={{ display: "flex", marginBottom: "5px" }}>
                                                            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M8.75 4a2.75 2.75 0 0 1 2.75 2.75v10.5A2.75 2.75 0 0 1 8.75 20h-4A2.75 2.75 0 0 1 2 17.25V6.75A2.75 2.75 0 0 1 4.75 4h4Zm0 1.5h-4c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25ZM19.25 4A2.75 2.75 0 0 1 22 6.75v10.5A2.75 2.75 0 0 1 19.25 20h-4a2.75 2.75 0 0 1-2.75-2.75V6.75A2.75 2.75 0 0 1 15.25 4h4Zm0 1.5h-4c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25ZM6 9v6a.75.75 0 0 0 1.5 0V9A.75.75 0 0 0 6 9Zm11.5.75v1.5h-1.25a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h2a.75.75 0 0 0 0-1.5H17v-1.5h1.25A.75.75 0 0 0 19 12V9a.75.75 0 0 0-.75-.75h-2a.75.75 0 0 0 0 1.5h1.25Z" /></svg>
                                                            <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Number of Seats : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.no_of_seats || 0}</span>
                                                        </Typography>

                                                        <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><circle cx="24" cy="24" r="20" /><path d="M24 4a20 20 0 0 1 20 20H24V4Z" /></g></svg>
                                                            <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "7px" }}>Available Seats : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{available_seats || 0}</span>
                                                        </Typography>
                                                        {/* <Typography style={{ display: "flex" }} color="textSecondary">
                                                                <svg className={classes.icon}  xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 20"><path fill="currentColor" d="M4.5 7H4v1h.5V7Zm6 1h.5V7h-.5v1Zm-2 2H8v1h.5v-1Zm2 1h.5v-1h-.5v1Zm-6-7H4v1h.5V4Zm2 1H7V4h-.5v1Zm4-4.5l.354-.354L10.707 0H10.5v.5Zm3 3h.5v-.207l-.146-.147l-.354.354ZM4.5 8h6V7h-6v1Zm4 3h2v-1h-2v1Zm-4-6h2V4h-2v1Zm8 9h-10v1h10v-1ZM2 13.5v-12H1v12h1ZM2.5 1h8V0h-8v1ZM13 3.5v10h1v-10h-1ZM10.146.854l3 3l.708-.708l-3-3l-.708.708ZM2.5 14a.5.5 0 0 1-.5-.5H1A1.5 1.5 0 0 0 2.5 15v-1Zm10 1a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM2 1.5a.5.5 0 0 1 .5-.5V0A1.5 1.5 0 0 0 1 1.5h1Z"/></svg>
                                                                <span style={{ fontSize: "15px", color: "#61656F" }}>Estimated Cost Per User : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>${(subDetails?.subscription_billing?.amount/subDetails?.no_of_seats).toFixed(2)}</span>
                                                            </Typography>
                                                        <Typography style={{ display: "flex",  marginBottom: "5px" ,alignItems:'center'}} color="textSecondary">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width='18' height='18' className='text-gray' viewBox="0 0 24 24"><path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path></svg>
                                                                    <span style={{ fontSize: "15px", color: "#61656F",paddingLeft: '5px'}}>Subscription Status : </span>
                                                                    <span style={{
                                                                        // border: '2px solid black',
                                                                        height:'10px',
                                                                        width:'10px',
                                                                        marginLeft: '10px',
                                                                        borderRadius: '50%',
                                                                        backgroundColor: subDetails?.software_asset_status?.status_color.code
                                                                    }}></span>
                                                                    <span style={{ display: "inline-block", fontSize: "15px", marginLeft: "5px" }}>
                                                                        {subDetails?.software_asset_status?.status_name}
                                                                    </span>
                                                        </Typography> */}
                                                        {/* <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                            <svg className={classes.icon}  xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 20"><path fill="currentColor" d="M4.5 7H4v1h.5V7Zm6 1h.5V7h-.5v1Zm-2 2H8v1h.5v-1Zm2 1h.5v-1h-.5v1Zm-6-7H4v1h.5V4Zm2 1H7V4h-.5v1Zm4-4.5l.354-.354L10.707 0H10.5v.5Zm3 3h.5v-.207l-.146-.147l-.354.354ZM4.5 8h6V7h-6v1Zm4 3h2v-1h-2v1Zm-4-6h2V4h-2v1Zm8 9h-10v1h10v-1ZM2 13.5v-12H1v12h1ZM2.5 1h8V0h-8v1ZM13 3.5v10h1v-10h-1ZM10.146.854l3 3l.708-.708l-3-3l-.708.708ZM2.5 14a.5.5 0 0 1-.5-.5H1A1.5 1.5 0 0 0 2.5 15v-1Zm10 1a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM2 1.5a.5.5 0 0 1 .5-.5V0A1.5 1.5 0 0 0 1 1.5h1Z"/></svg>
                                                            <span style={{ fontSize: "15px", color: "#61656F" }}>Billing Cycle : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{ (subDetails?.subscription_billing?.billing_cycle || '0')}{(Number(subDetails?.subscription_billing?.billing_cycle || 0)>1?` ${subDetails?.subscription_billing?.month}s`: ` ${subDetails?.subscription_billing?.month}`)}</span>
                                                        </Typography> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                <Typography color="textSecondary" style={{ display: "flex", marginBottom: "5px" }}>
                                                    <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36"><path fill="currentColor" d="M32.25 6H29v2h3v22H4V8h3V6H3.75A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6Z" class="clr-i-outline clr-i-outline-path-1" /><path fill="currentColor" d="M8 14h2v2H8z" class="clr-i-outline clr-i-outline-path-2" /><path fill="currentColor" d="M14 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-3" /><path fill="currentColor" d="M20 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-4" /><path fill="currentColor" d="M26 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-5" /><path fill="currentColor" d="M8 19h2v2H8z" class="clr-i-outline clr-i-outline-path-6" /><path fill="currentColor" d="M14 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-7" /><path fill="currentColor" d="M20 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-8" /><path fill="currentColor" d="M26 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-9" /><path fill="currentColor" d="M8 24h2v2H8z" class="clr-i-outline clr-i-outline-path-10" /><path fill="currentColor" d="M14 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-11" /><path fill="currentColor" d="M20 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-12" /><path fill="currentColor" d="M26 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-13" /><path fill="currentColor" d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1Z" class="clr-i-outline clr-i-outline-path-14" /><path fill="currentColor" d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1Z" class="clr-i-outline clr-i-outline-path-15" /><path fill="currentColor" d="M13 6h10v2H13z" class="clr-i-outline clr-i-outline-path-16" /><path fill="none" d="M0 0h36v36H0z" /></svg>
                                                    <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px", textWrap: 'nowrap'}}>Terms : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{dateReformatTo_mmm_dd_yyyy(subDetails?.term_start_date)} - {dateReformatTo_mmm_dd_yyyy(subDetails?.term_end_date)}</span>
                                                </Typography>
                                                <Typography variant="body2" style={{ display: "flex", marginBottom: "5px" }}>
                                                    <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"><path d="M12.96 3H7.04c-.3 0-.45.227-.428.42c.114.993.328 2.133.686 2.862c.477.973 1.628 2.066 2.497 2.789a.312.312 0 0 0 .41 0c.87-.723 2.02-1.816 2.497-2.79c.358-.728.572-1.868.687-2.861c.022-.193-.129-.42-.428-.42ZM7.04 2c-.844 0-1.519.696-1.422 1.534c.116 1.005.343 2.294.782 3.188c.581 1.184 1.888 2.396 2.756 3.118c.494.41 1.194.41 1.688 0c.868-.722 2.175-1.934 2.756-3.118c.439-.894.666-2.183.782-3.188C14.479 2.696 13.804 2 12.96 2H7.038Z" /><path d="M7.04 17h5.92c.3 0 .45-.227.428-.42c-.114-.993-.328-2.133-.686-2.861c-.477-.974-1.628-2.067-2.497-2.79a.312.312 0 0 0-.41 0c-.87.723-2.02 1.816-2.497 2.79c-.358.728-.572 1.868-.687 2.862c-.022.192.129.419.428.419Zm5.92 1c.844 0 1.519-.696 1.422-1.534c-.116-1.006-.343-2.294-.782-3.188c-.581-1.184-1.888-2.396-2.756-3.118a1.311 1.311 0 0 0-1.688 0c-.868.722-2.175 1.934-2.756 3.118c-.439.894-.666 2.182-.782 3.188C5.521 17.304 6.196 18 7.04 18h5.921ZM4 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5Z" /><path d="M4 17.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5Z" /></g></svg>
                                                    <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Remaining : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{getRemain()} days</span>
                                                </Typography>

                                                {/* <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                    <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z"/></svg>
                                                    <span style={{ fontSize: "15px", color: "#61656F",marginLeft:"5px" }}>Paid Via : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.subscription_payment?.value == 'CreditCard'?'Credit Card':subDetails?.subscription_payment?.value == 'purchaseOrder'?'Purchase Order':'N/A'}</span>
                                                </Typography> */}
                                                <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                    <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 1.25a4.75 4.75 0 1 0 0 9.5a4.75 4.75 0 0 0 0-9.5ZM8.75 6a3.25 3.25 0 1 1 6.5 0a3.25 3.25 0 0 1-6.5 0ZM12 12.25c-2.313 0-4.445.526-6.024 1.414C4.42 14.54 3.25 15.866 3.25 17.5v.102c-.001 1.162-.002 2.62 1.277 3.662c.629.512 1.51.877 2.7 1.117c1.192.242 2.747.369 4.773.369s3.58-.127 4.774-.369c1.19-.24 2.07-.605 2.7-1.117c1.279-1.042 1.277-2.5 1.276-3.662V17.5c0-1.634-1.17-2.96-2.725-3.836c-1.58-.888-3.711-1.414-6.025-1.414ZM4.75 17.5c0-.851.622-1.775 1.961-2.528c1.316-.74 3.184-1.222 5.29-1.222c2.104 0 3.972.482 5.288 1.222c1.34.753 1.961 1.677 1.961 2.528c0 1.308-.04 2.044-.724 2.6c-.37.302-.99.597-2.05.811c-1.057.214-2.502.339-4.476.339c-1.974 0-3.42-.125-4.476-.339c-1.06-.214-1.68-.509-2.05-.81c-.684-.557-.724-1.293-.724-2.601Z" clip-rule="evenodd" /></svg>
                                                    <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Name : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{`${subDetails?.user_companies_user?.first_name}  ${subDetails?.user_companies_user?.last_name}`}</span>
                                                </Typography>
                                                {/* <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                    <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12.0049 22.0029C6.48204 22.0029 2.00488 17.5258 2.00488 12.0029C2.00488 6.48008 6.48204 2.00293 12.0049 2.00293C17.5277 2.00293 22.0049 6.48008 22.0049 12.0029C22.0049 17.5258 17.5277 22.0029 12.0049 22.0029ZM12.0049 20.0029C16.4232 20.0029 20.0049 16.4212 20.0049 12.0029C20.0049 7.58465 16.4232 4.00293 12.0049 4.00293C7.5866 4.00293 4.00488 7.58465 4.00488 12.0029C4.00488 16.4212 7.5866 20.0029 12.0049 20.0029ZM8.50488 14.0029H14.0049C14.281 14.0029 14.5049 13.7791 14.5049 13.5029C14.5049 13.2268 14.281 13.0029 14.0049 13.0029H10.0049C8.62417 13.0029 7.50488 11.8836 7.50488 10.5029C7.50488 9.12222 8.62417 8.00293 10.0049 8.00293H11.0049V6.00293H13.0049V8.00293H15.5049V10.0029H10.0049C9.72874 10.0029 9.50488 10.2268 9.50488 10.5029C9.50488 10.7791 9.72874 11.0029 10.0049 11.0029H14.0049C15.3856 11.0029 16.5049 12.1222 16.5049 13.5029C16.5049 14.8836 15.3856 16.0029 14.0049 16.0029H13.0049V18.0029H11.0049V16.0029H8.50488V14.0029Z"></path></svg>                                            
                                                    <span style={{ fontSize: "15px", color: "#61656F",marginLeft:"5px" }}>Total Cost : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{`$${subDetails?.subscription_billing?.amount || '0'}`}</span>
                                                </Typography> */}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                            {
                                tabState === 'seats' && <div className='mt-10 flex justify-end '>
                                    <Button onClick={() => dispatch(openCsvDialog())} className='rounded-none mr-10 wizard-screen-backBtn shadow-none' color='primary' variant='outlined'>Import</Button>
                                    <Button onClick={() => dispatch(openUserFormDialog())} className='rounded-md' color='primary' variant='contained'>Add</Button>
                                </div>
                            }

                            <Grid container style={{ marginTop: "10px", background: "#fff" }}>
                                <Grid item lg={2}>
                                    <Card style={{ borderRadius: '0', boxShadow: "none" }}>
                                        <CardActions disableSpacing style={{ display: "flex", flexDirection: "column", borderRadius: '0', boxShadow: 'none' }}>
                                            <TabButton
                                                tab="information"
                                                tabState={tabState}
                                                tabStateChange={setTabState}
                                                activeClass={classes.activeTab}
                                                variant="text"
                                                color="primary"
                                                className={`w-full d-flex justify-content-start`}
                                                style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                            >
                                                Information
                                            </TabButton>

                                            <TabButton
                                                tab="seats"
                                                tabState={tabState}
                                                tabStateChange={setTabState}
                                                activeClass={classes.activeTab}
                                                variant="text"
                                                color="primary"
                                                className={`w-full d-flex justify-content-start`}
                                                style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                            >
                                               { `Seats(${subDetails?.multiple_subscription_users?.length || 0})`}
                                            </TabButton>

                                            <TabButton
                                                tab="utilization"
                                                tabState={tabState}
                                                tabStateChange={setTabState}
                                                activeClass={classes.activeTab}
                                                variant="text"
                                                color="primary"
                                                className={`w-full d-flex justify-content-start`}
                                                style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                            >
                                                Utilization
                                            </TabButton>

                                            <TabButton
                                                tab="integration"
                                                tabState={tabState}
                                                tabStateChange={setTabState}
                                                activeClass={classes.activeTab}
                                                variant="text"
                                                color="primary"
                                                className={`w-full d-flex justify-content-start`}
                                                style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                            >
                                                Integration
                                            </TabButton>
                                            <TabButton
                                                tab="activity"
                                                tabState={tabState}
                                                tabStateChange={setTabState}
                                                activeClass={classes.activeTab}
                                                variant="text"
                                                color="primary"
                                                className={`w-full d-flex justify-content-start ${classes.tabButton}`}
                                                style={{ width: "100%", display: "flex", justifyContent: "start", margin: "5px 0" }}
                                            >
                                                Activities
                                            </TabButton>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid lg={10}>
                                    <div>
                                        <Card style={{ width: "100%", borderRadius: "0", overflow: "auto" }}>
                                            <CardContent style={{ width: "100%" }}>
                                                <TabContainer tab="information" tabState={tabState}>
                                                    <InformationTab />
                                                </TabContainer>
                                                <TabContainer tab="seats" tabState={tabState}>
                                                    <SeatsTab />
                                                </TabContainer>
                                                <TabContainer tab="utilization" tabState={tabState}>
                                                    <Utilizationtab />
                                                </TabContainer>
                                                <TabContainer tab="integration" tabState={tabState}>
                                                    <IntegrationTab />
                                                </TabContainer>
                                                <TabContainer tab="activity" tabState={tabState}>
                                                    <ActivitieLogs logs={logs} height={'290px'}/>
                                                </TabContainer>									
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            </Grid>
                        </div></>
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={Close} variant='contained' color="primary">
                        Close
                    </Button>
                </DialogActions> */}
            </Dialog>
            {/* < AddUserForm/> */}
        </div>
    )
}

export default SubscriptionDailog
