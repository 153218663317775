import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const { hasPermission } = usePermission()
	const createServiceRequestSourcePermission = hasPermission('add-service_request_source')
	const addReportPermission = hasPermission('add-report')


	return (
		<div className="flex flex-1 w-full items-center justify-between">
			{/* Main header */}
			<div className="flex items-center">
				<svg width="26" height="26" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M22.6 33.7H25.4L25.9 31C26.5667 30.8 27.1333 30.55 27.6 30.25C28.0667 29.95 28.5 29.6 28.9 29.2L32 30.15L33.3 27.45L30.95 25.95C31.0833 25.25 31.15 24.6 31.15 24C31.15 23.4 31.0833 22.75 30.95 22.05L33.3 20.55L32 17.85L28.9 18.8C28.5 18.4 28.0667 18.05 27.6 17.75C27.1333 17.45 26.5667 17.2 25.9 17L25.4 14.3H22.6L22.1 17C21.4333 17.2 20.8667 17.45 20.4 17.75C19.9333 18.05 19.5 18.4 19.1 18.8L16 17.85L14.7 20.55L17.05 22.05C16.9167 22.75 16.85 23.4 16.85 24C16.85 24.6 16.9167 25.25 17.05 25.95L14.7 27.45L16 30.15L19.1 29.2C19.5 29.6 19.9333 29.95 20.4 30.25C20.8667 30.55 21.4333 30.8 22.1 31L22.6 33.7ZM24 28.25C22.8 28.25 21.7917 27.8417 20.975 27.025C20.1583 26.2083 19.75 25.2 19.75 24C19.75 22.8 20.1583 21.7917 20.975 20.975C21.7917 20.1583 22.8 19.75 24 19.75C25.2 19.75 26.2083 20.1583 27.025 20.975C27.8417 21.7917 28.25 22.8 28.25 24C28.25 25.2 27.8417 26.2083 27.025 27.025C26.2083 27.8417 25.2 28.25 24 28.25ZM9 42C8.2 42 7.5 41.7 6.9 41.1C6.3 40.5 6 39.8 6 39V9C6 8.2 6.3 7.5 6.9 6.9C7.5 6.3 8.2 6 9 6H39C39.8 6 40.5 6.3 41.1 6.9C41.7 7.5 42 8.2 42 9V39C42 39.8 41.7 40.5 41.1 41.1C40.5 41.7 39.8 42 39 42H9ZM9 39H39V9H9V39Z" fill="#040404" />
				</svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Service Ticket Sources List
				</Typography>
			</div>

			<div style={{display:'flex'}}>

				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}

				{/* Add new asset Source */}
				{createServiceRequestSourcePermission && <Button
					variant="contained"
					color="secondary"
					className={`w-full ${classes.buttonSquare}`}
					style={{width:'270px', marginRight: '10px'}}
					onClick = {()=>dispatch(openFormViewerDialog())}
					startIcon={
					<Icon fontSize="small">
						add
					</Icon>}>
						Add Service Ticket Source
				</Button>}

				{/* search */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Source"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	)
}

export default DynamicFieldsHeader;
