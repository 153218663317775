import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const { hasPermission } = usePermission()
	const createServiceRequestCategoryPermission = hasPermission('add-service_request_category')
	const addReportPermission = hasPermission('add-report')


	return (
		<div className="flex flex-1 w-full items-center justify-between">
			{/* Main header */}
			<div className="flex items-center">
				<svg style={{transform:"translateY(3px)"}} width="28" height="28" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M35 41.5C33.0667 41.5 31.4167 40.8167 30.05 39.45C28.6833 38.0833 28 36.4333 28 34.5C28 32.5667 28.6833 30.9167 30.05 29.55C31.4167 28.1833 33.0667 27.5 35 27.5C36.9333 27.5 38.5833 28.1833 39.95 29.55C41.3167 30.9167 42 32.5667 42 34.5C42 36.4333 41.3167 38.0833 39.95 39.45C38.5833 40.8167 36.9333 41.5 35 41.5ZM34.9883 38.5C36.0961 38.5 37.0417 38.1122 37.825 37.3367C38.6083 36.5613 39 35.6196 39 34.5117C39 33.4039 38.6122 32.4583 37.8367 31.675C37.0613 30.8917 36.1196 30.5 35.0117 30.5C33.9039 30.5 32.9583 30.8878 32.175 31.6633C31.3917 32.4387 31 33.3804 31 34.4883C31 35.5961 31.3878 36.5417 32.1633 37.325C32.9387 38.1083 33.8804 38.5 34.9883 38.5ZM6 36V33H24V36H6ZM13 20.5C11.0667 20.5 9.41667 19.8167 8.05 18.45C6.68333 17.0833 6 15.4333 6 13.5C6 11.5667 6.68333 9.91667 8.05 8.55C9.41667 7.18333 11.0667 6.5 13 6.5C14.9333 6.5 16.5833 7.18333 17.95 8.55C19.3167 9.91667 20 11.5667 20 13.5C20 15.4333 19.3167 17.0833 17.95 18.45C16.5833 19.8167 14.9333 20.5 13 20.5ZM12.9883 17.5C14.0961 17.5 15.0417 17.1122 15.825 16.3367C16.6083 15.5613 17 14.6196 17 13.5117C17 12.4039 16.6122 11.4583 15.8367 10.675C15.0613 9.89167 14.1196 9.5 13.0117 9.5C11.9039 9.5 10.9583 9.88775 10.175 10.6633C9.39167 11.4387 9 12.3804 9 13.4883C9 14.5961 9.38775 15.5417 10.1633 16.325C10.9387 17.1083 11.8804 17.5 12.9883 17.5ZM24 15V12H42V15H24Z" fill="#040404" />
				</svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Service Ticket Categories List
				</Typography>
			</div>

			<div style={{display:'flex'}}>

				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}

				{/* Add new service request Category */}
				{createServiceRequestCategoryPermission && <Button
					variant="contained"
					color="secondary"
					className={`w-full ${classes.buttonSquare}`}
					style={{width:'270px', marginRight: '10px'}}
					onClick = {()=>dispatch(openFormViewerDialog())}
					startIcon={
					<Icon fontSize="small">
						add
					</Icon>}>
						Add Service Ticket Category
				</Button>}

				{/* search */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Category"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	)
}

export default DynamicFieldsHeader;
