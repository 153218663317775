import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { rememberUser, submitLogin, submitGoogle, submitMicrosoft } from '../../auth/store/loginSlice';
import { useGoogleLogin } from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import axiosgeneral from "@fuse/utils/axios";
import axios from 'axios';
import { setUserData } from '../../auth/store/userSlice';
import { refreshTokenSetup } from './refreshToken';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	root: {},
	leftSection: {},
	rightSection: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	}
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup.string().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,"You must enter a valid email").required('You must enter a email'),
	password: yup
		.string()
		.required('Please enter your password.')
		.min(8, 'Password is too short - should be 8 chars minimum.')
});

const defaultValues = {
	email: '',
	password: '',
	remember: false
};

function Login({ user, rememberUser, submitLogin, loggingIn, submitGoogle, submitMicrosoft }) {

	const classes = useStyles();

	const [showPassword, setShowPassword] = useState(false);

	const { control, formState, handleSubmit, reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	function toggleShowPassword() {
		setShowPassword(!showPassword);
	}
	const { isValid, dirtyFields, errors } = formState;

	function onSubmit(formData) {
		reset(defaultValues);
		submitLogin(formData);
	}


	const onSuccess = (res) =>{
		//console.log('Login Success: currentUser:', res);
		const idToken = res.tokenId;
		const google_id= res.googleId;
		submitGoogle({idToken, google_id});
	};

	const onFailure = (res) => {
		console.log('Login failed: res:', res);
	};
	const clientId =process.env.REACT_APP_GOOGLE_CLIENT_ID2;
	//console.log(clientId, env.BACKEND_URL)
	const {signIn} = useGoogleLogin({
		onSuccess,
		onFailure,
		clientId,
		isSignedIn: false,
		accessType: 'offline'
	});
	const authHandler = (err, data) => {
		console.log(err, data);
		if(err)
			return;
		const uniqueId = data.uniqueId;
		const username = data.account.userName;
		//console.log(uniqueId, username)
		submitMicrosoft({uniqueId, username})
	};

	const microsoftclientId=`${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`;
	const microsoftclientredirectUri=`${process.env.REACT_APP_SERVER}`== "LOCAL" ?  `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID}` :  `${process.env.REACT_APP_SERVER}`== "DEV" ? `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_DEV}` : `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_STAGING}`
	return (
		<div
			className={clsx(
				classes.root,
				'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'
			)}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex w-full max-w-400 md:max-w-2xl rounded-20 shadow-2xl overflow-hidden"
			>
				<Card
					className={clsx(
						classes.leftSection,
						'flex flex-col w-full max-w-sm items-center justify-center shadow-0'
					)}
					square
				>
					<CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center mb-48">
								<img className="logo-icon w-48" src="assets/images/logos/dalos_logo.png" alt="logo" />
								<div className="border-l-1 mr-4 w-1 h-40" />
								<div>
									<Typography className="text-24 font-semibold logo-text" color="inherit">
										Dalos
									</Typography>
									{/* <Typography
										className="text-16 tracking-widest -mt-8 font-700"
										color="textSecondary"
									>
										REACT
									</Typography> */}
								</div>
							</div>
						</motion.div>

						<form
							name="loginForm"
							noValidate
							className="flex flex-col justify-center w-full"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-16"
										label="Email"
										autoFocus
										type="email"
										error={!!errors.email}
										helperText={errors?.email?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>

							<Controller
								name="password"
								control={control}
								render={({ field }) => (
									<>
									<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
										<InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
										<OutlinedInput
											{...field}
											id="outlined-adornment-password"
											type={showPassword ? 'text' : 'password'}
											className="mb-16"
											error={!!errors.password}
											endAdornment={
											<InputAdornment position="end">
												<IconButton
												aria-label="toggle password visibility"
												onClick={toggleShowPassword}
												onMouseDown={toggleShowPassword}
												edge="end"
												>
												{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
											}
											labelwidth={70}
										/>
									</FormControl>
									</>
								)}
							/>

							<div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
								<Controller
									name="remember"
									control={control}
									render={({ field }) => (
										<FormControl>
											<FormControlLabel label="Remember Me" control={<Checkbox {...field}/>} />
										</FormControl>
									)}
								/>

								<Link className="font-normal" to="/reset-password">
									Forgot Password?
								</Link>
							</div>

							<Button
								variant="contained"
								color="primary"
								className="w-full mx-auto mt-16"
								aria-label="LOG IN"
								disabled={_.isEmpty(dirtyFields) || !isValid}
								type="submit"
							>
								{loggingIn? <CircularProgress /> :'Login'}
							</Button>
						</form>

						{/* <div className="my-24 flex items-center justify-center">
							<Divider className="w-32" />
							<span className="mx-8 font-semibold">OR</span>
							<Divider className="w-32" />
						</div>

						<Button variant="outlined" color="primary" size="small" className="w-192 mb-8" onClick={ signIn }>
							Log in with Google ID
						</Button>
						<MicrosoftLogin clientId={microsoftclientId} redirectUri={window.location.href} authCallback={authHandler} prompt={'select_account'}>
							<Button variant="outlined" color="primary" size="small" className="w-192">
								Log in with Microsoft ID
							</Button>
						</MicrosoftLogin>				 */}
					</CardContent>

					<div className="flex flex-col items-center justify-center pb-32">
						<span className="font-normal">Don't have an account?</span>
						<Link className="font-normal" to="/registration">
							Get in touch with Dalos
						</Link>
					</div>
				</Card>

				<div className={clsx(classes.rightSection, 'hidden md:flex flex-1 items-center justify-center p-64')}>
					<div className="max-w-320">
						<motion.div
							initial={{ opacity: 0, y: 40 }}
							animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
						>
							<Typography color="inherit" className="text-32 sm:text-44 font-semibold leading-tight">
								Dalos
							</Typography>
						</motion.div>

						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
							<Typography variant="subtitle1" color="inherit" className="mt-32 font-medium">
								Maximize your service revenue.
							</Typography>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</div>
	);
	
}

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		loggingIn: state.auth.login.loggingIn
	}
}

const mapDispatchToProps = (dispatch) => ({
	rememberUser: remember => dispatch(rememberUser(remember)),
	submitLogin: formData => dispatch(submitLogin(formData)),
	submitGoogle: data => dispatch(submitGoogle(data)),
	submitMicrosoft: data => dispatch(submitMicrosoft(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Login);
