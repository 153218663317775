import Page from './index';
import { authRoles } from 'app/auth';

export default {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	auth: authRoles.agentAndAdmin,
	routes: [
		{
			path: '/users',
			exact: true,
			component: Page.Listing
		},
		{
			path: '/user/:id',
			exact: true,
			component: Page.UserDetails
		},
	]
};
