import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, TextField } from '@material-ui/core';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function AccessoryCategoryDialog(props) {

  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)

  const schema = yup.object({
    accessory_category_name: yup.string().required('Accessory Category Name is required!'),
  })

  const defaultValues = {
    accessory_category_name: '',
    description: ''
  }

  const { 
    control, 
    formState:{errors}, 
    handleSubmit: accessoryCategoryFormSubmit, 
    reset: accessoryCategoryFormReset 
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  function manageAccessoryCategoryData(formData){
    const payload = {
      entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
      accessory_category_name: formData.accessory_category_name,
      description: formData.description.trim()
    }
    axios.post('/accessory-category/add', payload)
    .then(response => {
      if(response.status == 201){
        toast.error(response.data.message)
        return
      }else {
        accessoryCategoryFormReset(defaultValues)
        //Send newly added accessory category value
        props.handleCloseDialog(response.data.data.new_accessory_category)
        toast.success('Accessory Category added Successfully.')
        props.refreshList()  
      }
    }).catch(err => {
        toast.error(err.response.data.message)
    })
  }

  function accessoryCategoryFromErrors(error){
      console.log('statusFormErrorData: ',error);
  }

  return (
    <Modal
      open={props.open}
      className={classes.modal}
    >
      <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
          <h1>Add Accessory Category</h1>
          <IconButton onClick={()=>{
            accessoryCategoryFormReset(defaultValues)
            props.handleCloseDialog()
          }}>
            <Icon>close</Icon>
          </IconButton>
        </div>

        <form onSubmit={accessoryCategoryFormSubmit(manageAccessoryCategoryData, accessoryCategoryFromErrors)}>
          <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
            <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
              <Controller
                name='accessory_category_name' 
                control={control}
                render={( { field} ) => (
                  <TextField 
                    {...field}
                    variant='outlined'
                    label='Accessory Category' 
                    autoFocus
                    fullWidth
                    // required
                    style={{background: 'white'}}
                    // error={!!errors.accessory_category_name}
                    // helperText={errors?.accessory_category_name?.message}
                    FormHelperTextProps={{
                      style: { 
                          margin : 0, 
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                      }
                    }}
                  />
                )}
              />
            </div>
            <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='description' 
										control={control}
										render={( { field} ) => (
											<>
												{/* <TextareaAutosize 
													{...field}
													style={{background: 'white',width: '100%',minHeight: '20%',border: 'solid 2px #c8c5c5',padding: '9px'}}
													variant='outlined' 
													label='Status description' 
													fullWidth
													placeholder="Status description"
													required
												/> */}
												<TextField 
													{...field}
													style={{background: 'white',resize: 'auto'}}
													variant='outlined'
													label='Description' 
													fullWidth
													// required
													multiline
													rows={10}
													inputProps={{
														style: {
														resize: "vertical",
														},
													}}
												/>
											</>
										)}
									/>
					  </div> 
          </div>
          <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              style={{ borderRadius: '4px' }}
              type='submit' 
            >
              Add
            </Button>
            <Button 
              variant='outlined'
              color='primary'
              size='small'
              style={{ borderRadius: '4px' }}
              onClick={()=>{
                  accessoryCategoryFormReset(defaultValues)
                  props.handleCloseDialog()
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AccessoryCategoryDialog
