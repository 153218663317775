import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { setfilterQuery } from '../store/filterParamsReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { UploadIcon } from '@fuse/icons';
import { openCsvDialog } from "../store/csvDialogReducer"
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { useDebounce } from '@fuse/hooks'


const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme)
	const dispatch = useDispatch();
	const toast = useToast(dispatch)

	const { hasPermission } = usePermission()
	const createCategoryPermission = hasPermission('add-asset_category')
	const updateCategoryPermission = hasPermission('update-category')
	const addReportPermission = hasPermission('add-report')
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);


	return (
		<div className="flex flex-1 w-full items-center justify-between">
			{/* Main heading */}
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M8 4H21V6H8V4ZM5 3V6H6V7H3V6H4V4H3V3H5ZM3 14V11.5H5V11H3V10H6V12.5H4V13H6V14H3ZM5 19.5H3V18.5H5V18H3V17H6V21H3V20H5V19.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Asset Categories List
				</Typography>
			</div>

			<div style={{display:'flex'}}>
				<div className={classes.buttonContainer}>
							<Button
							onClick={() => dispatch(openReportViewerDialog())}
							variant="contained"
							color="secondary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'150px',marginRight: '10px'}}
							startIcon={
								<Icon fontSize="small" title="Add Report">
									add
								</Icon>
							}>
								Add Report
							</Button>
					</div>
				{/* Csv */}
				{ addReportPermission && 
					<div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openCsvDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<SvgIcon fontSize="small"><UploadIcon color="default" /></SvgIcon>
						}>
							Upload CSV
						</Button>
					</div>
				}
				{/* Add Category */}
				{ createCategoryPermission && 
					<div className={classes.buttonContainer}>
						<Button
							variant="contained"
							color="secondary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'200px', marginRight: '10px'}}
							onClick = {()=>dispatch(openFormViewerDialog())}
							startIcon={
							<Icon fontSize="small" title="Add Category">
								add
							</Icon>}>
								Add Category
						</Button>
					</div>
				}
				{/* Save view */}
				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"20px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}
				{/* Search */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Asset Categories"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>
							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	)
}

export default DynamicFieldsHeader;
