const titleConfig = {
	//Dashboard
	'/dashboard':{
		id: 'dashboad-component',
		title: 'Dashboard',
		translate: 'Dashboard',
        url: '/dashboard',
	},

	//Reports
	'/reports':{
		id: 'reports',
		title: 'Report',
		url: '/reports',
	},


	//Asset Settings
    '/assets-list':{
        id: 'assets-component',
        title: 'Assets',
        url: '/assets-list',
    },
    '/assets-dashboard':{
        id: 'assets-dashboard-component',
        title: 'Assets Dashboard',
        url: '/assets-dashboard',
    },
    '/assets-listing':{
        id: 'assets-listing-component',
        title: 'Assets Listing',
        url: '/assets-listing',
    },

    '/archived-assets':{
        id: 'archived-assets-component',
        title: 'Archived Assets',
        url: '/archived-assets',
    },
    '/assets-catalog':{
        id: 'assets-catalog-component',
        title: 'Assets Catalog',
        url: '/assets-catalog',
    },
    '/manufacturers':{
        id: 'manufacturers-component',
        title: 'Manufacturers',
        url: '/manufacturers',
    },
    '/categories':{
        id: 'asset-category-component',
        title: 'Asset Categories',
        url: '/categories',
    },
    '/models':{
        id: 'models-component',
        title: 'Models',
        url: '/models',
    },
    '/accessories':{
        id: 'accessory-component',
        title: 'Accessories',
        url: '/accessories',
    },
    '/model-dynamic-attributes':{
        id: 'models-custom-attributes',
        title: 'Model Attributes',
        url: '/model-dynamic-attributes',
    },
    '/asset-statuses':{
        id: 'asset-status-component',
        title: 'Asset Statuses',
        url: '/asset-statuses',
    },
    '/accessory-categories':{
        id: 'accessory-category-component',
        title: 'Accessory Categories',
        url: '/accessory-categories',
    },

	//Contract Settings
    '/contracts':{
        id: 'contract-component',
        title: 'Contracts',
        url: '/contracts',
    },
    '/contract-details':{
        id: 'contract-component',
        title: 'Contracts',
        url: '/contract-details/:id',
    },
    '/archived-contracts':{
        id: 'archived-contract-component',
        title: 'Archived Contracts',
        url: '/archived-contracts',
    },
    '/payment-terms':{
        id: 'payment-term-component',
        title: 'Payment Terms',
        url: '/payment-terms',
    },
    '/subscription-payment-terms':{
        id: 'sub-payment-term-component',
        title: 'Payment Terms',
        url: '/subscription-payment-terms',
    },
    '/contract-tags':{
        id: 'contract-statuses-component',
        title: 'Contract Tags',
        url: '/contract-tags',
    },
    '/contract-types':{
        id: '/contract-types',
        title: 'Contract Types',
        url: '/contract-types',
    },
    '/service-providers':{
        id: 'service-providers-component',
        title: 'Service Providers',
        url: '/service-providers',
    },
    '/service-level-agreements':{
        id: 'service-level-agreements-component',
        title: 'Service Level Agreements',
        url: '/service-level-agreements',
    },

	//Subscription Settings
    '/subscriptions':{
        id: 'subscription-component',
        title: 'Subscriptions',
        url: '/subscriptions',
    },
    '/subscription-categories':{
        id: 'subscription-categories-component',
        title: 'Subscription Categories',
        url: '/subscription-categories',
    },
    '/subscription-statuses':{
        id: 'subscription-status-component',
        title: 'Subscription Statuses',
        url: '/subscription-statuses',
    },
    '/cloud-providers':{
        id: 'cloud-provider-component',
        title: 'Subscription Providers',
        url: '/cloud-providers',
    },

	//service requests
    '/service-tickets':{
        id: 'service-request-component',
        title: 'Service Tickets',
        url: '/service-tickets',
    },
    '/archived-service-tickets':{
        id: 'archived-service-tickets-component',
		title: 'Archived Service Tickets',
        url: '/archived-service-tickets',
    },
    '/service-ticket-categories':{
        id: 'service-request-categories-component',
        title: 'Service Ticket Categories',
        url: '/service-ticket-categories',
    },
    '/service-ticket-statuses':{
        id: 'service-request-status-component',
        title: 'Service Ticket Statuses',
        url: '/service-ticket-statuses',
    },
    '/service-ticket-sources':{
        id: 'service-request-sources-component',
        title: 'Service Ticket Sources',
        url: '/service-ticket-sources',
    },


	//System Settings
    '/roles':{
        id: 'roles',
        title: 'Roles',
        url: '/roles',
    },
    '/locations':{
        id: 'shipping-component',
        title: 'Locations',
        url: '/locations',
    },
    '/location-types':{
        id: 'location-type-component',
        title: 'Location Types',
        url: '/location-types',
    },
    '/departments':{
        id: 'departments',
        title: 'Departments',
        url: '/departments',
    },
    '/users':{
        id: 'users',
        title: 'Users',
        url: '/users',
    },
    '/custom-reports':{
        id: 'custom-reports',
        title: 'Custom Reports',
        url: '/custom-reports',
    },
    '/standard-reports':{
        id: 'standard-reports',
        title: 'Standard Reports',
        url: '/standard-reports',
    },
    /** '/integration':{
        id: 'integration',
        title: 'Integration',
        url: '/integration',
    },*/
    //connectors
    '/connectors':{
        id: 'marketplace-component',
        title: 'Marketplaces',
        url: '/connectors',
    },
    ///insights-log
	'/insights-log':{
		id: '/insights-log-component',
		title: 'Insights Log',
		url: '/insights-log'
	},
    //profile
    '/profile-settings':{
        id: '/profile-settings-component',
		title: 'Profile',
		url: '/profile-settings'
    },
    '/user-audit-logs':{
        id: '/user-audit-logs-component',
		title: 'User Audit Logs',
		url: '/user-audit-logs'
    },
    '/customer-management':{
        id: '/customer-management',
		title: 'Customer Management',
		url: '/customer-management'
    },
    '/agent-management':{
        id: '/agent-management',
		title: 'Agent Management',
		url: '/agent-management'
    },
    '/overview':{
        id: '/overview',
		title: 'Dashboard',
		url: '/overview'
    },
    '/admin-activity-log':{
        id: '/admin-activity-log',
		title: 'Activity Log',
		url: '/admin-activity-log'
    },
    '/admin-audit-log':{
        id: '/admin-audit-log',
		title: 'Audit Log',
		url: '/admin-audit-log'
    }
};

export default titleConfig;
