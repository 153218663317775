import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {SvgIcon, TextField} from '@material-ui/core';
import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import useToast from '@fuse/hooks/useToast'
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import axios from "@fuse/utils/axios";
import { openCsvDialog } from "../store/csvDialogReducer"
import { UploadIcon } from '@fuse/icons';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const { hasPermission } = usePermission()
	const addLocationPermission = hasPermission('add-location')
	const addReportPermission = hasPermission('add-report')

	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const fieldList = [
		{
			field:'city',
			label:'City'
		},
		{
			field:'state',
			label:'State'
		},
		{
			field:'country',
			label:'Country'
		},
		{
			field:'company_name',
			label:'Company Name'
		},
		{
			field:'asset_location_name',
			label:'Site name'
		}
	]

	function debounce(func, timeout = 300){
		let timer;
		return (...args) => {
		  clearTimeout(timer);
		  timer = setTimeout(() => { func.apply(this, args); }, timeout);
		};
	}

	function getSearchSuggetions(query, field){
		axios.get('/shipping/suggest-shipping-locations',{
			params: {
				text:query,
				field:field
			}
		})
		.then(res => {
			const { data } = res.data;
			console.log(data);
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleSearchSuggestions = debounce((query, field) => {
		if(query!='')
			getSearchSuggetions(query, field)
		else{
			setSearchSuggestions([]);
			props.handleSearchReset()
		}
	}, 500)

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			{/* Main header */}
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color: 'black'}}
				>
					Locations List
				</Typography>
			</div>

			<div style={{display:'flex'}}>
				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}
				{/* Csv */}
				{addLocationPermission && <div className={classes.buttonContainer}>
					<Button
						onClick={() => dispatch(openCsvDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<SvgIcon fontSize="small"><UploadIcon color="default" /></SvgIcon>
						}
					>
						Upload CSV
					</Button>
				</div>}
				{/* Add new location */}
				{addLocationPermission && <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'200px', marginRight: '10px'}}
						onClick = {()=>dispatch(openFormViewerDialog())}
						startIcon={
						<Icon fontSize="small" title="Add Manufacturer">
							add
						</Icon>}>
							Add Location
					</Button>
				</div>}
				{/* save view */}
				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"20px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}
				{/* search */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Locations"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>
							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>	
		</div>
	);
}

export default DynamicFieldsHeader;
