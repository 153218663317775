import React from 'react'
import { DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    AccordionActions,
    Dialog
} from '@material-ui/core';

function GeneralModal(props) {
    return (
        <div>
          <Dialog
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {props.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <Button onClick={() => props.handleConfirm()} autoFocus>
                {props.actionButtonName ? props.actionButtonName : 'Delete'}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    
    )
}

export default GeneralModal