import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import {Tab,Tabs} from '@material-ui/core'
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import { usePermission } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import AddModelDialog from './addContractStatusDialog';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import SaveReportDialog from '../../reports/components/saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {	
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [invoices, setContractStatus] = useState([]);
	const router = useRouter();
	const agents = getAgents(invoices);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});

	function getAgents(invoices){
		const agentSet = new Set();
		for(let i = 0; i < invoices.length ; i++){
			agentSet.add(invoices[i].Agent);
		}
		return Array.from(agentSet);
	}

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};
	
	function gotoDetailsPage(id){
		router.push(`manufacturers/${id}`)
	}

	async function getContractStatus(){
		try {
			const res = await axios.get("/contract-status");
			const { data } = res.data;
			setContractStatus(formatContractStatus(data));
		} catch (err) {
			console.log(err);
		}
	}
	
	async function getManufacturers(){
		try {
			const res = await axios.get("/manufacturers");
			const { data } = res.data;
		} catch (err) {
			console.log(err);
		}
	}

	function handleSearchReset(){
		getManufacturers()
	}

	function getSearchSuggetions(query, field){
		axios.get('/manufacturers/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/manufacturers/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			setContractStatus(data)
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	function formatContractStatus(data){
		return data.map((item) => {
			return {
				id: item.id,
				contract_tag_name: item.contract_status_name,
				status_description: (!item.status_description || item.status_description === "") ? '-' : item.status_description,
				status_color: item.status_color,
				company_name: item.user_company?.company_name ?? 'N/A'
			}
		})
	}

	function refreshList(){
		return getContractStatus();
	}

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}
	
	useEffect(()=>{
		setLoading(true)
		getContractStatus().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	const { hasPermission } = usePermission()

	const viewContractStatusPermission = hasPermission('view-contract_status')
	const createContractStatusPermission = hasPermission('add-contract_status')
	const updateContractStatusPermission = hasPermission('update-contract_status')
	const deleteContractStatusPermission = hasPermission('delete-contract_status')

	return (
		<>
			<SaveReportDialog tableName={"contract status"}/>
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		handleSearch={handleSearch} 
				// 		searchSuggestions={searchSuggestions} 
				// 		clearSearch={clearFilters}
				// 		searchFilters={searchFilters}
				// 		handleSearchSuggestions={handelSearchSuggestions}
				// 		handleSearchReset={handleSearchReset}
				// 		onSearchChange={(searchValue, fieldValue) => {
				// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
				// 		}}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Contract Status" />
				// 			{viewContractStatusPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}							
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							{
								loading && <FuseLoading />
							}
							{
								!loading && <SalesOrderTable 
								endUsersAccounts={invoices}
								selectedView={selectedView}
								gotoDetailsPage={gotoDetailsPage}
								refresh={refreshList}
								viewContractStatusPermission = { viewContractStatusPermission }
								createContractStatusPermission = { createContractStatusPermission }
								updateContractStatusPermission = { updateContractStatusPermission }
								deleteContractStatusPermission = { deleteContractStatusPermission }
							/>
							}
						</div>
						<AddModelDialog getContractStatus = {getContractStatus}/>
					</>
				}
				innerScroll
			/>
		</>
	)
	
}


export default withReducer('contractStatusApp', reducer)(CardedFullWidth2TabbedSample);
