import _ from '@lodash';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { useDispatch } from 'react-redux';
import { useRouter } from '@fuse/hooks';
import { setContractId } from '../../../../contracts/store/filterParamsReducer'
import { getContractStatus } from 'helper/status';

const useStyles = makeStyles({
    layoutRoot: {},
    head: {
        position: "sticky",
        top: 0
    },
    root: {
        // minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"25px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

function ContractsTable({assetDetails}) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const router = useRouter()
    const [contractData,setContractData] = useState(null)
    const [loading,setLoading] = useState(true)

    function redirectToContractDetailsPage(params){
        dispatch(setContractId(params.id))
        router.push('/contracts')
    }

    useEffect(()=>{
        if(!assetDetails) return
        axios.get(`/assets/${assetDetails.id}/contarct`).then((res)=>{
            setContractData(res.data.data.asset_contact.contract_header)
        }).catch((err)=>{
            console.log('err',err);
        }).finally(()=>{
            setLoading(false)
        })

    },[assetDetails])

    if(loading){
		return (
            <div style={{height: 290}}>
                <FuseLoading/>
            </div>
        )
	}

    return (
        <>
            <div className='cstm_aggridtable_holder'>
				<table>
					<thead>
						<tr>
							<th>Contract Name</th>
							<th>Contract Number</th>
							<th>Status</th>
							<th>Contract Type</th>
							<th>Service Provider Name</th>
							<th>Service Level Agreement</th>
							<th>Start Date</th>
                            <th>End Date</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{contractData?.contract_name ?? 'N/A'}</td>
                            <td>
                                <a style={{color: "black",cursor: 'pointer'}} onClick={()=>redirectToContractDetailsPage(contractData)}>{contractData?.entity_external_platform_id}</a>
                            </td>
                            <td>
                                <span>
                                    {contractData?.contract_end_date ? getContractStatus(contractData?.contract_end_date)?.status : 'N/A'}
                                </span>
                            </td>
                            <td>{contractData?.contract_headers_type?.display_name ?? 'N/A'}</td>
                            <td>{contractData?.contract_service_provider?.service_provider_name ?? 'N/A'}</td>
                            <td>{contractData?.service_level_agreement?.sla_name ?? 'N/A'}</td>
                            <td>{contractData?.contract_start_date ? dateReformatTo_mmm_dd_yyyy(contractData.contract_start_date) : 'N/A'}</td>
                            <td>{contractData?.contract_end_date ? dateReformatTo_mmm_dd_yyyy(contractData.contract_end_date) : 'N/A'}</td>
						</tr>
					</tbody>
				</table>
			</div>
            {/* <div className='flex flex-col text-center overflow-auto w-full' style={{height: 290}}>
                {
                    Boolean(contractData) ? 
                        <>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Contract Number</Typography>
                                    <Typography style={{fontWeight:400}} variant="subtitle1"> */}
                                        {/* {contractData?.entity_external_platform_id ?? 'N/A'} */}
                                        {/* <a style={{color: "black",cursor: 'pointer'}} onClick={()=>redirectToContractDetailsPage(contractData)}>{contractData?.entity_external_platform_id}</a>
                                    </Typography>
                                </div>

                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Contract Name</Typography>
                                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractData?.contract_name ?? 'N/A'}</Typography>
                                </div>


                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Contract Type</Typography>
                                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractData?.contract_headers_type?.display_name ?? 'N/A'}</Typography>
                                </div>

                            </div>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Start Date</Typography>
                                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractData?.contract_start_date ? dateReformatTo_mmm_dd_yyyy(contractData.contract_start_date) : 'N/A'}</Typography>
                                </div>

                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">End Date</Typography>
                                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractData?.contract_end_date ? dateReformatTo_mmm_dd_yyyy(contractData.contract_end_date) : 'N/A'}</Typography>
                                </div>

                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Status</Typography>
                                    <span style={{
                                        display: 'flex',
                                        gap: '6px',
                                        alignItems: 'center'
                                    }}>
                                        <span style={{
                                            height:'10px',
                                            width:'10px',
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            backgroundColor: contractData?.contract_header_status?.status_color?.code
                                        }}></span>
                                        <Typography style={{fontWeight:400}} variant="subtitle1">{contractData?.contract_header_status?.contract_status_name ?? 'N/A'}</Typography>
                                    </span>
                                </div>

                                

                            </div>
                            <div className={classes.row}>

                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Service Provider Name</Typography>
                                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractData?.contract_service_provider?.service_provider_name ?? 'N/A'}</Typography>
                                </div>

                                <div className={classes.column}>
                                    <Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Service Level Agreement</Typography>
                                    <Typography style={{fontWeight:400}} variant="subtitle1">{contractData?.service_level_agreement?.sla_name ?? 'N/A'}</Typography>
                                </div>

                                <div className={classes.column}></div>

                            </div>
                        </>  : 
                        <Typography variant="h6" style={{
                            height: 290,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>{'No contract currently available'}</Typography>
                }
            </div> */}
        </>
    )
}

export default withRouter(ContractsTable)
