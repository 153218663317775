// import Page from './index';
import { authRoles } from 'app/auth';
import { lazy } from 'react';
export default {
	settings: {
		layout: {
			config: {
				footer:{
					display:false
				}
			}
		}
	},
	auth: authRoles.agentAndAdmin,
	routes: [
		{
			path: '/dashboard',
			exact: true,
			component: lazy(() => import('./index'))
		}
	]
};