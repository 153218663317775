import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, AccordionActions } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';

const useStyles = makeStyles({
    layoutRoot: {},
    head: {
        position: "sticky",
        top: 0
    },
    root: {
        // minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function setHeaderName(header) {
    if (header == 'company_id') {
        return 'Company ID'
    } else {
        return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }
}

function getHeaderName(header) {

    const isObject = typeof header == "object";

    if (!isObject) return setHeaderName(header);

    return header.label ? header.label : ""
}

function getFieldName(header) {

    const isObject = typeof header == "object";

    if (!isObject) return header;

    return header.field ? header.field : ""
}

function ListView(props) {
    const classes = useStyles();

    const { rows, onRowClicked, columns, table_name } = props;

    const columnHeaders = columns ? columns : Object.keys(rows[0]);

    const[ windowHeight, setWindowHeight] = useState(window.innerHeight - 100)

    function onGridReady (params){
        loadInitialState(params);
    }

    function onGridSizeChanged(){
        setWindowHeight(window.innerHeight - 180)
    }

    function onFilterChanged(event){
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

    function loadInitialState (params){
        if(!table_name) return;
        axios.get(`/user-view/show-view-order?table_name=${table_name}`)
        .then(results=>{
            const { data } = results.data;
            const order = JSON.parse(data.order);
            params.columnApi.applyColumnState(order);
        })
        .catch(err=>{
            console.log(err);
        })
    }

    
    function saveView(rows){
        if(!table_name) return;
        const order = JSON.stringify(rows);
        axios.post('/user-view/save-view-order',{
            table_name : table_name,
            order : order
        })
        .then(results=>{
            console.log('view updated');
        })
        .catch(err=>{
            console.log(err);
        })
    }


    function onColumnMoved (params) {
        const rows = params.columnApi.getColumnState();
        saveView(rows);
    }

    function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

    return (
        <div className="ag-theme-material" style={{ height: windowHeight, width: "100%" }}>
            <AgGridReact
                onGridReady = { onGridReady }
                suppressDragLeaveHidesColumns={true}
                // domLayout={'autoHeight'}
                onGridSizeChanged={onGridSizeChanged}
                rowData={rows}
                overlayNoRowsTemplate={
					'<span style="text-align: center">No rows found</span>'
				}
				onFilterChanged = { onFilterChanged }	

                onColumnMoved = { onColumnMoved }

                onColumnVisible = { onColumnVisible }
                defaultColDef={{
                    resizable: true
                }}
                >
                {columnHeaders.map((header) => {

                    const cellRendererProps = header.cellRenderer ? {
                        cellRenderer: function (params){
                           return header.cellRenderer(params, params.node.data[header.field])
                        }
                    } : {};

                    const cellClickedProps = {
                        onCellClicked: function (params){
                            typeof header.cellClicked == "function"
                            ? header.cellClicked(params, params.node.value) 
                            : typeof onRowClicked == "function" && onRowClicked(params, params.node.value)
                        },
                    }
                    
                    return (
                        <AgGridColumn
                            key={header + "1"}
                            field={getFieldName(header)}
                            headerName={getHeaderName(header)}
                            sortable={true}
                            filter={true}
                            floatingFilter={true}
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                            {...cellRendererProps}
                            {...cellClickedProps}
                            filter="agTextColumnFilter"
                        ></AgGridColumn>
                    );
                })}
            </AgGridReact>
        </div>
    );
}

export default ListView
