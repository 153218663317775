import { jsPDF } from "jspdf";
import 'jspdf-autotable'

function generatePdf(dataArray, fileName){
  const doc = new jsPDF();

  // Define columns and rows
  let rows = []
  const columns = dataArray[0];
  if(fileName == 'asset_pdf') rows = dataArray.slice(1);
  else rows = dataArray.slice(1);
  createTable(doc, columns, rows, fileName);
  doc.save(`${fileName}.pdf`);

}

function createTable(doc, columns, rows, fileName) {
  let columnStyle = {}
  if(fileName == 'asset_pdf') columnStyle = {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 10 },
        3: { cellWidth: 10 },
        4: { cellWidth: 10 },
        5: { cellWidth: 10 },
        6: { cellWidth: 10 },
        7: { cellWidth: 10 },
        8: { cellWidth: 10 },
        9: { cellWidth: 10 },
        10: { cellWidth: 10 },
        11: { cellWidth: 10 },
        12: { cellWidth: 10 },
        13: { cellWidth: 10 },
        14: { cellWidth: 10 },
        15: { cellWidth: 10 },
        16: { cellWidth: 10 },
        17: { cellWidth: 10 },
    // // Add more column styles as needed
  }
  if(fileName == 'service_ticket_pdf')  columnStyle = {10: { cellWidth: 20 },2: { cellWidth: 20 }}
  // const columnWidths = [50, 30, 50]; // Adjust column widths as needed

  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 10,
    styles: {
      overflow: 'linebreak',
      fontSize: 2,
      cellPadding: 2,
    },
    columnStyles: columnStyle
    // margin: { top: 10 }
  });
}

export default generatePdf;