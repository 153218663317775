import axios from "@fuse/utils/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Modal,
  TextField,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
  MenuItem,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import useToast from "@fuse/hooks/useToast";
import { countries } from "@fuse/utils/countryName";
import CustomDatePicker from "@fuse/components/customDatePicker";

//Quick add componenets related imports
import QuickAddContractStatus from "@fuse/components/Modal/QuickAddContractStatus";
import QuickAddLocation from "@fuse/components/Modal/QuickAddLocationDialog";
import QuickAddContractType from "@fuse/components/Modal/QuickAddContractType";
import QuickAddSrviceLevelAggrement from "@fuse/components/Modal/QuickAddServiceLevelAggrement";
import QuickAddPaymentTerm from "@fuse/components/Modal/QuickAddPaymentTerm";
import QuickAddServiceProviderDialog from "@fuse/components/Modal/QuickAddServiceProvider";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subSection: {
    marginLeft: "20px !important",
    marginRight: "20px !important",
    marginBottom: "20px !important",
    padding: "10px",
    backgroundColor: "#f4f4f4",
    borderRadius: "4px",
  },
}));

function QuickAddContract(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useToast(dispatch);

  //component state
  // const [startDate,setStartDate] = useState('')
  // const [endDate,setEndDate] = useState('')
  const [shippingLocationMenu, setShippingLocationMenu] = useState([]);
  const [contractStatusMenu, setContractStatusMenu] = useState([]);
  const [serviceProviderMenu, setServiceProviderMenu] = useState([]);
  const [paymentTermMenu, setPaymentTermMenu] = useState([]);
  const [srviceLevelAggrementMenu, setSrviceLevelAggrementMenu] = useState([]);
  const [contractsMenu, setContractsMenu] = useState([]);
  const [childContract, setChildContract] = useState(false);
  const [parentContractMenu, setParentContractMenu] = useState([]);
  const [currentCountry, setCurrentCountry] = useState({
    name: "United States",
    code: "US",
    currency: "USD",
  });
  const [reminderCheckboxes, setReminderCheckBoxes] = useState({
    term45: true,
    term30: false,
    supportContact: false,
  });
  const [noReminderCheckBox, setNoReminderCheckBox] = useState(false);

  //Quick add dialogs related state
  const [
    openContractStatusQuickAddDialog,
    setOpenContractStatusQuickAddDialog,
  ] = useState(false);
  const [openLocationQuickAddDialog, setOpenLocationQuickAddDialog] = useState(
    false
  );
  const [
    openContractTypeQuickAddDialog,
    setOpenContractTypeQuickAddDialog,
  ] = useState(false);
  const [
    openSrviceLevelAggrementQuickAddDialog,
    setOpenSrviceLevelAggrementQuickAddDialog,
  ] = useState(false);
  const [
    openPaymentTermQuickAddDialog,
    setOpenPaymentTermQuickAddDialog,
  ] = useState(false);
  const [
    openServiceProviderQuickAddDialog,
    setOpenServiceProviderQuickAddDialog,
  ] = useState(false);

  //Form validation schema
  const schema = yup.object({
    // contractId: yup.string().required('Contract Number is required!'),
    // contractName: yup.string().required('Contract Name is required!'),
    // contractDescription: yup.string().max(200, 'Maximum 200 characters!').nullable(),
    // contractStartDate: yup.string().required('Contract Start Date is required!'),
    // contractEndDate: yup.string().required('Contract End Date is required!'),
    // contractStatus: yup.object().nullable().required('Contract Status is required!'),
    // currentShippingLocation: yup.object().nullable().required('Location is required!'),

    // renewal: yup.string().required('Renewal Status is required!'),
    // contractType: yup.string().nullable().required('Contract Type is required!'),
    // master: Boolean(editFormState) ? yup.object().nullable() : yup.object().nullable().required('Master is required!'),
    // parentContract: yup.object().nullable(),
    // // parentContract: yup.object().nullable().when("master", (master,schema)=>{
    // // 	if(master) return schema.required('Parent Contract is required!')
    // // }),
    // provider: yup.object().nullable().required('Service Provider is required!'),
    // sla: yup.object().nullable().required('Service Level Agreement is required!'),
    support_phone_number: yup.string().when({
      is: (val) => !!val,
      then: yup
        .string()
        .matches(
          /[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
          "Phone number should be in 000-000-0000 format"
        ),
    }),
    // paymentTerm: yup.object().nullable().required('Payment Term is required!'),
    // poNumber: yup.string().required('PO Number is required!'),
    // poDate: yup.string().nullable().required('PO Date is required!'),
    // country: yup.object().nullable().required('Currency is required!'),
    // paymentAmount: yup.string().matches(/^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/, 'Enter a valid Payment Amount!').required('Payment Amount is required!'),
  });

  const defaultValues = {
    contractId: "",
    contractName: "",
    contractDescription: "",
    // contractStartDate: "",
    // contractEndDate: "",
    contractStatus: null,
    currentShippingLocation: null,
    contractType: "",
    renewal: "",
    master: {},
    parentContract: null,
    srviceLevelAggrement: null,
    paymentAmount: "",
    country: { name: "United States", code: "US", currency: "USD" },
    paymentTerm: null,
    poNumber: "",
    // poDate: "",
    provider: null,
    support_phone_number: "",
  };

	const [contractStartDate,setContractStartDate] = useState({
		date: null,
		error: false
	})
	const [contractEndDate,setContractEndDate] = useState({
		date: null,
		error: false
	})
	const [poDate,setPoDate] = useState({
		date: null,
		error: false
	})

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  function manageContarctData(formData) {
    // console.log('manageStatusData: ',formData);
    if (!formData.master?.value && !formData.parentContract) {
      toast.error("Parent Contract required!");
      return;
    }

    if (
      reminderCheckboxes.supportContact === true &&
      formData.support_phone_number === ""
    ) {
      toast.error("Contact number is required for text messages");
      return;
    }

    if (formData.contractDescription.length > 200) {
			toast.error('Description should be maximum 200 characters')
			return
		}

    let payload = {
      contract_id: formData.contractId,
      contract_name: formData.contractName,
      contract_description: formData.contractDescription,
      // contract_start_date: formData.contractStartDate,
      // contract_end_date: formData.contractEndDate,
			contract_start_date: contractStartDate.date ?? '',
			contract_end_date: contractEndDate.date ?? '',
      contract_status: formData.contractStatus,
      shipping_id: formData.currentShippingLocation.id,
      contract_type: formData.contractType,
      master: formData.master?.value,
      parent_contract_id: formData.master?.value
        ? 0
        : formData.parentContract?.id,
      contract_description: formData.contractDescription,
      provider: formData.provider,
      sla: formData.srviceLevelAggrement,
      renewal: formData.renewal,
      support_phone_number: formData.support_phone_number ?? null,
      poNumber: formData.poNumber,
      // poDate: formData.poDate,
			poDate: poDate.date ?? '',
      country: formData.country,
      no_reminder: noReminderCheckBox,
      reminder: {
        term45: reminderCheckboxes.term45,
        term30: reminderCheckboxes.term30,
      },
      textMessage: reminderCheckboxes.supportContact,
      paymentAmount: parseFloat(formData.paymentAmount).toFixed(2).toString(),
      paymentTerm: formData.paymentTerm,
    };
    // console.log('payload: ',payload);
    axios
      .post("/contracts/add-contract-manually", payload)
      .then((response) => {
        if (response.status == 201) {
          toast.error(response.data.message);
        } else {
          toast.success("Contract Added Successfully.");
          close(response.data.data.created_contract);
        }
      })
      .catch((err) => {
        console.log("212=>", err);
        toast.error("Something went wrong!");
      });
  }

  function contractFormErrorData(error) {
    console.log("contractFormErrorData: ", error);
  }

  function close(new_contarct = null) {
    reset(defaultValues);
		setContractStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setContractEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setPoDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
    props.handleCloseDialog(new_contarct);
  }

  //Define functions
  const handleCurrentCountry = (countryCode) => {
    const current = countries.filter(
      (country) => country.code === countryCode
    )[0];
    setCurrentCountry(current);
  };

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  const handleChangeNoReminderCheckbox = () => {
    setNoReminderCheckBox(!noReminderCheckBox);
  };

  const handleChangeReminderCheckBoxes = (event) => {
    if (
      (event.target.name === "term45" &&
        event.target.checked === false &&
        reminderCheckboxes.term30 === false) ||
      (event.target.name === "term30" &&
        event.target.checked === false &&
        reminderCheckboxes.term45 === false)
    ) {
      setReminderCheckBoxes({
        ...reminderCheckboxes,
        [event.target.name]: event.target.checked,
        supportContact: false,
      });
      setValue("support_phone_number", "");
      setNoReminderCheckBox(true);
    } else {
      setReminderCheckBoxes({
        ...reminderCheckboxes,
        [event.target.name]: event.target.checked,
      });
    }
  };

  //async calls
  const fetchAllSuggestion = () => {
    axios
      .get(`contracts/get-all-suggestion`)
      .then((response) => {
        const {
          paymentTerm,
          shipping,
          contractStatus,
          sla,
          serviceProvider,
        } = response.data.data;
        setContractStatusMenu(contractStatus);
        setPaymentTermMenu(paymentTerm);
        setServiceProviderMenu(serviceProvider);
        setSrviceLevelAggrementMenu(sla);
        setShippingLocationMenu(shipping);
      })
      .catch((err) => {
        console.log("err", err);
        //   toast.error('Something went wrong!')
      });
  };

  function fetchContractType() {
    axios
      .get(`contracts/get-contract-types`)
      .then((response) => {
        // console.log(response.data.data)
        setContractsMenu(response.data.data.map((item) => item.display_name));
      })
      .catch((err) => {
        console.log("err", err);
        // toast.error('Something went wrong!')
      });
  }

  function fetchParentContract() {
    axios
      .get(`contracts/get-parent-contracts`)
      .then((response) => {
        // console.log(response.data.data)
        setParentContractMenu(response.data.data);
      })
      .catch((err) => {
        console.log("err", err);
        // toast.error('Something went wrong!')
      });
  }

  useEffect(() => {
    fetchAllSuggestion();
    fetchContractType();
    fetchParentContract();
  }, []);

  /**Quick Add Contarct Status */
  function handleContractStatusCloseDialog(new_status = null) {
    if (new_status) {
      setValue("contractStatus", new_status);
    }
    setOpenContractStatusQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openContractStatusQuickAddDialog) {
    return (
      <QuickAddContractStatus
        open={openContractStatusQuickAddDialog}
        handleCloseDialog={handleContractStatusCloseDialog}
      />
    );
  }

  /**Quick Add Location */
  function handleLocationCloseDialog(new_location = null) {
    if (new_location) {
      setValue("currentShippingLocation", new_location);
    }
    setOpenLocationQuickAddDialog(false);
  }

  if (openLocationQuickAddDialog) {
    return (
      <QuickAddLocation
        fetchLocationSuggestions={fetchAllSuggestion}
        createNewShippingLocation={openLocationQuickAddDialog}
        handleCloseLocationDialog={handleLocationCloseDialog}
      />
    );
  }

  /**Quick Add Contract Type */
  function handleContractTypeCloseDialog(new_contarct_type) {
    if (new_contarct_type) {
      setValue("contractType", new_contarct_type);
    }
    setOpenContractTypeQuickAddDialog(false);
    fetchContractType();
  }

  if (openContractTypeQuickAddDialog) {
    return (
      <QuickAddContractType
        open={openContractTypeQuickAddDialog}
        handleCloseDialog={handleContractTypeCloseDialog}
      />
    );
  }

  /**Quick Add Service Level Agreement */
  function handlSrviceLevelAggrementCloseDialog(
    new_service_level_aggrement = null
  ) {
    if (new_service_level_aggrement) {
      setValue("srviceLevelAggrement", new_service_level_aggrement);
    }
    setOpenSrviceLevelAggrementQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openSrviceLevelAggrementQuickAddDialog) {
    return (
      <QuickAddSrviceLevelAggrement
        open={openSrviceLevelAggrementQuickAddDialog}
        handleCloseDialog={handlSrviceLevelAggrementCloseDialog}
      />
    );
  }

  /**Quick Add Payment Term */
  function handlPaymentTermCloseDialog(new_payment_term) {
    if (new_payment_term) {
      setValue("paymentTerm", new_payment_term);
    }
    setOpenPaymentTermQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openPaymentTermQuickAddDialog) {
    return (
      <QuickAddPaymentTerm
        open={openPaymentTermQuickAddDialog}
        handleCloseDialog={handlPaymentTermCloseDialog}
      />
    );
  }

  /**Quick Add Service Provider*/
  function handlServiceProviderCloseDialog(new_service_provider) {
    if (new_service_provider) {
      setValue("provider", new_service_provider);
    }
    setOpenServiceProviderQuickAddDialog(false);
    fetchAllSuggestion();
  }

  if (openServiceProviderQuickAddDialog) {
    return (
      <QuickAddServiceProviderDialog
        open={openServiceProviderQuickAddDialog}
        handleCloseDialog={handlServiceProviderCloseDialog}
      />
    );
  }

  const handleChangeTextMeOption = (event) => {
    if (
      reminderCheckboxes.term30 === true ||
      reminderCheckboxes.term45 === true
    ) {
      setReminderCheckBoxes({
        ...reminderCheckboxes,
        supportContact: event.target.checked,
      });
    }

    if (event.target.checked === false) {
      setValue("support_phone_number", "");
    }
  };

  return (
    <Modal
      open={props.open}
      // onClose={close}
      className={classes.modal}
    >
      <div
        style={{
          width: 700,
          backgroundColor: "white",
          borderRadius: 9,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          maxHeight: "98vh",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <h1>Add Contract</h1>
          <IconButton
            onClick={() => {
              close();
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>

        <form
          onSubmit={handleSubmit(manageContarctData, contractFormErrorData)}
        >
          {/*---------------Contract Description*---------*/}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary style={{ pointerEvents: "none" }}>
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Contract Description</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    marginRight: "10px",
                    width: "100%",
                  }}
                >
                  <Controller
                    name="contractId"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Contract Number"
                          fullWidth
                          inputProps={{ readOnly: false }}
                          required
                          style={{ backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  />
                </div>

                <div style={{ marginBottom: "10px", width: "100%" }}>
                  <Controller
                    name="contractName"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Contract Name"
                          fullWidth
                          required
                          style={{ backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="contractDescription"
                  className="mb-16"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextareaAutosize
                        {...field}
                        variant="outlined"
                        label="Contract Description"
                        placeholder="Contract Description"
                        fullWidth
                        aria-label="maximum height"
                        className="customTextArea"
                        style={{
                          backgroundColor: "#fff",
                          width: "100%",
                          border: "1px solid #aaa",
                          borderRadius: "5px",
                          height: "100px",
                          padding: "12px 13px",
                        }}
                      />
                    </>
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <div style={{ marginRight: "10px", width: "100%" }}>
                  {/* <Controller
                    name="contractStartDate"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Start Date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            setStartDate(event.target.value);
                          }}
                          fullWidth
                          required
                          inputProps={{ max: `${endDate}` }}
                          style={{ width: "100%", backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  /> */}
									<CustomDatePicker
										state={contractStartDate}
										setState={setContractStartDate}
										required={true}
										label='Contract Start Date*'
										maxDate={contractEndDate.date}
										condition='Contract End Date'									
									/>
                </div>

                <div style={{ width: "100%" }}>
                  {/* <Controller
                    name="contractEndDate"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="End Date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            setEndDate(event.target.value);
                          }}
                          required
                          inputProps={{ min: `${startDate}` }}
                          style={{ width: "100%", backgroundColor: "#fff" }}
                        />
                      </>
                    )}
                  /> */}
									<CustomDatePicker
										state={contractEndDate}
										setState={setContractEndDate}
										required={true}	
										label='Contract End Date*'
										minDate={contractStartDate.date}
										condition='Contract Start Date'											
									/>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginBottom: "10px", width: "100%" }}>
                  <Controller
                    name="contractStatus"
                    className="mb-16"
                    control={control}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={contractStatusMenu}
                          disablePortal
                          onChange={(event, value) => {
                            field.onChange(value);
                          }}
                          PaperComponent={({ children }) => (
                            <Paper style={{ marginTop: "20px" }}>
                              {children}
                              <Button
                                style={{ width: "100%" }}
                                variant="outlined"
                                color="primary"
                                onMouseDown={() =>
                                  setOpenContractStatusQuickAddDialog(true)
                                }
                              >
                                Add Contract Status
                              </Button>
                            </Paper>
                          )}
                          getOptionLabel={(option) =>
                            option.contract_status_name ?? ""
                          }
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="contractStatus"
                                  label="Contract Status"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  inputRef={ref}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginBottom: "10px", width: "100%" }}>
                  <Controller
                    name="currentShippingLocation"
                    className="mb-16"
                    control={control}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={shippingLocationMenu}
                          disablePortal
                          onChange={(event, value) => {
                            field.onChange(value);
                          }}
                          PaperComponent={({ children }) => (
                            <Paper>
                              {children}
                              <Button
                                style={{ width: "100%" }}
                                variant="outlined"
                                color="primary"
                                onMouseDown={() =>
                                  setOpenLocationQuickAddDialog(true)
                                }
                              >
                                Add Location
                              </Button>
                            </Paper>
                          )}
                          getOptionLabel={(option) =>
                            `${option.location_name} - ${option.address1}, ${option.city}, ${option.state}, ${option.country}`
                          }
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="currentShippingLocation"
                                  label="Location"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/*---------------Contract Type------------------*/}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Contract Type</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <>
                    <Controller
                      name="contractType"
                      className="mb-16"
                      control={control}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            {...field}
                            style={{ width: "100%" }}
                            options={contractsMenu}
                            disablePortal
                            // getOptionLabel={option => option.display_name }
                            onChange={(event, value) => field.onChange(value)}
                            PaperComponent={({ children }) => (
                              <Paper style={{ marginTop: "50px" }}>
                                {children}
                                <Button
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  color="primary"
                                  onMouseDown={() =>
                                    setOpenContractTypeQuickAddDialog(true)
                                  }
                                >
                                  Add Contract Type
                                </Button>
                              </Paper>
                            )}
                            renderInput={(params) => {
                              return (
                                <div ref={params.InputProps.ref}>
                                  <TextField
                                    {...params}
                                    name="contractType"
                                    label="Contract Type"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    inputRef={ref}
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </>
                      )}
                    />
                  </>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="renewal"
                  className="mb-16"
                  control={control}
                  render={({ field }) => {
                    // console.log('1914=>',field)
                    return (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          select
                          label="New/Renew"
                          fullWidth
                          required
                          style={{ width: "100%", backgroundColor: "#fff" }}
                        >
                          {["New", "Renew"].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    );
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="master"
                  className="mb-16"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                          disablePortal
                          getOptionLabel={(option) => `${option?.label ?? ""}`}
                          onChange={(event, value) => {
                            // console.log('622: ',value);
                            setChildContract(!value.value);
                            field.onChange(value);
                          }}
                          PaperComponent={({ children }) => (
                            <Paper style={{ marginTop: "50px" }}>
                              {children}
                            </Paper>
                          )}
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="master"
                                  label="Master"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  inputRef={ref}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </>
                    );
                  }}
                />
              </div>

              {childContract && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Controller
                    name="parentContract"
                    className="mb-16"
                    control={control}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: "100%" }}
                          options={parentContractMenu}
                          disablePortal
                          getOptionLabel={(option) =>
                            `${option.entity_external_platform_id}-${option.contract_name}`
                          }
                          onChange={(event, value) => field.onChange(value)}
                          PaperComponent={({ children }) => (
                            <Paper style={{ marginTop: "50px" }}>
                              {children}
                            </Paper>
                          )}
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  name="parentContract"
                                  label="Parent Contract"
                                  variant="outlined"
                                  fullWidth
                                  inputRef={ref}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </div>
                            );
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {/*------------Service Level Agreement-----------*/}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Service Level Agreement</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="srviceLevelAggrement"
                  className="mb-16"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: "100%" }}
                        options={srviceLevelAggrementMenu}
                        disablePortal
                        getOptionLabel={(option) => option.sla_name ?? ""}
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginTop: "50px" }}>
                            {children}
                            <Button
                              style={{ width: "100%" }}
                              variant="outlined"
                              color="primary"
                              onMouseDown={() =>
                                setOpenSrviceLevelAggrementQuickAddDialog(true)
                              }
                            >
                              Add Service Level Agreement
                            </Button>
                          </Paper>
                        )}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                {...params}
                                name="srviceLevelAggrement"
                                label="Service Level Agreement"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={ref}
                                style={{
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/*----------------------Payment-----------------*/}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Payment</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="w-full">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginRight: "10px",
                      width: "100%",
                    }}
                  >
                    <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                      <Controller
                        name="paymentAmount"
                        className="mb-16"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              variant="outlined"
                              label="Amount"
                              fullWidth
                              required
                              style={{ width: "100%", backgroundColor: "#fff" }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment>
                                    <Icon style={{ opacity: "0.4" }}>
                                      attach_money_outlined
                                    </Icon>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </>
                        )}
                      />
                    </div>

                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      <Controller
                        name="country"
                        className="mb-16"
                        control={control}
                        render={({
                          field: { ref, ...field },
                          fieldState: { error },
                        }) => (
                          <>
                            <Autocomplete
                              {...field}
                              style={{ width: "100%" }}
                              options={countries}
                              disablePortal
                              getOptionLabel={(option) => option.currency ?? ""}
                              onChange={(event, value) => {
                                field.onChange(value);
                                if (value) handleCurrentCountry(value.code);
                              }}
                              PaperComponent={({ children }) => (
                                <Paper style={{ marginTop: "50px" }}>
                                  {children}
                                </Paper>
                              )}
                              renderInput={(params) => {
                                return (
                                  <div ref={params.InputProps.ref}>
                                    <TextField
                                      {...params}
                                      name="country"
                                      label="Currency"
                                      variant="outlined"
                                      fullWidth
                                      required
                                      inputRef={ref}
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#fff",
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment
                                            style={{ marginRight: "10px" }}
                                          >
                                            <img
                                              loading="lazy"
                                              width="40"
                                              src={`https://flagcdn.com/w20/${currentCountry.code.toLowerCase()}.png`}
                                              srcSet={`https://flagcdn.com/w40/${currentCountry.code.toLowerCase()}.png 2x`}
                                              alt=""
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                );
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <Controller
                      name="paymentTerm"
                      className="mb-16"
                      control={control}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            {...field}
                            style={{ width: "100%" }}
                            options={paymentTermMenu}
                            disablePortal
                            getOptionLabel={(option) =>
                              option.display_name ?? ""
                            }
                            onChange={(event, value) => field.onChange(value)}
                            PaperComponent={({ children }) => (
                              <Paper>
                                {children}
                                <Button
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  color="primary"
                                  onMouseDown={() =>
                                    setOpenPaymentTermQuickAddDialog(true)
                                  }
                                >
                                  Add Payment Term
                                </Button>
                              </Paper>
                            )}
                            renderInput={(params) => {
                              return (
                                <div ref={params.InputProps.ref}>
                                  <TextField
                                    {...params}
                                    name="paymentTerm"
                                    label="Payment Term"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    inputRef={ref}
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Controller
                  name="poNumber"
                  className="mb-16"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        variant="outlined"
                        label="PO Number"
                        fullWidth
                        required
                        style={{
                          width: "100%",
                          marginRight: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </>
                  )}
                />

                {/* <Controller
                  name="poDate"
                  className="mb-16"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        variant="outlined"
                        label="PO Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        fullWidth
                        required
                        style={{ width: "100%", backgroundColor: "#fff" }}
                      />
                    </>
                  )}
                /> */}
								<CustomDatePicker
									state={poDate}
									setState={setPoDate}
									required={true}
									label='PO Date*'										
								/>	
              </div>
            </AccordionDetails>
          </Accordion>

          {/*---------------Provider---------------------- */}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Service Provider</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Controller
                  name="provider"
                  className="mb-16"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: "100%" }}
                        options={serviceProviderMenu}
                        disablePortal
                        getOptionLabel={(option) =>
                          option.service_provider_name ?? ""
                        }
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginTop: "20px" }}>
                            {children}
                            <Button
                              style={{ width: "100%" }}
                              variant="outlined"
                              color="primary"
                              onMouseDown={() =>
                                setOpenServiceProviderQuickAddDialog(true)
                              }
                            >
                              Add Service Provider
                            </Button>
                          </Paper>
                        )}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                {...params}
                                name="provider"
                                label="Service Provider"
                                variant="outlined"
                                fullWidth
                                required
                                inputRef={ref}
                                style={{
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/*---------------Reminder---------------------- */}
          <Accordion defaultExpanded={true} className={classes.subSection}>
            <AccordionSummary
              style={{ pointerEvents: "none" }} /*expandIcon={<ExpandMore />}*/
            >
              <div style={{ width: "100%" }}>
                <Typography variant="h6">Reminder</Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                }}
              >
                <Typography style={{ fontSize: "17px" }}>
                  Dalos will notify you automatically 60 days before contract
                  expiration.
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={noReminderCheckBox}
                  onChange={handleChangeNoReminderCheckbox}
                  disabled={
                    reminderCheckboxes.supportContact === true ||
                    reminderCheckboxes.term30 === true ||
                    reminderCheckboxes.term45 === true
                  }
                />
                <Typography style={{ fontSize: "17px" }}>
                  Do not remind me
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  disabled={noReminderCheckBox === true}
                  name={"term45"}
                  checked={reminderCheckboxes.term45}
                  onChange={handleChangeReminderCheckBoxes}
                />
                <Typography style={{ fontSize: "17px" }}>
                  Notify me 45 days
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  disabled={noReminderCheckBox === true}
                  name={"term30"}
                  checked={reminderCheckboxes.term30}
                  onChange={handleChangeReminderCheckBoxes}
                />
                <Typography style={{ fontSize: "17px" }}>
                  Notify me 30 days
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  disabled={
                    noReminderCheckBox === true ||
                    (reminderCheckboxes.term45 === false &&
                      reminderCheckboxes.term30 === false)
                  }
                  name={"supportContact"}
                  checked={reminderCheckboxes.supportContact}
                  onChange={handleChangeTextMeOption}
                />
                <div className="flex items-center" style={{ width: "60%" }}>
                  <Typography
                    style={{
                      fontSize: "17px",
                      marginRight: "10px",
                      width: "30%",
                    }}
                  >
                    Text Me
                  </Typography>
                  <Controller
                    name="support_phone_number"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Support Phone Number*"
                          fullWidth
                          onKeyPress={(event) => {
                            if (!/[0-9/-]+/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          style={{ width: "100%", backgroundColor: "#fff" }}
                          disabled={
                            noReminderCheckBox === true ||
                            reminderCheckboxes.supportContact === false
                          }
                          error={!!errors.support_phone_number}
                          helperText={errors?.support_phone_number?.message}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <div style={{ display: "flex", gap: "20px", paddingTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ borderRadius: "4px" }}
              type="submit"
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ borderRadius: "4px" }}
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default QuickAddContract;
