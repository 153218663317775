import React, { useEffect } from 'react';
import { useState } from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icon, Menu, MenuItem } from '@material-ui/core';
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const buttonSize = 20

const useStyles = makeStyles(theme => ({
root: {
},
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  btn: {
      borderRadius: '50%',
      padding: 0,
      maxWidth: buttonSize,
      maxHeight: buttonSize,
      minWidth: buttonSize,
      minHeight: buttonSize
  },
  pageValueDetailLeft: {
    paddingRight: 10,
    borderRight: `1px solid ${theme.palette.action.selected}`,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  pageValueDetailRight: {
    paddingLeft: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  rowsPerPagesContainer: {
    padding: 2,
    border: `1px solid ${theme.palette.action.selected}`,
    borderRadius: 5,
    marginLeft: 5,
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  paginationContainer: {
    display: 'flex', 
    alignItems: 'center',
    background: theme.palette.action.selected,
    borderRadius: 20,
    overflow: 'hidden'
  },
  selectedPage: {
    background: '#07429E',
    "&:hover, &:focus": {
      backgroundColor: emphasize('#07429E', 0.08)
    },
    "&:active": {
      backgroundColor: emphasize('#07429E',0.12)
    },
    color: theme.palette.common.white
  }
}));

export default function TablePagination({count, page, onChangePage, onRowsPerPageChange, rowsPerPage,showRowsPerPage =true, returnExact=false}) {
  const classes = useStyles();

  // const handleChangePage = (event, page) => {
  //   onChangePage(event, page - 1)
  // }

  const { items } = usePagination({
    count: Math.ceil(count / (rowsPerPage === 'all' ? count : rowsPerPage)),
    page: page + 1
  });

  const [anchorEl, setAnchor] = useState(null)
  const [rowsPerPages, setRowsPerPages] = useState([10, 20, 25, 50, 100, 'all'])
  
  const calculateCountsPerPage = (count) => {
    const counts = [];
    let countIn = count;
    while (countIn > rowsPerPage) {
      counts.push(rowsPerPage);
      countIn -= rowsPerPage
    }
    if(countIn > 0){
      counts.push(countIn)
    }
    return counts
  }

  const [countsPerPage, setCountsPerPage] = useState(calculateCountsPerPage(count))
  const handleRowsPerPageSelect = (event, rowsPerPage) => {
    // if(rowsPerPage == 'all') rowsPerPage = count;
    onRowsPerPageChange(returnExact ? rowsPerPage : rowsPerPage !='all' ? rowsPerPage : count)
  }

  useEffect(()=>{
    setCountsPerPage(calculateCountsPerPage(count))
  },[count, rowsPerPage])

  return (
    <div className={classes.root}>
    {/* <nav> */}
    <Menu
        id="page-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={()=>setAnchor(null)}
      >
        {rowsPerPages.map((rpp, index) => (
          <MenuItem
            value={rpp}
            key={index}
            selected={rowsPerPage === rpp}
            onClick={(event) => {
              handleRowsPerPageSelect(event, rpp)
              setAnchor(null)
            }}
          >
            {rpp}
          </MenuItem>
        ))}
      </Menu>
      <ul className={classes.ul}>
        <li style={{marginRight: 'auto', display: 'flex',fontSize: 12}}>
          {showRowsPerPage &&
          <span className={classes.pageValueDetailLeft}>
            Show 
            <span 
              className={classes.rowsPerPagesContainer} 
              onClick={e => setAnchor(e.currentTarget)}
            >
              {rowsPerPage}
              <Icon style={{margin:0, padding:0}}>
                arrow_drop_down
              </Icon>
            </span>
             entires
          </span>
          }
          {rowsPerPage === 'all' ?
            <span className={classes.pageValueDetailRight}>
              Showing 1 to {count} of {count} entries
            </span>
            :
            <span className={classes.pageValueDetailRight}>
              Showing {page * rowsPerPage + 1} to {(page * rowsPerPage) + (countsPerPage[page] ? countsPerPage[page] : 0)} of {count} entries
            </span>
          }
        </li>
        <span className={classes.paginationContainer}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <Button 
              {...item}
              onClick={(e)=>onChangePage(e,page - 1)}
              className={`${classes.btn} ${selected ? classes.selectedPage : ''}`}>
                {page}
              </Button>
            );
          } else {
            children = (
              <Button 
               variant="text" {...item} onClick={(e)=>{onChangePage(e, page-1)}} 
               className={`p-0 ${type === 'previous' && 'pr-10'}`}
               style={{borderRadius: 0}}
               >
                {
                  type === 'previous' &&
                  <Icon>arrow_left</Icon>
                }
                {type}
                {
                  type === 'next' &&
                  <Icon>arrow_right</Icon>
                }
              </Button>
            );
          }
          return <li key={index}>{children}</li>;
        })}
        </span>
      </ul>
    {/* </nav> */}
    </div>
  );
}