import React, {useEffect,useState} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { withRouter } from 'react-router-dom';
import RenderHtml from '@fuse/components/RenderHtml';
import { formatPrice } from '@fuse/utils/currencyFormat';

const useStyles = makeStyles({
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"25px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

const Accordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
  	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
  	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

function InformationTab({assetDetails}) {
	const [expanded, setExpanded] = React.useState('panel1');
	const classes = useStyles();
	const [informationData,setInformationData] = useState({})

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	function truncateString(notes){
		let str
		if(notes === undefined || notes === null) return
		if(notes != ''){
			if(notes.length > 20){
				str = `${notes.slice(0,21)}...`
			}else{
				str = notes
			}
		}else{
			str = 'N/A'
		}
		return str
	}

	useEffect(()=>{
		if(!assetDetails) return
		axios.get(`/assets/${assetDetails.id}/information?model_id=${assetDetails.model_id}`).then((res)=>{
			setInformationData(res.data.data.asset)
		}).catch((err)=>{
			console.log('error',err)
		})
	},[assetDetails])


	return (
		<>
			<div className='cstm_aggridtable_holder'>
				<table>
					<thead>
						<tr>
							<th>Purchase Date</th>
							<th>Purchase Number</th>
							<th>Price</th>
							<th>End of Service Life</th>
							<th>End of Life</th>
							<th>Deployed Date</th>
							<th>Recovery Date</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{informationData.purchase_date ? dateReformatTo_mmm_dd_yyyy(informationData.purchase_date) : 'N/A'}</td>
							<td>{informationData.order_number ?? 'N/A'}</td>
							<td>{informationData.purchase_cost ? formatPrice(Number(informationData.purchase_cost)) : 'N/A'}</td>
							<td>{informationData?.asset_model?.eosl ? dateReformatTo_mmm_dd_yyyy(informationData.asset_model.eosl) : 'N/A'}</td>
							<td>{informationData?.asset_model?.eol ? dateReformatTo_mmm_dd_yyyy(informationData.asset_model.eol) : 'N/A'}</td>
							<td>{informationData.deployed_date ? dateReformatTo_mmm_dd_yyyy(informationData.deployed_date) : 'N/A'}</td>
							<td>{informationData.recovery_date ? dateReformatTo_mmm_dd_yyyy(informationData.recovery_date) : 'N/A'}</td>
						</tr>
					</tbody>
				</table>
			</div>
			{/* <div className='flex flex-col text-center overflow-auto w-full' style={{height: 290}}>

				<div className={classes.row}>

					<div className={classes.column}>
						<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Purchase Date</Typography>
						<Typography style={{fontWeight:400}} variant="subtitle1">{informationData.purchase_date ? dateReformatTo_mmm_dd_yyyy(informationData.purchase_date) : 'N/A'}</Typography>
					</div>
					<div className={classes.column}>
						<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Purchase Number</Typography>
						<Typography style={{fontWeight:400}} variant="subtitle1">{informationData.order_number ?? 'N/A'}</Typography>
					</div>
					<div className={classes.column}>
						<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Price</Typography>
						<Typography style={{fontWeight:400,display:'flex',alignItems:'center'}} variant="subtitle1">
							{informationData.purchase_cost ? formatPrice(Number(informationData.purchase_cost)) : 'N/A'}
						</Typography>
					</div>

				</div>


				
				<div className={classes.row}>

					<div className={classes.column}>
						<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">End Of Service Life</Typography>
						<Typography style={{fontWeight:400}} variant="subtitle1">{informationData?.asset_model?.eosl ? dateReformatTo_mmm_dd_yyyy(informationData.asset_model.eosl) : 'N/A'}</Typography>
					</div>
					<div className={classes.column}>
						<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">End Of Life</Typography>
						<Typography style={{fontWeight:400}} variant="subtitle1">{informationData?.asset_model?.eol ? dateReformatTo_mmm_dd_yyyy(informationData.asset_model.eol) : 'N/A'}</Typography>
					</div>
					<div className={classes.column}>
						<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Deployed Date</Typography>
						<Typography style={{fontWeight:400}} variant="subtitle1">{informationData.deployed_date ? dateReformatTo_mmm_dd_yyyy(informationData.deployed_date) : 'N/A'}</Typography>
					</div>
				</div>
				<div className={classes.row}>

					<div className={classes.column}>
						<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Recovery Date</Typography>
						<Typography style={{fontWeight:400}} variant="subtitle1">{informationData.recovery_date ? dateReformatTo_mmm_dd_yyyy(informationData.recovery_date) : 'N/A'}</Typography>				
					</div>
					<div className={classes.column}></div>
					<div className={classes.column}></div>
				</div>
				<div>
					{	Boolean(informationData?.asset_specs?.length) &&
						<Accordion style={{marginBottom:"20px"}} square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
								<Typography>Asset Specs</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container  direction="row">
									<Grid item lg={6} style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
										<Typography style={{fontWeight:600,color:"#848D9E",fontSize:"1.485rem"}} variant="subtitle1">Attribute</Typography>
										{
											(informationData?.asset_specs).map((spec)=>{
												return(
													<Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{spec.attribute.attribute_name}</Typography>
												)
											})
										}
									</Grid>
									<Grid item lg={6} style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
										<Typography style={{fontWeight:600,color:"#848D9E",fontSize:"1.485rem"}} variant="subtitle1">Value</Typography>
										{
											(informationData?.asset_specs).map((spec)=>{
												return(
													<Typography style={{fontWeight:400,fontSize:"15px"}} variant="subtitle1">{spec.attribute_value || 'N/A'}</Typography>
												)
											})
										}
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					}
				</div>
				{
					(!Boolean(informationData.note_style) && Boolean(informationData.notes)) ?
					<div className={classes.column}>
							<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Notes</Typography>
							<Typography style={{fontWeight:400,whiteSpace: 'pre-line'}} variant="subtitle1">
							{informationData.notes}
							</Typography>
						</div>:
					Boolean(informationData.note_style) && (<div className={classes.row}>
						<div className={classes.column}>
							<Typography style={{fontWeight:600,color:"rgb(132, 141, 158)"}} variant="subtitle1">Notes</Typography>
							<RenderHtml html={informationData.note_style} iframe={true}/>								
						</div>
					</div>)
				}
			</div> */}
		</>
	)
}

export default withRouter(InformationTab)
