import React, { useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import ColorPicker from '@fuse/components/ColorPicker'
import { Button, DialogTitle, TextField, TextareaAutosize, Typography, Dialog,DialogContent, DialogActions } from '@mui/material'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const AddServiceSourceFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddSourceModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const defaultValues = {
        source_name: '',
    }

    const schema = yup.object({
        source_name: yup.string().required('Source name is required!')
        .matches(/^[A-Za-z\s]+$/, 'Source name cannot contain numbers, alphanumeric characters, or special characters!')
        .matches(/\S/, 'Source name cannot be only spaces!')
        .max(30, 'Source name must be at most 30 characters!'),
    })

    const { 
        control, 
        formState:{errors: statusFormErrors}, 
        handleSubmit: sourceFormSubmit, 
        trigger,
        reset: sourceFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        await trigger();
        if(Object.keys(statusFormErrors).length !== 0) { 
            return;
        }

        let payload = {
			source_name: data.source_name,
		}
        axios.post('/service-request-source/add', payload).then(response => {
            sourceFormReset(defaultValues) 
            toast.success('Service ticket source added successfully.')
            newDataAddedEvent(response.data.data.new_source)
        }).catch(err => {
            toast.error(err.response.data.message || 'Something went wrong!')
        })
    }


    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddSourceModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Source</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Source Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="source_name"
                            // className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        required
                                        fullWidth
                                        error={!!statusFormErrors.source_name}
                                        helperText={statusFormErrors?.source_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={sourceFormSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>
    );


}

export default  AddServiceSourceFromQuickAction;