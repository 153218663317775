import React, { useEffect,useState,forwardRef,useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Icon } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import { downloadFile } from '@fuse/utils/fileUtils';

const useStyles = makeStyles({
    root:{
        '& .MuiDataGrid-columnsContainer':{
          background: '#8f8f8f26'
        }
    }
});

const TicketHistoryTab = forwardRef(({setNoteForEdit,serviceRequestId},ref) => {
	const classes = useStyles();
	const [rows,setRows] = useState([]);
	let on_hover = false;
	
	const columns = [
		{ field: 'action', headerName: 'Action', width: 200,
			renderCell: (params) => {
				if(params.row.file_download_link){;
					return(
						<Icon style={{cursor: 'pointer'}} onClick={()=>{
							downloadFile(params.row.file_download_link,params.row.notes)
						}}>download</Icon>
					)
				}else{
					return(
						<Icon style={{cursor: 'pointer'}} onClick={()=>{setNoteForEdit(params.row)}}>edit</Icon>
					)
				}
				
			}
		},
		{ field: 'notes', headerName: 'Notes', width: 700 ,renderCell: (params) => {
			if (params.value?.length > 80) {
                return (
					<span 
						onMouseOver={(()=>{
							on_hover = true
						})()} className='activitiesTab-descText'>{
							(()=>{
								if(!on_hover && params.value.length > 80){
								return `${params.value.slice(0,81)}...`
								}else if(on_hover){
									return params.value
								}else{
									return params.value
								}
							})()
						}
					</span>
                )
            } else {
                return (<span>{params.value}</span>)
            }
        }},
	];

	function getNotes(){
		if(!Boolean(serviceRequestId)) return
		axios.get(`/service/list-notes/${serviceRequestId}`).then((res)=>{
			const { result } = res.data.data
			setRows(result)
		}).catch((err)=>{
			console.log('err: ',err);
		})
	}

	useImperativeHandle(ref,()=>({
		getNotes
	}));

	useEffect(()=>{
		getNotes();
	},[serviceRequestId]);

	return (
		<div className={classes.root} style={{width: '100%',height: '300px'}}>
			<DataGrid
				rows={rows}
				columns={columns}
				hideFooter={true}
			/>
		</div>
	)
})

export default TicketHistoryTab
