import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useState, useEffect, useRef } from "react";
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import FuseLoading from '@fuse/core/FuseLoading';
import TablePagination from '@fuse/components/general/TablePagination';
import 'styles/ag-grid-floating-filter-style.css';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';import {
    Card, CardContent, Grid, Avatar, Typography, Divider, Icon, 
    IconButton, InputAdornment, Button, Drawer, TextField, Chip
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { usePusher } from '@fuse/hooks';
import { generateRecipientsString } from '@fuse/utils/stringOperations';

const dateReformat = (data) => {
    if(!data) return data
    return new Date(data).toLocaleDateString("en-US")
}

//Single Email element
function Email({email}){
    if(!email.event_detail){
        return <></>
    }
    const {body = "", sender = "", receivers = [], replied_from_portal= false} = email.event_detail
    return (
        <>
        <Grid xs={1}>
            <Avatar src={email?.avatar} />
        </Grid>
        <Grid item xs={11} style={{/*border: '2px solid black',*/paddingLeft: '10px'}}>
            {replied_from_portal &&
                <div style={{display: 'flex', justifyContent: 'flex-end', padding: 2}}>
                    <Chip label="Replied from portal" size="small" color="primary"/>
                </div>
            }
            <div>
                <Grid container>
                    <Grid item xs={1}><Typography variant="subtitle2">From:</Typography></Grid>
                    <Grid item xs={11}><Typography variant="subtitle2">{sender}</Typography></Grid>
                </Grid>
            </div>
            <div>
                <Grid container>
                    <Grid item xs={1}><Typography variant="subtitle2">To:</Typography></Grid>
                    <Grid item xs={11}><Typography variant="subtitle2">{`${generateRecipientsString(receivers)}`}</Typography></Grid>
                </Grid>
            </div>
            <div>
                <Grid container>
                    <Grid item xs={1}><Typography variant="subtitle2">Date:</Typography></Grid>
                    <Grid item xs={11}><Typography variant="subtitle2">{`${dateReformat(email.created_at)}`}</Typography></Grid>
                </Grid>
            </div>
            <div style={{ marginTop: '20px', minHeight: 50 }}  dangerouslySetInnerHTML={{__html: body}} >
            </div>
            <Divider style={{ marginTop: '10px' }} />
        </Grid>
        </>
    )
}

//layout for every single email 
const EmailDetailsList = (props) => {
    // console.log(props.emailInfo)
    const { emailInfo, threadEmails } = props
    
    return (
        <Card style={{width: '100%'}}>
            <CardContent style={{ padding: '7px' }}>
                <Grid container>
                    <Grid item container xs={1} style={{/*border: '2px solid black'*/ }}>
                        <div style={{ paddingTop: '0px' }}>
                            <Avatar src={emailInfo.data.avatar}/>
                        </div>
                    </Grid>
                    <Grid item xs={11} style={{/*border: '2px solid black',*/paddingLeft: '10px' }}>
                        <div>
                            <Grid container>
                                <Grid item xs={1}><Typography variant="subtitle2">From:</Typography></Grid>
                                <Grid item xs={11}><Typography variant="subtitle2">{emailInfo.data.sender_email}</Typography></Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container>
                                <Grid item xs={1}><Typography variant="subtitle2">To:</Typography></Grid>
                                <Grid item xs={11}><Typography variant="subtitle2">{`${generateRecipientsString(emailInfo.data.receiver_email)}`}</Typography></Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container>
                                <Grid item xs={1}><Typography variant="subtitle2">Date:</Typography></Grid>
                                <Grid item xs={11}><Typography variant="subtitle2">{`${dateReformat(emailInfo.data.delivery)}`}</Typography></Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px', minHeight: 50/*border:'2px solid black'*/ }}  dangerouslySetInnerHTML={{__html: emailInfo.data.email_body}} >
                        </div>
                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                </Grid>
                <Grid container>
                    {
                        threadEmails.map((email, index) => (
                            <Email email={email} key={`thread-email-${index}`} />
                        ))
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

const EmailDrawer = (props) => {
    const { closeEmailDrawer, emailInfo, threadEmails } = props
    return (
        <div style={{ width: '100%' }}>
            <div className="px-10 py-5" style={{/*border: '2px solid black' */ }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ marginLeft: '8px' }}>{emailInfo.data.email_subject}</h3>
                    <IconButton aria-label="close" onClick={closeEmailDrawer}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </div>
            <div>
                <EmailDetailsList emailInfo={emailInfo} threadEmails={threadEmails} />
            </div>
        </div>
    )
}


const ReplyEmailForm = (props) => {

    const { onClickCloseReplyMailSection, emailInfo, replyToEmail } = props;
    const [loading, setLoading] = useState(true);
    const [inReplyToEmail, setInReplyToEmail] = useState(emailInfo.data)

    useEffect(() => {
        if(replyToEmail)
            setInReplyToEmail(replyToEmail)
    }, [])

    const editorEmailBodyRef = useRef(null);
    const dispatch = useDispatch();
    const toast = useToast(dispatch)

    const sendReply = () => {
        if (editorEmailBodyRef.current) {
            const reply = editorEmailBodyRef.current.getContent()
            
            axios.post(`/emails/reply`, {
                reply, in_reply_to: inReplyToEmail.raw_message_id ?? inReplyToEmail.message_id,
                to: emailInfo.data.sender_email
            })
            .then(res => {
                toast.success("Successfully replied")
                props.onRepliedSuccessfully(emailInfo)
                editorEmailBodyRef.current.setContent('')
            })
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <div className="px-10 py-5">
                <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
                    <IconButton aria-label="close" onClick={onClickCloseReplyMailSection}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </div>

            <div className="p-10">
                <TextField
                    fullWidth
                    margin="dense"
                    value={emailInfo.data.receiver_email}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'><Icon>reply</Icon></InputAdornment>
                        )
                    }}
                />

                <div style={{ width: "100%", marginTop: 10 }}></div>

                <Editor
                    apiKey="rizevt0is9zmdrbesb6gkhvjtzmi8wpejsr6f8qw0hoh6uj4"
                    onInit={(evt, editor) => editorEmailBodyRef.current = editor}
                    onLoadContent={() => {
                        setLoading(false);
                    }}
                    init={{
                        height: 450,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px, }'
                    }}
                />
                {loading && <div style={{ width: '100%' }}><FuseLoading /></div>}
                <div style={{ width: "100%", marginTop: 10 }}></div>
                <Button variant="contained" color="primary" onClick={sendReply}>
                    Send
                </Button>
            </div>
        </div>
    )
}

function EmailView(props) {
    const table_name = "QUOTE_EMAIL_VIEW";
    const { quote_id } = props;
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginatedEmails, setPaginatedEmails] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openDrawer, setOpenDrawer] = useState(false)
    const [emailInfo, setEmailInfo] = useState({})
    const [clickReplyButton, setClickReplyButton] = useState(false)
    const [threadEmails, setThreadEmails] = useState([])
    const incoming_email_event = "new_incoming_email"
    const {broadcastChannel} = usePusher()

    const dispatch = useDispatch();
    const toast = useToast(dispatch)

    const onGridReady = (params) => {
        var allColumnIds = [];
        params.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        params.columnApi.autoSizeColumns(allColumnIds, false);

        loadInitialState(params);
    };

    function setHeaderName(header) {
        if (header == 'company_id') {
            return 'Company ID'
        } else {
            return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
        }
    }

    const openEmailDrawer = (details) => {
        getEmailThread(details)
        setEmailInfo(details)
        setOpenDrawer(true)
    }

    const closeEmailDrawer = () => {
        setOpenDrawer(false)
        setThreadEmails([])
    }

    const onClickOpenReplyMailSection = () => {
        setClickReplyButton(true)
    }

    const onClickCloseReplyMailSection = () => {
        setClickReplyButton(false)
    }

    function onRepliedSuccessfully(details){
        onClickCloseReplyMailSection();
        getEmailThread(details)
    }

    function formatTime(setDate) {

        if (setDate) {
            const date = dateReformatTo_mmm_dd_yyyy(setDate)
            const setTime = new Date(setDate);
            const time = setTime.toLocaleTimeString();
            return `${date} ${time}`;
        }

        return "_";
    }

    function getPhotoUrl(item){
        const sender = item?.event_detail?.sender
        if(!sender) return null
        let photoURL = ''
        if(item.client && sender.includes(`${item.client.first_name} ${item.client.last_name}`)){
            photoURL = item.client.photoURL
        }

        if(item.end_user && sender.includes(`${item.end_user.first_name} ${item.end_user.last_name}`)){
            photoURL = item.end_user.photoURL
        }
        return photoURL
    }

    function formatData(data) {
        let formattedData = [];

        for (let item of data) {
            formattedData.push({
                id: item.id,
                event_code: item.event_code,
                receiver_email: item?.event_detail?.receivers ?? "_",
                sender_email: item?.event_detail?.sender ?? "_",
                email_subject: item?.email_events[0]?.email_subject ?? "_",
                email_body: item?.event_detail?.body || '',
                send: formatTime(item?.email_events.filter(event => event.event_type.toLowerCase() == 'send')[0]?.event_date_time),
                delivery: formatTime(item?.email_events.filter(event => event.event_type.toLowerCase() == 'delivery')[0]?.event_date_time),
                open: formatTime(item?.email_events.filter(event => event.event_type.toLowerCase() == 'open')[0]?.event_date_time),
                clicked: formatTime(item?.email_events.filter(event => event.event_type.toLowerCase() == 'click')[0]?.event_date_time),
                raw_message_id: item.raw_message_id,
                message_id: item.message_id,
                avatar: getPhotoUrl(item) || 'Renewal Team',
            })
        }
        return formattedData;
    }

    function handleChangePage(event, value) {
        setPage(value);
        paginatedData(value, rowsPerPage)
    }

    function handleChangeRowsPerPage(rowsPerPage) {
        setRowsPerPage(rowsPerPage);
        paginatedData(0, rowsPerPage)
        setPage(0)
    }

    function paginatedData(page, no_of_rows) {
        let sliced_emails = emails.slice(page * no_of_rows, page * no_of_rows + no_of_rows);
        setPaginatedEmails(sliced_emails);
    }


    function viewEmails() {
        setLoading(true);
        axios.get(`/analytics/list-emails/${quote_id}`).then(res => {
            const { data } = res.data;
            props.getNotOpenedEmailCount()
            if (data.length == 0) {
            } else {
                const all_emails = formatData(data);
                setEmails(all_emails);
                const sliced_emails = all_emails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                setPaginatedEmails(sliced_emails)
                setCount(all_emails.length)
            }

        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        viewEmails()
    }, [quote_id])

    useEffect(() => {
        if(broadcastChannel && emailInfo.data){
            broadcastChannel.bind(incoming_email_event, data => {
                if(!emailInfo.data.id) return
                getEmailThread(emailInfo)
            })
    
            return () => broadcastChannel.unbind(incoming_email_event)
        }
    }, [emailInfo])

    if (loading) {
        return (<FuseLoading />)
    }

    if (paginatedEmails.length <= 0) {
        return (
            <div style={{ width: '100%' }}>
                <Paper
                    square
                    style={{ paddingLeft: 20, paddingTop: 10, paddingBottom: 10, paddingRight: 50 }}
                    className="flex flex-1 items-center justify-center"
                    elevation={0}
                >
                    No Emails found
                </Paper>
            </div>
        )
    }

    function onFilterChanged(event){
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

    function loadInitialState (params){
        axios.get(`/user-view/show-view-order?table_name=${table_name}`)
        .then(results=>{
            const { data } = results.data;
            const order = JSON.parse(data.order);
            params.columnApi.applyColumnState(order);
        })
        .catch(err=>{
            console.log(err);
        })
    }

    function saveView (rows){
        const order = JSON.stringify(rows);
        axios.post('/user-view/save-view-order',{
            table_name :table_name,
            order : order
        })
        .then((results)=>{
        })
        .catch(err=>{
            console.log(err);
        })
    }

    function onColumnMoved(params){
		const rows = params.columnApi.getColumnState();
        saveView(rows);
	}

    function onColumnPinned(params){
		const rows = params.columnApi.getColumnState();
        saveView(rows);
	}

    function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

    function handleSetOpenStatus(params){
        axios.post(`/quotes/emails-read-time/${quote_id}`, {
            event_id: params.data.id
        }).then(res => {
            props.getNotOpenedEmailCount()
        }).catch(err => {
            console.log(err);
        })
    }

    function getEmailThread(emailInfo) {
        axios.get(`/emails/${emailInfo.data.id}/thread`)
        .then(res => {
            const {emails} = res.data.data
            props.getNotOpenedEmailCount()
            setThreadEmails(emails.map(email => ({...email, avatar: getPhotoUrl(email)})))
            
        })
        .catch(err => {
            console.log(err)
            toast.error("Something went wrong")
        })
    }

    return (
        <>
            <div className="ag-theme-material" style={{ height: 350, width: "100%" }}>
                <AgGridReact
                    suppressDragLeaveHidesColumns={true}
                    rowData={paginatedEmails}
                    onGridReady={onGridReady}
                    // no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
					// get filter model
					onFilterChanged = { onFilterChanged }
                    onColumnMoved = { onColumnMoved }
                    onColumnVisible = { onColumnVisible }
                    onColumnPinned = { onColumnPinned }
                    defaultColDef={{
						resizable: true
					}}
                >
                    {Object.keys(paginatedEmails[0]).map((header, index) => {
                        if (header != 'id' && header != 'email_body') {
                            const cellClickedProps = {
                                onCellClicked: function (params) {
                                    handleSetOpenStatus(params)
                                    openEmailDrawer(params)
                                },
                            }
                            return (<AgGridColumn
                                key={index}
                                field={header}
                                headerName={setHeaderName(header)}
                                sortable={true} filter={true} floatingFilter={true}
                                {...cellClickedProps}
                                filter="agTextColumnFilter"
                            >
                            </AgGridColumn>)
                        }
                    })}
                </AgGridReact>
            </div>

            <Drawer anchor="right" open={openDrawer}>
                <div style={{width: '620px'}}>
                    <EmailDrawer
                        closeEmailDrawer={closeEmailDrawer}
                        emailInfo={emailInfo}
                        threadEmails={threadEmails}
                    />
                    <div style={{ width: '600', height: 'auto', /*border: '2px solid black' */ }}>
                        {
                            clickReplyButton ?
                                <div>
                                    <ReplyEmailForm
                                        onClickCloseReplyMailSection={onClickCloseReplyMailSection}
                                        emailInfo={emailInfo}
                                        replyToEmail={threadEmails.length > 0 ? threadEmails[threadEmails.length - 1] : null}
                                        onRepliedSuccessfully={onRepliedSuccessfully}
                                    />
                                </div>
                                :
                                <div style={{display: 'flex', flexDirection: 'row-reverse', margin: 10}}>
                                <Button
                                    variant="outlined"
                                    color='primary'
                                    startIcon={<Icon>reply</Icon>}
                                    style={{ width: '20%', marginLeft: '64px', marginTop: '20px' }}
                                    onClick={onClickOpenReplyMailSection}
                                >
                                    Reply
                                </Button>
                                </div>
                        }
                    </div>
                </div>
            </Drawer>

            <div className="p-10">
                <TablePagination
                    className="flex-shrink-0 border-t-1 mt-10"
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page'
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page'
                    }}
                    onChangePage={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    )
}

export default EmailView;