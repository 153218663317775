import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				footer:{
					display:false
				}
			}
		}
	},
	auth: authRoles.agentAndAdmin,
	routes: [
		{
			path: '/catalogs',
			exact: true,
			component: Page.Layout
		},
	]
};