import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import Badge from '@material-ui/core/Badge';

import usePusher from '@fuse/hooks/usePusher';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import axios from "@fuse/utils/axios"
import useRouter from '@fuse/hooks/useRouter';
import useNotification from '@fuse/hooks/useNotification';

function NotificationIcon(props) {
    const notification = props.notification;

    let icon = <Icon>notifications</Icon>;

    switch (notification.type) {
        case "chat": {
            icon = <Icon>chat</Icon>;
            break;
        }
        case "quote": {
            icon = <Icon>request_quote</Icon>;
            break;
        }
    }

    return <>
        <Avatar>
            {icon}
        </Avatar>
    </>
}

function NotificationMenu(props) {
    const { channel, channelName } = usePusher();
    const currentLoggedInUser = (useCurrentUser()).data;

    const {
        unread: unreadNotifications,
        notifications,
        refreshNotifications,
        updateReadStatusById,
        deleteNotification,
    } = useNotification();

    const [notificationMenu, setNotificationMenu] = useState(null);

    const router = useRouter();

    const notificationMenuClick = event => {
        setNotificationMenu(event.currentTarget);
    };

    const notificationMenuClose = () => {
        setNotificationMenu(null);
    };

    function getCurrentLoggedinUser() {
        return currentLoggedInUser;
    }

    function setupNotificationChannel() {
        if (!channel) return () => { };

        const onNotificationEventName = `${channelName}-on-notification`;

        // On Notification
        channel.bind(onNotificationEventName, function (data) {
            const {
                notification_id,
                user_id,
                user_type,
                type,
            } = data;

            const currentUser = getCurrentLoggedinUser();

            const whoseNotification = `${user_id}-${user_type}`;
            const ownNotification = whoseNotification === `${currentUser?.identifier}`;

            if (ownNotification) {
                if (type == "chat") {
                    const isChatWindowOpen = Boolean(window.__CWO__); // __CWO__ property is added in chat pages 

                    if (isChatWindowOpen) {
                        deleteNotification(notification_id)
                    }else{
                        refreshNotifications();
                    }
                } else {
                    refreshNotifications();
                }
            }
        });

        return () => {
            channel.unbind(onNotificationEventName);
        }
    }

    function handleNotificationClick(notification) {
        updateReadStatusById(notification.id, true);
        notificationMenuClose();

        try {
            const payload = JSON.parse(notification.payload); // 👈 Extra notification data, eg: { quote_id }

            switch (notification.type) {
                case "chat": {
                    router.push({
                        pathname: '/chat', state: {
                            chat_contact_identifier: payload.sender_identifier,
                        }
                    });

                    break;
                }
                case "quote": {
                    if (payload.redirection === "listing") {
                        router.push(`/quotes`)
                    } else if (payload.redirection === "approval" && payload.quote_id) {
                        router.push(`/approve-quotes/detail/${payload.quote_id}`)
                    } else if (payload.quote_id) {
                        router.push(`/quotes/detail/${payload.quote_id}`)
                    } else if(payload.url){
                        router.push(payload.url)
                    } else {
                        router.push(`/quotes`)
                    }

                    break;
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    function updateReadStatusOfAllNotifications(){
        axios.put('/notifications/update-read-status-of-all-notifications')
        .then(results=>{
            console.log(results.data.data);
        })
        .catch(err=>{
            console.log(err);
        })
        .finally(()=>{
            refreshNotifications();
            notificationMenuClose();
        })
    }

    useEffect(() => {
        window.sendNotification = () => {
            axios.get("/notifications/send-test-notification")
                .then((res) => console.log(res.data))
                .catch((err) => console.log(err));
        }

        refreshNotifications();
    }, [])

    useEffect(() => {
        const channelCleanup = setupNotificationChannel();

        return () => {
            channelCleanup();
        }
    }, [channel])

    return (
        <>
            <Tooltip title="Notifications" placement="bottom">
                <IconButton onClick={notificationMenuClick} className={clsx('w-40 h-40', props.className)}>
                    <Badge badgeContent={unreadNotifications} color="primary">
                        <Icon>notifications</Icon>
                    </Badge>
                </IconButton>
            </Tooltip>

            <Popover
                open={Boolean(notificationMenu)}
                anchorEl={notificationMenu}
                onClose={notificationMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: 'py-0'
                }}
            >
                <>
                    {notifications.length ? (
                        <>
                            <div style= {{margin:"5px",display:"flex",justifyContent:"end"}}>
                                <Button onClick = { updateReadStatusOfAllNotifications }>Mark as all read</Button>
                            </div>
                            <List style={{
                                width: '100%',
                                maxWidth: 360,
                                maxHeight: 350,
                            }}>
                                {notifications.map((notification) => {
                                    return (
                                        <ListItem button key={notification.id}
                                            onClick={() => handleNotificationClick(notification)}>
                                            <ListItemAvatar>
                                                <NotificationIcon notification={notification} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={notification.title}
                                                secondary={notification.body}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </>
                    ) : (
                        <>
                            <div style={{
                                height: "24vh",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: 20,
                            }} className="opacity-70">
                                <Icon className="my-20" style={{ fontSize: 100, color: "#6b7280" }}>notifications_none</Icon>
                                <Typography className="px-16 pb-24 text-center text-base" color="textSecondary">
                                    No notifications yet!
                                </Typography>
                            </div>
                        </>
                    )}
                </>
            </Popover>
        </>
    );
}

export default NotificationMenu;
