import useToast from '@fuse/hooks/useToast'
import { Button, 
	DialogTitle, Divider, Icon,
	IconButton, makeStyles, Paper, TextField, Typography,
	Drawer,InputAdornment 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { setFromData } from '../store/formViewDialogReducer';
import { countries } from '@fuse/utils/countryName'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
	display: 'flex',
	'& > * + *': {
		marginLeft: theme.spacing(2),
	},
	},
	subSection: {
		marginLeft:'20px',
		marginRight:'20px',
		marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
			borderRadius: '4px'
	},
	drawerWidth: {
		width: 600
	},
}))

function AddServiceProviderDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.serviceProviderApp.formViewDialog)
	const { data:editFromState } = useSelector(({serviceProviderApp}) => serviceProviderApp.formViewDialog);
	const [loading, setLoading] = useState(false)
	const [formattedNumber, setFormattedNumber] = useState('')


	const avoidHyperTextTransferProtocolString = ['http', 'https']
	const avoidWorldWideWebString = ['www']
	const dotValidation = [-1, 0]

	//form validation
	const schema = yup.object({
		serviceProvider: yup.string().required('Provider Name is required!'),
		// phone_number: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
		// }),
		address1: yup.string().nullable(),
		city: yup.string().nullable(),
		state: yup.string().nullable(),
		country: yup.object().nullable(),
		zip: yup.string().nullable(),
		url: yup.string().required('URL is required!'),
	})

	const defaultValues = {
		serviceProvider: '',
		phone_number: '',
		address1: '',
		city: '',
		state: '',
		country: {},
		zip: '',
		url: ''
	}

	const onSuccess = () => {
	  reset(defaultValues)
	  dispatch(setFromData(null))
	  closeDialog()
	}

	const closeDialog = () => {
		dispatch(closeFormViewerDialog())
		setFormattedNumber('')
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFromState){
			// const currentCountry = countries.filter(country => country.name === editFromState.country)[0]

			const url = editFromState.url.split('www.')[1]
			
			setValue('address1', editFromState.original_info.address_line_1)
			setValue('url', url)
			setValue('city', editFromState.original_info.city)
			setValue('country', {"name": editFromState.original_info.country, "code": "", "currency":""})
			// setValue('phone_number', editFromState.original_info.phone_number)
			setFormattedNumber(editFromState.original_info.phone_number ?? '')
			setValue('serviceProvider', editFromState.service_provider_name )
			setValue('state', editFromState.original_info.state)
			setValue('zip', editFromState.original_info.zip)
		}
	},[editFromState])

  const { errors } = formState

	const manageFormData = (formData) => {

		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}

		if( avoidWorldWideWebString.includes(formData.url?.split('.')[0]) || avoidHyperTextTransferProtocolString.includes(formData.url?.split(':')[0]) || dotValidation.includes(formData.url.indexOf('.')) ){
			toast.error(`URL www.${formData.url} is not valid`)
			return
		}
		
		let payload = {
			address1: formData.address1,
			serviceProvider: formData.serviceProvider,
			phone_number: formattedNumber || '',
			city: formData.city,
			state: formData.state,
			country: formData.country?.name ?? null,
			zip: formData.zip,
			url: `www.${formData.url}`
		}

		if(!editFromState){
			payload = {
				...payload,
				entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
			}
			
			axios.post('/service-provider/add', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					onSuccess()
					props.getServiceProviders()
					toast.success('Service Provider added successfully')
				}
				
			}).catch(err => {
				console.log('504=>',err)
				toast.error('Something went wrong!')
			})

		}else{
			
			payload = {
				...payload,
				id: editFromState.id,
			}

			axios.put('/service-provider/update', payload).then(res => {
				if(res.status === 201){
					toast.error(res.data.message)
				}else{
					onSuccess()
					props.getServiceProviders()
					toast.success('Service Provider updated successfully')
				}
				
			}).catch(err => {
				console.log('504=>',err)
				toast.error('Something went wrong!')
			})

		}
	}
	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	return (
		<div>

			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{ Boolean(editFromState) ? 'Update Service Provider' : 'Add Service Provider'}</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onSuccess }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*----------------------Item Information------------------------- */}
					
					<div className={classes.subSection}>
						<div style={{ width: '100%' }}>
							<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
							<Divider style={{marginBottom: '10px'}}/>
						</div>

						<div >

							<Controller
								name="serviceProvider"
								className="mb-16"
								defaultValue={''}
								control={control}
								render={({ field }) => (
									<>
										<TextField
											{...field}
											variant='outlined'
											label='Service Provider Name*'
											// fullWidth
											error={!!errors.serviceProvider}
											helperText={errors?.serviceProvider?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
											style={{ display:'flex', width: '100%', marginBottom:'10px', marginRight: '10px', backgroundColor:'#fff' }}
										/>
									</>
								)}
							/>

							<Controller
								name="url"
								className="mb-16"
								defaultValue={''}
								control={control}
								render={({ field }) => (
									<>
										<TextField
											{...field}
											variant='outlined'
											label='URL*'
											// fullWidth
											error={!!errors.url}
											helperText={errors?.url?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<Typography style={{ width: '100%', backgroundColor: '#fff', paddingRight: '0px', fontSize: '15px' }}>www.</Typography>
													</InputAdornment>
												),
												classes: {
													adornedEnd: classes.adornedEnd
												}
											}}
											style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
										/>
									</>
								)}
							/>

							<Controller
								name="phone_number"
								className="mb-16"
								defaultValue={''}
								control={control}
								render={({ field }) => (
									<>
										<TextField
											{...field}
											value={formattedNumber}
											variant='outlined'
											label='Phone Number'
											onKeyPress={(event) => {
												if (!/[0-9/-]+/.test(event.key)) {
													event.preventDefault();
												}
												}}
												onChange={(e)=>{
													changeNumberFormat(e)
												}}
												onPaste={(e)=>{
												e.preventDefault();
												}}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
											style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
										/>
									</>
								)}
							/>

							<Controller
								name="address1"
								className="mb-16"
								defaultValue={''}
								control={control}
								render={({ field }) => (
									<>
										<TextField
											{...field}
											variant='outlined'
											label='Address'
											// fullWidth
											error={!!errors.address1}
											helperText={errors?.address1?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
														margin : 0, 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
											style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
										/>
									</>
								)}
							/>

							<div style={{ display:'flex', width: '100%' }}>
								
								<Controller
									name="city"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												{...field}
												variant='outlined'
												label='City'
												// fullWidth
												error={!!errors.city}
												helperText={errors?.city?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
												style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
											/>
										</>
									)}
								/>

								<Controller
									name="state"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												{...field}
												variant='outlined'
												label='State'
												// fullWidth
												error={!!errors.state}
												helperText={errors?.state?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
												style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
											/>
										</>
									)}
								/>

							</div>

							<div style={{ display:'flex', width: '100%' }}>

								<Controller
									name="zip"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												{...field}
												variant='outlined'
												label='Zip'
												// fullWidth
												error={!!errors.zip}
												helperText={errors?.zip?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
												style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
											/>
										</>
									)}
								/>

								<Controller
									name="country"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => (
										<>
											<Autocomplete
												{...field}
												style={{ width: '100%' }}
												options={countries}
												disablePortal
												onChange={(event, value) => field.onChange(value)}
												PaperComponent={({ children }) => (
													<Paper style={{ marginTop:'56px', marginLeft: '300px', width:'100%' }}>{children}</Paper>
												)}
												getOptionLabel={option => option.name ?? ''}
												renderInput={(params) => {
													return (
														<div ref={params.InputProps.ref}>
															<TextField
																style={{background: 'white'}}
																{...params}
																label="Country"
																error={!!errors.country}
																helperText={errors?.country?.message}
																FormHelperTextProps={{
																	style: { 
																		margin : 0, 
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																inputRef={ref}
																variant="outlined"
																fullWidth
															/>
														</div>
													)
												}}
											/>
										</>
									)}
								/>

							</div>

						</div>
					</div>
					<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onSuccess }>Cancel</Button>
					</div>						
				</form>        
			</Drawer>
		</div>
	)
}

export default AddServiceProviderDialog
