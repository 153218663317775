import SalesOrderTable from "./tables";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useState, useEffect } from "react";
import DynamicFieldsHeader from "./header";
import FuseLoading from "@fuse/core/FuseLoading";
import { useDispatch } from "react-redux";
import { Card, CardContent, Menu, MenuItem } from "@material-ui/core";
import axios from "@fuse/utils/axios";
import useRouter from "@fuse/hooks/useRouter";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import lodash from "lodash";
import useToast from "@fuse/hooks/useToast";
import { usePermission } from "@fuse/hooks";
import reducer from "../store";
import withReducer from "app/store/withReducer";
import SaveReportDialog from '../../reports/components/saveReportDialog';
import AddModelDialog from "./addModelDialog";
import CsvUpload from "./CsvUpload";
import ModelImageUpload from "./ModelImageUpload";
import { usePusher } from "@fuse/hooks";
import useCurrentUser from "@fuse/hooks/useCurrentUser";
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';

const useStyles = makeStyles({
  layoutRoot: {
    background: "#fcfcfc",
  },
  pullRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  buttonSquare: {
    borderRadius: "5px",
  },
  topBg: {
    background: "#fcfcfc",
  },
  contentCard: {
    marginBottom: "15px",
    borderRadius: "5px",
  },
});

const views = ["List View", "Grid View"];
const defaultFilters = {
  quote_indicator: "",
  revenue: "",
  quote_status: "",
  agent: "",
};

// function debounce(func, timeout = 300) {
//   let timer;
//   return (...args) => {
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       func.apply(this, args);
//     }, timeout);
//   };
// }

function CardedFullWidth2TabbedSample(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedView, setSelectedView] = useState(0);
  const [viewMenuOpened, setViewMenuOpened] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [anchorEl, setAnchorEl] = useState(null);
  const [invoices, setModels] = useState([]);
  const router = useRouter();
  const agents = getAgents(invoices);
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [logs, setLogs] = useState([]);
  const [use, setUse] = useState(true);
  const { broadcastChannel } = usePusher();
  const new_incident_event = "permission_change";
  const user = useCurrentUser();

  // function handleViewMenuOpen(event) {
  //   setAnchorEl(event.currentTarget);
  //   setViewMenuOpened(true);
  // }

  function handleViewMenuClose() {
    setAnchorEl(null);
    setViewMenuOpened(false);
  }

  function getAgents(invoices) {
    const agentSet = new Set();
    for (let i = 0; i < invoices.length; i++) {
      agentSet.add(invoices[i].Agent);
    }
    return Array.from(agentSet);
  }

  const handleTabChange = (event, value) => {
    if (value == 1) {
      // getLogs();
      setUse(false);
    } else {
      setUse(true);
    }
    setSelectedTab(value);
  };

  async function getModels() {
    // if(!viewManufacturersPermission){
    // 	return;
    // }
    try {
      const res = await axios.get("/models");
      const { data } = res.data;
      setModels(formatInvoices(data));
    } catch (err) {
      console.log(err);
    }
  }

  function formatInvoices(data) {
    return data.map((item) => {
      item.dynamic_attribute.map((element) => {
        item[`${element.attribute_name}_4o94ry`] = element.attribute_value;
      });
      return {
        model_no: item.model_no,
        model_name: item.model_name,
        model_id: item.entity_external_platform_id,
        EOL_date: item.eol ? dateReformatTo_mmm_dd_yyyy(item.eol) : "-",
        EOSL_date: item.eosl ? dateReformatTo_mmm_dd_yyyy(item.eosl) : "-",
        category_: item.category.category_name,
        manufacturer_: item.manufacturer.manufacturer_name,
        size_no_of_assets: item.no_of_assets_belongs_to_this_model,
        description: item.description,
        original_info: {
          image_name: item.image_name,
          image_link: item.image_link,
          description: item.description,
        },
        ...lodash.omit(item, [
          "platform_customer_id",
          "dynamic_attributes",
          "created_at",
          "updated_at",
          "no_of_assets_belongs_to_this_model",
          "source",
          'platform_customer'
        ]),
        source: item.source,
        company_name: item.platform_customer.user_company.company_name,
      };
    });
  }

  function refreshList() {
    getModels();
  }

  // function formatLogs(data) {
  //   return data.map((item) => {
  //     return {
  //       id: item.id,
  //       action_id: `act${item.id}`,
  //       action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
  //       model_name: item?.model? `${item?.model.model_no}-${item?.model.model_name}`: item.model_name,
  //       name: item.user_name,
  //       action: item.action_name,
  //       description: item.comment,
  //       previous_value: item.previous_data,
  //       current_value: item.current_data,
  //       field_name: item.action_field_name,
  //       user_role: item.user_role_name,
  //     };
  //   });
  // }

  // function getLogs() {
  //   setLoadingActivity(true);
  //   axios
  //     .get("/models/activities")
  //     .then(({ data }) => {
  //       setLogs(formatLogs(data.data.activities));
  //     })
  //     .catch((err) => {
  //       console.log("cannot fetch logs");
  //     })
  //     .finally(setLoadingActivity(false));
  // }

  function callToast(success, error, value) {
    if (success) {
      toast.success(value);
    }
    if (error) {
      toast.error(value);
    }
  }

  useEffect(() => {
    setLoading(true);
    getModels().finally(() => {
      setLoading(false);
    });
  }, []);
	
  useEffect(() => {
    if (broadcastChannel /* && invoices.length*/) {
      broadcastChannel.bind(new_incident_event, (data) => {
        if (data.user_companies_id == user.data.user_companies_id) {
          location.reload();
        }
      });
      return () => broadcastChannel.unbind(new_incident_event);
    }
  }, [broadcastChannel]);

  const { hasPermission } = usePermission();
  const viewModelPermission = hasPermission("view-model");
  const createModelPermission = hasPermission("add-model");
  const updateModelPermission = hasPermission("update-model");
  const deleteModelPermission = hasPermission("delete-model");
  return (
    <>
      {(createModelPermission || updateModelPermission) && (
        <CsvUpload
          refresh={refreshList}
          viewModelPermission={viewModelPermission}
          createModelPermission={createModelPermission}
          updateModelPermission={updateModelPermission}
          deleteModelPermission={deleteModelPermission}
        />
      )}
      <ModelImageUpload />
      <SaveReportDialog tableName={"model"}/>
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
          toolbar: "p-0",
          topBg: classes.topBg,
          contentCard: classes.contentCard,
        }}
        header={<DynamicFieldsHeader />}
        contentToolbar={
          <>
         
            <div>
              {/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
								{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
							</IconButton> */}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={viewMenuOpened}
                onClose={handleViewMenuClose}
              >
                {views.map((view, index) => {
                  return (
                    <MenuItem
                      key={`view_${index}`}
                      value={index}
                      onClick={(ev) => {
                        setSelectedView(ev.target.value);
                        handleViewMenuClose();
                      }}
                    >
                      {view}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </>
        }
        content={
          <>
            <div className={selectedTab !== 0 ? "hidden" : ""}>
              {loading && <FuseLoading />}
              {!loading && (
                <SalesOrderTable
                  endUsersAccounts={invoices}
                  selectedView={selectedView}
                  refresh={refreshList}
                  viewModelPermission={viewModelPermission}
                  createModelPermission={createModelPermission}
                  updateModelPermission={updateModelPermission}
                  deleteModelPermission={deleteModelPermission}
                />
              )}
            </div>
            <AddModelDialog getModels={getModels} />
            {viewModelPermission && (
              <div className={selectedTab !== 1 ? "hidden" : "p-10"}>
                {/* <ActivitieLogs logs={logs} /> */}
                
								{/* {Boolean(logs.length) ? <ActivityAgGridListing module='model' logs={logs}/> : <FuseLoading/> } */}
              </div>
            )}
            {!viewModelPermission && (
              <div className={selectedTab !== 1 ? "hidden" : "p-10"}>
                <Card>
                  <CardContent>
                    You don't have the view permission for Model.
                  </CardContent>
                </Card>
              </div>
            )}
          </>
        }
        innerScroll
      />
    </>
  );
}

export default withReducer("modelApp", reducer)(CardedFullWidth2TabbedSample);
