import List from '@material-ui/core/List';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import FuseNavVerticalTab from './types/FuseNavVerticalTab';
import { useCurrentUser } from '@fuse/hooks';
import { navItemPermission } from '@fuse/utils/permission';

const useStyles = makeStyles(theme => ({
	navigation: {
		'& .fuse-list-item': {
			'&:hover': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)'
			},
			'&:focus:not(.active)': {
				backgroundColor: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)'
			}
		},
		'&.active-square-list': {
			'& .fuse-list-item, & .active.fuse-list-item': {
				width: '100%',
				borderRadius: '0'
			}
		},
		'&.dense': {}
	}
}));

function FuseNavVerticalLayout2(props) {
	const classes = useStyles(props);
	let { navigation, layout, active, dense, className, onItemClick, firstLevel, selectedId } = props;
	const theme = useTheme();
	const user = useCurrentUser()
	function handleItemClick(item) {
		onItemClick && onItemClick(item);
	}

	if(!user.role.includes('Super-User')){
		navigation = navigation.filter(i=> i.id != "user-audit-log")
	}

	return (
		<List
			className={clsx(
				'navigation main_navbar_holder whitespace-nowrap items-center flex flex-col',
				classes.navigation,
				`active-${active}-list`,
				dense && 'dense',
				className
			)}
		>
			{navigation.map(_item => 
				{
					if(!navItemPermission(_item.id, user.data.agent_type)){
						return <></>
					}
					return(
						<FuseNavVerticalTab
							key={_item.id}
							type={`vertical-${_item.type}`}
							item={_item}
							nestedLevel={0}
							onItemClick={handleItemClick}
							firstLevel={firstLevel}
							dense={dense}
							selectedId={selectedId}
						/>
					)
				}
			)}
		</List>
	);
}

export default FuseNavVerticalLayout2;
