import _ from '@lodash';
import 'ag-grid-enterprise';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Button, Breadcrumbs, Link, Card, CardContent, Box } from '@mui/material';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomFilterData, setfilterlist, setrowGroupModel, setServiceRequestId as setServiceRequestID } from '../store/filterParamsReducer';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import GeneralArchieveModal from '@fuse/components/Modal/GeneralArchieveModal';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import ServiceRequestDailog from './ServiceRequestDailog';


const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height: "100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin: '5px 5px 5px 0',
		height: '28px'
	},
	activeTab: {
		background: "rgba(107, 44, 87, 0.04);",
		borderRadius: "0",
	},
	buttonSquare: {
		borderRadius: '5px'
	},
	btn: {
		background: "#30C90A",
		width: "120px",
		borderRadius: "17px",
		color: "#fff"
	},
	icon: {
		fontSize: "18px",
		color: "#61656F",
		display: "inline-block"
	}
});

function ListView(props) {
	const table_name = "CUSTOMER";
  	const classes = useStyles();
	const router = useRouter();
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [displayButton, setDisplayButton] = useState(false);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const service_request_id = useSelector((state) => state.ServiceRequestsApp.filterParamsReducer.service_request_id);
	const tab_value = useSelector(
		(state) => state.reportApp.reportViewDialogReducer.tab_value
	);
	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');
	const [tabState, setTabState] = useState("asset");
	const [serviceRequestId, setServiceRequestId] = useState(null)
	const [filterApplied, setFilterApplied] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [isArchieve, setIsArchieve] = useState(false);
	const [logs, setLogs] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const frameworkComponents = {
		customFilter: CustomFilter,
		customDropdownEditor: CustomDropdownEditor
	};
	const tableHeaders = [
		"first_name", "last_name", "email", "phone_number", "status", "company_name", "subdomain", "country",
		"time_zone", "industry"
	];
	const skipHeader = [
		 "id",'phone', 'country_code', 'company_id'
	]

	useEffect(() => {
		setTabValue(tab_value)
	}, [tab_value])


	function TabButton(props) {
		const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
		const isActive = tab == tabState;
		return (
			<Button
				{...restProps}
				onClick={() => tabStateChange(tab)}
				className={restProps.className && isActive ? activeClass : ""}
			>
				{props.children}
			</Button>
		)
	}


	function Close() {
		setOpen(false)
		setTabState('asset')
		dispatch(setServiceRequestID(null))
	}

	const handelEdit = (data) => {
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}


	function handleClose() {
		setIsArchieve(false)
		setModalData(null);
	}

	function handleConfirm() {
		axios.delete(`/service/archived-service-request/${modalData.id}`).then(() => {
			handleClose();
			props.fetchServiceReq();
			toast.success("Service Ticket archived Successfully.");
		}).catch((err) => {
			console.log("err", err.response);
		});
	}

	function TabContainer(props) {
		const { tabState, tab } = props;
		const shouldRender = tab == tabState;
		return shouldRender ? props.children : <></>
	}


	function formatString(str) {
		if (!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	function formatLogs(data) {
		let {length} = data
		return data.map((item) => {
			const obj = {
				id: item.id,
				action_id: `act${length}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				email: item.entity_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data ?? '-',
				current_value: item.current_data ?? '-',
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				asset_serial_number: item.asset_serial_number || 'N/A'
			}
			length--;
			return obj
		})
	}

	function getLogs() {
		setLoadingLog(true)
		axios.get("/user/get-all-customer-activities").then((res) => {
			setLogs(formatLogs(res.data.data.activities));
			setLoadingLog(false)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
		});
	}

	const onGridReady = (params) => {
		setGridApi(params);
		// props.serviceRequests.forEach((list) => {
		// 	for(const [fieldName, value] of Object.entries(list)){
		// 		if(fieldName === "ticket_number") return list[fieldName] = value
		// 		if((value === '-' || value === '')&& fieldName == 'user_name') 
		// 			list[fieldName] = 'Unassigned'
		// 		else if (value === '' || !value || value === '')
		// 			list[fieldName] = `No ${formatString(fieldName)}`
		// 	}
		// })
		if (router.query.report_name == undefined) {
			// loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		} else {
			getReport(params);
		}
	}

	function onFilterChanged(event) {
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if (total_rows === 0) {
			event.api.showNoRowsOverlay();
		} else {
			event.api.hideOverlay();
		}
	}

	function getReport(event) {
		const {report_name} = router.query;
		const table_name = "service request";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
			.then(results => {
				const model = JSON.parse(results.data.data.filter);
				event.api.setFilterModel(model);
				const row_model = JSON.parse(results.data.data.row_model);
				event.columnApi.setColumnState(row_model);
			})
			// autoGroupColumnDef={{ minWidth: 5000 }}
			.catch(err => {
				console.log(err);
			})
	}

	function loadInitialState(params) {
		axios
			.get(`/user-view/show-view-order?table_name=${table_name}`)
			.then((results) => {
				const { data } = results.data;
				if (data !== null) {
					const order = JSON.parse(data.order);
					const filter = JSON.parse(data.filter);
					params?.columnApi.applyColumnState({ state: order });
					params?.api.setFilterModel(filter);
					if (Object.keys(filter)?.length) {
						setFilterApplied(true)
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	useEffect(() => {
		if (filterApplied) {
			toast.success("Filters have been applied")
		}
	}, [filterApplied])

	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filter),
				table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	const onColumnMoved = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const onColumnPinned = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if (hide) {
				++total_hidden_columns;
			}
		});
		if (total_hidden_columns < total_no_of_columns) {
			if (router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event) {
		if (event.columns.length == 0) {
			setGroupByArr([])
		} else {
			setGroupByArr(event.columns)
		}
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0;
		let startRowIndex = 0;
		let endRowIndex = 0;

		const rows = params.api.getCellRanges();

		if (rows.length != 0) {
			columnCount = params.api.getCellRanges()[0].columns.length;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex;
		}

		if (columnCount > 1 || startRowIndex != endRowIndex) {
			setDisplayButton(true);
		} else {
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		if (header == 'accessory_category_id') {
			return 'Accessory Category Name'
		} if (header == 'manufacturer_id') {
			return 'Manufacturer Name'
		} if(header === 'detailed_description_of_the_issue') {
			return 'Issue Description'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	useEffect(() => {
		gridRef.current.api.setQuickFilter(query_data);
	}, [query_data])

	useEffect(() => {
		if (service_request_id) {
			setOpen(true)
			setServiceRequestId(service_request_id)
		}
	}, [service_request_id]);

	useEffect(() => {
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	}, [props.serviceRequests])


	const handleChildClick = (value) => {
		setTabValue(value)
	};

	useEffect(() => {
		(tabValue == 1) && getLogs()
	}, [tabValue])

	function handleAddServiceTicket() {
		dispatch(openFormViewerDialog())
	}

	const clearRedirectionFilter = () => {
		dispatch(setCustomFilterData(null))
	}

	




	return (
		<>

			<AgGridFilterGroupView
				tableRef={gridRef}
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList={groupByArr}
				tableName="customer_management"
				logs={logs}
				displayTableName="Customers"
				onClick={handleChildClick}
				onClickAddButton={handleAddServiceTicket}
				customFilterData={props.customFilterData}
				clearRedirectionFilter={clearRedirectionFilter}
				serviceRequestTotalRecords={props?.serviceRequestTotalRecords}
				loadingLog={loadingLog}
				totalCustomer = {props.serviceRequests.length}
			/>

			{/* {(tabValue == 1) && (<ActivityAgGridListing module={'service_request'} logs={logs} displayTableName={'Service Tickets'}/>)}		 */}

			{
				(tabValue == 0) &&
				<div
					className="ag-theme-alpine"
					style={{
						width: "100%",
						height: windowHeight + 105,
						fontSize: '11px'
					}}
				>
					{filterApplied && <p style={{ margin: '4px' }}>Filters have been applied</p>}
					<AgGridReact
						ref={gridRef}
						suppressRowClickSelection
						onGridReady={onGridReady}
						animateRows
						rowData={props.serviceRequests}
						rowHeight={38}
						onRowClicked={() => {
						}}
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							resizable: true
						}}
						// no rows overlay 
						overlayNoRowsTemplate="<span>No Result Found</span>"

						// Row Grouping conditions
						showOpenedGroup
						suppressDragLeaveHidesColumns
						suppressMakeColumnVisibleAfterUnGroup
						rowGroupPanelShow={rowGroupPanelShow}

						onColumnMoved={onColumnMoved}
						onColumnPinned={onColumnPinned}
						onColumnVisible={onColumnVisible}
						onSortChanged={onSortChanged}
						groupHeaderHeight={1}
						// headerHeight = {15}
						floatingFiltersHeight={40}

						// status bar enabled on range selection
						enableRangeSelection
						statusBar={{
							statusPanels: [
								{
									statusPanel: 'agAggregationComponent'
								},
							],
						}}

						// to check the the no. of rows selected during range selection
						onRangeSelectionChanged={onRangeSelectionChanged}

						onFilterChanged={onFilterChanged}

						// on column group changed
						onColumnRowGroupChanged={onColumnRowGroupChanged}
						frameworkComponents={frameworkComponents}
						paginationPageSize={10}
						pagination
					>
						<AgGridColumn
							lockPosition
							rowSelection="single"
							width={120}
							minWidth={120}
							maxWidth={120}
							suppressSizeToFit
							headerName="Actions"
							pinned='right'
							className="action_field"
							lockPinned
							cellRendererFramework={({ data }) => {
								if (data) {
									return (
										<>
											{data.status != 'Closed' && <Tooltip id="edit" title="Edit" placement="top">
												<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-pencil" onClick={() => handelEdit(data)} />

												{/* <Icon
													onClick={() => handelEdit(data)}
													style={{
														color: '#1d2939',
														paddingBottom: '5px',
														paddingRight: '30px',
														fontSize: '15px',
														cursor: 'pointer'
													}}
												>
													edit
												</Icon> */}
											</Tooltip>}

										</>
									)
								} 
									return (<></>)
								
							}}
						 />
						{/* Object.keys(props.serviceRequests[0]) */}
						{props.serviceRequests.length >  0  &&  tableHeaders.map(header => {
							if (!skipHeader.includes(header)) {
								if (header === 'status') {
									return (
										<AgGridColumn
											field={header}
											key={header}
											suppressSizeToFit
											headerName="Status"
											sortable
											flex={1}
											minWidth={150}
											// floatingFilter = { true } 
											headerComponentFramework={CustomFilter}
											cellRendererFramework={(data) => {
												return (
													<span
														className={`listing_status_badge_holder ${data.data.status && data.data.status.toLowerCase()}`}
													>
														{data.data.status}
													</span>                                            
												);
											}}
											filter="text"
										 />
									)
								}
								return (
									<AgGridColumn
										field={header}
										key={header}
										suppressSizeToFit
										headerName={generateHeaderLabel(header)}
										sortable
										flex={1}
										minWidth={200}
										// floatingFilter = { true } 
										headerComponentFramework={CustomFilter}
										filter="text"
									 />
								)
							}
						})}
					</AgGridReact>
				</div>
			}

			{open && <ServiceRequestDailog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={open}
				setOpen={setOpen}
				Close={Close}
				classes={classes}
				TabButton={TabButton}
				tabState={tabState}
				setTabState={setTabState}
				TabContainer={TabContainer}
				serviceRequestId={serviceRequestId}
			/>}

			{isArchieve && (
				<GeneralArchieveModal
					open={isArchieve}
					title="Are you sure to archive service ticket?"
					text="Your ticket will be moved to archive service tickets if you proceed"
					handleClose={handleClose}
					handleConfirm={handleConfirm}
					actionButtonName="Yes, Proceed!"
				/>
			)}
		</>
	);
}

export default ListView