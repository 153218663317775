import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useRouter } from "@fuse/hooks";
import { Button } from "@mui/material";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AddAssetsInContract from "./AddAssetsInContract";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      background: "#8f8f8f26",
    },
  },
});

function AssetsTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { allAssets } = props;
  const [ open, setOpen ] = useState(false)

  const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
  const onGridReady = params => {
    // Following line to make the currently visible columns fit the screen  
    params.api.sizeColumnsToFit();

    // Following line dymanic set height to row on content
    params.api.resetRowHeights();
  };
  
  function redirectToAssetPage(params) {
    router.push(`/assets-list/${params.data.id}`);
  }

  const columns = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      width: 200,
      headerComponentFramework: CustomFilter,
      cellRendererFramework: (params) => {
        return (
          <a
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => redirectToAssetPage(params)}
          >
            {params.value}
          </a>
        );
      },
    },
    { field: "user_name", headerName: "User Name", headerComponentFramework: CustomFilter, width: 200 },
    { field: "model", headerName: "Model", headerComponentFramework: CustomFilter, width: 200 },
    { field: "category", headerName: "Category", headerComponentFramework: CustomFilter, width: 200 },
    { field: "location", headerName: "Location", headerComponentFramework: CustomFilter, width: 200 },
    { field: "asset_status", headerName: "Asset Status", headerComponentFramework: CustomFilter, width: 200 },
  ];

  if (loading) {
    return <FuseLoading />;
  }

  //handle Add Assets Drawer open
  function handleDrawerOpen () {
    setOpen(true)
  }

  //handle Add Assets Drawer close
  function handleDrawerClose () {
    setOpen(false)
  }

  return (
    <>

      {allAssets.length === 0 && 
        <div className='no_data_holder'>
          <img src='assets/images/nodata.svg' alt='icon' />
          <p className='bold_txt'>It's all empty here!</p>
          <p className='normal_txt'>No data to show yet</p>
        </div>
      }
      {
        allAssets.length > 0 &&      
        <div 
          className="ag-theme-alpine" 
          style={{
          width: "100%",
          height: windowHeight+105,
          fontSize:'12px'
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={allAssets}
            animateRows
            onGridReady = { onGridReady }
            paginationPageSize={10}
            pagination
          >
            {columns.map(header => {
              return(
                <AgGridColumn 
                  key = {header.field} 
                  field = {header.field}
                  headerName = {header.headerName}  
                  width = { header.width }
                  minWidth = { header.minWidth }
                  maxWidth = { header.maxWidth }
                  sortable = {false}
                  flex = {1}
                  filter = "text"
                  wrapText
                  cellRendererFramework = { header.cellRendererFramework}
                  headerComponentFramework = {header.headerComponentFramework}								
                />
              )
            })}
          </AgGridReact>
        </div>
      }
      {
        open && <AddAssetsInContract 
          open={open} 
          close={handleDrawerClose} 
          getContractDetails={props.getContractDetails}
          contractDetails={props.contractDetails}
        />
      }
    </>
  );
}

export default withRouter(AssetsTab);
