import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import DeleteConfirmModal from '@fuse/components/Modal/DeleteConfirmModal';
import { customCaseInsensitiveStringSort } from '@fuse/utils/aggridCustomSort';


function ListView(props) {
	const table_name = "SERVICE_REQUEST_STATUS";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [serviceRequestStatus, setServiceRequestStatus] = useState(null)
	const [ logs,setLogs ] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const skipHeader = [
		"id","platform_customer_id", 
		"created_at", "updated_at_", "status_name",
		"description","status_color",'original_info','company_name'
	]
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	const handleConfirm = (data) => {
		setServiceRequestStatus(data)
	}
	
	const handleClose = () => {
		setServiceRequestStatus(null)
	}

	function cellStyle(params){
		if(params.colDef.field === "status_name_"){
			return{color: params.data.status_color.code}
		}
		return null
	}

	function handelEdit(data){
		dispatch(setFromData({id:data.id,
			status_name:data.status_name,
			type:data.type,
			status_description: data.status_description,
			status_color: data.status_color,
			original_info: data.original_info
		}))
		dispatch(openFormViewerDialog())
	}

	function handelDelete(){
		axios.delete(`/service-request-status/delete/${serviceRequestStatus?.id}`).then((res)=>{
				handleClose()
				toast.success('Service ticket status deleted successfully.')
				props.refresh()
		}).catch((err)=>{
			console.log(err)
			toast.error(err.response.data.message);
			setServiceRequestStatus(null)
		})
	}

	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				status_name: item?.service_request_status?.status_name || item.status_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}

	function getLogs(){
		setLoadingLog(true)
		axios.get('/service-request-status/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
			setLoadingLog(false)
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	const onGridReady = (params) =>{
		props.serviceRequestStatus.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '') list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]));
		}else{
			getReport(params);
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "service request status";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		// autoGroupColumnDef={{ minWidth: 5000 }}
		.catch(err=>{
			console.log(err);
		})
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		// validation for no-overlay 
		saveView("N/A", rows)
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
		  const filter = JSON.parse(data.filter);
          params?.columnApi.applyColumnState({ state: order });
		  params?.api.setFilterModel(filter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
		payload = {
			filter:  JSON.stringify(filter),
			table_name : table_name
		}
		}else{
		payload = {
			order:  JSON.stringify(rows),
			table_name : table_name
		}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
		console.log('view updated')
		})
		.catch(err=>{
		console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
  }

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		const payload = {
			edit_id: e.data.id,
			status_color: e.data.status_color,
			status_name: e.data.status_name_,
			status_description: e.data.status_description === 'No Status Description' ? e.data.original_info.description : e.data.status_description,
		}
		if(!payload.status_name){
			toast.error('Satus Name is required')
			props.refresh()
			return
		}
		axios.post('/service-request-status/edit', payload).then(response => {
			toast.success('Status updated Successfully.')
			props.refresh()
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error(err.response.data.message || 'Something went wrong!')
		})

	}

	const generateHeaderLabel = (header) => {
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	const handleChildClick = (value) => {
		setTabValue(value)
  	};

  	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])

	useEffect(()=>{
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.serviceRequestStatus])

	function  handleAddServiceTicketStatus(){
		dispatch(openFormViewerDialog())
		
	}


	return (
		<>
			<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList ={ groupByArr }
				tableName={'service_request_status'}
				logs={logs}
				displayTableName = {'Service Ticket Statuses'}
				onClick={handleChildClick}
				onClickAddButton={handleAddServiceTicketStatus}
				loadingLog={loadingLog}
				
			/>	
			{
				(tabValue == 0) &&
				<div 
					className="ag-theme-alpine ag-single-col" 
					style={{
						width: "100%",
						height:windowHeight+105,
						fontSize:'12px'
					}}
				>
					<AgGridReact
						ref = {gridRef}
						onGridReady = { onGridReady }
						animateRows = { true }
						rowData = {props?.serviceRequestStatus || []}
						rowHeight = {30}
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							resizable: true
						}}

						gridOptions = {{
							suppressHorizontalScroll: false,
							scrollbarWidth: 0
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}

						// Row Grouping conditions
						showOpenedGroup = {true}
						suppressDragLeaveHidesColumns = {true}
						suppressMakeColumnVisibleAfterUnGroup = {true}
						rowGroupPanelShow = { rowGroupPanelShow }

						onColumnMoved = { onColumnMoved }
						onColumnPinned = { onColumnPinned }
						onColumnVisible = { onColumnVisible }
						onSortChanged={onSortChanged}
						groupHeaderHeight ={1}
						// headerHeight = {15}
						floatingFiltersHeight = {40}
						frameworkComponents={frameworkComponents}
						// get filter model
						onFilterChanged = { onFilterChanged }	
						
						// on column group changed
						onColumnRowGroupChanged = { onColumnRowGroupChanged }

						pagination={true}
					>
						<AgGridColumn 
							lockPosition={true}
							rowSelection={'single'}
							width={120}
              				minWidth={120}
              				maxWidth={120}
							suppressSizeToFit={true}
							headerName="Actions"
							pinned= 'right'
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({data}) =>{
								if(data){
									return (
										<>
																			
											{(!data.is_system_generated && props.updateServiceRequestStatusPermission) &&<Tooltip id="edit" title="Edit" placement="top">
											<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-pencil" onClick={() => handelEdit(data)} />
											</Tooltip>}
											
											{(!data.is_system_generated && props.deleteServiceRequestStatusPermission) &&  <Tooltip id="delete" title="Delete" placement="top">
											<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-trash" onClick={() => handleConfirm(data)} />
											</Tooltip>}
										</>
									)
								}else{
									return (<></>)
								}
							}}
						>
						</AgGridColumn>
								<AgGridColumn 
									key = {'status_name_'} 
									field = {'status_name_'}

									headerName = {'Status Name'}  
									comparator={customCaseInsensitiveStringSort}
									sortable = {true}
									cellRendererFramework={(event) =>{
										if(event.colDef.field === "status_name_"){
											return (
												<span>
													<span style={{
														height:'10px',
														width:'10px',
														marginTop: '2px',
														borderRadius: '50%',
														display: 'inline-block',
														backgroundColor: event.data?.status_color?.rgba || event.data?.status_color?.code
													}}></span>
													<span style={{marginLeft: '5px'}}>{event.data?.status_name_}</span>
												</span>
											)
										}else if(event.colDef.field === "status_description"){
											return <>{event.data?.status_description}</>
										}else if(event.colDef.headerName == 'Group' && event.node.field == 'status_name_'){
											return <><span>{event.value}</span></>
										}else{
											return (<><span>{event.value}</span></>)
										}
									}}
									flex = {1}
									minWidth={200}
									filter = "text"
									// floatingFilter = {true}
									headerComponentFramework={CustomFilter}
									
								></AgGridColumn>
								<AgGridColumn 
									key = {'status_description'} 
									field = {'status_description'}

									headerName = {'Status Description'}  
									sortable = {true}
									flex = {1}
									minWidth={200}
									filter = "text"
									// floatingFilter = {true}
									headerComponentFramework={CustomFilter}
									
								></AgGridColumn>

					</AgGridReact>

					<DeleteConfirmModal 
						openAlerModal={serviceRequestStatus}
						module={'Service Ticket Status'}
						handleCloseAlertModal={handleClose} 
						handleAlertModal={handelDelete}
					/>

					{/* <GeneralModal
						open={serviceRequestStatus}
						title={'Delete Service Request Status'}
						text={<h4>Are you sure to delete this Status named as <b>{serviceRequestStatus?.status_name}</b>?</h4>}
						handleClose={handleClose}
						handleConfirm={handelDelete}
					/> */}

				</div>
			}	
		</>
	);
}

export default ListView
