import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import axios from "@fuse/utils/axios";
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'

const useStyles = makeStyles({
    row:{
        // border: '2px solid black',
        // height: '100%',
        width: '100%',
        marginBottom:"25px",
        display: 'flex'
    },
    column:{
        // border: '2px solid black',
        width: '100%',
        textAlign: 'left'
    }
});

import { withRouter } from 'react-router-dom';

  

function SubscriptionDetailsTab({assetDetails}) {
    const classes = useStyles();
    const [contractData,setContractData] = useState([])

    useEffect(()=>{
        axios.get(`/assets/${assetDetails.id}/contarct`).then((res)=>{
            console.log('res: ',res.data.data.asset_contact);
            setContractData(res.data.data.asset_contact.contract_header)
        }).catch((err)=>{
            console.log('err',err);
        })
    },[assetDetails])


    return (
        <div style={{height: 369, width: '100%',textAlign:'center',display: 'flex',flexDirection: 'column'}}>

            <div className={classes.row}>

                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E",fontSize:"12px"}} variant="subtitle1">SUBCRIPTION</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">SUB-0125390</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E",fontSize:"12px"}} variant="subtitle1">STATUS</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">Active</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E",fontSize:"12px"}} variant="subtitle1">SUPPORT TYPE</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">Juniper Care Core Support</Typography>
                </div>

            </div>
            <div className={classes.row}>

            <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E",fontSize:"12px"}} variant="subtitle1">START DATE</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">10/24/2020</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E",fontSize:"12px"}} variant="subtitle1">EXPIRE DATE</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">10/23/2023</Typography>
                </div>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E",fontSize:"12px"}} variant="subtitle1">SUBCRIPTION TERM</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">3 years</Typography>
                </div>

            </div>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Typography style={{fontWeight:600,color:"#848D9E",fontSize:"12px"}} variant="subtitle1">DAYS REMAINING</Typography>
                    <Typography style={{fontWeight:400}} variant="subtitle1">268</Typography>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SubscriptionDetailsTab)
