import React, { useRef, useState } from 'react'
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, Icon } from "@material-ui/core";
import { onFetchAsset } from './Modules/asset';
import { getColDefs, getRowData } from './Modules'
import useRouter from '@fuse/hooks/useRouter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { useDispatch } from 'react-redux';

const Table = ({
  standardReportProps
}) => {


  const [rowData, setRowData] = useState([]);
  const [groupByArr, setGroupByArr] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
  const dispatch = useDispatch(); 
  const router = useRouter()
  const [groupByHappen, setGroupByHappen] = useState(false)
  const windowHeight = (window.innerHeight-320);
  const gridRef = useRef();
  const colDefs = getColDefs(standardReportProps);
  const rowModelType = standardReportProps.moduleName === "asset" ? 'serverSide' : 'clientSide';

  const onGridReady = (params) => {
    if(standardReportProps.moduleName === "asset"){
      const datasource = {
        getRows(params) {
          onFetchAsset(params, gridRef)
        }
      };
      params?.api.setServerSideDatasource(datasource);
    }else{
      getRowData(standardReportProps.moduleName).then((res)=>{
        setRowData(res)
      })
    }
  };

  const getChildCount = data => {
    // here child count is stored in the 'childCount' property
    return data.childCount;
  };

  const frameworkComponents = {
		customFilter: CustomFilter,
	};

  function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
      setGroupByHappen(false)
		}else{
			setGroupByArr(event.columns)
      setGroupByHappen(true)
		}
  }

  function saveView(rows, filter) {
		if(isArray(rows) && !rows.length ) return
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
 	}


  return (
    <>
      <AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= {'Data Report'}
				displayTableName={'Data Report'}
			/>
      <div 
        className="ag-theme-alpine" 
        style={{
          width: "100%",
          height:windowHeight,
          fontSize:'11px'
        }}
      >
        <AgGridReact
          ref={gridRef}
          //Data and column definition
          rowData={rowData}
          columnDefs={colDefs}
          onGridReady={onGridReady}

          //SSRM
          rowModelType={rowModelType}
          serverSideStoreType={'partial'}
          getChildCount = {getChildCount}
          
          //Row-Grouping
          suppressDragLeaveHidesColumns={true}
          rowGroupPanelShow={'always'}

          //Row-style
          groupHeaderHeight={1}
          // headerHeight={15}
          floatingFiltersHeight={40}
          rowHeight={28}
          animateRows={true}
          onColumnRowGroupChanged = { onColumnRowGroupChanged }
					frameworkComponents={frameworkComponents}
          //Pagination
          pagination={true}
          defaultColDef={{
						resizable: true
					}}
        />
      </div>
    </>
  )
}

export default Table
