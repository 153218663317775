import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "DEPARTMENT";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const gridRef = useRef();
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [editData, setEditData ]  = useState({});
	const [editButtonClick, setEditButtonClick ]  = useState(false);
	const [editDone, setEditDone ]  = useState(false);
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);
	const [departmentData, setDepartmentData] = useState(null)
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			// dispatch(setfilterlist({}));
			loadInitialState(params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
			getReport(params);
		}
	}

	function onSortChanged(params){
		if (router.query.report_name == undefined) {
		  const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
		  saveView(rows);
		}
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "department";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
		axios
		  .get(`/user-view/show-view-order?table_name=${table_name}`)
		  .then((results) => {
			const { data } = results.data;
			if (data !== null) {
			  const order = JSON.parse(data.order);
			  const filter = JSON.parse(data.filter);
			  params?.columnApi.applyColumnState({ state: order });
			  params?.api.setFilterModel(filter);
			}
		  })
		  .catch((err) => {
			console.log(err);
		  });
	}

	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
		payload = {
			filter:  JSON.stringify(filter),
			table_name : table_name
		}
		}else{
		payload = {
			order:  JSON.stringify(rows),
			table_name : table_name
		}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
		console.log('view updated')
		})
		.catch(err=>{
		console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(report_name == undefined){
			const rows = event.columnApi.getColumnState();

			saveView(rows);
			dispatch(setrowGroupModel(rows));
			// const first_row = rows[0];
	
			// if(first_row.colId !== 'ag-Grid-AutoColumn'){
			// 	saveView(rows);
			// 	dispatch(setrowGroupModel([]));
			// }else{
			// 	dispatch(setrowGroupModel(rows));
			// }
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
		// auto-size columns on row-grouping		
		// onRowGroupOpenedAutoSize();	
	}

	const handleConfirm = (data) => {
		setDepartmentData(data)
	}

	const handleClose = () => {
		setDepartmentData(null)
	}

	function handelDelete(){
		axios.delete(`/department/delete/${departmentData?.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message, 5000);
			}
			else{
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}	
		}).catch((err)=>{
			console.log(err)
			toast.error(err.data.message);
		})
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		const payload = {
			department_name:e.data.department,
			edit_id:e.data.id,
		}
		if(!payload.department_name){
			toast.error('Department Name is required')
			props.refresh()
			return
		}
		axios.post('/department/edit', payload).then(response => {
			if(response.status == 201){
				toast.success(response.data.message)
				props.refresh()
			} else {
			//   onSuccess()
			  toast.success('Department updated Successfully.')
			  props.refresh()
			//   props.fetchAllHardwareAssets()
			}
			
		  
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})

	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])
	
	useEffect(()=>{
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.endUsersAccounts])


	function handelEdit(data){
		dispatch(setFromData({id:data.id,
			department_name:data.department_name,
		}))
		dispatch(openFormViewerDialog())		
	}

	// function editCloseForm(data){
	// 	if(data){
	// 		setEditDone(true)
	// 		setEditButtonClick(false)
	// 	}
	// }
	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	function formatLogs(data){
		let length = data.length
		return data.map((item)=>{
			const deptObject = {
				id:item.id,
				action_id: `act${length}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				department_name : item?.department?.department_name || item.department_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item?.user_company?.company_name,
			}
			length--
			return deptObject
		})
	}

	function getLogs(){
		axios.get('/department/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	return (
		<>	
				<AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'department'} logs={logs} displayTableName = {'Departments'}
				 onClick={handleChildClick}
			/>			
			{(tabValue == 0) && <div className="ag-theme-alpine" style={{width: "100%",height:windowHeight+105,fontSize:'11px'}}>
				<AgGridReact
					ref={gridRef}
					rowData={props.endUsersAccounts}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowHeight = {30}
					// onGridSizeChanged = { onGridSizeChanged }
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
					
					// get filter model
					onFilterChanged = { onFilterChanged }		
					onColumnMoved = { onColumnMoved }	
					onColumnVisible = { onColumnVisible }
					onColumnPinned = { onColumnPinned }
					onSortChanged={onSortChanged}

					//row grouping
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					groupHeaderHeight ={1}
					floatingFiltersHeight = {40}
					frameworkComponents={frameworkComponents}

					pagination={true}
					defaultColDef={{
						// flex: 1,
						// minWidth: 180,
						// sortable: true,
						resizable: true
					}}
				>
					<AgGridColumn 
						field="department" 
						headerName= "Department" 

						sortable={ true } filter="text"
						headerComponentFramework={CustomFilter} 
						flex = {1}
						minWidth={200}
					>
					</AgGridColumn>			
					<AgGridColumn 
						field="company_name" 
						headerName= "Company Name" 

						sortable={ true } filter="text"
						headerComponentFramework={CustomFilter} 
						flex = {1}
						minWidth={200}
					>
					</AgGridColumn>				
				</AgGridReact>
				<GeneralModal
					open={departmentData}
					title={'Delete Department'}
					text={<h4>Are you sure to delete this Department named as <b>{departmentData?.department_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handelDelete}
				/>
			</div>}
		</>
	);
}

export default ListView
