const initialState = {
    open: false,
    data: null,
    quickAddedServiceTicketCategory: false,
}

const actionTypes = {
    OPEN_FORM_VIEWER_DIALOG: 'OPEN_FORM_VIEWER_DIALOG',
    CLOSE_FORM_VIEWER_DIALOG: 'CLOSE_FORM_VIEWER_DIALOG',
    QUICK_ADDED_SERVICE_TICKET_CATEGORY: 'QUICK_ADDED_SERVICE_TICKET_CATEGORY',
    SET_FROM_DATA: 'SET_FROM_DATA'
}

export function openFormViewerDialog() {
    return {
        type: actionTypes.OPEN_FORM_VIEWER_DIALOG
    }
} 

export function quickAddedServiceTicketCategory() {
    return {
        type: actionTypes.QUICK_ADDED_SERVICE_TICKET_CATEGORY
    }
}


export function setFromData(data=null) {
    return {
        type: actionTypes.SET_FROM_DATA,
        payload: {
			data
		}
       }
} 



export function closeFormViewerDialog() {
    return {
        type: actionTypes.CLOSE_FORM_VIEWER_DIALOG
    }
}

export default function (state = initialState, action) {
    switch(action.type) {
        case actionTypes.OPEN_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open: true
            }
        }

        case actionTypes.CLOSE_FORM_VIEWER_DIALOG: {
            return {
                ...state,
                open:false
            }
        }

        case actionTypes.SET_FROM_DATA: {
            return {
                ...state,
                data: action.payload.data
            }
        }

        case actionTypes.QUICK_ADDED_SERVICE_TICKET_CATEGORY: {
            return {
                ...state,
                quickAddedServiceTicketCategory: !state.quickAddedServiceTicketCategory
            }
        }

        default:
            return state
    }
}