import { combineReducers } from '@reduxjs/toolkit';
// import userReducer from './userReducer';

import reportViewDialogReducer from './reportViewDialogReducer';

const reducer = combineReducers({
	reportViewDialogReducer,
});

export default reducer;
