import SalesOrderTable from "./tables";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useState, useEffect } from "react";
import DynamicFieldsHeader from "./header";
import FuseLoading from "@fuse/core/FuseLoading";
import { useDispatch } from "react-redux";
import axios from "@fuse/utils/axios";
import useRouter from "@fuse/hooks/useRouter";
import useToast from "@fuse/hooks/useToast";
import { usePermission } from "@fuse/hooks";
import ActivitieLogs from "@fuse/components/ActivitieLogs";
import reducer from "../store";
import withReducer from "app/store/withReducer";
// import SaveReportDialog from "./saveReportDialog";
import AddModelDialog from "./addServiceProviderDialog";
import CsvUpload from "./CsvUpload";
import { usePusher } from "@fuse/hooks";
import useCurrentUser from "@fuse/hooks/useCurrentUser";
import SaveReportDialog from '../../reports/components/saveReportDialog';


const useStyles = makeStyles({
  layoutRoot: {
    background: "#fcfcfc",
  },
  pullRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  buttonSquare: {
    borderRadius: "5px",
  },
  topBg: {
    background: "#fcfcfc",
  },
  contentCard: {
    marginBottom: "15px",
    borderRadius: "5px",
  },
});

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function CardedFullWidth2TabbedSample(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedView, setSelectedView] = useState(0);
  const [invoices, setServiceProvider] = useState([]);
  const router = useRouter();
  const agents = getAgents(invoices);
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const [logs, setLogs] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [currentSearchParameters, setCurrentSearchParameters] = useState({});
  const { broadcastChannel } = usePusher();
  const new_incident_event = "permission_change";
  const user = useCurrentUser();

  function getAgents(invoices) {
    const agentSet = new Set();
    for (let i = 0; i < invoices.length; i++) {
      agentSet.add(invoices[i].Agent);
    }
    return Array.from(agentSet);
  }

  function clearFilters() {
    const { query, field } = currentSearchParameters;
    handleSearch(query, field, {});
    setSelectedFilters({});
  }

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  async function getServiceProviders() {
    try {
      const res = await axios.get("/service-provider");
      const { data } = res.data;
      setServiceProvider(formatServiceProvider(data));
    } catch (err) {
      console.log(err);
    }
  }

  async function getManufacturers() {
    try {
      const res = await axios.get("/manufacturers");
      const { data } = res.data;
      setServiceProvider(formatServiceProvider(data));
    } catch (err) {
      console.log(err);
    }
  }

  function handleSearchReset() {
    getManufacturers();
  }

  function getSearchSuggetions(query, field) {
    axios
      .get("/manufacturers/search-suggestions", {
        params: {
          query,
          field,
        },
      })
      .then((res) => {
        const { data } = res.data;
        setSearchSuggestions(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handelSearchSuggestions = debounce((query, field) => {
    getSearchSuggetions(query, field);
  }, 500);

  function handleSearch(query, field, filters) {
    axios
      .get("/manufacturers/search", {
        params: {
          query,
          field,
          filters,
        },
      })
      .then((res) => {
        const { data } = res.data;
        setServiceProvider(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function formatServiceProvider(data) {
    return data.map((item) => {
      return {
        id: item.id,
        original_info: {
          phone_number: item.phone_number,
          address_1: item.address1,
          city: item.city,
          state: item.state,
          zip: item.zip,
          country: item.country,
        },
        service_provider_name: item.service_provider_name,
        url: item.url,
        phone_number: item.phone_number || "",
        address1: item.address1 || "",
        city: item.city || "",
        state: item.state || "",
        zip: item.zip || "",
        country: item.country || "",
        company_name: item.user_company?.company_name ?? 'N/A'
      };
    });
  }

  function refreshList() {
    getServiceProviders();
  }

  function callToast(success, error, value) {
    if (success) {
      toast.success(value);
    }
    if (error) {
      toast.error(value);
    }
  }

  useEffect(() => {
    setLoading(true);
    getServiceProviders().finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (broadcastChannel /* && invoices.length*/) {
      broadcastChannel.bind(new_incident_event, (data) => {
        if (data.user_companies_id == user.data.user_companies_id) {
          location.reload();
        }
      });
      return () => broadcastChannel.unbind(new_incident_event);
    }
  }, [broadcastChannel]);

  const { hasPermission } = usePermission();
  const viewServiceProviderPermission = hasPermission("view-service_provider");
  const createServiceProviderPermission = hasPermission("add-service_provider");
  const updateServiceProviderPermission = hasPermission(
    "update-service_provider"
  );
  const deleteServiceProviderPermission = hasPermission(
    "delete-service_provider"
  );

  return (
    <>
      <CsvUpload refresh={refreshList} />
      <SaveReportDialog tableName={"service provider"}/>
      <FusePageCarded
        classes={{
          root: classes.layoutRoot,
          toolbar: "p-0",
          topBg: classes.topBg,
          contentCard: classes.contentCard,
        }}
        // header={
        //   <DynamicFieldsHeader
        //     handleSearch={handleSearch}
        //     searchSuggestions={searchSuggestions}
        //     clearSearch={clearFilters}
        //     searchFilters={searchFilters}
        //     handleSearchSuggestions={handelSearchSuggestions}
        //     handleSearchReset={handleSearchReset}
        //     onSearchChange={(searchValue, fieldValue) => {
        //       setCurrentSearchParameters({
        //         query: searchValue,
        //         field: fieldValue,
        //       });
        //     }}
        //   />
        // }
        // contentToolbar={
        //   <>
        //     <Tabs
        //       value={selectedTab}
        //       onChange={handleTabChange}
        //       indicatorColor="primary"
        //       textColor="primary"
        //       variant="scrollable"
        //       scrollButtons="off"
        //       className="w-full h-64"
        //     >
        //       <Tab
        //         key="end_user_tab_1"
        //         className="h-64"
        //         label="All Service Providers"
        //       />
        //       <Tab key="end_user_tab_2" className="h-64" label="Activities" />
        //     </Tabs>
        //   </>
        // }
        content={
          <>
            <div className={selectedTab !== 0 ? "hidden" : ""}>
              {loading && <FuseLoading />}
              {!loading && (
                <SalesOrderTable
                  endUsersAccounts={invoices}
                  selectedView={selectedView}
                  refresh={refreshList}
                  viewServiceProviderPermission={viewServiceProviderPermission}
                  createServiceProviderPermission={
                    createServiceProviderPermission
                  }
                  updateServiceProviderPermission={
                    updateServiceProviderPermission
                  }
                  deleteServiceProviderPermission={
                    deleteServiceProviderPermission
                  }
                />
              )}
            </div>
            <AddModelDialog getServiceProviders={getServiceProviders} />
          </>
        }
        innerScroll
      />
    </>
  );
}

export default withReducer(
  "serviceProviderApp",
  reducer
)(CardedFullWidth2TabbedSample);
