import useToast from '@fuse/hooks/useToast'
import { Button, 
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, Paper, TextField, Typography,
  Drawer 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import { setFromData } from '../store/formViewDialogReducer';
import Editor from '@fuse/components/Editor';
import LocationTypeDialog from '@fuse/components/Modal/QuickAddLocationType'

const useStyles = makeStyles(theme => (
  {
    uploadImageButton: {
      padding: '6px 16px',
      fontSize: '1.3rem',
      minWidth: '64px',
      boxSizing: 'border-box',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: '4px',
      textTransform: 'none',
      backgroundColor: '#6B2C57',
      color: '#ffffff',
      cursor: 'pointer'
    },
    loader: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    subSection: {
      marginLeft:'20px',
			marginRight:'20px',
			marginBottom:'20px',
      padding: '10px', 
      backgroundColor: '#f4f4f4', 
      borderRadius:'4px'
    },
    endButtons: {
      borderRadius: '4px'
    },
    drawerWidth: {
		width: 600
	},
  }
))

const contractList = ['ABCDEFG1', 'JDFJEWFU432', '2WGBAIU2G3', 'HBWQIEU3BI3']

function AddLocationDialog(props) {

  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const openState = useSelector((state) => state.shippingsApp.formViewDialog)
  const { data:editFromState } = useSelector(({shippingsApp}) => shippingsApp.formViewDialog);
  const [loading, setLoading] = useState(false)
  const [ locationNote,setLocationNote ] = useState('')
  const [ locationTypes, setLocationTypes ] = useState([])

  useEffect(()=>{
    if(editFromState){
      setValue('location_name',editFromState.location_name)
      setValue('address',editFromState.address_1)
      setValue('address2',editFromState.address_2)
      setValue('city',editFromState.city);
      setValue('state',editFromState.state);
      setValue('zip',editFromState.zip);
      setValue('country',{name: editFromState.country, code: "N/A"});
      setLocationNote(editFromState.note)
      setValue('location_type',(editFromState.location_type))
    }
    getLocationTypes()
  },[editFromState])

  //form validation
  const schema = yup.object({
    address: yup.string().required('Address is required!'),
    // address2: yup.string(),
    city: yup.string().required('City is required!'),
    state: yup.string().required('State/Province is required!'),
    zip: yup.string().required('Zipcode is required!'),
    country:yup.object().nullable().required('Country name is required!'),
    location_name: yup.string().required('Location name is required!')
  })

  const defaultValues = {
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    location_name: '',
    location_type: null
  }

  const onCancel = () => {
    reset(defaultValues)
    dispatch(setFromData(null))
    dispatch(closeFormViewerDialog())
  }

  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const closeDialog = () => {
    dispatch(setFromData(null))
    dispatch(closeFormViewerDialog())
  }

  const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
    defaultValues,
		resolver: yupResolver(schema)
	});

  const { errors } = formState

  function getLocationTypes(){
		axios.get("/location-type").then((res) => {
			const { data } = res.data;
			setLocationTypes((()=>{
        return data.map((item)=>{
          return {
            id: item.id,
            name: item.name
          }
        })
      })())
		}).catch((err) => {
			console.log(err)
		})
	}

  function editFrom(payload){
    axios.post('/shipping/edit', payload).then(response => {
      if(response.status == 201){
        toast.error(response.data.message)
      } else {
      //   onSuccess()
        reset(defaultValues)
        closeDialog()
        toast.success('Location updated Successfully.')
        if(props.getContracts){
          props.getContracts()
        }
      //   props.fetchAllHardwareAssets()
      }  
    }
  ).catch(err => {
      console.log('212=>',err);
      toast.error('Something went wrong!')
    })
  }

  const manageFormData = (formData) => {
    let payload = {
      entity_external_platform_id: generateString(5),
      location: formData.location_name,
      address: formData.address,
      address2: formData.address2 ||'',
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      country: formData.country.name,
      note: locationNote,
      location_type_id: formData.location_type.id
    }
    if(editFromState){
      const payload = {
        location: formData.location_name,
        address: formData.address,
        address2: formData.address2 || '',
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        country: formData.country.name,
        edit_id: editFromState.id,
        note: locationNote,
        location_type_id: formData.location_type.id
      }
      editFrom(payload)
      return
    }
    // console.log('322=>',payload);

    axios.post('/shipping/add', payload).then(response => {
      if(response.status == 201){
        toast.error(response.data.message)
      } else {
        reset(defaultValues)
        closeDialog()
        toast.success('Location Added Successfully.')
        props.getContracts()
      }      
    }).catch(err => {
      console.log('212=>',err);
      toast.error('Something went wrong!')
    })   
  }

  const errorData = (error) => {
    console.log('363=>',error)
  }

  function onEditorContentChange (content) {
    setLocationNote(content)
  }

  const [createNewLocationType,setCreateNewLocationType] = useState(false)

  const handleLocationTypeDialogClose = (new_location_type=null) => {
		setCreateNewLocationType(false)

		//Set Quick added LOCATION TYPE value into field
		if(new_location_type){
      setValue('location_type',{id:new_location_type.id,name:new_location_type.name})
    }
	}
  const handleLocationTypeDialogOpen = () => {
		setCreateNewLocationType(true)
	}

  return (
    <div>
      <Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
          <DialogTitle>{editFromState ? 'Update' : 'Add'} Location</DialogTitle>
          <IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
            <Icon>close</Icon>
          </IconButton>
        </div>

        <form onSubmit={ handleSubmit(manageFormData, errorData) }>

          {/*----------------------Item Information---------------------------------- */}
              
          <div className={classes.subSection}>
            <div style={{ width: '100%' }}>
            <Typography style={{fontSize:'16px'}}>Required Information *</Typography>
              <Divider style={{marginBottom: '10px'}}/>
            </div>
            <div>              
              <div style={{ display: 'flex', justifyContent:'center' }}>
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name='location_name' 
                    control={control}
                    render={( { field} ) => (
                      <TextField
                        {...field}
                        style={{background: 'white'}}
                        variant='outlined'
                        label='Location Name' 
                        autoFocus
                        fullWidth
                        required
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}} 
                      />
                    )}
                  />
                </div>                             
              </div>
              <div style={{ display: 'flex', justifyContent:'center' }}>
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name='address' 
                    control={control}
                    render={( { field} ) => (
                      <TextField 
                        {...field}
                        style={{background: 'white'}}
                        variant='outlined'
                        label='Address Line 1' 
                        autoFocus
                        fullWidth
                        required                        
                        // error={!!errors.address}
                        // helperText={errors?.address?.message} 
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
                      />
                    )}
                  />
                </div>                             
              </div>
              <div style={{ display: 'flex', justifyContent:'center' }}>
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name='address2' 
                    control={control}
                    render={( { field} ) => (
                      <TextField 
                        {...field}
                        style={{background: 'white'}}
                        variant='outlined'
                        label='Address Line 2' 
                        autoFocus
                        fullWidth                        
                        // error={!!errors.address}
                        // helperText={errors?.address?.message} 
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
                      />
                    )}
                  />
                </div>                             
              </div>
              <div style={{ display: 'flex', justifyContent:'center' }}>
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name='city' 
                    control={control}
                    render={( { field } ) => (
                      <TextField 
                        {...field}
                        style={{background: 'white'}}
                        variant='outlined'
                        label='City' 
                        autoFocus
                        fullWidth   
                        required                   
                        // error={!!errors.city}
                        // helperText={errors?.city?.message} 
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}                               
                      />
                    )}
                  />
                </div>
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name='state' 
                    control={control}
                    render={( { field } ) => (
                      <TextField 
                        {...field}
                        style={{background: 'white'}}
                        variant='outlined'
                        label='State/Province' 
                        autoFocus
                        fullWidth
                        required                        
                        // error={!!errors.state}
                        // helperText={errors?.state?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
                      />
                    )}
                  />
                </div>                             
              </div>
              <div style={{ display: 'flex', justifyContent:'center' }} >
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name='zip' 
                    control={control}
                    render={( { field } ) => (
                      <TextField 
                        {...field}
                        style={{background: 'white'}}
                        variant='outlined'
                        label='Zipcode' 
                        autoFocus
                        fullWidth   
                        required                     
                        // error={!!errors.zip}
                        // helperText={errors?.zip?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
                      />
                    )}
                  />
                </div>
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name="country"
                    className="mb-16"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                      <>
                        <Autocomplete
                          {...field}
                          style={{ width: '100%' }}
                          options={countries}
                          disablePortal
                          onChange={(event, value) => field.onChange(value)}
                          PaperComponent={({ children }) => (
                            <Paper style={{ marginTop:'0px' }}>{children}</Paper>
                          )}
                          getOptionLabel={option => option.name ?? ''}
                          renderInput={(params) => {
                            return (
                              <div ref={params.InputProps.ref}>
                                <TextField
                                  {...params}
                                  label="Country"
                                  style={{background:"#fff"}}
                                  required
                                  // error={!!errors.country}
                                  // helperText={errors?.country?.message}
                                  FormHelperTextProps={{
                                      style: {marginLeft : 0}
                                  }}
                                  inputRef={ref}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            )
                          }}
                        />
                      </>
                    )}
                  />
                </div> 
              </div>
              <div style={{display: 'flex', marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                <Controller
                  name="location_type"
                  className="mb-16"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <>
                      <Autocomplete
                        {...field} 
                        style={{ width: '100%' }}
                        options={locationTypes}
                        disablePortal
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginTop:'50px' }}>{children}
                            <Button 
                              style={{width:'100%'}} 
                              variant="outlined" 
                              color="primary" 
                              onMouseDown={() => handleLocationTypeDialogOpen()}
                            >Add Location Type</Button>
                          </Paper>
                        )}
                        getOptionLabel={option => option.name ?? ''}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                name="location_type"
                                style={{background: 'white'}}
                                {...params}
                                label="Location Type"
                                required
                                FormHelperTextProps={{
                                  style: { 
                                    margin : 0, 
                                    backgroundColor: '#f4f4f4',
                                    width: '100%',
                                    paddingTop: '2px'
                                  }
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          )
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ display: 'flex', justifyContent:'center' }}>
                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Editor
                    editorContent={locationNote}
                    onEditorContentChange={onEditorContentChange}
                  />        
                </div>
              </div>
            </div>
          </div>
          <div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
              <Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? 'Update' : 'Add'}</Button>
              <Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
          </div>                
        </form>        
      </Drawer>

      <LocationTypeDialog
				open={createNewLocationType}
				handleCloseDialog={handleLocationTypeDialogClose}
				refresh={getLocationTypes}
			/>
  </div>
  )
}

export default AddLocationDialog
