import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef} from 'react';
import { closeMapViewerDialog } from "../store/mapViewDialogReducer"
import useToast from '@fuse/hooks/useToast';
import axios from "@fuse/utils/axios";
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { makeStyles } from '@material-ui/core/styles';
import lodash from "lodash"

const useStyles = makeStyles(theme => ({
	dropZone: {
		border: "4px dashed #999",
		padding: 80
	},
	dropZoneDraging: {
		border: "4px dashed #6B2C57",
	}
}));

function CsvUpload(props) {
	
	const classes = useStyles(props);

	const mapViewDiv = useRef(null);

	const dispatch = useDispatch();

    const user = useCurrentUser();

    const toast = useToast(dispatch);

    const dialogState = useSelector(({contractsApp}) => contractsApp.mapViewDialogReducer);
    
	const [loading, setLoading] = useState(false);
	const [contracts, setContracts] = useState([]);
	const [mapAPI, setMapAPI] = useState(null);
	const [markerList, setMarkerList] = useState([]);
	const [locations, setLocations] = useState([]);

    function getFormatedHeaderName(header){
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
	}

    function formatContracts(data){
		return data.map((contract) => {

			const dynamicAttributes = Object.entries(contract.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value

				return acc;
			},{});

			return {
				contract_id: contract.entity_external_platform_id,
				...lodash.omit(contract, [
					"user_companies_id",
					"user_companies_users_id",
					"user_company",
					"user_companies_user",
					"dynamic_attributes",
					"user_id",
					"platform_customer_id",
					"entity_external_platform_id",
					"created_at",
					"updated_at",
				]),
				contract_start_date: contract.contract_start_date,
				contract_end_date: contract.contract_end_date,
				...dynamicAttributes,
			}
		})
	}

    function getContracts(){
        // return []
        return axios.get("/end-user-contracts/map-details").then(res => {
            const { data } = res.data;

            const result = data

            setContracts(result);

            return result;
        }).catch(err => {
            console.log(err)   
        })
    }

    function initMap() {
        const map = null

        setMapAPI(map);
        showMarkers(map);
    }

    function showMarkers(map) {
        const geocoder = null

        const markers = [];

        // getContracts().then(data => {
        //     let infoWindow = new google.maps.InfoWindow({});

        //     // Getting lat & lng group
        //     Object.values(data.reduce((acc, item) => {
                
        //         let keyName = `${item.latitude}_${item.longitude}`;

        //         if(!acc[keyName]) acc[keyName] = {
        //             latitude: item.latitude,
        //             longitude: item.longitude,
        //             contracts: [],
        //         }

        //         acc[keyName].contracts.push(item);

        //         return acc;

        //     },{})).forEach((contractList) => {
               
        //         // geocoder.geocode({'address': contract.location}, function(results, status) {
        //         //     if (status === 'OK') { /*GEOCODE API*/ } else {
        //         //       console.log("Error: ", status);
        //         //     }
        //         // });
                
        //           const [lat, lng] = [contractList.latitude, contractList.longitude];

        //           const marker = new google.maps.Marker({
        //             map: map,
        //             position: new google.maps.LatLng(lat, lng)
        //           });

        //           const contractNumbers = String(contractList.contracts.map((contract) => (
        //                `<a href="/contracts/details/${contract.id}" target="_blank" 
        //                 style="color: #6B2C57 !important;
        //                 background: transparent;
        //                 font-weight: 500;
        //                 border: none;">
        //                     ${contract.entity_external_platform_id}
        //                 </a>`
        //           )))

        //           const assetList = contractList.contracts.reduce((acc, item) => {
        //                 item.contract_line_items?.forEach((assetItem) => {
        //                     if(!acc[assetItem.asset.id]) {
        //                         acc[assetItem.asset.id] = {
        //                             count: 1,
        //                             asset: assetItem.asset,
        //                         }
        //                     } else {
        //                         acc[assetItem.asset.id].count += 1;
        //                     }
        //                 });

        //                 return acc;
        //           },{});
                  
        //           const assetCountGroupByCategory = Object.values(assetList).reduce((res,item)=>{
        //             if(!res[item.asset.category]){
        //                 res[item.asset.category] = 1
        //             }else{
        //                 res[item.asset.category] += 1
        //             }
        //             return res
        //         },{})

        //         const assetCountGroupByCategoryHTML = Object.entries(assetCountGroupByCategory).reduce((res,item)=>{
        //             res += `
        //             <p style="margin-top: 5px;">
        //                 <span>${item[0]}: ${item[1]}</span>
        //             </p>`;

        //             return res
        //         },'')
                
        //         const assetListHtml = Object.values(assetList).reduce((acc, item) => {
        //             const service_sku = item.asset?.service_sku ? ` (${item.asset?.service_sku})` : '';

        //             acc += `
        //                 <p style="margin-top: 5px;">
        //                     <span>${item.asset?.asset_name}${service_sku}: ${item.count}</span>
        //                 </p>
        //             `;

        //             return acc
        //         }, "");

        //           const contractAssetCount = Object.values(assetList).reduce((acc, item) => {
        //                 return item.count ? acc + item.count : acc;
        //           }, 0);

        //           const infoContent = `
        //             <p> <span style="font-weight: bold">Contract number:</span> 
        //                 ${contractNumbers}
        //             </p>
        //             <p style="margin-top: 5px;">
        //                 <span style="font-weight: bold">Total count:</span> ${contractAssetCount}
        //             </p>

        //             <div style="display: none;">
        //                 ${assetListHtml}
        //             </div>

        //             <div>
        //                 ${assetCountGroupByCategoryHTML}
        //             </div>
        //           `;

        //           const boxContent = document.createElement("div");

        //           boxContent.style.cssText = `
        //                 padding: 5px;
        //                 text-align: left;
        //           `;

        //           boxContent.innerHTML = infoContent;

        //           marker.addListener('click', function () {
        //               infoWindow.setContent(boxContent);
        //               infoWindow.open(map, marker);
        //           });

        //           markers.push(marker);
        //     });
    
        //     setMarkerList([...markers])
        // })
    }

    function removeAllMarkers(){
        markerList.map(marker => {
            marker.setMap(null);
        });
    }

    // useEffect(() => {
    //     if(dialogState.open){
    //         getContracts();
    //     }
    // },[dialogState.open]);

    useEffect(() => {
        const timer = setInterval(() => {
            if(document.getElementById('dalos-contracts-map-view')){
                clearTimeout(timer);
                initMap();
            }
        },1000)
    },[])

	return (
        <Dialog open={true} style={{display: dialogState.open ? 'inherit' : 'none'}} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
            <DialogTitle id="form-dialog-title">
				Contract Locations
			</DialogTitle>
            <DialogContent>

			   <div>
					<div className="text-center" role="button">
							<CircularProgress style={{display: loading ? "block" : "none"}}/>
						
                            <div id="dalos-contracts-map-view" 
                            ref={mapViewDiv}
                            style={{display: loading ? "none" : "block", height: "400px"}}></div>
					</div>
			   </div>
			
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closeMapViewerDialog())} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CsvUpload;
