import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import { Card, CardContent, Menu, MenuItem } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import AddCategoryDialog from './addCategoryDialog';
import { usePermission } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import SaveReportDialog from './saveReportDialog';
import CsvUpload from './CsvUpload';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];

// function debounce(func, timeout = 300){
// 	let timer;
// 	return (...args) => {
// 	  clearTimeout(timer);
// 	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
// 	};
// }

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setManufacturers] = useState([]);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const { hasPermission } = usePermission()
	const viewCategoryPermission = hasPermission("view-asset_category")
	const createCategoryPermission = hasPermission("add-asset_category")
	const updateCategoryPermission = hasPermission('update-asset_category')
	const deleteategoryPermission = hasPermission('delete-asset_category')
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	// function getAgents(invoices){
	// 	const agentSet = new Set();
	// 	for(let i = 0; i < invoices.length ; i++){
	// 		agentSet.add(invoices[i].Agent);
	// 	}
	// 	return Array.from(agentSet);
	// }

	const handleTabChange = (event, value) => {
		if(value ==1){
			getLogs()
		}
		setSelectedTab(value);
	};
	
	async function getCategories(){
		try {
			const res = await axios.get("/category?category_type=hardware");
			const { data } = res.data;
			setManufacturers(formatInvoices(data));
		} catch (err) {
			console.log(err);
		}
	}

	function formatInvoices(data){
		return data.map((item) => {
			const tempDescription = item.description;
			const tempElement = document.createElement('div');
			tempElement.innerHTML = tempDescription;
			item.description = item.description == '' ?  'No Description' : tempElement.textContent
			return {
				category_id: item.entity_external_platform_id,
				category_name: item.category_name,
				category_type: item.category_type,
				no_of_assets: item.no_of_assets_belongs_to_this_category,
				original_info:{
					category_name: item.category_name,
					no_of_assets: item.no_of_assets_belongs_to_this_category,
				},
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
					"platform_customer"
				]),
				company_name: item.platform_customer.user_company.company_name,
			}
		})
	}

	function refreshList(){
		getCategories();
	}

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				category_name: item?.category?.category_name || item.category_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}
	
	function getLogs(){
		axios.get('/category/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}

	useEffect(()=>{
		setLoading(true)
		getCategories().finally(() => {
			setLoading(false)
		});
	},[]);
	
	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
			{(createCategoryPermission || updateCategoryPermission) && 
				<CsvUpload
					refresh={refreshList}
					viewCategoryPermission = { viewCategoryPermission }
					createCategoryPermission = { createCategoryPermission }
					updateCategoryPermission = { updateCategoryPermission }
				/>
			}

			<SaveReportDialog callToast = { callToast } />

			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
						{
							loading && <FuseLoading />
						}
						{
							!loading && 
							<SalesOrderTable 
								endUsersAccounts={invoices}
								selectedView={selectedView}
								refresh={refreshList}
								viewCategoryPermission = {viewCategoryPermission}
								createCategoryPermission = {createCategoryPermission}
								updateCategoryPermission = {updateCategoryPermission}
								deleteategoryPermission = {deleteategoryPermission}
							/>
						}
						</div>
						<AddCategoryDialog refreshlist = {getCategories}/>
						{viewCategoryPermission && 
							<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
								{/* <ActivitieLogs logs = {logs} /> */}
								{Boolean(logs.length) ? <ActivityAgGridListing module='asset_category' logs={logs}/> : <FuseLoading/> }
							</div>
						}
						{!viewCategoryPermission && 
							<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							<Card>
								<CardContent>You don't have the view permission for Categories.</CardContent>
							</Card>
						</div>}
					</>
				}
				innerScroll
			/>
			
		</>)
	}
}

export default withReducer('categoryApp', reducer)(CardedFullWidth2TabbedSample);
