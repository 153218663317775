import useToast from '@fuse/hooks/useToast'
import { Button, 
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer, Paper, TextareaAutosize
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import ManufacturerDialog from '@fuse/components/Modal/QuickAddManufacturer'
import AccessoryCategoryDialog from '@fuse/components/Modal/QuickAddAccessoryCategory'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { usePermission } from '@fuse/hooks';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft:'20px !important',
		marginRight:'20px !important',
		marginBottom:'20px !important',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 600
	},
}))

function AddAccessoryDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.accessoryApp.formViewDialog);
	const { data:editFromState } = useSelector(({accessoryApp}) => accessoryApp.formViewDialog);
	const { hasPermission } = usePermission()
	const createManufacturePermission = hasPermission('add-manufacture')
	const createAccessoryCategoryPermission = hasPermission('add-accessory_category')
	const [loading, setLoading] = useState(false);
	const [manufacturers, setManufacturers] = useState([])
	const [manufacturesSearch, setManufacturesSearch ] = useState({ id: 0, manufacturer_name: '' });
	const [accessoryCategoryMenu, setAccessoryCategoryMenu] = useState([])
	const [currentAccessoryCategory, setCurrentAccessoryCategory] = useState({ id: 0, accessory_category_name: '' });
	
	//form validation
	const schema = yup.object({ })

	// setting default values of accessory
	const defaultValues = {
		accessory_name:'',
		model_number:'',
		note:''
	}

	// when cancelling to add or edit all values are reset to default values
	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		dispatch(closeFormViewerDialog())
		dispatch(closeFormViewerDialog())
		setManufacturesSearch({ id: 0, manufacturer_name: '' })
		setCurrentAccessoryCategory({ id: 0, accessory_category_name: '' })
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setManufacturesSearch({ id: 0, manufacturer_name: '' })
		setCurrentAccessoryCategory({ id: 0, accessory_category_name: '' })
	}

	// managing the manufacturer dropdown
	const manageManufacturerSearch = (value) => {
		if(!value) return ''
		let currentLocation = manufacturers.filter(item => item.manufacturer_name === value)
		let finalVal = {id:currentLocation[0].id,manufacturer_name:currentLocation[0].manufacturer_name}
		setManufacturesSearch(finalVal)
	}

	// fetching the value for manufacturer dropdown list
	const fetchManufacturerSuggestions = (value = '') => {
		setLoading(true)
		axios.get("/manufacturers").then(response => {
			setLoading(false)
			setManufacturers((AlphabeticalSorting(response.data.data,'manufacturer_name')).filter((item)=>{ return Boolean( item.manufacturer_name)}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	/**-----------------Manufacture-------------------- */
	const [openManufactureDialog, setOpenManufactureDialog] = useState(false)
	
	function handleManufactureDialogOpen(){
		setOpenManufactureDialog(true)
	}

	function manufacturerFormReset(new_manufacturer=null){
		setOpenManufactureDialog(false)

		//Set Quick added MANUFACTURER value into manufacturer field
		if(new_manufacturer){setManufacturesSearch({ id: new_manufacturer.id, manufacturer_name: new_manufacturer.manufacturer_name })}
	}

	// managing the accessory category dropdown
	const manageCurrentAccessoryCategory = (value) => {
		if(!value) return ''		
		let currentAccessoryCategory = accessoryCategoryMenu.filter((item => item.accessory_category_name === value))
		let finalVal = {id:currentAccessoryCategory[0].id,accessory_category_name:currentAccessoryCategory[0].accessory_category_name}
		setCurrentAccessoryCategory(finalVal)
	}

	// fetching the value for accessory category dropdown list
	const fetchAccessoryCategorySuggestions = (value = '') => {
		setLoading(true)
		axios.get('/accessory-category').then(response => {
			setLoading(false)
			setAccessoryCategoryMenu((AlphabeticalSorting(response.data.data,'accessory_category_name')).filter((item)=>{ return Boolean( item.accessory_category_name)}))
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}

	/**-----------------Accessory Category-------------------- */
	const [openAccessoryCategoryDialog, setOpenAccessoryCategoryDialog] = useState(false)

	function handleAccessoryCategoryDialogOpen(){
		setOpenAccessoryCategoryDialog(true)
	}

	function accessoryCategoryFormReset(new_accessory_category=null){
		setOpenAccessoryCategoryDialog(false)

		//Set Quick added Accessory Category value into accessory field
		if (new_accessory_category) {
			setCurrentAccessoryCategory(
				{ 
					id: new_accessory_category.id, 
					accessory_category_name: new_accessory_category.accessory_category_name 
				}
			)
		}
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	// changing the value of accessory if its edited
	useEffect(()=>{
		if(editFromState){
			setValue('accessory_name', editFromState.accessory_name);
			setValue('model_number', editFromState.model_number);
			setValue('note', editFromState.note);
			setCurrentAccessoryCategory({
				id: editFromState.accessory_category?.id, 
				accessory_category_name: editFromState.accessory_category?.accessory_category_name 
			})
			setManufacturesSearch({
				id: editFromState.manufacturer?.id, 
				manufacturer_name: editFromState.manufacturer?.manufacturer_name 
			})
		}
	},[editFromState])

	const { errors } = formState

	// edit accessory api call
	function editAccessory(payload){
		axios.post(`/accessory/edit`, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success('Accessory updated Successfully.')
				dispatch(setQuickFilter(null))
				if(props.getAccessory){
					props.getAccessory()
				}
			}
		}).catch(err => {
			console.log('212=>',err);
			// toast.error('Something went wrong!')
		})
	}

	// setting the payload data and calling the edit or add accessory api
	const manageFormData = (formData) => {
		let payload = {
			entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
			accessory_name: formData.accessory_name,
			model_number: formData.model_number,
			note: formData.note,
			manufacturer_id: manufacturesSearch.id,
			accessory_category_id: currentAccessoryCategory.id,
		}

		if(editFromState){
			let payload = {
				edit_id: Number(editFromState.id),
				accessory_name:formData.accessory_name,
				model_number: formData.model_number,
				note: formData.note,
				manufacturer_id: manufacturesSearch.id,
				accessory_category_id: currentAccessoryCategory.id,
			}
			editAccessory(payload)
		} else {
			console.log('payload', payload)
			axios.post('/accessory/add', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					closeDialog()
					toast.success('Accessory added Successfully.')
					dispatch(setQuickFilter(null))
					props.getAccessory()
				}
			}).catch(err => {
				console.log('212=>',err);
				// toast.error('Something went wrong!')
			})
		}
	}

	// to call the manufacturer and accessory category suggestions
	useEffect(() => {
		fetchManufacturerSuggestions()
		fetchAccessoryCategorySuggestions()
	}, [])

	const errorData = (error) => {
		console.log('363=>',error)
	}

	return (
		<div>
			
			<AccessoryCategoryDialog
				open={openAccessoryCategoryDialog}
				handleCloseDialog={accessoryCategoryFormReset}
				refreshList={fetchAccessoryCategorySuggestions}
			/>

			<ManufacturerDialog
				open={openManufactureDialog}
				handleCloseDialog={manufacturerFormReset}
				refreshList={fetchManufacturerSuggestions}
			/>

			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>

				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{ Boolean(editFromState) ? "Update Accessory" : "Add Accessory" }</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

					{/*-------Item Information------- */}					
					<div className={classes.subSection}>
						<div style={{ width: '100%' }}>
							<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
							<Divider style={{marginBottom: '10px'}}/>
						</div>
						<div >
							<div style={{ display: 'flex', justifyContent:'center' }}>
								{/* Accessory Name */}
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='accessory_name' 
										control={control}
										render={( { field} ) => (
										<>
											<TextField 
												{...field}
												style={{background: 'white'}}
												variant='outlined'
												label='Accessory' 
												autoFocus
												fullWidth
												required
											/>
										</>
										)}
									/>
								</div>
								{/* Model Number */}
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='model_number' 
										control={control}
										render={( { field} ) => (
										<>
											<TextField 
												{...field}
												style={{background: 'white'}}
												variant='outlined'
												label='Model' 
												autoFocus
												fullWidth
												required
											/>
										</>
										)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent:'center' }}>
								{/* Accessory Category */}
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="accessory_category_id"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<Autocomplete
													style={{ width: '100%' }}
													options={accessoryCategoryMenu}
													value={currentAccessoryCategory}
													disablePortal
													onInputChange={(event) => {
														if(event) {
															manageCurrentAccessoryCategory(event.target.innerText)
														}
													}}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop:'0px' }}>
															{children}
															{createAccessoryCategoryPermission && <Button 
																style={{width:'100%'}} 
																variant="outlined" 
																color="primary" 
																onMouseDown={() => handleAccessoryCategoryDialogOpen()}
															>
																Add Accessory Category
															</Button>}
														</Paper>
													)}
													getOptionLabel={option => option.accessory_category_name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	style={{background: 'white'}}
																	label="Accessory Category"
																	variant="outlined"
																	fullWidth
																	required
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>  
								{/* Manufacturer */}
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="manufacturer_id"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<Autocomplete
													style={{ width: '100%' }}
													options={manufacturers}
													disablePortal
													value={manufacturesSearch}
													onInputChange={(event) => {
														if(event) {
															manageManufacturerSearch(event.target.innerText)
														}
													}}
													PaperComponent={({ children }) => (
													<Paper style={{ marginTop:'0px' }}>
														{children}
														{createManufacturePermission && <Button 
															style={{width:'100%'}} 
															variant="outlined" 
															color="primary" 
															onMouseDown={() => handleManufactureDialogOpen()}
														>
															Add Manufacturer
														</Button>}
													</Paper>
													)}
													getOptionLabel={option => option.manufacturer_name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
															<TextField
																{...params}
																style={{background: 'white'}}
																label="Manufacturer"
																variant="outlined"
																fullWidth
																required
																// error={!!errors.manufacturer_id}
																// helperText={errors?.manufacturer_id?.message}
															/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent:'center' }}>
								{/* Note */}
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='note' 
										control={control}
										render={( { field} ) => (
											<TextareaAutosize
												{...field}
												style={{background: 'white',width: '100%',minHeight: '20%',border: 'solid 2px #c8c5c5'}}
												variant='outlined'
												label='Note'
												fullWidth
												multiline
												placeholder="Notes"
												error={!!errors.note}
												helperText={errors?.note?.message} 
											/>
										) }
									/>
								</div>                              
							</div>
						</div>
					</div>
					<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button 
							className={classes.endButtons} 
							style={{marginRight: '10px'}} 
							type='submit' 
							variant='contained' 
							color='primary'
						>
							{editFromState ? 'Update' : 'Add'}
						</Button>
						<Button 
							className={classes.endButtons}  
							variant='outlined' 
							color='primary' 
							onClick={ onCancel }
						>
							Cancel
						</Button>
					</div>
				</form>        
			</Drawer>
		</div>
	)
}

export default AddAccessoryDialog
