import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Drawer, Icon, IconButton, InputLabel, ListItemText, makeStyles, 
	MenuItem, 
	Modal, Paper, Select, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { useCurrentUser } from '@fuse/hooks'
import { countries } from '@fuse/utils/countryName';
import Grid from '@material-ui/core/Grid';
import DepartmentDialog from '@fuse/components/Modal/QuickAddDepartment'
import CustomDatePicker from '@fuse/components/customDatePicker'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles((theme) => ({
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft:'20px !important',
		marginRight:'20px !important',
		marginBottom:'20px !important',
		padding: '10px',
		backgroundColor: '#f4f4f4',
		borderRadius: '4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
	  style: {
		maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		width: 250,
	  },
	},
	anchorOrigin: {
	  vertical: "bottom",
	  horizontal: "center"
	},
	transformOrigin: {
	  vertical: "top",
	  horizontal: "center"
	},
	getContentAnchorEl: null  
};

function UserDialog(props) {

  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
	const currentUser = useCurrentUser()
	const [showRole, setShowRole] = useState(false)
	const [department, setDepartment] = useState([])
	const [userRoles, setUserRoles] = useState([]);
	const [roles, setRoles] = useState([])
	const [openQuickAddDepartment,setOpenQuickAddDepartment] = useState(false)
	const [formattedNumber, setFormattedNumber] = useState('')
	const [startDate,setStartDate] = useState({
		date: null,
		error: false
	})
	const [endDate,setEndDate] = useState({
		date: null,
		error: false
	})
	const schema = yup.object({
		first_name: yup.string().required('First name is required!'),
		last_name: yup.string().required('Last name is required!'),
		title: yup.string().required('Title is required!'),
		// email: yup.string().email('Enter a valid Email Address!').required('Email is required!'),
		emp_no: yup.string(),
		address: yup.string(),
		department: yup.object().nullable(),
		start_date: yup.string(),
		end_date: yup.string(),
		address_2: yup.string(),
		country: yup.object().nullable(),
		phone: yup.string().when({
			is: (val) => !!val,
			then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		}),
	})

  const defaultValues = {
    first_name: '',
		last_name: '',
		title: '',
		email: '',
		login_enabled: false,
		role: null,
		emp_no: '',
		address: '',
		address_2: '',
		city: '',
		state: '',
		zipcode: '',
		country: null,
		department: null,
		// start_date: '',
		// end_date: '',
		phone:''
  }

	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

  const loginEnabledCondition = () => {
		if(!currentUser.role.includes('Super-User') ){
			return true
		}
	}

	const onCancel = () => {
		setUserRoles([])
		reset(defaultValues)
		setShowRole(false)
		setStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		props.handleCloseDialog()
	}

	const closeDialog = (new_user=null) => {
		setUserRoles([])
		reset(defaultValues)
		setShowRole(false)
		setStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		props.handleCloseDialog(new_user)
	}

  const { 
    control,
    formState,
    handleSubmit, 
    reset, 
		setValue
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

	const { errors } = formState

	async function getDepartment(){
		try {
			const res = await axios.get("/department");
			const { data } = res.data;
			setDepartment(data.map((item) => {
				return {
					id: item.id,
					department_name: item.department_name
				};
			}));
		} catch (err) {
			console.log(err);
		}
	}

	async function getRoles(){
		try {
			const res = await axios.get("/roles");
			const { data } = res.data;
			let all_roles = [...data.preDefinedRoles, ...data.customRoles];
			setRoles(all_roles.map((item) => {
				return {
					id: item.id,
					display_name: item.display_name
				};
			}));
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(()=>{
		getDepartment()
		getRoles()
	},[])

	const handleRoleChange = (event) => {
		setUserRoles(event.target.value);
	};

	function getRoleArr(userRoles,roles){
		let item = [];
		for(let i=0 ;i<userRoles.length;i++){
			item.push(roles.find((item)=>{
				if(item.display_name == userRoles[i]){
					return item
				}
			}).id)
		}
		if(item.length == 0) return null
		return item
	}

	const manageFormData = (formData) => {
		if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)){
			toast.error("Enter a valid Email!")
			return
		}
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
		let payload
		let role_arr = getRoleArr(userRoles,roles)
		payload = {
			first_name: formData.first_name,
			last_name: formData.last_name,
			title: formData.title,
			email:  formData.email,
			login_enabled: formData.login_enabled ?? false,
			role_id: role_arr ?? null,
			emp_no: formData.emp_no,
			address: formData.address,
			address_2: formData.address_2,
			city: formData.city,
			state: formData.state,
			zipcode: formData.zipcode,
			country: formData.country?.name ?? '',
			department_id: formData.department?.id ?? null,
			// start_date: formData.start_date,
			// end_date: formData.end_date,
			start_date: startDate.date ?? '',
			end_date: endDate.date ?? '',
			phone:  formattedNumber || '',	
		}

		axios.post('/user/add', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				//onSuccess()
				setUserRoles([])
				reset(defaultValues)
				closeDialog(response.data.data.data)
				props.refreshList(response.data.data.data.email)
				toast.success(response.data.message)
			}			
		}).catch(err => {
			console.log('212=>',err);
			toast.error(err.response.data.data);
		})
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	const handleDepartmentDialogClose = (new_data=null) => {
		if(new_data) setValue('department',{id:new_data.id,department_name:new_data.department_name})
		setOpenQuickAddDepartment(false)
	}

  return (
		<div>
			<DepartmentDialog
				open={openQuickAddDepartment}
				handleCloseDialog={handleDepartmentDialogClose}
				getDepartment={getDepartment}
			/>

			<Modal
				open={props.open}
				className={classes.modal}
			>
				<div 
					style={{
						width: 730, 
						backgroundColor: 'white',
						borderRadius: 9, 
						display: 'flex', 
						alignItems: 'center', 
						flexDirection: 'column', 
						padding:'20px',
						maxHeight: '98vh',
						overflowY: 'auto'
					}}
				>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', width: "100%" }}>
						<h1 style={{paddingLeft:"20px"}}>Add User</h1>
						<IconButton onClick={()=>{
							reset(defaultValues)
							props.handleCloseDialog()
						}}>
							<Icon>close</Icon>
						</IconButton>
					</div>

					<form onSubmit={ handleSubmit(manageFormData, errorData) }>

						{/*-----------Item Information------------*/}
						<div className={classes.subSection}>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>User Information *</Typography>
								<Divider style={{marginBottom: '10px'}}/>
							</div>
							<div style={{width:'100%'}}>
								<Grid container>
									<Grid item lg={6}>
										<div style={{marginBottom: '10px',marginRight:"10px", borderRadius:9,}}>
											<Controller
												name='first_name' 
												control={control}
												render={( { field} ) => (
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='First Name' 
														autoFocus
														fullWidth	
														required
													/>
												)}
											/>
										</div> 
									</Grid>

									<Grid item lg={6}>
										<div style={{marginBottom: '10px', borderRadius:9}}>
											<Controller
												name='last_name' 
												control={control}
												render={( { field} ) => (
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='Last Name' 
														autoFocus
														fullWidth	
														required
													/>
												)}
											/>
										</div>
									</Grid>

									<Grid item lg={6}>
										<div style={{marginBottom: '10px',marginRight:"10px", borderRadius:9}}>
											<Controller
												name='title' 
												control={control}
												render={( { field} ) => (
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='Title' 
														autoFocus
														fullWidth	
														required											
														
													/>
												)}
											/>
										</div>
									</Grid>

									<Grid item lg={6}>
										<div style={{marginBottom: '10px',  borderRadius:9}}>
											<Controller
												name='email' 
												control={control}
												render={( { field} ) => (
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='Email Address' 
														autoFocus
														fullWidth
														required
													/>
												)}
											/>
										</div> 
									</Grid>

									<Grid item lg={12}>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name="phone"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<TextField
															{...field}
															variant='outlined'
															label='Phone Number'
															value={formattedNumber}
															onChange={(e)=>{
																changeNumberFormat(e)
															}}
															fullWidth
															onKeyPress={(event) => {
																if (!/[0-9/-]+/.test(event.key)) {
																	event.preventDefault();
																}
															}}
															// required
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															error={!!errors.phone}
															helperText={errors?.phone?.message}
															style={{ backgroundColor: '#fff' }}
														/>
													</>
												)}
											/>
										</div>
									</Grid>
								</Grid>

								{/* login enabled button */}
								<div style={{ marginBottom: '10px', minWidth: '179px' }}>
									<Controller
										name="login_enabled"
										className="mb-16"
										control={control}
										render={({ field }) => {
											return(
												<>
													<div className='flex items-center'>
														<Checkbox
															{...field}
															color="primary"
															inputProps={{ 'aria-label': 'secondary checkbox' }}
															checked={field.value}
															onChange={(e,value)=>{
																field.onChange(value)
																if(e.target.checked == true) setShowRole(true)
																else setShowRole(false)
															}}
															disabled={loginEnabledCondition()}
														/>
														<Typography>Login Enabled</Typography>
													</div>
												</>
											)
										}}
									/>
								</div>
								{/* role dropdown */}
								{showRole && 
									<div style={{ marginBottom: '10px', minWidth: '179px' }}>
										<Controller
											name="role"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
												<>
												<InputLabel required id="company_list_label" className="mt-12 mb-16 ml-16">Select Roles</InputLabel>
													<Select
														required
														labelId="company_list_label"
														id="company_list"
														label="Select Roles"
														multiple
														value = {userRoles}
														onChange={handleRoleChange}
														renderValue={(selected) => selected.join(', ')}
														MenuProps={MenuProps}
														variant="outlined"
														fullWidth
														className="mt-16 mb-16" 
													>
														{roles.map((company) => (															
															<MenuItem key={company.id} value={company.display_name}>
															<ListItemText 
																primary={company.display_name} 
																primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
																/>
															</MenuItem>
														))}
													</Select>
												</>
											)}
										/>
									</div> 
								}                             
								
							</div>
						</div>

						{/*-----------optional Information--------*/}
						<Accordion className={classes.subSection}>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<div style={{ width: '100%' }}>
									<Typography variant='h6'>Optional Information</Typography>
									<Divider style={{marginBottom: '10px'}}/>
								</div>
							</AccordionSummary>
							<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{width:'596px'}}>
									
									<Grid container>
										<Grid item lg={6}>
											<div style={{marginBottom: '10px', marginRight:"10px", borderRadius:9}}>
												<Controller
													name='emp_no' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															variant='outlined'
															label='Employee Number' 
															autoFocus
															fullWidth													
															error={!!errors.emp_no}
															helperText={errors?.emp_no?.message} 
														/>
													)}
												/>
											</div>
										</Grid>

										<Grid item lg={6}>
											<div style={{marginBottom: '10px', width: '100%', borderRadius:9}}>
												<Controller
													name='address' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															variant='outlined'
															label='Address Line 1' 
															autoFocus
															fullWidth
															error={!!errors.address}
															helperText={errors?.address?.message} 
														/>
													)}
												/>
											</div>
										</Grid>

										<Grid item lg={6}>
											<div style={{marginBottom: '10px', marginRight:"10px", borderRadius:9}}>
												<Controller
													name='address_2' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															variant='outlined'
															label='Address Line 2' 
															autoFocus
															fullWidth
															error={!!errors.address_2}
															helperText={errors?.address_2?.message} 
														/>
													)}
												/>
											</div>
										</Grid>

										<Grid item lg={6}>
											<div style={{marginBottom: '10px', borderRadius:9}}>
												<Controller
													name='city' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															variant='outlined'
															label='City' 
															autoFocus
															fullWidth
															error={!!errors.city}
															helperText={errors?.city?.message} 
														/>
													)}
												/>
											</div>
										</Grid>

										<Grid item lg={6}>
											<div style={{marginBottom: '10px', marginRight:"10px",borderRadius:9}}>
												<Controller
													name='state' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															variant='outlined'
															label='State/Province'
															autoFocus
															fullWidth
															error={!!errors.state}
															helperText={errors?.state?.message} 
														/>
													)}
												/>
											</div>
										</Grid>

										<Grid item lg={6}>
											<div style={{marginBottom: '10px',  borderRadius:9}}>
												<Controller
													name='zipcode' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															variant='outlined'
															label='Zipcode' 
															autoFocus
															fullWidth
															error={!!errors.zipcode}
															helperText={errors?.zipcode?.message} 
														/>
													)}
												/>
											</div>
										</Grid>

										<Grid item lg={6}>
											<div style={{marginBottom: '10px', marginRight:"10px", borderRadius:9}}>
												<Controller
													name="country"
													className="mb-16"
													control={control}
													render={({ field: { ref, ...field }, fieldState: { error } }) => (
														<>
															<Autocomplete
																{...field}
																style={{ width: '100%' }}
																options={countries}
																disablePortal
																onChange={(event, value) => field.onChange(value)}
																PaperComponent={({ children }) => (
																	<Paper style={{ marginTop:'0px' }}>{children}</Paper>
																)}
																getOptionLabel={option => option.name ?? ''}
																renderInput={(params) => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			{...params}
																			style={{background: 'white'}}
																			label="Country"
																			error={!!errors.country}
																			helperText={errors?.country?.message}
																			FormHelperTextProps={{
																				style: {marginLeft : 0}
																			}}
																			inputRef={ref}
																			variant="outlined"
																			fullWidth
																		/>
																	</div>
																)
																}}
															/>
														</>
													)}
												/>
											</div> 
										</Grid>

										<Grid item lg={6}>
											<div style={{ marginBottom: '10px', width: '100%', borderRadius:9}}>
												<Controller
													name="department"
													className="mb-16"
													control={control}
													render={({ field: { ref, ...field }, fieldState: { error } }) => (
														<>
															<Autocomplete
																{...field}
																style={{ width: '100%' }}
																options={department}
																disablePortal
																getOptionLabel={option => option.department_name  ?? ''}
																onChange={(event, value) => {
																	field.onChange(value)
																}}
																PaperComponent={({ children }) => (
																	<Paper style={{ marginTop: '0px' }}>{children}
																		<Button 
																			style={{width:'100%'}} 
																			variant="outlined" 
																			color="primary" 
																			onMouseDown={() => setOpenQuickAddDepartment(true)}
																		>Add Department</Button>
																	</Paper>
																)}
																renderInput={params => {
																	return (
																		<div ref={params.InputProps.ref}>
																			<TextField
																				{...params}
																				style={{background: 'white'}}
																				name="department"
																				label='Department'
																				variant='outlined'
																				fullWidth
																				error={!!errors.department}
																				helperText={errors?.department?.message}
																				inputRef={ref}
																				FormHelperTextProps={{
																					style: {marginLeft : 0}
																				}}
																			/>
																		</div>
																	)
																}}
															/>
														</>
													)}
												/>
											</div>
										</Grid>


										<Grid item lg={6}>
											<div style={{marginBottom: '10px', marginRight:"10px", borderRadius:9}}>
												{/* <Controller
													name='start_date' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															type="date"
															InputLabelProps={{ shrink: true }}
															variant='outlined'
															label='Start Date' 
															autoFocus
															fullWidth
															error={!!errors.start_date}
															helperText={errors?.start_date?.message} 
														/>
													)}
												/> */}
												<CustomDatePicker
													state={startDate}
													setState={setStartDate}
													required={false}
													label='Start Date'
													maxDate={endDate.date}
													condition='End Date'									
												/>
											</div>
										</Grid>

										<Grid item lg={6}>
											<div style={{marginBottom: '10px', width: '100%', borderRadius:9}}>
												{/* <Controller
													name='end_date' 
													control={control}
													render={( { field} ) => (
														<TextField 
															{...field}
															style={{background: 'white'}}
															type="date"
															InputLabelProps={{ shrink: true }}
															variant='outlined'
															label='End Date' 
															autoFocus
															fullWidth
															error={!!errors.end_date}
															helperText={errors?.end_date?.message} 
														/>
													)}
												/> */}
												<CustomDatePicker
													state={endDate}
													setState={setEndDate}
													required={false}	
													label='End Date'
													minDate={startDate.date}
													condition='Start Date'											
												/>
											</div>
										</Grid>
									</Grid>
										
								</div>
							</AccordionDetails>
						</Accordion>

						<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
							<Button 
								className={classes.endButtons} 
								style={{marginRight: '10px'}} 
								type='submit' 
								variant='contained' 
								color='primary' 
							>
								Add
							</Button>
							<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
						</div>
							
					</form> 
				</div>
			</Modal>
		</div>
  )
}

export default UserDialog
