import { SketchPicker } from 'react-color';
import { GetColorName } from 'hex-color-to-color-name';
import React, { useState,useRef, useCallback } from 'react'


export default function ColorPicker({color,setColor}) {

//   const defaultColorPalate = ['#000000','#808080','#FFC0CB','#00ff00','#0000FF',
//     '#FFFF00', '#FF681F', '#FF0000', '#240A40']
  const defaultColorPalate = ['rgba(0, 0, 0, 1)', 'rgba(128, 128, 128, 1)', 'rgba(255, 192, 203, 1)', 'rgba(0, 255, 0, 1)', 
    'rgba(0, 0, 255, 1)', 'rgba(255, 255, 0, 1)', 'rgba(255, 104, 31, 1)', 'rgba(255, 0, 0, 1)', 'rgba(36, 10, 64, 1)',
    'rgb(205, 147, 215, 1)', 'rgba(179, 156, 219, 1)', 'rgba(129, 196, 233, 1)', 'rgba(255, 209, 144, 1)', 'rgba(255, 245, 157, 1)',
    'rgba(165, 215, 167, 1)', 'rgba(56, 118, 29, 1)'
  ]

  const getRgbaCode = (value) => {
    return `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`
  }


  const handleChangeComplete = (newColor) => {
    setColor({code: newColor.hex, name: GetColorName(newColor.hex), rgba: getRgbaCode(newColor.rgb)});
  };

  return (
      <>
        <SketchPicker color={color.rgba || color.code}
          presetColors={defaultColorPalate}
          onChangeComplete={handleChangeComplete}
        />
      </>
  )
}
