import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link ,useParams, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import { useSelector, useDispatch } from 'react-redux';
import {useState,useEffect} from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import useRouter from '@fuse/hooks/useRouter';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



const useStyles = makeStyles(theme => ({
	root: {}
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	password: yup
		.string()
		.required('Please enter your password.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/," "),
	passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
});

const defaultValues = {
	password: '',
	passwordConfirm: ''
};

function SetPassword(props) {

	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const router = useRouter();

	const [ userMail,setUserMail ] = useState(null);

	const { control, formState, handleSubmit, reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	useEffect(()=>{
		let base64ToString = Buffer.from(props.id, "base64").toString();
		base64ToString = JSON.parse(base64ToString);
		let {email} = base64ToString;
		console.log(68,email)
		setUserMail(email);
	},[])

	function onSubmit(formData) {
		reset(defaultValues);
		onResetPassword(formData);
	}

	function onResetPassword(formData){
		axios.post('/end-user/set-password',{
			email:userMail,
			password:formData.password
		})
		.then(results=>{
			console.log(results);
			toast.success("Password updated successfully");
			router.push("/login");
		}).catch(err=>{
			if(err.response){
				if(err.response.status === 404){
					toast.error(err.response.data.message)
				}
			}
			console.log('err is',err)
		})
	}

	const [validationRules,setValidationRules] = useState([

	{
		checkType:'one_lowercase_character',
		label:'One lowercase',
		isValid:false	
	},
	{
		checkType:'one_special_character',
		label:'One special character',
		isValid:false
	},
	{
		checkType:'one_uppercase_character',
		label:'One uppercase',
		isValid:false	
	},
	{
		checkType:'minimum_eight_characters',
		label:'8 characters minimum',
		isValid:false	
	},
	{
		checkType:'one_number',
		label:'One number',
		isValid:false	
	},
	

		]);


	function validatePassword(value){

		const newValidationRules = [...validationRules];
		validationRules.forEach((item,index)=>{
			newValidationRules[index].isValid = false
			if(item.checkType==='minimum_eight_characters'){
				if(value.length >=8 ){
					newValidationRules[index].isValid = true
				}
			}
			if(item.checkType === 'one_uppercase_character'){
				const upperCaseRegex = /[A-Z]/;
				if(upperCaseRegex.test(value)){
					newValidationRules[index].isValid = true	
				}
			}
			if(item.checkType === 'one_lowercase_character' ){
				const lowercaseRegex = /[a-z]/;
				if(lowercaseRegex.test(value)){
					newValidationRules[index].isValid = true
				}
			}
			if(item.checkType === 'one_number'){
				const oneNumberRegex = /[0-9]/;
				if(oneNumberRegex.test(value)){
					newValidationRules[index].isValid = true
				}
			}
			if(item.checkType === 'one_special_character'){
				const oneSpecialcharacterRegex = /[!@#$%^&;:=*()]/;
				if(oneSpecialcharacterRegex.test(value)){
					newValidationRules[index].isValid = true
				}
			}
			

		})
		setValidationRules([...newValidationRules]);
	}

	const [passwordVisibility,setPasswordVisibility] = useState(false);
	const [confirmpasswordVisibility, setconfirmPasswordVisibility] = useState(false);

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto items-center justify-center p-16 sm:p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
					<Card className="w-full max-w-430">
						<CardContent className="flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
							<img className="w-128 m-32" src="assets/images/logos/dalos_logo.png" alt="logo" />

							<Typography variant="h6" className="mt-16 mb-24 font-semibold text-18 sm:text-24">
							Set new password
							</Typography>

							<form
								name="resetForm"
								noValidate
								className="flex flex-col justify-center w-full"
								onSubmit={handleSubmit(onSubmit)}
							>

								<Controller
									name="password"
									control={control}
									render={({ field }) => (
										<TextField
											{...field }
											onChange = {
												(event)=>{
													validatePassword(event.target.value);
													field.onChange(event)
												}
											}
											InputProps={{
        										endAdornment: ( passwordVisibility ? <Visibility role = 'button' onClick= {()=>{ setPasswordVisibility(false) }} /> : <VisibilityOff role="button" onClick= {()=>{ setPasswordVisibility(true) }} /> ),
      										}}
											className="mb-16"
											label="Password"
											type= {passwordVisibility ? "text":"password"}
											name="password"
											error={!!errors.password}
											helperText={errors?.password?.message}
											variant="outlined"
											required
											fullWidth
										/>
									)}
								/>

								<Controller
									name="passwordConfirm"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-16"
											label="Password (Confirm)"
											type= {confirmpasswordVisibility ? "text":"password"}
											error={!!errors.passwordConfirm}
											helperText={errors?.passwordConfirm?.message}
											variant="outlined"
											required
											InputProps={{
        										endAdornment: ( confirmpasswordVisibility ? <Visibility role = 'button' onClick= {()=>{ setconfirmPasswordVisibility(false) }} /> : <VisibilityOff role="button" onClick= {()=>{ setconfirmPasswordVisibility(true) }} /> ),
      										}}
											fullWidth
										/>
									)}
								/>
								<p style={{marginBottom:'15px',textAlign:'center'}}>Your password must contain</p>
								<ul style={{columns:'2'}}>
									{
										validationRules.map((item,index)=>
											<li key={index}>
												<span style={{color: item.isValid ? "green":"#999",fontSize:'12px'}}>
												{!item.isValid ? <CancelIcon fontSize='small'/> : <CheckCircleOutlineIcon fontSize='small' /> }
												 &nbsp; {item.label}  </span>
											</li>
											)
									}
								</ul>

								<Button
									variant="contained"
									color="primary"
									className="w-224 mx-auto mt-16"
									aria-label="Reset"
									disabled={_.isEmpty(dirtyFields) || !isValid}
									type="submit"
								>
									Set new password
								</Button>
							</form>

							{/* <div className="flex flex-col items-center justify-center pt-32 pb-24">
								<Link className="font-normal" to="/login">
									Go back to Login
								</Link>
							</div> */}
						</CardContent>
					</Card>
				</motion.div>
			</div>
		</div>
	);
}

export default SetPassword;
