import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@fuse/components/general/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, AccordionActions } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useRouter from '@fuse/hooks/useRouter';
import ListView from '../components/listView';
import lodash from "lodash"
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function contractLineItemsTable(props) {	
	const classes = useStyles();

	const dispatch = useDispatch();
	const router = useRouter();

	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);

	const { contractId } = props;

	const [contractLineItems, setContractLineItems] = useState([]);

	const table_name = "CONTRACT_DETAILS_CONTRACT_LINE_ITEM_LISTING";

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event);
		setPage(0);
	}

	function getData() {
		setLoading(true);

		axios.get("/contract-line-items",{
			params: {
				contract_id: contractId,
			}
		}).then((res) => {
			const { data } = res.data;
			setContractLineItems(formatContractLineItems(data))
		}).catch((err) => {
			console.log(err)
		}).finally(() =>{
			setLoading(false)
		})
	}

    function getFormattedDate(dateString){
		if(dateString){
			const newDate = new Date(dateString);
			let month = newDate.getMonth() + 1;
			let day = newDate.getDate();
			let year = newDate.getFullYear();
		
			if(month < 10) month =  "0" + month
			if(day < 10) day =  "0" + day
		
			return `${month}-${day}-${year}`
		}else return ''
		
	}

	function monthDiff(d1, d2) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

	function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `${formatedNumber}.${secondNumber}`;
		return result
	}

	function formatString(str){
		if(str){
			return str.split('_').map(word =>  word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
		}
	}

	function formatContractLineItems(data){
		return data.map((lineItem) => {

			const dynamicAttributes = Object.entries(lineItem.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value

				return acc;
			},{});

			return {
				...lineItem,
				line_item_id: lineItem.entity_external_platform_id,
				serial_number: lineItem.serial_number,
				contract_id: lineItem.contract_id,
				cost: "$" + toNumberFormat(lineItem.cost),
				msrp: "$" + toNumberFormat(lineItem.msrp),
				sell_price: "$" + toNumberFormat(lineItem.sell_price),
				start_date: getFormattedDate(lineItem?.contract_header?.contract_start_date),
				end_date: getFormattedDate(lineItem?.contract_header?.contract_end_date),
				model: lineItem?.asset?.model,
				manufacturer: lineItem?.asset?.manufacturer?.manufacturer_name,
				eosl_date: getFormattedDate(lineItem?.asset?.eosl),
				asset_type: formatString(lineItem?.asset?.asset_type) || '',
				asset_sku: lineItem?.asset?.hardware_sku,
				asset_short_description: lineItem?.asset?.short_description,
				contract_type: lineItem?.contract_header?.contract_type,
				contract_length: (monthDiff(new Date(lineItem?.contract_header?.contract_start_date), new Date(lineItem?.contract_header?.contract_end_date)) || 0).toString() + " months",
				pc_number: lineItem?.asset?.price_catalogues[0] ? lineItem?.asset?.price_catalogues[0].concession_no : "-",
				pc_end_date: getFormattedDate(lineItem?.asset?.price_catalogues[0].end_date ? lineItem?.asset?.price_catalogues[0].end_date : "") || "",
				service_sku: lineItem?.asset?.price_catalogues[0]?.service_external_id || "",
				shipping_location_name: lineItem?.asset?.shipping?.location_name,
				shipping_street_address: [lineItem?.asset?.shipping?.address1, lineItem?.asset?.shipping?.address2].filter(Boolean).join(', '),
				shipping_city: lineItem?.asset?.shipping?.city,
				shipping_state: lineItem?.asset?.shipping?.state,
				shipping_zip: lineItem?.asset?.shipping?.zip,
				shipping_country: lineItem?.asset?.shipping?.country,
				quote_id: lineItem?.asset?.quote_line_item?.quote?.quote_id,
				...dynamicAttributes,
			}
		})
	}

	const columnHeaders = [
		{
			field: 'serial_number',
			label: 'Serial Number',
			cellClicked: function (params, value){
				router.push(`/asset-infos/detail/${params.node.data?.asset?.id}`)
			},
		},
		{
			field: 'asset_type',
			label: 'Asset Type',
		},
		{
			field: 'asset_sku',
			label: 'SKU',
		},
		{
			field: 'asset_short_description',
			label: 'Asset Description',
		},
		{
			field: 'start_date',
			label: 'Start Date',
		},
		{
			field: 'end_date',
			label: 'End date',
		},
		{
			field: 'contract_length',
			label: 'Contract Length',
		},
		{
			field: 'contract_type',
			label: 'Contract Type',
		},
		{
			field: 'model',
			label: 'Model',
		},
		{
			field: 'manufacturer',
			label: 'Manufacturer',
		},
		{
			field: 'contract_id',
			label: 'Contract ID',
		},
		{
			field: 'eosl_date',
			label: 'EOSL Date',
		},
		{
			field: 'pc_number',
			label: 'PC Number',
		},
		{
			field: 'pc_end_date',
			label: 'PC End Date',
		},
		{
			field: 'service_plan',
			label: 'Service Plan',
		},
		{
			field: 'service_sku',
			label: 'Service SKU',
		},
		{
			field: 'cost',
			label: 'Cost',
		},
		{
			field: 'msrp',
			label: 'MSRP',
		},
		{
			field: 'sell_price',
			label: 'Sell Price',
		},
		{
			field: 'quote_id',
			label: 'Quote Number',
			cellClicked: function (params, value){
				router.push(`/quotes/detail/${params.node.data?.asset?.quote_line_item?.quote?.id}`)
			},
		},
		{
			field: 'shipping_location_name',
			label: 'Location Name',
		},
		{
			field: 'shipping_street_address',
			label: 'Location Street Address',
		},
		{
			field: 'shipping_city',
			label: 'Location City',
		},
		{
			field: 'shipping_state',
			label: 'Location State',
		},
		{
			field: 'shipping_zip',
			label: 'Location Zip',
		},
		{
			field: 'shipping_country',
			label: 'Location Country',
		},
	];

	useEffect(() => {
	    getData()
	}, [])

	if (loading) {
		return (<FuseLoading />)
	}

	if (contractLineItems.length <= 0) {
		return (
			<div
				style={{ width: "100%" }}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="w-full flex flex-1 items-center justify-center h-full mt-16">
				<div style={{ height: "200px" }}>
					<Typography variant="body2" component="p" color="textSecondary">
						No data found
					</Typography>
				</div>
			</div>
		)
	}

    function getHeaderName(header){
        if(header == "line_item_id") return "Line Item ID";
        if(header == "msrp") return "MSRP";
        if(header == "contract_id") return "Contract ID";
    
        return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	return (
		<div className="w-full flex flex-col">
			<ListView rows={contractLineItems} columns={columnHeaders} getHeaderName={getHeaderName} onRowClicked={() => {}} flexGrow={false} table_name = { table_name } tabName={'contract-line-items'}/>
			<div className="p-10">
			<TablePagination
				className="flex-shrink-0 border-t-1 mt-10"
				component="div"
				count={contractLineItems.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page'
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page'
				}}
				onChangePage={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			</div>
		</div>
	);
}

export default withRouter(contractLineItemsTable)
