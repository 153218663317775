import useToast from '@fuse/hooks/useToast'
import { Button, DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer} from '@material-ui/core'
import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ColorPicker from '@fuse/components/ColorPicker'
import { ColorPicker as Picker, useColor } from "react-color-palette";
import { Box } from '@mui/material';
import Editor from '@fuse/components/Editor';
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => ({
		uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft:'20px',
		marginRight:'20px',
		marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 520
	},
}))

function AddServiceRequestStatusDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)

	const openState = useSelector((state) => state.ServiceRequestStatusApp.formViewDialog)
	const { data:editFromState } = useSelector(({ServiceRequestStatusApp}) => ServiceRequestStatusApp.formViewDialog);


	const [color, setColor] = useState({name:'black',code:"#000000"});
	const [openAlerModal, setOpenAlertModal] = useState(false);
	const [description, setDescription] = useState('')

	//form validation
	const schema = yup.object({
    status: yup.string().required('Status name is required!')
		.matches(/^[A-Za-z\s]+$/, 'Status name cannot contain numbers, alphanumeric characters, or special characters!')
		.matches(/\S/, 'Status name cannot be only spaces!')
		.max(30, 'Status name must be at most 30 characters!'),
  })


	const defaultColor = {name:'black',code:"#000000"}

	const defaultValues = {
		status:'',
		status_description: ''
	}

	const { formState: { errors: statusFormErrors },control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});
	
	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setDescription('')
		setColor(defaultColor)
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setDescription('')
		setColor(defaultColor)
	}

	const handleAlertModal=(_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenAlertModal(!openAlerModal)
	}
	const handleCloseAlertModal=() =>{
    setOpenAlertModal(false)
    onCancel()
	}

	useEffect(()=>{
		if(editFromState){
			setValue('status',editFromState.status_name);
			setDescription(editFromState.original_info.status_description)
			setColor(editFromState.status_color)
		}
	},[editFromState])

	const { errors } = formState

	function editSatus(payload){
		axios.post(`/service-request-status/edit`, payload).then(response => {
			reset(defaultValues)
			closeDialog()
			toast.success('Service ticket status updated successfully.')
			if(props.getServiceRequestStatus){
				props.getServiceRequestStatus()
			}
		}).catch(err => {
			if(err.response.status == 409){
				toast.error(err.response.data.message || 'Something went wrong!')
				return
			}
			toast.error(err.response.data.message || 'Something went wrong!')
		})
	}

	const manageFormData = (formData) => {
		let payload = {
			status_name: formData.status,
			status_description: description,
			status_color: color
		}
		if(editFromState){
			let payload = {
				status_name: formData.status,
				status_description: description,
				edit_id: Number(editFromState.id),
				status_color: color
			}
			editSatus(payload)
		}else{
			axios.post('/service-request-status/add', payload).then(response => {
				reset(defaultValues)
				closeDialog()
				toast.success('Service ticket status added successfully.')
				if(props.getServiceRequestStatus){
					props.getServiceRequestStatus()
				}
			}).catch(err => {
				toast.error(err.response.data.message || 'Something went wrong!')
			})
		}	
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	function onEditorContentChange(content) {
    setDescription(content)
  }


	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				{/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{Boolean(editFromState) ? 'Update' : 'Add'} Service Ticket Status</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div> */}
				<div className='heading_holder'>
          <h4 className='heading'>{Boolean(editFromState) ? 'Update' : 'Add'} Service Ticket Status</h4>
          <IconButton style={{ padding: '0px' }} onClick={ handleAlertModal }>
            <Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
          </IconButton>
        </div>
        <div className='progressbar_holder'>
          <div className='progress'>
            &nbsp;
          </div>
        </div>

				{/* <form onSubmit={ handleSubmit(manageFormData, errorData) }> */}

					{/*--------------------------Item Information----------------------------- */}
					<div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
							<div className='content_holder'>
								<div className="text-left">
									<div className='mb-10'>
										<Typography className='status_text'>Status Name
										<span className='required_span'>*</span>
										</Typography>
										<Controller
											name='status' 
											control={control}
											render={( { field} ) => (
											<>
												<TextField 
													{...field}
													style={{background: 'white'}}
													variant='outlined'
													// label='Status Name' 
													autoFocus
													fullWidth
													required
													error={!!statusFormErrors.status}
                            helperText={statusFormErrors?.status?.message}
                            FormHelperTextProps={{
                              style: {
                                margin: 0,
                                width: '100%',
                                paddingTop: '2px',
                                fontSize: '10px'
                              }
                            }}
												/>
											</>
											)}
										/>
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>Status Description
										{/* <span className='required_span'>*</span> */}
										</Typography>
										{/* <Controller
										name='status_description' 
										control={control}
										render={( { field} ) => (
											<>
												<TextField 
													{...field}
													style={{background: 'white',resize: 'auto'}}
													variant='outlined'
													// label='Status Description' 
													fullWidth
													// required
													multiline
													rows={10}
													inputProps={{
														style: {
														resize: "vertical",
														},
													}}
												/>
											</>
										)}
									/> */}
									<Editor
                      editorContent={description}
                      onEditorContentChange={onEditorContentChange}
                    />
									</div>
									<div className='mb-10'>
										<Typography className='status_text'>Choose Color
										{/* <span className='required_span'>*</span> */}
										</Typography>
										<ColorPicker setColor={setColor} color={color}/>

									</div>
								</div>
							</div>
							<div className='btn_holder'>
                <Button
                  color="inherit"
                  onClick={ handleAlertModal }
                  className='back_btn secondary_btn'
                >
                  Cancel
                </Button>
                <Button onClick={handleSubmit(manageFormData)} className='next_btn'>
                  Proceed
                </Button>
              </div>
						</div>

						{/* <div>
							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='status' 
										control={control}
										render={( { field} ) => (
										<>
											<TextField 
												{...field}
												style={{background: 'white'}}
												variant='outlined'
												label='Status Name' 
												autoFocus
												fullWidth
												required
											/>
										</>
										)}
									/>
								</div> 
							</div>

							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='status_description' 
										control={control}
										render={( { field} ) => (
											<>
												<TextField 
													{...field}
													style={{background: 'white',resize: 'auto'}}
													variant='outlined'
													label='Status Description' 
													fullWidth
													// required
													multiline
													rows={10}
													inputProps={{
														style: {
														resize: "vertical",
														},
													}}
												/>
											</>
										)}
									/>
								</div> 
							</div>

							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%',display:'flex',alignItems:'center' }}>
									<Typography style={{paddingRight: '10px',fontSize:'16px'}}>Status Color</Typography>
									<ColorPicker setColor={setColor} color={color}/>
								</div> 
							</div>
						</div>	 */}
					</Box>
					</div>

					{/* <div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button 
							className={classes.endButtons} 
							style={{marginRight: '10px'}} 
							type='submit' variant='contained' 
							color='primary'
						>
							{editFromState ? 'Update' : 'Add'}
						</Button>
						<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
					</div> */}
				{/* </form>         */}
			</Drawer>
			{openAlerModal &&
                <AlertModal  openAlerModal module="service ticket status" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
		</div>
	)
}

export default AddServiceRequestStatusDialog
