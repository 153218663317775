const initialState = {
	open: false,
	quick_filter: null,
	asset_quick_filter: null,
	asset_count: 0,
	tab_value: 0
}

const actionTypes = {
    OPEN_REPORT_VIEWER_DIALOG: "OPEN_REPORT_VIEWER_DIALOG",
    CLOSE_REPORT_VIEWER_DIALOG: "CLOSE_REPORT_VIEWER_DIALOG",
		SET_ASSET_QUICK_FILTER: "SET_ASSET_QUICK_FILTER",
		HANDLE_QUICK_FILTER: "HANDLE_QUICK_FILTER",
		SET_ASSET_COUNT: 'SET_ASSET_COUNT',
		GROUP_VIEW_TAB_VALUE: 'GROUP_VIEW_TAB_VALUE'
}

export function setGroupViewTabValue(tab_value = 0){
	return {
			type: actionTypes.GROUP_VIEW_TAB_VALUE,
			payload: {
				tab_value
		}
	}
}

export function openReportViewerDialog(){
	return {
			type: actionTypes.OPEN_REPORT_VIEWER_DIALOG,
	}
}

export function closeReportViewerDialog(){
	return {
			type: actionTypes.CLOSE_REPORT_VIEWER_DIALOG,
	}
}

export function setAssetQuickFilter(query = null){
	return {
			type:actionTypes.SET_ASSET_QUICK_FILTER,
			payload: {
					query
			}
	}
}

export function setQuickFilter(query = null){
	return {
			type:actionTypes.HANDLE_QUICK_FILTER,
			payload: {
					query
			}
	}
}

export function setAssetCount (asset_count = 0){
	return {
			type:actionTypes.SET_ASSET_COUNT,
			payload: {
					asset_count
			}
	}
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.OPEN_REPORT_VIEWER_DIALOG:{
				return {
						...state,
						open: true,
				}
		}
		case actionTypes.CLOSE_REPORT_VIEWER_DIALOG:{
					return {
							...state,
							open: false,
					}
		}

		case actionTypes.HANDLE_QUICK_FILTER:{
				return {
						...state,
						quick_filter: action.payload.query
				}
		}
		case actionTypes.SET_ASSET_QUICK_FILTER:{
			return {
					...state,
					asset_quick_filter: action.payload.query
			}
	}
	case actionTypes.SET_ASSET_COUNT :{
		return {
				...state,
				asset_count : action.payload.asset_count
		}
	}
	case actionTypes.GROUP_VIEW_TAB_VALUE :{
		return {
				...state,
				tab_value : action.payload.tab_value
		}
	}
	  default:
		return state
	}
}