import { useCurrentUser } from '@fuse/hooks';
import { navItemPermission } from '@fuse/utils/permission';
import { ContactSupportOutlined } from '@material-ui/icons';
const components = {};

export function registerComponent(name, Component) {
	components[name] = Component;
}


export default function FuseNavItem(props) {
	const user = useCurrentUser()
	if(!navItemPermission(props.item.id,user.data.agent_type)){
		return null
	}

	const C = components[props.type];
	return C ? <C {...props} /> : null;
}
