import useToast from '@fuse/hooks/useToast'
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { Breadcrumbs, Link, Card, CardContent, Box } from '@mui/material';
import { IconTicket, IconUserUp, IconLayoutDistributeHorizontal, IconUserCheck, IconUserX } from '@tabler/icons-react';
import ListView from './listView';


function CardedFullWidth2TabbedSample(props) {
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [analytics, setAnalytics ] = useState(null)

	function getAnalytics(){
		axios.get('/super-admin/overview-metrics').then((res)=>{
			setAnalytics(res.data.data)
		}).catch((err)=>{
			console.log(err)
		})
	}

	useEffect(()=>{
		getAnalytics();
	},[]);

	return (
		< div style={{padding: 28}} >
			<div role="presentation" className="cstm_breadcrumbs_holder pt-0">
				<Breadcrumbs aria-label="breadcrumb">
					<Link underline="hover" color="inherit">
						Dashboard
					</Link>
					<span>Overview</span>
				</Breadcrumbs>
			</div>
			<h4 className='main_page_heading'>Admin Dashboard</h4>
			<div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px', gap: '12px' }}>
				<div className='dashboard_card' style={{cursor: 'auto'}}>
					<Card className='dashboard_cardshadow'>
						<CardContent style={{ height: '100%', padding: '24px' }}>
							<div>
								<span className='dashboardPattern_img'>
									<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
									</svg>
								</span>
								<Box sx={{ alignContent: 'center', display: 'flex', }}>
									<span className='dashboard_cardIcon icon_holder purple_icon_holder'>
										<IconUserUp size={18} />
									</span>
								</Box>
								<div className='dashboard_cardDetails'>
									<p>Total Clients</p>
									<h5>{analytics?.clientCount ?? 0}</h5>
								</div>
							</div>
						</CardContent>
					</Card>
				</div>
				<div className='dashboard_card' style={{cursor: 'auto'}}>
					<Card className='dashboard_cardshadow'>
						<CardContent style={{ height: '100%', padding: '24px' }}>
							<div>
								<span className='dashboardPattern_img'>
									<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
									</svg>
								</span>
								<Box sx={{ alignContent: 'center', display: 'flex', }}>
									<span className='location_icon icon_holder deepyellow_icon_holder'>
										<IconLayoutDistributeHorizontal size={18} />
									</span>
								</Box>
								<div className='dashboard_cardDetails'>
									<p>Total Assets</p>
									<h5>{analytics?.totalAssetCount ?? 0}</h5>
								</div>
							</div>
						</CardContent>
					</Card>
				</div>
				<div className='dashboard_card' style={{cursor: 'auto'}}>
					<Card className='dashboard_cardshadow'>
						<CardContent style={{ height: '100%', padding: '24px' }}>
							<div>
								<span className='dashboardPattern_img'>
									<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
									</svg>
								</span>
								<Box sx={{ alignContent: 'center', display: 'flex', }}>
									<span className='ticket_icon icon_holder skyblue_icon_holder'>
										<IconUserCheck size={18} />
									</span>
								</Box>
								<div className='dashboard_cardDetails'>
									<p>Active Clients</p>
									<h5>{analytics?.activeClientCount ?? 0}</h5>
								</div>			

							</div>
						</CardContent>
					</Card>
				</div>
				<div className='dashboard_card' style={{cursor: 'auto'}}>
					<Card className='dashboard_cardshadow'>
						<CardContent style={{ height: '100%', padding: '24px' }}>
							<div>
								<span className='dashboardPattern_img'>
									<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
									</svg>
								</span>
								<Box sx={{ alignContent: 'center', display: 'flex', }}>
									<span className='active_assetsIcon icon_holder yellow_icon_holder'>
										<IconUserX size={18} />
									</span>
								</Box>
								<div className='dashboard_cardDetails'>
									<p>Inactive Clients</p>
									<h5>{analytics?.inActiveClientCount ?? 0}</h5>
								</div>
							</div>
						</CardContent>
					</Card>
				</div>
				<div className='dashboard_card' style={{cursor: 'auto'}}>
					<Card className='dashboard_cardshadow'>
						<CardContent style={{ height: '100%', padding: '24px' }}>
							<div>
								<span className='dashboardPattern_img'>
									<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB" />
										<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB" />
									</svg>
								</span>
								<Box sx={{ alignContent: 'center', display: 'flex', }}>
									<span className='active_assetsIcon icon_holder red_icon_holder'>
										<IconTicket size={18} />
									</span>
								</Box>
								<div className='dashboard_cardDetails'>
									<p>Total Tickets</p>
									<h5>{analytics?.totalServiceTicketsCount ?? 0}</h5>
								</div>
							</div>
						</CardContent>
					</Card>
				</div>
			</div>

			<div className="w-full flex flex-col">
				<ListView />
			</div>
		</div>
	)
}

export default withReducer('SuperAdminDashboard', reducer)(CardedFullWidth2TabbedSample);
