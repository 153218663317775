import useToast from '@fuse/hooks/useToast'
import {
	Button, Icon,
	IconButton, makeStyles, TextField, Typography,
	Drawer
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box } from '@mui/material';
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft: '20px',
		marginRight: '20px',
		marginBottom: '20px',
		padding: '10px',
		backgroundColor: '#f4f4f4',
		borderRadius: '4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 600
	},
}))

function AddServiceRequestSourceDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.ServiceRequestSourceApp.formViewDialog)
	const { data: editFromState } = useSelector(({ ServiceRequestSourceApp }) => ServiceRequestSourceApp.formViewDialog);
	const [openAlerModal, setOpenAlertModal] = useState(false);

	//form validation
	const schema = yup.object({
		source: yup.string().required('Source name is required!')
		.matches(/^[A-Za-z\s]+$/, 'Source name cannot contain numbers, alphanumeric characters, or special characters!')
		.matches(/\S/, 'Source name cannot be only spaces!')
		.max(30, 'Source name must be at most 30 characters!'),
	})

	const defaultValues = {
		source: ''
	}

	const { control, formState: { errors: sourceFormErrors }, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});
	const { errors } = formState

	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
	}

	useEffect(() => {
		if (editFromState) {
			setValue('source', editFromState.source_name);
		}
	}, [editFromState])

	function editSatus(payload) {
		axios.post(`/service-request-source/edit`, payload).then(response => {
			if (response.status == 201) {
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success('Service ticket source updated successfully.')
				if (props.getServiceRequestSource) {
					props.getServiceRequestSource()
				}
			}
		}).catch(err => {
			toast.error(err.response.data.message || 'Something went wrong!')
		})
	}

	const manageFormData = (formData) => {
		let payload = {
			source_name: formData.source
		}
		if (editFromState) {
			let payload = {
				source_name: formData.source,
				edit_id: Number(editFromState.id),
			}
			editSatus(payload)
		} else {
			axios.post('/service-request-source/add', payload).then(response => {
				reset(defaultValues)
				closeDialog()
				toast.success('Service ticket source added successfully.')
				if (props.getServiceRequestSource) {
					props.getServiceRequestSource()
				}
			}).catch(err => {
				toast.error(err.response.data.message || 'Something went wrong!')
			})
		}
	}

	const handleAlertModal = (_, reason) => {
		if (reason === 'backdropClick') {
			return;
		}

		setOpenAlertModal(!openAlerModal)

	}
	const handleCloseAlertModal = () => {
		setOpenAlertModal(false)
		onCancel()
	}


	return (
		<>
			<div>
				<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
					<div className='heading_holder'>
						<h4 className='heading'>{Boolean(editFromState) ? 'Update' : 'Add'} Service Ticket Source</h4>
						<IconButton style={{ padding: '0px' }} onClick={handleAlertModal}  >
							<Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
						</IconButton>
					</div>
					<div className='progressbar_holder'>
						<div className='progress'>
							&nbsp;
						</div>
					</div>

					{/* <form onSubmit={ handleSubmit(manageFormData, errorData) }> */}

					{/*--------------------------Item Information----------------------------- */}
					<div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
						<Box sx={{ width: '100%' }} className='box_holder'>
							<div className='main_content_holder'>
								<div className='content_holder'>
									<div className="text-left">
										<div className='mb-10'>
											<Typography className='status_text'>Source Name
												<span className='required_span'>*</span>
											</Typography>
											<Controller
												name='source'
												control={control}
												render={({ field }) => (
													<>
														<TextField
															{...field}
															style={{ background: 'white' }}
															variant='outlined'
															autoFocus
															fullWidth
															required
															error={!!sourceFormErrors.source}
															helperText={sourceFormErrors?.source?.message}
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	width: '100%',
																	paddingTop: '2px',
																	fontSize: '10px'
																}
															}}
														/>
													</>
												)}
											/>
										</div>
									</div>
								</div>
								<div className='btn_holder'>
									<Button
										color="inherit"
										onClick={handleAlertModal}
										className='back_btn secondary_btn'
									>
										Cancel
									</Button>
									<Button onClick={handleSubmit(manageFormData)} className='next_btn'>
										Proceed
									</Button>
								</div>
							</div>
						</Box>
					</div>
				</Drawer>
			</div>

			{openAlerModal &&
				<AlertModal openAlerModal module="service ticket source" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal} />
			}
		</>

	)
}

export default AddServiceRequestSourceDialog
