import React from 'react'
import Icon from '@material-ui/core/Icon';
import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SeatsTab from './SeatsTab';
import axios from "@fuse/utils/axios";
import { useDispatch } from 'react-redux';
import { openUserFormDialog } from '../store/formViewDialogReducer';
import { openUserCsvDialog } from "../store/csvDialogReducer"
import ActivitiesTab from './ActivitiesTab';
import Tooltip from '@material-ui/core/Tooltip';
import { usePermission } from '@fuse/hooks';


const useStyles = makeStyles({
    activeTab: {
        background: "rgba(107, 44, 87, 0.04);",
        borderRadius: "0",
    },
    icon: {
        color: '#61656F',
        display: 'inline-block',
        fontSize: '18px'
    }
})

const AccessoriesDialog = ({ fullWidth, maxWidth, open, setOpen, Close, TabButton, tabState, setTabState, TabContainer, accessoryId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [accessoryDetails, setAccessoryDetails] = useState(null)
    async function getAccessoryDetails() {
        setLoading(true)
        axios.get(`/accessory/accessory-details/${accessoryId}`)
            .then(res => {
                setAccessoryDetails(res.data.data.details)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(true)
            })
    }

    useEffect(async () => {
        getAccessoryDetails()
    }, [open])

    function addButtonClick() {
        dispatch(openUserFormDialog())
    }
    return (
        <>
            {/* <AddUserDialog accessoryId={accessoryId}/> */}
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                aria-labelledby="max-width-dialog-title"
                disableEnforceFocus
            >
                <DialogTitle
                    id="max-width-dialog-title"
                    style={{
                        textAlign: "right",
                        fontSize: "20px",
                        cursor: "pointer",
                        padding: '0 30px 0'
                    }}
                >
                    <Icon
                        style={{ transform: "translateY(5px)" }}
                        onClick={Close}
                    >
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </Icon>
                </DialogTitle>
                <DialogContent>
                    {loading ?
                        <>
                            <Box
                                style={{ width: "100%", height: "220px" }}
                                className='flex items-center justify-center'
                            >
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress />
                                </Box>
                            </Box>
                        </> :
                        <>
                            <div style={{ background: '#F5F5F5', padding: "5px" }}>
                                <Grid container spacing={1}>
                                    <Grid item lg={12}>
                                        <Card style={{ borderRadius: "0", padding: "14px 25px" }}>
                                            <Grid container>
                                                {/* Hedaer part */}
                                                <Grid item lg={12}>
                                                    <Typography variant='h6' className='mb-24'>{accessoryDetails?.accessory_name || 'N/A'}</Typography>
                                                </Grid>
                                                {/* Subheader left side */}
                                                <Grid item lg={6}>
                                                    <Grid container >
                                                        <Grid item lg={12}>
                                                            <Typography variant="body2" style={{ display: "flex", marginBottom: "5px" }}>
                                                                <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M8.75 4a2.75 2.75 0 0 1 2.75 2.75v10.5A2.75 2.75 0 0 1 8.75 20h-4A2.75 2.75 0 0 1 2 17.25V6.75A2.75 2.75 0 0 1 4.75 4h4Zm0 1.5h-4c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25ZM19.25 4A2.75 2.75 0 0 1 22 6.75v10.5A2.75 2.75 0 0 1 19.25 20h-4a2.75 2.75 0 0 1-2.75-2.75V6.75A2.75 2.75 0 0 1 15.25 4h4Zm0 1.5h-4c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25ZM6 9v6a.75.75 0 0 0 1.5 0V9A.75.75 0 0 0 6 9Zm11.5.75v1.5h-1.25a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h2a.75.75 0 0 0 0-1.5H17v-1.5h1.25A.75.75 0 0 0 19 12V9a.75.75 0 0 0-.75-.75h-2a.75.75 0 0 0 0 1.5h1.25Z" />
                                                                </svg>
                                                                <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Model Number : </span>
                                                                <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>
                                                                    {accessoryDetails?.model_number || 'N/A'}
                                                                </span>
                                                            </Typography>
                                                            <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                                <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                    <path fill="currentColor" d="M2.75 17h12.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 17h12.5h-12.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 13h18.5h-18.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 9h18.5h-18.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 5h18.5h-18.5Z" />
                                                                </svg>
                                                                <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Description : </span>
                                                                <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>
                                                                    {/* {accessoryDetails?.note || 'N/A'} */}
                                                                    {/* n/a */}
                                                                    <Tooltip title={accessoryDetails?.note} placement="top-start">
                                                                        <span>{`${accessoryDetails?.note.length>30 ? accessoryDetails?.note.slice(0,30) : accessoryDetails?.note}...`}</span>
                                                                    </Tooltip>
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* Subheader right side */}
                                                <Grid item lg={6}>
                                                    <Grid container>
                                                        <Grid item lg={12}>
                                                            <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                                <svg className='subs-pen-icon' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16">
                                                                    <path d="M15.25 12H15V8.75C15 7.785 14.215 7 13.25 7H9V5h.25c.412 0 .75-.338.75-.75v-2.5A.752.752 0 0 0 9.25 1h-2.5a.752.752 0 0 0-.75.75v2.5c0 .412.338.75.75.75H7v2H2.75C1.785 7 1 7.785 1 8.75V12H.75a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.413 0 .75-.338.75-.75v-2.5a.752.752 0 0 0-.75-.75H3V9h4v3h-.25a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.412 0 .75-.338.75-.75v-2.5a.753.753 0 0 0-.75-.75H9V9h4v3h-.25a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.412 0 .75-.338.75-.75v-2.5a.753.753 0 0 0-.75-.75zM3 15H1v-2h2v2zm6 0H7v-2h2v2zM7 4V2h2v2H7zm8 11h-2v-2h2v2z" />
                                                                </svg>
                                                                <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "7px" }}>Accessory Category Name : </span>
                                                                <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>
                                                                    {accessoryDetails?.accessory_category?.accessory_category_name || 'N/A'}
                                                                </span>
                                                            </Typography>
                                                            <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                                <svg className='subs-pen-icon' xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24">
                                                                    <path d="M21 19H23V21H1V19H3V4C3 3.44772 3.44772 3 4 3H14C14.5523 3 15 3.44772 15 4V19H19V11H17V9H20C20.5523 9 21 9.44772 21 10V19ZM5 5V19H13V5H5ZM7 11H11V13H7V11ZM7 7H11V9H7V7Z"></path>
                                                                </svg>
                                                                <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Manufacturer Name : </span>
                                                                <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>
                                                                    {accessoryDetails?.manufacturer?.manufacturer_name || 'N/A'}
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "10px", background: "#fff" }}>
                                    <Grid item lg={2}>
                                        <Card style={{ borderRadius: '0', boxShadow: "none" }}>
                                            <CardActions disableSpacing style={{ display: "flex", flexDirection: "column", borderRadius: '0', boxShadow: 'none' }}>
                                                <TabButton
                                                    tab="seats"
                                                    tabState={tabState}
                                                    tabStateChange={setTabState}
                                                    activeClass={classes.activeTab}
                                                    variant="text"
                                                    color="primary"
                                                    className={`w-full d-flex justify-content-start`}
                                                    style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                                >
                                                    Information
                                                </TabButton>

                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid lg={10}>
                                        <div>
                                            <Card style={{ width: "100%", borderRadius: "0", overflow: "auto" }}>
                                                <CardContent style={{ width: "100%" }}>
                                                    <TabContainer tab="seats" tabState={tabState}>
                                                        <SeatsTab accessoryId={accessoryId} />
                                                    </TabContainer>
                                                    <TabContainer tab="activities" tabState={tabState}>
                                                        <ActivitiesTab accessoryId={accessoryId} />
                                                    </TabContainer>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={Close} variant='contained' color="primary">
                        Close
                    </Button>
                </DialogActions> */}
            </Dialog>

        </>
    )
}

export default AccessoriesDialog
