import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon,
    IconButton, makeStyles, Paper, TextField, 
    Modal, InputAdornment} from '@material-ui/core';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function SoftwareCategoryDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
    //     phone: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		// }),   
    })

    const defaultValues = {
			provider_name: '',
			url: '',
			phone: '',
			address_1: '',
			address_2: '',
			city: '',
			state: '',
			zip: '',
			country: '',
	  }

    const { 
        control, 
        // formState:{errors: statusFormErrors}, 
        handleSubmit: statusFormSubmit, 
        reset: statusFormReset ,
        formState
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { errors } = formState
		const [formattedNumber, setFormattedNumber] = useState('')
		function changeNumberFormat(e){
			setFormattedNumber(formatPhoneNumber(e.target.value))
		}

    function manageProviderData(formData){
				if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
					toast.error("Enter a valid Phone Number!")
					return
				}
        if(formData.url.indexOf('.') == -1){
					toast.error('Please enter a valid URL')
					return
		  	}
				let payload = {
					entity_external_platform_id: String(Math.trunc(Math.random()*200)),
					provider_name:formData.provider_name,
					phone:formattedNumber || '',
					address_1:formData.address_1 || '',
					address_2:formData.address_2 || '',
					city:formData.city || '',
					state:formData.state ||'',
					zip:formData.zip || '',
					country:formData.country.name || '',
					url:"www."+(formData.url).trim(),
		  	}

        axios.post('cloud-provider/add', payload).then(response => {
            if(response.status == 201){
                toast.error(response.data.message)
            } else {
                statusFormReset(defaultValues)
                // response.data.data.new_hardware_asset_status

                //Send newly added status value
                props.handleCloseDialog(response.data.data.new_provider)
                
                toast.success('Subscription Provider added successfully.')
                props.getProvider()
								setFormattedNumber('')
            }
        }).catch(err => {
            console.log('234: ',err);
            toast.error(err.response.data.data)
        })

    }

    function statusFormErrorData(error){
        console.log('statusFormErrorData: ',error);
    }


    return (
        <Modal
            open={props.open}
            onClose={()=>{
                statusFormReset(defaultValues)
                // props.handleCloseDialog()
            }}
            className={classes.modal}
        >
				<div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
								<h1>Add Subscription Provider</h1>
								<IconButton onClick={()=>{
										statusFormReset(defaultValues)
										props.handleCloseDialog()
								}}>
										<Icon>close</Icon>
								</IconButton>
						</div>

						<form onSubmit={statusFormSubmit(manageProviderData, statusFormErrorData)}>
								<div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
									<div style={{ display: 'flex', justifyContent:'center' }}>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name='url' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
													{...field}
													style={{background: 'white'}}
													InputProps={{
														startAdornment: 
														<InputAdornment style={{color: "grey",marginRight:'0'}} disableTypography position="start">
															www.</InputAdornment>,
													}}
													variant='outlined'
													label='URL' 
													autoFocus
													fullWidth   
													required                       
													// error={!!errors.url}
													// helperText={errors?.url?.message}
													FormHelperTextProps={{
														style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
														}
													}}
													/>
												</>
												)}
											/>
										</div> 
									</div>
									<div style={{ display: 'flex', justifyContent:'center' }}>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
													name='provider_name' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='Provider Name' 
														autoFocus
														fullWidth
														required
													/>
												</>
												)}
											/>
										</div> 
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name='phone' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
														{...field}
														style={{background: 'white'}}
														value={formattedNumber}
														variant='outlined'
														label='Phone Number' 
														onKeyPress={(event) => {
															if (!/[0-9/-]+/.test(event.key)) {
																event.preventDefault();
															}
														}}
														onChange={(e)=>{
															changeNumberFormat(e)
														}}
														autoFocus
														fullWidth
														FormHelperTextProps={{
															style: {
																margin: 0,
																backgroundColor: '#f4f4f4',
																width: '100%',
																paddingTop: '2px'
															}
														}}
														error={!!errors.phone}
														helperText={errors?.phone?.message}
													/>
												</>
												)}
											/>
										</div>
									</div>
									<div style={{ display: 'flex', justifyContent:'center' }}>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name='address_1' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='Address Line 1' 
														autoFocus
														fullWidth
														// required
													/>
												</>
												)}
											/>
										</div>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name='address_2' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='Address Line 2' 
														autoFocus
														fullWidth
														// required
													/>
												</>
												)}
											/>
										</div> 
									</div>
									<div style={{ display: 'flex', justifyContent:'center' }}>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name='city' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='City' 
														autoFocus
														fullWidth
														// required
													/>
												</>
												)}
											/>
										</div>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name='state' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='State' 
														autoFocus
														fullWidth
														// required
													/>
												</>
												)}
											/>
										</div> 
									</div>
									<div style={{ display: 'flex', justifyContent:'center' }}>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
											<Controller
												name='zip' 
												control={control}
												render={( { field} ) => (
												<>
													<TextField 
														{...field}
														style={{background: 'white'}}
														variant='outlined'
														label='Zip' 
														autoFocus
														fullWidth
														// required
													/>
												</>
												)}
											/>
										</div>
										<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
										<Controller
											name="country"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={countries}
													// disablePortal
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop:'0px' }}>{children}</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={(params) => {
														return (
														<div ref={params.InputProps.ref}>
															<TextField
																{...params}
																label="Country"
																style={{background: 'white'}}
																//   error={!!errors.country}
																//   helperText={errors?.country?.message}
																//   FormHelperTextProps={{
																//       style: {marginLeft : 0}
																//   }}
																inputRef={ref}
																variant="outlined"
																fullWidth
															/>
														</div>
														)
													}}
												/>
											</>
											)}
								/>
										</div> 
									</div>
								</div>
								<div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
										<Button
												variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
												type='submit'
										>Add</Button>
										<Button 
												variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
												onClick={()=>{
														statusFormReset(defaultValues)
														props.handleCloseDialog()
												}}
										>Cancel</Button>
								</div>
						</form>
				</div>

        </Modal>
    )
}

export default SoftwareCategoryDialog