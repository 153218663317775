import useToast from '@fuse/hooks/useToast'
import {
	Accordion, AccordionDetails, AccordionSummary, Button, 
	DialogTitle, Icon,IconButton, makeStyles, Paper, 
	TextField, Typography, Drawer} from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import UserDialog from '@fuse/components/Modal/QuickAddUser'
import {closeUserFormDialog, setUserListChange,setUserFromData } from '../store/formViewDialogReducer';

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px !important',
			marginRight: '20px !important',
			marginBottom: '20px !important',
			padding: '10px',
			backgroundColor: '#f4f4f4',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		customDrawerPaper: {
			maxWidth: '600px'
		}
	}
))

function AddUserDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.subscriptionApp.formViewDialog)
	const { data: editFromState } = useSelector((state) => state.subscriptionApp.formViewDialog)	
	const { sub_data: sub_desc } = useSelector((state) => state.subscriptionApp.formViewDialog)
	const { edit_user_data: edit_user_data } = useSelector((state) => state.subscriptionApp.formViewDialog)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	//new
	const [subscriptionCategory,setSubscriptionCategory] = useState([])
	const [dropDownHeight,setDropDownHeight] = useState(100)
	const [translateDropdown,setTranslateDropdown] = useState(23)
	const [provider,setProvider] = useState([])
	const [department,setDepartment] = useState([])
	const [substatus,setSubStatus] = useState([])
	const [purchaseOrder,setPurchaseOrder] = useState('purchaseOrder')
	const [selectDepartment,setSelectDepartment] = useState([])
	const [selectDepartmentId,setSelectDepartmentId] = useState([])
	const [createNewDept, setCreateNewDept] = useState(false)
	const [createCategory, setCreateCategory] = useState(false)
	const [createStatus, setcreateStatus] = useState(false)
	const [createProvider, setCreateProvider] = useState(false)
	const [data, setData] = useState([]);
	const [checkForTerm, setCheckForTerm] = useState(false)
	const [checkForBilling, setCheckForBilling] = useState(false)
	const [inputVal, setInputVal] = useState("");
	const [showOptions, setShowOptions] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);
	const website_ref = useRef("");
    const [usersMenu, setUsersMenu] = useState([])
    const [userId, setUserId] = useState([])
	const [currentUser, setCurrentUser] = useState(null)
	const [prevUser, setPrevUser] = useState(null)
	const [ openUserDialogState, setOpenUserDialogState ] = useState(false)
	const { sub_id: sub_id } = useSelector((state) => state.subscriptionApp.formViewDialog)

	const MenuProps = {
		PaperProps: {
		  style: {
			// marginTop:'56px',
			// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			height:`${dropDownHeight}px`
	,		width: 250,
			transform:`translateY(${translateDropdown}%)`
		  },
		},
		variant: 'menu',
		anchorOrigin: {
		  vertical: "top",
		  horizontal: "center"
		},
		transformOrigin: {
		  vertical: "top",
		  horizontal: "center"
		},
		getContentAnchorEl: null  
	};

	/*function countryToFlag(isoCode) {
		return typeof String.fromCodePoint !== 'undefined'
		  ? isoCode
			  .toUpperCase()
			  .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
		  : isoCode;
	}*/

	//form validation
	const schema = yup.object({
		// phone: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		// }),
	})

	const defaultValues = {
		user: '',
		phone: '',
		email: '',
		description: '',
	}

	useEffect(() => {
		if (edit_user_data) {
			setCurrentUser(edit_user_data)
			setUserId(edit_user_data.id)
			setPrevUser(edit_user_data.id)
			setValue('phone',edit_user_data?.phone)
			setValue('description',edit_user_data?.description || '')
			setValue('email',edit_user_data?.email)
		
		}
	}, [edit_user_data])

	const onCancel = () => {
		reset(defaultValues)
		setCurrentUser(null)
		// dispatch(setFromData(null))
		dispatch(closeUserFormDialog())
		dispatch(setUserFromData(null))
		// dispatch(setUserListChange(false))
	}

	const closeDialog = () => {
		dispatch(closeUserFormDialog())
		dispatch(setUserFromData(null))
		setCurrentUser(null)
		// dispatch(setUserListChange(false))
		// dispatch(setFromData(null))

	}

	const { control, formState, handleSubmit, reset, setValue, clearErrors } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { errors } = formState

	const manageFormData = (formData) => {
		dispatch(setUserListChange(false))
		let payload = {
			user_id: userId,
			phone_number:formData.phone || '',
			subscription_id:sub_id,
			description:formData.description || ''
		}
		if(edit_user_data){
			let payload = {
				user_id: userId,
				phone_number:formData.phone || '',
				subscription_id:sub_id,
				description:formData.description || '',
				prev_user_id: prevUser
	
			}
			axios.post('/subscription/update-user', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					closeDialog()
					toast.success(response.data.message)
					props.fetchSubscriptionUser()
					dispatch(setUserListChange(true))
					// if (props.getSubscription) {
					// 	props.getSubscription()
					// 	setSelectDepartment([])
					// }
				}
			}).catch(err => {
				console.log('212=>', err);
				toast.error('Something went wrong!')
			})
		}else{
			axios.post('/subscription/add-user', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					closeDialog()
					toast.success(response.data.message)
					props.fetchSubscriptionUser()
					dispatch(setUserListChange(true))
					// if (props.getSubscription) {
					// 	props.getSubscription()
					// 	setSelectDepartment([])
					// }
				}
			}).catch(err => {
				console.log('212=>', err);
				toast.error('Something went wrong!')
			})
		}
	}

	const errorData = (error) => {
		console.log('363=>', error)
		// error.subscription_name.ref.scrollIntoView({ behavior: 'smooth', block: 'center' })
		// errorElement.focus({ preventScroll: true });
	}

    const fetchUserSuggestions = () => {
		axios.get('/assets/fetch-users-suggestion').then(response => {
			let users = AlphabeticalSorting(response.data.data.users,'first_name')
			setUsersMenu(users)
		}).catch(err => {
			console.log('164=>',err);
			toast.error('Something went wrong!')
		})
	}

	const handleClickOpenUserDialog = () => {
		setOpenUserDialogState(true)
	};

	const handleCloseUserDialog = (new_user=null) => {
		setOpenUserDialogState(false)

		//Set Quick added USER value into user field
		if(new_user){
			setCurrentUser({
				email: new_user.email,
				first_name: new_user.first_name,
				id: new_user.id,
				last_name: new_user.last_name,
				phone: new_user.phone,
			})
			setUserId(new_user?.id)
			setValue('email',new_user?.email || '')
			setValue('phoe',new_user?.phone || '')
		}
	};

	function getUserDetail(user){
		setCurrentUser(user)
		setUserId(user?.id)
		setValue('phone',user?.phone || '')
		setValue('email',user?.email || '')
	}

	useEffect(() => {
		fetchUserSuggestions()
	}, [])


	return (
		<div>
			<UserDialog
				open={openUserDialogState}
				handleCloseDialog={handleCloseUserDialog}
				refreshList={fetchUserSuggestions}
			/>

			<Drawer anchor='right' open={openState.user_form} classes={{ paper: classes.customDrawerPaper }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<DialogTitle>{edit_user_data ? 'Update' : 'Add'} User</DialogTitle>
					<IconButton style={{ marginRight: '15px' }} onClick={onCancel}>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={handleSubmit(manageFormData, errorData)} >
					{/*--------------------Contract Description----------------------- */}
					<Accordion defaultExpanded={true} className={classes.subSection}>
						<AccordionSummary
							style={{ pointerEvents: 'none' }}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>User Information</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="user"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
										<>
											<Autocomplete 
											style={{ width: '100%' }}
											options={usersMenu}
											disablePortal
											value={currentUser}
											defaultValue={currentUser}
											onChange={(event, value) => { getUserDetail(value) }}
											onInputChange={(event) => {
												if(event && event.target) {
													// manageCurrentUser(event.target.innerText)
												}
											}}
											PaperComponent={({ children }) => (
												<Paper style={{ marginTop:'10px'}}>{children}
													{<Button 
														style={{width:'100%'}} 
														variant="outlined" 
														color="primary" 
														onMouseDown={() => handleClickOpenUserDialog()}
													>Add User</Button>}
												</Paper>
											)}
											getOptionLabel={option => `${option.first_name || ''} ${option.last_name || ''} (${option?.email || ''})` }
											renderInput={params => {

												return (
													<div ref={params.InputProps.ref}>
														<TextField
															{...params}
															style={{background: 'white'}}
															label="User Name"
															required
															// error={!!errors.userId}
															// helperText={errors?.userId?.message}
															// FormHelperTextProps={{
															// 	style: {marginLeft : 0}
															// }}
															variant="outlined"
															fullWidth
														/>
													</div>
												)
											}}
										/>
											</>
										)}
									/>

								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<div style={{marginRight:"10px"}}>
									<Controller
										name="phone"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													inputRef={website_ref}
													{...field}
													variant='outlined'
													label='Phone Number'
													fullWidth
													disabled
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
                                <div>
									<Controller
										name="email"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													inputRef={website_ref}
													{...field}
													variant='outlined'
													label='Email'
													fullWidth
													required
													disabled
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>
                            <div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', width: '100%' }}>
									<Controller
										name='description' 
										control={control}
										render={( { field} ) => (
											<>
												<TextField 
													{...field}
													style={{background: 'white',resize: 'auto'}}
													variant='outlined'
													label='Description' 
													fullWidth
													multiline
													rows={5}
													inputProps={{
														style: {
														resize: "vertical",
														},
													}}
												/>
											</>
										)}
									/>
								</div> 
							</div>
						</AccordionDetails>
					</Accordion>

					<div style={{ padding: 0, margin: 20, display: 'flex', justifyContent: 'left' }}>
						<Button className={classes.endButtons} style={{ marginRight: '10px' }} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{edit_user_data ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons} variant='outlined' color='primary' onClick={onCancel}>Cancel</Button>
					</div>

				</form>
			</Drawer>
		</div>
	)
}

export default AddUserDialog
