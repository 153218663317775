import _ from '@lodash';
import TablePagination from '@fuse/components/general/TablePagination';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListView from './listView';
import GridView from './gridView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
  const classes = useStyles();
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(props.endUsersAccounts.length);
	const [loading, setLoading] = useState(false);
	let endUsersAccountsMod = props.endUsersAccounts
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	function handleChangePage(event, value) {
		setPage(value);
	}

	// useEffect(()=>{
	// 	setRowsPerPage(props.endUsersAccounts.length);
	// },[props.endUsersAccounts])

	useEffect(()=>{
		setRowsPerPage(props.endUsersAccounts.length)
		setPage(0)
	},[props.endUsersAccounts.length])
	
	function handleChangeRowsPerPage(rowsPerPage) {
		setRowsPerPage(rowsPerPage);
		setPage(0);
	}

	const viewManufacturersPermission = props.viewManufacturersPermission;

	if(loading){
		return (<FuseLoading/>)
	}

	// if(!viewManufacturersPermission){
	// 	return (
	// 		<div style={{margin:'20px'}}>You don't have the view permission</div>
	// 	)
	// }

	if (!props.viewContractTypePermission) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Contract Types at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}

	if(endUsersAccountsMod.length <= 0){
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-center h-full"
			>
				<div style={{height: "200px"}}>
					<Typography variant="body2" component="p" color="textSecondary">
						No data found
					</Typography>
				</div>
			</motion.div>
		)
	}

	return (
		<div className="w-full flex flex-col">
			
			{/* <motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-end h-full"
			>
				<Button
				color="primary"
					onClick={()=>props.openAddAttributeDialog(props.platformModuleId)}
				>
					Add field
				</Button>
			</motion.div> */}

			<SwitchView 
				selectedView={props.selectedView}
				endUsersAccounts={endUsersAccountsMod} 
				refresh={props.refresh}
				viewContractTypePermission = { props.viewContractTypePermission }
				createContractTypePermission = { props.createContractTypePermission }
				updateContractTypePermission = { props.updateContractTypePermission }
				deleteContractTypePermission = { props.deleteContractTypePermission }	
			/>

			{/* <div className="p-10">
				<TablePagination
					className="flex-shrink-0 border-t-1 mt-10"
					component="div"
					count={props.endUsersAccounts.length}
					rowsPerPage={rowsPerPage !== props.endUsersAccounts.length ? rowsPerPage : 'all'}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div> */}
		</div>
	);

}

function SwitchView({endUsersAccounts,selectedView,refresh,viewContractTypePermission,
	createContractTypePermission,updateContractTypePermission,deleteContractTypePermission}) {
	if(selectedView == 0){
		return(
			<ListView 
				endUsersAccounts={endUsersAccounts}
				refresh={refresh}
				viewContractTypePermission = { viewContractTypePermission }
				createContractTypePermission = { createContractTypePermission }
				updateContractTypePermission = { updateContractTypePermission }
				deleteContractTypePermission = { deleteContractTypePermission } 
			/>
		)
	}else{
		return(<GridView endUsersAccounts={endUsersAccounts} />)		
	}
}

export default withRouter(EndUsersAccountsTable)
