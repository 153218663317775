import React, { useEffect, useState } from 'react'
import { Button, Icon, IconButton, Modal, TextField, Paper, Typography } from '@material-ui/core'
import axios from '@fuse/utils/axios';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import Editor from '@fuse/components/Editor';
import LocationTypeDialog from '@fuse/components/Modal/QuickAddLocationType';

function ShippingLocationQuickAdd({
    createNewShippingLocation,
    modal,
    setCreateNewShippingLocation,
    manageShippingFormData,
    shippingFormErrorData
}) {

    const [ locationNote,setLocationNote ] = useState('')
    const [ locationTypes, setLocationTypes ] = useState([])

	const shippingLocationDefaultValues = {
		shipToCode:'',
		shipAddress:'',
        address2: '',
		shipLocationName: '',
		shipCity: '',
		shipState:'',
		shipZip:'',
		shipCountry: {},
		locationType: null
	}

	const shippingLocationSchema = yup.object({
		shipAddress: yup.string().required('Address Line 1 is required!'),
		shipLocationName: yup.string().required('Location Name is required!'),
		shipCity: yup.string().required('City is required!'),
		shipState: yup.string().required('State is required!'),
		shipZip: yup.string().required('Zip is required!'),
		shipCountry: yup.object().nullable().required('Country is required!')
	})

	const { control: shippingLocationFormControl, 
		formState:{errors: shippingLocationFormErrors}, 
		handleSubmit: shippingLocationFormSubmit, 
		reset: shippingLocationFormReset,
        setValue
	} = useForm({
		mode: 'onChange',
		defaultValues :shippingLocationDefaultValues,
		resolver: yupResolver(shippingLocationSchema)
	});

    const onSubmitForm = (formData) => {
        formData.note = locationNote
        manageShippingFormData(formData)
        shippingLocationFormReset(shippingLocationDefaultValues)
    }

    function onEditorContentChange (content) {
        setLocationNote(content)
    }

    function getLocationTypes(){
		axios.get("/location-type").then((res) => {
			const { data } = res.data;
			setLocationTypes((()=>{
                return data.map((item)=>{
                    return {
                        id: item.id,
                        name: item.name
                    }
                })
            })())
		}).catch((err) => {
			console.log(err)
		})
	}

    const [createNewLocationType,setCreateNewLocationType] = useState(false)

    const handleLocationTypeDialogClose = (new_location_type=null) => {
        setCreateNewLocationType(false)

        //Set Quick added LOCATION TYPE value into field
        if(new_location_type){
            console.log('new_location_type: ', new_location_type);
            setValue('locationType',{id:new_location_type.id,name:new_location_type.name})
        }
    }
    const handleLocationTypeDialogOpen = () => {
		setCreateNewLocationType(true)
	}

    useEffect(()=>{
        getLocationTypes()
    },[])

    return (
        <>
            <Modal
                open={createNewShippingLocation}
                className={modal}
            >
                <div style={{width:700,backgroundColor: 'white',borderRadius:9,padding:'25px',display:'flex',flexDirection:'column',height: '100vh', overflow: 'auto'}}>
                    
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                        <h1>Create Location</h1>
                        <IconButton onClick={()=>{
                            shippingLocationFormReset(shippingLocationDefaultValues)
                            setCreateNewShippingLocation(false)
                        }}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>

                    {/* <p style={{marginBottom: '10px'}}>Submit the below form to add a Location.</p> */}

                    <form onSubmit={shippingLocationFormSubmit(onSubmitForm, shippingFormErrorData)}>
                        <div style={{display:'flex',flexDirection:'column', backgroundColor: '#f4f4f4', borderRadius:9, padding: '15px', marginBottom:'10px'}}>                        
                            <Controller
                                name="shipLocationName"
                                className="mb-16"
                                defaultValue={''}
                                control={shippingLocationFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            required
                                            {...field}
                                            variant='outlined'
                                            label='Location Name'
                                            // fullWidth
                                            error={!!shippingLocationFormErrors.shipLocationName}
                                            helperText={shippingLocationFormErrors?.shipLocationName?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px'
                                                }
                                            }}
                                            style={{ display:'flex', width: '100%', marginBottom:'10px', marginRight: '10px', backgroundColor:'#fff' }}
                                        />
                                    </>
                                )}
                            />

                            <Controller
                                name="shipAddress"
                                className="mb-16"
                                defaultValue={''}
                                control={shippingLocationFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            required
                                            {...field}
                                            variant='outlined'
                                            label='Address Line 1'
                                            // fullWidth
                                            error={!!shippingLocationFormErrors.shipAddress}
                                            helperText={shippingLocationFormErrors?.shipAddress?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                        margin : 0, 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px'
                                                }
                                            }}
                                            style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                        />
                                    </>
                                )}
                            />

                            <Controller
                                name="address2"
                                className="mb-16"
                                defaultValue={''}
                                control={shippingLocationFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            label='Address Line 2'
                                            // fullWidth
                                            error={!!shippingLocationFormErrors.shipAddress}
                                            helperText={shippingLocationFormErrors?.shipAddress?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                        margin : 0, 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px'
                                                }
                                            }}
                                            style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                        />
                                    </>
                                )}
                            />

                            <div style={{ display:'flex', width: '100%' }}>
                                
                                <Controller
                                    name="shipCity"
                                    className="mb-16"
                                    defaultValue={''}
                                    control={shippingLocationFormControl}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                required
                                                {...field}
                                                variant='outlined'
                                                label='City'
                                                // fullWidth
                                                error={!!shippingLocationFormErrors.shipCity}
                                                helperText={shippingLocationFormErrors?.shipCity?.message}
                                                FormHelperTextProps={{
                                                    style: { 
                                                        margin : 0, 
                                                        backgroundColor: '#f4f4f4',
                                                        width: '100%',
                                                        paddingTop: '2px'
                                                    }
                                                }}
                                                style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
                                            />
                                        </>
                                    )}
                                />

                                <Controller
                                    name="shipState"
                                    className="mb-16"
                                    defaultValue={''}
                                    control={shippingLocationFormControl}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                required
                                                {...field}
                                                variant='outlined'
                                                label='State/Province'
                                                // fullWidth
                                                error={!!shippingLocationFormErrors.shipState}
                                                helperText={shippingLocationFormErrors?.shipState?.message}
                                                FormHelperTextProps={{
                                                    style: { 
                                                        margin : 0, 
                                                        backgroundColor: '#f4f4f4',
                                                        width: '100%',
                                                        paddingTop: '2px'
                                                    }
                                                }}
                                                style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                            />
                                        </>
                                    )}
                                />

                            </div>

                            <div style={{ display:'flex', width: '100%' }}>

                                <Controller
                                    name="shipZip"
                                    className="mb-16"
                                    defaultValue={''}
                                    control={shippingLocationFormControl}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                required
                                                {...field}
                                                variant='outlined'
                                                label='Zip'
                                                // fullWidth
                                                error={!!shippingLocationFormErrors.shipZip}
                                                helperText={shippingLocationFormErrors?.shipZip?.message}
                                                FormHelperTextProps={{
                                                    style: { 
                                                        margin : 0, 
                                                        backgroundColor: '#f4f4f4',
                                                        width: '100%',
                                                        paddingTop: '2px'
                                                    }
                                                }}
                                                style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
                                            />
                                        </>
                                    )}
                                />

                                <Controller
                                    name="shipCountry"
                                    className="mb-16"
                                    control={shippingLocationFormControl}
                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                        <>
                                            <Autocomplete
                                                {...field}
                                                style={{ width: '100%' }}
                                                options={countries}
                                                // disablePortal
                                                onChange={(event, value) => field.onChange(value)}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ marginTop:'56px',  width:'100%' }}>{children}</Paper>
                                                )}
                                                getOptionLabel={option => option.name ?? ''}
                                                renderInput={(params) => {
                                                    return (
                                                        <div ref={params.InputProps.ref}>
                                                            <TextField
                                                                required
                                                                style={{background: 'white'}}
                                                                {...params}
                                                                label="Country"
                                                                error={!!shippingLocationFormErrors.shipCountry}
                                                                helperText={shippingLocationFormErrors?.shipCountry?.message}
                                                                FormHelperTextProps={{
                                                                    style: { 
                                                                        margin : 0, 
                                                                        backgroundColor: '#f4f4f4',
                                                                        width: '100%',
                                                                        paddingTop: '2px'
                                                                    }
                                                                }}
                                                                inputRef={ref}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </>
                                    )}
                                />

                            </div>
                            <div style={{ display:'flex', width: '100%' }}>
                            <Controller
                                name="locationType"
                                // className="mb-16"
                                control={shippingLocationFormControl}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <>
                                    <Autocomplete
                                    {...field} 
                                    style={{ width: '100%',marginBottom: '10px'}}
                                    options={locationTypes}
                                    // disablePortal
                                    onChange={(event, value) => field.onChange(value)}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ marginTop:'50px' }}>{children}
                                        <Button 
                                            style={{width:'100%'}} 
                                            variant="outlined" 
                                            color="primary" 
                                            onMouseDown={() => handleLocationTypeDialogOpen()}
                                        >Add Location Type</Button>
                                        </Paper>
                                    )}
                                    getOptionLabel={option => option.name ?? ''}
                                    renderInput={(params) => {
                                        return (
                                        <div ref={params.InputProps.ref}>
                                            <TextField
                                            name="locationType"
                                            style={{background: 'white'}}
                                            {...params}
                                            label="Location Type"
                                            required
                                            FormHelperTextProps={{
                                                style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                                }
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            />
                                        </div>
                                        )
                                    }}
                                    />
                                </>
                                )}
                            />

                            </div>
                            <div style={{ display: 'flex', justifyContent:'center' }}>
                                <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                                    <Editor
                                        editorContent={locationNote}
                                        onEditorContentChange={onEditorContentChange}
                                    />        
                                </div>
                            </div>
                        </div>

                        <div style={{display:'flex',gap:'10px'}}>
                            <Button 
                                variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                                type='submit'
                            >Save</Button>
                            <Button 
                                variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                                onClick={()=>{
                                    shippingLocationFormReset(shippingLocationDefaultValues)
                                    setCreateNewShippingLocation(false)
                                }}
                            >Cancel</Button>
                        </div>
                    </form>
                </div>

            </Modal>

            <LocationTypeDialog
                open={createNewLocationType}
                handleCloseDialog={handleLocationTypeDialogClose}
                refresh={getLocationTypes}
            />
        </>
    )
}

export default ShippingLocationQuickAdd
