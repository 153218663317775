
function hslToHex(hsl) {
  let {h, s, l} = hsl
  h /= 360;
  s /= 100;
  l /= 100;
  let r; let g; let b;
  if (s === 0) {
  r = g = b = l; // achromatic
  } else {
  const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  r = hue2rgb(p, q, h + 1 / 3);
  g = hue2rgb(p, q, h);
  b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = x => {
  const hex = Math.round(x * 255).toString(16);
  return hex.length === 1 ? `0${  hex}` : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
 

}

const customColor = (seriesData) => {
  const colors = []
  for (let i=0; i< seriesData.length; i++){
  const hsl = {h:i*16, s: 100, l:80}
  const result = hslToHex(hsl)
  colors.push(result)
  }
  return colors
}

export default customColor;

export const ucFirst = (str) => {
  if (typeof (str) !== 'string') {
    return ''
  }

  if (!str?.trim()) {
    return ''
  }

  return str.split(' ').map((item) => `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`).join(' ')
}

export const ucFirstLetter = (str) => {
  if (typeof (str) !== 'string') {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const stripHtml = (html) =>{
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export const acceptFileType = ['.doc', '.docx', '.pdf', '.txt', '.xls', '.xlsx', '.csv', '.ppt', '.pptx', '.jpg', '.jpeg', '.png', ".plain", ".msword", ".vnd.openxmlformats-officedocument.wordprocessingml.document",
".vnd.oasis.opendocument.text"]

export const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

export const hexToRgba = (hex, opacity) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
  
    // Parse the hex color
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
