import _ from '@lodash';
import 'ag-grid-enterprise';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomFilterData, setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import { useCurrentUser } from '@fuse/hooks';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height: "100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin: '5px 5px 5px 0',
		height: '28px'
	},
	activeTab: {
		background: "rgba(107, 44, 87, 0.04);",
		borderRadius: "0",
	},
	buttonSquare: {
		borderRadius: '5px'
	},
	btn: {
		background: "#30C90A",
		width: "120px",
		borderRadius: "17px",
		color: "#fff"
	},
	icon: {
		fontSize: "18px",
		color: "#61656F",
		display: "inline-block"
	}
});

function ListView(props) {
	const table_name = "SERVICE_REQUEST";
  	const classes = useStyles();
	const router = useRouter();
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [displayButton, setDisplayButton] = useState(false);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
	const [filterApplied, setFilterApplied] = useState(false);
	const [logs, setLogs] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const frameworkComponents = {
		customFilter: CustomFilter,
		customDropdownEditor: CustomDropdownEditor
	};

	const tableHeaders = ["first_name", "last_name", "email_address", "phone_number", "status", "level", "role", "created_date", "updated_date"];

	const handelEdit = (data) => {
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	function formatString(str) {
		if (!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	function formatLogs(data) {
		let {length} = data
		return data.map((item) => {
			const obj = {
				id: item.id,
				action_id: `act${length}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				ticket_id: item.service_request ? item.service_request.ticket_id : item.ticket_id,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data ?? '-',
				current_value: item.current_data ?? '-',
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				asset_serial_number: item.asset_serial_number || 'N/A'
			}
			length--;
			return obj
		})
	}

	function getLogs() {
		setLoadingLog(true)
		axios.get("/agent-management/activities").then((res) => {
			setLogs(formatLogs(res.data.data.activities));
			setLoadingLog(false)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
		});
	}

	const onGridReady = (params) => {
		setGridApi(params);
		props.agentsList.forEach((list) => {
			for(const [fieldName, value] of Object.entries(list)){
				if(fieldName === "ticket_number") return list[fieldName] = value
				if((value === '-' || value === '')&& fieldName == 'user_name') 
					list[fieldName] = 'Unassigned'
				else if (value === '' || !value || value === '')
					list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if (router.query.report_name == undefined) {
			// loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		} else {
			getReport(params);
		}
	}

	function onFilterChanged(event) {
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if (total_rows === 0) {
			event.api.showNoRowsOverlay();
		} else {
			event.api.hideOverlay();
		}
	}

	function getReport(event) {
		const {report_name} = router.query;
		const table_name = "service request";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
			.then(results => {
				const model = JSON.parse(results.data.data.filter);
				event.api.setFilterModel(model);
				const row_model = JSON.parse(results.data.data.row_model);
				event.columnApi.setColumnState(row_model);
			})
			// autoGroupColumnDef={{ minWidth: 5000 }}
			.catch(err => {
				console.log(err);
			})
	}

	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filter),
				table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	const onColumnMoved = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const onColumnPinned = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if (hide) {
				++total_hidden_columns;
			}
		});
		if (total_hidden_columns < total_no_of_columns) {
			if (router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event) {
		if (event.columns.length == 0) {
			setGroupByArr([])
		} else {
			setGroupByArr(event.columns)
		}
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0;
		let startRowIndex = 0;
		let endRowIndex = 0;

		const rows = params.api.getCellRanges();

		if (rows.length != 0) {
			columnCount = params.api.getCellRanges()[0].columns.length;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex;
		}

		if (columnCount > 1 || startRowIndex != endRowIndex) {
			setDisplayButton(true);
		} else {
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	useEffect(() => {
		if (filterApplied) {
			toast.success("Filters have been applied")
		}
	}, [filterApplied])

	useEffect(() => {
		onGridReady();
		return () => {}
	}, [])

	const handleChildClick = (value) => {
		setTabValue(value)
	};

	useEffect(() => {
		(tabValue == 1) && getLogs()
	}, [tabValue])


	function handleAddServiceTicket() {
		dispatch(openFormViewerDialog())
	}

	const clearRedirectionFilter = () => {
		dispatch(setCustomFilterData(null))
	}

	return (
		<>
			<div role="presentation" className="cstm_breadcrumbs_holder">
				<Breadcrumbs aria-label="breadcrumb">
					<Link underline="hover" color="inherit">
						System Settings
					</Link>
					<span>Agent Management</span>
				</Breadcrumbs>
			</div>
			<AgGridFilterGroupView
				tableRef={gridRef}
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList={groupByArr}
				tableName="agents"
				logs={logs}
				displayTableName="Agents"
				onClick={handleChildClick}
				onClickAddButton={handleAddServiceTicket}
				customFilterData={props.customFilterData}
				clearRedirectionFilter={clearRedirectionFilter}
				serviceRequestTotalRecords={props.agentsList.length}
				loadingLog={loadingLog}
			/>

			{/* {(tabValue == 1) && (<ActivityAgGridListing module={'service_request'} logs={logs} displayTableName={'Service Tickets'}/>)}		 */}

			{
				(tabValue == 0) &&
				<div
					className="ag-theme-alpine"
					style={{
						width: "100%",
						height: windowHeight + 105,
						fontSize: '11px'
					}}
				>
					{filterApplied && <p style={{ margin: '4px' }}>Filters have been applied</p>}
					<AgGridReact
						ref={gridRef}
						suppressRowClickSelection
						onGridReady={onGridReady}
						animateRows
						rowData={props.agentsList}
						rowHeight={38}
						onRowClicked={() => {}}
						// Set the desired sheet name
						defaultExcelExportParams={{sheetName: 'Dalos-Source'}}
						defaultColDef={{resizable: true}}
						// no rows overlay 
						overlayNoRowsTemplate="<span>No Result Found</span>"

						// Row Grouping conditions
						showOpenedGroup
						suppressDragLeaveHidesColumns
						suppressMakeColumnVisibleAfterUnGroup
						rowGroupPanelShow={rowGroupPanelShow}

						onColumnMoved={onColumnMoved}
						onColumnPinned={onColumnPinned}
						onColumnVisible={onColumnVisible}
						onSortChanged={onSortChanged}
						groupHeaderHeight={1}
						// headerHeight = {15}
						floatingFiltersHeight={40}

						// status bar enabled on range selection
						enableRangeSelection
						statusBar={{
							statusPanels: [
								{
									statusPanel: 'agAggregationComponent'
								},
							],
						}}

						// to check the the no. of rows selected during range selection
						onRangeSelectionChanged={onRangeSelectionChanged}

						onFilterChanged={onFilterChanged}

						// on column group changed
						onColumnRowGroupChanged={onColumnRowGroupChanged}
						frameworkComponents={frameworkComponents}
						paginationPageSize={10}
						pagination
					>
						<AgGridColumn
							lockPosition
							rowSelection="single"
							width={120}
							minWidth={120}
							maxWidth={120}
							suppressSizeToFit
							headerName="Actions"
							pinned='right'
							className="action_field"
							lockPinned
							cellRendererFramework={({ data }) => {
								if (data) {
									return (
										<>
											<Tooltip id="edit" title="Edit" placement="top">
												<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-pencil" onClick={() => handelEdit(data)} />
											</Tooltip>

										</>
									)
								} 
									return (<></>)
								
							}}
						/>
						{tableHeaders.map(header => {
							if (header === 'status') {
								return (
									<AgGridColumn
										field={header}
										key={header}
										suppressSizeToFit
										headerName="Status"
										sortable
										flex={1}
										minWidth={150}
										// floatingFilter = { true } 
										headerComponentFramework={CustomFilter}
										cellRendererFramework={(data) => {
											return (
												<span
													className={`listing_status_badge_holder ${data.value && data.value.toLowerCase()}`}
												>
													{data.value}
												</span>                                            
											);
										}}
										filter="text"
									 />
								)
							} 
							
								return (
									<AgGridColumn
										field={header}
										key={header}
										suppressSizeToFit
										headerName={generateHeaderLabel(header)}
										sortable
										minWidth={200}
										// floatingFilter = { true } 
										flex={1}
										headerComponentFramework={CustomFilter}
										filter="text"

									/>
								)
							
						})}
					</AgGridReact>
				</div>
			}
		</>
	);
}

export default ListView