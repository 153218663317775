import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch, useSelector } from 'react-redux';
import CsvUpload from './CsvUpload';
import {Card, CardContent, Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import { usePermission } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import AddManufactureDialog from './addManufactureDialog';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];

const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setManufacturers] = useState([]);
	const router = useRouter();
	// const agents = getAgents(invoices);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const [addButtonClick, setAddButtonClick] = useState(false);
	const { hasPermission } = usePermission()
	const viewManufacturersPermission = hasPermission('view-manufacture')
	const createManufacturersPermission = hasPermission('add-manufacture')
	const updateManufacturersPermission = hasPermission('update-manufacture')
	const deleteManufacturersPermission = hasPermission('delete-manufacture')
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	// const filterParamsState = useSelector(({manufacturersApp}) => manufacturersApp.filterParamsReducer);
	
	// function handleViewMenuOpen(event) {
	// 	setAnchorEl(event.currentTarget);
	// 	setViewMenuOpened(true);
	// }

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	// function getAgents(invoices){
	// 	const agentSet = new Set();
	// 	for(let i = 0; i < invoices.length ; i++){
	// 		agentSet.add(invoices[i].Agent);
	// 	}
	// 	return Array.from(agentSet);
	// }

	// function applyFilters(filters){
	// 	setFilters(filters);
	// 	setManufacturers(invoices.filter(acc=>{
	// 		let flag = true;
	// 		if(filters.agent != '' && filters.agent != acc.Agent){
	// 			flag = false;
	// 		}
	// 		return flag;
	// 	}))
	// }

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			getLogs()
		}
		setSelectedTab(value);
	};
	
	// function toggleView(val){
	// 	setSelectedView(val);
	// }

	function gotoDetailsPage(id){
		router.push(`manufacturers/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	async function getManufacturers(){
		try {
			const res = await axios.get("/manufacturers");
			const { data } = res.data;
			// if((!viewManufacturersPermission !='all') && data.length > 0){
			// 	if(!viewManufacturersPermission?.length){
			// 		window.location.reload();
			// 	}
			// }
			setManufacturers(formatData(data));
		} catch (err) {
			console.log(err);
			// if(err?.response?.data?.statusCode == 403 && (viewManufacturersPermission == 'all' || viewManufacturersPermission?.length)){
			// 	window.location.reload();
			// }
		}
	}
	
	function handleSearchReset(){
		getManufacturers()
	}

	// function getSearchFilters(){
	// 	setSearchFilters([
	// 		{field: "hardware_sku", label: "SKU"},
	// 		{field: "service_plan", label: "Service plan"},
	// 	])
	// }

	// function getCustomFilters(){
	// 	axios.get('/manufacturers/get-filters')
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setCustomFilters(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }

	function getSearchSuggetions(query, field){
		axios.get('/manufacturers/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/manufacturers/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			setManufacturers(data)
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	function formatData(data){
		return data.map((item) => {
			let length = 0;
			item.models.map((model) => {
					length += model.assets?.length
				}
			)
			return {
				manufacturer_id: item.entity_external_platform_id,
				created_at:item.created_date?dateReformatTo_mmm_dd_yyyy(item.created_date):'',
				updated_at_:item.updated_date?dateReformatTo_mmm_dd_yyyy(item.updated_date):'',
				original_info: {
					phone_number: item.phone_number,
					address_line_1: item.address_line_1,
					address_line_2: item.address_line_2,
					city: item.city,
					state: item.state,
					zip: item.zip,
					country: item.country,
				},
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
					'platform_customer'
				]),
				number_of_assets: length,
				company_name: item.platform_customer.user_company.company_name,
			}
		})
	}

	function refreshList(){
		getManufacturers();
	}

	// function formatLogs(data){
	// 	return data.map((item)=>{
	// 		return {
	// 			id:item.id,
	// 			action_id: `act${item.id}`,
	// 			action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
	// 			name: item.user_name,
	// 			manufacturer_name: item?.manufacturer?.manufacturer_name || item.manufacturer_name,
	// 			action: item.action_name,
	// 			description: item.comment,
	// 			previous_value: item.previous_data,
	// 			current_value: item.current_data,
	// 			field_name: item.action_field_name,
	// 			user_role: item.user_role_name
	// 		}
	// 	})
	// }

	// function getLogs(){
	// 	axios.get('/manufacturers/activities')
	// 	.then(({data})=>{
	// 		setLogs(formatLogs(data.data.activities));
	// 		// setLogs(data.data.activities);
	// 	})
	// 	.catch(err=>{
	// 		console.log('cannot fetch logs');
	// 	})
	// }

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}

	function closeForm(data){
		if(data){
			setAddButtonClick(false)
		}
	}

	useEffect(()=>{
		setLoading(true)
		getManufacturers().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
				{(createManufacturersPermission || updateManufacturersPermission) && 
					<CsvUpload 
						refresh={refreshList}
						viewManufacturersPermission = { viewManufacturersPermission }
						createManufacturersPermission = { createManufacturersPermission }
						updateManufacturersPermission = { updateManufacturersPermission }
						deleteManufacturersPermission = {deleteManufacturersPermission}
					/>
				}

				<SaveReportDialog tableName={"manufacturer"}/>

				<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				header={
					<DynamicFieldsHeader 
						handleSearch={handleSearch} 
						searchSuggestions={searchSuggestions} 
						clearSearch={clearFilters}
						searchFilters={searchFilters}
						handleSearchSuggestions={handelSearchSuggestions}
						handleSearchReset={handleSearchReset}
						onSearchChange={(searchValue, fieldValue) => {
							setCurrentSearchParameters({query: searchValue, field: fieldValue})
						}}
					/>
				}
				contentToolbar={
					<>
						{/* <Tabs
							value={selectedTab}
							onChange={handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="off"
							className="w-full h-64"
						>
							<Tab key="end_user_tab_1" className="h-64" label="All Manufacturers" />
							{viewManufacturersPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
							
						</Tabs> */}
						<div>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={viewMenuOpened}
								onClose={handleViewMenuClose}
							>
								{	
									views.map((view,index) => {
										return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
									})
								}
							</Menu>
						</div>
					</>
				}
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
						{
							loading && <FuseLoading />
						}
						{
							!loading && <SalesOrderTable 
								endUsersAccounts={invoices}
								selectedView={selectedView}
								gotoDetailsPage={gotoDetailsPage}
								refresh={refreshList}
								viewManufacturersPermission = { viewManufacturersPermission }
								createManufacturersPermission = { createManufacturersPermission }
								updateManufacturersPermission = { updateManufacturersPermission }
								deleteManufacturersPermission = {deleteManufacturersPermission}
							/>
						}
						</div>
						<AddManufactureDialog getManufacturers = {getManufacturers}/>
						{viewManufacturersPermission && 
							<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
								{/* <ActivitieLogs logs = {logs}/> */}

								{/* {Boolean(logs.length) ? <ActivityAgGridListing module='manufacturer' logs={logs}/> : <FuseLoading/> } */}
							</div>
						}
						{!viewManufacturersPermission && <div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							<Card>
								<CardContent>You don't have the view permission for Manufacturers.</CardContent>
							</Card>
						</div>}
					</>
				}
				innerScroll
			/>
		</>)
	}
}

export default withReducer('manufacturersApp', reducer)(CardedFullWidth2TabbedSample);
