import useToast from '@fuse/hooks/useToast'
import { Button, 
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Tooltip, Typography,
  Drawer 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
	},
	loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
	},
	subSection: {
			margin:'0 20px', 
			padding: '10px', 
			backgroundColor: '#f4f4f4', 
			borderRadius:'4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 600
	},
}))

function AddCustomAttributeDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.modelCustomAttributeApp.formViewDialog)
	const { data:editFromState } = useSelector(({modelCustomAttributeApp}) => modelCustomAttributeApp.formViewDialog);
	const [allSpan, setAllSpan] = useState([])
	const spanTemp = {
		attribute_name: '',
	}
	const addNewSpan = () => {
		let newSpan = {
			...spanTemp
		}
		setAllSpan((allSpan) => [...allSpan, newSpan])
	}
	const deleteSpan = (index) => {
		setAllSpan(allSpan.filter((span,inx) => inx !== index ))
	}
	const handleSpanInput = (index, value, propName) => {
		setAllSpan((spans) => {
			spans[index][propName] = value
			return [...spans]
		} )
	}

	//form validation
	const schema = yup.object({
		attribute_name: yup.string().required('Attribute Name is required!'),
	})

	const defaultValues = {
		attribute_name:''
	}

	const onCancel = () => {
		setAllSpan([])
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
	}
	
	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		// resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFromState){
			// setValue('attribute_name',editFromState.attribute_name);
			setAllSpan([{
				attribute_name: editFromState.attribute_name
			}])
		}
	},[editFromState])

	const { errors } = formState

	const manageFormData = (formData) => {
		let payload
		if(editFromState){ //edit
			payload = {
				attribute_name: allSpan[0].attribute_name,
			}
		}else{//create
			payload = {
				attribute_names: allSpan,
			}
		}
		let api_url

		if(editFromState){ //edit
			api_url = `/model-custom-attributes/update/${editFromState.id}`
		}else{
			api_url = '/model-custom-attributes/add'
		}
		axios.post(api_url, payload).then((res)=>{
			if(res.status == 201){
				toast.error(res.data.message)
				onCancel()
			} else {
				toast.success(res.data.message)
				dispatch(setQuickFilter(null))
				props.getModelDynamicAttributes()
				onCancel()
			}
		}).catch((err)=>{
			console.log('error',err);
			toast.error("Something went wrong!")
		})
	}

	const errorData = (error) => {
		console.log('error:134',error)
	}

	useEffect(()=>{
		if(!editFromState && openState.open){
			addNewSpan()
		}
	},[openState.open])

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{editFromState ? 'Update' : 'Add'} Model Dynamic Attributes</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*----------------------------------------------------Item Information---------------------------------------------------------------- */}
					
				<div className={classes.subSection}>
					<div style={{ width: '100%' }}>
					<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
						<Divider style={{marginBottom: '10px'}}/>
					</div>

					<div >
						{ 
							allSpan.map((span, index) => {
								return ( 
									<div style={{display:'flex', flexDirection: 'row', width:'100%'}}>
										<div style={{ display: 'flex', justifyContent:'center' }}>
											<div style={{marginBottom: '10px', marginRight: '10px', width: '522px' }}>
												<Controller
													name='attribute_name' 
													control={control}
													render={( { field} ) => (
														<>
															<TextField 
																{...field}
																variant='outlined'
																label='Model Attribute' 
																autoFocus
																style={{background: 'white',width: '95%',}}
																fullWidth
																required
																value={span.attribute_name}
																onChange={event => {
																	handleSpanInput(index, event.target.value, "attribute_name")
																}}
																FormHelperTextProps={{
																	style: { 
																		margin : 0, 
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
															/>
														</>
													) }
												/>
											</div> 
										</div>
										{ 
											(!editFromState && (index === 0 ? 
												<Tooltip title='Add More' placement='top'>
													<IconButton  style={{flexGrow:1,transform:"translateX(-30px)"}} onClick={() => addNewSpan()}>
														<Icon>add_circle_outline</Icon>
													</IconButton>
												</Tooltip>
												:
												<Tooltip title='Remove Span' placement='top'>
													<IconButton  style={{flexGrow:1,transform:"translateX(-30px)"}} onClick={() => deleteSpan(index)} >
														<Icon>cancel_circle</Icon>
													</IconButton>
												</Tooltip>
											))
										}
									</div>
								)
							})
						}
					</div>        
				</div>

				<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
					<Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? 'Update' : 'Add'}</Button>
					<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
				</div>
						
				</form>        
			</Drawer>
		</div>
	)
}

export default AddCustomAttributeDialog
