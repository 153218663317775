import useToast from '@fuse/hooks/useToast'
import {
	Accordion, AccordionDetails, AccordionSummary, Button, DialogTitle, Icon,
	IconButton, makeStyles, MenuItem, Paper, TextField, Typography,
	Drawer, Checkbox, InputAdornment, FormControlLabel, Radio, InputLabel, Select, MenuList,Divider
} from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import RadioGroup from '@material-ui/core/RadioGroup';
import ListItemText from '@material-ui/core/ListItemText';
import { setFromData } from '../store/formViewDialogReducer';
import DepartmentDialog from '@fuse/components/Modal/QuickAddDepartment'
import SoftwareCategoryDialog from '@fuse/components/Modal/QuickAddSoftwareCategory'
import CloudProviderDialog from '@fuse/components/Modal/QuickAddCloudProvider'
import AddSubscriptionStatus from '@fuse/components/Modal/QuickAddSubscriptionStatus'
import { FormControl } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { formatPriceField } from '@fuse/utils/general'
import CustomDatePicker from '@fuse/components/customDatePicker'
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import QuickAddPaymentTerm from "@fuse/components/Modal/QuickAddPaymentTerm";
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px !important',
			marginRight: '20px !important',
			marginBottom: '20px !important',
			padding: '10px',
			backgroundColor: '#f4f4f4',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		customDrawerPaper: {
			maxWidth: '600px'
		}
	}
))

function AddSubscriptionDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.subscriptionApp.formViewDialog)
	const { data: editFromState } = useSelector((state) => state.subscriptionApp.formViewDialog)
	// const [startDate, setStartDate] = useState('')
	// const [endDate, setEndDate] = useState('')

	//new
	const [subscriptionCategory,setSubscriptionCategory] = useState([])
	const [dropDownHeight,setDropDownHeight] = useState(100)
	const [translateDropdown,setTranslateDropdown] = useState(23)
	const [provider,setProvider] = useState([])
	const [department,setDepartment] = useState([])
	const [substatus,setSubStatus] = useState([])
	const [purchaseOrder,setPurchaseOrder] = useState('purchaseOrder')
	const [selectDepartment,setSelectDepartment] = useState([])
	const [selectDepartmentId,setSelectDepartmentId] = useState([])
	const [createNewDept, setCreateNewDept] = useState(false)
	const [createCategory, setCreateCategory] = useState(false)
	const [createStatus, setcreateStatus] = useState(false)
	const [createProvider, setCreateProvider] = useState(false)
	const [data, setData] = useState([]);
	const [paymentTerm, setPaymentTerm] = useState([]);
	const [checkForTerm, setCheckForTerm] = useState(false)
	const [checkForBilling, setCheckForBilling] = useState(false)
	const [checkPaymentMethod, setCheckPaymentMethod] = useState(false)
	const [checkReminder, setCheckReminder] = useState(false)
	const [inputVal, setInputVal] = useState("");
	const [showOptions, setShowOptions] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [disableField, setDisableField] = useState(false);
	const [cardNumber, setCardNumber] = useState('');
	const [everyFielVal, setEveryFielVal] = useState(1);
	const [formattedNumber, setFormattedNumber] = useState('')
	const [
    openPaymentTermQuickAddDialog,
    setOpenPaymentTermQuickAddDialog,
  ] = useState(false);
	const ref = useRef(null);
	const start_date_ref = useRef("");
	const end_date_ref = useRef("");
	const sub_name_ref = useRef("");
	const provider_ref = useRef("");
	const website_ref = useRef("");
	const category_ref = useRef("");
	const department_ref = useRef("");
	const amount_ref = useRef("");
	const month_ref = useRef("");
	const every_ref = useRef("");
	const billing_date_ref = useRef("");
	const card_holder_ref = useRef("");
	const card_number_ref = useRef("");
	const po_number_ref = useRef("");
	const po_date_ref = useRef("");
	const support_number_ref = useRef("");
	const [startDate,setStartDate] = useState({
		date: null,
		error: false
	})
	const [endDate,setEndDate] = useState({
		date: null,
		error: false
	})
	const [billingDate,setBillingDate] = useState({
		date: null,
		error: false
	})
	const [paymentDate,setPaymentDate] = useState({
		date: null,
		error: false
	})

	const [reminderCheckboxes, setReminderCheckBoxes] = useState({
		term45: true,
		term30: false,
		supportContact: false
	})
	const [noReminderCheckBox, setNoReminderCheckBox] = useState(false)
	const [currentCountry, setCurrentCountry] = useState('us')

	// const [dropdown, setdropdown] = useState([
	// 	{ id: 1, name: 'Day' },
	// 	{ id: 2, name: 'Week' },
	// 	{ id: 3, name: 'Month' },
	// 	{ id: 4, name: 'Year' }
	// ])

	const MenuProps = {
		PaperProps: {
		  style: {
			// marginTop:'56px',
			// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			height:`${dropDownHeight}px`
	,		width: 250,
			transform:`translateY(${translateDropdown}%)`
		  },
		},
		variant: 'menu',
		anchorOrigin: {
		  vertical: "top",
		  horizontal: "center"
		},
		transformOrigin: {
		  vertical: "top",
		  horizontal: "center"
		},
		getContentAnchorEl: null  
	};
	// MenuProps.PaperProps.style.height = `${dropDownHeight}px`

	const handleRadioChange = (event) => {
		if(event.target.value == 'purchaseOrder'){
			setCardNumber('')
			setValue('holder_name','')
		}if(event.target.value == 'CreditCard'){
			setValue('po_date', '');
			setValue('po_number', '');
		}
		setPurchaseOrder(event.target.value);
	};

	const handleChangeNoReminderCheckbox = (event) => {
		setNoReminderCheckBox(event.target.checked)
	}

	const handleChangeReminderCheckBoxes = (event) => {		
		if((event.target.name === 'term45' && event.target.checked === false && reminderCheckboxes.term30 === false) || (event.target.name === 'term30' && event.target.checked === false && reminderCheckboxes.term45 === false)){
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked,
				supportContact: false
			})
			setValue('support_phone_number', '')
			setNoReminderCheckBox(true)
		}
		else{
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked
			})
		}
	}

	const handleChangeTextMeOption = (event) => {
		if(reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true){
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: event.target.checked
			})
		}

		if(event.target.checked === false){
			setValue('support_phone_number', '')
		}
	}

	const handleOpen = () => { setIsOpen(true); }; 
	const handleClose = () => { setIsOpen(false); };

	const handleCurrentCountry = (countryCode) => {
		const current = countries.filter(country => country.code === countryCode)[0]
		setCurrentCountry(current.code)
	}

	function fetchSubscriptionCategory() {
		axios.get(`category?category_type=software`).then(response => {
			setSubscriptionCategory(response.data.data.map((item) => {
				return { id: item.id, name: item.category_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	function fetchCloudProvider() {
		axios.get(`/cloud-provider`).then(response => {
			setProvider(response.data.data.map((item) => {
				return { id: item.id, name: item.provider_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	function fetchDepartment() {
		axios.get(`/department`).then(response => {
			setDepartment(response.data.data.map((item) => {
				return { id: item.id, name: item.department_name }
			}))
			if(response.data.data.length <2){
				setDropDownHeight(100)
				setTranslateDropdown(55)
			}
			else if(response.data.data.length <3){
				setDropDownHeight(130)
				setTranslateDropdown(45)
			}
			else if(response.data.data.length <4){
				setDropDownHeight(170)
				setTranslateDropdown(35)
			}
			else if(response.data.data.length <5){
				setDropDownHeight(200)
				setTranslateDropdown(22)
			}
			else{
				setDropDownHeight(200)
				setTranslateDropdown(22)
			}
			// setContractsMenu((response.data.data.map((item)=>item.display_name)))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	//form validation
	const schema = yup.object({
		// support_phone_number: reminderCheckboxes.supportContact && yup.string().required('Phone Number is required!').matches(/^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
	})

	const defaultValues = {
		amount: '',
		category: '',
		country: '',
		currency: '',
		department: '',
		end_date: '',
		// every: 1,
		month: '',
		po_date: '',
		po_number: '',
		provider_name: '',
		country: { "name": "United States", "code": "US", "currency": "USD" },
		renewal_date: '',
		start_date: '',
		subscription_name: '',
		department: '',
		website: '',
		status:'',
		no_of_seats:'',
		holder_name: '',
		card_number:''
	}

	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

	useEffect(() => {
		console.log(editFromState)
		if (editFromState) {
			if(editFromState?.manually_added){
				setDisableField(false)
			}else{
				setDisableField(true)
			}
			setInputVal(editFromState?.subscription_name)
			setValue('no_of_seats',editFromState?.no_of_seats)
			setValue('provider_name', { id: editFromState.provider_obj.id, name: editFromState.provider_obj.provider_name });
			setValue('website', editFromState.website?.slice(4, editFromState?.website.length));
			setValue('category', { id: editFromState.category_obj.id, name: editFromState.category_obj.category_name });
			setValue('status', { id: editFromState.status_obj.id, name: editFromState.status_obj.status_name });
			setValue('amount',  formatPriceFieldOnBlur(String(editFromState.amount)))
			setSelectDepartment((()=>{
				let arr = editFromState.department.split(',')
				return arr.map(item=>item.trim())
			})())
			setEveryFielVal(editFromState.billing_cycle_)
			setValue('month', { name: editFromState.month, id: editFromState.payment_term_id });
			setValue('po_number', editFromState.po_number == 'No PO Number'?'': editFromState.po_number);
			setCardNumber(editFromState.card_number == 'No Card Number'?'':editFromState.card_number)
			setValue('holder_name', editFromState.card_holder_name == 'No Card Holder Name'?'':editFromState.card_holder_name);
			setPurchaseOrder(editFromState.value || 'purchaseOrder')			
			const data = countries.find(item => item.currency == editFromState.currency)
			setCurrentCountry(data?.code)
			setValue('currency', { "name": "United States", "code": data?.code, "currency": editFromState?.currency });
			setStartDate((prev) => {
				return {
					...prev,
					date: editFromState.term_start_date_exact
				}
			})
			setEndDate((prev) => {
				return {
					...prev,
					date: editFromState.term_end_date_exact
				}
			})
			setPaymentDate((prev) => {
				return {
					...prev,
					date: editFromState.po_date_exact
				}
			})
			setBillingDate((prev) => {
				return {
					...prev,
					date: editFromState.billing_cycle_date_exact
				}
			})

			switch(editFromState.reminder){	
				case "No Reminder":
					setNoReminderCheckBox(true)
					setReminderCheckBoxes({
						term45: false,
						term30: false,
						supportContact: false
					})
					break;
	
				case 'Remind Before 45 Days And 30 Days':
					setNoReminderCheckBox(false)
					setReminderCheckBoxes({
						supportContact:false,
						term30: true,
						term45: true
					})
					break;
	
				case 'Remind Before 45 Days':
					setNoReminderCheckBox(false)
					setReminderCheckBoxes({
						supportContact:false,
						term30: false,
						term45: true
					})
					break;
	
				case 'Remind Before 30 Days':
					setNoReminderCheckBox(false)
					setReminderCheckBoxes({
						supportContact:false,
						term30: true,
						term45: false
					})
					break;
			}
			const avoidCases = ['No Text Contact Number', '-', '']

			// setValue('support_phone_number', avoidCases.includes(editFromState.text_contact_number) ? '' : editFromState.text_contact_number)
			setFormattedNumber(avoidCases.includes(editFromState.text_contact_number) ? '' : editFromState.text_contact_number)
			if(!avoidCases.includes(editFromState.text_contact_number)){
				setReminderCheckBoxes({
					...reminderCheckboxes,
					supportContact: true
				})
			}
		}
	}, [editFromState])


	//   const filterval = data.filter((cval) => {
	//     // return cval.name.toLowerCase().includes(inputVal.toLowerCase());
	//     const crrVal = cval.name.toLowerCase();
	//     const crrInpval = inputVal.toLowerCase();
	//     if (crrVal.includes(crrInpval)) {
	//       return cval.name;
	//     }
	//   });

	const handleInputval = async (e) => {
		let query = e.target.value || ' '
		setInputVal(e.target.value);
		if (e.target.value) {
			const res = await axios.get(`/subscription/get-name/${query}`);
			setShowOptions(true);
			setData(res.data.data);
		}
		if (e.target.value === "") {
			setShowOptions(false);
			setValue('category', '');
			setValue('website', '');
			setValue('provider_name', '');
			setDisableField(false)
		}
	};

	function summeryClickSubTerm() {
		let expand = !checkForTerm
		setCheckForTerm(expand)
	}

	function summeryClickSubBilling() {
		let expand = !checkForBilling
		setCheckForBilling(expand)
	}

	function summeryClickSubPayment() {
		let expand = !checkPaymentMethod
		setCheckPaymentMethod(expand)
	}

	function summeryClickReminder() {
		let expand = !checkReminder
		setCheckReminder(expand)
	}

	const getListItem = (item) => {
		setDisableField(true)
		setInputVal(item.software_name);
		setValue('category', { id: item?.category_id, name: item?.category_name });
		setValue('provider_name', { id: item?.provider_id, name: item?.provider_name });
		if(item.website[item?.website.length -1 ] == '/'){
			setValue('website', item.website?.slice(4, item?.website.length-1))
		}else{
			setValue('website', item.website?.slice(4, item?.website.length));
		}
		setShowOptions(false)
	};

	const onCancel = () => {
		reset(defaultValues)
		setCardNumber('')
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setSelectDepartment([])
		setEveryFielVal(1)
		setValue('currency', { "name": "United States", "code": "US", "currency": "USD" })
		setCurrentCountry('US')
		setCheckForTerm(false)
		setCheckPaymentMethod(false)
		setCheckReminder(false)
		setInputVal("")
		setDisableField(false)
		closeDialog()
	}

	const closeDialog = () => {
		dispatch(closeFormViewerDialog())
		reset(defaultValues)
		setSelectDepartment([])
		dispatch(setFromData(null))
		setValue('currency', { "name": "United States", "code": "US", "currency": "USD" })
		setCurrentCountry('US')
		setCheckForTerm(false)
		setCheckForBilling(false)
		setCheckPaymentMethod(false)
		setFormattedNumber("")
		setCheckReminder(false)
		setInputVal("")
		setDisableField(false)
		setPaymentDate({
			date: null,
			error: false
		})
		setBillingDate({
			date: null,
			error: false
		})
		setEndDate({
			date: null,
			error: false
		})
		setStartDate({
			date: null,
			error: false
		})
	}

	const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { errors } = formState

	function getDeptArr(selectDepartment, department) {
		let item = [];
		for (let i = 0; i < selectDepartment.length; i++) {
			item.push(department.find((item) => {
				if (item.name == selectDepartment[i]) {
					return item
				}
			})?.id)
		}
		if (item.length == 0) return null
		return item
	}

	function editSubscription(payload) {
		axios.post(`/subscription/edit-subscription-manually`, payload).then(response => {
			if (response.status == 201) {
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				setCardNumber('')
				closeDialog()
				toast.success('Subscription updated successfully.')
				dispatch(setQuickFilter(null))
				if (props.getSubscription) {
					props.getSubscription()
					setSelectDepartment([])
					setEveryFielVal(1)
				}
			}
		}).catch(err => {
			console.log('212=>', err);
			// toast.error('Something went wrong!')
		})
	}

	function clickSubmit() {
		if (
			(startDate.date ==  null || endDate.date == null)) {
			setCheckForTerm(true)
		}
		if (amount_ref?.current.value == '' || billingDate.date == null ||
		month_ref.current.value == '' || every_ref.current.value == '') {
			setCheckForBilling(true)
		}
		if(purchaseOrder == 'purchaseOrder' && (paymentDate.date == null || po_number_ref?.current.value == '') ){
			setCheckPaymentMethod(true)
		}
		if(purchaseOrder == 'CreditCard' && (card_holder_ref?.current.value == '' || card_number_ref?.current.value == '') ){
			setCheckPaymentMethod(true)
		}
		if(support_number_ref.current.value == '' && reminderCheckboxes.supportContact){
			setCheckReminder(true)
		}
	}

	const manageFormData = (formData) => {
		const avoidHyperTextTransferProtocolString = ['http', 'https']
		const avoidWorldWideWebString = ['www']
		const dotValidation = [-1, 0]
		if (avoidWorldWideWebString.includes(formData.website?.split('.')[0]) || avoidHyperTextTransferProtocolString.includes(formData.website?.split(':')[0]) || dotValidation.includes(formData.website.indexOf('.'))){
			toast.error('Please enter a valid url')
			return
		}
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
		// if (formData.website.indexOf('.') == -1 || formData.website.includes('www')) {
		// 	toast.error('Please enter a valid url')
		// 	return
		// }
		if (Number(formData.no_of_seats < 1)) {
			toast.error('Number of Seats must be greater than zero')
			return
		}
		if(reminderCheckboxes.supportContact === true && formattedNumber === ''){
			toast.error('Contact number is required for text messages')
			return
		}

		if(!reminderCheckboxes.supportContact && !reminderCheckboxes.term30 && !reminderCheckboxes.term45 && !noReminderCheckBox ){
			toast.error('Please select a reminder option.')
			return
		}
		let dept_id = getDeptArr(selectDepartment, department)
		let dept_str = ''
		selectDepartment.forEach(item => {
			dept_str += `, ${item}`
		})
		if(purchaseOrder == 'CreditCard' && cardNumber.length < 4){
			toast.error('Please enter last four digit number of your credit card.')
			return
		}
		let payload = {
			subscription_name: inputVal,
			entity_external_platform_id: String(Math.trunc(Math.random() * 200)),
			provider_id: formData.provider_name.id,
			website: "www." + formData.website || '',
			subscription_category_id: formData.category.id,
			status_id: formData.status.id,
			department_id: dept_str.slice(1),
			department_id_: dept_id??null,
			no_of_seats:Number(formData.no_of_seats),
			term_start_date: startDate.date || '',
			term_end_date: endDate.date || '',
			billing: {
				amount: String(formData.amount).replaceAll(',',''),
				location: formData.currency.name,
				cycle: String(everyFielVal) || '',
				month: formData.month.name || '',
				date: billingDate.date || '',
				currency: formData.currency.currency,
				payment_term_id: formData.month.id
			},
			payment: {
				value: purchaseOrder,
				po_number: formData.po_number,
				po_date: paymentDate.date || '',
				card_number:cardNumber || '',
				card_holder_name: formData.holder_name
			},
			support_phone_number: reminderCheckboxes.supportContact ? formattedNumber : null,

			no_reminder: noReminderCheckBox,
			reminder: {term45: reminderCheckboxes.term45, term30: reminderCheckboxes.term30},
			textMessage: reminderCheckboxes.supportContact,
			manually_added: !disableField
		}
		if (editFromState) {
			let payload = {
				edit_id: editFromState.id,
				subscription_name: inputVal,
				entity_external_platform_id: String(Math.trunc(Math.random() * 200)),
				provider_id: formData.provider_name.id,
				website: "www." + formData.website || '',
				subscription_category_id: formData.category.id,
				department_id: dept_str.slice(1),
				department_id_: dept_id??null,
				term_start_date: startDate.date || '',
				term_end_date: endDate.date || '',
				status_id: formData.status.id,
				no_of_seats: Number(formData.no_of_seats),
				billing: {
					billing_edit_id: editFromState.billing_cycle_id,
					amount: String(formData.amount).replaceAll(',',''),
					location: formData.currency.name,
					cycle: String(everyFielVal) || '',
					month: formData.month.name || '',
					date: billingDate.date || '',
					currency: formData.currency.currency,
					payment_term_id: formData.month.id
				},
				payment: {
					payment_edit_id: editFromState.payment_id,
					value: purchaseOrder,
					po_number: formData.po_number || '',
					po_date: paymentDate.date || '',
					card_number:cardNumber || '',
					card_holder_name: formData.holder_name || ''
				},
				support_phone_number: reminderCheckboxes.supportContact ? formattedNumber : null,
				no_reminder: noReminderCheckBox,
				reminder: {term45: reminderCheckboxes.term45, term30: reminderCheckboxes.term30},
				textMessage: reminderCheckboxes.supportContact,
				manually_added: !disableField
			}
			editSubscription(payload)
		} else {
			axios.post('/subscription/add-subscription-manually', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					setCardNumber('')
					closeDialog()
					toast.success(response.data.message)
					dispatch(setQuickFilter(null))
					if (props.getSubscription) {
						props.getSubscription()
						setSelectDepartment([])
						setEveryFielVal(1)
					}
				}
			}).catch(err => {
				console.log('212=>', err);
				// toast.error('Something went wrong!')
			})
		}

	}

	function handleDepartmentChange(e) {
		const ids = e.target.value.map(item=>{
			return department.find(ele=>ele.name == item).id
		})
		setSelectDepartmentId(ids)
		setSelectDepartment(e.target.value)
	}

	const handleCategoryDialogOpen = () => {
		setCreateCategory(true)
	}

	const handleStatusDialogOpen = () => {
		setcreateStatus(true)
	}

	const handleCategoryDialogClose = (new_data = null) => {
		setCreateCategory(false)
		if (new_data) {
			setValue('category', { id: new_data?.id, name: new_data?.category_name });
		}
	}

	const handleStatusDialogClose = (new_data = null) => {
		setcreateStatus(false)
		if (new_data) {
			setValue('status', { id: new_data?.id, name: new_data?.status_name });

		}
	}

	const handleProviderDialogOpen = () => {
		setCreateProvider(true)
	}

	const handleProviderDialogClose = (new_data = null) => {
		setCreateProvider(false)

		if (new_data) {
			setValue('provider_name', { id: new_data.id, name: new_data.provider_name });

		}
	}

	useEffect(() => {

		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowOptions(false);
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	const handleDepartmentDialogOpen = () => {
		setCreateNewDept(true)
	}

	const handleDepartmentDialogClose = (new_data = null) => {
		setCreateNewDept(false)
		setIsOpen(false)

		//Set Quick added STATUS value into status field
		if (new_data) {
			// setValue('department', { id: new_data.id, department_name: new_data.department_name })
			let new_depts = selectDepartment.concat([new_data.department_name])
			setSelectDepartment(new_depts)
		}
	}

	const errorData = (error) => {
		console.log('363=>', error)
	}
	
	async function fetchSubstatus(){
		axios.get(`/asset-status?type=software`).then(response => {
			setSubStatus(response.data.data.map((item) => {
				return { id: item.id, name: item.status_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}
	async function fetchPaymetTerm(){
		axios.get(`subscription/get-payment-term`).then(response => {
			setPaymentTerm(response.data.data.map((item) => {
				return { id: item.id, name: item.display_name }
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	useEffect(() => {
		fetchSubscriptionCategory()
		fetchCloudProvider()
		fetchDepartment()
		fetchSubstatus()
		fetchPaymetTerm()
		setValue('currency', { "name": "United States", "code": "US", "currency": "USD" })
		setInputVal("")
		setValue('every', 1);
	}, [])

	function handlPaymentTermCloseDialog(new_payment_term) {
    if (new_payment_term) {
      setValue("month", {name:new_payment_term.display_name, id:new_payment_term.id});
    }
    setOpenPaymentTermQuickAddDialog(false);
    fetchPaymetTerm();
  }


	return (
		<div>
			<QuickAddPaymentTerm
				open={openPaymentTermQuickAddDialog}
				handleCloseDialog={handlPaymentTermCloseDialog}
			/>
			<CloudProviderDialog
				open={createProvider}
				handleCloseDialog={handleProviderDialogClose}
				getProvider={fetchCloudProvider}
			/>
			<SoftwareCategoryDialog
				open={createCategory}
				handleCloseDialog={handleCategoryDialogClose}
				getCategory={fetchSubscriptionCategory}
			/>
			<AddSubscriptionStatus
				openStatusDialogState={createStatus}
				handleCloseDialog={handleStatusDialogClose}
				fetchStatus={fetchSubstatus}
			/>
			<DepartmentDialog
				open={createNewDept}
				handleCloseDialog={handleDepartmentDialogClose}
				getDepartment={fetchDepartment}
			/>

			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.customDrawerPaper }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<DialogTitle>{editFromState ? 'Update' : 'Add'} Subscription</DialogTitle>
					<IconButton style={{ marginRight: '15px' }} onClick={onCancel}>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={handleSubmit(manageFormData, errorData)} >
					{/*--------------------Contract Description----------------------- */}
					<Accordion defaultExpanded={true} className={classes.subSection}>
						{/* <AccordionSummary
							style={{ pointerEvents: 'none' }}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Subscription Description</Typography>
							</div>
						</AccordionSummary> */}
						<Typography style={{fontSize:'16px'}}>	Required Information *</Typography>
						<Divider style={{marginBottom: '10px'}}/>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column',padding:0 }}>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%',position:"relative" }}>
									{/* <Controller
										name="subscription_name"
										className="mb-16"
										control={control} */}
									{/* render={({ field }) => (
											<> */}
									<TextField
										inputRef={sub_name_ref}
										id="outlined-basic"
										onChange={handleInputval}
										value={inputVal}
										label="Subscription Name"
										required
										variant="outlined"
										autoComplete='off'
										type="search"
										style={{ width: "100% ", backgroundColor: '#fff' }}
									/>
									{showOptions && (
										<>
											<div className='custom_dropdownMenu custom_dropdownMenu2 absolute'
												ref={ref}
												
											>
												{data.map((cval) => {
													return (
														<>
															<MenuList>
																<MenuItem
																	style={{ margin: "-5px  0" }}
																	onClick={() => getListItem(cval)}
																>
																	<img style={{ width: "30px", marginRight: "5px" }}
																		src={cval.signed_url}
																		alt=""
																	/>{" "}
																	{cval.software_name}
																</MenuItem>
																{/* <MenuItem>My account</MenuItem>
																<MenuItem>Logout</MenuItem> */}
															</MenuList>
															{/* <ul style={{ padding: "2px 10px" }}>
																<li
																style={{ margin: "10px 0" }}
																onClick={() => getListItem(cval.name)}
																>
																<i
																	class="fa fa-bookmark-o"
																	style={{ marginRight: "20px" }}
																	aria-hidden="true"
																></i>
																{cval.name}
																</li>
															</ul> */}
														</>
													);
												})}
											</div>
										</>
									)}
									{/* </> */}
									{/* )} */}
									{/* // /> */}
								</div>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="provider_name"
										className="mb-16"
										control={control}
										
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete

													{...field}
													style={{ width: '100%' }}
													options={provider}
													disablePortal
													disabled = {disableField}
													onChange={(event, value) => { field.onChange(value) }}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '0px' }}>{children}
															{<Button
																style={{ width: '100%' }}
																variant="outlined"
																color="primary"
																onMouseDown={() => handleProviderDialogOpen()}
															>Add Subscription Provider</Button>}
														</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	inputRef={provider_ref}
																	{...params}
																	label='Provider Name'
																	variant='outlined'
																	fullWidth
																	required
																	FormHelperTextProps={{
																		style: { marginLeft: 0 }
																	}}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>

								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="website"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												inputRef={website_ref}
												{...field}
												variant='outlined'
												label='Website'
												fullWidth
												required
												disabled = {disableField}
												InputProps={{
													startAdornment:
														<InputAdornment style={{ color: "grey", marginRight: '0' }} disableTypography position="start">
															www.</InputAdornment>,
												}}
												FormHelperTextProps={{
													style: { marginLeft: 0 }
												}}
												style={{ backgroundColor: '#fff' }}
											/>
										</>
									)}
								/>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="category"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={subscriptionCategory}
													disablePortal
													disabled = {disableField}
													onChange={(event, value) => { field.onChange(value) }}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '0px', position: 'relative', zIndex: "1223646466" }}>{children}
															{<Button
																style={{ width: '100%' }}
																variant="outlined"
																color="primary"
																onMouseDown={() => handleCategoryDialogOpen()}
															>Add Subscription Category</Button>}
														</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	label='Select Category'
																	variant='outlined'
																	fullWidth
																	required																	
																	inputRef={category_ref}
																	FormHelperTextProps={{
																		style: { marginLeft: 0 }
																	}}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="no_of_seats"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												inputRef={website_ref}
												{...field}
												variant='outlined'
												label='No of Seats'
												fullWidth
												required
												onKeyPress={(event) => {
													if (!/[0-9/-]+/.test(event.key)) {
														event.preventDefault();
													}
												}}
												FormHelperTextProps={{
													style: { marginLeft: 0 }
												}}
												style={{ backgroundColor: '#fff' }}
											/>
										</>
									)}
								/>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%', position: 'relative' }}>
									<Controller
										name="department"
										className={classes.dropdownCss}
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<FormControl variant='outlined' fullWidth>
													<InputLabel style={{ background: "#fff" }} id="demo-simple-select-outlined-label">Purchasing Department*</InputLabel>
													<Select
														inputRef={department_ref}
														required
														labelId="company_list_label"
														id="company_list"
														open={isOpen} onOpen={handleOpen} onClose={handleClose}
														multiple
														value={selectDepartment}
														onChange={handleDepartmentChange}
														renderValue={(selected) => selected.join(', ')}
														MenuProps={MenuProps}
														fullWidth
														style={{ width: '100%', backgroundColor: '#fff' }}
													>
														{department.length>0 && department.map((dept) => (	
															<MenuItem key={dept.id} value={dept.name} className='test' style={{ position: 'relative' }}>
															<ListItemText 
																primary={dept.name} 
																primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
																/>
															</MenuItem>
														))}{
															department.length==0 && <MenuItem  className='test' style={{ position: 'relative',color:grey }}>
															<ListItemText 
																style={{ position: 'relative',color:grey }}
																primary={'No options'} 
																primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
																/>
															</MenuItem>
														}{<Button
															style={{background:"white",width
															:"100%",padding:"10px",position:"sticky",bottom:"0px",zIndex:'12345'}}
															variant="outlined"
															color="primary"
															onMouseDown={() => handleDepartmentDialogOpen()}
														>Add Department</Button>}

													</Select>
												</FormControl>
											</>
										)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="status"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={substatus}
													disablePortal
													onChange={(event, value) => { field.onChange(value) }}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '0px', position: 'relative', zIndex: "1223646466" }}>{children}
															{<Button
																style={{ width: '100%' }}
																variant="outlined"
																color="primary"
																onMouseDown={() => handleStatusDialogOpen()}
															>Add Subscription Staus</Button>}
														</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	label='Select Status'
																	variant='outlined'
																	fullWidth
																	required
																	FormHelperTextProps={{
																		style: { marginLeft: 0 }
																	}}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>

					{/*---------------Subscription Terms------------------- */}
					<Accordion expanded={checkForTerm} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickSubTerm} expandIcon={<ExpandMore />}>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Subscription Terms</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
								<div className='cstm-date' style={{ marginRight: '10px', width: '100%' }}>
									{/* <Controller
										name="start_date"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													inputRef={start_date_ref}
													{...field}
													variant='outlined'
													label="Start Date"
													type='date'
													InputLabelProps={{ shrink: true }}
													onChange={(event) => {
														field.onChange(event.target.value)
														setStartDate(event.target.value)
													}}
													fullWidth
													required
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													inputProps={{ max: `${endDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={startDate}
										setState={setStartDate}
										required={true}
										label='Start Date*'
										maxDate={endDate.date}
										condition='End Date'									
									/> */}
									<CustomDatePickerV2
										state={startDate}
										setState={setStartDate}
										required={true}
										label='Start Date*'
										maxDate={endDate.date}
										condition='End Date'									
									/>
								</div>
								<div className='cstm-date' style={{ width: '100%' }}>
									{/* <Controller
										name="end_date"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													inputRef={end_date_ref}
													{...field}
													variant='outlined'
													label="End Date"
													type='date'
													InputLabelProps={{ shrink: true }}
													fullWidth
													onChange={(event) => {
														field.onChange(event.target.value)
														setEndDate(event.target.value)
													}}
													required
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													inputProps={{ min: `${startDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={endDate}
										setState={setEndDate}
										required={true}	
										label='End Date*'
										minDate={startDate.date}
										condition='Start Date'											
									/> */}
									<CustomDatePickerV2
										state={endDate}
										setState={setEndDate}
										required={true}	
										label='End Date*'
										minDate={startDate.date}
										condition='Start Date'											
									/>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>

					{/*----------------------billing cycle------------------- */}
					<Accordion expanded={checkForBilling} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickSubBilling}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Billing Cycle</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div className='w-full'>
								<div style={{ display: 'flex', justifyContent: 'center', }}>
									<div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
										<div style={{ marginBottom: '10px', marginRight: '10px' }}>
											<Controller
												name="amount"
												className="mb-16"
												control={control}
												render={({ field }) => {
													return (
														<>
															<TextField
																inputRef={amount_ref}
																{...field}
																variant='outlined'
																label='Amount'
																fullWidth
																required
																FormHelperTextProps={{
																	style: { marginLeft: 0 }
																}}
																onBlur={()=>{
																	setValue('amount', formatPriceFieldOnBlur(getValues('amount')))																		
																}}
																onKeyPress={(event) => {
																	if (!/[0-9/-]+/.test(event.key)) {
																		event.preventDefault();
																	}
																}}
																onChange={(event) => { field.onChange(event.target.value ?? '')}}
																style={{ width: '100%', backgroundColor: '#fff' }}
																InputProps={{
																	startAdornment:
																		<InputAdornment>
																			<Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
																		</InputAdornment>
																}}
															/>
														</>
													)
												}}
											/>
										</div>
										<div style={{ display: 'flex', marginBottom: '10px', width: '50%' }}>
											<Controller
												name="currency"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															options={countries}
															disablePortal
															getOptionLabel={option => option.currency ?? ''}
															renderOption={option =>
																<>
																	<InputAdornment style={{ marginRight: '15px' }}>
																		<img
																			loading="lazy"
																			width="20"
																			src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
																			srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
																			alt=""
																		/>
																	</InputAdornment>
																	{option.currency}
																</>}
															onChange={(event, value) => {
																field.onChange(value)
																if (value) handleCurrentCountry(value.code)
															}}
															PaperComponent={({ children }) => (
																<Paper style={{ marginTop: '50px' }}>{children}</Paper>
															)}
															renderInput={params => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			{...params}
																			label='Currency'
																			variant='outlined'
																			fullWidth
																			required
																			inputRef={ref}
																			FormHelperTextProps={{
																				style: { marginLeft: 0 }
																			}}
																			style={{ width: '100%', backgroundColor: '#fff' }}
																			InputProps={{
																				startAdornment:
																					<InputAdornment style={{ marginRight: '15px' }}>
																						<img
																							loading="lazy"
																							width="20"
																							src={`https://flagcdn.com/w20/${currentCountry?.toLowerCase()}.png`}
																							srcSet={`https://flagcdn.com/w40/${currentCountry?.toLowerCase()}.png 2x`}
																							alt=""
																						/>
																					</InputAdornment>
																			}}
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='w-full'>
								<div style={{ display: 'flex', justifyContent: 'center', }}>
									<div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div style={{ marginRight: '10px', marginBottom: '10px', }}>
												<span color="white" variant="contained"
													style={{
														backgroundColor: "#F5F5F5",
														fontSize: '16px',
														color: 'gray',
														borderRadius: '4px'
													}}>
													Every*:
												</span>
											</div>

											<div style={{ marginRight: '10px', marginBottom: '10px', width: '70px' }}>
												<Controller
													name="every"
													className="mb-16"
													control={control}
													render={({ field }) => (
														<>
															<TextField
																{...field}
																variant='outlined'
																label=""
																inputRef={every_ref}
																type='number'
																fullWidth
																min="0"
																onChange={(e) => {
																	let val = parseInt(e.target.value, 10);
																	if (isNaN(val)) {
																	  setEveryFielVal('');
																	} else {
																	  // is A Number
																	  val = val >= 0 ? val : 0;
																	  setEveryFielVal(val);
																	}
																  }}
																value = {everyFielVal}
																InputLabelProps={{ shrink: true }}
																required
																style={{ width: '100%', backgroundColor: '#fff' }}
															/>
														</>
													)}
												/>
											</div>
										</div>
										<div style={{ marginRight: '10px', marginBottom: '10px', width: '500px' }}>
											<Controller
												name="month"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															options={paymentTerm}
															disablePortal
															
															onChange={(event, value) => { field.onChange(value) }}
															PaperComponent={({ children }) => (
																<Paper>
																{children}
																{<Button
																	style={{ width: '100%' }}
																	variant="outlined"
																	color="primary"
																	onMouseDown={() => setOpenPaymentTermQuickAddDialog(true)}
																>
																	Add Payment Term
																</Button>}
															</Paper>
															)}
															getOptionLabel={option => option.name ?? ''}
															renderInput={params => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			{...params}
																			label='Payment Term'
																			variant='outlined'
																			fullWidth
																			required
																			inputRef={month_ref}
																			FormHelperTextProps={{
																				style: { marginLeft: 0 }
																			}}
																			style={{ width: '100%', backgroundColor: '#fff' }}
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</div>
										<div style={{ marginRight: '10px', marginBottom: '10px', width: '100%' }}>
											{/* <Controller
												name="renewal_date"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<TextField
															{...field}
															variant='outlined'
															label="Date"
															type='date'
															inputRef={billing_date_ref}
															InputLabelProps={{ shrink: true }}
															fullWidth
															required
															onChange={(event) => {
																field.onChange(event.target.value)
																setEndDate(event.target.value)
															}}

															FormHelperTextProps={{
																style: { marginLeft: 0 }
															}}
															inputProps={{ min: `${startDate}` }}
															style={{ width: '100%', backgroundColor: '#fff' }}
														/>
													</>
												)}
											/> */}
											{/* <CustomDatePicker
												state={billingDate}
												setState={setBillingDate}
												required={true}	
												label='Date*'										
											/> */}
											<CustomDatePickerV2
												state={billingDate}
												setState={setBillingDate}
												required={true}	
												label='Date*'										
											/>
										</div>
									</div>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>

					{/*---------------Payment------------------- */}
					<Accordion expanded={checkPaymentMethod} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickSubPayment}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Payment Method</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', marginBottom: '10px' }}>
								<RadioGroup aria-label="quiz" name="quiz" value={purchaseOrder} onChange={handleRadioChange}>
									<div style={{ display: 'flex' }}>
										<div style={{ marginRight: '20px' }}>
											<FormControlLabel value="CreditCard" control={<Radio color="primary" />} label="Credit Card" />
										</div>
										<div style={{ marginLeft: '20px' }}>
											<FormControlLabel value="purchaseOrder" control={<Radio color="primary" />} label="Purchase Order" />
										</div>
									</div>
								</RadioGroup>
							</div>
							<div>
							{purchaseOrder == 'purchaseOrder' && <div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="po_number"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='PO Number'
													fullWidth
													required
													inputRef={po_number_ref}
													inputProps={{ readOnly: false }}
													error={!!errors.contractId}
													helperText={errors?.contractId?.message}
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									{/* <Controller
										name="po_date"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="PO Date"
													type='date'
													inputRef={po_date_ref}
													required
													InputLabelProps={{ shrink: true }}
													onChange={(event) => {
														field.onChange(event.target.value)
														setStartDate(event.target.value)
													}}
													fullWidth
													FormHelperTextProps={{
														style: { marginLeft: 0 }
													}}
													inputProps={{ max: `${endDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={paymentDate}
										setState={setPaymentDate}
										required={true}	
										label='PO Date*'										
									/> */}
									<CustomDatePickerV2
										state={paymentDate}
										setState={setPaymentDate}
										required={true}	
										label='PO Date*'										
									/>
								</div>
							</div>}
							{purchaseOrder == 'CreditCard' && <div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="holder_name"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="Card Holder's Name"
													fullWidth
													required
													inputRef={card_holder_ref}
													inputProps={{ readOnly: false }}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="card_number"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													required
													variant='outlined'
													inputRef={card_number_ref}
													value={cardNumber}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													label="Last 4 Digits of Credit Card"
													onChange={(e)=>{
														if(e.target.value.length > 4){
															return
														}
														setCardNumber(e.target.value)
													}}
													fullWidth
													inputProps={{ readOnly: false }}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>}
							</div>
						</AccordionDetails>
					</Accordion>

					{/*---------------Reminder------------------- */}
					<Accordion expanded={checkReminder} className={classes.subSection}>
						<AccordionSummary onClick={summeryClickReminder}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Reminder</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px' }}>
								<Typography style={{ fontSize: '17px' }}>Dalos will notify you automatically 60 days before contract expiration.</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									checked={noReminderCheckBox}
									onChange={handleChangeNoReminderCheckbox}
									disabled={reminderCheckboxes.supportContact === true || reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true}
								/>
								<Typography style={{ fontSize: '17px' }}>Do not remind me</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									disabled={noReminderCheckBox === true}
									name={'term45'}
									checked={reminderCheckboxes.term45}
									onChange={handleChangeReminderCheckBoxes}
								/>
								<Typography style={{ fontSize: '17px' }}>Notify me 45 days</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									disabled={noReminderCheckBox === true}
									name={'term30'}
									checked={reminderCheckboxes.term30}
									onChange={handleChangeReminderCheckBoxes}
								/>
								<Typography style={{ fontSize: '17px' }}>Notify me 30 days</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									disabled={noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false )}
									name={'supportContact'}
									checked={reminderCheckboxes.supportContact}
									onChange={handleChangeTextMeOption}
								/>
								<div className='flex items-center' style={{ width: '60%' }}>
									<Typography style={{ fontSize: '17px', marginRight: '10px', width: '20%', whiteSpace: "nowrap" }}>Text Me</Typography>
									<Controller
										name="support_phone_number"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Support Phone Number'
													fullWidth
													inputRef={support_number_ref}
													value={formattedNumber}
													error={!!errors.support_phone_number}
													helperText={errors?.support_phone_number?.message}
													FormHelperTextProps={{
														style: { 
															margin : 0, 
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													onChange={(e)=>{
														changeNumberFormat(e)
													}}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													style={{ width: '100%', backgroundColor: '#fff' }}
													disabled={noReminderCheckBox === true || reminderCheckboxes.supportContact === false}
												/>
											</>
										)}
									/>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<div style={{ padding: 0, margin: 20, display: 'flex', justifyContent: 'left' }}>
						<Button onClick={clickSubmit} className={classes.endButtons} style={{ marginRight: '10px' }} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons} variant='outlined' color='primary' onClick={onCancel}>Cancel</Button>
					</div>
				</form>
			</Drawer>
		</div>
	)
}

export default AddSubscriptionDialog
