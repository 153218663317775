import useToast from '@fuse/hooks/useToast'
import { Button, 
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer,InputAdornment,Paper,CircularProgress ,Modal
} from '@material-ui/core'
import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';


const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft:'20px',
		marginRight:'20px',
		marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 600
	},
}))

function AddCloudProviderDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.cloudProviderApp.formViewDialog)
	const { data:editFromState } = useSelector(({cloudProviderApp}) => cloudProviderApp.formViewDialog);
	const [imageName, setImageName] = useState('')
	const [imageLocation, setImageLocation] = useState('')
	const [previewImageLink,setPreviewImageLink] = useState('')
	const [openImagePreview,setOpenImagePreview] = useState(false)
	const [loading, setLoading] = useState(false)
	const [formattedNumber, setFormattedNumber] = useState('')


	//form validation 
	const schema = yup.object({
		// phone: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		// }),
	})

	const defaultValues = {
		provider_name: '',
		url: '',
		phone: '',
		address_1: '',
		address_2: '',
		city: '',
		state: '',
		zip: '',
		country: '',
	}

	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setImageName('')
		setFormattedNumber('')
		setPreviewImageLink('')
		setImageLocation('')
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setImageName('')
		setPreviewImageLink('')
		setFormattedNumber('')
		setImageLocation('')
	}

	const onChangeImageUpload = (event) => {
		setLoading(true)
		const selectedFile = event.target.files[0]
		if(!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
			setLoading(false)
			return;
		} else {
			let formData = new FormData()
			formData.append("file", selectedFile)

			axios.post('/cloud-provider/cloud-provider-image-upload', formData ).then(response => {
			if(response.status === 201) {
				toast.error(response.data.message)
			}
			else{
				setImageName(selectedFile.name)
				setImageLocation(response.data.data.imageLocation)
				setPreviewImageLink(response.data.data.imageLink)
			}
			}).catch(err => {
				console.log('136=>',err);
				toast.error('Something went wrong!')
			}).finally(() => {
				setLoading(false)
			})
		}
	}
	
	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFromState){
			setValue('url',editFromState?.url?.slice(4,editFromState?.url.length))
			setValue('provider_name',editFromState.provider_name);
			// setValue('phone',editFromState.original_info.phone);
			setFormattedNumber(editFromState.original_info.phone ?? '')
			setValue('address_1',editFromState.original_info.address_1);
			setValue('address_2',editFromState.original_info.address_2);
			setValue('city',editFromState.original_info.city);
			setValue('state',editFromState.original_info.state);
			setValue('zip',editFromState.original_info.zip);
			if(editFromState.country == 'No Country'){
				setValue('country','');
			}else{
				setValue('country',{name:editFromState.country,code:'N/A'});
			}
			setImageName(editFromState.image_name)
			setImageLocation(editFromState.image_link)
			setPreviewImageLink(editFromState?.preview_image_link ?? '')
		}
	},[editFromState])

	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

	const { errors } = formState

	function editCloudProvider(payload){
		axios.post(`/cloud-provider/edit`, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success('Subscription Provider updated Successfully.')
				dispatch(setQuickFilter(null))
				if(props.getCloudProvider){
				props.getCloudProvider()
				}
			}
		}).catch(err => {
			console.log('212=>',err);
			toast.error('Something went wrong!')
		})
	}

	const manageFormData = (formData) => {
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
		if(formData.url.indexOf('.') == -1){
			toast.error('Please enter a valid URL')
			return
		}
		let payload = {
			entity_external_platform_id: String(Math.trunc(Math.random()*200)),
			provider_name:formData.provider_name,
			phone:formattedNumber || '',
			address_1:formData.address_1 || '',
			address_2:formData.address_2 || '',
			city:formData.city || '',
			state:formData.state ||'',
			zip:formData.zip || '',
			country:formData?.country?.name || '',
			url:"www."+(formData.url).trim(),
			image_link:imageLocation,
			image_name:imageName
		}
		if(editFromState){
			let payload = {
				provider_name:formData.provider_name,
				phone:formattedNumber || '',
				address_1:formData.address_1 || '',
				address_2:formData.address_2 || '',
				city:formData.city || '',
				state:formData.state ||'',
				zip:formData.zip || '',
				country:formData?.country?.name || '',
				url:"www."+(formData.url).trim(),
				edit_id:Number(editFromState.id),
				image_link:imageLocation == 'No Image Link' ? '' : imageLocation,
				image_name:imageName == "No Image Name" ? '' : imageName
			}
			editCloudProvider(payload)
		}else{
			axios.post('/cloud-provider/add', payload).then(response => {
				if(response.status == 201){
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					closeDialog()
					toast.success(response.data.message)
					dispatch(setQuickFilter(null))
					if(props.getCloudProvider){
					props.getCloudProvider()
					}
				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})
		}	
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	return (
		<div>
			<Modal
				open={openImagePreview}
				className={classes.modal}
			>
				<div style={{
					height: 500,
					width: 500,
					backgroundColor: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems:'center',
					borderRadius: '13px',
					display: 'flex',
					flexDirection: 'column'
				}}>
					<IconButton style={{left: '203px',bottom: '19px'}} onClick={()=>{setOpenImagePreview(false)}}>
						<Icon>close</Icon>
					</IconButton>
					<img style={{height: '400px',width: '400px'}} src={previewImageLink} alt='' ></img>
				</div>
			</Modal>
		<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
			<DialogTitle>{Boolean(editFromState) ? 'Update' : 'Add'} Subscription Provider</DialogTitle>
			<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
				<Icon>close</Icon>
			</IconButton>
			</div>

			<form onSubmit={ handleSubmit(manageFormData, errorData) }>

			{/*--------------------------Item Information----------------------------- */}
				
			<div className={classes.subSection}>
				<div style={{ width: '100%' }}>
				<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
					<Divider style={{marginBottom: '10px'}}/>
				</div>

				<div >
					<div style={{ display: 'flex', justifyContent:'center' }}>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='url' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										InputProps={{
											startAdornment: 
											<InputAdornment style={{color: "grey",marginRight:'0'}} disableTypography position="start">
												www.</InputAdornment>,
										}}
										variant='outlined'
										label='URL' 
										autoFocus
										fullWidth   
										required
										FormHelperTextProps={{
											style: { 
											margin : 0, 
											backgroundColor: '#f4f4f4',
											width: '100%',
											paddingTop: '2px'
											}
										}}
									/>
								</>
								)}
							/>
						</div> 
					</div>

					<div style={{ display: 'flex', justifyContent:'center' }}>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='provider_name' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										variant='outlined'
										label='Provider Name' 
										autoFocus
										fullWidth
										required
									/>
								</>
								)}
							/>
						</div> 
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='phone' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										value={formattedNumber}
										variant='outlined'
										label='Phone Number' 
										onKeyPress={(event) => {
											if (!/[0-9/-]+/.test(event.key)) {
												event.preventDefault();
											}
										}}
										onChange={(e)=>{
											changeNumberFormat(e)
										}}
										autoFocus
										fullWidth
										FormHelperTextProps={{
											style: {
												margin: 0,
												backgroundColor: '#f4f4f4',
												width: '100%',
												paddingTop: '2px'
											}
										}}
										error={!!errors.phone}
										helperText={errors?.phone?.message}
									/>
								</>
								)}
							/>
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent:'center' }}>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='address_1' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										variant='outlined'
										label='Address Line 1' 
										autoFocus
										fullWidth
									/>
								</>
								)}
							/>
						</div>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='address_2' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										variant='outlined'
										label='Address Line 2' 
										autoFocus
										fullWidth
									/>
								</>
								)}
							/>
						</div> 
					</div>
					<div style={{ display: 'flex', justifyContent:'center' }}>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='city' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										variant='outlined'
										label='City' 
										autoFocus
										fullWidth
									/>
								</>
								)}
							/>
						</div>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='state' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										variant='outlined'
										label='State' 
										autoFocus
										fullWidth
									/>
								</>
								)}
							/>
						</div> 
					</div>
					<div style={{ display: 'flex', justifyContent:'center' }}>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name='zip' 
								control={control}
								render={( { field} ) => (
								<>
									<TextField 
										{...field}
										style={{background: 'white'}}
										variant='outlined'
										label='Zip' 
										autoFocus
										fullWidth
									/>
								</>
								)}
							/>
						</div>
						<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name="country"
								className="mb-16"
								control={control}
								render={({ field: { ref, ...field }, fieldState: { error } }) => (
								<>
									<Autocomplete
									{...field}
									style={{ width: '100%' }}
									options={countries}
									disablePortal
									onChange={(event, value) => field.onChange(value)}
									PaperComponent={({ children }) => (
										<Paper style={{ marginTop:'0px' }}>{children}</Paper>
									)}
									getOptionLabel={option => option.name ?? ''}
									renderInput={(params) => {
										return (
										<div ref={params.InputProps.ref}>
											<TextField
											{...params}
											label="Country"
											style={{background: 'white'}}
											inputRef={ref}
											variant="outlined"
											fullWidth
											/>
										</div>
										)
									}}
									/>
								</>
								)}
							/>
						</div> 
					</div>
					<div style={{display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>
						<Typography variant='subtitle1' style={{marginBottom: '10px'}}>Upload Image</Typography>
						<div style={{display:'flex', alignItems: 'center'}}>
							<input
								accept="image/*"
								id="upload"
								type="file"
								onChange = {(event) => onChangeImageUpload(event)}
								hidden
							/>
							<label htmlFor='upload' className={classes.uploadImageButton}>
								Select File
							</label>
							<div style={{marginLeft: '10px'}}>
								{ loading ? 
									<>
										<div className={classes.loader}>
										<CircularProgress />
										</div>
									</> : <Typography 
											onClick={()=>{
												if(imageName == '' || imageName == 'No Image Name') return;
												setOpenImagePreview(true)
											}}
											style={{color: 'black',textDecoration:'none',cursor: 'pointer'}}
										>{imageName}</Typography>
								}
							</div>
						</div>
					</div>
					
					<Typography>{'Accepts jpg, jpeg, png, gif. 2Mb max (rec. 700x430px)'}</Typography>
				</div>	
			</div>

			<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
				<Button 
					className={classes.endButtons} 
					style={{marginRight: '10px'}} 
					type='submit' variant='contained' 
					color='primary'
				>
					{editFromState ? 'Update' : 'Add'}
				</Button>
				<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
			</div>
			</form>        
		</Drawer>
	</div>
	)
}

export default AddCloudProviderDialog
