import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import axios from '@fuse/utils/axios';
import { useRouter } from '@fuse/hooks';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const  Activities = forwardRef(({serviceRequestId},ref) => {
  const gridRef = useRef();
  const router = useRouter();
  const id = router.params.id ?? serviceRequestId;
  
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 430);
  const [rowData1, setRowData1] = useState([]);
  const [loading, setLoading ] = useState(false);

  const tableHeaders1 = [
    {
      field: "action_id",
      headerName: "Action Id",
      headerComponentFramework: CustomFilter
    },
    {
      field: "ticket_id",
      headerName: "Ticket Id",
      headerComponentFramework: CustomFilter
    },
    {
      field: "action_date",
      headerName: "Action Date",
      headerComponentFramework: CustomFilter
    },
    {
      field: "action",
      headerName: "Action",
      headerComponentFramework: CustomFilter
    },
    {
      field: "field_name",
      headerName: "Field Name",
      headerComponentFramework: CustomFilter
    },
    {
      field: "previous_value",
      headerName: "Previous value",
      headerComponentFramework: CustomFilter
    },
    {
      field: "current_value",
      headerName: "Current value",
      headerComponentFramework: CustomFilter
    },
    {
      field: 'name',
      headerName: 'Name',
      headerComponentFramework: CustomFilter
    },
    {
      field: 'description',
      headerName: 'Description',
      headerComponentFramework: CustomFilter
    },
    {
      field: 'user_role',
      headerName: 'User Role',
      headerComponentFramework: CustomFilter
    }
  ];

  const action_to_color = {
    "CREATED": "green",
    "UPDATED": "orange",
    "ARCHIVED": "red",
    "RESTORED": "blue",
    'DELETED': "red"
  }
  function formatLogs(data) {
		let {length} = data
		return data.map((item) => {
			const obj = {
				id: item.id,
				action_id: `act${length}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				ticket_id: item.service_request ? item.service_request.ticket_id : item.ticket_id,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				asset_serial_number: item.asset_serial_number || 'N/A'
			}
			length--;
			return obj
		})
	}

  const onGridReady = params => {
		// Following line to make the currently visible columns fit the screen  
		params.api.sizeColumnsToFit();

		// Following line dynamic set height to row on content
		params.api.resetRowHeights();
	};

  function cellRendererFramework(params){
    if(params.colDef.field === "action"){
      return (
          <span style={{
            color: action_to_color[params.value],
            fontWeight: 'bold'
          }}>{params.value}</span>
      )
    }if( params.colDef.field === 'description'){
      return(
          <span
              style={{
                  wordBreak: 'break-word'
              }}
          >{params.value}</span>
      )
  }
      return(
        <>{params.value}</>
      )
    
  }
 
  function fetchActivities(){
    setLoading(true)
    axios.get(`/service/fetch-service-request-activities/${id}`).then((res)=>{
      setRowData1(formatLogs(res.data.data.activities))
    }).catch((err) => {
      console.log(err)
    }).finally(()=>setLoading(false))
  }

  useImperativeHandle(ref, ()=>({fetchActivities}));

  useEffect(()=>{
    fetchActivities()
  },[]);

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: windowHeight + 105,
          fontSize: '12px'
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData1}
          animateRows
          onGridReady={onGridReady}
          paginationPageSize={10}
          pagination
        >
          {tableHeaders1.map(header => {
              return (
                <AgGridColumn
                  key={header.field}
                  field={header.field}
                  headerName={header.headerName}
                  width={200}
                  minWidth={200}
                  maxWidth={200}
                  sortable={false}
                  flex={1}
                  filter="text"
                  wrapText
                  autoHeight = {header.field === 'description'}
                  // floatingFilter = {true}
                  cellRendererFramework={cellRendererFramework}
                  headerComponentFramework={header.headerComponentFramework}
                />
              )
          })}
        </AgGridReact>
      </div>
    </>
  )
})

export default Activities