import useToast from '@fuse/hooks/useToast'
import { Button, DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer} from '@material-ui/core'
import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
		uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft:'20px',
		marginRight:'20px',
		marginBottom:'20px',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	drawerWidth: {
		width: 600
	},
}))

function AddServiceRequestCategory(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)

	const openState = useSelector((state) => state.ServiceRequestCategoryApp.formViewDialog)
	const { data:editFromState } = useSelector(({ServiceRequestCategoryApp}) => ServiceRequestCategoryApp.formViewDialog);

	//form validation
	const schema = yup.object({
		category_name: yup.string().required('Category name is required!')
		.matches(/^[A-Za-z\s]+$/, 'Category name cannot contain numbers, alphanumeric characters, or special characters!')
		.matches(/\S/, 'Category name cannot be only spaces!')
		.max(30, 'Category name must be at most 30 characters!'),

	})


	const defaultValues = {
		category_name:'',
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});
	
	const onCancel = () => {
		reset(defaultValues)
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
	}

	const closeDialog = () => {
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
	}

	useEffect(()=>{
		if(editFromState){
			setValue('category_name',editFromState.category_name);
		}
	},[editFromState])

	const { errors } = formState

	function editCategory(payload){
		axios.post(`/service-request-category/edit`, payload).then(response => {
			reset(defaultValues)
			closeDialog()
			toast.success('Service ticket category updated successfully.')
			if(props.getServiceRequestCategory){
				props.getServiceRequestCategory()
			}
		}).catch(err => {
			toast.error(err.response.data.message || 'Something went wrong!')
		})
	}

	const manageFormData = (formData) => {
		let payload = {
			category_name: formData.category_name,
		}
		if(editFromState){
			let payload = {
				category_name: formData.category_name,
				edit_id: Number(editFromState.id),
			}
			editCategory(payload)
		}else{
			axios.post('/service-request-category/add', payload).then(response => {
				reset(defaultValues)
				closeDialog()
				toast.success('Service ticket category added successfully.')
				if(props.getServiceRequestCategory){
					props.getServiceRequestCategory()
				}
			}).catch(err => {
				toast.error(err.response.data.message || 'Something went wrong!')
			})
		}	
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}


	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{Boolean(editFromState) ? 'Update' : 'Add'} Service Ticket Category</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

					{/*--------------------------Item Information----------------------------- */}
						
					<div className={classes.subSection}>
						<div style={{ width: '100%' }}>
						<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
							<Divider style={{marginBottom: '10px'}}/>
						</div>

						<div >
							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='category_name' 
										control={control}
										render={( { field} ) => (
										<>
											<TextField 
												{...field}
												style={{background: 'white'}}
												variant='outlined'
												label='Category Name' 
												autoFocus
												fullWidth
												required
											/>
										</>
										)}
									/>
								</div> 
							</div>

						</div>	
					</div>

					<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button 
							className={classes.endButtons} 
							style={{marginRight: '10px'}} 
							type='submit' variant='contained' 
							color='primary'
						>
							{editFromState ? 'Update' : 'Add'}
						</Button>
						<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
					</div>
				</form>        
			</Drawer>
		</div>
	)
}

export default AddServiceRequestCategory
