import useToast from '@fuse/hooks/useToast'
import { Button, 
  DialogTitle, Divider, Icon,
  IconButton, makeStyles, TextField, Typography,
  Drawer 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog,setFromData } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft:'20px',
					marginRight:'20px',
					marginBottom:'20px',
			padding: '10px', 
			backgroundColor: '#f4f4f4', 
			borderRadius:'4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		drawerWidth: {
		width: 600
	},
	}
))

function AddCategoryDialog(props) {

	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.categoryApp.formViewDialog)
	const { data:editFromState } = useSelector(({categoryApp}) => categoryApp.formViewDialog);
	const schema = yup.object({
		category: yup.string().required('Asset Category Name is required!'),
	})

	const defaultValues = {
		category: '',
		description: ''
	}

	const onCancel = () => {
		reset(defaultValues)
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const closeDialog = () => {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
			mode: 'onChange',
			defaultValues,
			resolver: yupResolver(schema)
		});

	useEffect(()=>{
		if(editFromState){
			setValue('category',editFromState.category_name)
			setValue('description',editFromState.description)
		}
	},[editFromState])
	const { errors } = formState


 	const manageFormData = (formData) => {

		let payload
		let api_url

		if(!editFromState){
			//add payload
			payload = {
				entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
				category_name: formData.category.trim(),
				category_type: 'hardware',
				description: formData.description.trim()
			}
			api_url = '/category/add'

		} else {
			//edit payload
			api_url = `/category/edit?category_type=hardware`
			payload = {
				category_id: editFromState.id,
				category_name: formData.category.trim(),
				description: formData.description.trim()
			}
		}

		axios.post(api_url, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success(response.data.message)
				dispatch(setQuickFilter(null))
				props.refreshlist()
			}      
		}).catch(err => {
			console.log('212=>',err);
			toast.error('Something went wrong!')
		})    
  	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	return (
		<div>
		<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
				<DialogTitle>{editFromState ? 'Update' : 'Add'} Asset Category</DialogTitle>
				<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
					<Icon>close</Icon>
				</IconButton>
			</div>

			<form onSubmit={ handleSubmit(manageFormData, errorData) }>
				{/*------------------Item Information------------------------------ */}
				<div className={classes.subSection}>
					<div style={{ width: '100%' }}>
					<Typography style={{fontSize:'16px'}}>Required Information *</Typography>                  <Divider style={{marginBottom: '10px'}}/>
					</div>
					<div >
						<div style={{ display: 'flex', justifyContent:'center',flexDirection: 'column' }}>
							<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
								<Controller
									name='category' 
									control={control}
									render={( { field} ) => (
										<TextField 
											{...field}
											variant='outlined'
											label='Asset Category'
											required 
											autoFocus
											fullWidth
											style={{background: 'white'}}
											error={!!errors.category}
											helperText={errors?.category?.message}
											FormHelperTextProps={{
												style: { 
													margin : 0, 
													backgroundColor: '#f4f4f4',
													width: '100%',
													paddingTop: '2px'
												}
											}}
									/>
								) }
								/>
							</div>  
							<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='description' 
										control={control}
										render={( { field} ) => (
											<>
												{/* <TextareaAutosize 
													{...field}
													style={{background: 'white',width: '100%',minHeight: '20%',border: 'solid 2px #c8c5c5',padding: '9px'}}
													variant='outlined' 
													label='Status description' 
													fullWidth
													placeholder="Status description"
													required
												/> */}
												<TextField 
													{...field}
													style={{background: 'white',resize: 'auto'}}
													variant='outlined'
													label='Description' 
													fullWidth
													// required
													multiline
													rows={10}
													inputProps={{
														style: {
														resize: "vertical",
														},
													}}
												/>
											</>
										)}
									/>
								</div> 
						</div>
					</div>
				</div>
				<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
					<Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? 'Update' : 'Add'}</Button>
					<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
				</div>					
			</form>        
		</Drawer>
	</div>
	)
}

export default AddCategoryDialog
