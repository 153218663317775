import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import { setUserData } from './userSlice';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

export const submitLogin = ({ email, password, remember }) => async dispatch => {
	dispatch(loggingIn(true));
	return jwtService
		.signInWithEmailAndPassword(email.toLowerCase(), password, remember)
		.then(user => {
			dispatch(setUserData(user));
			dispatch(
				showMessage({
					message     : 'Logged in successfully',//text or html
					autoHideDuration: 6000,//ms
					anchorOrigin: {
						vertical  : 'bottom',//top bottom
						horizontal: 'right'//left center right
					},
					variant: 'success'//success error info warning null
				})
			);
			dispatch(loggingIn(false));
			return dispatch(loginSuccess());
		})
		.catch(errors => {
			console.log(errors)
			dispatch(
				showMessage({
					message     : typeof(errors) == 'string' ? errors : 'Could not login',//text or html
					autoHideDuration: 6000,//ms
					anchorOrigin: {
						vertical  : 'bottom',//top bottom
						horizontal: 'right'//left center right
					},
					variant: 'danger'//success error info warning null
				})
			);
			dispatch(loggingIn(false));
			return dispatch(loginError(errors));
		});
};

export const submitGoogle = ({ idToken, google_id }) => async dispatch =>{
	dispatch(loggingIn(true));
	return jwtService
		.signInWithGoogle(idToken, google_id)
		.then(user => {
			dispatch(setUserData(user));
			dispatch(
				showMessage({
					message     : 'Logged in successfully',//text or html
					autoHideDuration: 6000,//ms
					anchorOrigin: {
						vertical  : 'bottom',//top bottom
						horizontal: 'right'//left center right
					},
					variant: 'success'//success error info warning null
				})
			);
			dispatch(loggingIn(false));
			return dispatch(loginSuccess());
		})
		.catch(errors => {
			dispatch(
				showMessage({
					message     : errors,//text or html
					autoHideDuration: 6000,//ms
					anchorOrigin: {
						vertical  : 'bottom',//top bottom
						horizontal: 'right'//left center right
					},
					variant: 'danger'//success error info warning null
				})
			);
			dispatch(loggingIn(false));
			return dispatch(loginError(errors));
		});
};

export const submitMicrosoft = ({ uniqueId, username }) => async dispatch =>{
	dispatch(loggingIn(true));
	return jwtService
		.signInWithMicrosoft(uniqueId, username)
		.then(user => {
			dispatch(setUserData(user));
			dispatch(
				showMessage({
					message     : 'Logged in successfully',//text or html
					autoHideDuration: 6000,//ms
					anchorOrigin: {
						vertical  : 'bottom',//top bottom
						horizontal: 'right'//left center right
					},
					variant: 'success'//success error info warning null
				})
			);
			dispatch(loggingIn(false));
			return dispatch(loginSuccess());
		})
		.catch(errors => {
			dispatch(
				showMessage({
					message     : errors,//text or html
					autoHideDuration: 6000,//ms
					anchorOrigin: {
						vertical  : 'bottom',//top bottom
						horizontal: 'right'//left center right
					},
					variant: 'danger'//success error info warning null
				})
			);
			dispatch(loggingIn(false));
			return dispatch(loginError(errors));
		});
};

export const submitLoginWithFireBase = ({ email, password }) => async dispatch => {
	if (!firebaseService.auth) {
		console.warn("Firebase Service didn't initialize, check your configuration");

		return () => false;
	}
	return firebaseService.auth
		.signInWithEmailAndPassword(email, password)
		.then(() => {
			return dispatch(loginSuccess());
		})
		.catch(error => {
			const emailErrorCodes = [
				'auth/email-already-in-use',
				'auth/invalid-email',
				'auth/operation-not-allowed',
				'auth/user-not-found',
				'auth/user-disabled'
			];
			const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];
			const response = [];

			if (emailErrorCodes.includes(error.code)) {
				response.push({
					type: 'email',
					message: error.message
				});
			}

			if (passwordErrorCodes.includes(error.code)) {
				response.push({
					type: 'password',
					message: error.message
				});
			}

			if (error.code === 'auth/invalid-api-key') {
				dispatch(showMessage({ message: error.message }));
			}

			return dispatch(loginError(response));
		});
};

const loginSuccessPersistConfig = {
	key: 'loginSuccess',
	storage: sessionStorage
}

const loginErrorPersistConfig = {
	key: 'loginError',
	storage: sessionStorage
}

const initialState = {
	success: false,
	errors: [],
	rememberUser: false,
	loggingIn:false,
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: persistReducer(loginSuccessPersistConfig,(state, action) => {
			state.success = true;
			state.errors = [];
		}),
		loginError: persistReducer(loginErrorPersistConfig,(state, action) => {
			state.success = false;
			state.errors = action.payload;
		}),
		rememberUser: (state, action) => {
			state.rememberUser = action.payload;
		},
		loggingIn: (state, action) => {
			state.loggingIn = action.payload;
		},
	},
	extraReducers: {}
});

export const { loginSuccess, loginError, rememberUser, loggingIn } = loginSlice.actions;

export default loginSlice.reducer;
