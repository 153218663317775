import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { setfilterQuery } from '../store/filterParamsReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { UploadIcon } from '@fuse/icons';
import { openCsvDialog } from "../store/csvDialogReducer"
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';


const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)

	const { hasPermission } = usePermission()
	const addAssetStatusPermission = hasPermission('add-asset_status')
	const updateAssetStatusPermission = hasPermission('update-asset_status')
	const addReportPermission = hasPermission('add-report')
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);


	function handleSearchReset(){
		props.handleSearchReset();
		props.handleSearchSuggestions("", currentFilter)
		setSearchQuery("");
	}

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			{/* Main header */}
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Asset Statuses List
				</Typography>
			</div>

			<div style={{display:'flex'}}>
				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}
				{/* Csv */}
				{(addAssetStatusPermission) && 
					<div className={classes.buttonContainer}>
						<Button
							onClick={() => dispatch(openCsvDialog())}
							variant="contained"
							color="secondary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'150px',marginRight: '10px'}}
							startIcon={
								<SvgIcon fontSize="small"><UploadIcon color="default" /></SvgIcon>
							}
						>
							Upload CSV
						</Button>
					</div>
				}
				{/* Add new asset status */}
				{ addAssetStatusPermission && 
					<div className={classes.buttonContainer}>
						<Button
							variant="contained"
							color="secondary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'200px', marginRight: '10px'}}
							onClick = {()=>dispatch(openFormViewerDialog())}
							startIcon={
							<Icon fontSize="small" title="Add Asset Status">
								add
							</Icon>}>
								Add Asset Status
						</Button>
					</div>
				}
				{/* save view */}
				{/* <div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight:"20px"}}
						onClick = {()=>dispatch(openReportViewerDialog())}
						startIcon={
						<Icon fontSize="large" title="Save View">
							save
						</Icon>}>
							Save View
					</Button>
				</div> */}
				{/* search */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Asset Status"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								disabled={props.currentSearch == ''}
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	)
}

export default DynamicFieldsHeader;
