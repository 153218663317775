export function customDateSort(date1, date2) {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  if (parsedDate1 < parsedDate2) {
      return -1;
  } else if (parsedDate1 > parsedDate2) {
      return 1;
  } else {
      return 0;
  }
}

  export function customCaseInsensitiveStringSort(str1, str2) {
    const lowerStr1 = str1.toLowerCase();
    const lowerStr2 = str2.toLowerCase();

    if (lowerStr1 < lowerStr2) {
        return -1;
    } else if (lowerStr1 > lowerStr2) {
        return 1;
    } else {
        return 0;
    }
}
