import { useDeepCompareEffect } from '@fuse/hooks';
import FuseLayouts from '@fuse/layouts/FuseLayouts';
import _ from '@lodash';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import { generateSettings, setSettings } from 'app/store/fuse/settingsSlice';
import { memo, useContext, useMemo, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import axios from "@fuse/utils/axios";
import { token } from "@fuse/utils/axios";
import { isExcludedRoute } from 'app/containers/authCheck';
import { setAllPages } from 'app/auth/store/pagesAccessSlice';

const useStyles = makeStyles(theme => ({
	'@global': {
		'code:not([class*="language-"])': {
			color: theme.palette.secondary.dark,
			backgroundColor: theme.palette.type === 'light' ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)',
			padding: '2px 3px',
			borderRadius: 2,
			lineHeight: 1.7
		},
		'table.simple tbody tr td': {
			borderColor: theme.palette.divider
		},
		'table.simple thead tr th': {
			borderColor: theme.palette.divider
		},
		'a:not([role=button])': {
			color: theme.palette.secondary.main,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		},
		'a.link, a:not([role=button])[target=_blank]': {
			background: fade(theme.palette.secondary.main, 0.2),
			color: 'inherit',
			borderBottom: `1px solid ${theme.palette.divider}`,
			textDecoration: 'none',
			'&:hover': {
				background: fade(theme.palette.secondary.main, 0.3),
				textDecoration: 'none'
			}
		},
		'[class^="border-"]': {
			borderColor: theme.palette.divider
		},
		'[class*="border-"]': {
			borderColor: theme.palette.divider
		},
		hr: {
			borderColor: theme.palette.divider
		},
		'::-webkit-scrollbar-thumb': {
			boxShadow: `inset 0 0 0 20px ${
				theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
			}`
		},
		'::-webkit-scrollbar-thumb:active': {
			boxShadow: `inset 0 0 0 20px ${
				theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
			}`
		},
		html: {
			backgroundColor: `${theme.palette.background.default}!important`,
			color: `${theme.palette.text.primary}!important`
		}
	},
	root: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary
	}
}));

function FuseLayout(props) {

	const isGuest = !props.user.data?.token;

    if(isGuest && !isExcludedRoute()){
		window.location.href = '/login'
		return
    }

	useEffect(()=>{
		if(props.user.data.token){
			axios.defaults.headers.common.Authorization = `Bearer ${props.user.data.token}`;
			token.token = props.user.data.token;
		}	
	});
	const dispatch = useDispatch();
	const settings = useSelector(({ fuse }) => fuse.settings.current);
	const defaultSettings = useSelector(({ fuse }) => fuse.settings.defaults);

	const appContext = useContext(AppContext);
	const { routes } = appContext;
	const classes = useStyles(props);
	const location = useLocation();
	const { pathname } = location;
	const matched = matchRoutes(routes, pathname)[0];
	const newSettings = useRef(null);
	const  PAGES_ACCESS_BY_USER = (useSelector(({ auth }) => auth.pagesAccessByUser.PAGES_ACCESS_BY_USER)).map((item)=>{
		return {
			...item
		}
	});
	function calculateTimeSpentOnAPage(pathname){
		const isExcludeCurrentPage = Boolean(PAGES_ACCESS_BY_USER.find((page)=>page.pathName === pathname))
		if(!PAGES_ACCESS_BY_USER.length || !isExcludeCurrentPage || pathname === '/') return
		const previousAccessPage = PAGES_ACCESS_BY_USER.find((item)=>item.startSessionTimeInSec != null);
		if(!previousAccessPage){
			//No Page is accessed before
			const currentPage = PAGES_ACCESS_BY_USER.find((item)=>item.pathName == pathname);
			currentPage.startSessionTimeInSec = new Date()
			dispatch(setAllPages(PAGES_ACCESS_BY_USER))
		}
		else{
			previousAccessPage.totalSessionTimeInSec +=  new Date() - new Date(previousAccessPage.startSessionTimeInSec);
			previousAccessPage.startSessionTimeInSec = null
			const currentPage = PAGES_ACCESS_BY_USER.find((item)=>item.pathName == pathname);
			currentPage.startSessionTimeInSec = new Date()
			dispatch(setAllPages(PAGES_ACCESS_BY_USER))
		}
	}

	useEffect(()=>{
		calculateTimeSpentOnAPage(pathname);
	},[pathname]);

	const shouldAwaitRender = useCallback(() => {
		let _newSettings;
		/**
		 * On Path changed
		 */
		// if (prevPathname !== pathname) {
		if (matched && matched.route.settings) {
			/**
			 * if matched route has settings
			 */

			const routeSettings = matched.route.settings;

			_newSettings = generateSettings(defaultSettings, routeSettings);
		} else if (!_.isEqual(newSettings.current, defaultSettings)) {
			/**
			 * Reset to default settings on the new path
			 */
			_newSettings = _.merge({}, defaultSettings);
		} else {
			_newSettings = newSettings.current;
		}

		if (!_.isEqual(newSettings.current, _newSettings)) {
			newSettings.current = _newSettings;
		}
	}, [defaultSettings, matched]);

	shouldAwaitRender();

	useDeepCompareEffect(() => {
		if (!_.isEqual(newSettings.current, settings)) {
			dispatch(setSettings(newSettings.current));
		}
	}, [dispatch, newSettings.current, settings]);

	// console.warn('::FuseLayout:: rendered');

	const Layout = useMemo(() => FuseLayouts[settings.layout.style], [settings.layout.style]);

	return _.isEqual(newSettings.current, settings) ? <Layout classes={{ root: classes.root }} {...props} /> : null;
}

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		loggingIn: state.auth.login.loggingIn
	}
}

export default memo(connect(mapStateToProps,null)(FuseLayout));
