import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import GeneralModal from "@fuse/components/Modal/GeneralModal.js"
import Tooltip from '@material-ui/core/Tooltip';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { isArray } from "lodash";
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';


const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
	const table_name = "MODEL_ATTRIBUTES";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const gridRef = useRef();
	const [ windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [ modalData, setModalData ] = useState(null)
	const [ displayButton, setDisplayButton ] = useState(false);
	const skipHeader = [
		"id", "platform_customer_id", "user_companies_id",
		"created_at", "updated_at","original_info"
	]
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);

	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	function editCloseForm(data){
		if(data){
			setEditDone(true)
			setEditButtonClick(false)
		}
	}
	
	function handelDelete(data){
		setModalData(data)
	}

	function handleClose(){
		setModalData(null)
	}

	function handleConfirm(){
		axios.delete(`/model-custom-attributes/delete/${modalData.id}`).then(response => {
			handleClose()
			props.refresh()
			toast.success(response.data.message)
		}).catch(err => {
			console.log('err',err.response);
			toast.error(err.response.data.message ?? 'Something went wrong!')
		})
	}

	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) =>{
		props.endUsersAccounts.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '') list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]))
		}else{
			getReport(params);
		}
	}

	// const onGridSizeChanged = ()=>{
	// 	setWindowHeight(window.innerHeight - 290);
	// }
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)

		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "model attribute";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
		axios.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then(results=>{
			const { data } = results.data;
			if(data !== null){
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data?.filter);
				params.columnApi.setColumnState(order);
				params.api.setFilterModel(filter);
			}
		})
		.catch(err=>{
			console.log(err);
		})
  }

	function saveView(rows, filter) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	function onColumnRowGroupChanged(event) {
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		let attribute_name = e.data.attribute_name

		const payload = {
			attribute_name: e.data.attribute_name,
		}
		if(!payload.attribute_name){
			toast.error('Attribute Name is required')
			props.refresh()
			return
		}
		axios.post(`/model-custom-attributes/update/${e.data.id}`, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				toast.success('Attribute updated Successfully.')
				props.refresh()
			}		  
		}).catch(err => {
			console.log('212=>',err);
			toast.error('Something went wrong!')
		})
	}

	useEffect(()=>{
		onGridReady()
	},[props.endUsersAccounts])

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0 ;
		let startRowIndex = 0 ;
		let endRowIndex =  0 ;
		
		const rows = params.api.getCellRanges();

		if(rows.length != 0){
			columnCount = params.api.getCellRanges()[0].columns.length ;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex ;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex ;			
		}

		if(columnCount > 1 || startRowIndex != endRowIndex  ){
			setDisplayButton(true);
		}else{
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		console.log(header)
		if(header === "attribute_name") {
			return 'Model Attribute';
		} else {
			return header.split('_').map(word => {
				return word.charAt(0).toUpperCase() + word.substring(1)
			}).join(' ')
		}
		
	}

	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		  );
		// if(Object.keys(filterModel).length !== 0){
		// 	setTimeout(()=>{
		// 		gridRef.current.api.setFilterModel(filterModel);
		// 	},0)
		// }
	},[query_data])

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//logs
	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				attribute_name: item?.model_custom_attributes?.attribute_name || item.attribute_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item.user_company.company_name
			}
		})
	}

	function getLogs(){
		axios.get('/model-custom-attributes/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	return (			
		<>
			<AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'custom_attributes'} logs={logs} displayTableName = {'Model Attributes'}
				 onClick={handleChildClick}
			/>
			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					suppressRowClickSelection={true}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData={props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged = { onSortChanged }
					groupHeaderHeight ={1}
					floatingFiltersHeight = {40}
					frameworkComponents={frameworkComponents}

					// status bar enabled on range selection
					enableRangeSelection={ true }
					statusBar={{
						statusPanels: [
						{ 
							statusPanel: 'agAggregationComponent' },
						],
					}}

					// to check the the no. of rows selected during range selection
					onRangeSelectionChanged = { onRangeSelectionChanged }						

					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					cacheQuickFilter={true}
					
					pagination={true}
					defaultColDef={{
						resizable: true
					}}
				>			
					{Object.keys(props.endUsersAccounts[0]).map(header => {
						if(!skipHeader.includes(header)){
							return(
								<AgGridColumn 
									key = {header} 
									field = {header}
									suppressSizeToFit = {true}
									headerName = {generateHeaderLabel(header)}  
									sortable = {true} 
									filter = "text"
									flex={1}
									minWidth={200}
									headerComponentFramework={CustomFilter}
									
								></AgGridColumn>
							)
						}							
					})}
				</AgGridReact>
			</div>}
			{
				modalData && (
					<GeneralModal
						open={modalData}
						title={'Delete Attribute'}
						text={<h4>Are you sure to delete this Attribute named as <b>{modalData.attribute_name}</b>?</h4>}
						handleClose={handleClose}
						handleConfirm={handleConfirm}
					/>
				)
			}
		</>
	);
}

export default ListView
