
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

const  DeleteConfirmModal=(props) =>{
    
    return(
        <Dialog
        className="cstm_dialog_modal small text-center"
        open={props.openAlerModal}
        // onClose={props.handleCloseAlertModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={props.handleCloseAlertModal}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder red">
              <i className="ti ti-exclamation-mark" />
            </div>
            {(props.module != 'Service Ticket Details') && <h2 className="heading">Are you sure to delete the {props.module}?</h2>}
            {(props.module == 'Service Ticket Details') && <h2 className="heading">Are you sure to close the Service Ticket?</h2>}
            {(props.module != 'Service Ticket Details') && <p className="sub_ontent">You will loss data</p>}
            {(props.module == 'Service Ticket Details') && <p className="sub_ontent">You will not be able to update later</p>}
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={props.handleCloseAlertModal} className="secondary_btn">No, go back!</Button>
          <Button onClick={props.handleAlertModal} className="primary_btn">
            Yes, Proceed!
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default  DeleteConfirmModal