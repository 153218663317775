import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { setAssetQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { UploadIcon } from '@fuse/icons';
import { openCsvDialog } from '../store/csvDialogReducer';
import { openImageDialog } from '../store/imageDialogReducer';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { useDebounce } from '@fuse/hooks'

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {

	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	let suggRef = useRef(null);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)

	const { hasPermission } = usePermission()
	const viewAssetPermission = hasPermission('view-assets',true)
	const createAssetPermission = hasPermission('add-assets',true)
	const updateAssetPermission = hasPermission('update-assets',true)
	const deleteAssetPermission = hasPermission('delete-assets',true)
	const addReportPermission = hasPermission('add-report')

	const handleDebouncedInputValue = useDebounce(e => {
		dispatch(setAssetQuickFilter(e.target.value))
	}, 250);

	return (
		<div className="flex flex-1 w-full items-center justify-between">
		</div>
	);
}

export default DynamicFieldsHeader;
