import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
	name: 'notifications',
	initialState: {
        total: 0,
        unread: 0,
        notifications: [],
	},
	reducers: {
		setNotifications: (state, action) => {
            state.notifications = action.payload.notifications;
            state.total = action.payload.total;
            state.unread = action.payload.unread;
		},
	}
});

export const { setNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
