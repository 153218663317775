import axios from "@fuse/utils/axios";
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { closeReportViewerDialog } from '../store/reportViewDialogReducer';
import useToast from '@fuse/hooks/useToast';
import useRouter from '@fuse/hooks/useRouter';


export default function SaveReportDialog(props) {
    const dispatch = useDispatch();
    const [ reportName,setReportName ] = useState('');
    const [initialState,setInitialState] = useState(false);
    const [isAgGridEventApplied,setIsAgGridEventApplied] = useState(false);
    const [isColumnPinned,setIsColumnPinned] = useState(false);
    // const [isColumnGrouped,setIsColumnPinned] = useState(false);
    const dialogState = useSelector(({reportApp}) => reportApp.reportViewDialogReducer);
    const toast = useToast(dispatch);
    const router = useRouter();
    let filterParamsState = {}; 
    if(props.tableName == 'manufacturer') filterParamsState = useSelector(({manufacturersApp}) => manufacturersApp.filterParamsReducer);
    if(props.tableName == 'model') filterParamsState = useSelector(({modelApp}) => modelApp.filterParamsReducer);
    if(props.tableName == 'asset') filterParamsState = useSelector(({assetsApp}) => assetsApp.filterParamsReducer);
    if(props.tableName == 'accessory') filterParamsState = useSelector(({accessoryApp}) => accessoryApp.filterParamsReducer);
    if(props.tableName == 'model attribute') filterParamsState = useSelector(({modelCustomAttributeApp}) => modelCustomAttributeApp.filterParamsReducer);
    if(props.tableName == 'asset status') filterParamsState = useSelector(({assetStatusApp}) => assetStatusApp.filterParamsReducer);
    if(props.tableName == 'accessory category') filterParamsState = useSelector(({accessoryCategoryApp}) => accessoryCategoryApp.filterParamsReducer);
    if(props.tableName == 'contract') filterParamsState = useSelector(({contractsApp}) => contractsApp.filterParamsReducer);
    if(props.tableName == 'payment term') filterParamsState = useSelector(({paymentTermApp}) => paymentTermApp.filterParamsReducer);
    if(props.tableName == 'contract status') filterParamsState = useSelector(({contractStatusApp}) => contractStatusApp.filterParamsReducer);
    if(props.tableName == 'contract type') filterParamsState = useSelector(({contractTypeApp}) => contractTypeApp.filterParamsReducer);
    if(props.tableName == 'service provider') filterParamsState = useSelector(({serviceProviderApp}) => serviceProviderApp.filterParamsReducer);
    if(props.tableName == 'service level agreement') filterParamsState = useSelector(({slaApp}) => slaApp.filterParamsReducer);
    if(props.tableName == 'subscription') filterParamsState = useSelector(({subscriptionApp}) => subscriptionApp.filterParamsReducer);
    if(props.tableName == 'subscription category') filterParamsState = useSelector(({subCategoryApp}) => subCategoryApp.filterParamsReducer);
    if(props.tableName == 'subscription status') filterParamsState = useSelector(({subStatusApp}) => subStatusApp.filterParamsReducer);
    if(props.tableName == 'subscription provider') filterParamsState = useSelector(({cloudProviderApp}) => cloudProviderApp.filterParamsReducer);
    if(props.tableName == 'service request') filterParamsState = useSelector(({ServiceRequestsApp}) => ServiceRequestsApp.filterParamsReducer);
    if(props.tableName == 'service request category') filterParamsState = useSelector(({ServiceRequestCategoryApp}) => ServiceRequestCategoryApp.filterParamsReducer);
    if(props.tableName == 'service request status') filterParamsState = useSelector(({ServiceRequestStatusApp}) => ServiceRequestStatusApp.filterParamsReducer);
    if(props.tableName == 'service request source') filterParamsState = useSelector(({ServiceRequestSourceApp}) => ServiceRequestSourceApp.filterParamsReducer);
    if(props.tableName == 'location') filterParamsState = useSelector(({shippingsApp}) => shippingsApp.filterParamsReducer);
    if(props.tableName == 'location type') filterParamsState = useSelector(({LocationTypeApp}) => LocationTypeApp.filterParamsReducer);
    if(props.tableName == 'department') filterParamsState = useSelector(({departmentApp}) => departmentApp.filterParamsReducer);
    if(props.tableName == 'user') filterParamsState = useSelector(({usersApp}) => usersApp.filterParamsReducer);
    
    const handleCloseDialog = () =>{
        setInitialState(false);
        setReportName('');
        dispatch(closeReportViewerDialog())
    }

    const handleInputChange = (params) =>{
        if(initialState == false){
            setInitialState(true);
        }
        setReportName(params.target.value);
    }

    useEffect(()=>{
        // if(filterParamsState?.rowGroupModel.length){
            const aggridEvent = filterParamsState?.rowGroupModel.filter(item=>(item.hide == true || item.sort != null || item.colId == 'ag-Grid-AutoColumn'))
            const pinnedColumn = filterParamsState?.rowGroupModel.filter(item=>(item.pinned != null))
            // const sortedField = filterParamsState?.rowGroupModel.filter(item=>item.sort != null)
            if(aggridEvent?.length){
                setIsAgGridEventApplied(true)
                
            }else{
                setIsAgGridEventApplied(false)  
            }
            if(pinnedColumn?.length > 1){
                setIsColumnPinned(true)
            }else{
                setIsColumnPinned(false)
            }
        // }
    },[filterParamsState?.rowGroupModel])

    useEffect(()=>{
        // if(filterParamsState?.rowGroupModel.length){
            console.log(filterParamsState?.rowGroupModel)
            const aggridEvent = filterParamsState?.rowGroupModel.filter(item=>(item.hide == true || item.sort != null || item.colId == 'ag-Grid-AutoColumn'))
            const pinnedColumn = filterParamsState?.rowGroupModel.filter(item=>(item.pinned != null))
            // const sortedField = filterParamsState?.rowGroupModel.filter(item=>item.sort != null)
            if(aggridEvent?.length){
                setIsAgGridEventApplied(true)
                
            }else{
                setIsAgGridEventApplied(false)  
            }
            if(pinnedColumn?.length > 1){
                setIsColumnPinned(true)
            }else{
                setIsColumnPinned(false)
            }
        // }
    },[props])

    const handleSaveReport = () =>{
        
        const report_name = reportName.trim();
        const table_name = props.tableName;
        const filter =  JSON.stringify(filterParamsState?.filter);
        const row_model = JSON.stringify(filterParamsState?.rowGroupModel);

        const payload = {
            report_name,table_name,filter,row_model
        }

        axios.post('/reports/add-report',payload)
        .then(results=>{
            console.log(results);
            toast.success("Report created successfully")
        })
        .catch(err=>{
            console.log(err);
            toast.error("report save unsuccessfull")
        })
        .finally(()=>{
            setInitialState(false);
            setReportName('');
            dispatch(closeReportViewerDialog())
        })

    }

    return (
        <>
            <div>
                <Dialog open = { dialogState.open } fullWidth ={ true } maxWidth = 'sm'>
                    <DialogTitle>Create Report</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Add the name of the report to save the current filter state.
                        </DialogContentText>
                        <TextField
                            value = { reportName }
                            error = { (reportName.trim().length == 0 && initialState == true) ? true : false }
                            autoFocus
                            id="report_name_contract"
                            label="Report Name"
                            fullWidth
                            variant="standard"
                            onChange = { handleInputChange }
                            helperText={ (reportName.trim().length == 0 && initialState == true) ? "report name cannot be empty or all spaces" : "" }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ handleCloseDialog }>Cancel</Button>
                        <Button 
                        onClick={ handleSaveReport } disabled = { (reportName.trim().length !==0 && (Object.keys(filterParamsState?.filter)?.length !== 0 || isAgGridEventApplied || isColumnPinned) && (router.query.report_name == undefined || dialogState.event) ) ? false : true } >
                            Add</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
