import EndUsersAccountsTable from './tables';
import useToast from '@fuse/hooks/useToast'
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import AddServiceRequestFormDialog from './addServiceRequestDialog';
import { usePermission } from '@fuse/hooks';
import { setCustomFilterData, setfilterQuery,setSerialNumberForServiceRequest } from '../store/filterParamsReducer';
import { openFormViewerDialog,closeFormViewerDialog } from '../store/formViewDialogReducer';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { Tab, Tabs } from '@material-ui/core';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import { stripHtml } from 'helper/common';
import { generateProperLocationString } from '@fuse/utils/general';


const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

function CardedFullWidth2TabbedSample(props) {
	const router = useRouter();
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [page, setPage] = useState(0);
	const { hasPermission } = usePermission();
	const viewServiceRequestPermission = hasPermission('view-service_request')
	const createServiceRequestPermission = hasPermission('add-service_request')
	const updateServiceRequestPermission = hasPermission('update-service_request')
	const deleteServiceRequestPermission = hasPermission('delete-service_request')
	const [loading, setLoading] = useState(false);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const NEW_TICKET_CREATED_FROM_USER = 'new_service_request_created_from_user'
	const NEW_MESSAGE_FROM_USER = 'service_req_chat_new_message_from_user'
	const user = useCurrentUser();
	const [selectedTab, setSelectedTab] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [currentSearch, setCurrentSearch] = useState('_');
	const [serviceRequest, setServiceRequest] = useState([]);
	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
	const serial_number = useSelector(({ServiceRequestsApp})=>ServiceRequestsApp.filterParamsReducer.serial_number)
	const custom_filter_data = useSelector(({ServiceRequestsApp})=>ServiceRequestsApp.filterParamsReducer.custom_filter_data)
	let [totalRecords,setTotalRecords] = useState(0);
	const [ redirectedWith, setRedirectedWith ] = useState(null);


	function getServiceRequest(){
		let url = '/service/show-all-service-request' ;
		// if(window.location.search === '' || router.query.report_name) {
		  
		//   url = `/service/show-all-service-request`
		// } else {
		//   url = `/service/show-all-service-request${window.location.search}`
		// }
		axios.get(url).then((res) => {
			const { response } = res.data.data;
			if(custom_filter_data){
				setRedirectedWith({Status:custom_filter_data})
				dispatch(setCustomFilterData(null))
			}
			setServiceRequest(formatData(response))
			setTotalRecords(response?.length)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setLoading(false)
		});
	}

	function formatData(data){
		const serviceReqWithUnreadMessage = [], serviceRequests = []
		for( let item of data){
			const formattedObject = {
				id: item?.id,
				ticket_number: item?.ticket_id,
				asset_id: item.asset_id,
				serial_number: item?.asset?.serial_number ?? 'No Serial Number',
				problem_summary: item?.title,
				priority: item?.service_request_priority?.name,
				model_number: item?.asset?.asset_model?.model_no,
				// model_name: item?.asset?.asset_model?.model_name,
				contract_Number: item?.asset?.contract_header?.entity_external_platform_id,
				status: item?.service_request_status?.status_name,
				status_details: {
					id: item?.service_request_status?.id,
					status_name: item?.service_request_status?.status_name,
					status_color: item?.service_request_status?.status_color
				},
				priority_details: {
					id: item?.service_request_status?.id,
					priority_name: item?.service_request_priority?.name,
					priority_color: item?.service_request_priority?.priority_color
				},
				// category_name: item?.service_request_category?.category_name,
				// source_name: item?.service_request_source?.source_name,
				agent_name: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : '-',
				agent_details: {
					id: item.agent?.id,
					first_name: item.agent?.first_name || '',
					last_name: item.agent?.last_name || '',
				},
				user_name: item.requestor ? `${item.requestor.first_name} ${item.requestor.last_name}` : '-',
				user_details: {
					id: item.asset?.assigned_user?.id,
					first_name: item.asset?.assigned_user?.first_name || '',
					last_name: item.asset?.assigned_user?.last_name || '',
				},
				client_name: item.user_company.company_name,
				asset_name: item.asset?.asset_name ?? "No Asset Name",
				location: item.asset ? generateProperLocationString({
					location_name: item.asset?.shipping?.location_name ?? '',
					address1: item.asset?.shipping?.address1 ?? '',
					address2: item.asset?.shipping?.address2 ?? '',
					city: item.asset?.shipping?.city ?? '',
					state: item.asset?.shipping?.state ?? '',
					country: item.asset?.shipping?.country ?? '',
					zip:  item.asset?.shipping?.zip ?? ''
				}) : 'No Location Name',
				//location: item.asset?.shipping ? item.asset?.shipping?.location_name : '-',
				shipping_location_details: {
					id: item.asset?.shipping?.id,
					location_name: item.asset?.shipping?.location_name,
					address1: item.asset?.shipping?.address1 || '',
					city: item?.asset?.shipping?.city || '',
					state: item?.asset?.shipping?.state || '',
					country: item?.asset?.shipping?.country || '',
				},
				detailed_description_of_the_issue: stripHtml(item?.description),
				created_date: item.created_at ? dateReformatTo_mmm_dd_yyyy(item.created_at) : '-',
				updated_date: item.updated_at ? dateReformatTo_mmm_dd_yyyy(new Date(item.updated_at).toISOString()) : '-',
				has_unread_message: item.new_message_to_agent
			}
			if(formattedObject.has_unread_message){
				serviceReqWithUnreadMessage.push(formattedObject)
			}else{
				serviceRequests.push(formattedObject)
			}
		}
		return [...serviceReqWithUnreadMessage, ...serviceRequests]
	}

	function refreshList(){
		getAccessory();
	}
	
	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};

	useEffect(()=>{
		setLoading(true)
		getServiceRequest()

		if(serial_number){
			dispatch(openFormViewerDialog())
		}

		//call on component unmount
		return(()=>{
			dispatch(setSerialNumberForServiceRequest(null))
			dispatch(closeFormViewerDialog())
		})
	},[]);
	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			});

			broadcastChannel.bind(NEW_TICKET_CREATED_FROM_USER, data => {
				getServiceRequest();
			});

			broadcastChannel.bind(NEW_MESSAGE_FROM_USER, data => {
				getServiceRequest();
			});
			return () => {
				broadcastChannel.unbind(new_incident_event)
				broadcastChannel.unbind(NEW_TICKET_CREATED_FROM_USER)
				broadcastChannel.unbind(NEW_MESSAGE_FROM_USER)
			}
		}
	},[broadcastChannel])

	return (
		<div>
			<SaveReportDialog tableName={"service request"}/>
		
			{/* <FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg: classes.topBg,
					contentWrapper : classes.contentWrapper
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		currentSearch={currentSearch}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Service TIckets" />
				// 			<Tab key="end_user_tab_2" className="h-64" label="Activities" />
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							<EndUsersAccountsTable 
								fetchServiceReq={getServiceRequest}
								serviceRequests={serviceRequest}
								totalRecords={totalRecords}
								page={page}
								setPage={setPage}
								rowsPerPage={rowsPerPage}
								setRowsPerPage={setRowsPerPage}
								loading={loading}
								tableName='serviceRequest'
								customFilterData={redirectedWith}
								viewServiceRequestPermission = {viewServiceRequestPermission}
								createServiceRequestPermission = {createServiceRequestPermission}
								updateServiceRequestPermission = {updateServiceRequestPermission}
								deleteServiceRequestPermission = {deleteServiceRequestPermission}
							/>
							{openState.open && <AddServiceRequestFormDialog getServiceRequest = {getServiceRequest}/>}
						</div>
					</>
				}
				innesrScroll
			/> */}
			<div className='px-32'>
				<div className={selectedTab !== 0 ? 'hidden' : ''}>
					<EndUsersAccountsTable 
						fetchServiceReq={getServiceRequest}
						serviceRequests={serviceRequest}
						totalRecords={totalRecords}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						loading={loading}
						tableName='serviceRequest'
						customFilterData={redirectedWith}
						viewServiceRequestPermission = {viewServiceRequestPermission}
						createServiceRequestPermission = {createServiceRequestPermission}
						updateServiceRequestPermission = {updateServiceRequestPermission}
						deleteServiceRequestPermission = {deleteServiceRequestPermission}
					/>
					{openState.open && <AddServiceRequestFormDialog getServiceRequest = {getServiceRequest}/>}
				</div>
			</div>
		</div>
	)
}

export default withReducer('ServiceRequestsApp', reducer)(CardedFullWidth2TabbedSample);
