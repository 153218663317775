import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState,useEffect, useRef } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { isArray } from "lodash";
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "paymentTerm";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	// const rowGroupPanelShow = 'always';
	const skipHeader = [ 'id','system_generated' ]
	const gridRef = useRef();
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [paymentTermDetails, setPaymentTermDetails] = useState({})
	const [tabValue, setTabValue] = useState(0)
	const [logs,setLogs] = useState([]);
	const [groupByArr, setGroupByArr] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );

	function setHeaderName(header){
		if(header == 'model_no'){
			return 'Model Number'
		}
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};

	const handleChildClick = (value) => {
		setTabValue(value)
	}

	const handleConfirmDelete = (data) => {
		setOpenDeleteDialog(true)
		setPaymentTermDetails(data)
	}

	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]))
		}else{
			getReport(params);
		}
	}

	const onGridSizeChanged = ()=>{
		// setWindowHeight(window.innerHeight - 290);
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)

		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
	},[query_data])

	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "payment term";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
					const filter = JSON.parse(data?.filter);
          params?.columnApi.applyColumnState({ state: order });
					params.api.setFilterModel(filter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

	function saveView(rows, filter) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}	

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		const payload = {
			paymentTerm:e.data.payment_term_name,
			id:e.data.id,
		}
		if(!payload.paymentTerm){
			toast.error('Payment Term is required')
			props.refresh()
			return
		}
		axios.put('/payment-term/update', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				// onSuccess()
			  toast.success('Payment Term updated successfully')
			  props.refresh()
				// props.fetchAllHardwareAssets()
			}
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})

	}

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				payment_term_name: item?.payment_term?.display_name || item.payment_term_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item?.user_company?.company_name,
			}
		})
	}

	function getLogs(){
		axios.get('/payment-term/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	const handelDelete = () => {
		console.log('218=>','response coming')
		axios.delete(`/payment-term/delete/${paymentTermDetails?.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message)
			}else{
				toast.success(res.data.message)
				handleClose()
				props.refresh()
			}
		}).catch((err)=>{
			console.log('err',err.response);
			toast.error(err.response.data.message);
		})
	}

	useEffect(()=>{
		onGridReady()
	},[props.endUsersAccounts])

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	return (
		<>
			<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= {'payment_term'}
				displayTableName={'Payment Terms'}
				logs={logs}
				onClick={handleChildClick}
			/>
			{(tabValue == 0) &&
				<div className="ag-theme-alpine" style={{width: "100%",height:windowHeight+105,fontSize:'11px'}}>
					<AgGridReact
						ref={gridRef}
						rowData={props.endUsersAccounts}
						onGridReady = { onGridReady }
						animateRows = { true }
						rowHeight = {30}
						onGridSizeChanged = { onGridSizeChanged }
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							resizable: true
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}
						
						// get filter model
						onFilterChanged = { onFilterChanged }		
						onColumnMoved = { onColumnMoved }	
						onColumnVisible = { onColumnVisible }
						onColumnPinned = { onColumnPinned }
						onSortChanged={onSortChanged}

						groupHeaderHeight ={1}
						// headerHeight = {15}
						floatingFiltersHeight = {40}

						//row grouping
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						rowGroupPanelShow={ rowGroupPanelShow }
						onColumnRowGroupChanged = { onColumnRowGroupChanged }
						frameworkComponents = {frameworkComponents}

						pagination={true}
					>
						{/* <AgGridColumn
							lockPosition={true}
							rowSelection={'single'}
							width={100}
							minWidth={100}
							maxWidth={100}
							suppressSizeToFit={true}
							headerName="Actions"
							pinned= 'right' 
							lockPinned={true}
							className="action_field"
							cellRendererFramework={({data}) =>{
								if(data){
									return (
										<>
											{props.updatePaymentTermPermission && !data.system_generated && <Tooltip id="edit" title="Edit" placement="top"
										><Icon onClick={() => handelEdit(data) }
										style={{
											color: '#1d2939',
											paddingBottom: '5px',
											paddingRight:'30px',
											fontSize:'15px',
											cursor:'pointer'
										}}
										>edit</Icon></Tooltip>}
										{props.deletePaymentTermPermission && !data.system_generated &&<Tooltip id="edit" title="Delete" placement="top" ><Icon
										onClick={() => handleConfirmDelete(data) }
										style={{
											color: '#1d2939',
											paddingBottom: '3px',
											paddingRight:'30px',
											fontSize:'15px',
											cursor:'pointer'
										}}>delete</Icon></Tooltip>}
										</>
									)
								}else{
									return (<></>)
								}
							}}
						>
						</AgGridColumn> */}
						{
							Object.keys(props.endUsersAccounts[0]).map((header)=>{
								if(!skipHeader.includes(header)){
									return(
										<AgGridColumn 
											field = {header}
											key = {header} 
											//editable = {props.updatePaymentTermPermission}
											onCellValueChanged = {onCellValueChanged}
											headerName = { setHeaderName(header) } 
											sortable = { true }
											filter = "text"
											flex = {1} 
											minWidth={200}
											// floatingFilter = { true }
											headerComponentFramework={CustomFilter}
											
										></AgGridColumn>
									)
								}
							})
						}
					</AgGridReact>
				</div>
			}
			<GeneralModal
				open={openDeleteDialog}
				title={'Delete Payment Term'}
				text={<h4>Are you sure to delete this Payment Term named as <b>{paymentTermDetails?.payment_term_name}</b>?</h4>}
				handleConfirm={handelDelete}
				handleClose={handleClose}
			/>
		</>
	);
}

export default ListView
