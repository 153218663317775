import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles,
    Modal, TextField,Typography,Divider,InputAdornment,Paper } from '@material-ui/core';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function ServiceProviderDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [formattedNumber, setFormattedNumber] = useState('')

    const schema = yup.object({
        // phone_number: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		// }),
    })

    const defaultValues = {
		url: '',
        service_provider: '',
		phone_number: '',
		address_1: '',
		city: '',
		state: '',
		zip: '',
		country: {},
    }

    const { 
        control, 
        formState:{errors}, 
        handleSubmit, 
        reset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

    function manageData(formData){
        // console.log('manageManufactureData: ',formData);
        if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
        if(formData.url.indexOf('.') == -1){
            toast.error('Please enter a valid URL')
            return
        }
		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			address1: formData.address_1,
			serviceProvider: formData.service_provider,
			phone_number: formattedNumber || '',
			city: formData.city,
			state: formData.state,
			country: formData.country?.name,
			zip: formData.zip,
			url: `www.${formData.url}`
		}
		
		axios.post('/service-provider/add', payload).then(res => {
			if(res.status === 201){
				toast.error(res.data.message)
			}else{
				close(res.data.data)
				toast.success('Service Provider added successfully')
			}
            setFormattedNumber('')
			
		}).catch(err => {
			console.log('504=>',err)
			// toast.error('Something went wrong!')
		})

    }

    function fromErrors(error){
        console.log('fromErrors: ',error);
    }

    function close(new_service_provider=null){
        reset(defaultValues)
        props.handleCloseDialog(new_service_provider)
    }

    return (
        <Modal
            open={props.open}
            // onClose={()=>close()}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Create Service Provider</h1>
                    <IconButton onClick={()=>close()}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={handleSubmit(manageData, fromErrors)}>

                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>

                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='service_provider' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='Service Provider Name' 
                                        autoFocus
                                        fullWidth
                                        required
                                        style={{background: 'white'}}
                                    />
                                )}
                            />
                        </div>

                        <div style={{marginBottom: '10px', width: '100%' }}>
                            <Controller
                                name='url' 
                                control={control}
                                render={( { field} ) => (
                                <>
                                    <TextField 
                                        {...field}
                                        InputProps={{
                                            startAdornment: 
                                            <InputAdornment style={{color: "grey",marginRight:"0"}} disableTypography position="start">
                                                www.</InputAdornment>,
                                        }}
                                        variant='outlined'
                                        label='URL' 
                                        autoFocus
                                        fullWidth
                                        required
                                        style={{background: 'white'}}                                    
                                    />
                                </>
                                ) }
                            />
                        </div>
                        
                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='phone_number' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        value={formattedNumber}
                                        // style={{background: 'white'}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9/-]+/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e)=>{
											changeNumberFormat(e)
										}}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                            }
                                        }}
                                        onPaste={(e)=>{e.preventDefault()}}
                                        variant='outlined'
                                        label='Phone Number' 
                                        autoFocus
                                        fullWidth
                                        // error={!!errors.phone_number}
                                        // helperText={errors?.phone_number?.message}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                )}
                            />
                        </div>

                        <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='address_1' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='Address Line 1' 
                                        autoFocus
                                        fullWidth 
                                        style={{background: 'white'}}
                                    />
                                ) }
                            />
                        </div> 
                        
                      <div className='flex justify-between'>
                          <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='city' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='City' 
                                        autoFocus
                                        fullWidth
                                        style={{background: 'white'}}
                                    />
                                )}
                            />
                        </div> 

                        <div style={{marginBottom: '10px', width: '100%' }}>
                            <Controller
                                name='state' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='State/Province' 
                                        autoFocus
                                        style={{background: 'white'}}
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                      </div>

                     <div className='flex justify-between'>
                           <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                            <Controller
                                name='zip' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        variant='outlined'
                                        label='Zip' 
                                        style={{background: 'white'}}
                                        autoFocus
                                        fullWidth
                                    />
                                )}
                            />
                        </div>  
                        
                        <div style={{marginBottom: '10px',  width: '100%' }}>
                            <Controller
                                name="country"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={countries}
                                            // disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ marginBottom:'20px' }}>{children}</Paper>
                                            )}
                                            getOptionLabel={option => option.name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            style={{background: 'white'}}
                                                            {...params}
                                                            label="Country"
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                     </div>
                    </div>


                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit' 
                        >
                            Add
                        </Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>close()}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default ServiceProviderDialog
