import React from 'react'
import Grid from '@material-ui/core/Grid';
// import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import Tooltip from '@material-ui/core/Tooltip';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { formatPrice } from '@fuse/utils/currencyFormat';


const InformationTab = () => {
    const [subDetails, setSubDetails] = useState(null);
    const { sub_id: sub_id } = useSelector((state) => state.subscriptionApp.formViewDialog)


    async function getSubDetails() {
        axios.get(`subscription/subscription-details/${sub_id}`)
            .then(results => {
                const { available_seats, subDetails } = results.data.data
                setSubDetails(subDetails)
            })
            .catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        getSubDetails()
    }, [])

    return (
        <div style={{ height: 290, overflow: "auto" }}>
            <Grid container >
                <Grid item lg={6}>

                    <Typography style={{ display: "flex", marginBottom: "10px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 20"><path fill="currentColor" d="M4.5 7H4v1h.5V7Zm6 1h.5V7h-.5v1Zm-2 2H8v1h.5v-1Zm2 1h.5v-1h-.5v1Zm-6-7H4v1h.5V4Zm2 1H7V4h-.5v1Zm4-4.5l.354-.354L10.707 0H10.5v.5Zm3 3h.5v-.207l-.146-.147l-.354.354ZM4.5 8h6V7h-6v1Zm4 3h2v-1h-2v1Zm-4-6h2V4h-2v1Zm8 9h-10v1h10v-1ZM2 13.5v-12H1v12h1ZM2.5 1h8V0h-8v1ZM13 3.5v10h1v-10h-1ZM10.146.854l3 3l.708-.708l-3-3l-.708.708ZM2.5 14a.5.5 0 0 1-.5-.5H1A1.5 1.5 0 0 0 2.5 15v-1Zm10 1a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM2 1.5a.5.5 0 0 1 .5-.5V0A1.5 1.5 0 0 0 1 1.5h1Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F" }}>Estimated Cost Per User : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{formatPrice(subDetails?.subscription_billing?.amount / subDetails?.no_of_seats)}</span>
                    </Typography>
                    <Typography style={{ display: "flex", marginBottom: "15px", alignItems: 'center' }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width='18' height='18' className='text-gray' viewBox="0 0 24 24"><path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V19H19V5H5ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", paddingLeft: '5px' }}>Subscription Status : </span>
                        <span style={{
                            // border: '2px solid black',
                            height: '10px',
                            width: '10px',
                            marginLeft: '10px',
                            borderRadius: '50%',
                            backgroundColor: subDetails?.software_asset_status?.status_color.code
                        }}></span>
                        <span style={{ display: "inline-block", fontSize: "15px", marginLeft: "5px" }}>
                            {subDetails?.software_asset_status?.status_name}
                        </span>
                    </Typography>
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 20"><path fill="currentColor" d="M4.5 7H4v1h.5V7Zm6 1h.5V7h-.5v1Zm-2 2H8v1h.5v-1Zm2 1h.5v-1h-.5v1Zm-6-7H4v1h.5V4Zm2 1H7V4h-.5v1Zm4-4.5l.354-.354L10.707 0H10.5v.5Zm3 3h.5v-.207l-.146-.147l-.354.354ZM4.5 8h6V7h-6v1Zm4 3h2v-1h-2v1Zm-4-6h2V4h-2v1Zm8 9h-10v1h10v-1ZM2 13.5v-12H1v12h1ZM2.5 1h8V0h-8v1ZM13 3.5v10h1v-10h-1ZM10.146.854l3 3l.708-.708l-3-3l-.708.708ZM2.5 14a.5.5 0 0 1-.5-.5H1A1.5 1.5 0 0 0 2.5 15v-1Zm10 1a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM2 1.5a.5.5 0 0 1 .5-.5V0A1.5 1.5 0 0 0 1 1.5h1Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F" }}>Billing Cycle : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{`${subDetails?.subscription_billing?.billing_cycle} ${subDetails?.subscription_billing?.month}${subDetails?.subscription_billing?.billing_cycle > 1 ? 's' : ''}`}</span>
                    </Typography>



                </Grid>
                <Grid item lg={6}>
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Paid Via : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.subscription_payment.value == 'purchaseOrder' ? 'Purchase Order' : 'Credit Card'}</span>
                    </Typography>

                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M12.0049 22.0029C6.48204 22.0029 2.00488 17.5258 2.00488 12.0029C2.00488 6.48008 6.48204 2.00293 12.0049 2.00293C17.5277 2.00293 22.0049 6.48008 22.0049 12.0029C22.0049 17.5258 17.5277 22.0029 12.0049 22.0029ZM12.0049 20.0029C16.4232 20.0029 20.0049 16.4212 20.0049 12.0029C20.0049 7.58465 16.4232 4.00293 12.0049 4.00293C7.5866 4.00293 4.00488 7.58465 4.00488 12.0029C4.00488 16.4212 7.5866 20.0029 12.0049 20.0029ZM8.50488 14.0029H14.0049C14.281 14.0029 14.5049 13.7791 14.5049 13.5029C14.5049 13.2268 14.281 13.0029 14.0049 13.0029H10.0049C8.62417 13.0029 7.50488 11.8836 7.50488 10.5029C7.50488 9.12222 8.62417 8.00293 10.0049 8.00293H11.0049V6.00293H13.0049V8.00293H15.5049V10.0029H10.0049C9.72874 10.0029 9.50488 10.2268 9.50488 10.5029C9.50488 10.7791 9.72874 11.0029 10.0049 11.0029H14.0049C15.3856 11.0029 16.5049 12.1222 16.5049 13.5029C16.5049 14.8836 15.3856 16.0029 14.0049 16.0029H13.0049V18.0029H11.0049V16.0029H8.50488V14.0029Z"></path></svg>


                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Provider Name : </span>
                       
                            <Tooltip title={subDetails?.cloud_provider?.provider_name} placement="top-start">
                                <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>
                               {subDetails?.cloud_provider?.provider_name.length > 30 ? subDetails?.cloud_provider?.provider_name.slice(0, 30) + "..." : subDetails?.cloud_provider?.provider_name}
                               </span>
                            </Tooltip>
                            

                    </Typography>
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M13.0605 8.11073L14.4747 9.52494C17.2084 12.2586 17.2084 16.6908 14.4747 19.4244L14.1211 19.778C11.3875 22.5117 6.95531 22.5117 4.22164 19.778C1.48797 17.0443 1.48797 12.6122 4.22164 9.87849L5.63585 11.2927C3.68323 13.2453 3.68323 16.4112 5.63585 18.3638C7.58847 20.3164 10.7543 20.3164 12.7069 18.3638L13.0605 18.0102C15.0131 16.0576 15.0131 12.8918 13.0605 10.9392L11.6463 9.52494L13.0605 8.11073ZM19.778 14.1211L18.3638 12.7069C20.3164 10.7543 20.3164 7.58847 18.3638 5.63585C16.4112 3.68323 13.2453 3.68323 11.2927 5.63585L10.9392 5.98941C8.98653 7.94203 8.98653 11.1079 10.9392 13.0605L12.3534 14.4747L10.9392 15.8889L9.52494 14.4747C6.79127 11.741 6.79127 7.30886 9.52494 4.57519L9.87849 4.22164C12.6122 1.48797 17.0443 1.48797 19.778 4.22164C22.5117 6.95531 22.5117 11.3875 19.778 14.1211Z"></path></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>URL : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.cloud_provider?.url}</span>
                    </Typography>
                </Grid>
                <Grid item lg={6}>
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Department : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.department_id}</span>
                    </Typography>
                    
                </Grid>
                <Grid item lg={6}>
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Subscription Category : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.software_category.category_name}</span>
                    </Typography>
                    
                </Grid>
                {subDetails?.subscription_payment.value == 'purchaseOrder' && <Grid item lg={6} >
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>PO Number : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.subscription_payment.po_number}</span>
                    </Typography>
                    
                </Grid>}
                {subDetails?.subscription_payment.value == 'purchaseOrder' && <Grid item lg={6}>
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>PO Date : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{dateReformatTo_mmm_dd_yyyy(subDetails?.subscription_payment.po_date)}</span>
                    </Typography>
                    
                </Grid>}
                {subDetails?.subscription_payment.value == 'CreditCard' && <Grid item lg={6} >
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Card Holder's Name : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{subDetails?.subscription_payment.card_holder_name}</span>
                    </Typography>
                    
                </Grid>}
                {subDetails?.subscription_payment.value == 'CreditCard' && <Grid item lg={6}>
                    <Typography style={{ display: "flex", marginBottom: "15px" }} color="textSecondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#61656f" d="M15.75 14.5a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5ZM2 8.25A3.25 3.25 0 0 1 5.25 5h13.5A3.25 3.25 0 0 1 22 8.25v7.5A3.25 3.25 0 0 1 18.75 19H5.25A3.25 3.25 0 0 1 2 15.75v-7.5ZM20.5 9.5V8.25a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 8.25V9.5h17ZM3.5 11v4.75c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V11h-17Z" /></svg>
                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}> Card Number : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{(subDetails?.subscription_payment.card_number)}</span>
                    </Typography>
                    
                </Grid>}


            </Grid>

        </div>
    )
}

export default InformationTab
