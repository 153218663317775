import React from 'react'
import Icon from '@material-ui/core/Icon';
import { useEffect, useState,useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button,TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import AssetTab from './AssetTab';
import ContractTab from './ContractTab';
import axios from "@fuse/utils/axios";
import ActivitieLogs from '@fuse/components/ActivitieLogs';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import TicketHistoryTab from './TicketHistoryTab';
import useToast from '@fuse/hooks/useToast';
import { useDispatch } from 'react-redux';
import { setAssetIdForRediection } from '../../assets/store/filterParamsReducer';

const ServiceRequestDailog = ({ 
    fullWidth, maxWidth, open, setOpen, Close, classes, TabButton, 
    tabState, setTabState, TabContainer, serviceRequestId
}) => {
    const dispatch = useDispatch()
    const toast = useToast(dispatch);
    const childRef = useRef(null);

    const [openNote, setopenNote] = useState(false);
    const [fullWidth2, setFullWidth2] = useState(true);
    const [maxWidth2, setMaxWidth2] = useState('xs');
    const [serviceRequestDetails, setServiceRequestDetails] = useState(null)
    const [loading, setLoading] = useState(true)
    const [logs,setLogs] = useState([]);
    const [notes,setNotes] = useState('');
    const [noteForEditState,setNoteForEditState] = useState(null);

    function handleClickopenNote() {
        setopenNote(true)
    }

    function CloseNote() {
        setNotes('')
        setopenNote(false)
        setNoteForEditState(null)
    }

    function AddNote(){
        if(!notes.length){
            toast.error('Note is required');
            return;
        }
        axios.post(`/service/add-notes/${serviceRequestId}`,{
            notes: notes
        }).then((res)=>{
            CloseNote()
            childRef.current?.getNotes();
            toast.success('Notes added successfully');
        }).catch((err)=>{
            console.log('err',err);
            toast.error(err.response.data.message || 'Something went wrong!')
        })
    }

    function UpdateNote(){
        if(!notes.length){
            toast.error('Note is required');
            return;
        }
        console.log('type: ',typeof noteForEditState.id);
        axios.post(`/service/edit-notes/${noteForEditState.id}`,{
            notes: notes
        }).then((res)=>{
            CloseNote()
            childRef.current.getNotes();
            toast.success('Notes updated successfully');
        }).catch((err)=>{
            console.log('err',err);
            toast.error(err.response.data.message || 'Something went wrong!')
        })
    }

    const setNoteForEdit = (row) =>{
        setNotes(row.notes)
        setopenNote(true);
        setNoteForEditState(row)
    }

    useEffect(() => {
        if (!Boolean(serviceRequestId)) return
        setLoading(true)
        axios.post(`/service/request/get-details?request_id=${serviceRequestId}&archived=${true}`)
        .then((res) => {
            setServiceRequestDetails(res.data.data.result)
            dispatch(setAssetIdForRediection(res.data.data.result.asset.asset_id))
            setLoading(false)
        }).catch((err) => {
            console.log('err', err);
            setLoading(true)
        })
    }, [serviceRequestId])

    function formatLogs(data){
        let length = data.length
        return data.map((item)=>{
            const obj =  {
                id:item.id,
                action_id: `act${length}`,
                action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
                name: item.user_name,
                action: item.action_name,
                description: item.comment,
                previous_value: item.previous_data,
                current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
            }
            length --;
            return obj
        })
    }

    useEffect(() => {
        if (tabState !== 'activities' || !Boolean(serviceRequestId)) return
        setLoading(true)
        axios.get(`/service/fetch-service-request-activities/${serviceRequestId}`)
        .then((res) => {
            setLogs(formatLogs(res.data.data.activities));
        }).catch((err) => {
            console.log('err', err);
        })
    }, [serviceRequestId,tabState])

    return (
        <>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                aria-labelledby="max-width-dialog-title"
                disableEnforceFocus
            >
                <DialogTitle 
                    id="max-width-dialog-title" 
                    style={{ 
                        textAlign: "right", 
                        fontSize: "20px", 
                        cursor: "pointer",
                        padding:'0 30px 0' 
                    }}
                >
                    <Icon style={{transform:"translateY(5px)"}} onClick={()=>{
                        dispatch(setAssetIdForRediection(null))
                        Close()}}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </Icon>
                </DialogTitle>
                <DialogContent >
                    <div style={{ background: '#F5F5F5', padding: "5px" }}>
                        <Grid container spacing={1}>
                            <Grid item lg={12}>
                                <Card style={{ borderRadius: "0", padding: "14px 20px" }}>
                                    <Grid container>
                                        <Grid item lg={12}>
                                            <Typography variant='h6' className='mb-24'>Ticket Number : {serviceRequestDetails?.service_request?.ticket_number}</Typography>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Grid container >
                                                <Grid item lg={12}>
                                                    <Typography color="textSecondary" style={{ display: "flex", marginBottom: "5px" }}>
                                                        <svg className='subs-pen-icon' xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M17.8492 11.6982L17.1421 10.9911L7.24264 20.8906H3V16.6479L14.3137 5.33421L19.9706 10.9911C20.3611 11.3816 20.3611 12.0148 19.9706 12.4053L12.8995 19.4763L11.4853 18.0621L17.8492 11.6982ZM15.7279 9.57685L14.3137 8.16264L5 17.4763V18.8906H6.41421L15.7279 9.57685ZM18.5563 2.50578L21.3848 5.33421C21.7753 5.72474 21.7753 6.3579 21.3848 6.74842L19.9706 8.16264L15.7279 3.92L17.1421 2.50578C17.5327 2.11526 18.1658 2.11526 18.5563 2.50578Z"></path></svg>
                                                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Title : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{serviceRequestDetails?.service_request?.request_title}</span>
                                                    </Typography>
                                                    <Typography variant="body2" style={{ display: "flex", marginBottom: "5px" }}>
                                                        <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M8.75 4a2.75 2.75 0 0 1 2.75 2.75v10.5A2.75 2.75 0 0 1 8.75 20h-4A2.75 2.75 0 0 1 2 17.25V6.75A2.75 2.75 0 0 1 4.75 4h4Zm0 1.5h-4c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25ZM19.25 4A2.75 2.75 0 0 1 22 6.75v10.5A2.75 2.75 0 0 1 19.25 20h-4a2.75 2.75 0 0 1-2.75-2.75V6.75A2.75 2.75 0 0 1 15.25 4h4Zm0 1.5h-4c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25ZM6 9v6a.75.75 0 0 0 1.5 0V9A.75.75 0 0 0 6 9Zm11.5.75v1.5h-1.25a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h2a.75.75 0 0 0 0-1.5H17v-1.5h1.25A.75.75 0 0 0 19 12V9a.75.75 0 0 0-.75-.75h-2a.75.75 0 0 0 0 1.5h1.25Z" /></svg>
                                                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Requestor : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{serviceRequestDetails?.service_request?.requestor_name}</span>
                                                    </Typography>
                                                    <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                        <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M2.75 17h12.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 17h12.5h-12.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 13h18.5h-18.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 9h18.5h-18.5Zm0-4h18.5a.75.75 0 0 1 .102 1.493l-.102.007H2.75a.75.75 0 0 1-.102-1.493L2.75 5h18.5h-18.5Z" /></svg>
                                                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Catgory : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{serviceRequestDetails?.service_request?.request_category_name}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Grid container  >
                                                <Grid item lg={12}>
                                                    <Typography style={{ display: "flex", alignItems: "center", marginBottom: "5px" }} color="textSecondary">
                                                        <svg className='subs-pen-icon' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path d="M15.25 12H15V8.75C15 7.785 14.215 7 13.25 7H9V5h.25c.412 0 .75-.338.75-.75v-2.5A.752.752 0 0 0 9.25 1h-2.5a.752.752 0 0 0-.75.75v2.5c0 .412.338.75.75.75H7v2H2.75C1.785 7 1 7.785 1 8.75V12H.75a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.413 0 .75-.338.75-.75v-2.5a.752.752 0 0 0-.75-.75H3V9h4v3h-.25a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.412 0 .75-.338.75-.75v-2.5a.753.753 0 0 0-.75-.75H9V9h4v3h-.25a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.412 0 .75-.338.75-.75v-2.5a.753.753 0 0 0-.75-.75zM3 15H1v-2h2v2zm6 0H7v-2h2v2zM7 4V2h2v2H7zm8 11h-2v-2h2v2z" /></svg>
                                                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "7px" }}>Status : </span>
                                                        <span style={{
                                                            height:'10px',
                                                            width:'10px',
                                                            marginLeft: '10px',
                                                            borderRadius: '50%',
                                                            backgroundColor: serviceRequestDetails?.service_request?.request_status_color.code
                                                        }}></span>
                                                        <span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{serviceRequestDetails?.service_request?.request_status_name} </span>
                                                    </Typography>
                                                    <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                        <svg className='subs-pen-icon' xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M21 19H23V21H1V19H3V4C3 3.44772 3.44772 3 4 3H14C14.5523 3 15 3.44772 15 4V19H19V11H17V9H20C20.5523 9 21 9.44772 21 10V19ZM5 5V19H13V5H5ZM7 11H11V13H7V11ZM7 7H11V9H7V7Z"></path></svg>
                                                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Agent Name : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{serviceRequestDetails?.service_request?.agent_name}</span>
                                                    </Typography>
                                                    <Typography style={{ display: "flex", marginBottom: "5px" }} color="textSecondary">
                                                        <svg className='subs-pen-icon' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path d="M15.25 12H15V8.75C15 7.785 14.215 7 13.25 7H9V5h.25c.412 0 .75-.338.75-.75v-2.5A.752.752 0 0 0 9.25 1h-2.5a.752.752 0 0 0-.75.75v2.5c0 .412.338.75.75.75H7v2H2.75C1.785 7 1 7.785 1 8.75V12H.75a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.413 0 .75-.338.75-.75v-2.5a.752.752 0 0 0-.75-.75H3V9h4v3h-.25a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.412 0 .75-.338.75-.75v-2.5a.753.753 0 0 0-.75-.75H9V9h4v3h-.25a.753.753 0 0 0-.75.75v2.5c0 .412.338.75.75.75h2.5c.412 0 .75-.338.75-.75v-2.5a.753.753 0 0 0-.75-.75zM3 15H1v-2h2v2zm6 0H7v-2h2v2zM7 4V2h2v2H7zm8 11h-2v-2h2v2z" /></svg>
                                                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "7px" }}>Source : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{serviceRequestDetails?.service_request?.request_source_name}</span>
                                                    </Typography>
                                                    <Typography color="textSecondary" style={{ display: "flex", marginBottom: "5px" }}>
                                                        <svg className='subs-pen-icon' xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M17.8492 11.6982L17.1421 10.9911L7.24264 20.8906H3V16.6479L14.3137 5.33421L19.9706 10.9911C20.3611 11.3816 20.3611 12.0148 19.9706 12.4053L12.8995 19.4763L11.4853 18.0621L17.8492 11.6982ZM15.7279 9.57685L14.3137 8.16264L5 17.4763V18.8906H6.41421L15.7279 9.57685ZM18.5563 2.50578L21.3848 5.33421C21.7753 5.72474 21.7753 6.3579 21.3848 6.74842L19.9706 8.16264L15.7279 3.92L17.1421 2.50578C17.5327 2.11526 18.1658 2.11526 18.5563 2.50578Z"></path></svg>
                                                        <span style={{ fontSize: "15px", color: "#61656F", marginLeft: "5px" }}>Type : </span><span style={{ color: "#000", display: "inline-block", fontSize: "15px", marginLeft: "10px" }}>{serviceRequestDetails?.service_request?.ticket_type.charAt(0).toUpperCase() + serviceRequestDetails?.service_request?.ticket_type.slice(1)}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                        {/* <div className='flex justify-end items-center mt-10'>
                            <Button className='rounded-md ' variant='contained' color='primary' onClick={handleClickopenNote}>Add Note</Button>
                        </div> */}
                        <Grid container style={{ marginTop: "10px", background: "#fff" }}>
                            <Grid item lg={2}>
                                <Card style={{ borderRadius: '0', boxShadow: "none" }}>
                                    <CardActions disableSpacing style={{ display: "flex", flexDirection: "column", borderRadius: '0', boxShadow: 'none' }}>
                                        <TabButton
                                            tab="asset"
                                            tabState={tabState}
                                            tabStateChange={setTabState}
                                            activeClass={classes.activeTab}
                                            variant="text"
                                            color="primary"
                                            className={`w-full d-flex justify-content-start`}
                                            style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                        >
                                            Asset
                                        </TabButton>
                                        <TabButton
                                            tab="contract"
                                            tabState={tabState}
                                            tabStateChange={setTabState}
                                            activeClass={classes.activeTab}
                                            variant="text"
                                            color="primary"
                                            className={`w-full d-flex justify-content-start`}
                                            style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                        >
                                            Contract
                                        </TabButton>
                                        <TabButton
                                            tab="ticket"
                                            tabState={tabState}
                                            tabStateChange={setTabState}
                                            activeClass={classes.activeTab}
                                            variant="text"
                                            color="primary"
                                            className={`w-full d-flex justify-content-start`}
                                            style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
                                        >
                                            Ticket History
                                        </TabButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid lg={10}>
                                <div>
                                    <Card style={{ width: "100%", borderRadius: "0", overflow: "auto" }}>
                                        <CardContent style={{ width: "100%" }}>
                                            <TabContainer tab="asset" tabState={tabState}>
                                                <AssetTab 
                                                    assetDetails={serviceRequestDetails?.asset}
                                                />
                                            </TabContainer>
                                            <TabContainer tab="contract" tabState={tabState}>
                                                <ContractTab 
                                                    contractDetails={serviceRequestDetails?.contract}
                                                />
                                            </TabContainer>
                                             <TabContainer tab="ticket" tabState={tabState}>
                                                <TicketHistoryTab setNoteForEdit={setNoteForEdit} serviceRequestId={serviceRequestId} ref={childRef}/>
                                            </TabContainer>
                                            <TabContainer tab="activities" tabState={tabState}>
                                                <ActivitieLogs logs = {logs} height={'290px'}/>
                                            </TabContainer>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                {/* <DialogActions>
                    <Button  onClick={()=>{
                        dispatch(setAssetIdForRediection(null))
                        Close()}} variant='contained' color="primary">
                        Close
                    </Button>
                </DialogActions> */}
            </Dialog>
            {/* Add Note Dialog */}
            <Dialog 
                fullWidth={fullWidth2}
                maxWidth={maxWidth2}
                open={openNote}
                aria-labelledby="max-width-dialog-title"
            >
                {/* <DialogTitle 
                    id="max-width-dialog-title" 
                    style={{ 
                        textAlign: "right", 
                        fontSize: "20px", 
                        cursor: "pointer" ,
                        padding:'5px 15px 0'
                    }}
                >
                    <Icon onClick={CloseNote} style={{transform:"translateY(10px)"}}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </Icon>
                </DialogTitle> */}
                <DialogContent>
                    <form>
                      <div className='flex justify-between'>
                          <Typography variant='h6' style={{marginBottom:"15px"}}>{noteForEditState ? 'Update' : 'Add'} Note</Typography>
                         <Icon onClick={CloseNote} >
                        <span className="material-symbols-outlined cursor-pointer">
                            close
                        </span>
                    </Icon>
                      </div>
                        <TextField
                            id="outlined-textarea"
                            label="Note"
                            placeholder=""
                            rows={8}
                            value={notes}
                            onChange={(e)=>setNotes(e.target.value)}
                            style={{ width: "100%", background: "#fff", resize: "auto" }}
                            multiline
                            variant="outlined"
                            inputProps={{
                                style: {
                                    resize: "vertical",
                                },
                            }}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    {noteForEditState ? 
                        (<Button onClick={UpdateNote} variant='contained' color="primary">
                            Update
                        </Button>)
                        :
                        (<Button onClick={AddNote} variant='contained' color="primary">
                            Add
                        </Button>)
                    }
                    {/* <Button onClick={CloseNote} variant='contained' color="primary">
                        Close
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ServiceRequestDailog
