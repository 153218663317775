export const navItemPermission = (current_nav_item, agent_type) =>{
	
	const agent_restricted_nav_item_id = [
		'customer-management',
		'agent-management',
    'overview-component',
    'activity-log',
    'super-admin-overview',
    'dashboard-overview-component',
	]

  const agent_admin_restricted_nav_item_id = [
		'overview-component',
    'activity-log',
    'super-admin-overview',
    'dashboard-overview-component',
	]

  const super_admin_permitted_nav_item_id = [
    'super-admin-overview',
    'dashboard-overview-component',
    'log-component',
    'overview-component',
    'activity-log',
    'admin-audit-log'
  ]


	switch (agent_type) {
		case 'agent':
      if(agent_restricted_nav_item_id.includes(current_nav_item)){
				return false
			}else{
        return true
      }

    case 'dalos-admin':
      if(super_admin_permitted_nav_item_id.includes(current_nav_item)){
        return true
      }
      else{
        return false
      }
    case 'agent-admin':
    if(agent_admin_restricted_nav_item_id.includes(current_nav_item)){
      return false
    }
    else{
      return true
    }


		default:
			return true
	}
}