import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import { usePermission } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
// import SaveReportDialog from './saveReportDialog';
import AddModelDialog from './addSLADialog';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import SaveReportDialog from '../../reports/components/saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {	
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setServiceLevelAgreement] = useState([]);
	const router = useRouter();
	const agents = getAgents(invoices);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	// const [loading1, setLoading1] = useState(false);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const [use, setUse] = useState(true);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();

	// function handleViewMenuOpen(event) {
	// 	setAnchorEl(event.currentTarget);
	// 	setViewMenuOpened(true);
	// }

	// function handleViewMenuClose() {
	// 	setAnchorEl(null);
	// 	setViewMenuOpened(false);
	// }

	function getAgents(invoices){
		const agentSet = new Set();
		for(let i = 0; i < invoices.length ; i++){
			agentSet.add(invoices[i].Agent);
		}
		return Array.from(agentSet);
	}

	// function applyFilters(filters){
	// 	setFilters(filters);
	// 	setServiceLevelAgreement(invoices.filter(acc=>{
	// 		let flag = true;
	// 		if(filters.agent != '' && filters.agent != acc.Agent){
	// 			flag = false;
	// 		}
	// 		return flag;
	// 	}))
	// }

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			setUse(false)
		}else {
			setUse(true)
		}
		setSelectedTab(value);
	};
	

	// function toggleView(val){
	// 	setSelectedView(val);
	// }

	function gotoDetailsPage(id){
		router.push(`manufacturers/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	async function getServiceLevelAgreements(){
		// if(!viewManufacturersPermission){
		// 	return;
		// }
		try {
			const res = await axios.get("/service-level-agreement");
			const { data } = res.data;
			setServiceLevelAgreement(formatServiceLevelAgreement(data));
		} catch (err) {
			console.log(err);
		}
	}
	
	async function getManufacturers(){
		// if(!viewManufacturersPermission){
		// 	return;
		// }
		try {
			const res = await axios.get("/manufacturers");
			const { data } = res.data;
			setServiceLevelAgreement(formatServiceLevelAgreement(data));
		} catch (err) {
			console.log(err);
		}
	}

	function handleSearchReset(){
		getManufacturers()
	}

	// function getSearchFilters(){
	// 	setSearchFilters([
	// 		{field: "hardware_sku", label: "SKU"},
	// 		{field: "service_plan", label: "Service plan"},
	// 	])
	// }

	// function getCustomFilters(){
	// 	axios.get('/manufacturers/get-filters')
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setCustomFilters(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }

	function getSearchSuggetions(query, field){
		axios.get('/manufacturers/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/manufacturers/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			setServiceLevelAgreement(data)
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	function formatServiceLevelAgreement(data){
		return data.map((item) => {
			// item.dynamic_attribute.map((element)=>{
			// 	item[element.attribute_name] = element.attribute_value
			// })
			return {
				id: item.id,
				service_level_agreement_name: item?.sla_name || '',
				description: item?.description || '',
				company_name: item.user_company?.company_name ?? 'N/A'
			}
		})
	}

	async function getDynamicAttributes(){
		try {
			const res = await axios.get("/dynamic-attributes/fetch-all");
			const { data } = res.data;
			const platformModuleItem = data.find(({ name }) => name == "invoice_header");
			setPlatformModule(platformModuleItem);
			try {
				const res_1 = await axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`);
				const { data: data_1 } = res_1.data;
				setPlatformModuleAttributes(data_1);
				return data_1;
			} catch (err) {
				toast.error("Unable to fetch dynamic attributes");
				console.log(err);
			}
		} catch (err_1) {
			toast.error("Unable to fetch dynamic attributes");
			setPlatformModule({});
			console.log(err_1);
		}
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			// return getManufacturers();
			return getServiceLevelAgreements();
		})
	}

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}
	

	useEffect(()=>{
		setLoading(true)
		// getManufacturers().finally(() => {
		// 	setLoading(false)
		// });
		getServiceLevelAgreements().finally(() => {
			setLoading(false)
		});
	},[]);

	const { hasPermission } = usePermission()

	const viewSLAggrementPermission = hasPermission('view-service_level_agreement')
	const createSLAggrementPermission = hasPermission('add-service_level_agreement')
	const updateSLAggrementPermission = hasPermission('update-service_level_agreement')
	const deleteSLAggrementPermission = hasPermission('delete-service_level_agreement')
	
	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	return (
		<>
			{/* <CsvUpload 
					refresh={refreshList}
					viewModelPermission = { viewModelPermission }
					createModelPermission = { createModelPermission }
					updateModelPermission = { updateModelPermission }
					deleteModelPermission = { deleteModelPermission }
				/> */}
			<SaveReportDialog tableName={"service level agreement"}/>
			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		handleSearch={handleSearch} 
				// 		searchSuggestions={searchSuggestions} 
				// 		clearSearch={clearFilters}
				// 		searchFilters={searchFilters}
				// 		handleSearchSuggestions={handelSearchSuggestions}
				// 		handleSearchReset={handleSearchReset}
				// 		onSearchChange={(searchValue, fieldValue) => {
				// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
				// 		}}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Service Level Agreements" />
				// 			<Tab key="end_user_tab_2" className="h-64" label="Activities" />							
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							{
								loading && <FuseLoading />
							}
							{
								!loading && <SalesOrderTable 
								endUsersAccounts={invoices}
								selectedView={selectedView}
								gotoDetailsPage={gotoDetailsPage}
								refresh={refreshList}
								viewSLAggrementPermission = { viewSLAggrementPermission }
								createSLAggrementPermission = { createSLAggrementPermission }
								updateSLAggrementPermission = { updateSLAggrementPermission }
								deleteSLAggrementPermission = { deleteSLAggrementPermission }
							/>
							}
						</div>
						<AddModelDialog getServiceLevelAgreements = {getServiceLevelAgreements}/>
					</>
				}
				innerScroll
			/>
		</>
	)
	
}


// export default CardedFullWidth2TabbedSample;
export default withReducer('slaApp', reducer)(CardedFullWidth2TabbedSample);
