import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import axios from "@fuse/utils/axios";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Editor } from '@tinymce/tinymce-react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const useStyles = makeStyles({
    layoutRoot: {},
    head: {
        position: "sticky",
        top: 0
    },
    root: {
        // minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function setHeaderName(header) {
    if (header == 'company_id') {
        return 'Company ID'
    } else {
        return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }
}

function getHeaderName(header) {
    const isObject = typeof header == "object";
    if (!isObject) return setHeaderName(header);
    return header.label ? header.label : ""
}

function getFieldName(header) {
    const isObject = typeof header == "object";
    if (!isObject) return header;
    return header.field ? header.field : ""
}

const dateReformat = (data) => {
    if(data){
        const reformatDate = []
        const reformatTime = []
        const splitDate = data.split(' ')
        const month = splitDate[0].slice(0, 3)
        const SplitTime = splitDate[3].split(':')
        reformatTime.push(SplitTime[0], SplitTime[1])
        const time = reformatTime.join(':')
        reformatDate.push(month, splitDate[1], splitDate[2], ',', time, splitDate[4])
        return reformatDate.join(' ')
    }else return ''
    
}
//layout for every single email 
const EmailDetailsList = (props) => {
    console.log(props.emailInfo)
    const { emailInfo } = props
    return (
        <Card>
            <CardContent style={{ padding: '7px' }}>
                <Grid container>
                    <Grid item container xs={1}>
                        <div style={{ paddingTop: '0px' }}>
                            <Avatar>N</Avatar>
                        </div>
                    </Grid>
                    <Grid item xs={11} style={{paddingLeft: '10px' }}>
                        <div>
                            <Grid container>
                                <Grid item xs={1}><Typography variant="subtitle2">From:</Typography></Grid>
                                <Grid item xs={11}><Typography variant="subtitle2">{`no-reply@dalos.co`}</Typography></Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container>
                                <Grid item xs={1}><Typography variant="subtitle2">To:</Typography></Grid>
                                <Grid item xs={11}><Typography variant="subtitle2">{`${emailInfo.data.receiver_email}`}</Typography></Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container>
                                <Grid item xs={1}><Typography variant="subtitle2">Date:</Typography></Grid>
                                <Grid item xs={11}><Typography variant="subtitle2">{`${dateReformat(emailInfo.data.delivery)}`}</Typography></Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px',minHeight: 50 }} dangerouslySetInnerHTML={{__html: emailInfo.data.email_body}}>
                        </div>

                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const EmailDrawer = (props) => {
    const { closeEmailDrawer, emailInfo } = props
    return (

        <div style={{ width: '600px' }}>
            <div className="px-10 py-5" style={{/*border: '2px solid black' */ }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ marginLeft: '8px' }}>{emailInfo.data.email_subject}</h3>
                    <IconButton aria-label="close" onClick={closeEmailDrawer}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </div>
            <div>
                <EmailDetailsList emailInfo={emailInfo} />
            </div>
        </div>
    )
}

const ReplyEmailForm = (props) => {
    const { onClickCloseReplyMailSection, emailInfo } = props;
    const [loading, setLoading] = useState(true);
    return (
        <div style={{ width: "100%" }}>
            <div className="px-10 py-5">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <IconButton aria-label="close" onClick={onClickCloseReplyMailSection}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </div>

            <div className="p-10">
                <TextField
                    fullWidth
                    margin="dense"
                    value={emailInfo.data.receiver_email}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'><Icon>reply</Icon></InputAdornment>
                        )
                    }}
                />

                <div style={{ width: "100%", marginTop: 10 }}></div>

                <Editor
                    apiKey="rizevt0is9zmdrbesb6gkhvjtzmi8wpejsr6f8qw0hoh6uj4"
                    onLoadContent={() => {
                        setLoading(false);
                    }}
                    init={{
                        height: 450,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px, }'
                    }}
                />
                {loading && <div style={{ width: '100%' }}><FuseLoading /></div>}
                <div style={{ width: "100%", marginTop: 10 }}></div>

                <Button variant="contained" color="primary">
                    Send
                </Button>
            </div>
        </div>
    )
}

function ListView(props) {
    const classes = useStyles();
    const { rows, onRowClicked, columns, table_name } = props;
    const columnHeaders = columns ? columns : Object.keys(rows[0]);
    const [windowHeight,setWindowHeight] = useState(window.innerHeight - 400);
    const [openDrawer, setOpenDrawer] = useState(false)
    const [emailInfo, setEmailInfo] = useState({})
    const [clickReplyButton, setClickReplyButton] = useState(false)

    const onGridSizeChanged = () =>{
        setWindowHeight(window.innerHeight-400);
    }

    function onFilterChanged(event){ 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

    const openEmailDrawer = (details) => {
        setEmailInfo(details)
        setOpenDrawer(true)
        if(props.tabName === 'contract-line-items') setOpenDrawer(false)
    }

    const closeEmailDrawer = () => {
        setOpenDrawer(false)
    }

    const onClickOpenReplyMailSection = () => {
        setClickReplyButton(true)
    }

    const onClickCloseReplyMailSection = () => {
        setClickReplyButton(false)
    }

    const onGridReady = (params) => {
        loadInitialState (params)
    }

    const onColumnMoved = (params) =>{
        const rows = params.columnApi.getColumnState();
        saveView(rows);
    }

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
            const rows = params.columnApi.getColumnState();
            saveView(rows);
        }
	}

    function saveView(rows){
        const order = JSON.stringify(rows);
        axios.post('/user-view/save-view-order',{
            table_name : table_name,
            order : order
        })
        .then((results)=>{
			console.log('view updated')
        })
        .catch(err=>{
            console.log(err);
        })
    }

    function loadInitialState (params){
        axios.get(`/user-view/show-view-order?table_name=${table_name}`)
        .then(results=>{
			const { data } = results.data;
            const order = JSON.parse(data.order);
            params.columnApi.applyColumnState(order);
        })
        .catch(err=>{
            console.log(err);
        })
    }

    return (
        <>
            <div className="ag-theme-alpine" style={{ width: "100%", height : windowHeight }}>
                <AgGridReact
                    onGridReady = { onGridReady }
                    rowData={rows}
                    animateRows = { true }
                    onGridSizeChanged = { onGridSizeChanged }

                    overlayNoRowsTemplate={'<span>No Result Found</span>'}
                    onFilterChanged = { onFilterChanged }
                    onColumnMoved = { onColumnMoved }
                    onColumnVisible = { onColumnVisible }
                    defaultColDef={{
						resizable: true
					}}
                >
                {columnHeaders.map((header) => {

                    const cellRendererProps = header.cellRenderer ? {
                        cellRenderer: function (params){
                            return header.cellRenderer(params, params.node.data[header.field])
                        }
                    } : {};
                    const cellClickedProps = {
                        onCellClicked: function (params){
                            typeof header.cellClicked == "function"
                            ? header.cellClicked(params, params.node.value) 
                            : typeof onRowClicked == "function" && onRowClicked(params, params.node.value)
                            openEmailDrawer(params)
                        },
                    }                    
                    return (
                        <AgGridColumn
                            key={header + "1"}
                            field={getFieldName(header)}
                            headerName={getHeaderName(header)}
                            sortable={true}
                            filter={true}
                            floatingFilter={true}
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                            {...cellRendererProps}
                            {...cellClickedProps}
                            filter="agTextColumnFilter"
                        ></AgGridColumn>
                    );
                    })}
                </AgGridReact>
            </div>
            <Drawer anchor="right" open={openDrawer}>
                <EmailDrawer
                    closeEmailDrawer={closeEmailDrawer}
                    emailInfo={emailInfo}
                />
                <div style={{ width: '600px', height: 'auto', /*border: '2px solid black' */ }}>
                    {
                        clickReplyButton ?
                            <div>
                                <ReplyEmailForm
                                    onClickCloseReplyMailSection={onClickCloseReplyMailSection}
                                    emailInfo={emailInfo}
                                />
                            </div>
                            :
                        <Button
                            variant="outlined"
                            color='primary'
                            startIcon={<Icon>reply</Icon>}
                            style={{ width: '20%', marginLeft: '64px', marginTop: '20px' }}
                            onClick={onClickOpenReplyMailSection}
                        >
                            Reply
                        </Button>
                    }
                </div>
            </Drawer>
        </>
    );
}

export default ListView
