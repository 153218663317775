const { Fragment, useState, useRef, useEffect } = require("react");

export default function CustomDropdownEditor({ value, column, onCellValueChanged, values, api, params, context, node, getValue }){
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef(null);
  // const onCellValueChange = (status_id) => {
  //   // onCellValueChanged()
  //   // const payload = {
  //   //   asset_id: node.data.id,
  //   //   entity_name: "status_id",
  //   //   entity_value: Number(status_id.trim())
  //   // };


  //   // status_id && axios
  //   //   .post(`/assets/inline-edit`, payload)
  //   //   .then((results) => {
  //   //     toast.success("Asset updated successfully.");
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   });

  //   // const gridApi = api
  //   // const transaction = {
  //   //   update: [{ rowIndex: 4, data: { 'status': "hgfhgf" } }],
  //   // };

  //   // // Specify the rows to refresh
  //   // const params = {
  //   //   force: true, // Set to true to refresh all rows
  //   // };
  //   // gridApi.redrawRows();
  // }

  function onCellValueChange(name){
    getValue(name, node.data.id, column.colDef.field)
  }

  useEffect(() => {
    // Focus on the input element when the component mounts
    inputRef?.current?.focus();
  }, []);

  if (column.colDef.field === 'user_name') {
    return (
      <>
        <div className='userlisting_dropdown'>
          <div>
            <input value={inputValue} onChange={(e) => { setInputValue(e.target.value) }} ref={inputRef} ></input>
          </div>
          <div>
            <List>
              <ListItem className="userlist_items" disablePadding>
                <ListItemButton>
                  <ListItemText className="userlist_itemsText" primary="Trash" />
                </ListItemButton>
              </ListItem>
              <ListItem className="userlist_items" disablePadding>
                <ListItemButton>
                  <ListItemText className="userlist_itemsText" primary="Trash" />
                </ListItemButton>
              </ListItem>
              <ListItem className="userlist_items" disablePadding>
                <ListItemButton>
                  <ListItemText className="userlist_itemsText" primary="Trash" />
                </ListItemButton>
              </ListItem>
              <ListItem className="userlist_items" disablePadding>
                <ListItemButton>
                  <ListItemText className="userlist_itemsText" primary="Trash" />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>
      </>
    )
  }
  if (column.colDef.field === 'manufacturer_') {
    return (
      <div className='listing_dropdown'>
        <div>
          <div>
            {values.map(((item, index) => {
              return(
              <Fragment key={index}>
                <div onClick={(e) => { onCellValueChange(item.split(',')[0]) }} className="assigned_list">
                {/* <span className="dot_circle" style={{ backgroundColor: `${(item.split(','))[1]}` }} /> */}
                <p className="assigned_text">
                  {(item.split(','))[0]}
                </p>
              </div><br />
            </Fragment>)}))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='listing_dropdown'>
      <div>
        <div>
          {values.map(((item, index) => {
            return(
            <Fragment key={index}>
              <div onClick={(e) => { onCellValueChange(item.split(',')[0]) }} className="assigned_list">
              <span className="dot_circle" style={{ backgroundColor: `${(item.split(','))[1]}` }} />
              <p className="assigned_text">
                {(item.split(','))[0]}
              </p>
            </div><br />
          </Fragment>)}))}
        </div>
      </div>
    </div>
  )

};