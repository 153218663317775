import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import MapViewer from './MapViewer';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { usePermission } from '@fuse/hooks';
// import SaveReportDialog from './saveReportDialog';
import AddSubscriptionDialog from './addSubscriptionDialog';
import { formatString } from '@fuse/utils/stringOperations';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import { Tab, Tabs } from '@material-ui/core';
import SaveReportDialog from '../../reports/components/saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [endUsersAccounts, setEndUsersAccounts] = useState([]);
	const [logs,setLogs] = useState([]);
	const [ logsLoading,setLogsLoading ] = useState(false)

	const router = useRouter();
	const agents = getAgents(endUsersAccounts);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const [manualContract,setManualContract] = useState([])
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const { hasPermission } = usePermission()
	const viewSubscriptionPermission = hasPermission('view-subcription')?true:hasPermission('view-subscription')?true:false
	const createSubscriptionPermission = hasPermission('add-subcription')?true:hasPermission('add-subscription')?true:false
	const updateSubscriptionPermission = hasPermission('update-subcription')?true:hasPermission('update-subscription')?true:false
	const deleteSubscriptionPermission = hasPermission('delete-subcription')?true:hasPermission('delete-subscription')?true:false

	// function handleViewMenuOpen(event) {
	// 	setAnchorEl(event.currentTarget);
	// 	setViewMenuOpened(true);
	// }

	// function handleViewMenuClose() {
	// 	setAnchorEl(null);
	// 	setViewMenuOpened(false);
	// }

	const handleTabChange = (event, value) => {
		if(value == 1){
			getSubscriptionActivities()
		}
		setSelectedTab(value);
	};

	function getAgents(endUsersAccounts){
		const agentSet = new Set();
		for(let i = 0; i < endUsersAccounts.length ; i++){
			agentSet.add(endUsersAccounts[i].Agent);
		}
		return Array.from(agentSet);
	}

	// function applyFilters(filters){
	// 	setFilters(filters);
	// 	setEndUsersAccounts(endUsersAccounts.filter(acc=>{
	// 		let flag = true;
	// 		if(filters.agent != '' && filters.agent != acc.Agent){
	// 			flag = false;
	// 		}
	// 		return flag;
	// 	}))
	// }

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	// const handleTabChange = (event, value) => {
	// 	setSelectedTab(value);
	// };
	
	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	async function getSubscription(){
		try {
			const res = await axios.get('/subscription');
			const { data } = res.data;
			setEndUsersAccounts(formatSubscription(data));
		} catch (err) {
			console.log(err);
		}
	}
	
	function handleSearchReset(){
		getSubscription()
	}

	function getSearchFilters(){
		setSearchFilters([
			{field: "entity_external_platform_id", label: "Contract ID"},
			{field: "contract_status", label: "Contract Status"},
			{field: "location", label: "Contact Location"},
		])
	}

	function debounce(func, timeout = 300){
		let timer;
		return (...args) => {
		  clearTimeout(timer);
		  timer = setTimeout(() => { func.apply(this, args); }, timeout);
		};
	}

	// function getCustomFilters(){
	// 	axios.get('/contracts/get-filters')
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setCustomFilters(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }

	function getSearchSuggetions(query, field){
		axios.get('/end-user-contracts/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	// function handleSearch(query, field, filters) {
	// 	axios.get('/end-user-contracts/search',{
	// 		params: {
	// 			query,
	// 			field,
	// 			filters
	// 		}
	// 	})
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		// setEndUsersAccounts(data)
			
	// 		setEndUsersAccounts(formatSubscription(data))
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }
	
	function formatSubscription(data){
		return data.map((subscription) => {
			return {
					subscription_name:subscription.subscription_name,
					provider_name:subscription.cloud_provider.provider_name || '',
					status:subscription?.software_asset_status?.status_name,
					no_of_seats:subscription.no_of_seats,
					category:subscription.software_category.category_name,
					department:subscription.department_id,
					term_start_date_exact:subscription?.term_start_date || '',
					term_end_date_exact:subscription?.term_end_date || '',
					start_date : subscription.term_start_date?dateReformatTo_mmm_dd_yyyy(subscription.term_start_date):'',
					end_date : subscription.term_end_date?dateReformatTo_mmm_dd_yyyy(subscription.term_end_date):'',
					amount: subscription.subscription_billing?.amount || '',
					billing_cycle: `${subscription?.subscription_billing?.billing_cycle} ${subscription?.subscription_billing?.month || ''}` || '',
					billing_cycle_: subscription.subscription_billing?.billing_cycle,
					billing_cycle_date:subscription?.subscription_billing?.billing_cycle_date?dateReformatTo_mmm_dd_yyyy(subscription.subscription_billing?.billing_cycle_date):'No Billing Cycle Date',
					website:subscription.website,
					po_date_exact:subscription?.subscription_payment?.po_date || '',
					po_date:subscription.subscription_payment.po_date?dateReformatTo_mmm_dd_yyyy(subscription.subscription_payment.po_date): 'No PO Date',
					po_number:subscription.subscription_payment.po_number || 'No PO Number',
					department:subscription.department_id,
					department_arr:subscription.department_id_,
					currency:subscription?.subscription_billing?.currency || '',
					month:subscription?.subscription_billing?.month || '',
					payment_term_id:subscription?.subscription_billing?.payment_term_id || '',
					provider_obj:subscription?.cloud_provider || '',
					category_obj:subscription?.software_category || '',
					status_obj:subscription?.software_asset_status || '',
					billing_cycle_id:subscription?.subscription_billing?.id || '',
					payment_id:subscription?.subscription_payment?.id || '',
					id:subscription.id,
					term_end_date_exact:subscription.term_end_date,
					term_start_date_exact:subscription.term_start_date,
					po_date_exact:subscription.subscription_payment.po_date,
					value:subscription.subscription_payment.value,
					card_number:subscription.subscription_payment.card_number || 'No Card Number',
					card_holder_name:subscription.subscription_payment.card_holder_name || 'No Card Holder Name',
					billing_cycle_date_exact:subscription?.subscription_billing?.billing_cycle_date,
					reminder: formatString(subscription?.reminder) || '',
					text_contact_number: subscription?.support_number || '',
					manually_added: subscription?.manually_added || false,
					// original_info:{
					// 	subscription_name:subscription.subscription_name,
					// 	provider_name:subscription.cloud_provider.provider_name || '',
					// 	status:subscription?.software_asset_status?.status_name,
					// 	no_of_seats:subscription.no_of_seats,
					// 	category:subscription.software_category.category_name,
					// 	department:subscription.department_id,
					// 	start_date : subscription.term_start_date?dateReformatTo_mmm_dd_yyyy(subscription.term_start_date):'',
					// 	end_date : subscription.term_end_date?dateReformatTo_mmm_dd_yyyy(subscription.term_end_date):'',
					// 	amount: subscription.subscription_billing.amount || '',
					// 	billing_cycle: `${subscription?.subscription_billing?.billing_cycle} ${subscription?.subscription_billing?.month || ''}` || '',
					// 	billing_cycle_: subscription.subscription_billing.billing_cycle,
					// 	billing_cycle_date:subscription.subscription_billing.billing_cycle_date?dateReformatTo_mmm_dd_yyyy(subscription.subscription_billing.billing_cycle_date):'',
					// 	website:subscription.website,
					// 	po_date:subscription.subscription_payment.po_date?dateReformatTo_mmm_dd_yyyy(subscription.subscription_payment.po_date): '',
					// 	po_number:subscription.subscription_payment.po_number || '',
					// 	department:subscription.department_id,
					// 	currency:subscription.subscription_billing.currency || '',
					// 	month:subscription.subscription_billing.month || '',
					// 	provider_obj:subscription.cloud_provider || '',
					// 	category_obj:subscription.software_category || '',
					// 	status_obj:subscription.software_asset_status || '',
					// 	billing_cycle_id:subscription.subscription_billing.id || '',
					// 	payment_id:subscription.subscription_payment.id || '',
					// 	id:subscription.id,
					// 	term_end_date_exact:subscription.term_end_date,
					// 	term_start_date_exact:subscription.term_start_date,
					// 	po_date_exact:subscription.subscription_payment.po_date,
					// 	billing_cycle_date_exact:subscription.subscription_billing.billing_cycle_date,
					// },
				// ...lodash.omit(contract, [
				// 	"user_companies_id",
				// 	"user_companies_users_id",
				// 	"user_company",
				// 	"user_companies_user",
				// 	"dynamic_attributes",
				// 	"user_id",
				// 	"platform_customer_id",
				// 	"entity_external_platform_id",
				// 	"created_at",
				// 	"updated_at",
				// 	"company_id",
				// ]),
				
			}
		})
	}

	async function getDynamicAttributes(){
		try {
			const res = await axios.get("/dynamic-attributes/fetch-all");
			const { data } = res.data;
			const platformModuleItem = data.find(({ name }) => name == "contract_header");
			setPlatformModule(platformModuleItem);
		} catch (err) {
			toast.error("Unable to fetch dynamic attributes");
			setPlatformModule({});
			console.log(err);
		}
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getSubscription();
		})
	}

	useEffect(()=>{
		setLoading(true)
		getSubscription()
		getSearchFilters();
		// getCustomFilters();
		getDynamicAttributes().then(() => {
			return getSubscription();
		}).finally(() => {
			setLoading(false)
		});
	},[]);

	// const actionConst = {
  //       create: 'CREATED',
  //       update: 'UPDATED',
  //       delete: 'DELETED',
  //       set_master: 'SET MASTER',
  //       archive: 'ARCHIVED',
  //       restore: 'RESTORED',
  //       upload_csv_created: 'CSV UPLOAD',
  //       upload_csv_updated: 'CSV UPLOAD'
  //   }


	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
			{/* <CsvUpload refresh={refreshList}/> */}

			<MapViewer/>

			<SaveReportDialog tableName={"subscription"}/>

			<FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg:classes.topBg,
				contentWrapper:classes.contentWrapper
			}}
			// header={
			// 	<DynamicFieldsHeader 
			// 		// handleSearch={handleSearch} 
			// 		searchSuggestions={searchSuggestions} 
			// 		clearSearch={clearFilters}
			// 		searchFilters={searchFilters}
			// 		handleSearchSuggestions={handelSearchSuggestions}
			// 		handleSearchReset={handleSearchReset}
			// 		onSearchChange={(searchValue, fieldValue) => {
			// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
			// 		}}
			// 	/>
			// }
			// contentToolbar={
			// 	<>
			// 			<Tabs
			// 				value={selectedTab}
			// 				onChange={handleTabChange}
			// 				indicatorColor="primary"
			// 				textColor="primary"
			// 				variant="scrollable"
			// 				scrollButtons="off"
			// 				className="w-full h-64"
			// 			>
			// 				<Tab key="end_user_tab_1" className="h-64" label="All Subscriptions" />
			// 				<Tab key="end_user_tab_2" className="h-64" label="Activities" />
							
			// 			</Tabs>
			// 	<div>
			// 				{/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
			// 					{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
			// 				</IconButton> */}
			// 		</div>
			// 	</>
			// }

			content={
				<>
					<div className={selectedTab !== 0 ? 'hidden' : 'p-10'}>
						<EndUsersAccountsTable 
							endUsersAccounts={endUsersAccounts}
							selectedView={selectedView}
							refresh={refreshList}
							getSubscription = {getSubscription}
							viewSubscriptionPermission = {viewSubscriptionPermission}
							createSubscriptionPermission = {createSubscriptionPermission}
							updateSubscriptionPermission = {updateSubscriptionPermission}
							deleteSubscriptionPermission = {deleteSubscriptionPermission}
							/>
					</div>
					< AddSubscriptionDialog getSubscription={getSubscription}/>
					<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
					{/* { selectedTab === 1 && <ActivityAgGridListing 
							module='subscription'
							fetchData={getSubscriptionActivities}
						/>} */}
						{/* {logsLoading ? <FuseLoading/> : <ActivityAgGridListing module='subscription' logs = {logs} />} */}
					</div>
				</>
			}
			innerScroll
		/>
	</>)
	}
}


export default withReducer('subscriptionApp', reducer)(CardedFullWidth2TabbedSample);

