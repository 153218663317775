import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import ListView from './listView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
  const classes = useStyles();
	if(props.loading){
		return (<FuseLoading/>)
	}

	return (
		<div className="w-full flex flex-col">
			<ListView 
				agentsList={props.agentsList}
				customFilterData={props.customFilterData}
			/>
		</div>
	);

}

export default withRouter(EndUsersAccountsTable)