import useToast from '@fuse/hooks/useToast'
import {
	makeStyles, CircularProgress
} from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, Stepper, Step, StepButton, Button, Icon, IconButton, TextField, Typography, Drawer, Paper, Grid, InputAdornment, MenuItem } from '@mui/material'
import { Modal } from '@material-ui/core'
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from 'helper/alertModal'
import { usePermission } from '@fuse/hooks';
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import { Autocomplete } from '@material-ui/lab'
import { statusOption, timezone_list, industry_list, countryCodes } from './constant'
import { countries } from '@fuse/utils/countryName'
import { formatPhoneNumber } from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: '"Inter", sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > span': {
				margin: '10px auto'
			},
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px',
			marginRight: '20px',
			marginBottom: '20px',
			padding: '10px',
			backgroundColor: '#F9FAFB',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		drawerWidth: {
			width: 520,
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			overflowX: 'hidden',
			overflowY: 'hidden'
		},
	}
))
function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function AddCustomerFormDialog(props) {
	let domain = ''
	process.env.REACT_APP_ENVIRONMENT === 'development'?domain='.dalos-dev.co':
	process.env.REACT_APP_ENVIRONMENT === 'staging'?domain='.dalos-staging.space':
	process.env.REACT_APP_ENVIRONMENT === 'production'?domain='.dalos.co':''
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)

	//customer state
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [status, setStatus] = useState('Inactive')
	const [companyName, setCompanyName] = useState('')
	const [subDomain, setSubdomain] = useState('')
	const [country, setCountry] = useState({name: 'United States', code: 'US', currency: 'USD'})
	const [timeZone, setTimeZone] = useState('')
	const [industryList, setIndustryList] = useState('')
	const [countryCode, setCountryCode] = useState('+1');
	const [specialCharacterErrorFName, setSpecialCharacterErrorFName] = useState(false);
	const [specialCharacterErrorLName, setSpecialCharacterErrorLName] = useState(false);
	const [subDomainValidation, setSubDomainValidation] = useState(false);

	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
	const [loading, setLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { data: editFromState } = useSelector((state) => state.ServiceRequestsApp.formViewDialog);

	const types = [
		{
			id: 1,
			label: 'Incident'
		},
		{
			id: 1,
			label: 'Request'
		}
	]

	const [openAlerModal, setOpenAlertModal] = useState(false)


	const steps = ['Personal Info', 'Organization Info'];
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const { hasPermission } = usePermission();
	const [formErr, setFormErr] = useState({
    fName: { error: false },
    lName: { error: false },
    email: { error: false },
    phoneNo: { error: false },
    status: { error: false },
    CoName: { error: false },
    subDomain: { error: false },
    country: { error: false },
    timeZone: { error: false },
    industry: { error: false },
  });
	const addServiceRequestPermission = hasPermission('add-service_request')
	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const defaultValues = {
		client: null,
		user: null,
		asset: null,
		priority: null,
		source: null,
		type: null,
		agent: null,
		status: null,
		category: null,
		firstName: ''
	};

	const validationSchema = [
		// 1 Contact Info
		yup.object({
			firstName: yup.string().required('First name is required!')
			.matches(/^[A-Za-z\s]+$/, 'First name cannot contain numbers, alphanumeric characters, or special characters!')
			.matches(/\S/, 'First name cannot be only spaces!')
			.max(30, 'First name must be at most 30 characters!'),

			lastName: yup.string().required('Last name is required!')
			.matches(/^[A-Za-z\s]+$/, 'Last name cannot contain numbers, alphanumeric characters, or special characters!')
			.matches(/\S/, 'Last name cannot be only spaces!')
			.max(30, 'Last name must be at most 30 characters!'),

			email: yup.string().trim().email('Invalid email').required('Email address is required!'),

			phone: yup.string().trim().required('Phone number is required!').when({
				is: (val) => !!val,
				then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
			}),

			
			companyName: yup.string().required('company name is required!')
			.matches(/^[A-Za-z\s]+$/, 'company name cannot contain numbers, alphanumeric characters, or special characters!')
			.matches(/\S/, 'company name cannot be only spaces!')
			.max(30, 'company name must be at most 30 characters!'),
		}),
		

	];

	const currentValidationSchema = validationSchema[activeStep];


	const methods = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(currentValidationSchema),
		mode: "onChange"
	});

	const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues, trigger, register } = methods

	const { errors } = formState



	function fetchServiceRequestDetails(editFromState) {
		setFirstName(editFromState?.first_name)
		setLastName(editFromState?.last_name)
		setEmail(editFromState?.email)
		setEmail(editFromState?.email)
		setPhone(editFromState?.phone)
		setStatus(editFromState?.status)
		setCompanyName(editFromState?.company_name)
		setSubdomain(editFromState?.subdomain?.replace(domain,''))
		setTimeZone(editFromState?.time_zone)
		setIndustryList(editFromState?.industry)
		setCountryCode(editFromState?.country_code || '+1')
	}


	useEffect(() => {
		if(editFromState){
			fetchServiceRequestDetails(editFromState)
		}
	}, [editFromState])

	const handleError = () =>{
		let error = false
		if(!firstName || specialCharacterErrorFName){
			if(!specialCharacterErrorFName){
				setFormErr((prevState) => ({
					...prevState,
					fName: { error: true},
				}))
			}
			
			error = true
		} 
		if(!lastName || specialCharacterErrorLName){
			if(!specialCharacterErrorLName){
				setFormErr((prevState) => ({
					...prevState,
					lName: { error: true},
				}))
			}
			
			error = true
		} 
		if(!email){
			setFormErr((prevState) => ({
				...prevState,
				email: { error: true},
			}))
			error = true
		} 
		if(!phone){
			setFormErr((prevState) => ({
				...prevState,
				phoneNo: { error: true},
			}))
			error = true
		}
		if(formErr.phoneNo.error === 'invalid') {
			setFormErr((prevState) => ({
				...prevState,
				phoneNo: { error: 'invalid'},
			}))
			error = true
		}

		if(!isValidEmailBasic(email) ){
			error = true
		} 
		if(!status){
			setFormErr((prevState) => ({
				...prevState,
				status: { error: true},
			}))
			error = true
		}

		return error
	}

	const handleSubmitErr = () => {
		let error = false
		if(!companyName){
			setFormErr((prevState) => ({
				...prevState,
				CoName: { error: true},
			}))
			error = true
		} 
		if(!subDomain || subDomainValidation){
			if(!subDomainValidation){
				setFormErr((prevState) => ({
					...prevState,
					subDomain: { error: true},
				}))
			}
			
			error = true
		} 
		if(!timeZone){
			setFormErr((prevState) => ({
				...prevState,
				timeZone: { error: true},
			}))
			error = true
		} 
		if(!industryList){
			setFormErr((prevState) => ({
				...prevState,
				industry: { error: true},
			}))
			error = true
		} 

		return error
	}



	const handleNext = async () => {
		// await trigger();
		if(handleError()) return
		switch (activeStep) {
			// Required Details
			case 0:
				if (Object.keys(errors).length !== 0) {
					return;
				}
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			case 1:
				if (Object.keys(errors).length !== 0) {
					return;
				}

				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			case 2:
				if (Object.keys(errors).length !== 0) {
					return;
				}

				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			case 3:
				if (Object.keys(errors).length !== 0) {
					return;
				}

				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			default:
				break;
		}
	};

	function containsCharacterTypes(input) {
		let err = false
		const numberPattern = /\d/; 
		const alphanumericPattern = /[a-zA-Z0-9]/;
		const specialCharPattern = /[^a-zA-Z0-9\s]/;
	
			err = numberPattern.test(input),
			err = alphanumericPattern.test(input)
			err =  specialCharPattern.test(input)

			return err
	}

	function isValidPhoneNumberBasic(phoneNumber) {
		const basicPattern = /^\d{10}$/;
		return basicPattern.test(phoneNumber);
	}

	function isValidEmailBasic(email) {
		const basicPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return basicPattern.test(email);
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const onSubmit = () => {
		if(handleSubmitErr()) return
		let payload = {
			first_name: firstName?.trim(),
			last_name: lastName?.trim(),
			email: email?.trim(),
			phone: phone?.trim(),
			country_code: countryCode,
			status: status,
			company_name: companyName?.trim(),
			subdomain: subDomain?.trim(),
			country: country.code,
			timezone: timeZone,
			industry: industryList,
		}
		setIsLoading(true)
		if (editFromState) {
			payload.user_id = editFromState.id
			payload.company_id = editFromState.company_id
			delete payload.email
			
			axios.post(`/user/edit-customer`, payload, {
			}).then(results => {
				if(results.data.statusCode === 201){
					toast.error(results.data.message)
					return
				}
				toast.success("Customer updated successfully")
				reset(defaultValues);
				props.getCustomer()
				onCancel()
			}).catch(err => {
				console.log(err)
				toast.error(err?.response?.data?.message);
			})
		} else {
			axios.post('/end-user/registration', payload).then(res => {
        if(res.data.statusCode === 201){
            toast.error(res.data.message)
        }
        else{
            toast.success('Customer added successfully')
						onCancel()
						props.getCustomer()
        }
					
			}).catch((err) => {
					console.log('92=>',err)
					if(err?.response?.status === 400){
							toast.error('Please check if there\'s any blank field!')
					}else{
							toast.error('Something went wrong!')
					}
			})
		}
	}


	const handleAlertModal = (_, reason) => {
		if (reason === 'backdropClick') {
			return;
		}

		setOpenAlertModal(!openAlerModal)

	}
	const handleCloseAlertModal = () => {
		setOpenAlertModal(false)
		onCancel()
	}

	const onCancel = () => {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData())
		setActiveStep(0)
	}

	return (
		<div>
			<Drawer anchor='right' open={addServiceRequestPermission ? openState.open : false} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>{editFromState ? (editFromState?.cloneItem ? 'Clone ' : 'Update ') : 'Add '} Customer</h4>
					<IconButton onClick={handleAlertModal} style={{ padding: '0px' }}>
						<Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
					</IconButton>
				</div>

				<div className='progressbar_holder'>
					<div style={{ width: activeStep === 0 ? '50%' : activeStep === 1 ? '100%' : '0%' }} className='progress'>
						&nbsp;
					</div>
				</div>

				<div className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<Stepper activeStep={activeStep} style={{marginBottom: '28px'}} className='stepper_holder'>
							{steps.map((label, index) => (
								<Step className='each_step_holder' key={label} completed={completed[index]}>
									<StepButton color="inherit" onClick={handleStep(index)}>
										{label}
									</StepButton>
								</Step>
							))}
						</Stepper>
						<div className='main_content_holder mt-5'>
							<>
								<div className='content_holder'>
									{activeStep === 0 &&
										<div className='box_holder'>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>
																First Name
																<span className='required_span'>*</span>
															</Typography>
															<Controller
																name='first_name'
																control={control}
																render={({ field }) => (
																	<>
																	<TextField
																		{...field}
																		style={{ background: '#FCFCFD' }}
																		className='serial_numberField'
																		variant='outlined'
																		fullWidth
																		required
																		value={firstName}
																		onChange={(e)=>{
																			setFirstName(e.target.value)
																			setFormErr((prevState) => ({
																				...prevState,
																				fName: { error: !e.target.value?.trim()? true: false},
																			}))
																			if(e.target.value && !/^[A-Za-z\s]+$/.test(e.target.value)){
																				setSpecialCharacterErrorFName(true)
																			}else if(e.target.value){
																				setSpecialCharacterErrorFName(false)
																			}
																		}}
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				paddingTop: '2px',
																				border: '1px solid #F2F4F7',
																				fonstSize: '10px'
																			}
																		}}
																	/>
																	</>
															)}
															/>
															{specialCharacterErrorFName && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>

																First name cannot contain numbers, alphanumeric characters, or special characters!</span>}
															{formErr?.fName.error && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>First Name is required!</span>}
														</Box>
													</Grid>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>
																Last Name
																<span className='required_span'>*</span>
															</Typography>
															<Controller
																name='last_name'
																control={control}
																render={({ field }) => (
																	<>
																	<TextField
																		{...field}
																		style={{ background: '#FCFCFD' }}
																		className='serial_numberField'
																		variant='outlined'
																		fullWidth
																		value={lastName}
																		onChange={(e)=>{
																			
																			setLastName(e.target.value)
																			setFormErr((prevState) => ({
																				...prevState,
																				lName: { error: !e.target.value?.trim()? true: false},
																			}))
																			if(e.target.value && !/^[A-Za-z\s]+$/.test(e.target.value)){
																				setSpecialCharacterErrorLName(true)
																			}else if(e.target.value){
																				setSpecialCharacterErrorLName(false)
																			}
																		}}
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				paddingTop: '2px',
																				border: '1px solid #F2F4F7',
																				fonstSize: '10px'
																			}
																		}}
																	/>
																</>
															)}
														/>
														{specialCharacterErrorLName && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>

																Last name cannot contain numbers, alphanumeric characters, or special characters!</span>}
																{formErr?.lName.error && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>Last Name is required!</span>}
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Email Address
																<span className='required_span'>*</span>
															</Typography>
															<Controller
																name='email_'
																control={control}
																render={({ field }) => (
																	<>
																		<TextField
																			{...field}
																			disabled={editFromState?true:false}
																			style={{ background: '#FCFCFD' }}
																			className='serial_numberField'
																			variant='outlined'
																			value={email}
																			onChange={(e)=>{
																				setEmail(e.target.value)
																				setFormErr((prevState) => ({
																					...prevState,
																					email: { error: !e.target.value?.trim()? true: !isValidEmailBasic(e.target.value)? 'invalid' : false},
																				}))
																			}}
																			fullWidth
																			FormHelperTextProps={{
																				style: {
																					margin: 0,
																					backgroundColor: '#FCFCFD',
																					width: '100%',
																					paddingTop: '2px',
																					border: '1px solid #F2F4F7',
																					fonstSize: '10px'
																				}
																			}}
																		/>
																	</>
																)}
															/>
															{formErr?.email.error == true && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>Email is required!</span>}

															{(formErr?.email.error == 'invalid') && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>Invalid email!</span>}
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Phone Number
																<span className='required_span'>*</span>
															</Typography>
															<Controller
																name='phone_'
																control={control}
																render={({ field }) => (
																	<>
																			<TextField
																				{...field}
																				label=""
																				style={{ background: '#FCFCFD' }}
																				className='serial_numberField'
																				variant="outlined"
																				fullWidth
																				value={phone}
																				onKeyPress={(event) => {
																					if (!/[0-9/-]+/.test(event.key)) {
																						event.preventDefault();
																					}
																				}}
																				onChange={(e)=>{
																					setPhone(formatPhoneNumber(e.target.value))
																					setFormErr((prevState) => ({
																						...prevState,
																						phoneNo: { error: !e.target.value?.trim()? true:  !(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formatPhoneNumber(e.target.value?.trim())))? 'invalid':  false},
																					}))
																				}}
																				FormHelperTextProps={{
																					style: {
																						margin: 0,
																						backgroundColor: '#FCFCFD',
																						width: '100%',
																						paddingTop: '2px',
																						border: '1px solid #F2F4F7',
																						fonstSize: '10px'
																					}
																				}}
																				InputProps={{
																					startAdornment: (
																					<InputAdornment position="start">
																						<TextField
																							select
																							className='textfield_select_holder'
																							value={countryCode}
																							onChange={(e) => setCountryCode(e.target.value)}
																							variant="standard"
																							InputProps={{ disableUnderline: true }}
																							SelectProps={{
																								MenuProps: {
																									PaperProps: {
																									style: {
																										maxHeight: 200,
																									},
																									},
																								},
																							}}
																						>
																						{countryCodes.map((option) => (
																							<MenuItem key={option.code} value={option.code}>
																								{option.code}
																							</MenuItem>
																						))}
																						</TextField>
																					</InputAdornment>
																					),
																				}}
																			/>
																			</>
																)}
															/>
															{(formErr?.phoneNo.error == true) && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>Phone Number is required!</span>}
																{(formErr?.phoneNo.error == 'invalid') && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>Phone number should be in 000-000-0000 format</span>}
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Status<span className='required_span'>*</span></Typography>
															{/* <Controller
																	name="status"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<> */}
																		<Autocomplete
																				// {...field}
																				style={{ width: '100%' }}
																				options={statusOption}
																				getOptionLabel={option => option ?? ''}
																				value={status}
																				disabled={!editFromState || editFromState?.cloneItem}
																				onChange={(e, value)=>{
																					setStatus(value)
																					setFormErr((prevState) => ({
																						...prevState,
																						status: { error: e.target.value == undefined? true: false},
																					}))

																				}}
																				// onChange={(event, value) => field.onChange(value)}
																				PaperComponent={({ children }) => (
																					<Paper className="autocomplete_holder extra_styling">{children}</Paper>
																				)}
																				renderInput={(params) => {
																					return (
																						<div>
																							<TextField
																								{...params}
																								style={{ background: 'white' }}
																								variant="outlined"
																								fullWidth
																								required
																								
																							/>
																						</div>
																					)
																				}}
																		/>
																		{formErr?.status.error && <span className='required_span  pt-2' 
																			style={ {
																				fontSize: '10px',
																				color: '#f44336',
																				fontFamily: '"Inter", sans-serif !important',
																				letterSpacing: '0.03333em'
																			}}
																		>Status is required!</span>}
																		{/* </>
																	)}
																/> */}
														</Box>
													</Grid>
												</Grid>
											</Box>
										</div>
									}
									{activeStep === 1 &&
										<>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Company Name
																<span className='required_span'>*</span>
															</Typography>

																	<TextField
																		style={{ background: '#FCFCFD' }}
																		className='serial_numberField'
																		variant='outlined'
																		value={companyName}
																		onChange={(e)=>{
																			setCompanyName(e.target.value)

																			setFormErr((prevState) => ({
																				...prevState,
																				CoName: { error: !e.target.value?.trim() ? true: false},
																			}))
																		}}
																		// error={!!errors.companyName}
																		// helperText={errors?.companyName?.message}
																		fullWidth
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				paddingTop: '2px',
																				border: '1px solid #F2F4F7',
																				fonstSize: '10px'
																			}
																		}}
																	/>
																	{formErr?.CoName.error && <span className='required_span  pt-2' 
																			style={ {
																				fontSize: '10px',
																				color: '#f44336',
																				fontFamily: '"Inter", sans-serif !important',
																				letterSpacing: '0.03333em'
																			}}
																		>Company Name is required!</span>}
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Sub Domain
																<span className='required_span'>*</span>
															</Typography>
															<TextField
																label=""
																style={{ background: '#FCFCFD' }}
																className='serial_numberField start_end_text_input'
																variant="outlined"
																fullWidth
																value={subDomain}
																onChange={(e)=>{
																	setSubdomain(e.target.value)
																	setFormErr((prevState) => ({
																		...prevState,
																		subDomain: { error: !e.target.value?.trim() ? true: false},
																	}))

																	if(e.target.value && /[^a-zA-Z0-9-]/.test(e.target.value)){
																		setSubDomainValidation(true)
																	}else if(e.target.value){
																		setSubDomainValidation(false)
																	}
																}}
																InputProps={{
																	startAdornment: (
																	  <InputAdornment position="start">
																		<span className='text_holder'>@</span>
																	  </InputAdornment>
																	),
																	endAdornment: (
																	  <InputAdornment position="end">
																		<span className='text_holder'>{domain}</span>
																	  </InputAdornment>
																	),
																  }}
															/>
															{formErr?.subDomain.error && <span className='required_span  pt-2' 
																			style={ {
																				fontSize: '10px',
																				color: '#f44336',
																				fontFamily: '"Inter", sans-serif !important',
																				letterSpacing: '0.03333em'
																			}}
																		>Sub Domain is required!</span>}
															{subDomainValidation && <span className='required_span  pt-2' 
																	style={ {
																		fontSize: '10px',
																		color: '#f44336',
																		fontFamily: '"Inter", sans-serif !important',
																		letterSpacing: '0.03333em'
																	}}
																>

																Invalid sub domain!</span>}
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Country<span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																		<Controller
																				name="country"
																				className="mb-16"
																				control={control}
																				render={({ field: { ref, ...field }, fieldState: { error } }) => (
																						<>
																								<Autocomplete
																										{...field}
																										style={{ width: '100%' }}
																										options={countries}
																										value={country}
																										disableClearable={!country}
																										getOptionLabel={option => option.name ?? ''}
																										onChange={(_, value) => {
																												let params = value ? value : defaultValues.country
																												setCountry(params)
																										}}
																										PaperComponent={({ children }) => (
																											<Paper className="autocomplete_holder extra_styling">{children}</Paper>
																										)}
																										renderOption={(option) => {
																												return (
																														<div className='flex items-center'>
																																<img
																																		loading="lazy"
																																		// width={"20px"}
																																		className='mr-5 py-2'
																																		src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
																																		srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
																																		alt=""
																																/>
																																<span className='pt-2'>{option.name}</span>
																														</div>
																												)
																										}}
																										renderInput={params => {
																												return (
																														<div ref={params.InputProps.ref}>
																																<TextField
																																		{...params}
																																		variant='outlined'
																																		fullWidth
																																		// required
																																		inputRef={ref}
																																		FormHelperTextProps={{
																																				style: { marginLeft: 0 }
																																		}}
																																		style={{ width: '100%', backgroundColor: '#fff' }}
																																		// InputProps={{
																																		// 		startAdornment:
																																		// 				<InputAdornment style={{ marginRight: '15px' }}>
																																		// 						<img
																																		// 								loading="lazy"
																																		// 								width="20"
																																		// 								src={`https://flagcdn.com/w20/${country?.code?.toLowerCase()}.png`}
																																		// 								srcSet={`https://flagcdn.com/w40/${country?.code?.toLowerCase()}.png 2x`}
																																		// 								alt=""
																																		// 						/>
																																		// 				</InputAdornment>
																																		// }}
																																		InputProps={{
																																			...params.InputProps,
																																			startAdornment: (
																																			  <InputAdornment style={{ marginRight: '15px' }}>
																																				<img
																																				  loading="lazy"
																																				  width="20"
																																				  src={`https://flagcdn.com/w20/${country?.code?.toLowerCase()}.png`}
																																				  srcSet={`https://flagcdn.com/w40/${country?.code?.toLowerCase()}.png 2x`}
																																				  alt=""
																																				/>
																																			  </InputAdornment>
																																			),
																																			endAdornment: params.InputProps.endAdornment, // Ensure the default popup indicator is rendered
																																		  }}
																																/>
																														</div>
																												)
																										}}
																								/>
																						</>
																				)}
																		/>
																</FormControl>
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Time Zone<span className='required_span'>*</span></Typography>
																<Autocomplete
																	style={{ width: '100%' }}
																	options={timezone_list}
																	getOptionLabel={option => option ?? ''}
																	value={timeZone}
																	disableClearable={!timeZone}
																	onChange={(e, value)=>{
																		setTimeZone(value)
																		setFormErr((prevState) => ({
																			...prevState,
																			timeZone: { error: e.target.value== undefined ? true: false},
																		}))
																	}}
																	PaperComponent={({ children }) => (
																		<Paper className="autocomplete_holder extra_styling">{children}</Paper>
																	)}
																	renderInput={(params) => {
																		return (
																			<div>
																				<TextField
																					{...params}
																					style={{ background: 'white' }}
																					variant="outlined"
																					fullWidth
																					required
																					
																				/>
																			</div>
																		)
																	}}
															/>
															{formErr?.timeZone.error && <span className='required_span  pt-2' 
																			style={ {
																				fontSize: '10px',
																				color: '#f44336',
																				fontFamily: '"Inter", sans-serif !important',
																				letterSpacing: '0.03333em'
																			}}
																		>Time Zone is required!</span>}
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Industry List<span className='required_span'>*</span></Typography>
															<Autocomplete
																		style={{ width: '100%' }}
																		options={industry_list}
																		value={industryList}
																		disableClearable={!industryList}
																		onChange={(e, value)=>{
																			setIndustryList(value)
																			setFormErr((prevState) => ({
																				...prevState,
																				industry: { error: e.target.value== undefined ? true: false},
																			}))
																		}}
																		getOptionLabel={option => option ?? ''}
																		PaperComponent={({ children }) => (
																			<Paper className="autocomplete_holder extra_styling">{children}</Paper>
																		)}
																		renderInput={(params) => {
																			return (
																				<div>
																					<TextField
																						{...params}
																						style={{ background: 'white' }}
																						variant="outlined"
																						fullWidth
																						required
																					/>
																				</div>
																			)
																		}}
																/>
																{formErr?.industry.error && <span className='required_span  pt-2' 
																			style={ {
																				fontSize: '10px',
																				color: '#f44336',
																				fontFamily: '"Inter", sans-serif !important',
																				letterSpacing: '0.03333em'
																			}}
																		>Industry is required!</span>}
														</Box>
													</Grid>
												</Grid>
											</Box>
										</>
									}
								</div>
								<div className='btn_holder'>
									{activeStep !== 0 &&
										<Button
											color="inherit"
											disabled={activeStep === 0}
											onClick={handleBack}
											className='back_btn'
										>
											Back
										</Button>
									}

									{activeStep !== steps.length - 1 &&
										<Button onClick={handleNext} className='next_btn'>
											Next
										</Button>
									}
									{activeStep === steps.length - 1 &&
										<Button onClick={onSubmit} className='next_btn' >
											Proceed
										</Button>
									}
								</div>
							</>
						</div>
					</Box>

				</div>
			</Drawer>

			{openAlerModal &&
				<AlertModal openAlerModal module="customer" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal} />
			}

		</div >
	)
}
export default AddCustomerFormDialog
