import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState,useEffect, useRef } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { openFormViewerDialog,setFromData,setSubId } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import SubscriptionDailog from './SubscriptionDailog';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { statusColors } from "@fuse/utils/colorName";
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { isArray } from "lodash";
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';



const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	icon:{
		fontSize:"18px",
		color:"#61656F",
		display:"inline-block"
	},
	activeTab: {       
		background:"rgba(107, 44, 87, 0.04);",
		borderRadius:"0",
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "SUBSCRIPTION";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const gridRef = useRef();
	const [open, setOpen] = useState(false);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');	
	const [tabState, setTabState] = useState("information");
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [editData, setEditData ]  = useState({});
	const [editButtonClick, setEditButtonClick ]  = useState(false);
	const [editDone, setEditDone ]  = useState(false);
	const [deleteData, setDeleteData] = useState(null);
	const [substatus,setSubStatus] = useState([])

	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);

	const [filterApplied, setFilterApplied] = useState(false);
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );
	const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );
	// item != 'provider_obj' && item !='category_obj' && item!='billing_cycle_id' &&
	// item !='payment_id' && item!='id' && item !='term_start_date_exact' &&
	// item!='term_end_date_exact' && item!='po_date_exact' && item !='billing_cycle_date_exact' && item!='month' && item!='every' && item!='currency'
	// && item!='status_obj'
	const skipHeader = ['provider_obj','category_obj','billing_cycle_id','payment_id',
		'id','term_start_date_exact','term_end_date_exact','po_date_exact','value','reminder','text_contact_number',
		'billing_cycle_date_exact','month','every','currency','status_obj','billing_cycle_','original_info','department_arr', 'manually_added','payment_term_id'
	]

	function TabButton(props) {
		const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
		const isActive = tab == tabState;
		return (
			<Button 
				{...restProps} 
				onClick={() => tabStateChange(tab)} 
				className={restProps.className && isActive ? activeClass : ""}
			>
				{props.children}
			</Button>
		)
	}

	function Close(){
		setOpen(false)
		setTabState('information')
	}

	const handleClickOpen = (data) => {
		dispatch(setSubId(data.id))
		// dispatch(setSubData(data))
		// axios.get(`subscription/subscription-details/${data.id}`)
		// .then(results=>{
		// 	dispatch(setSubData(results.data.data.subDetails))
		// })
		// .catch(err=>{
		// 	console.log(err);
		// })
		setOpen(true);
	};

	function TabContainer(props) {
		const { tabState, tab } = props;
		const shouldRender = tab == tabState;
		return shouldRender ? props.children : <></>
	}

	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) =>{
		props.endUsersAccounts.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '' && fieldName == 'po_number'){
				 list[fieldName] = `No PO Number`
				}
				else if(value === '' && fieldName == 'po_date'){
					list[fieldName] = `No PO Date`
				   }
					
				else if(value === '') list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
    	// loadInitialState  (params)
    	dispatch(setfilterlist({}));
    	dispatch(setrowGroupModel([]));
    }else{
    	getReport(params);
    }
	}

	const onGridSizeChanged = ()=>{
		setWindowHeight(window.innerHeight - 330);
	}
	

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "subscription";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function onFilterChanged(event){
		const rows = event.api.getFilterModel();

		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
					const filter = JSON.parse(data?.filter);
          params?.columnApi.applyColumnState({ state: order });
					params.api.setFilterModel(filter);
					if(Object.keys(filter)?.length){
            setFilterApplied(true)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

	useEffect(()=>{
    if(filterApplied){
      toast.success("Filters have been applied")
    }
  },[filterApplied])

	function saveView(rows, filter) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(report_name == undefined){
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];
	
			if(first_row.colId !== 'ag-Grid-AutoColumn'){
				saveView(rows);
				dispatch(setrowGroupModel(rows));
			}else{
				dispatch(setrowGroupModel(rows));
				saveView(rows);
			}
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
		// auto-size columns on row-grouping		
		// onRowGroupOpenedAutoSize();	
	}

	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	const handleConfirm = (data) => {
		setDeleteData(data)
	}

	const handleClose = () => {
		setDeleteData(null)
	}

	function handelDelete(){
		axios.delete(`/subscription/delete/${deleteData.id}?`).then((res)=>{
			if(res.status == 201){
				toast.error(res.data.message);
			} else{
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}
		}).catch((err)=>{
			console.log(err)
			// toast.error("Something went wrong!");
		})
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function handelEdit(data){
		dispatch(setFromData(data))
		// setEditButtonClick(true)
		// setEditData(data)
		dispatch(openFormViewerDialog())
	}

	useEffect(()=>{
		fetchSubstatus()
		// props.refresh()
		if(router.params?.id){
			handleClickOpen({id: router.params.id})
		}
	},[])
	

	// useEffect(()=>{
	// 	console.log("000", query_data.query)
	// 	gridRef.current.api.setQuickFilter(
	// 		query_data.query
	// 	);
	// 	if(Object.keys(filterModel).length !== 0){
	// 		setTimeout(()=>{
	// 			gridRef.current.api.setFilterModel(filterModel);
	// 		},0)
	// 	}
	// },[query_data.query])
	useEffect(() => {
    gridRef.current.api.setQuickFilter(query_data);
  }, [query_data]);
	
	useEffect(()=>{
		onGridReady()
	},[props.endUsersAccounts])
	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	function onCellValueChanged(e){
		let status = e.data.status_obj
		let no_of_seats = e.data.no_of_seats
		if(e.colDef.field == "status"){
			// status = substatus.find(item => item.label == e.newValue?.split(",")[0])
			props.refresh();
			return
		}
		if(e.colDef.field == "no_of_seats"){
			if(Number(String(e.newValue)) < 1){
				toast.error('Number of Seats must be greater than zero')
				props?.refresh()
				return
			}
			if(isNaN(Number(String(e.newValue)))){
				toast.error('Number of Seats should be a number')
				props?.refresh()
				return
			}
			no_of_seats = e.newValue
		}
		
		let payload = {
			edit_id: e.data.id,
			status_id: status.id,
			status_id: status ? status.id : e.data.status_obj.id,
			no_of_seats: Number(no_of_seats)
		}
		if(!payload.no_of_seats){
			toast.error('Number of Seats is required')
			props?.refresh()
			return
		}
		axios.post('/subscription/edit-subscription-for-inline-edit', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				toast.success('Subscription updated successfully.')
				props.refresh()
			}
		}).catch(err => {
			console.log('212=>',err);
			// toast.error('Something went wrong!')
		})
	}

	// function editCloseForm(data){
	// 	if(data){
	// 		setEditDone(true)
	// 		setEditButtonClick(false)
	// 	}
	// }

	async function fetchSubstatus(){
		axios.get(`/asset-status?type=software`).then(response => {
			let status = AlphabeticalSorting(response.data.data,'status_name')
			setSubStatus(status.map((item)=>{
				return {
					id: item.id,
					label: item.status_name,
					color: item.status_color.code,
				}
			}))
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	const frameworkComponents = {
		customFilter: CustomFilter,
		customDropdownEditor: CustomDropdownEditor
	};

	function getHeaderName(header){
		if(header == 'address_1'){
			return 'Address Line 1'
		}
		if(header == 'url'){
			return 'URL'
		}
		if(header == 'address_2'){
			return 'Address Line 2'
		}
		if(header == 'po_date'){
			return 'PO Date'
		}
		if(header == 'po_number'){
			return 'PO Number'
		}
		if(header == 'card_holder_name'){
			return "Card Holder's Name"
		}
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const handleChildClick = (value) => {
		setTabValue(value)
  };



	//Logs
	const actionConst = {
		create: 'CREATED',
		update: 'UPDATED',
		delete: 'DELETED',
		set_master: 'SET MASTER',
		archive: 'ARCHIVED',
		restore: 'RESTORED',
		upload_csv_created: 'CSV UPLOAD',
		upload_csv_updated: 'CSV UPLOAD'
}

	function formatLogs(data) {
		return data.map((item) => {
			return {
				id:item.id,
				action_id: `act${item.id}`,
				subscription_name: item?.subscription?.subscription_name || item.subscription_name,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				name: item.user_name,
				action: actionConst[item.action_name],
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				user_role: item.user_role_name,
				field_name: item.action_field_name
			}
		})
	}

	function getSubscriptionActivities () {
		axios.get(`/subscription/get-all-activities`).then(({data})=>{
			setLogs(formatLogs(data.data));
		})
		.catch((err)=>{
			console.log(err,'cannot fetch logs');
		}).finally(()=>{
		})
	}

	useEffect(()=>{
		(tabValue == 1) && getSubscriptionActivities()
	},[tabValue])

	useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])

	const getValues = (status_name, row_id, column_name)=>{
		let status = null;
		status = substatus.find(
			(item) => item.label == status_name
		);
		if(!status) return
		let payload = { status_id: status ? status.id : e.data.status.id,
			subscription_id: parseInt(row_id)
		}
		axios.post(`/subscription/edit-status`, payload)
		.then(results=>{
			toast.success("Subscription updated successfully")
			props.refresh();
		})
		.catch(err=>{
			console.log(err);
		})
	}
	

	return (
		<>
			{(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'subscription'} logs={logs} displayTableName = {'Subscriptions'}
				 onClick={handleChildClick}
			/>}
			{(tabValue == 1) && (<ActivityAgGridListing module={'subscription'} logs={logs} displayTableName={'Subscriptions'}/>)}

			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'12px'
				}}
			>
				{filterApplied && <p style={{margin: '4px'}}>Filters have been applied</p>}
				<AgGridReact
					ref={gridRef}
					suppressDragLeaveHidesColumns={true}
					rowData={props.endUsersAccounts}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					onGridReady = { onGridReady }
					rowHeight = {30}
					animateRows = { true }
					onGridSizeChanged = { onGridSizeChanged }

					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
					
					// get filter model
					onFilterChanged = { onFilterChanged }		
					onColumnMoved = { onColumnMoved }	
					onColumnVisible = { onColumnVisible }
					onColumnPinned = { onColumnPinned }
					onSortChanged={onSortChanged}
					groupHeaderHeight ={1}
					floatingFiltersHeight = {40}

					//row grouping
					showOpenedGroup={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }
					// autoGroupColumnDef={{ minWidth: 5000 }}
					onColumnRowGroupChanged = { onColumnRowGroupChanged }
					frameworkComponents={frameworkComponents}

					pagination={true}
					defaultColDef={{
						resizable: true
					}}
				>
					<AgGridColumn 
						headerName="Actions"  
						lockPosition={true}
						rowSelection={'single'}
						pinned= 'right' 
						className="action_field"
						width={120}
						minWidth={120}
						maxWidth={120}
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
									{/* props.updateAssetStatusPermission && */}
									{/* props.deleteAssetStatusPermission && */}
									{props.viewSubscriptionPermission && <Tooltip id="view" title="Setting" placement="top">
										<Icon  onClick={()=>{handleClickOpen(data)}} style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}}>settings</Icon>
									</Tooltip>}
									{props.updateSubscriptionPermission && <Tooltip id="edit" title="Edit" placement="top">
										<Icon onClick={() => handelEdit(data)} style={{color: '#1d2939',paddingBottom: '3px',paddingRight:'30px',fontSize:'15px',cursor:'pointer'}}>edit</Icon>
									</Tooltip>}
									{props.deleteSubscriptionPermission && <Tooltip id="delete" title="Delete" placement="top">
										<Icon  onClick={() => handleConfirm(data) } style={{
											color: '#1d2939',
											paddingBottom: '3px',
											paddingRight:'30px',
											fontSize:'15px',
											cursor:'pointer'
										}}>delete</Icon>
									</Tooltip>}
										{/* {<IconButton variant='contained' onClick={() => handelEdit(data) }><Icon>edit</Icon></IconButton>}
										{<IconButton variant='contained' onClick={()=>{handleClickOpen(data)}}><Icon>settings</Icon></IconButton>}
										{<IconButton variant='contained' onClick={() => handleConfirm(data) }><Icon>delete</Icon></IconButton>} */}
									</>
									)
							}else{
								return(<></>)
							}

						}}
					>
					</AgGridColumn>
					{
						Object.keys(props.endUsersAccounts[0]).map(item => {
							if(!skipHeader.includes(item)){
								if(item == 'subscription_name'){
									return(
										<AgGridColumn 
											field={item}
											headerName={getHeaderName(item)} 
											// editable={true}
											sortable={ true }
											filter="text"
											flex={1}
											minWidth={200}
											headerComponentFramework={CustomFilter}
											// onCellValueChanged = {onCellValueChanged}
											
										>
										</AgGridColumn>
									)
								}
								if(item == 'website'){
									return(
										<AgGridColumn 
											field={item}
											headerName={getHeaderName(item)}
											sortable={ true }
											filter="text"
											flex={1}
											minWidth={200}
											headerComponentFramework={CustomFilter}
											// onCellValueChanged = {onCellValueChanged}
											
										>
										</AgGridColumn>
									)
								}
								if(item === 'status'){
									return(
										<AgGridColumn 
											field={item}
											headerName={getHeaderName(item)} 
											key = { item } 
											cellEditorPopup={true}
											suppressSizeToFit={true}
											flex={1}
											minWidth={200}
											editable={props.updateSubscriptionPermission}
											sortable={ true }
											headerComponentFramework={CustomFilter}
											filter="text" 
											
											// cellEditorParams= {{values:substatus.map(s => s.label)}}
											cellEditorParams={{
												values: substatus.map(
													(s) => `${s.label},${statusColors[s.color]}`
												),
												getValue: getValues
											}}
											// cellEditor= 'agRichSelectCellEditor'
											cellEditor='customDropdownEditor'
											onCellValueChanged = {onCellValueChanged}
											cellRendererFramework={(event) =>{ 
												if(event.colDef.field === "status" ){
													return (
														<span>
															<span style={{
																height:'10px',
																width:'10px',
																marginTop: '2px',
																borderRadius: '50%',
																display: 'inline-block',
																// backgroundColor: event.data?.status_obj?.status_color?.code
																backgroundColor:
																	event.value?.split(",")[1] ??
																	event.data?.status_obj?.status_color?.code,
															}}></span>
															<span style={{marginLeft: '5px'}}>
																{/* {event.data?.status_obj?.status_name} */}
																{event.value?.split(",")[0]}
															</span>
														</span>
													)
												}else if(event.colDef.headerName == 'Group' && event.node.field == 'status'){
													return <><span>{event.value}</span></>
												}else{
													return(<><span>{event.value}</span></>)
												}											
											}}
										>
										</AgGridColumn>
									)
								}
								if(item === 'no_of_seats'){
									return(
										<AgGridColumn 
											field={item}
											headerName={getHeaderName(item)} 
											editable={props.updateSubscriptionPermission}
											sortable={ true }
											headerComponentFramework={CustomFilter}
											filter="text"
											flex={1}
											minWidth={200}
											onCellValueChanged = {onCellValueChanged}
											
										>
										</AgGridColumn>
									)
								}
								if(item === 'amount'){
									return(
										<AgGridColumn 
											field={item}
											headerName={getHeaderName(item)} 
											// editable={true}
											sortable={ true }
											headerComponentFramework={CustomFilter}
											filter="text"
											flex={1}
											minWidth={200}
											// onCellValueChanged = {onCellValueChanged}
											cellRendererFramework={(event) =>{ 
												if(event?.data){
													return (
														<span>
															<span ><Icon style={{ fontSize:'14px', opacity: '0.7',transform:'translateY(3px)' }} >attach_money_outlined</Icon></span>
															<span style={{marginLeft: '5px'}}>{event.data?.amount}</span>

														</span>
													)
												}else{
													return(
														<></>
													)
												}
												
											}}
											
										>
										</AgGridColumn>
									)
								}
								return(
									<AgGridColumn 
										field={item}
										headerName={getHeaderName(item)} 
										sortable={ true }
										headerComponentFramework={CustomFilter}
										filter="text"
										flex={1}
										minWidth={200}
										// onCellValueChanged = {onCellValueChanged}
										
									>
									</AgGridColumn>
								)
							}
						})
					}
				</AgGridReact>
				<GeneralModal
					open={deleteData}
					title={'Delete Subscription'}
					text={<h4>Are you sure to delete this Subscription named as <b>{deleteData?.subscription_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handelDelete}
				/>
			</div>}

			{open && (
				<SubscriptionDailog 
					fullWidth={fullWidth} 
					maxWidth={maxWidth} 
					open={open} 
					setOpen ={setOpen} 
					Close={Close} 
					classes={classes} 
					TabButton ={TabButton} 
					tabState={tabState} 
					setTabState={setTabState} 
					TabContainer={TabContainer}
				/>
			)}
		</>
	);
}

export default ListView
