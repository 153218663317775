import useToast from '@fuse/hooks/useToast'
import {
	Accordion, AccordionDetails, Button, 
	DialogTitle, Drawer, Icon, IconButton, makeStyles, Paper, 
	TextField, Typography, Divider} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import { useCurrentUser, useRouter } from '@fuse/hooks'
import axios from "@fuse/utils/axios"
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Editor from '@fuse/components/Editor';

const useStyles = makeStyles(theme => ({
	loader: {
		display: 'flex',
		marginTop: '27px',
		marginLeft: '10px',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	drawerWidth: {
		width: 600
	},
	uploadImageButton: {
		padding: '10px 36px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer',
		marginTop: "25px"
	},
	subSection: {
		marginLeft: '20px !important',
		marginRight: '20px !important',
		marginBottom: '20px !important',
		padding: '10px',
		backgroundColor: '#f4f4f4',
		borderRadius: '4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
}))

function AddLocationTypeFormDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const router = useRouter()
	const currentUser = useCurrentUser();
	const openState = useSelector((state) => state.LocationTypeApp.formViewDialog)
	const [loading, setLoading] = useState(false)
    const [locationType, setLocationType] = useState('')
    const [description, setDescription] = useState('')
	const { data: editFromState } = useSelector((state) => state.LocationTypeApp.formViewDialog);


	function setDefault(){
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		setLocationType('')
	}

	const onCancel = () => {
		setDefault()
	}

	//form validation
	// const schema = yup.object({ })

	// setting default values of accessory
	// const defaultValues = {
	// 	location_type: '',
	// }

	// const { control, formState, handleSubmit, reset, setValue, getValues } = useForm({
	// 	mode: 'onChange',
	// 	defaultValues,
	// 	resolver: yupResolver(schema)
	// });

	function onEditorContentChange (content) {
		setDescription(content)
	}

	const handleSubmit = (e)=>{
		e.preventDefault()

		let payload = {
			name: locationType.trim(),
			description: description	
		}
		
		if(editFromState){
			payload.id = editFromState.id
			axios.post(`/location-type/edit`, payload, {
			}).then(results=>{
				toast.success(results.data.message)
				setDefault()
				props.getLocationTypes()
			}).catch(err=>{
				console.log(err)
				toast.error(err.response.data.message);
			}).finally(() => {
				setTimeout(() => {
					// setLoading(false)
				}, 500)
			});
		} else {
			axios.post(`/location-type/add`, payload, {
			}).then(results=>{
				toast.success(results.data.message)
				setDefault()
				props.getLocationTypes()
			}).catch(err=>{
				console.log(err)
				toast.error(err.response.data.message);
			}).finally(() => {
				setTimeout(() => {
					// setLoading(false)
				}, 500)
			});
		}
    }

	useEffect(() => {
		if(editFromState){
			let descriptionForEdit;
			if(editFromState.description === 'No Description') {
				descriptionForEdit = "";
			} else {
				descriptionForEdit = editFromState.description;
			}
			setLocationType(editFromState.location_type)
			setDescription(descriptionForEdit)
		}
	}, [])

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<DialogTitle>{editFromState ? 'Update ' : 'Create '}
						Location Type
					</DialogTitle>
					<IconButton style={{ marginRight: '15px' }} onClick={onCancel}>
						<Icon>close</Icon>
					</IconButton>
				</div>
				<form onSubmit={(e)=>handleSubmit(e)}>
					<Accordion className={classes.subSection} expanded={true}>
						<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
						<Divider style={{marginBottom: '10px'}}/>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column',padding:0 }}>							
							<div>							
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<TextField
										value={locationType}
										onChange={(e)=>setLocationType(e.target.value)}
										variant='outlined'
										label='Location Type'
										fullWidth
										required
										name='location_type'
										style={{ background: 'white' }}									
									/>
								</div>
							</div>
							<div>
								{/* Asset Tag */}
								<div style={{ marginBottom: '10px', width: '100%' }}>
								<Editor
									editorContent={description}
									onEditorContentChange={onEditorContentChange}
								/>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<div style={{ padding: 0, margin: 20, display: 'flex', justifyContent: 'left' }}>
						<Button
							className={classes.endButtons} 
							style={{ marginRight: '10px' }} 
							type='submit' variant='contained' 
							color='primary'
						>
							{editFromState ? 'Update' : 'Add '}
						</Button>
						<Button className={classes.endButtons} variant='outlined' color='primary' onClick={onCancel}>Cancel</Button>
					</div>
				</form>
			</Drawer>
		</div>
	)
}

export default AddLocationTypeFormDialog
