import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import AddUserForm from "./addUserForm";
import { useEffect, useState, useRef } from "react";
import axios from "@fuse/utils/axios";
import useToast from "@fuse/hooks/useToast";
import GeneralModal from "@fuse/components/Modal/GeneralModal";
import CsvUpload from "./CsvUpload";
import Tooltip from "@material-ui/core/Tooltip";
import {
  openFormViewerDialog,
  setFromData,
  setUserListChange,
  setUserFromData,
  openUserFormDialog,
} from "../store/formViewDialogReducer";

const useStyles = makeStyles({
  root: {
    // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
    //   border: '2px solid black',
    //   display: 'none !important'
    // }
    "& .MuiDataGrid-columnsContainer": {
      background: "#8f8f8f26",
    },
  },
});

const SeatsTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useToast(dispatch);

  const [rows, setRows] = useState([]);
  const [deleteData, setDeleteData] = useState(null);

  const { sub_data: sub_desc } = useSelector(
    (state) => state.subscriptionApp.formViewDialog
  );
  const { open } = useSelector((state) => state.subscriptionApp.formViewDialog);
  const { sub_id: sub_id } = useSelector(
    (state) => state.subscriptionApp.formViewDialog
  );

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            <svg
              onClick={() => {
                handelEdit(params.row);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              className="mr-10"
              viewBox="0 0 24 24"
            >
              <path d="M15.7279 9.57629L14.3137 8.16207L5 17.4758V18.89H6.41421L15.7279 9.57629ZM17.1421 8.16207L18.5563 6.74786L17.1421 5.33365L15.7279 6.74786L17.1421 8.16207ZM7.24264 20.89H3V16.6474L16.435 3.21233C16.8256 2.8218 17.4587 2.8218 17.8492 3.21233L20.6777 6.04075C21.0682 6.43128 21.0682 7.06444 20.6777 7.45497L7.24264 20.89Z"></path>
            </svg>
            <svg
              onClick={() => {
                handleConfirm(params.row);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM9 4V6H15V4H9Z"></path>
            </svg>
          </>
        );
      },
    },
    {
      field: "Username",
      headerName: "User Name",
      width: 150,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 250,
      editable: true,
      renderCell: (params) => {
        if (params.value?.length > 22) {
          return (
            <Tooltip title={params.value} placement="top-start">
              <span className="activitiesTab-descText">{params.value}</span>
            </Tooltip>
          );
        } else {
          return <span>{params.value}</span>;
        }
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 150,
      renderCell: (params) => {
        if (params.value == "" || params.value == null) {
          return <span>No Phone</span>;
        } else {
          return <span>{params.value}</span>;
        }
      },
      editable: true,
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      renderCell: (params) => {
        if (params.value == "" || params.value == null) {
          return <span>No Description</span>;
        } else if (params.value?.length > 50) {
          return (
            <Tooltip title={params.value} placement="top-start">
              <span className="activitiesTab-descText">{params.value}</span>
            </Tooltip>
          );
        } else {
          return <span>{params.value}</span>;
        }
      },
      editable: true,
    },
    // {
    //   field: 'date',
    //   headerName: 'Date',
    //   // type: 'date',
    //   width: 110,
    //   editable: true,
    // },
  ];

  function handelEdit(data) {
    dispatch(setUserFromData(data));
    dispatch(openUserFormDialog());
  }

  function handelDelete() {
    dispatch(setUserListChange(false));
    axios
      .delete(`/subscription/delete-user/${deleteData.item_id}`)
      .then((res) => {
        if (res.status == 201) {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
          handleClose();
          fetchSubscriptionUser();
          dispatch(setUserListChange(true));
        }
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong!");
      });
  }

  function fetchSubscriptionUser() {
    axios
      .get(`/subscription/get-associate-user/${sub_id}`)
      .then((response) => {
        const user_info = response.data.data.userDetails.map((item, index) => {
          return {
            ...item.user_companies_user,
            Seatnumber: index + 1,
            Username:
              item.user_companies_user?.first_name +
              " " +
              item.user_companies_user?.last_name,
            description: item.description,
            item_id: item.id,
          };
        });
        setRows(user_info);
        // setContractsMenu((response.data.data.map((item)=>item.display_name)))
      })
      .catch((err) => {
        console.log("err", err);
        // toast.error('Something went wrong!')
      });
  }

  const handleConfirm = (data) => {
    setDeleteData(data);
  };
  const handleClose = () => {
    setDeleteData(null);
  };

  useEffect(() => {
    fetchSubscriptionUser();
  }, [open]);

  return (
    <>
      <CsvUpload refresh={fetchSubscriptionUser} />
      {/* <div className={classes.root} style={{ height: 350, width: '100%' }}> */}
      <div
        className={classes.root}
        style={{ height: 290, width: "100%", overflow: "auto" }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter={true}

          // onRowClick = {handelClick}
        />
        <GeneralModal
          open={deleteData}
          title={"Delete User"}
          text={
            <h4>
              Are you sure to delete this User named as{" "}
              <b>{deleteData?.Username}</b>?
            </h4>
          }
          handleClose={handleClose}
          handleConfirm={handelDelete}
        />
      </div>
      <AddUserForm fetchSubscriptionUser={fetchSubscriptionUser} />
    </>
  );
};

export default SeatsTab;
