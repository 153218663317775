import useToast from '@fuse/hooks/useToast'
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, 
  DialogTitle, Divider, Drawer, Icon, IconButton, makeStyles, Paper, TextField, 
	Tooltip, Typography, TextareaAutosize,InputAdornment,Modal 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog,setFromData } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import ModelDialog from '@fuse/components/Modal/QuickAddModelDialog'
import LocationDialog from '@fuse/components/Modal/QuickAddLocationDialog'
import { useDebounce, useRouter } from '@fuse/hooks'
import UserDialog from '@fuse/components/Modal/QuickAddUser'
import StatusDialog from '@fuse/components/Modal/QuickAddStatusDialog'
import QuickAddContract from '@fuse/components/Modal/QuickAddContract'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { usePermission } from '@fuse/hooks';
import { generateProperLocationString } from '@fuse/utils/general'
import CustomDatePicker from '@fuse/components/customDatePicker'
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
// import { Editor as EditOld } from '@tinymce/tinymce-react';
import { countries } from '@fuse/utils/countryName'
import Editor from '@fuse/components/Editor';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'

const useStyles = makeStyles(theme => ({
    uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
    },
    loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
    },
    subSection: {
		marginLeft:'20px !important',
		marginRight:'20px !important',
		marginBottom:'20px !important',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
    },
    endButtons: {
      	borderRadius: '4px'
    },
	modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
	drawerWidth: {
		width: 600
	},
	
}))

function AddAssetFormDialog(props) {
	const [deployedDate,setDeplaoyedDate] = useState({
		date: null,
		error: false
	})
	const [recoveryDate,setRecoveryDate] = useState({
		date: null,
		error: false
	})

	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const router = useRouter()
	const openState = useSelector((state) => state.assetsApp.formViewDialog)
	const { data:editFromState } = useSelector((state) => state.assetsApp.formViewDialog);
	const [loading, setLoading] = useState(false)
	const [assetNote, setAssetNote] = useState('')
	const [assetNoteInitial, setAssetNoteInitial] = useState('')
	const [assetNoteText, setAssetNoteText] = useState('')
	const [quickAddContract,setQuickAddContract] = useState(false)
	const { hasPermission } = usePermission()
	const createAssetStatusPermission = hasPermission('add-asset_status')
	const createModelPermission = hasPermission('add-model')
	const createLocationPermission = hasPermission('add-location')
	const createUserPermission = hasPermission('add-end-user')
	const createContractPermission = hasPermission('add-contract')

	let [allSpan, setAllSpan] = useState([
		{
			id: Date.now(),
			serial_number: '',
			asset_tag: '',
			asset_name: ''
		},
	])

	const [currentUser, setCurrentUser] = useState(null)
	const [imageName, setImageName] = useState('')
	const [imageLocation, setImageLocation] = useState('')
	const [previewImageLink,setPreviewImageLink] = useState('')
	const [openImagePreview,setOpenImagePreview] = useState(false)
	const [ statusMenu,setStatusMenu ] = useState([])
	const [optionalFields, setOptionalFields] = useState({
		contractId: null,
		orderNumber: null,
		purchaseCost: null,
		// purchaseDate: null,
		// installDate: null,
		// warrantyEndDate: null,
	})
	const [locationMenu, setLocationMenu] = useState([])
	const [usersMenu, setUsersMenu] = useState([])
	const [contractsMenu, setContractsMenu] = useState([])
	const [modelMenu, setModelMenu] = useState([])
	const [locationSearch, setLocationSearch ] = useState({ id: 0, entity_external_platform_id: '' })
	const [modelSearch, setModelSearch] = useState(null)
	const [contractSearch, setContractSearch] = useState(null)
	const [assetSpecs,setAssetSpecs] = useState([])
	const [purchaseDate,setPurchaseDate] = useState({
		date: null,
		error: false
	})
	const [installDate,setInstallDate] = useState({
		date: null,
		error: false
	})
	const [warrantyEndDate,setWarrantyEndDate] = useState({
		date: null,
		error: false
	})
	const [currentCountry, setCurrentCountry] = useState({
		"name": "United States",
		"code": "US",
		"currency": "USD"
	})

	//form validation
	const schema = yup.object({
		allSpan: yup.array().required('Field is required').of(yup.object().required('Fields are required').shape({
			serial_number: yup.string().required('Serial Number is required!'),
			asset_name: yup.string().required('Asset Name is required!'),
		})),
		status: yup.object().nullable().required('Status is required!'),
		model: yup.number().integer().required('Model is required!'),
		location: yup.object().nullable().required('Location is required!'),
	})

	const defaultValues = {
		allSpan: [{
			id: Date.now(),
			serial_number: '',
			asset_tag: '',
			asset_name: '',
		}],
		model: 0,
		imageLocation: '',
		status: null,
		location: null,
		notes: '',
		userId: 0,
		country: { "name": "United States", "code": "US", "currency": "USD" },
	}

	const onSuccess = () => {
		reset(defaultValues)
		resetFormDetails()
		setAllSpan([{
			id: Date.now(),
			serial_number: '',
			asset_tag: '',
			asset_name: ''
		}])
		setImageName('')
		setAssetNote('')
		// setAssetNoteInitial('')
		setAssetNoteText('')
	}

	const onCancel = () => {
		reset(defaultValues)
		resetFormDetails()
		setAllSpan([{
			id: Date.now(),
			serial_number: '',
			asset_tag: '',
			asset_name: '',
		}])
		setImageName('')
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		setAssetNote('')
		setAssetNoteInitial('')
		setAssetNoteText('')
	}

	function onEditorContentChange (content) {
    setAssetNote(content)
  }

	const fetchLocationSuggestions = (value = '') => {
		setLoading(true)
		axios.get(`assets/fetch-location-suggestion?value=${value}`).then(response => {
			let locations = AlphabeticalSorting(response.data.data.locations,'location_name')
			setLoading(false)
			setLocationMenu(locations)
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const fetchStatus = () => {
		// setLoading(true)
		axios.get(`asset-status?type=hardware`).then(response => {
			let status = AlphabeticalSorting(response.data.data,'status_name')
			// setLoading(false)
			setStatusMenu(status.map((item)=>{
				return{
					id: item.id,
					label: item.status_name
				}
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	// const manageLocationSearch = (value) => {
	// 	if(!value) return ''

	// 	let currentLocation = locationMenu.filter(item => item.entity_external_platform_id === value)
	// 	setLocationSearch(currentLocation[0])

	// }

	const fetchUserSuggestionsOnInputChange = useDebounce(value => {
		if(!value.length){
			setUsersMenu([])
			return
		}
		fetchUserSuggestions(value)
	}, 500);

	const fetchUserSuggestions = (search='') => {
		axios.get(`/assets/fetch-users-suggestion?search=${search}`).then(response => {
			let users = AlphabeticalSorting(response.data.data.users,'first_name')
			setUsersMenu(users)
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const manageCurrentUser = (value) => {
		if(!value){
			//unassign a user
			setCurrentUser(null)
			return
		}
		
		let [ name, email ] = value.split('(')

		let currentUser = usersMenu.filter(user => (user.email === email.slice(0,-1)))
		setCurrentUser(currentUser[0])
	}

	const fetchContractSuggestions = () => {
		axios.get('/assets/fetch-contract-suggestion').then(response => {
			let contracts = AlphabeticalSorting(response.data.data.contracts,'entity_external_platform_id')
			setContractsMenu(contracts)
		}).catch(err => {
			console.log('174=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const manageCurrentContract = (value) => {
		// if(!value) return ''
		if(!value){
			//unassign a user
			setContractSearch(null)
			setOptionalFields(state => {return ({...state, contractId: null})})
			return
		} 
		const currentContract = contractsMenu.filter(contract => contract.entity_external_platform_id === value)
		setContractSearch(currentContract[0])
		setOptionalFields(state => {return ({...state, contractId:currentContract[0].id})})
	}

	const fetchModelSuggestions = (value) => {
		axios.get('/assets/fetch-model-suggestion').then(response => {
			let models = AlphabeticalSorting(response.data.data,'model_no')
			setModelMenu(models)
		}).catch((err) => {
			console.log('260=>',err);
			// toast.error('Something went wrong!')
		})
	}

	function fetchAssetSpecs(model_id=null,asset_id=null){
		if(!model_id) return
		let api_url = `/assets/fetch-asset-specs?model_id=${model_id}`
		if(asset_id) api_url =  `/assets/fetch-asset-specs?model_id=${model_id}&asset_id=${asset_id}`

		axios.get(api_url).then(res => {
			setAssetSpecs(res.data.data.response)
		}).catch((err) => {
			console.log('err',err);
			toast.error(err.response.data.message)
		})
	}

	const manageModelSearch = (value) => {
		if(!value) return ''
		let currentModel = modelMenu.filter(item => `${item.model_no ?? ''}-${item.model_name}` === value)
		if(!Boolean(currentModel.length)) return
		if(editFromState?.id){
			fetchAssetSpecs(currentModel[0].id,editFromState?.id)
		}else{
			fetchAssetSpecs(currentModel[0].id)
		}
		setModelSearch(currentModel[0])
	}

	const onChangeImageUpload = (event) => {
		setLoading(true)
		const selectedFile = event.target.files[0]
		if(!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
			setLoading(false)
			return;
		} else {
			let formData = new FormData()
			formData.append("file", selectedFile)
			axios.post('/assets/hardware-asset-image-upload', formData ).then(response => {
				if(response.status === 201) {
					toast.error(response.data.message)
				}
				else{
					setImageName(selectedFile.name)
					setImageLocation(response.data.data.imageLocation)
					setPreviewImageLink(response.data.data.imageLink)
				}
			}).catch(err => {
				toast.error('Something went wrong!')
			}).finally(() => {
				setLoading(false)
			})
		}
	}

	// function formatDate(date){
	// 	let yourDate = new Date(date)
	// 	const offset = yourDate.getTimezoneOffset()
	// 	return (new Date(yourDate.getTime() - (offset*60*1000))).toISOString().split('T')[0]
	// }

	const closeDialog = () => {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const resetFormDetails = () => {
		setLocationSearch({ id: 0, entity_external_platform_id: '' })
		setModelSearch(null)
		setContractSearch(null)
		setCurrentUser(null)
		setAssetNote('')
		setAssetNoteInitial('')
		setAssetNoteText('')
		setUsersMenu([])
		setDeplaoyedDate((prev)=>{
			return {
				...prev,
				date: null
			}
		})
		setRecoveryDate((prev)=>{
			return {
				...prev,
				date: null
			}
		})
		setPurchaseDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setInstallDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setWarrantyEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setAssetSpecs([])
		setOptionalFields({ 
			contractId: null,
			orderNumber: null,
			purchaseCost: null,
			// purchaseDate: null,			
			// installDate: null,
			// warrantyEndDate: null,
			// eolDate: null,
			// eoslDate: null
		})
		setCurrentCountry({
			"name": "United States",
			"code": "US",
			"currency": "USD"
		})
	}

  const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
    	defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(!editFromState) return
		const getCountryDetails = (countries.filter(country => country.code === editFromState.country_code))[0]
		handleCurrentCountry(getCountryDetails.code)
		
		setAllSpan([{
			id: Date.now(),
			serial_number: editFromState.serial_no,
			asset_name: editFromState.name,
			asset_tag: editFromState?.cloneItem ? '' : (editFromState.asset_tag ?? '')
		}])
		//status
		setValue('status',{id:editFromState.status_value.id,label:editFromState.status_value.status_name})

		//model
		const current_model = modelMenu.find((item)=> item.id == editFromState.model_id)
		manageModelSearch(`${current_model.model_no}-${current_model.model_name}`)

		//loacation
		setValue('location',editFromState.shipping_location_details)
		//notes
		if(editFromState.notes != 'No Notes' && editFromState.note_style=='No Note Style'){
			setAssetNote(editFromState.notes)
		}
		else if(editFromState.note_style!='No Note Style' && editFromState.notes == 'No Notes'){
			setAssetNote(editFromState.note_style ?? '')
		} else if(!editFromState.note_style && editFromState.notes){
			setAssetNote(editFromState.notes ?? '')
		}
		else if(editFromState.note_style && !editFromState.notes){
			setAssetNote(editFromState.note_style ?? '')
		}else{
			setAssetNote(editFromState.note_style ?? '')
		}

		//image
		setImageName(editFromState.image_name)
		setPreviewImageLink(editFromState?.preview_image_link ?? '')
		//user
		if(editFromState.assigned_user_details){
			fetchUserSuggestions(editFromState.assigned_user_details.email)	
		}

		setDeplaoyedDate((prev)=>{
			return{
				...prev,
				date: editFromState.deployed_date
			}
		})
		setRecoveryDate((prev)=>{
			return{
				...prev,
				date: editFromState.recovery_date
			}
		})
		//contract
		manageCurrentContract(editFromState.contract_header?.entity_external_platform_id ?? '')
		setOptionalFields(state => {return ({
			...state, 
			orderNumber: editFromState.order_number,
			purchaseCost: editFromState.purchase_cost,
			// purchaseDate: editFromState.original_info.purchase_date ? (editFromState.original_info.purchase_date) : null,
			// installDate: editFromState.original_info.install_date ? (editFromState.original_info.install_date) : null,
			// warrantyEndDate: editFromState.original_info.warranty_end_date ? (editFromState.original_info.warranty_end_date) : null
		})})
		setPurchaseDate((prev) => {
			return {
				...prev,
				date: editFromState.purchase_date
			}
		})
		setInstallDate((prev) => {
			return {
				...prev,
				date: editFromState.install_date
			}
		})
		setAssetNoteText(editFromState.notes == 'No Notes'?'':editFromState.notes)
		setWarrantyEndDate((prev) => {
			return {
				...prev,
				date: editFromState.warranty_end_date
			}
		})
	},[editFromState])

	const { errors } = formState

	const addNewSpan = () => {
		let newSpan = {id: Date.now(), serial_number: '', asset_tag: '', asset_name: ''}
		setAllSpan((allSpan) => [...allSpan, newSpan])
	}

	const deleteSpan = (index) => {
		setAllSpan(allSpan.filter(span => allSpan[index] !== span )  )
	}

	const handleSpanInput = (index, value, propName) => {
		setAllSpan((spans) => {
		spans[index][propName] = value
		return [...spans]
		})
	}

	function handleSpecsValueChange(index,value,propName){
		assetSpecs[index][propName] = value
	}

	const manageFormData = (formData) => {
		// if(imageName === '') {
		// 	toast.error('Can not proceed without uploading asset image!')
		// }
		// else {
		let validationObj = {}
		let validateAssetTag = {}

		for(let span of allSpan){
			let temp_serial_no = span.serial_number.toLocaleLowerCase()
			if(!validationObj[temp_serial_no]){
				validationObj[temp_serial_no] = true
			}else{
				toast.error(`Serial number ${span.serial_number} has a duplicate value`)
				return
			}

			let tamp_asset_tag = span.asset_tag.toLocaleLowerCase()
			if(span.asset_tag !== '' && !validateAssetTag[tamp_asset_tag]){
				validateAssetTag[tamp_asset_tag] = true
			}else if(validateAssetTag[tamp_asset_tag]){
				toast.error(`Asset tag ${span.asset_tag} has a duplicate value`)
				return
			}
		}

		if(deployedDate.error || recoveryDate.error){
			return
		}

		if(purchaseDate.error || installDate.error || warrantyEndDate.error){
			return
		}

		let payload = {
			allSpan,
			status_id: Number(formData.status.id),
			model_id: modelSearch.id,
			shipping_location: formData.location,
			notes: assetNoteText,
			note_style: assetNote,
			users_id: currentUser?.id ?? null,
			deployed_date: deployedDate.date,
			recovery_date: recoveryDate.date,
			image_location: imageLocation,
			contract_headers_id: Boolean(optionalFields.contractId) ? optionalFields.contractId : null,
			purchase_cost: Boolean(optionalFields.purchaseCost) ? optionalFields.purchaseCost.replaceAll(',','') : null,
			// purchase_date: Boolean(optionalFields.purchaseDate) ? optionalFields.purchaseDate : null,
			// install_date: Boolean(optionalFields.installDate) ? optionalFields.installDate : null,
			// warranty_end_date: Boolean(optionalFields.warrantyEndDate) ? optionalFields.warrantyEndDate : null,
			purchase_date: purchaseDate.date ?? null,
			install_date: installDate.date ?? null,
			warranty_end_date: warrantyEndDate.date ?? null,
			eol_date: null,
			eosl_date: null,
			order_number: Boolean(optionalFields.orderNumber) ? optionalFields.orderNumber : null,
			image_name: imageName,
			country_code: formData.country.code
		}
		if(Boolean(editFromState)) payload.id = editFromState.id
		if(assetSpecs.length){
			payload.asset_spec = assetSpecs.map((item)=>{
				return{
					attribute_id: item.attribute.id,
					attribute_value: item.attribute_value
				}
			})
		} 

		let api_url 
		// = `/assets/${Boolean(editFromState?.cloneItem) ? 'update-hardware-asset-request' : 'hardware-asset-request'}`
		if(editFromState && !editFromState?.cloneItem){
			api_url = `/assets/update-hardware-asset-request`
		}else{
			api_url = `/assets/hardware-asset-request`
		}
		axios.post(api_url, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				onSuccess()
				closeDialog()
				editFromState && !editFromState?.cloneItem 
					?	toast.success('Asset updated successfully.')
					:	toast.success('Asset added successfully.')
				props.fetchAllHardwareAssets()
			}
		}).catch(err => {
			console.log('212=>',err);
			toast.error(err.response.data.message)
		})
		// }		
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	/*-----------------------User Dialog Func------------------*/

	const [ openUserDialogState, setOpenUserDialogState ] = useState(false)

	const handleClickOpenUserDialog = () => {
		setOpenUserDialogState(true)
	};

	const handleCloseUserDialog = (new_user=null) => {
		setOpenUserDialogState(false)

		//Set Quick added USER value into user field
		if(new_user){
			setCurrentUser({
				email: new_user.email,
				first_name: new_user.first_name,
				id: new_user.id,
				last_name: new_user.last_name,
				phone: new_user.phone,
			})
		}
	};

	/*----------------------------------------------------Location Dialog Func-------------------------------------------- */
	const [createNewShippingLocation,setCreateNewShippingLocation] = useState(false)

	const handleCloseLocationDialog = (new_shipping_location=null) => {
		setCreateNewShippingLocation(false)

		//Set Quick added LOCATION value into location field
		if(new_shipping_location){
			setValue('location',{
				entity_external_platform_id: new_shipping_location.entity_external_platform_id,
				id: new_shipping_location.id,
				location_name: new_shipping_location.location_name,
				address1: new_shipping_location.address1 || '',
				address2: new_shipping_location.address2 || '',
				city: new_shipping_location.city || '',
				state: new_shipping_location.state || '',
				country: new_shipping_location.country || '',
			})
		}
	}

	const handleOpenLocationDialog = () => {
		setCreateNewShippingLocation(true)
	}

	/*---------------------------------Model Dialog Func----------------------------*/

	const [ openModelDialogState, setOpenModelDialogState ] = useState(false)

	const handleClickOpen = () => {
		setOpenModelDialogState(true)
	};

	const handleClose = (new_model=null) => {
		setOpenModelDialogState(false)

		//Set Quick added MODEL value into model field
		if(new_model){
			setModelSearch({
				entity_external_platform_id: new_model.entity_external_platform_id,
				model_name: new_model.model_name,
				model_no: new_model.model_no,
				id: new_model.id
			})
			fetchAssetSpecs(new_model.id)
		}
	};

	const handleEditorChange = (content, editor) => {
		const htmlContent = editor.getContent();
    const textContent = new DOMParser().parseFromString(htmlContent, 'text/html').body.textContent;

    setAssetNoteText(textContent)
  
		setAssetNote(content)

  };

	/**---------------------------------- Status Dialog func---------- */
	const [createNewStatus,setCreateNewStatus] = useState(false)

	const handleStatusDialogClose = (new_status=null) => {
		setCreateNewStatus(false)

		//Set Quick added STATUS value into status field
		if(new_status)setValue('status',{id:new_status.id,label:new_status.status_name})
	}

	const handleStatusDialogOpen = () => {
		setCreateNewStatus(true)
	}

	useEffect(() => {
		fetchLocationSuggestions()
		// fetchUserSuggestions()
		fetchContractSuggestions()
		fetchModelSuggestions()
		fetchStatus()
	}, [])

	useEffect(() => {
		setValue("allSpan", allSpan)
	}, [allSpan])

	useEffect(()=>{
		if(editFromState){
			manageCurrentUser(editFromState.assigned_user_details 
				? `${editFromState.assigned_user_details.first_name} ${editFromState.assigned_user_details.last_name} (${editFromState.assigned_user_details.email})`
				: null
			)
		}
	},[usersMenu])

	function handleCloseDialog(new_contract=null){
		if(new_contract){
			setContractSearch({
				id: new_contract.id,
				entity_external_platform_id: new_contract.entity_external_platform_id,
			})
			setOptionalFields(state => {return ({...state, contractId:new_contract.id})})
		}
		setQuickAddContract(false)
		fetchContractSuggestions()
	}

	const handleCurrentCountry = (countryCode) => {
		const current = (countries.filter(country => country.code === countryCode))[0]
		setCurrentCountry(current)
	}

	return (
		<div>
			<Modal
				open={openImagePreview}
				className={classes.modal}
			>
				<div style={{
					height: 500,
					width: 500,
					backgroundColor: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems:'center',
					borderRadius: '13px',
					display: 'flex',
					flexDirection: 'column'
				}}>
					<IconButton 
						style={{left: '203px',bottom: '19px'}} 
						onClick={()=>{
							setOpenImagePreview(false)
						}}>
						<Icon>close</Icon>
					</IconButton>
					<img style={{height: '400px',width: '400px'}} src={previewImageLink} alt='' ></img>
				</div>
			</Modal>

			<QuickAddContract
				open={quickAddContract}
				handleCloseDialog={handleCloseDialog}
			/>

			<ModelDialog 
				fetchModelSuggestions={fetchModelSuggestions}
				openModelDialogState={openModelDialogState}
				handleClose={handleClose}
			/>

			<LocationDialog
				fetchLocationSuggestions={fetchLocationSuggestions}
				createNewShippingLocation={createNewShippingLocation}
				handleCloseLocationDialog={handleCloseLocationDialog}
			/>

			<UserDialog
				open={openUserDialogState}
				handleCloseDialog={handleCloseUserDialog}
				refreshList={fetchUserSuggestions}
			/>

			<StatusDialog
				openStatusDialogState={createNewStatus}
				handleCloseDialog={handleStatusDialogClose}
				fetchStatus={fetchStatus}
			/>

			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{editFromState ? (editFromState?.cloneItem ? 'Clone ' : 'Update ') : 'Add '} 
						Asset
					</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*----------------------------------------------------Item Information---------------------------------------------------------------- */}
					
				<div className={classes.subSection}>
					<div style={{ width: '100%' }}>
						<Typography style={{fontSize:'16px'}}>	Required Information *</Typography>
						<Divider style={{marginBottom: '10px'}}/>
					</div>
					{ allSpan.map((span, index) => {
						return ( 
							<div style={{ display: 'flex', justifyContent:'center', width:'100%', border:'1px solid #ddd', borderRadius: '5px', padding: '10px', marginBottom: '10px' }} key={span.id}>
								<div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
									<div style={{ display: 'flex', justifyContent:'center', marginBottom: '10px', width:'100%' }}>
										{/* Serial Number */}
										<div style={{ marginRight: '10px', width: '100%' }}>
											<Controller
												name='allSpan' 
												control={control}
												render={( { field: { onChange, ...field } } ) => (
													<TextField 
														{...field}
														style={{background: 'white'}}
														value={span.serial_number}
														onChange={event => {
															handleSpanInput(index, event.target.value, "serial_number")
														}}
														variant='outlined'
														label='Serial Number' 
														autoFocus
														fullWidth
														required
														// error={!!errors.allSpan?.[index]?.['serial_number']}
														// helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
														FormHelperTextProps={{
															style: { 
																margin : 0, 
																backgroundColor: '#f4f4f4',
																width: '100%',
																paddingTop: '2px'
															}
														}}
													/>
												)}
											/>
										</div>
										{/* Asset Name */}
										<div style={{ width: '100%' }}>
											<Controller
												name='allSpan' 
												control={control}
												render={( { field: { onChange, ...field } } ) => (
													<TextField 
														{...field}
														style={{background: 'white'}}
														value={span.asset_name}
														onChange={event => {
															handleSpanInput(index, event.target.value, "asset_name")
														}}
														variant='outlined'
														label='Asset Name' 
														fullWidth
														required
														// error={!!errors.allSpan?.[index]?.['asset_name']}
														// helperText={errors?.allSpan?.[index]?.['asset_name']?.message}
														FormHelperTextProps={{
															style: { 
																margin : 0, 
																backgroundColor: '#f4f4f4',
																width: '100%',
																paddingTop: '2px'
															}
														}}
													/>
												) }
											/>
										</div>
									</div>
												
									<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
										{/* Asset Tag */}
										<div style={{marginRight: '5px', width: '100%'}}>
											<Controller
												name='allSpan' 
												control={control}
												render={( { field: { onChange, ...field } } ) => (
													<TextField 
														{...field}
														style={{background: 'white'}}
														onChange={(event) => {
															handleSpanInput(index, event.target.value, "asset_tag")
														}}
														value={span.asset_tag}
														variant='outlined'
														label='Asset Tag' 
														fullWidth
													/>
												) }
											/>
										</div>
										{/* Add more asset tag */}
										{ !Boolean(editFromState) && 
											(index === 0 ? 
												<Tooltip title='Add More' placement='top'>
													<IconButton onClick={() => addNewSpan()}>
														<Icon>add_circle_outline</Icon>
													</IconButton>
												</Tooltip>
												:
												<Tooltip title='Remove Span' placement='top'>
													<IconButton onClick={() => deleteSpan(index)} >
														<Icon>cancel_circle</Icon>
													</IconButton>
												</Tooltip>
											)
										}
									</div>									
								</div>
							</div>
						)
					})} 
					<div style={{ display: 'flex', justifyContent:'center' }}>
						{/* status */}
						<div style={{display: 'flex', marginBottom: '10px', marginRight: '10px', width: '100%' }}>
							<Controller
								name="status"
								className="mb-16"
								control={control}
								render={({ field: { ref, ...field }, fieldState: { error } }) => (
									<>
										<Autocomplete
											{...field} 
											style={{ width: '100%' }}
											options={statusMenu}
											disablePortal
											onChange={(event, value) => field.onChange(value)}
											PaperComponent={({ children }) => (
												<Paper style={{ marginTop:'50px' }}>{children}
													{ createAssetStatusPermission && <Button 
														style={{width:'100%'}} 
														variant="outlined" 
														color="primary" 
														onMouseDown={() => handleStatusDialogOpen()}
													>Add Status</Button>}
												</Paper>
											)}
											getOptionLabel={option => option.label ?? ''}
											renderInput={(params) => {
												return (
													<div ref={params.InputProps.ref}>
														<TextField
															name="status"
															style={{background: 'white'}}
															{...params}
															label="Status"
															required
															// error={!!error}
															// helperText={error?.message}
															FormHelperTextProps={{
																style: { 
																	margin : 0, 
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															variant="outlined"
															fullWidth
														/>
													</div>
												)
											}}
										/>
									</>
								)}
							/>
						</div>
						{/* Model */}
						<div style={{display: 'flex', marginBottom: '10px', width: '100%'}}>
							<Controller
								name="model"
								className="mb-16"
								control={control}
								render={({ field }) => (
									<>
										<Autocomplete 
											style={{ width: '100%' }}
											options={modelMenu}
											disablePortal
											disableClearable={true}
											value={modelSearch}
											defaultValue={modelSearch}
											onInputChange={(event) => {
												if(event) {
													manageModelSearch(event.target.innerText)
												}
											}}
											PaperComponent={({ children }) => (
												<Paper style={{ marginTop:'50px' }}>{children}
													{createModelPermission 
														&& <Button 
															style={{width:'100%'}} 
															variant="outlined" 
															color="primary" 
															onMouseDown={() => handleClickOpen()}
														>
															Add Model
														</Button>
													}
												</Paper>
											)}
											getOptionLabel={option => `${option.model_no ?? ''}-${option.model_name}` }
											renderInput={(params) => {
												return (
													<div ref={params.InputProps.ref}>
														<TextField
															style={{background: 'white'}}
															{...params}
															label="Model"
															variant="outlined"
															fullWidth
															required
															// error={!!errors.model}
															// helperText={errors?.model?.message}
															FormHelperTextProps={{
																style: { 
																	margin : 0, 
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
														/>
													</div>
												)
											}}
										/>
										
										{/* <div style={{ width:'40%', padding: '10px', border: '1px solid #aaa', borderLeft:'0', borderRadius: '0 4px 4px 0'}}>
											<Button 
											variant="outlined" 
											color='primary' 
											size='small' 
											style={{  borderRadius: '4px' }}
											onClick={() => handleClickOpen()}
											>
												New
											</Button>
										</div> */}
									</>
								)}
							/>
						</div>
					</div>
					{/* Location */}
					<div style={{display: 'flex', marginBottom: '10px'}}>
						<Controller
							name="location"
							className="mb-16"
							control={control}
							render={({ field: { ref, ...field }, fieldState: { error } }) => (
								<>
									<Autocomplete
										{...field}
										style={{ width: '100%' }}
										options={locationMenu}
										disablePortal
										onChange={(event, value) => field.onChange(value)}
										PaperComponent={({ children }) => (
											<Paper style={{ marginTop:'50px' }}>{children}
												{createLocationPermission && <Button 
													style={{width:'100%'}} 
													variant="outlined" 
													color="primary" 
													onMouseDown={() => handleOpenLocationDialog()}
												>Add Location</Button>}
											</Paper>
										)}
										getOptionLabel={
											option => {
												return generateProperLocationString({
													location_name: option?.location_name ?? '',
													address1: option?.address1 ?? '',
													address2: option?.address2 ?? '',
													city: option?.city ?? '',
													state: option?.state ?? '',
													country: option?.country ?? ''
												})
											} 
										}
										renderInput={(params) => {
											return (
											<div ref={params.InputProps.ref}>
												<TextField
													{...params}
													style={{background: 'white'}}
													label="Location"
													required
													// error={!!errors.location}
													// helperText={errors?.location?.message}
													FormHelperTextProps={{
														style: { 
															margin : 0, 
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													variant="outlined"
													fullWidth
												/>
											</div>
											)
										}}
									/>
									{/* <div style={{ width:'18%', padding: '10px', border: '1px solid #aaa', borderLeft:'0', borderRadius: '0 4px 4px 0'}}>
										<Button variant='outlined' color='primary' size='small' style={{  borderRadius: '4px'}} onClick={() => handleOpenLocationDialog()}>New</Button>
									</div> */}
								</>
							)}
						/>
					</div>
					{/* Notes */}							
					<div style={{marginBottom: '10px'}}>
					<Editor
                    editorContent={assetNote}
                    onEditorContentChange={onEditorContentChange}
										setAssetNoteText = { setAssetNoteText }
                  />
					</div>
					{/* Image upload */}
					<div style={{display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>
						<Typography variant='subtitle1' style={{marginBottom: '10px'}}>Upload Image</Typography>
						<div style={{display:'flex', alignItems: 'center'}}>
							<input
								accept="image/*"
								id="upload"
								type="file"
								onChange = {(event) => onChangeImageUpload(event)}
								hidden
							/>
							<label htmlFor='upload' className={classes.uploadImageButton}>
								Select File
							</label>
							<div style={{marginLeft: '10px'}}>
								{ loading ? 
									<>
										<div className={classes.loader}>
											<CircularProgress />
										</div>
									</> : <Typography 
											onClick={()=>{
												if(imageName == '' || imageName == 'No Image Name') return;
												setOpenImagePreview(true)
											}}
											style={{color: 'black',textDecoration:'none',cursor: 'pointer'}}
										>
											{imageName}
										</Typography>
								}
							</div>
						</div>
					</div>
					
					<Typography>{'Accepts jpg, jpeg, png, gif. 2Mb max (rec. 700x430px)'}</Typography>

					{ 
						assetSpecs.map((assetSpec, index) => {
							return ( 
								<div style={{ display: 'flex', justifyContent:'center', width:'100%', border:'1px solid #ddd', borderRadius: '5px', padding: '10px', marginTop: '15px' ,marginBottom: '10px' }} key={index}>
									<div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
										<div style={{ display: 'flex', justifyContent:'center', marginBottom: '10px', width:'100%' }}>

											<div style={{ marginRight: '10px', width: '100%' }}>
												<TextField 
													label="Attribute"
													variant="outlined"
													style={{background: 'white'}}
													fullWidth 
													disabled
													value={assetSpec.attribute.attribute_name}
													onChange={() => {}}>
												</TextField>
												
											</div>

											<div style={{ width: '100%' }}>
												<TextField 
													label="Value"
													variant="outlined"
													style={{background: 'white'}}
													fullWidth
													defaultValue={assetSpec.attribute_value}
													onChange={(e) => handleSpecsValueChange(index,e.target.value,'attribute_value')}>
												</TextField>
											</div>
										</div>
									</div>
								</div>
							)
						})
					} 
				</div>

				{/*----------------------------------------------------User Information---------------------------------------------------------------- */}

				<Accordion className={classes.subSection}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
					>

						<div style={{width:'100%'}}>
							<Typography variant='h6'>User Information</Typography>
							{/* <Divider style={{marginBottom: '10px'}}/> */}
						</div> 
					</AccordionSummary>

					<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{display: 'flex', marginBottom: '10px'}}>
							<Controller 
								name='userId'
								control={control}
								render={({ field }) => (
									<>
										<Autocomplete 
											style={{ width: '100%' }}
											options={usersMenu}
											// disablePortal
											value={currentUser}
											defaultValue={currentUser}
											// onInputChange={(event) => {
											// 	if(event && event.target) {
											// 		console.log('1285: ',);
											// 		fetchUserSuggestionsOnInputChange(event)
											// 		// manageCurrentUser(event.target.innerText)
											// 	}
											// }}
											onChange={(event)=>{
												manageCurrentUser(event.target.innerText)
											}}										
											PaperComponent={({ children }) => (
												<Paper style={{ marginTop:'0px'}}>{children}
													{createUserPermission && <Button 
														style={{width:'100%'}} 
														variant="outlined" 
														color="primary" 
														onMouseDown={() => handleClickOpenUserDialog()}
													>Add User</Button>}
												</Paper>
											)}
											getOptionLabel={option => `${option.first_name ?? ''} ${option.last_name ?? ''} (${option.email})` }
											renderInput={params => {
												return (
													<div ref={params.InputProps.ref}>
														<TextField
															{...params}
															style={{background: 'white'}}
															// label="User Name"
															placeholder='Start typing user name'
															error={!!errors.userId}
															helperText={errors?.userId?.message}
															FormHelperTextProps={{
																style: {marginLeft : 0}
															}}
															onChange={(event) => {
																if(event && event.target) {
																	fetchUserSuggestionsOnInputChange(event.target.value)													
																}
															}}
															variant="outlined"
															fullWidth
														/>
													</div>
												)
											}}
										/>
									</>
								)}
							/>
						</div>

						<div style={{ display: 'flex', justifyContent:'center' }}>

							<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
								<TextField 
									variant='outlined' 
									style={{background: 'white'}}
									// value={userPhone}
									value={currentUser?.phone ?? ''}
									label='Phone Number' 
									fullWidth
									disabled
								/>
							</div> 

							<div style={{marginBottom: '10px', width:'100%'}}>
								<TextField 
									variant='outlined' 
									label='Email Address' 
									fullWidth 
									style={{background: 'white'}}
									disabled
									value={currentUser?.email ?? ''}
								/>
							</div>
						</div>

						<div style={{ display: 'flex', justifyContent:'center' }}>

							<div className='cstm-date' style={{marginBottom: '10px', width:'100%',position: 'relative'}}>							
								{/* <CustomDatePicker
									required={false}
									state={deployedDate}
									setState={setDeplaoyedDate}							
									label='Deployed Date'													
								/>				 */}
								<CustomDatePickerV2 
									state={deployedDate}
									setState={setDeplaoyedDate}
									required={false}
									label='Deployed Date'
								/>
							</div> 

							<div className='cstm-date' style={{marginLeft: '10px', width:'100%'}}>			
								{/* <CustomDatePicker
									required={false}
									state={recoveryDate}
									setState={setRecoveryDate}
									label='Recovery Date'
								/> */}
								<CustomDatePickerV2 
									state={recoveryDate}
									setState={setRecoveryDate}
									required={false}
									label='Recovery Date'
								/>
							</div>

						</div>

					</AccordionDetails>
				</Accordion>
						
				{/*----------------------------------------------------Optional Information---------------------------------------------------------------- */}

				<Accordion className={classes.subSection}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<div style={{width: '100%'}}>
							<Typography variant='h6'>Contract Information</Typography>
							{/* <Divider style={{marginBottom: '10px'}}/> */}
						</div>
					</AccordionSummary>

					<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ display: 'flex', justifyContent:'center' }}>

							<div style={{display: 'flex', marginBottom: '10px', marginRight: '10px', width:'100%' }}>
								<Autocomplete 
									style={{ width: '100%' }}
									options={contractsMenu}
									disablePortal
									value={contractSearch}
									defaultValue={contractSearch}
									onInputChange={event => {
										if(event) {
											manageCurrentContract(event.target.innerText)
										}
									}}
									PaperComponent={({ children }) => (
									<Paper style={{ marginTop:'50px' }}>{children}
										{createContractPermission && <Button 
											style={{width:'100%'}} 
											variant="outlined" 
											color="primary" 
											onMouseDown={() => {
												setQuickAddContract(true)
											}}
										>Add Contract</Button>}
									</Paper>
									)}
									getOptionLabel={option => option.entity_external_platform_id ?? ''}
									renderInput={params => {
									return (
										<div ref={params.InputProps.ref}>
											<TextField 
												{...params}
												label='Contract Number'
												variant='outlined'
												fullWidth
												style={{background: 'white'}}
											/>
										</div>
									)
									}}
								/>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent:'center' }}>
							<div style={{marginBottom: '10px', width: '30%',paddingRight: '8px' }}>
								<Controller
									name="country"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => (
										<>
											<Autocomplete
												{...field}
												style={{ width: '100%' }}
												options={countries}
												disablePortal
												value={currentCountry}
												getOptionLabel={option => option.currency ?? ''}
												onChange={(event, value) => {
													field.onChange(value)
													if (value) handleCurrentCountry(value.code)
												}}
												PaperComponent={({ children }) => (
													<Paper style={{ marginTop: '50px' }}>{children}</Paper>
												)}
												renderOption={(option) => {
													return (
														<div className='flex items-center'>
															<img
																loading="lazy"
																// width={"20px"}
																className='mr-5 py-2'
																src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
																srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
																alt=""
															/>
															<span className='pt-2'>{option.currency}</span>
														</div>
													)
												}}
												renderInput={params => {
													return (
														<div ref={params.InputProps.ref}>
															<TextField
																required
																{...params}
																name="country"
																label='Currency'
																variant='outlined'
																fullWidth
																error={!!error}
																helperText={error?.message}
																inputRef={ref}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																style={{ width: '100%', backgroundColor: '#fff' }}
																InputProps={{
																	readOnly: true,
																	startAdornment:
																		<InputAdornment style={{ marginRight: '10px', border: '1px solid black', height: '50%', width: '50%' }}>
																			<img
																				loading="lazy"
																				width="40"
																				src={`https://flagcdn.com/w20/${currentCountry.code.toLowerCase()}.png`}
																				srcSet={`https://flagcdn.com/w40/${currentCountry.code.toLowerCase()}.png 2x`}
																				alt=""
																			/>
																		</InputAdornment>
																}}
															/>
														</div>
													)
												}}
											/>
										</>
									)}
								/>
							</div>
							<div style={{marginBottom: '10px', width: '100%' }}>
								<TextField
									variant='outlined'
									label='Purchase Price (USD)' 
									fullWidth
									style={{background: 'white'}}
									value={optionalFields.purchaseCost}
									onBlur={()=>{
										setOptionalFields(prevState => {
											return { 
												...prevState, 
												purchaseCost: formatPriceFieldOnBlur(optionalFields.purchaseCost)
											}
										})										
									}}
									onKeyPress={(event) => {
										if (!/[0-9,.]+/.test(event.key)) {
											event.preventDefault();
										}
									}}
									onChange={event => {
										setOptionalFields(prevState => {
											return {
												...prevState,
												purchaseCost: event.target.value
											}
										})
									}}
									InputProps={{
										startAdornment: 
											<InputAdornment>
												<Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
											</InputAdornment>
									}}
								/>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent:'center' }}>

							<div className='cstm-date' style={{marginBottom: '10px', marginRight: '10px', width:'100%'}}>
								{/* <TextField
									variant='outlined'
									label="Purchase Date"
									type='date'
									style={{background: 'white'}}
									InputLabelProps={{ shrink: true }}
									defaultValue={optionalFields.purchaseDate}
									onChange={(event) => setOptionalFields(prevState => { return { ...prevState, purchaseDate: event.target.value } })}
									fullWidth
								/> */}
								{/* <CustomDatePicker
									state={purchaseDate}
									setState={setPurchaseDate}
									required={false}
									label='Purchase Date'							
								/> */}
								<CustomDatePickerV2 
									state={purchaseDate}
									setState={setPurchaseDate}
									required={false}
									label='Purchase Date'
								/>
							</div>

							<div className='cstm-date' style={{ marginBottom: '10px', width:'100%' }}>
								{/* <TextField
									variant='outlined'
									type='date'
									InputLabelProps={{ shrink: true }}
									label="Install Date"
									style={{background: 'white'}}
									defaultValue={optionalFields.installDate}
									onChange={(event) => setOptionalFields(prevState => { return { ...prevState, installDate: event.target.value } })}
									fullWidth
								/> */}
								{/* <CustomDatePicker
									state={installDate}
									setState={setInstallDate}
									required={false}
									label='Install Date'									
								/> */}
								<CustomDatePickerV2 
									state={installDate}
									setState={setInstallDate}
									required={false}
									label='Install Date'
								/>
							</div>

						</div>

						<div style={{ display: 'flex', justifyContent:'center' }}>

							<div className='cstm-date' style={{marginBottom: '10px', marginRight: '10px', width: '60%'}}>
								{/* <TextField
									variant='outlined'
									type='date'
									label="Warranty End Date"
									InputLabelProps={{ shrink: true }}
									defaultValue={optionalFields.warrantyEndDate}
									onChange={(event) => setOptionalFields(prevState => { return { ...prevState, warrantyEndDate: event.target.value } })}
									fullWidth
									style={{background: 'white'}}
								/> */}
								{/* <CustomDatePicker
									state={warrantyEndDate}
									setState={setWarrantyEndDate}
									required={false}
									label='Warranty End Date'							
								/> */}
								<CustomDatePickerV2 
									state={warrantyEndDate}
									setState={setWarrantyEndDate}
									required={false}
									label='Warranty End Date'
								/>
							</div>

						{/* </div>

						<div style={{ display: 'flex', justifyContent:'center' }}> */}

							<div style={{marginBottom: '10px', marginleft: '10px', width:'60%'}}>
								<TextField 
									variant='outlined' 
									label='Order Number' 
									fullWidth
									value={optionalFields.orderNumber}
									onChange={event => setOptionalFields(prevState => { return { ...prevState, orderNumber: event.target.value } })}
									style={{background: 'white'}}
								/>
							</div>

						</div>

					</AccordionDetails>

				</Accordion>

				<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
					<Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? (editFromState?.cloneItem ? 'Clone' : 'Update') : 'Add'}</Button>
					<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
				</div>
						
				</form>        
			</Drawer>
		</div>
	)
}

export default AddAssetFormDialog
