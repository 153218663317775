import _ from '@lodash';
import TablePagination from '@fuse/components/general/TablePagination';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListView from './listView';
import { useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
  	const classes = useStyles();	
	const [loading, setLoading] = useState(false);
	
	if(loading){
		return (<FuseLoading/>)
	}

	// if (props.viewAssetPermission != 'all' && props.viewAssetPermission?.length == 0) {
	// 	return (
	// 		<div>
	// 			<Card>
	// 				<CardContent>You don't have permission to view any Assets at this time. Please contact your Systems Administrator for access.</CardContent>
	// 			</Card>
	// 		</div>
	// 	)
	// }

	return (
		<div className="w-full flex flex-col">
			<SwitchView
				viewAssetPermission = { props.viewAssetPermission }
				createAssetPermission = { props.createAssetPermission }
				updateAssetPermission = { props.updateAssetPermission }
				deleteAssetPermission = { props.deleteAssetPermission }
			/>
			<div className = "p-10">
				{/* <TablePagination
					className="flex-shrink-0 border-t-1"
					component="div"
					count={props.endUsersAccounts.length}
					rowsPerPage={rowsPerPage !== props.endUsersAccounts.length ? rowsPerPage : 'all'}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
			</div>
		</div>
	);

}

function SwitchView({
	viewAssetPermission, createAssetPermission,
	updateAssetPermission, deleteAssetPermission,
}) {
	
	return(
		<ListView
			viewAssetPermission = { viewAssetPermission }
			createAssetPermission = { createAssetPermission }
			updateAssetPermission = { updateAssetPermission }
			deleteAssetPermission = { deleteAssetPermission }
		/>
	)
}

export default withRouter(EndUsersAccountsTable)
