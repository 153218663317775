import React from 'react'
import { Button, Icon, IconButton, Modal, TextField } from '@material-ui/core'

function SLAQuickAdd({
    slaField,
    modal,
    setNewSla,
    setSlaField,
    addNewSla,
    newSla
}) {
  return (
    <Modal
        open={slaField}
        className={modal}
    >
        <div style={{width:700,backgroundColor: 'white',borderRadius:9,display:'flex',alignItems:'center',flexDirection:'column',padding:'20px'}}>
            {/* <h1 style={{display:'flex',alignSelf:'flex-start',paddingLeft:'25px'}}>Create Contract Type</h1> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', width: "100%" }}>
                <h1>Create Service Level Agreement</h1>
                <IconButton onClick={()=>{
                    setNewSla('')
                    setSlaField(false)
                }}>
                    <Icon>close</Icon>
                </IconButton>
            </div>

            {/* <p style={{marginBottom: '10px', width: '100%'}}>Submit the below form to add Service Level Agreement.</p> */}

            <form onSubmit={addNewSla} className='w-full'>
                <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px', width:'100%', marginBottom: '10px'}}>
                    <TextField
                        required
                        variant='outlined'
                        label="Service Level Agreement"
                        fullWidth
                        value={newSla}
                        style={{ width: '100%', backgroundColor: '#fff' }}
                        onChange={(e)=>{
                            setNewSla(e.target.value)
                        }}
                        // error={errorSla}
                        // helperText={errorSla ? errorSla : null}
                        FormHelperTextProps={{
                            style: { 
                                margin : 0, 
                                backgroundColor: '#f4f4f4',
                                width: '100%',
                                paddingTop: '2px'
                            }
                        }}
                    />
                </div>
                <div style={{display:'flex',gap:10, width:'100%'}}>
                    <Button 
                        variant='contained' type='submit' color='primary' size='small' style={{ borderRadius: '4px' }}
                    >Save</Button>
                    <Button 
                        variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                        onClick={()=>{
                            setSlaField(false)
                            setNewSla('')
                        }}
                    >Cancel</Button>
                </div>
            </form>
        </div>
    </Modal>
  )
}

export default SLAQuickAdd
