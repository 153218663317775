exports.exportToCSV = (dataArr, fileName) => {

  // Convert the data to CSV format
  const csvContent = dataArr.map(row => row.map(cell => `"${String(cell).replace(/"/g, '""')}"`).join(',')).join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv' });

  // Create a download link and trigger a click to download the file
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}