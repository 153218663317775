import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './registerSlice';
import user from './userSlice';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import pagesAccessSlice from './pagesAccessSlice';

const userPersistConfig = {
	key: 'user',
	storage: sessionStorage
}
const authReducers = combineReducers({
	user: persistReducer(userPersistConfig,user),
	login,
	register,
	pagesAccessByUser: persistReducer({
		key: 'pagesAccessByUser',
		storage: sessionStorage
	},pagesAccessSlice)
});

export default authReducers;
