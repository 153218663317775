import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch, useSelector } from 'react-redux';
import {Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import { usePermission } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import AddServiceRequestCategory from './addServiceRequestCategory';
import QuickAddServiceCategory from 'app/fuse-layouts/shared-components/QuickAddServiceCategory';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer';



const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const { hasPermission } = usePermission()
	const viewServiceRequestCategoryPermission = hasPermission('view-service_request_category')
	const createServiceRequestCategoryPermission = hasPermission('add-service_request_category')
	const updateServiceRequestCategoryPermission = hasPermission('update-service_request_category')
	const deleteServiceRequestCategoryPermission = hasPermission('delete-service_request_category')

	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [serviceRequestCategory, setServiceRequestCategory] = useState([]);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const { open: openAddServiceTicketCategoryForm } = useSelector((state) => state.ServiceRequestCategoryApp.formViewDialog)

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}



	function handleAddServiceTicketCategoryFormClose(){
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			getLogs()
		}
		setSelectedTab(value);
	};

	function getServiceRequestCategory(){
		return axios.get("/service-request-category").then((res) => {
			const { data } = res.data;
			setServiceRequestCategory(formatData(data))
		}).catch((err) => {
			console.log(err)
		})
	}
	



	function formatData(data){
		return data.map((item) => {
			return {
				service_request_category_name: item.category_name,
				is_system_generated:item.is_system_generated,
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
					'user_company'
				]),
				company_name: item.user_company.company_name
			}
		})
	}

	function refreshList(){
		getServiceRequestCategory();
	}

	useEffect(()=>{
		setLoading(true)
		getServiceRequestCategory().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	} else {
		return (
			<>
				<SaveReportDialog tableName={"service request category"}/>
				<FusePageCarded
					classes={{
						root: classes.layoutRoot,
						toolbar: 'p-0',
						topBg:classes.topBg,
						contentCard:classes.contentCard
					}}
					// header={
					// 	<DynamicFieldsHeader />
					// }
					// contentToolbar={
					// 	<>
					// 		<Tabs
					// 			value={selectedTab}
					// 			onChange={handleTabChange}
					// 			indicatorColor="primary"
					// 			textColor="primary"
					// 			variant="scrollable"
					// 			scrollButtons="off"
					// 			className="w-full h-64"
					// 		>
					// 			<Tab key="end_user_tab_1" className="h-64" label="All Service Ticket Categories" />
					// 			{viewServiceRequestCategoryPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
								
					// 		</Tabs>
					// 		<div>
					// 			<Menu
					// 				id="simple-menu"
					// 				anchorEl={anchorEl}
					// 				keepMounted
					// 				open={viewMenuOpened}
					// 				onClose={handleViewMenuClose}
					// 			>
					// 				{	
					// 					views.map((view,index) => {
					// 						return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
					// 					})
					// 				}
					// 			</Menu>
					// 		</div>
					// 	</>
					// }
					content={
						<>
							<div className={selectedTab !== 0 ? 'hidden' : ''}>
								{
									loading && <FuseLoading />
								}
								{
									!loading && <SalesOrderTable 
										serviceRequestCategory={serviceRequestCategory}
										refresh={refreshList}
										viewServiceRequestCategoryPermission = {viewServiceRequestCategoryPermission}
										createServiceRequestCategoryPermission = {createServiceRequestCategoryPermission}
										updateServiceRequestCategoryPermission = {updateServiceRequestCategoryPermission}
										deleteServiceRequestCategoryPermission = {deleteServiceRequestCategoryPermission}
									/>
								}
							</div>
							{
								openAddServiceTicketCategoryForm &&

								<QuickAddServiceCategory 
									open = {openAddServiceTicketCategoryForm}
									close = {handleAddServiceTicketCategoryFormClose}
								/>
							}
							{/* <AddServiceRequestCategory getServiceRequestCategory = {getServiceRequestCategory}/> */}
						</>
					}
					innerScroll
				/>
			</>
		)
	}
}

export default withReducer('ServiceRequestCategoryApp', reducer)(CardedFullWidth2TabbedSample);
