import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatPhoneNumber } from '@fuse/utils/formatPhoneNumber';
import OTPInput from 'react-otp-input';
import {
	Checkbox, 
} from '@material-ui/core'
import { 
    Card, 
    Box, 
    Stepper, 
    Step, 
    DialogContent,
    StepButton, 
    Button,
    Dialog, 
    TextareaAutosize, 
    DialogTitle, 
    DialogActions,
    Divider, 
    Icon, 
    IconButton, 
    TextField, 
    Typography, 
    InputAdornment, 
    Drawer, 
    CardContent, 
    Paper, 
    Grid, 
    Item, 
    CircularProgress, 
    FormControl, 
    InputLabel, 
    MenuItem, 
    Select, 
    Tab, 
    Tabs, 
    FormLabel, 
    RadioGroup 
} from '@mui/material'
import axios from '@fuse/utils/axios'
import { Autocomplete } from '@material-ui/lab'
import Editor from '@fuse/components/Editor';
import PropTypes from 'prop-types';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { formatPriceField, generateProperLocationString } from '@fuse/utils/general'
import { usePermission } from '@fuse/hooks';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import { countries } from '@fuse/utils/countryName'
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import AlertModal from './modal/AlertModal'
import { quickAddedServiceTicketCategory } from 'app/main/service-request-category/store/formViewDialogReducer'



const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))



function QuickAddServiceCategory(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
	const toast = useToast(dispatch)

    const [openAlerModal, setOpenAlertModal] = useState(false);
    const [ formName, setFormName ] = useState('Add Service Ticket Category')
    const { data: editFromState } = useSelector((state) => state.ServiceRequestCategoryApp.formViewDialog);


    const schema = yup.object({
        category_name: yup.string().required('Category Name is required!')
        .matches(/^[A-Za-z\s]+$/, 'Category Name cannot contain numbers, alphanumeric characters, or special characters!')
		.matches(/\S/, 'Category Name cannot be only spaces!')
		.max(30, 'Category Name must be at most 30 characters!'),
    })

    const defaultValues = {
        category_name: ''
    }

    const { 
        control, 
        formState:{errors}, 
        handleSubmit, 
        reset,
        setValue,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const handleCloseForm = () => {
        
        props.close('serviceticketcategory')
    }

    const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        handleCloseForm()
    }

    useEffect( () => {
        if(!editFromState) return
        setFormName('Update Service Ticket Category')
        setValue('category_name', editFromState.category_name)
    }, [editFromState])


    const formError = (error) => {
        console.log(error)
    }


    const onSubmit = async(data) => {
        const payload = {
            category_name: data.category_name,
        }

        if(editFromState !== null) {
            payload.edit_id = editFromState.id
            axios.post('/service-request-category/edit', payload).then(response => {
                reset(defaultValues)
                // //Send newly added category value
                // props.handleCloseDialog(response.data.data.new_category)
                toast.success('Service ticket category updated successfully.')
                dispatch(quickAddedServiceTicketCategory())
                handleCloseForm()
                // props.refreshList()  
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        } else {
            axios.post('/service-request-category/add', payload).then(response => {
                reset(defaultValues)
                // //Send newly added category value
                // props.handleCloseDialog(response.data.data.new_category)
                toast.success('Service ticket category added successfully.')
                dispatch(quickAddedServiceTicketCategory())
                handleCloseForm()
                    // props.refreshList()  
            }).catch(err => {
                toast.error(err.response.data.message)
            })
        }
        

    }




    return (
        <>
            <div>
                <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                    <div className='heading_holder'>
                        <h4 className='heading'>{ formName }</h4>
                        <IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
                            <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                        </IconButton>
                    </div>
                    <div className='progressbar_holder'>
    					<div className='progress'>
    						&nbsp;
    					</div>
    				</div>
                    <div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
						<Box sx={{ width: '100%' }} className='box_holder'>
                        <div className='main_content_holder'>
								<div className='content_holder'>
									<div className="text-left">
										<div className='mb-10'>
                                            <Typography className='status_text'>
                                                Category Name
                                                <span className='required_span'>*</span>
                                            </Typography>
                                            <Controller 
                                                name="category_name"
                                                className='serial_numberField'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        style={{ background: 'white' }}
                                                        variant='outlined'
                                                        fullWidth
                                                        required
                                                        defaultValue={defaultValues.category_name}
                                                        error={!!errors.category_name}
                                                        helperText={errors?.category_name?.message}
                                                        FormHelperTextProps={{
                                                            style: {
                                                                margin: 0,
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                border: '1px solid #F2F4F7',
                                                                fontSize: '10px',
                                                            }
                                                        }} 
                                                    />
                                                )}
                                            />
										</div>
									</div>
								</div>
								{/* <DialogActions className="action_footer_holder"> */}
								<div className='btn_holder'>
									<Button
										color="inherit"
										onClick={handleAlertModal}
										className='back_btn secondary_btn'
									>
									Cancel
									</Button>
									<Button onClick={handleSubmit(onSubmit, formError)} className='next_btn'>                                                
										Proceed
									</Button>
								</div>
							</div>
                        </Box>
                    </div>
                </Drawer>
            </div>
            
            { openAlerModal &&
                <AlertModal  openAlerModal module="service ticket category" action={editFromState ? 'updating' : 'adding'}  handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }
        </>
    )


}


export default QuickAddServiceCategory