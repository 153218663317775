import React, {useEffect,useState, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { withRouter } from 'react-router-dom';
import axios from "@fuse/utils/axios";
import { setServiceRequestId } from 'app/main/service-request/store/filterParamsReducer';
// import { setSerialNumberForServiceRequest } from 'app/main/service-request/store/filterParamsReducer';
import { setServiceTicketsCount } from 'app/main/assets/store/formViewDialogReducer'
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Autocomplete } from '@material-ui/lab'
import { Controller, useForm } from 'react-hook-form'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import useToast from '@fuse/hooks/useToast'
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import ServiceRequestStatusDialog from '@fuse/components/Modal/QuickAddServiceRequestStatus'
import ServiceRequestCategoryDialog from '@fuse/components/Modal/QuickAddServiceRequestCategory'
import ServiceRequestSourceDialog from '@fuse/components/Modal/QuickAddServiceRequestSource'
import LocationDialog from '@fuse/components/Modal/QuickAddLocationDialog'
import {
	Accordion, AccordionDetails, AccordionSummary, 
	DialogTitle, Drawer, IconButton, Paper, 
	TextField, Typography, Radio, Divider, CircularProgress} from '@material-ui/core'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { openFormViewerDialog } from 'app/main/service-request/store/formViewDialogReducer';
import AddServiceRequestFormDialog from 'app/main/service-request/components/addServiceRequestDialog';
// const useStyles = makeStyles({

//     root:{
//       '& .MuiDataGrid-columnsContainer':{
//         background: '#8f8f8f26'
//       }
//     }
// });

const useStyles = makeStyles(theme => ({
	loader: {
			display: 'flex',
			marginTop: '27px',
			marginLeft: '10px',
			'& > * + *': {
					marginLeft: theme.spacing(2),
			},
	},
	drawerWidth: {
			width: 600
	},
	uploadImageButton: {
			padding: '10px 36px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer',
			marginTop: "25px"
	},
	subSection: {
			marginLeft: '20px !important',
			marginRight: '20px !important',
			marginBottom: '20px !important',
			padding: '10px',
			backgroundColor: '#f4f4f4',
			borderRadius: '4px'
	},
	endButtons: {
			borderRadius: '4px'
	},
}))


function ServiceRequestsTab(props) {

	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
    
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
     
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };

	const tableHeaders = [
		"ticket_number","serial_number", "title", "priority", "status", "agent_name", 
	];

    const columns = [
        { field: 'ticket_number', headerName: 'Ticket Number', minWidth: 150, width: 200 , headerComponentFramework: CustomFilter,	
            renderCell: (params) =>{
                return (<a style={{color: "black",cursor: 'pointer'}} onClick={()=>redirectToServiceRequestsPage(params)}>{params.value}</a>)
            }
        },
        { field: 'serial_number', headerName: 'Serial Number', headerComponentFramework: CustomFilter, minWidth: 150, width: 200},
        { field: 'title', headerName: 'Title',headerComponentFramework: CustomFilter, minWidth: 150, width: 200},
        { field: 'priority', headerName: 'Priority',headerComponentFramework: CustomFilter, minWidth: 150,	width: 200},
        { field: 'agent_name', headerName: 'Agent Name',headerComponentFramework: CustomFilter, minWidth: 150,	width: 200},
        { field: 'service_request_status', headerName: 'Status',headerComponentFramework: CustomFilter, minWidth: 150,	width: 200,renderCell: (params)=>{
            return(
                <span style={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center'
                }}>
                    <span style={{
                        height:'10px',
                        width:'10px',
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginLeft: '5px',
                        backgroundColor: params.value.status_color.code
                    }}></span>
                    <span style={{ display: "inline-block", fontSize: "15px" }}>{params.value.status_name}</span>
                </span>
            )
        }}
    ];
    
    const classes = useStyles();
    const router = useRouter()
    const [serviceRequests,setServiceRequests] = useState([])
    const [loading,setLoading] = useState(false)
    const {assetDetails} = props

	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)

    function redirectToServiceRequestsPage(params){
        dispatch(setServiceRequestId(params.row.id))
        router.push('/service-tickets')
    }

    function formatServiceRequests(serviceRequests){
        return serviceRequests.map((item)=>{
            return { 
                id: item.id,
                ticket_number: item.ticket_id,
                serial_number: item.asset.serial_number,
                title: item.title,
                priority: item.service_request_priority?.name ?? 'N/A',               
                agent_name: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : 'N/A',
                status: item.service_request_status ? item.service_request_status?.status_name : 'N/A', 
				status_details: {
					id: item?.service_request_status?.id,
					status_name: item?.service_request_status?.status_name,
					status_color: item?.service_request_status?.status_color
				},
				deleted_at: item.deleted_at,
            }
        })
    }
    function getServiceRequests(){
        if(!assetDetails) return
        // setLoading(true)
        axios.get(`/assets/fetch-asset-service-request/${assetDetails.id}`,{
        }).then((res) => {
            const { response } = res.data.data;
            setServiceRequests(formatServiceRequests(response))
						dispatch(setServiceTicketsCount(response.length))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            // setLoading(false)
        })
    }

    useEffect(()=>{
        getServiceRequests()
    },[])

    if(loading){
        return(
            <FuseLoading/>
        )
    }


	const defaultValues = {
		ticket_type: '',
		title: '',
		agent: null,
		description:'',
		location: null,
	}

	const { control, formState, handleSubmit, reset, setValue, getValues } = useForm({
		mode: 'onChange',
		defaultValues,
		// resolver: yupResolver(schema)
	});

	function redirectToServiceTicketProfile(event) {
		if(event?.data?.deleted_at){
			router.push(`/service-tickets/${event?.data.id}?archived=true`)
		}else{
			router.push(`/service-tickets/${event?.data.id}`)
		}
	}

	const generateHeaderLabel = (header) => {
		if (header == 'accessory_category_id') {
			return 'Accessory Category Name'
		} else if (header == 'manufacturer_id') {
			return 'Manufacturer Name'
		} else if(header === 'detailed_description_of_the_issue') {
			return 'Issue Description'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	function handleHideColumnDropDownDesign(){
		return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 8px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
  	}


    return (
        <div className={classes.root}>

			{/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
				<Button  className='button primary_maroon_outlined_btn mb-10'
					onClick = {()=>{redirectToServiceRequestsPageWithSerialNo(props.assetDetails.serial_number)}}
				>
          			<i className="icon ti ti-plus" />
					Add Service Tickets
				</Button>
			</div> */}


			<div 
				className="ag-theme-alpine" 
				style={{
				width: "100%",
				height: windowHeight+105,
				fontSize:'12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={serviceRequests}
					animateRows
					onGridReady = { onGridReady }
					paginationPageSize={10}
					pagination
					defaultColDef={{
						resizable: true
					}}
				>
				{tableHeaders.map(header => {
					if (header == 'ticket_number') {
						return (
							<AgGridColumn
								field={header}
								key={header}
								suppressSizeToFit={true}
								headerName={generateHeaderLabel(header)}
								sortable={true}
								// floatingFilter = { true } 
								headerComponentFramework={CustomFilter}
								filter="text"
								flex={1}
								minWidth={200}
								cellRendererFramework={(event) => {
									return (<a
										style={{
											height: '10px',
											width: '10px',
											color: 'black',
											cursor: 'pointer',
											marginTop: '2px',
											borderRadius: '50%',
											display: 'inline-block',
										}}
										onClick={() => redirectToServiceTicketProfile(event)}
										variant="subtitle1"
									>
										{<><span>{event.value}</span></>}
										{<><span> {event?.data?.deleted_at ? '(Archived)' : ''}</span></>}
									</a>)
								}}
							>
							</AgGridColumn>
						)
					}
					if (header == 'status') {
						return (
							<AgGridColumn
							  field={header}
							  key={header}
							  minWidth={230}
							  cellEditorPopup
							  suppressSizeToFit
							  // cellEditor="agRichSelectCellEditor"
							  cellEditor='customDropdownEditor'
							  headerComponentFramework={CustomFilter}
							  sortable
							  
							  // floatingFilter
							  headerName={generateHeaderLabel(header)}
							  filter="text"
							  editable={false}
							  cellRendererFramework={(event) => {
								if (event.colDef.field === "status") {
								  return (
									<span
									  style={handleHideColumnDropDownDesign()}>
									  <span
										style={{
										  height: "10px",
										  width: "10px",
										  marginTop: "2px",
										  borderRadius: "50%",
										  display: "inline-block",
										  // backgroundColor: event.data?.asset_status_color?.code
										  backgroundColor:
											event.value?.split(",")[1] ??
											event.data?.status_details?.status_color?.code,
										}}
									  />
									  {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
									  {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
									  <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
										{event.value?.split(",")[0]}
									  </span>
									</span>
								  );
								} if (
								  event.colDef.headerName == "Group" &&
								  event.node.field == "status"
								) {
								  return (
									<>
									  <span>{event.value}</span>
									</>
								  );
								}
								return (
								  <>
									<span>{event.value}</span>
								  </>
								);
			
							  }}
							/>
						  );
					}
					return(
						<AgGridColumn 
							key = {header} 
							field = {header}
							headerName={generateHeaderLabel(header)}
							width = { 200 }
							minWidth = { 170 }
							sortable = {true}
							flex = {1}
							filter = "text"
							wrapText
							headerComponentFramework = {CustomFilter}								
						/>
					)
					
				})}
				</AgGridReact>
			</div>

			{/* {openState.open && <AddServiceRequestFormDialog getServiceRequest = {getServiceRequests} asset={props?.assetDetails}/>} */}

        </div>
    )
}

export default withRouter(ServiceRequestsTab)
