/**
 * Authorization Roles
 */
const authRoles = {
	superAdmin: ['dalos-admin'],
	agent: ['agent'],
	adminAgent: ['agent-admin',],
	agentAndAdmin: ['agent', 'agent-admin'],
	staff: ['staff'],
	user: ['Admin','Normal user', 'Power user', 'Super-User', 'Support', 'View-Only'],
	onlyGuest: []
};

export default authRoles;
