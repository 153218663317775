import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const Utilizationtab = () => {
  const [series, setSeries] = useState([
    {
      name: 'Cost',
      type: 'column',
      data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6, 5.6, 6.6, 7.6]
    }, {
      name: 'Total Seats',
      type: 'column',
      data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5, 9.5, 10.5, 11.5]
    }, {
      name: 'Used Seats',
      type: 'line',
      data: [20, 29, 37, 36, 44, 45, 50, 58, 62, 68, 75]
    }
  ])
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      stacked: false
    },
    toolbar: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [1, 1, 4]
    },
    // title: {
    //   text: 'XYZ - Stock Analysis (2009 - 2016)',
    //   align: 'left',
    //   offsetX: 110
    // },
    xaxis: {
      categories: [2010, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019],
      labels: {
        formatter: function (value, timestamp) {
          // console.log(value)
          const abc = (new Date('12/15/2010').getFullYear())
          return value // The formatter function overrides format property
        }, 
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30",

      },
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          }
        },
        // title: {
        //   text: "Income (thousand crores)",
        //   style: {
        //     color: '#008FFB',
        //   }
        // },
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: 'Income',
        opposite: true,
        axisTicks: {
          show: true,
        },
        // axisBorder: {
        //   show: true,
        //   color: '#00E396'
        // },
        labels: {
          style: {
            colors: '#00E396',
          }
        },
        // title: {
        //   text: "Operating Cashflow (thousand crores)",
        //   style: {
        //     color: '#00E396',
        //   }
        // },
      },
      {
        seriesName: 'Revenue',
        opposite: true,
        axisTicks: {
          show: false,
        },
        // axisBorder: {
        //   show: true,
        //   color: '#FEB019'
        // },
        // labels: {
        //   style: {
        //     colors: '#FEB019',
        //   },
        // },
        // title: {
        //   text: "Revenue (thousand crores)",
        //   style: {
        //     color: '#FEB019',
        //   }
        // }
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60
      },
    },
    // legend: {
    //   horizontalAlign: 'left',
    //   offsetX: 40
    // }
  },


  )
  return (
    <>
      <ReactApexChart options={options} series={series} type="line" height={290} />


    </>
  )
}

export default Utilizationtab
