import React from 'react'
import { Grid, Card, Box, Button } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { TextField, Paper, TextareaAutosize } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@material-ui/core';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'


const useStyles = makeStyles((theme) => ({

  textarea: {
    resize: "both",
    width: "100%"
  }
}));

const schema = yup.object().shape({
  // name: yup
  // 	.string()
  // 	.required('You must enter a product name')
  // 	.min(5, 'The product name must be at least 5 characters')
});
const Comp6 = ({ activeStep, setActiveStep, handleNext, handleBack }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(schema)
  });
  const classes = useStyles();

  const { reset, watch, control, onChange, formState, handleSubmit, setValue } = methods;
  return (

    <>
      <div style={{ position: "relative", height: "100%" }}>
        <Grid container className='p-5'>
          <Grid item lg={12} >
            <Box style={{ border: "2px dashed #efefef" }} p={1} mb={1}>
              <Box className='flex justify-between'>
                <div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                  <Controller
                    name="address_1"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant='outlined'
                          label='Serial Number*'
                          fullWidth
                          inputProps={{ readOnly: false }}
                          // InputLabelProps={{ shrink: true }}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              paddingTop: '2px'
                            }
                          }}
                          style={{ backgroundColor: '#fff' }}

                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <Controller
                    name="address_1"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant='outlined'
                          label='Asset Name*'
                          fullWidth
                          inputProps={{ readOnly: false }}
                          // InputLabelProps={{ shrink: true }}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              paddingTop: '2px'
                            }
                          }}
                          style={{ backgroundColor: '#fff' }}

                        />
                      </>
                    )}
                  />
                </div>
              </Box>
              <Box>

              </Box>
              <Box className='flex justify-between items-center'>
                <div style={{ marginBottom: '10px', width: '100%' }}>
                  <Controller
                    name="address_1"
                    className="mb-16"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextField
                          {...field}
                          variant='outlined'
                          label='Asset Tag*'
                          fullWidth
                          inputProps={{ readOnly: false }}
                          // InputLabelProps={{ shrink: true }}
                          FormHelperTextProps={{
                            style: {
                              margin: 0,
                              backgroundColor: '#f4f4f4',
                              width: '100%',
                              paddingTop: '2px'
                            }
                          }}
                          style={{ backgroundColor: '#fff' }}

                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ marginBottom: '10px', width: '100%', display: "flex", justifyContent: "flex-end" }}>
                  <Button variant='outlined' className='wizard-screen-backBtn'>
                    <Icon>
                      <span class="material-symbols-outlined">
                        add
                      </span>
                    </Icon>
                    Add More</Button>
                </div>
              </Box>







            </Box>
            <Box className='flex justify-between' p={1} pb={0}>
              <div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                <Controller
                  name="country"
                  className="mb-16"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: '100%' }}
                        options={countries}
                        disablePortal
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginTop: '0px', marginLeft: '0px', width: '100%' }}>{children}</Paper>
                        )}
                        getOptionLabel={option => option.name ?? ''}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                style={{ background: 'white' }}
                                {...params}
                                label="Status*"

                                inputRef={ref}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          )
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ marginBottom: '10px', width: '100%' }}>
                <Controller
                  name="country"
                  className="mb-16"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: '100%' }}
                        options={countries}
                        disablePortal
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginTop: '0px', marginLeft: '0px', width: '100%' }}>{children}</Paper>
                        )}
                        getOptionLabel={option => option.name ?? ''}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                style={{ background: 'white' }}
                                {...params}
                                label="Model*"

                                inputRef={ref}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          )
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </Box>
            <Box className='flex justify-between' pt={0} p={1}>
              <div style={{ marginBottom: '10px', width: '100%' }}>
                <Controller
                  name="country"
                  className="mb-16"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: '100%' }}
                        options={countries}
                        disablePortal
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginTop: '0px', marginLeft: '0px', width: '100%' }}>{children}</Paper>
                        )}
                        getOptionLabel={option => option.name ?? ''}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                style={{ background: 'white' }}
                                {...params}
                                label="Location*"

                                inputRef={ref}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          )
                        }}
                      />
                    </>
                  )}
                />
              </div>

            </Box>

            <Box pt={0} p={1} mb={1}>
              <Box className='flex justify-between'>
                <div className='ws-notes-field' style={{ marginBottom: '40px', width: '100%' }}>
                  <Controller
                    name='notes'
                    control={control}
                    render={({ field }) => (
                      // <TextareaAutosize
                      //   {...field}
                      //   style={{ background: 'white', width: '100%', minHeight: '20%', border: '1px solid #c4c4c4', padding: '9px' }}
                      //   variant='outlined'
                      //   label='Notes'
                      //   fullWidth
                      //   multiline
                      //   placeholder="Notes"
                      // // rows={4}
                      // // error={!!errors.notes}
                      // // helperText={errors?.notes?.message}
                      // />
                      <TextField
                        id="outlined-textarea"
                        label="Notes"
                        placeholder=""
                        rows={8}
                        style={{ width: "100%" }}
                        multiline
                        variant="outlined"
                        inputProps={{ className: classes.textarea }}
                      />
                    )}
                  />
                </div>

              </Box>
              <Box>

              </Box>
              {/* <Box className='flex justify-between items-center'>
          <div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="country"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={countries}
													disablePortal
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '0px', marginLeft: '0px', width: '100%' }}>{children}</Paper>
													)}
													getOptionLabel={option => option.name}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	style={{ background: 'white' }}
																	{...params}
																	label="Country"

																	inputRef={ref}
																	variant="outlined"
																	fullWidth
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
                
           </Box> */}







            </Box>
          </Grid>
        </Grid>

        <Box className='w-full absolute bottom-1'>
          <Box className='w-full flex justify-between'>
            <Button variant='outlined' className='wizard-screen-backBtn' onClick={handleBack}>Go Back</Button>
            <Button variant='outlined' className='wizard-screen-nextBtn' onClick={handleNext}>Next</Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Comp6
