import _ from '@lodash';
import 'ag-grid-enterprise';
import { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomFilterData, setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import AddCustomerFormDialog from './addCustomerDialog';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	cardGen: {
		width: '20%',
		height: "100%"
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin: '5px 5px 5px 0',
		height: '28px'
	},
	activeTab: {
		background: "rgba(107, 44, 87, 0.04);",
		borderRadius: "0",
	},
	buttonSquare: {
		borderRadius: '5px'
	},
	btn: {
		background: "#30C90A",
		width: "120px",
		borderRadius: "17px",
		color: "#fff"
	},
	icon: {
		fontSize: "18px",
		color: "#61656F",
		display: "inline-block"
	}
});

function ListView(props) {
	const table_name = "SERVICE_REQUEST";
  	const classes = useStyles();
	const router = useRouter();
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch)
	const [gridApi, setGridApi] = useState(null);
	const gridRef = useRef();
	const [displayButton, setDisplayButton] = useState(false);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
	const [filterApplied, setFilterApplied] = useState(false);
	const [logs, setLogs] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [clientsList, setClientsList ] = useState([])
	const frameworkComponents = {
		customFilter: CustomFilter,
		customDropdownEditor: CustomDropdownEditor
	};
	const openState = useSelector((state) => state.SuperAdminDashboard.formViewDialog)

	const tableHeaders = ["client_name", "total_assets", "status", "total_contracts", "first_name", "last_name", "email_address", "phone_number", "created_date", "updated_date"];

	const handelEdit = (data) => {
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	function formatString(str) {
		if (!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) => {
		setGridApi(params);
		clientsList.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(fieldName === "ticket_number") return list[fieldName] = value
				if((value === '-' || value === '')&& fieldName == 'user_name') 
					list[fieldName] = 'Unassigned'
				else if (value === '' || !value || value === '')
					list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if (router.query.report_name == undefined) {
			// loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		} else {
			getReport(params);
		}
	}

	function onFilterChanged(event) {
		const rows = event.api.getFilterModel();
		// stop rerender to show no overlay text
		dispatch(setfilterlist(rows));
		saveView("N/A", rows)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if (total_rows === 0) {
			event.api.showNoRowsOverlay();
		} else {
			event.api.hideOverlay();
		}
	}

	function cellRendererFramework(params){
		if(params.column.colId === "status"){
			return (
				<span className={`listing_status_badge_holder ${params.value && params.value.toLowerCase()}`}>
				{params.value}
				</span> 
			)
		}
		return(<span>{params.value}</span>)
	}

	function getReport(event) {
		const report_name = router.query.report_name;
		const table_name = "service request";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
			.then(results => {
				const model = JSON.parse(results.data.data.filter);
				event.api.setFilterModel(model);
				const row_model = JSON.parse(results.data.data.row_model);
				event.columnApi.setColumnState(row_model);
			})
			// autoGroupColumnDef={{ minWidth: 5000 }}
			.catch(err => {
				console.log(err);
			})
	}

	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filter),
				table_name: table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name: table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	const onColumnMoved = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const onColumnPinned = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if (hide) {
				++total_hidden_columns;
			}
		});
		if (total_hidden_columns < total_no_of_columns) {
			if (router.query.report_name == undefined) {
				saveView(rows);
			}
		}
	}

	function onColumnRowGroupChanged(event) {
		if (event.columns.length == 0) {
			setGroupByArr([])
		} else {
			setGroupByArr(event.columns)
		}
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0;
		let startRowIndex = 0;
		let endRowIndex = 0;

		const rows = params.api.getCellRanges();

		if (rows.length != 0) {
			columnCount = params.api.getCellRanges()[0].columns.length;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex;
		}

		if (columnCount > 1 || startRowIndex != endRowIndex) {
			setDisplayButton(true);
		} else {
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		if(header === "first_name") return "Person's First Name"
		if(header === "last_name") return "Person's Last Name"
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	function formatClientList( clients ){
		return clients.map((client)=>{
			return {
				id: client.user_id,
				company_id: client.company_id,
				client_name: client.client_name,
				first_name: client.first_name,
				last_name: client.last_name,
				email_address: client.email_address,
				phone_number: Boolean(client.phone_number) ? client.phone_number : '',
				phone: client.phone,
				subdomain: client.subdomain,
				country_code: client.country_code,
				status: client.status,
				company_name: client.company_name,
				time_zone: client.time_zone,
				industry: client.industry,
				created_date: dateReformatTo_mmm_dd_yyyy(client.created_date),
				updated_date: dateReformatTo_mmm_dd_yyyy(client.updated_date),
				total_assets: client.total_asset_count,
				total_contracts: client.total_contract_count
      }
    })
	}

	function getAllClients(){
		axios.get(`/super-admin/client-list`).then(res =>{
			setClientsList(formatClientList(res.data.data.clients))
		}).catch(err => {
			console.log(err)
		})
	}

	useEffect(() => {
		if (filterApplied) {
			toast.success("Filters have been applied")
		}
	}, [filterApplied])

	useEffect(() => {
		onGridReady();
		getAllClients()
		return () => {}
	}, [])

	const handleChildClick = (value) => {
		setTabValue(value)
	};

	function handleAddServiceTicket() {
		dispatch(openFormViewerDialog())
	}

	const clearRedirectionFilter = () => {
		dispatch(setCustomFilterData(null))
	}

	const handleClickOpen = (data) => {
		router.push(`/client/detail/${data.id}`)
	}

	return (
		<>
			{openState.open && <AddCustomerFormDialog getCustomer = {getAllClients}/>}
			<AgGridFilterGroupView
				tableRef={gridRef}
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList={groupByArr}
				tableName={'super_admin_portal_client_list'}
				logs={logs}
				displayTableName={'Client Accounts'}
				onClick={handleChildClick}
				onClickAddButton={handleAddServiceTicket}
				customFilterData={props.customFilterData}
				clearRedirectionFilter={clearRedirectionFilter}
				serviceRequestTotalRecords={clientsList.length}
				loadingLog={loadingLog}
				hideTableName = {true}
			/>

			{/* {(tabValue == 1) && (<ActivityAgGridListing module={'service_request'} logs={logs} displayTableName={'Service Tickets'}/>)}		 */}

			{
				(tabValue == 0) &&
				<div
					className="ag-theme-alpine"
					style={{
						width: "100%",
						height: windowHeight + 105,
						fontSize: '11px'
					}}
				>
					{filterApplied && <p style={{ margin: '4px' }}>Filters have been applied</p>}
					<AgGridReact
						ref={gridRef}
						suppressRowClickSelection={true}
						onGridReady={onGridReady}
						animateRows={true}
						rowData={clientsList}
						rowHeight={38}
						onRowClicked={() => {}}
						// Set the desired sheet name
						defaultExcelExportParams={{sheetName: 'Dalos-Source'}}
						defaultColDef={{resizable: true}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}

						// Row Grouping conditions
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						rowGroupPanelShow={rowGroupPanelShow}

						onColumnMoved={onColumnMoved}
						onColumnPinned={onColumnPinned}
						onColumnVisible={onColumnVisible}
						onSortChanged={onSortChanged}
						groupHeaderHeight={1}
						// headerHeight = {15}
						floatingFiltersHeight={40}

						// status bar enabled on range selection
						enableRangeSelection={true}
						statusBar={{
							statusPanels: [
								{
									statusPanel: 'agAggregationComponent'
								},
							],
						}}

						// to check the the no. of rows selected during range selection
						onRangeSelectionChanged={onRangeSelectionChanged}

						onFilterChanged={onFilterChanged}

						// on column group changed
						onColumnRowGroupChanged={onColumnRowGroupChanged}
						frameworkComponents={frameworkComponents}
						paginationPageSize={10}
						pagination={true}
					>
						<AgGridColumn
							lockPosition={true}
							rowSelection={'single'}
							width={120}
							minWidth={120}
							maxWidth={120}
							suppressSizeToFit={true}
							headerName="Actions"
							pinned='right'
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({ data }) => {
								if (data) {
									return (
										<>
											{<Tooltip id="view" title="View" placement="top">
												<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-eye" onClick={() => handleClickOpen(data)} />
											</Tooltip>}
											{<Tooltip id="edit" title="Edit" placement="top">
												<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-pencil" onClick={() => handelEdit(data)} />
											</Tooltip>}

										</>
									)
								} else {
									return (<></>)
								}
							}}
						/>
						{tableHeaders.map(header => {
							return (
								<AgGridColumn
									field={header}
									key={header}
									suppressSizeToFit={true}
									headerName={generateHeaderLabel(header)}
									sortable={true}
									minWidth={400}
									// floatingFilter = { true } 
									flex={1}
									headerComponentFramework={CustomFilter}
									filter="text"
									cellRendererFramework={cellRendererFramework}
								/>
							)
						})}
					</AgGridReact>
				</div>
			}
		</>
	);
}

export default ListView