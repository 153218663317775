import React, { useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import ColorPicker from '@fuse/components/ColorPicker'
import { Button, DialogTitle, TextField, TextareaAutosize, Typography, Dialog,DialogContent, DialogActions } from '@mui/material'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const AddServiceCategoryFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddCategoryModalStateData,
    newDataAddedEvent
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const defaultValues = {
        category_name: '',
    }

    const schema = yup.object({
        category_name: yup.string().required('Category name is required!')
            .matches(/^[A-Za-z\s]+$/, 'Category name cannot contain numbers, alphanumeric characters, or special characters!')
            .matches(/\S/, 'Category name cannot be only spaces!')
            .max(30, 'Category name must be at most 30 characters!'),
    })

    const { 
        control, 
        formState:{errors: categoryFormErrors}, 
        handleSubmit: categoryFormSubmit, 
        trigger,
        reset: categoryFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        await trigger();
        if(Object.keys(categoryFormErrors).length !== 0) { 
            return;
        }

        let payload = {
			category_name: data.category_name,
		}
        axios.post('/service-request-category/add', payload).then(response => {
                categoryFormReset(defaultValues)             
                toast.success(response.data.message)
                newDataAddedEvent(response.data.data.new_category)
            }).catch(err => {
				toast.error(err.response.data.message || 'Something went wrong!')
			})
    }


    return (
        <Dialog
            className="cstm_dialog_modal small text-center"
            open={openAddCategoryModalStateData}
            // onClose={handleOpenCloseAddModalEvent}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <div className='heading_holder flexbox_holder items-center w-full'>
                    <h2 className="main_heading">Add Category</h2>
                    <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                        <i className="icon ti ti-x" />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="text-left">
                    <div className='mb-10'>
                        <Typography className='status_text'>
                            Category Name
                            <span className='required_span'>*</span>
                        </Typography>
                        <Controller
                            name="category_name"
                            // className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        required
                                        fullWidth
                                        error={!!categoryFormErrors.category_name}
                                        helperText={categoryFormErrors?.category_name?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                </>
                            )}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="action_footer_holder">
                <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                <Button onClick={categoryFormSubmit(onSubmit)} className="primary_btn">
                    Save and Select
                </Button>
            </DialogActions>
        </Dialog>
    );


}

export default  AddServiceCategoryFromQuickAction;