import useToast from '@fuse/hooks/useToast'
import {
	Accordion, AccordionDetails, AccordionSummary, Button,
	DialogTitle, Divider, Icon,
	IconButton, TextareaAutosize, makeStyles, MenuItem, Paper, TextField, Typography,
	Drawer, Checkbox, InputAdornment
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import { generateRandomNumberString } from '@fuse/utils/stringOperations'
import ContractTypeQuickAdd from './quickAdds/contractType'
import ContractStatusQuickAdd from './quickAdds/contractStatus'
import SLAQuickAdd from './quickAdds/serviceLevelAgreement'
import PaymentTermQuickAdd from './quickAdds/paymentTerm'
import ServiceProviderQuickAdd from './quickAdds/serviceProvider'
import ShippingLocationQuickAdd from './quickAdds/shippingLocation'
import { formatPriceField, generateProperLocationString } from '@fuse/utils/general'
import { usePermission } from '@fuse/hooks';
import CustomDatePicker from '@fuse/components/customDatePicker'
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import QuickAddPaymentTerm from "@fuse/components/Modal/QuickAddPaymentTerm";
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'


/*
CODE CONTENT: 
Accordions Expand Control
Main Form
Service Provider Quick Add
Shipping Location Quick Add
Other Quick Adds
Utility Functions
UseEffect Declarations
*/

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px !important',
			marginRight: '20px !important',
			marginBottom: '20px !important',
			padding: '10px',
			backgroundColor: '#f4f4f4',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		customDrawerPaper: {
			maxWidth: '700px'
		}
	}
))

function AddContractFormDialog(props) {

	const [contractStartDate,setContractStartDate] = useState({
		date: null,
		error: false
	})
	const [contractEndDate,setContractEndDate] = useState({
		date: null,
		error: false
	})
	const [poDate,setPoDate] = useState({
		date: null,
		error: false
	})
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.contractsApp.formViewDialog)
	const { data: editFormState } = useSelector((state) => state.assetsApp.formViewDialog);
	const [loading, setLoading] = useState(false)
	const [shippingLocationMenu, setShippingLocationMenu] = useState([])
	const [contractStatusMenu, setContractStatusMenu] = useState([])
	const [serviceProviderMenu, setServiceProviderMenu] = useState([])
	const [paymentTermMenu, setPaymentTermMenu] = useState([])
	const [slaMenu, setSlaMenu] = useState([])
	const [contractTypeField, setContractTypeField] = useState(false)
	const [slaField, setSlaField] = useState(false)
	const [contractStatusField, setContractStatusField] = useState(false)
	const [paymentTermField, setPaymentTermField] = useState(false)
	const [serviceProviderField, setServiceProviderField] = useState(false)
	const [contractsMenu, setContractsMenu] = useState([])
	const [childContract, setChildContract] = useState(false)
	const [parentContractMenu, setParentContractMenu] = useState([])
	// const [startDate, setStartDate] = useState('')
	// const [endDate, setEndDate] = useState('')
	const [newContractType, setNewContractType] = useState('')
	const [createNewShippingLocation, setCreateNewShippingLocation] = useState(false)
	const [newSla, setNewSla] = useState('')
	const [newContractStatus, setNewContractStatus] = useState('')
	const [newPaymentTerm, setNewPaymentTerm] = useState('')
	const [contractTypeAccordionExpand, setContractTypeAccordionExpand] = useState(false)
	const [slaAccordionExpand, setSlaAccordionExpand] = useState(false)
	const [serviceProviderAccordionExpand, setServiceProviderAccordionExpand] = useState(false)
	const [paymentAccordionExpand, setPaymentAccordionExpand] = useState(false)
	const [formattedNumber, setFormattedNumber] = useState('')
	const [
    openPaymentTermQuickAddDialog,
    setOpenPaymentTermQuickAddDialog,
  ] = useState(false);
	const contractTypeInputRef = useRef(""),
		renewalInputRef = useRef(""),
		masterInputRef = useRef(""),
		slaInputRef = useRef(""),
		serviceProviderInputRef = useRef(""),
		paymentTermInputRef = useRef(""),
		poNumberInputRef = useRef(""),
		poDateInputRef = useRef(""),
		paymentAmountInputRef = useRef("")

	const { hasPermission } = usePermission()
	const createCStatusPermission = hasPermission('add-contract_status')
	const createCLocationPermission = hasPermission('add-location')
	const createCTypePermission = hasPermission('add-contract_type')
	const createSLAPermission = hasPermission('add-service_level_agreement')
	const createCPaymentTermPermission = hasPermission('add-payment_term')
	const createCServiceProviderPermission = hasPermission('add-service_provider')

	const [reminderCheckboxes, setReminderCheckBoxes] = useState({
		term45: true,
		term30: false,
		supportContact: false
	})

	const [noReminderCheckBox, setNoReminderCheckBox] = useState(false)
	const [currentCountry, setCurrentCountry] = useState({
		"name": "United States",
		"code": "US",
		"currency": "USD"
	})

	/*--------------------------------------Accordions Expand Control---------------------------------------*/
	const resetAccordionsExpand = () => {
		setContractTypeAccordionExpand(false)
		setSlaAccordionExpand(false)
		setPaymentAccordionExpand(false)
		setServiceProviderAccordionExpand(false)
	}

	const manageContractTypeAccordion = () => {
		setContractTypeAccordionExpand(!contractTypeAccordionExpand)
	}

	const manageSLAAccordion = () => {
		setSlaAccordionExpand(!slaAccordionExpand)
	}

	const managePaymentAccordion = () => {
		setPaymentAccordionExpand(!paymentAccordionExpand)
	}

	const manageServiceProviderAccordion = () => {
		setServiceProviderAccordionExpand(!serviceProviderAccordionExpand)
	}

	const onSubmitAccordionExpand = () => {

		if (
			contractTypeInputRef.current.value === '' ||
			renewalInputRef.current.value === '' ||
			masterInputRef.current.value === ''
		) {
			setContractTypeAccordionExpand(true)
		}

		if (slaInputRef.current.value === '') {
			setSlaAccordionExpand(true)
		}

		if (serviceProviderInputRef.current.value === '') {
			setServiceProviderAccordionExpand(true)
		}

		if (
			paymentTermInputRef.current.value === '' ||
			poDateInputRef.current.value === '' ||
			poNumberInputRef.current.value === '' ||
			paymentAmountInputRef.current.value === ''
		) {
			setPaymentAccordionExpand(true)
		}

	}
	/*---------------------------------------------------------------------------------------------------------*/

	/*-----------------------------------------------Main Form-----------------------------------------*/

	const schema = yup.object({
		contractId: yup.string().required('Contract Number is required!'),
		renewal: yup.string().required('Renewal Status is required!'),
		contractName: yup.string().required('Contract Name is required!'),
		contractDescription: yup.string().max(200, 'Maximum 200 characters!').nullable(),
		// contractStartDate: yup.string().required('Contract Start Date is required!'),
		// contractEndDate: yup.string().required('Contract End Date is required!'),
		contractStatus: yup.object().nullable().required('Contract Status is required!'),
		// department: yup.string().required('Department is required!'),
		contractType: yup.string().nullable().required('Contract Type is required!'),
		currentShippingLocation: yup.object().nullable().required('Location is required!'),
		// currentBillingLocation: yup.object().nullable().required('Billing Location is required!'),
		master: Boolean(editFormState) ? yup.object().nullable() : yup.object().nullable().required('Master is required!'),
		parentContract: yup.object().nullable(),
		// parentContract: yup.object().nullable().when("master", (master,schema)=>{
		// 	if(master) return schema.required('Parent Contract is required!')
		// }),
		provider: yup.object().nullable().required('Service Provider is required!'),
		sla: yup.object().nullable().required('Service Level Agreement is required!'),
		// support_phone_number: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
		// }),
		// support_phone_number: reminderCheckboxes.supportContact && yup.string().required('Phone Number is required!').matches(/^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
		paymentTerm: yup.object().nullable().required('Payment Term is required!'),
		poNumber: yup.string().required('PO Number is required!'),
		// poDate: yup.string().nullable().required('PO Date is required!'),
		country: yup.object().nullable().required('Currency is required!'),
		// paymentAmount: yup.string().matches(/[1-9]\d*(\.[0-9]\d{0,2})?$/, 'Enter a valid Payment Amount').required('Payment Amount is required!'),
		paymentAmount: yup.string().required('Payment Amount is required!'),
	})

	const defaultValues = {
		contractName: '',
		contractDescription: '',
		provider: null,
		sla: null,
		renewal: '',
		contractId: '',
		// contractStartDate: '',
		// contractEndDate: '',
		contractStatus: null,
		// department: '',
		contractType: '',
		currentShippingLocation: null,
		// currentBillingLocation: null,
		master: {},
		parentContract: null,
		// notes:'',
		support_phone_number: '',
		poNumber: '',
		// poDate: '',
		country: { "name": "United States", "code": "US", "currency": "USD" },
		paymentAmount: '',
		paymentTerm: null,
	}

	const { control, formState, handleSubmit, reset, setValue, clearErrors,getValues } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { errors } = formState

	const onSuccess = () => {
		reset(defaultValues)
		resetAccordionsExpand()
	}

	const onCancel = () => {
		reset(defaultValues)
		closeDialog()
	}

	const closeDialog = () => {
		setContractStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setFormattedNumber("")
		setContractEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setPoDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		resetAccordionsExpand()
	}

	const manageFormData = (formData) => {

		if (!editFormState && !formData.master?.value && !(formData.parentContract)) {
			toast.error('Parent Contract required!')
			return
		}
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}

		if (reminderCheckboxes.supportContact === true && formattedNumber === '') {
			toast.error('Contact number is required for text messages')
			return
		}

		if (!reminderCheckboxes.supportContact && !reminderCheckboxes.term30 && !reminderCheckboxes.term45 && !noReminderCheckBox) {
			toast.error('Please select a reminder option.')
			return
		}

		if (formData.contractDescription.length > 200) {
			toast.error('Description should be maximum 200 characters.')
			return
		}

		if(contractStartDate.error || contractEndDate.error || poDate.error){
			return
		}

		let payload = {
			contract_id: formData.contractId,
			contract_name: formData.contractName,
			contract_description: formData.contractDescription,
			contract_start_date: contractStartDate.date ?? '',
			contract_end_date: contractEndDate.date ?? '',
			contract_status: formData.contractStatus,
			shipping_id: formData.currentShippingLocation.id,
			// billing_id: formData.currentBillingLocation.id,
			contract_type: formData.contractType,
			master: formData.master?.value,
			parent_contract_id: formData.master?.value ? 0 : formData.parentContract?.id,
			// notes: formData.notes,
			// department: formData.department,
			provider: formData.provider,
			sla: formData.sla,
			renewal: formData.renewal,
			support_phone_number: reminderCheckboxes.supportContact ? formattedNumber : null,
			poNumber: formData.poNumber,
			poDate: poDate.date ?? '',
			country: formData.country,
			no_reminder: noReminderCheckBox,
			reminder: { term45: reminderCheckboxes.term45, term30: reminderCheckboxes.term30 },
			textMessage: reminderCheckboxes.supportContact,
			paymentAmount: formData.paymentAmount.replaceAll(',',''),
			paymentTerm: formData.paymentTerm
		}

		if (Boolean(editFormState)) {
			payload.id = editFormState.id

			const { contract_id, ...updatePayload } = payload

			axios.put('/contracts/update-contract-manually', updatePayload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					dispatch(setFromData(null))
					onSuccess()
					closeDialog()
					toast.success('Contract Updated Successfully.')
					props.fetchAllManuallyAddedContract()
					fetchParentContract()
				}

			}).catch(err => {
				console.log('212=>', err);
			})
		} else {

			axios.post('/contracts/add-contract-manually', payload).then(response => {
				if (response.status == 201) {
					toast.error(response.data.message)
				} else {
					onSuccess()
					closeDialog()
					toast.success('Contract Added Successfully.')
					props.fetchAllManuallyAddedContract()
					fetchParentContract()
				}

			}).catch(err => {
				console.log('212=>', err);
			})
		}
	}

	const errorData = (error) => {
		console.log('363=>', error)
	}

	/*------------------------------------------------------------------------------------------------*/

	/*-----------------------------------------Service Provider Quick Add---------------------------------------*/
	const addNewServiceProvider = (formData, formattedNumber) => {
		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			address1: formData.address1,
			serviceProvider: formData.serviceProvider,
			phone_number: formattedNumber || '',
			city: formData.city,
			state: formData.state,
			country: formData.country?.name,
			zip: formData.zip,
			url: `www.${formData.url}`
		}

		axios.post('/service-provider/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				setValue('provider', res.data.data)
				setServiceProviderField(false)
				fetchAllSuggestion()
				toast.success('Service Provider added successfully')
			}

		}).catch(err => {
			console.log('504=>', err)
			// toast.error('Something went wrong!')
		})

	}

	function serviceProviderErrorData(error) {
		console.log('serviceProviderErrorData: ', error);
	}
	/*-----------------------------------------------------------------------------------------------*/

	/*-------------------------------------------Shipping Location Quick Add--------------------------------------------*/
	function manageShippingFormData(formData) {
		// console.log('manageShippingFormData: ',formData);
		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			address: formData.shipAddress,
			address2: formData.address2,
			location: formData.shipLocationName,
			city: formData.shipCity,
			state: formData.shipState,
			zip: formData.shipZip,
			country: formData.shipCountry.name,
			note: formData.note,
			location_type_id: formData.locationType.id
		}

		axios.post('/shipping/add', payload).then(response => {
			if (response.status === 201) {
				toast.error(response.data.message)
			} else {
				setValue('currentShippingLocation', response.data.data.new_shipping_location)
				setCreateNewShippingLocation(false)
				toast.success('Location Added Successfully.')
				fetchAllSuggestion()
			}

		}).catch(err => {
			// console.log('234: ',err.response);
			// toast.error('Something went wrong!')
		})

	}

	function shippingFormErrorData(error) {
		console.log('shippingFormErrorData: ', error);
	}
	/*-----------------------------------------------------------------------------------------------*/

	/*----------------------------------------------Other Quick Adds----------------------------------------------*/
	function addNewContractType(event) {
		event.preventDefault()
		// if(!newContractType.trim()){
		// 	// toast.error('Enter a valid name')
		// 	setErrorContractType('Please enter a value!')
		// 	return
		// }

		axios.post('contracts/add-contract-types', {
			contract_type: newContractType.trim()
		}).then((res) => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				// setErrorContractType(null)
				setValue('contractType', newContractType)
				fetchContractType()
				setContractTypeField(false)
				setNewContractType('')
				toast.success('Contract Type added successfully.')
			}
		}).catch((err) => {
			console.log(err.response);
			toast.error(err.response.data.message)
		})
	}

	const addNewSla = (event) => {
		event.preventDefault()
		// if(!newSla.trim()){
		// 	// toast.error('Invalid value!')
		// 	setErrorSla('Please enter a value!')
		// 	return
		// }

		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			sla: newSla.trim()
		}

		axios.post('/service-level-agreement/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				// setErrorSla('Please enter a value!')
				setValue('sla', res.data.data)
				setSlaField(false)
				setNewSla('')
				fetchAllSuggestion()
				toast.success('Service Level Agreement added successfully')
			}
		}).catch(err => {
			// toast.error('Something went wrong!')
		})
	}

	const addNewPaymentTerm = (event) => {
		event.preventDefault()
		// if(!newPaymentTerm.trim()){
		// 	setErrorPaymentTerm('Please enter a value!')
		// 	return
		// }

		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			paymentTerm: newPaymentTerm
		}

		axios.post('/payment-term/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				setValue('paymentTerm', res.data.data)
				setNewPaymentTerm('')
				setPaymentTermField(false)
				fetchAllSuggestion()
				toast.success('Payment Term added successfully')
			}

		}).catch(err => {
			// toast.error('Something went wrong!')
		})
	}

	const addNewContractStatus = (event) => {
		event.preventDefault()
		// if(!newContractStatus.trim()){
		// 	setErrorContractStatus('Please enter a value!')
		// 	return
		// }

		const payload = {
			entity_external_platform_id: generateRandomNumberString(),
			contractStatus: newContractStatus
		}

		axios.post('/contract-status/add', payload).then(res => {
			if (res.status === 201) {
				toast.error(res.data.message)
			} else {
				setValue('contractStatus', res.data.data)
				setNewContractStatus('')
				setContractStatusField(false)
				fetchAllSuggestion()
				toast.success('Contract Status added successfully')
			}
		}).catch(err => {
			// toast.error('Something went wrong!')
		})

	}
	/*----------------------------------------------------------------------------------------------------------*/

	/*-----------------------------------------Utility Functions-------------------------------------*/
	// function formatDate(date) {
	// 	if (!date) return
	// 	let yourDate = new Date(date)
	// 	const offset = yourDate.getTimezoneOffset()
	// 	return (new Date(yourDate.getTime() - (offset * 60 * 1000))).toISOString().split('T')[0]
	// }

	const handleChangeNoReminderCheckbox = (event) => {
		setNoReminderCheckBox(event.target.checked)
	}

	const handleChangeReminderCheckBoxes = (event) => {

		if ((event.target.name === 'term45' && event.target.checked === false && reminderCheckboxes.term30 === false) || (event.target.name === 'term30' && event.target.checked === false && reminderCheckboxes.term45 === false)) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked,
				supportContact: false
			})
			setValue('support_phone_number', '')
			setNoReminderCheckBox(true)
		}
		else {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				[event.target.name]: event.target.checked
			})
		}
	}

	const handleChangeTextMeOption = (event) => {
		if (reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: event.target.checked
			})
		}

		if (event.target.checked === false) {
			setValue('support_phone_number', '')
		}
	}

	const handleCurrentCountry = (countryCode) => {
		const current = (countries.filter(country => country.code === countryCode))[0]
		setCurrentCountry(current)
	}

	const fetchAllSuggestion = () => {
		axios.get(`contracts/get-all-suggestion`).then(response => {
			const { paymentTerm, shipping, contractStatus, sla, serviceProvider } = response.data.data
			setContractStatusMenu(contractStatus)
			setPaymentTermMenu(paymentTerm)
			setServiceProviderMenu(serviceProvider)
			setSlaMenu(sla)
			setShippingLocationMenu(shipping)
		}).catch(err => {
			console.log('err', err);
			//   toast.error('Something went wrong!')
		})
	}

	function fetchContractType() {
		axios.get(`/contracts/get-contract-types`).then(response => {
			// console.log(response.data.data)
			const check = response.data.data.map((item) => item.display_name)
			setContractsMenu(check)
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

	function fetchParentContract() {
		axios.get(`contracts/get-parent-contracts`).then(response => {
			// console.log(response.data.data)
			setParentContractMenu(response.data.data)
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}
	/*----------------------------------------------------------------------------------------------*/

	/*--------------------------------------UseEffect Declarations----------------------------------*/
	useEffect(() => {
		if (!editFormState) return

		const getCountryDetails = (countries.filter(country => country.currency === editFormState.contract_payment_object?.country))[0]

		handleCurrentCountry(getCountryDetails?.code)
		setValue('contractId', editFormState.contract_number)
		setValue('contractName', editFormState.contract_name),
		setValue('contractDescription', editFormState.contract_description == 'No Contract Description'? '':editFormState.contract_description)

		setContractStartDate((prev) => {
			return {
				...prev,
				date: editFormState.original_info.contract_start_date
			}
		})
		setContractEndDate((prev) => {
			return {
				...prev,
				date: editFormState.original_info.contract_end_date
			}
		})
		setPoDate((prev) => {
			return {
				...prev,
				date: editFormState.original_info.payment_order_date
			}
		})

		// setValue('contractStartDate', editFormState.original_info.contract_start_date)
		// setValue('contractEndDate', editFormState.original_info.contract_end_date)
		setValue('contractStatus', editFormState.contract_status_object)
		setValue('currentShippingLocation', editFormState.location_object)
		setValue('contractType', editFormState?.contract_type_object?.display_name)
		setValue('renewal', editFormState['new/renew'])
		setValue('master', editFormState.master_object)
		setValue('parentContract', editFormState.parent_contract_object)
		setValue('sla', editFormState.service_level_agreement_object)
		setValue('paymentAmount', formatPriceFieldOnBlur(editFormState.contract_payment_object?.payment_amount))
		setValue('country', getCountryDetails)
		setValue('paymentTerm', editFormState.contract_payment_object?.payment_term)
		setValue('poNumber', editFormState.contract_payment_object?.po_number)
		// setValue('poDate', (editFormState.original_info.payment_order_date))
		setValue('provider', editFormState.service_provider_object)

		switch (editFormState.reminder) {
			case 'no_reminder':
				setNoReminderCheckBox(true)
				break;

			case 'remind_before_45_days_and_30_days':
				setReminderCheckBoxes({
					...reminderCheckboxes,
					term30: true,
					term45: true
				})
				break;

			case 'remind_before_45_days':
				setReminderCheckBoxes({
					...reminderCheckboxes,
					term45: true
				})
				break;

			case 'remind_before_30_days':
				setReminderCheckBoxes({
					...reminderCheckboxes,
					term30: true
				})
				break;
		}

		const avoidCases = ['No Text Contact Number', '-', '']

		// setValue('support_phone_number', avoidCases.includes(editFormState.text_contact_number) ? '' : editFormState.text_contact_number)
			setFormattedNumber(avoidCases.includes(editFormState.text_contact_number) ? '' : editFormState.text_contact_number)

		if (!avoidCases.includes(editFormState.text_contact_number)) {
			setReminderCheckBoxes({
				...reminderCheckboxes,
				supportContact: true
			})
		}

	}, [editFormState])

	useEffect(() => {
		fetchAllSuggestion()
		fetchContractType()
		fetchParentContract()
	}, [])
	function handlPaymentTermCloseDialog(new_payment_term) {
    if (new_payment_term) {
      setValue("paymentTerm", new_payment_term);
    }
    setOpenPaymentTermQuickAddDialog(false);
    fetchAllSuggestion();
  }

	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}
	/*----------------------------------------------------------------------------------------------*/

	return (
		<div>
			{/* Contract Type */}
			<ContractTypeQuickAdd
				contractTypeField={contractTypeField}
				modal={classes.modal}
				setNewContractType={setNewContractType}
				setContractTypeField={setContractTypeField}
				addNewContractType={addNewContractType}
				newContractType={newContractType}
			/>

			{/* Service Level Agreement */}
			<SLAQuickAdd
				slaField={slaField}
				modal={classes.modal}
				setNewSla={setNewSla}
				setSlaField={setSlaField}
				addNewSla={addNewSla}
				newSla={newSla}
			/>

			{/* Contract Status */}
			<ContractStatusQuickAdd
				contractStatusField={contractStatusField}
				modal={classes.modal}
				setContractStatusField={setContractStatusField}
				setNewContractStatus={setNewContractStatus}
				addNewContractStatus={addNewContractStatus}
				newContractStatus={newContractStatus}
			/>

			{/* Payment Term */}
			<QuickAddPaymentTerm
				open={openPaymentTermQuickAddDialog}
				handleCloseDialog={handlPaymentTermCloseDialog}
			/>

			{/* <PaymentTermQuickAdd
				paymentTermField={paymentTermField}
				modal={classes.modal}
				setNewPaymentTerm={setNewPaymentTerm}
				setPaymentTermField={setPaymentTermField}
				addNewPaymentTerm={addNewPaymentTerm}
				newPaymentTerm={newPaymentTerm}
			/> */}

			{/* Service Provider */}
			<ServiceProviderQuickAdd
				serviceProviderField={serviceProviderField}
				classes={classes}
				setServiceProviderField={setServiceProviderField}
				serviceProviderErrorData={serviceProviderErrorData}
				addNewServiceProvider={addNewServiceProvider}
			/>

			{/* Shipping Location */}
			<ShippingLocationQuickAdd
				createNewShippingLocation={createNewShippingLocation}
				modal={classes.modal}
				setCreateNewShippingLocation={setCreateNewShippingLocation}
				manageShippingFormData={manageShippingFormData}
				shippingFormErrorData={shippingFormErrorData}
			/>

			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.customDrawerPaper }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<DialogTitle>{Boolean(editFormState) ? 'Update Contract' : 'Add Contract'}</DialogTitle>
					<IconButton style={{ marginRight: '15px' }} onClick={onCancel}>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={handleSubmit(manageFormData, errorData)} >

					{/*--------------------Contract Description----------------------- */}
					<Accordion defaultExpanded={true} className={classes.subSection}>
						<Typography style={{ fontSize: '16px' }}>	Required Information *</Typography>
						<Divider style={{ marginBottom: '10px' }} />
						{/* <AccordionSummary
							// expandIcon={<ExpandMore />}
							style={{ pointerEvents: 'none' }}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Contract Description</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> 
							</div>
						</AccordionSummary> */}

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="contractId"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label='Contract Number'
													fullWidth
													inputProps={{ readOnly: false }}
													error={!!errors.contractId}
													helperText={errors?.contractId?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
													disabled={Boolean(editFormState)}
												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="contractName"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label='Contract'
													fullWidth
													error={!!errors.contractName}
													helperText={errors?.contractName?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="contractDescription"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextareaAutosize
												{...field}
												variant='outlined'
												label='Contract Description'
												placeholder='Contract Description'
												fullWidth
												aria-label="maximum height"
												maxRows={4}
												error={!!errors.contractDescription}
												helperText={errors?.contractDescription?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
												className='customTextArea'
												style={{ backgroundColor: '#fff', width: '100%', border: '1px solid #aaa', borderRadius: '5px', height: '100px', padding: '12px 13px' }}
											/>
										</>
									)}
								/>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<div className='cstm-date' style={{ marginRight: '10px', width: '100%' }}>
									{/* <Controller
										name="contractStartDate"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label="Start Date"
													type='date'
													InputLabelProps={{ shrink: true }}
													onChange={(event)=>{
														field.onChange(event.target.value)
														setStartDate(event.target.value)
													}}
													fullWidth
													error={!!errors.contractStartDate}
													helperText={errors?.contractStartDate?.message}
													FormHelperTextProps={{
														style: { 
															margin : 0, 
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													inputProps={{ max: `${endDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>												
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={contractStartDate}
										setState={setContractStartDate}
										required={true}
										label='Contract Start Date*'
										maxDate={contractEndDate.date}
										condition='Contract End Date'									
									/> */}
									<CustomDatePickerV2
										state={contractStartDate}
										setState={setContractStartDate}
										required={true}
										label='Contract Start Date*'
										maxDate={contractEndDate.date}
										condition='Contract End Date'									
									/>
								</div>

								<div className='cstm-date' style={{ width: '100%' }}>
									{/* <Controller
										name="contractEndDate"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													label="End Date"
													type='date'
													InputLabelProps={{ shrink: true }}
													fullWidth
													onChange={(event) => {
														field.onChange(event.target.value)
														setEndDate(event.target.value)
													}}
													error={!!errors.contractEndDate}
													helperText={errors?.contractEndDate?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													inputProps={{ min: `${startDate}` }}
													style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/> */}
									{/* <CustomDatePicker
										state={contractEndDate}
										setState={setContractEndDate}
										required={true}	
										label='Contract End Date*'
										minDate={contractStartDate.date}
										condition='Contract Start Date'											
									/> */}
									<CustomDatePickerV2
										state={contractEndDate}
										setState={setContractEndDate}
										required={true}
										label='Contract End Date*'
										minDate={contractStartDate.date}
										condition='Contract Start Date'									
									/>
								</div>

							</div>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="contractStatus"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={contractStatusMenu}
													disablePortal
													onChange={(event, value) => { field.onChange(value) }}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '20px' }}>
															{children}
															{createCStatusPermission && <Button
																style={{ width: '100%' }}
																variant="outlined"
																color="primary"
																onMouseDown={() => setContractStatusField(true)}
															>
																Add Contract Status
															</Button>}
														</Paper>
													)}
													getOptionLabel={option => option.contract_status_name ?? ''}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	required
																	{...params}
																	name="contractStatus"
																	label='Contract Status'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="currentShippingLocation"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={shippingLocationMenu}
													disablePortal
													onChange={(event, value) => { field.onChange(value) }}
													PaperComponent={({ children }) => (
														<Paper>
															{children}
															{createCLocationPermission && <Button
																style={{ width: '100%' }}
																variant="outlined"
																color="primary"
																onMouseDown={() => setCreateNewShippingLocation(true)}
															>
																Add Location
															</Button>}
														</Paper>
													)}
													getOptionLabel={option => {
														return generateProperLocationString({
															location_name: option?.location_name ?? '',
															address1: option?.address1 ?? '',
															address2: option?.address2 ?? '',
															city: option?.city ?? '',
															state: option?.state ?? '',
															country: option?.country ?? ''
														})
														// `${option.location_name ?? ''} - ${option.address1}, ${option.city}, ${option.state}, ${option.country}`
													}}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	required
																	{...params}
																	name="currentShippingLocation"
																	label='Location'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>

						</AccordionDetails>
					</Accordion>

					{/*---------------Contract Type------------------- */}
					<Accordion className={classes.subSection} expanded={contractTypeAccordionExpand}>
						<AccordionSummary onClick={manageContractTypeAccordion} expandIcon={<ExpandMore />}>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Contract Type</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

							<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
								<div style={{ display: 'flex', marginBottom: '10px', width: '100%' }}>
									{/* {contractTypeField == 'dropdown' && */}
									<>
										<Controller
											name="contractType"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
												<>
													<Autocomplete
														{...field}
														style={{ width: '100%' }}
														options={contractsMenu}
														disablePortal
														// getOptionLabel={option => option.display_name }
														onChange={(event, value) => field.onChange(value)}
														PaperComponent={({ children }) => (
															<Paper style={{ marginTop: '50px' }}>
																{children}
																{createCTypePermission && <Button
																	style={{ width: '100%' }}
																	variant="outlined"
																	color="primary"
																	onMouseDown={() => setContractTypeField(true)}
																>
																	Add Contract Type
																</Button>}
															</Paper>
														)}
														renderInput={params => {
															return (
																<div ref={params.InputProps.ref}>
																	<TextField
																		required
																		{...params}
																		name="contractType"
																		label='Contract Type'
																		variant='outlined'
																		fullWidth
																		error={!!error}
																		helperText={error?.message}
																		inputRef={contractTypeInputRef}
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#f4f4f4',
																				width: '100%',
																				paddingTop: '2px'
																			}
																		}}
																		style={{ width: '100%', backgroundColor: '#fff' }}
																	/>
																</div>
															)
														}}
													/>
													{/* <div style={{ width: '84px', padding: '10px', border: '1px solid #aaa', borderLeft: '0', borderRadius: '0 4px 4px 0' ,height:'53px'}}>
														<Button 
															variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
															onClick={()=>setContractTypeField(true)}
														>New</Button>
													</div> */}
												</>
											)}
										/>
									</>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="renewal"
									className="mb-16"
									control={control}
									render={({ field }) => {
										return (
											<>
												<TextField
													required
													{...field}
													variant='outlined'
													select
													label="New/Renew"
													fullWidth
													error={!!errors.renewal}
													helperText={errors?.renewal?.message}
													inputRef={renewalInputRef}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ width: '100%', backgroundColor: '#fff' }}
												>
													{['New', 'Renew'].map((option) => (
														<MenuItem key={option} value={option}>
															{option}
														</MenuItem>
													))}
												</TextField>
											</>
										)
									}}
								/>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="master"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => {
										return (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
													disablePortal
													disabled={!!editFormState}
													getOptionLabel={option => option.label ?? ''}
													onChange={(event, value) => {
														setChildContract(!value.value)
														field.onChange(value)
													}}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '50px' }}>{children}</Paper>
													)}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	required
																	{...params}
																	name="master"
																	label='Master'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={masterInputRef}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	// disabled={!!editFormState}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)
									}}
								/>
							</div>

							{(childContract || editFormState?.master === 'No') &&
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<Controller
										name="parentContract"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={parentContractMenu}
													disablePortal
													getOptionLabel={option => `${option.entity_external_platform_id ?? ''}-${option.contract_name}`}
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '50px' }}>{children}</Paper>
													)}
													disabled={!!editFormState}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	name="parentContract"
																	label='Parent Contract'
																	variant='outlined'
																	fullWidth
																	error={!!error}
																	helperText={error?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	disabled={!!editFormState}
																	style={{ width: '100%', backgroundColor: '#fff' }}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							}
						</AccordionDetails>
					</Accordion>

					{/*---------------Service Level Agreement------------------- */}
					<Accordion expanded={slaAccordionExpand} className={classes.subSection}>
						<AccordionSummary
							onClick={manageSLAAccordion}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Service Level Agreement</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="sla"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => (
										<>
											<Autocomplete
												{...field}
												style={{ width: '100%' }}
												options={slaMenu}
												disablePortal
												getOptionLabel={option => option.sla_name ?? ''}
												onChange={(event, value) => field.onChange(value)}
												PaperComponent={({ children }) => (
													<Paper style={{ marginTop: '50px' }}>
														{children}
														{createSLAPermission && <Button
															style={{ width: '100%' }}
															variant="outlined"
															color="primary"
															onMouseDown={() => setSlaField(true)}
														>
															Add Service Level Agreement
														</Button>}
													</Paper>
												)}
												renderInput={params => {
													return (
														<div ref={params.InputProps.ref}>
															<TextField
																required
																{...params}
																name="sla"
																label='Service Level Agreement'
																variant='outlined'
																fullWidth
																error={!!error}
																helperText={error?.message}
																inputRef={slaInputRef}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																style={{ width: '100%', backgroundColor: '#fff' }}

															/>
														</div>
													)
												}}
											/>
											{/* <div style={{ width: '84px', padding: '10px', border: '1px solid #aaa', borderLeft: '0', borderRadius: '0 4px 4px 0' ,height:'53px'}}>
												<Button 
													variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
													onClick={()=>setContractTypeField(true)}
												>New</Button>
											</div> */}
										</>
									)}
								/>
							</div>
						</AccordionDetails>
					</Accordion>

					{/*----------------------Payment------------------- */}
					<Accordion expanded={paymentAccordionExpand} className={classes.subSection}>
						<AccordionSummary
							onClick={managePaymentAccordion}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Payment</Typography>
							</div>
						</AccordionSummary>

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div className='w-full'>
								<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
									<div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
										<div style={{ marginBottom: '10px', marginRight: '10px' }}>
											<Controller
												name="paymentAmount"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<TextField
															inputRef={paymentAmountInputRef}
															required
															{...field}
															variant='outlined'
															label='Amount'
															fullWidth
															error={!!errors.paymentAmount}
															helperText={errors?.paymentAmount?.message}
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
															onBlur={()=>{
																setValue('paymentAmount', formatPriceFieldOnBlur(getValues('paymentAmount')))																		
															}}
															onKeyPress={(event) => {
																if (!/[0-9,.]+/.test(event.key)) {
																	event.preventDefault();
																}
															}}
															onChange={(event) => { field.onChange(event.target.value ?? '') }}
															style={{ width: '100%', backgroundColor: '#fff' }}
															InputProps={{
																startAdornment:
																	<InputAdornment>
																		<Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
																	</InputAdornment>
															}}
														/>
													</>
												)}
											/>
										</div>
										<div style={{ display: 'flex', marginBottom: '10px', width: '70%' }}>
											<Controller
												name="country"
												className="mb-16"
												control={control}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															options={countries}
															disablePortal
															getOptionLabel={option => option.currency ?? ''}
															onChange={(event, value) => {
																field.onChange(value)
																if (value) handleCurrentCountry(value?.code)
															}}
															PaperComponent={({ children }) => (
																<Paper style={{ marginTop: '50px' }}>{children}</Paper>
															)}
															renderOption={(option) => {
																console.log('1633: ',option);
																return (
																	<div className='flex items-center'>
																		<img
																			loading="lazy"
																			// width={"20px"}
																			className='mr-5 py-2'
																			src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
																			srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
																			alt=""
																		/>
																		<span className='pt-2'>{option.currency}</span>
																	</div>
																)
															}}
															renderInput={params => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			required
																			{...params}
																			name="country"
																			label='Currency'
																			variant='outlined'
																			fullWidth
																			error={!!error}
																			helperText={error?.message}
																			inputRef={ref}
																			FormHelperTextProps={{
																				style: {
																					margin: 0,
																					backgroundColor: '#f4f4f4',
																					width: '100%',
																					paddingTop: '2px'
																				}
																			}}
																			style={{ width: '100%', backgroundColor: '#fff' }}
																			InputProps={{
																				startAdornment:
																					<InputAdornment style={{ marginRight: '10px', border: '1px solid black', height: '50%', width: '50%' }}>
																						<img
																							loading="lazy"
																							width="40"
																							src={`https://flagcdn.com/w20/${currentCountry?.code.toLowerCase()}.png`}
																							srcSet={`https://flagcdn.com/w40/${currentCountry?.code.toLowerCase()}.png 2x`}
																							alt=""
																						/>
																					</InputAdornment>
																			}}
																		/>
																	</div>
																)
															}}
														/>
													</>
												)}
											/>
										</div>
									</div>
									<div style={{ width: '100%' }} >
										<Controller
											name="paymentTerm"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
												<>
													<Autocomplete
														{...field}
														style={{ width: '100%' }}
														// options={[{id: 1, name: '1 Month'}, {id: 1, name: '3 Months'}, {id: 1, name: '6 Months'}, {id: 1, name: '12 Months'}]}
														options={paymentTermMenu}
														disablePortal
														getOptionLabel={option => option.display_name ?? ''}
														onChange={(event, value) => field.onChange(value)}
														PaperComponent={({ children }) => (
															<Paper>
																{children}
																{createCPaymentTermPermission && <Button
																	style={{ width: '100%' }}
																	variant="outlined"
																	color="primary"
																	onMouseDown={() => setOpenPaymentTermQuickAddDialog(true)}
																>
																	Add Payment Term
																</Button>}
															</Paper>
														)}
														renderInput={params => {
															return (
																<div ref={params.InputProps.ref}>
																	<TextField
																		required
																		{...params}
																		inputRef={paymentTermInputRef}
																		name="paymentTerm"
																		label='Payment Term'
																		variant='outlined'
																		fullWidth
																		error={!!error}
																		helperText={error?.message}
																		// inputRef={ref}
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#f4f4f4',
																				width: '100%',
																				paddingTop: '2px'
																			}
																		}}
																		style={{ width: '100%', backgroundColor: '#fff' }}
																	/>
																</div>
															)
														}}
													/>
												</>
											)}
										/>
									</div>
								</div>
							</div>
							<div className='cstm-date' style={{ display: 'flex', justifyContent: 'center' }}>
								<Controller
									name="poNumber"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												inputRef={poNumberInputRef}
												required
												{...field}
												variant='outlined'
												label='PO Number'
												fullWidth
												error={!!errors.poNumber}
												helperText={errors?.poNumber?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
												style={{ width: '100%', marginRight: '10px', backgroundColor: '#fff' }}
											/>
										</>
									)}
								/>

								{/* <Controller
									name="poDate"
									className="mb-16"
									control={control}
									render={({ field }) => (
										<>
											<TextField
												inputRef={poDateInputRef}
												required
												{...field}
												variant='outlined'
												label="PO Date"
												type='date'
												InputLabelProps={{ shrink: true }}
												onChange={(event) => {
													field.onChange(event.target.value)
													setStartDate(event.target.value)
												}}
												fullWidth
												error={!!errors.poDate}
												helperText={errors?.poDate?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
												style={{ width: '100%', backgroundColor: '#fff' }}
											/>
										</>
									)}
								/> */}

								{/* <CustomDatePicker
									state={poDate}
									setState={setPoDate}
									required={true}
									label='PO Date*'										
								/>	 */}
								<CustomDatePickerV2
									state={poDate}
									setState={setPoDate}
									required={true}
									label='PO Date*'										
								/>	
							</div>
						</AccordionDetails>
					</Accordion>

					{/*---------------Provider------------------- */}
					<Accordion expanded={serviceProviderAccordionExpand} className={classes.subSection}>
						<AccordionSummary
							onClick={manageServiceProviderAccordion}
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Service Provider</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>

						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Controller
									name="provider"
									className="mb-16"
									control={control}
									render={({ field: { ref, ...field }, fieldState: { error } }) => (
										<>
											<Autocomplete
												{...field}
												style={{ width: '100%' }}
												options={serviceProviderMenu}
												disablePortal
												getOptionLabel={option => option.service_provider_name ?? ''}
												onChange={(event, value) => field.onChange(value)}
												PaperComponent={({ children }) => (
													<Paper style={{ marginTop: '20px' }}>
														{children}
														{createCServiceProviderPermission && <Button
															style={{ width: '100%' }}
															variant="outlined"
															color="primary"
															onMouseDown={() => setServiceProviderField(true)}
														>
															Add Service Provider
														</Button>}
													</Paper>
												)}
												renderInput={params => {
													return (
														<div ref={params.InputProps.ref}>
															<TextField
																required
																{...params}
																name="provider"
																label='Service Provider'
																variant='outlined'
																fullWidth
																error={!!error}
																helperText={error?.message}
																inputRef={serviceProviderInputRef}
																FormHelperTextProps={{
																	style: {
																		margin: 0,
																		backgroundColor: '#f4f4f4',
																		width: '100%',
																		paddingTop: '2px'
																	}
																}}
																style={{ width: '100%', backgroundColor: '#fff' }}
															/>
														</div>
													)
												}}
											/>
											{/* <div style={{ width: '84px', padding: '10px', border: '1px solid #aaa', borderLeft: '0', borderRadius: '0 4px 4px 0' ,height:'53px'}}>
												<Button 
													variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
													onClick={()=>setContractTypeField(true)}
												>New</Button>
											</div> */}
										</>
									)}
								/>
							</div>
						</AccordionDetails>
					</Accordion>

					{/*---------------Reminder------------------- */}
					<Accordion className={classes.subSection}>
						<AccordionSummary
							expandIcon={<ExpandMore />}
						>
							<div style={{ width: '100%' }}>
								<Typography variant='h6'>Reminder</Typography>
								{/* <Divider style={{ marginBottom: '10px' }} /> */}
							</div>
						</AccordionSummary>
						<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px' }}>
								<Typography style={{ fontSize: '17px' }}>Dalos will notify you automatically 60 days before contract expiration.</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									checked={noReminderCheckBox}
									onChange={handleChangeNoReminderCheckbox}
									disabled={reminderCheckboxes.supportContact === true || reminderCheckboxes.term30 === true || reminderCheckboxes.term45 === true}
								/>
								<Typography style={{ fontSize: '17px' }}>Do not remind me</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									disabled={noReminderCheckBox === true}
									name={'term45'}
									checked={reminderCheckboxes.term45}
									onChange={handleChangeReminderCheckBoxes}
								/>
								<Typography style={{ fontSize: '17px' }}>Notify me 45 days</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									disabled={noReminderCheckBox === true}
									name={'term30'}
									checked={reminderCheckboxes.term30}
									onChange={handleChangeReminderCheckBoxes}
								/>
								<Typography style={{ fontSize: '17px' }}>Notify me 30 days</Typography>
							</div>
							<div style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', alignItems: 'center' }}>
								<Checkbox
									disabled={noReminderCheckBox === true || (reminderCheckboxes.term45 === false && reminderCheckboxes.term30 === false)}
									name={'supportContact'}
									checked={reminderCheckboxes.supportContact}
									onChange={handleChangeTextMeOption}
								/>
								<div className='flex items-center' style={{ width: '60%' }}>
									<Typography style={{ fontSize: '17px', marginRight: '10px', width: '30%' }}>Text Me</Typography>
									<Controller
										name="support_phone_number"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Support Phone Number'
													fullWidth
													value={formattedNumber}
													onChange={(e)=>{
														changeNumberFormat(e)
													}}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													error={!!errors.support_phone_number}
													helperText={errors?.support_phone_number?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ width: '100%', backgroundColor: '#fff' }}
													disabled={noReminderCheckBox === true || reminderCheckboxes.supportContact === false}
												/>
											</>
										)}
									/>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<div style={{ padding: 0, margin: 20, display: 'flex', justifyContent: 'left' }}>
						<Button onClick={onSubmitAccordionExpand} className={classes.endButtons} style={{ marginRight: '10px' }} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{Boolean(editFormState) ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons} variant='outlined' color='primary' onClick={onCancel}>Cancel</Button>
					</div>
				</form>
			</Drawer>
		</div>
	)
}

export default AddContractFormDialog
