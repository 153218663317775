import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { TextField, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import { usePermission } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const mainTheme = useSelector(selectMainTheme);
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const { hasPermission } = usePermission()
	const createServiceRequestStatusPermission = hasPermission('add-service_request_status')
	const addReportPermission = hasPermission('add-report')

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			{/* Main header */}
			<div className="flex items-center">
				<svg width="34" height="34" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M30.6 20.5L37.65 13.4L36.25 12L30.6 17.65L27.75 14.8L26.35 16.25L30.6 20.5ZM6 40V37H30V40H6ZM31.9947 25.5C29.3649 25.5 27.125 24.5732 25.275 22.7197C23.425 20.8662 22.5 18.6245 22.5 15.9947C22.5 13.3649 23.4268 11.125 25.2803 9.275C27.1338 7.425 29.3755 6.5 32.0053 6.5C34.6351 6.5 36.875 7.42677 38.725 9.2803C40.575 11.1338 41.5 13.3755 41.5 16.0053C41.5 18.6351 40.5732 20.875 38.7197 22.725C36.8662 24.575 34.6245 25.5 31.9947 25.5ZM6 23V20H19.1C19.2773 20.5441 19.48 21.0635 19.708 21.5581C19.936 22.0527 20.1833 22.5333 20.45 23H6ZM6 31.5V28.5H26.95C27.41 28.7121 27.9 28.8902 28.42 29.0341C28.94 29.178 29.4667 29.2833 30 29.35V31.5H6Z" fill="black" />
				</svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Service Ticket Statuses List
				</Typography>
			</div>

			<div style={{display:'flex'}}>

				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}

				{/* Add new asset status */}
				{createServiceRequestStatusPermission && <Button
					variant="contained"
					color="secondary"
					className={`w-full ${classes.buttonSquare}`}
					style={{width:'270px', marginRight: '10px'}}
					onClick = {()=>dispatch(openFormViewerDialog())}
					startIcon={
					<Icon fontSize="small" title="Add Asset Status">
						add
					</Icon>}>
						Add Service Ticket Status
				</Button>}

				{/* search */}
				<div className={classes.buttonContainer}>
					<ThemeProvider theme={mainTheme}>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
							style={{minWidth:"40px"}}
						>
							<TextField
								placeholder="Search Status"
								className="flex flex-1 mx-8 p-3"
								fullWidth
								InputProps={{
									disableUnderline: true,
									
								}}
								value={query_data ?? '' }
								onChange={(e)=>{
									dispatch(setQuickFilter(e.target.value))
								}}
							/>

							<IconButton
								aria-label="toggle password visibility"
								size="small"
								className=""
							>
								<Icon>search</Icon>
							</IconButton>
						</Paper>
					</ThemeProvider>
				</div>
			</div>
		</div>
	)
}

export default DynamicFieldsHeader;
