import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { rememberUser, submitLogin, submitGoogle, submitMicrosoft } from '../../auth/store/loginSlice';
import { useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from "react-microsoft-login";
import axiosgeneral from "@fuse/utils/axios";
import axios from 'axios';
import { setUserData } from '../../auth/store/userSlice';
import { refreshTokenSetup } from './refreshToken';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Icon } from '@material-ui/core';
import Test from './Test';

const useStyles = makeStyles(theme => ({
	root: {
        // '& .MuiOutlinedInput-adornedStart-90': {
        //     padding: 0
        // },
        // '& .MuiInputAdornment-root-119': {
        //     marginRight: '10px',
        // }
    },
	loaderContainer:{
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		alignItems: 'center',
		backgroundColor: '#111'
	},
	loaderLogo: {
		fontWeight: 600,
		fontSize: '45px',
		color: '#fff',
		paddingBottom: '10px'
	},
	progress: {
		height: '50px !important',
		width: '50px !important',
		color: '#61DAFB',
	},
	leftSection: {},
	rightSection: {
		// backgroundImage: 'url("assets/images/backgrounds/sam_registration.png")',
        // backgroundRepeat: 'no-repeat',
        // backgroundAttachment: 'fixed', 
        // backgroundSize: '100% 100%',
        backgroundImage: 'url("assets/images/backgrounds/sam_registration_rightside.png")',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'initial', 
        backgroundSize: '111% 101%',
	},
    logo: {
        fontSize: 80,
        fontWeight: 600,
        // marginBottom: '20px',
        color: '#6b2c57'
    },
    subLogo: {
        fontSize: 35,
        fontWeight: 600,
        marginBottom: '30px',
        color: '#6b2c57'
    },
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup.string().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i,"You must enter a valid email").required('You must enter a email'),
	password: yup
		.string()
		.required('Please enter your password.')
		.min(8, 'Password is too short - should be 8 chars minimum.')
});

const defaultValues = {
	email: '',
	password: '',
	remember: false
};

function Login({ user, rememberUser, submitLogin, loggingIn, submitGoogle, submitMicrosoft }) {

	const [isRightDomain, setIsRightDomain] = useState(true)
	const [loading, setLoading] = useState(false)

	const domainCheck = () => {
		setLoading(true)
		axiosgeneral.post('/auth/check-domain', {subdomain: window.location.host}).then(res => {
			setIsRightDomain(true)
		}).catch(err => {
			// console.log('26=>err',err)
			setIsRightDomain(false)
		}).finally(() => {
			setLoading(false)
		})
	}
	

	useEffect(() => {
		domainCheck()
	}, [])

	

	const classes = useStyles();

	const [showPassword, setShowPassword] = useState(false);

	const { control, formState, handleSubmit, reset } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	function toggleShowPassword() {
		setShowPassword(!showPassword);
	}
	const { isValid, dirtyFields, errors } = formState;

	function onSubmit(formData) {
		reset(defaultValues);
		submitLogin(formData);
	}


	const onSuccess = (res) =>{
		const idToken = res.access_token;
		const google_id= (new Date().getTime()).toString();

		submitGoogle({idToken, google_id});
	};

	const onFailure = (res) => {
		console.log('Login failed: res:', res);
	};

	const signIn = useGoogleLogin({
		onSuccess,
		onFailure
	});
	const authHandler = (err, data) => {
		console.log(err, data);
		if(err)
			return;
		const uniqueId = data.uniqueId;
		const username = data.account.userName;
		//console.log(uniqueId, username)
		submitMicrosoft({uniqueId, username})
	};

    const onError = (err) => {
        console.log('140=>',err)
    }

	//const microsoftclientId=`${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`;
	//const microsoftclientredirectUri=`${process.env.REACT_APP_SERVER}`== "LOCAL" ?  `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID}` :  `${process.env.REACT_APP_SERVER}`== "DEV" ? `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_DEV}` : `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_STAGING}`

	let microsoftclientId = '', microsoftclientredirectUri = ''
	
	switch(`${process.env.REACT_APP_SERVER}`){
		case "LOCAL": {
			microsoftclientredirectUri = `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID}`
			microsoftclientId = `${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`
			break;
		}

		case "DEV": {
			microsoftclientredirectUri = `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_DEV}`
			microsoftclientId = `${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`
			break;
		}

		case "PRODUCTION": {
			microsoftclientredirectUri = `${process.env.REACT_APP_MICROSOFT_REDIRECT_ID_DEV}`
			microsoftclientId = `${process.env.REACT_APP_MICROSOFT_CLIENT_ID2}`
			break;
		}
	}

	if(loading){
		return (
			<span className={classes.loaderContainer}>
				<span className='flex flex-col' style={{paddingBottom: '20px' }}>
					<Typography className={classes.loaderLogo}>dalos</Typography>
					<span className='flex justify-center'><CircularProgress className={classes.progress} /></span>
				</span>
			</span>
		)
	}

	if(!isRightDomain){
		return <Test />
	}

    return (
		<div
			className={clsx(
				classes.root,
				'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'
			)}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex w-full max-w-400 md:max-w-2xl rounded-20 shadow-2xl overflow-hidden"
                style={{ height:'720px' }}
			>
				<Card
					className={clsx(
						classes.leftSection,
						'flex flex-col w-full max-w-sm items-center justify-center shadow-0'
					)}
					square
				>
					<CardContent className="flex flex-col items-center justify-center w-full py-68 max-w-320">
						{/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, width: '100%', transition: { delay: 0.2 } }}> */}
                            <div className="d-flex flex-column items-center mb-40 w-full">
                                <div className={classes.logo}>dalos</div>
                                <h1 style={{ fontWeight: 600, marginBottom: '10px', color:'#6b2c57' }}>Sign In</h1>
                            </div>
						
						{/* </motion.div> */}

						<form
							name="loginForm"
							noValidate
							className="flex flex-col justify-start w-full login_holder"
							onSubmit={handleSubmit(onSubmit, onError)}
						>
                            <div className='mb-7'>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-16"
                                            label="Email"
                                            autoFocus
                                            type="email"
											inputProps={{
												autocomplete: 'new-password',
											 }}
											autoComplete="tel-national email"
                                            error={!!errors.email}
                                            helperText={errors?.email?.message}
                                            variant="outlined"
                                            required
                                            // size='small'
                                            fullWidth
                                            style={{ padding: 0, width: '100%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment style={{ marginRight: '14px' }} className='icon_holder'>
                                                        <Icon style={{ opacity: '0.7', fontSize: '23px' }}>mail_outlined</Icon>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                        {/* <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel> */}
                                            <TextField
                                                {...field}
                                                variant='outlined'
                                                id="outlined-adornment-password"
                                                label='Password'
                                                type={showPassword ? 'text' : 'password'}
                                                error={!!errors.password}
                                                // size='small'
                                                style={{ width: '100%' }}
                                                helperText={errors?.password?.message}
                                                required
												inputProps={{
													// autocomplete: 'new-password',
													form: {
													autocomplete: 'off',
													},
												}}
                                                InputProps={{
                                                    startAdornment:(
                                                        <InputAdornment style={{ marginRight: '14px' }} className='icon_holder'>
                                                            <Icon
                                                            // style={{padding: '12px 12px 12px 0', width:'100%'}}
                                                            style={{ opacity: '0.7', fontSize: '25px', cursor: 'pointer' }}
                                                            aria-label="toggle password visibility"
                                                            onClick={toggleShowPassword}
                                                            onMouseDown={toggleShowPassword}
                                                            edge="end"
                                                            >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </Icon>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                labelwidth={70}
                                            />
                                            {/* <FormHelperText style={{ color: '#f44336' }}>{errors?.password?.message}</FormHelperText> */}
                                        {/* </FormControl> */}
                                        </>
                                    )}
                                />
                            </div>

							<div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
								<Controller
									name="remember"
									control={control}
									render={({ field }) => (
										<FormControl>
											<FormControlLabel label="Remember Me" control={<Checkbox {...field}/>} />
										</FormControl>
									)}
								/>

								<Link className="font-normal" style={{ color: 'blue' }} to="/reset-password">
									Forgot Password?
								</Link>
							</div>

							<Button
								variant="contained"
								// color="primary"
								// className="mt-16"
                                style={{ borderRadius: 4, width: '100%', backgroundColor: '#F9B84B' }}
								aria-label="LOG IN"
								disabled={_.isEmpty(dirtyFields) || !isValid}
								type="submit"
							>
								{loggingIn? <CircularProgress /> :'Login'}
							</Button>
						</form>

						{/* <div className="my-10 flex items-center justify-center">
							<Divider className="w-32" />
							<span className="mx-8 font-semibold">OR</span>
							<Divider className="w-32" />
						</div> */}

                        {/* <div className='flex flex-col justify-center'>
                           <Button variant="outlined" color="primary" size="small" className="mr-10 mb-8" 
								style={{
									fontSize: '10px', 
									borderRadius:'4px', 
									width: '100%' 
								}} onClick={ signIn }>
                                Log in with Google ID
                            </Button>
                            <MicrosoftLogin clientId={microsoftclientId} redirectUri={microsoftclientredirectUri} authCallback={authHandler} >
                                <Button variant="outlined" color="primary" size="small"  style={{ fontSize: '10px', borderRadius:'4px', width: '100%' }}>
                                    Log in with Microsoft ID
                                </Button>
                            </MicrosoftLogin>
                        </div>			 */}
					</CardContent>

					{/* <div className="flex flex-col items-center justify-center pb-32">
						<span className="font-normal">Don't have an account?</span>
						<Link className="font-normal" style={{ color: 'blue' }} to="/registration">
							Sign Up Here
						</Link>
					</div> */}
				</Card>
                
                <div className={clsx(classes.rightSection, 'hidden md:flex flex-1 items-center justify-center p-64')}> </div>

			</motion.div>
		</div>
	);
	
}

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		loggingIn: state.auth.login.loggingIn
	}
}

const mapDispatchToProps = (dispatch) => ({
	rememberUser: remember => dispatch(rememberUser(remember)),
	submitLogin: formData => dispatch(submitLogin(formData)),
	submitGoogle: data => dispatch(submitGoogle(data)),
	submitMicrosoft: data => dispatch(submitMicrosoft(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(Login);
