import useToast from '@fuse/hooks/useToast'
import {makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ColorPicker from '@fuse/components/ColorPicker'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AlertModal from 'helper/alertModal'


const useStyles = makeStyles(theme => (
	{
			uploadImageButton: {
					padding: '6px 16px',
					fontSize: '1.3rem',
					minWidth: '64px',
					boxSizing: 'border-box',
					transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					fontFamily: '"Inter", sans-serif',
					fontWeight: 500,
					lineHeight: 1.75,
					borderRadius: '4px',
					textTransform: 'none',
					backgroundColor: '#6B2C57',
					color: '#ffffff',
					cursor: 'pointer'
			},
			loader: {
					display: 'flex',
					'& > * + *': {
							marginLeft: theme.spacing(2),
					},
			},
			subSection: {
					marginLeft: '20px',
					marginRight: '20px',
					marginBottom: '20px',
					padding: '10px',
					backgroundColor: '#F9FAFB',
					borderRadius: '4px'
			},
			endButtons: {
					borderRadius: '4px'
			},
			drawerWidth: {
					width: 520,
					borderTopLeftRadius: '8px',
					borderBottomLeftRadius: '8px',
					overflowX: 'hidden',
					overflowY: 'hidden'
			},
	}
))

const contractList = ['ABCDEFG1', 'JDFJEWFU432', '2WGBAIU2G3', 'HBWQIEU3BI3']

function AddServiceRequestPriorityDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.departmentApp.formViewDialog)
	const { data:editFromState } = useSelector(({departmentApp}) => departmentApp.formViewDialog);
	const [type, setType] = useState()
	const [disableType, setDisableType] = useState(false)
	const [openAlerModal, setOpenAlertModal] = useState(false);
	const [color, setColor] = useState({name:'black',code:"#000000"});
	// let {editData=[]} = props

	//form validation
	const schema = yup.object({
		priority_name: yup.string().required('Priority name is required!')
		.matches(/^[A-Za-z\s]+$/, 'Priority name cannot contain numbers, alphanumeric characters, or special characters!')
		.matches(/\S/, 'Priority name cannot be only spaces!')
		.max(30, 'Priority name must be at most 30 characters!'),
	})

	const defaultValues = {
		priority_name: '',
	}

	const onCancel = () => {
		reset(defaultValues)
		setType(null)
		// setAllSpan([{
		//     id: Date.now(),
		//     serial_number: '',
		//     asset_tag: ''
		// }])
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		// if(props.editCloseForm){
		//   props?.editCloseForm(true)
		// }
		// if(props.closeForm){
		//   props?.closeForm(true)
		// }		
	}

	const closeDialog = () => {
		// editData = []
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		departmentFormReset(defaultValues)
		setColor({name:'black',code:"#000000"})
	}

	const { 
		control, 
		formState:{errors: departmentFormErrors}, 
		handleSubmit: departmentFormSubmit, 
		reset: departmentFormReset,
		trigger,
		setValue,
		getValues,
		reset
   } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
  });

	useEffect(()=>{
		if(editFromState){
			setDisableType(true)
			setValue('priority_name',editFromState.priority_name)
			setColor(editFromState.priority_color)
		}else{
			setDisableType(false)
		}
	},[editFromState])

	// const { errors } = formState

	function editAssetStatus (payload) {
		axios.post('/service-request-priority/edit', payload).then(response => {

			departmentFormReset(defaultValues)
			closeDialog()
			toast.success('Service ticket priority updated Successfully.')
			if(props.getAssetStatus){
				props.getAssetStatus()
			}
			  
		}).catch(err => {
			toast.error(err.response.data.message);
			console.log('212=>',err);
		})
	}


	const onSubmit = async() => {
		await trigger();
		if(Object.keys(departmentFormErrors).length !== 0) { 
				return;
		}
		if(editFromState){
			let payload = {
				name:getValues().priority_name,
				edit_id:Number(editFromState.id),
				priority_color: color
			}
			editAssetStatus(payload)
		}else{
			const payload = {
				name: getValues().priority_name,
				priority_color: color
		  }
		  axios.post('/service-request-priority/add', payload).then(response => {

				departmentFormReset(defaultValues)
				toast.success('Service ticket priority added successfully.')
				closeDialog()
				props.getAssetStatus()
				
		  }).catch(err => {
				toast.error(err.response.data.message);
				console.log('234: ',err);
		  })
		}
		
		// } 
		
}

const handleAlertModal=(_, reason) => {
	if (reason === 'backdropClick') {
	  return;
	}
	setOpenAlertModal(!openAlerModal)
}

const handleCloseAlertModal=() =>{
	setOpenAlertModal(false)
	closeDialog()
	onCancel()
}

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>{editFromState?'Update':'Add'} Service Ticket Priority</h4>
					<IconButton onClick={()=>{handleAlertModal()}} style={{padding: '0px'}}>
							<Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
					</IconButton>
        </div>
				<div className='progressbar_holder'>
					<div className='progress'>
							&nbsp;
					</div>
				</div>
					<div style={{paddingTop:'30px'}} className='cstm_wizard_holder'>
						<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
								<div className='content_holder'>
									<div className="text-left">
										<div className='mb-10'>
											<Typography className='status_text'>
											        Priority Name
													<span className='required_span'>*</span>
											</Typography>
											<Controller
                            name="priority_name"
                            className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        // label='Department Name'
                                        required
                                        // fullWidth
                                        error={!!departmentFormErrors.priority_name}
                                        helperText={departmentFormErrors?.priority_name?.message}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                        style={{ width: '100%', backgroundColor: '#fff' }}
                                    />
                                </>
                            )}
                        />
										</div>
										<div className='mb-10'>
										<Typography className='status_text'>Choose Color
										<span className='required_span'>*</span>
										</Typography>
										<ColorPicker setColor={setColor} color={color}/>

									</div>
									</div>
								</div>
								{/* <DialogActions className="action_footer_holder"> */}
								<div className='btn_holder'>
									<Button
										color="inherit"
										onClick={()=>{handleAlertModal()}}
										className='back_btn secondary_btn'
									>
									Cancel
									</Button>
									<Button onClick={departmentFormSubmit(onSubmit)} className='next_btn'>                                                
										Proceed
									</Button>
								</div>
							</div>
						</Box>
					</div>
			</Drawer>

			{openAlerModal &&
        <AlertModal  openAlerModal module="service ticket priority" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
      }
		</div>
	)
}

export default AddServiceRequestPriorityDialog
