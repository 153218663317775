import _ from '@lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';

function ListView(props) {
	const table_name = "SERVICE_REQUEST_STATUS";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [ logs,setLogs ] = useState([]);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const skipHeader = [
		"id","platform_customer_id", 
		"created_at", "updated_at_", "status_name",
		"description","status_color",'original_info'
	]
	const tableHeaders = ["name", "role", "login_time", "logout_time", "total_session", "ip_address", "company_name", "portal", "pages_accessed"];
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	function cellRendererFramework(params){
		if(params.colDef.field === "pages_accessed"){
			return(<abbr style={{textDecoration: 'none'}} title={params.value}>{params.value}</abbr>)
		}else{
			return(<span>{params.value}</span>)
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "service request status";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		// autoGroupColumnDef={{ minWidth: 5000 }}
		.catch(err=>{
			console.log(err);
		})
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		// validation for no-overlay 
		saveView("N/A", rows)
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
		  const filter = JSON.parse(data.filter);
          params?.columnApi.applyColumnState({ state: order });
		  params?.api.setFilterModel(filter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
		payload = {
			filter:  JSON.stringify(filter),
			table_name : table_name
		}
		}else{
		payload = {
			order:  JSON.stringify(rows),
			table_name : table_name
		}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
		console.log('view updated')
		})
		.catch(err=>{
		console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
  }

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	const generateHeaderLabel = (header) => {
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	const handleChildClick = (value) => {
		setTabValue(value)
  	};

	const getChildCount = data => {
		// here child count is stored in the 'childCount' property
		return data.childCount;
	};

	function convertUTCtoLocalDate(date){
		if(date === 'N/A') return date
		const dateObj = new Date(date);
		// Extract the components
		const year = dateObj.getFullYear();
		const month = String(dateObj.getMonth() + 1).padStart(2, '0');
		const day = String(dateObj.getDate()).padStart(2, '0');
		const hours = String(dateObj.getHours()).padStart(2, '0');
		const minutes = String(dateObj.getMinutes()).padStart(2, '0');
		const seconds = String(dateObj.getSeconds()).padStart(2, '0');

		// Format the new date string
		return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
	}

	function flattenResponse( response ){
		return response.map(( item ) => {
			const {
				user_name, user_role, created_at, login_time, logout_time,
				total_session_time, public_ip, company_name, portal, pages_access, childcount=undefined
			} = item;
			return {
				name: user_name,
				role: user_role,
				login_time: login_time ? convertUTCtoLocalDate(login_time) : undefined,
				logout_time: logout_time ? convertUTCtoLocalDate(logout_time) : undefined,
				total_session: total_session_time,
				ip_address: public_ip,
				company_name: company_name,
				portal: portal,
				childCount: childcount,
				pages_accessed: pages_access
			}
		})
	}

	function onFetchUserAuditLogs(params){
		let {startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode} = params.request;
		// groupKeys = groupKeys.map(item => item === 'Not yet' ? null : item);
		axios.post('/user/get-audit-logs',{
			dataSourceParams: {
				startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode
			}
		}).then((res) => {
			params.successCallback(flattenResponse(res.data.data.rows), res.data.data.lastRow);
		}).catch((err) => {
			console.log(err)
			params.failCallback();
		})
	}
	const datasource = {
		getRows(params) {
			// Call the function to fetch asset data from the server.
			onFetchUserAuditLogs(params)
		}
	};

	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			// loadInitialState (params);
			dispatch(setrowGroupModel([]));
		}else{
			getReport(params);
		}
		params?.api.setServerSideDatasource(datasource);
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])

	useEffect(()=>{
		onGridReady()
	},[])

	return (
		<>
			<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList ={ groupByArr }
				tableName={'user_audit_logs'}
				logs={logs}
				displayTableName = {'User Audit Logs'}
				onClick={handleChildClick}
			/>	
			{
				(tabValue == 0) &&
				<div 
					className="ag-theme-alpine" 
					style={{
						width: "100%",
						height:windowHeight+105,
						fontSize:'12px'
					}}
				>
					<AgGridReact
						ref = {gridRef}
						onGridReady = { onGridReady }
						animateRows = { true }
						rowHeight = {30}
						rowModelType='serverSide'
          				serverSideStoreType="partial"
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							resizable: true
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}

						// Row Grouping conditions
						showOpenedGroup = {true}
						suppressDragLeaveHidesColumns = {true}
						suppressMakeColumnVisibleAfterUnGroup = {true}
						rowGroupPanelShow = { rowGroupPanelShow }

						onColumnMoved = { onColumnMoved }
						onColumnPinned = { onColumnPinned }
						onColumnVisible = { onColumnVisible }
						onSortChanged={onSortChanged}
						groupHeaderHeight ={1}
						// headerHeight = {15}
						floatingFiltersHeight = {40}
						frameworkComponents={frameworkComponents}
						// get filter model
						onFilterChanged = { onFilterChanged }	
						
						// on column group changed
						onColumnRowGroupChanged = { onColumnRowGroupChanged }

						pagination
						getChildCount={getChildCount}
					>
						{tableHeaders.map(header => {
							return(
								<AgGridColumn 
									key = {header} 
									field = {header}
									headerName = {generateHeaderLabel(header)}  
									sortable = {true}
									flex = {1}
									filter = "text"
									// floatingFilter = {true}
									cellRendererFramework={cellRendererFramework}
									headerComponentFramework={CustomFilter}
									
								></AgGridColumn>
							)
						})}
					</AgGridReact>
				</div>
			}	
		</>
	);
}

export default ListView
