import React, { useState } from 'react'
import { Button, Icon, IconButton, InputAdornment, Modal, TextField, Typography, Paper } from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'
import useToast from '@fuse/hooks/useToast'
import { useDispatch } from 'react-redux'


function ServiceProviderQuickAdd({
    serviceProviderField,
    classes,
    setServiceProviderField,
    serviceProviderErrorData,
    addNewServiceProvider
}) {

    const serviceProviderDefaultValues = {
		serviceProvider: '',
		phone_number: '',
		address1: '',
		city: '',
		state: '',
		country: {},
		zip: '',
		url: ''
	}
    const [formattedNumber, setFormattedNumber] = useState('')
    const dispatch = useDispatch()
    const toast = useToast(dispatch)


	const serviceProviderSchema = yup.object({
		serviceProvider: yup.string().required('Provider Name is required!'),
		// phone_number: yup.string().when({
		// 	is: (val) => !!val,
		// 	then: yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Phone number should be in 000-000-0000 format').max(12, 'Phone Number can\'t exceed 10 digits'),
		// }),
		address1: yup.string().nullable(),
		city: yup.string().nullable(),
		state: yup.string().nullable(),
		country: yup.object().nullable(),
		zip: yup.string().nullable(),
		url: yup.string().required('URL is required!'),
	})

	const { control: serviceProviderFormControl, 
		formState:{errors: serviceProviderFormErrors}, 
		handleSubmit: serviceProviderFormSubmit, 
		reset: serviceProviderFormReset,
	} = useForm({
		mode: 'onChange',
		serviceProviderDefaultValues,
		resolver: yupResolver(serviceProviderSchema)
	});

    const onSubmitForm = (formData) => {
        if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
        addNewServiceProvider(formData, formattedNumber)
        serviceProviderFormReset(serviceProviderDefaultValues)
    }

    function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

    return (
        <Modal
            open={serviceProviderField}
            className={classes.modal}
        >
            <div style={{height:'auto',width:700,backgroundColor: 'white',borderRadius:9,padding:'25px',display:'flex',justifyContent:'center',flexDirection:'column',}}>
                
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Create Service Provider</h1>
                    <IconButton onClick={()=>{
                        serviceProviderFormReset(serviceProviderDefaultValues)
                        setServiceProviderField(false)
                    }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                {/* <p style={{marginBottom: '10px'}}>Submit the below form to add Service Provider.</p> */}

                <form onSubmit={serviceProviderFormSubmit(onSubmitForm, serviceProviderErrorData)}>
                    <div style={{display:'flex',flexDirection:'column', backgroundColor: '#f4f4f4', borderRadius:9, padding: '15px', marginBottom:'10px'}}>

                        <Controller
                            name="serviceProvider"
                            className="mb-16"
                            defaultValue={''}
                            control={serviceProviderFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        required
                                        {...field}
                                        variant='outlined'
                                        label='Service Provider Name'
                                        // fullWidth
                                        error={!!serviceProviderFormErrors.serviceProvider}
                                        helperText={serviceProviderFormErrors?.serviceProvider?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                            }
                                        }}
                                        style={{ display:'flex', width: '100%', marginBottom:'10px', marginRight: '10px', backgroundColor:'#fff' }}
                                    />
                                </>
                            )}
                        />

                        <Controller
                            name="url"
                            className="mb-16"
                            defaultValue={''}
                            control={serviceProviderFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        required
                                        {...field}
                                        variant='outlined'
                                        label='URL'
                                        // fullWidth
                                        error={!!serviceProviderFormErrors.url}
                                        helperText={serviceProviderFormErrors?.url?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                            }
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment>
                                                    <Typography style={{ width: '100%', backgroundColor: '#fff',  fontSize: '15px', fontStyle: 'italic' }}>www.</Typography>
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                adornedEnd: classes.adornedEnd
                                            }
                                        }}
                                        style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                    />
                                </>
                            )}
                        />

                        <Controller
                            name="phone_number"
                            className="mb-16"
                            defaultValue={''}
                            control={serviceProviderFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Phone Number'
                                        value={formattedNumber}
                                        onKeyPress={(event) => {
                                            if (!/[0-9/-]+/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e)=>{
                                            changeNumberFormat(e)
                                        }}
                                        // fullWidth
                                        // error={!!serviceProviderFormErrors.phone_number}
                                        // helperText={serviceProviderFormErrors?.phone_number?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                            }
                                        }}
                                        style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                    />
                                </>
                            )}
                        />

                        <Controller
                            name="address1"
                            className="mb-16"
                            defaultValue={''}
                            control={serviceProviderFormControl}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Address Line 1'
                                        // fullWidth
                                        error={!!serviceProviderFormErrors.address1}
                                        helperText={serviceProviderFormErrors?.address1?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                    margin : 0, 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px'
                                            }
                                        }}
                                        style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                    />
                                </>
                            )}
                        />

                        <div style={{ display:'flex', width: '100%' }}>
                            
                            <Controller
                                name="city"
                                className="mb-16"
                                defaultValue={''}
                                control={serviceProviderFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            label='City'
                                            // fullWidth
                                            error={!!serviceProviderFormErrors.city}
                                            helperText={serviceProviderFormErrors?.city?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px'
                                                }
                                            }}
                                            style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
                                        />
                                    </>
                                )}
                            />

                            <Controller
                                name="state"
                                className="mb-16"
                                defaultValue={''}
                                control={serviceProviderFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            label='State/Province'
                                            // fullWidth
                                            error={!!serviceProviderFormErrors.state}
                                            helperText={serviceProviderFormErrors?.state?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px'
                                                }
                                            }}
                                            style={{ display:'flex', width: '100%', marginBottom:'10px', backgroundColor:'#fff' }}
                                        />
                                    </>
                                )}
                            />

                        </div>

                        <div style={{ display:'flex', width: '100%' }}>

                            <Controller
                                name="zip"
                                className="mb-16"
                                defaultValue={''}
                                control={serviceProviderFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            label='Zip'
                                            // fullWidth
                                            error={!!serviceProviderFormErrors.zip}
                                            helperText={serviceProviderFormErrors?.zip?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px'
                                                }
                                            }}
                                            style={{ display:'flex', width: '100%', marginBottom:'10px',marginRight: '10px', backgroundColor:'#fff' }}
                                        />
                                    </>
                                )}
                            />

                            <Controller
                                name="country"
                                className="mb-16"
                                control={serviceProviderFormControl}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={countries}
                                            // disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper style={{ marginTop:'56px',  width:'100%' }}>{children}</Paper>
                                            )}
                                            getOptionLabel={option => option.name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            style={{background: 'white'}}
                                                            {...params}
                                                            label="Country"
                                                            error={!!serviceProviderFormErrors.country}
                                                            helperText={serviceProviderFormErrors?.country?.message}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px'
                                                                }
                                                            }}
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />

                        </div>

                    </div>

                    <div style={{display:'flex',gap:'10px'}}>
                        <Button 
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit'
                        >Save</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>{
                                serviceProviderFormReset(serviceProviderDefaultValues)
                                setServiceProviderField(false)
                            }}
                        >Cancel</Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default ServiceProviderQuickAdd
