import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import {Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import { usePermission } from '@fuse/hooks';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import AddServiceRequestStatus from './addServiceRequestStatus';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import SaveReportDialog from '../../reports/components/saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];

// function debounce(func, timeout = 300){
// 	let timer;
// 	return (...args) => {
// 	  clearTimeout(timer);
// 	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
// 	};
// }

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const { hasPermission } = usePermission()
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const viewServiceRequestStatusPermission = hasPermission('view-service_request_status')
	const createServiceRequestStatusPermission = hasPermission('add-service_request_status')
	const updateServiceRequestStatusPermission = hasPermission('update-service_request_status')
	const deleteServiceRequestStatusPermission = hasPermission('delete-service_request_status')

	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [serviceRequestStatus, setServiceRequestStatus] = useState([]);
	const [logs,setLogs] = useState([]);


	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};

	async function getServiceRequestStatus(){
		try {
			const res = await axios.get("/service-request-status");
			const { data } = res.data;
			setServiceRequestStatus(formatData(data));
		} catch (err) {
			console.log(err);
		}
	}
	
	function formatData(data){
		return data.map((item) => {
			const tempDescription = item.status_description;
			const tempElement = document.createElement('div');
			tempElement.innerHTML = tempDescription;
			item.status_description = item.status_description == '' ?  'No Description' : tempElement.textContent
			return {
				status_name_: item.status_name,
				is_system_generated: item.is_system_generated,
				original_info:{
					status_description: item.status_description === 'No Description' ? '' : tempDescription
				},
				company_name: item.user_company.company_name,
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
					'user_company'
				]),
				
			}
		})
	}

	function refreshList(){
		getServiceRequestStatus();
	}

	useEffect(()=>{
		setLoading(true)
		getServiceRequestStatus().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	} else {
		return (
			<>
				<SaveReportDialog tableName={"service request status"}/>
				<FusePageCarded
					classes={{
						root: classes.layoutRoot,
						toolbar: 'p-0',
						topBg:classes.topBg,
						contentCard:classes.contentCard
					}}
					// header={
					// 	<DynamicFieldsHeader />
					// }
					// contentToolbar={
					// 	<>
					// 		<Tabs
					// 			value={selectedTab}
					// 			onChange={handleTabChange}
					// 			indicatorColor="primary"
					// 			textColor="primary"
					// 			variant="scrollable"
					// 			scrollButtons="off"
					// 			className="w-full h-64"
					// 		>
					// 			<Tab key="end_user_tab_1" className="h-64" label="All Service Ticket Status" />
					// 			{viewServiceRequestStatusPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}
								
					// 		</Tabs>
					// 		<div>
					// 			<Menu
					// 				id="simple-menu"
					// 				anchorEl={anchorEl}
					// 				keepMounted
					// 				open={viewMenuOpened}
					// 				onClose={handleViewMenuClose}
					// 			>
					// 				{	
					// 					views.map((view,index) => {
					// 						return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
					// 					})
					// 				}
					// 			</Menu>
					// 		</div>
					// 	</>
					// }
					content={
						<>
							<div className={selectedTab !== 0 ? 'hidden' : ''}>
								{
									loading && <FuseLoading />
								}
								{
									!loading && <SalesOrderTable 
										serviceRequestStatus={serviceRequestStatus}
										refresh={refreshList}
										viewServiceRequestStatusPermission = {viewServiceRequestStatusPermission}
										createServiceRequestStatusPermission = {createServiceRequestStatusPermission}
										updateServiceRequestStatusPermission = {updateServiceRequestStatusPermission}
										deleteServiceRequestStatusPermission = {deleteServiceRequestStatusPermission}
									/>
								}
							</div>
					
							<AddServiceRequestStatus getServiceRequestStatus = {getServiceRequestStatus}/>
						</>
					}
					innerScroll
				/>
			</>
		)
	}
}

export default withReducer('ServiceRequestStatusApp', reducer)(CardedFullWidth2TabbedSample);
