import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import {TextField,MenuItem, FormControlLabel} from '@material-ui/core';
import { useState, useEffect, Fragment } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { openReportViewerDialog } from '../store/reportViewDialogReducer';
import { openFormViewerDialog } from '../store/formViewDialogReducer';
import useToast from '@fuse/hooks/useToast'
import { usePermission } from '@fuse/hooks';
import { openReportViewerDialog } from "../../reports/store/reportViewDialogReducer"
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';

const useStyles = makeStyles({
	layoutRoot: {},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	noPaddingRight:{
		paddingRight:'0px'
	},
});

function DynamicFieldsHeader(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch)
	const mainTheme = useSelector(selectMainTheme);
	const [search, setSearchQuery] = useState('');
	const [currentFilter, setCurrentFilter] = useState('');
	const [currentFilterLabel, setCurrentFilterLabel] = useState('');
	const { hasPermission } = usePermission()
	const createContractStatusPermission = hasPermission('add-contract_status')
	const addReportPermission = hasPermission('add-report')
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	useEffect(() => {
		console.log(props.searchFilters);
		
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilter(props.searchFilters[0].field)
		}
	},[props.searchFilters])

	useEffect(() => {
		if(props.searchFilters && props.searchFilters.length){
			setCurrentFilterLabel(props.searchFilters.find((item) => item.field == currentFilter)?.label || "");
		}
	},[currentFilter]);

	useEffect(() => {
		props.onSearchChange(search, currentFilter)
	},[search, currentFilter])

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 24 24"><path d="M12 21.9966C6.47715 21.9966 2 17.5194 2 11.9966C2 6.47373 6.47715 1.99658 12 1.99658C17.5228 1.99658 22 6.47373 22 11.9966C22 17.5194 17.5228 21.9966 12 21.9966ZM12 19.9966V3.99658C7.58172 3.99658 4 7.5783 4 11.9966C4 16.4149 7.58172 19.9966 12 19.9966Z"></path></svg>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="hidden sm:flex text-16 md:text-18 mx-12 font-semibold"
					style = {{ color:'black'}}
				>
					Contract Statuses List
				</Typography>
			</div>
			<div style={{display:'flex'}}>
				{addReportPermission && <div className={classes.buttonContainer}>
						<Button
						onClick={() => dispatch(openReportViewerDialog())}
						variant="contained"
						color="secondary"
						className={`w-full ${classes.buttonSquare}`}
						style={{width:'150px',marginRight: '10px'}}
						startIcon={
							<Icon fontSize="small" title="Add Report">
								add
							</Icon>
						}>
							Add Report
						</Button>
				</div>}
				{createContractStatusPermission && <div className={classes.buttonContainer}>
						<Button
							variant="contained"
							color="secondary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'200px', marginRight: '20px'}}
							onClick = {()=>dispatch(openFormViewerDialog())}
							startIcon={
							<Icon fontSize="small" title="Add Contract Status">
								add
							</Icon>}>
							Add Contract Status
						</Button>
					</div>}
					{/* <div className={classes.buttonContainer}>
						<Button
							variant="contained"
							color="secondary"
							className={`w-full ${classes.buttonSquare}`}
							style={{width:'150px',marginRight:"20px"}}
							onClick = {()=>dispatch(openReportViewerDialog())}
							startIcon={
							<Icon fontSize="large" title="Save View">
								save
							</Icon>}>
								Save View
						</Button>
					</div> */}
					<div className={classes.buttonContainer}>
						<ThemeProvider theme={mainTheme}>
							<Paper
								component={motion.div}
								initial={{ y: -20, opacity: 0 }}
								animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
								className={`flex items-center max-w-200 shadow ${classes.buttonSquare}`}
								style={{minWidth:"40px"}}
							>
								<TextField
									placeholder="Search Contract Status"
									className="flex flex-1 mx-8 p-3"
									fullWidth
									InputProps={{
										disableUnderline: true,
										
									}}
									value={query_data ?? '' }
									onChange={(e)=>{
										dispatch(setQuickFilter(e.target.value))
									}}
								/>

								<IconButton
									aria-label="toggle password visibility"
									size="small"
									disabled={props.currentSearch == ''}
									className=""
								>
									<Icon>search</Icon>
								</IconButton>
							</Paper>
						</ThemeProvider>
					</div>
				</div>

			
		</div>
	)
}

export default DynamicFieldsHeader;
