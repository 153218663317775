import React, {useEffect,useRef,useState} from 'react'
import axios from "@fuse/utils/axios";
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import useToast from '@fuse/hooks/useToast';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import { downloadFile } from '@fuse/utils/fileUtils';

const useStyles = makeStyles({

    root:{
      // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
      //   border: '2px solid black',
      //   display: 'none !important'
      // }
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    }
});

import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@material-ui/core';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { useDispatch } from 'react-redux';


function FilesTab({assetDetails,assetFiles,fetchFiles}) {
    const columns = [
        { field: 'upload_date', headerName: 'Upload Date', width: 200,
            renderCell: (params) => {
                return (<span>{dateReformatTo_mmm_dd_yyyy(params.value)}</span>)
            }
        },
        { field: 'file_name', headerName: 'File Name',width: 400,
            renderCell: (params) =>{
                return (<span style={{color: "blue"}}>{params.value}</span>)
            }
        },
        { field: 'action', headerName: 'Action',width: 200,
            renderCell: (params) =>{
                return (
                    <>
                        <Icon 
                            onClick={()=>downloadFile(params.row.file_link,params.row.file_name)} 
                            style={{cursor: 'pointer',marginRight: '10px'}} 
                            fontSize="small" 
                            color="action" 
                            title="Download"
                        >
                            download
                        </Icon>
                        <Icon 
                            // onClick={()=>deleteFile(params.row.id)} 
                            onClick={()=>setDeleteFileDetails(params.row)}
                            style={{cursor: 'pointer'}} 
                            fontSize="small" 
                            color="action" 
                            title="Delete"
                        >
                            delete
                        </Icon>
                    </>
                )
            }
        }
    ];

    const classes = useStyles();
    const [ dialogStateOpen,setDialogStateOpen ] = useState(false)
    const assetId = assetDetails.id
    const [ data,setData ] = useState([])
    const [ deleteFileDetails, setDeleteFileDetails ] = useState(null)
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    function handleClose(){
        setDialogStateOpen(false)
    }

    function deleteFile(delete_file_id){
        axios.delete(`/assets/delete-asset-files/${delete_file_id}`).then((res)=>{
            fetchFiles()
            setDeleteFileDetails(null)
            toast.success(res.data.message || 'Success')
        }).catch((err)=>{   
            console.log(err);
        })
    }

    function handleModalClose(){
        setDeleteFileDetails(null)
    }

    // function fetchFiles(){
    //     axios.get(`/assets/get-asset-files/${assetId}`).then((res)=>{
    //         const { files } = res.data.data;
    //         setData(files)
    //         getAssetFileCount(files.length)
    //     }).catch((err)=>{   
    //         console.log(err);
    //     })
    // }

    // useEffect(()=>{
    //     fetchFiles()
    // },[])


    return (
        <>
            <div className={classes.root} style={{height: 290, width: '100%',overflow:"auto"}}>
                <DataGrid
                    rows={assetFiles}
                    columns={columns}
                    hideFooter={true}
                />
                {dialogStateOpen && <DropZone 
                        open={dialogStateOpen} 
                        handleClose={handleClose} 
                        assetId={assetId}
                        fetchFiles={fetchFiles}
                    />
                }
            </div>
            <GeneralModal
                open={deleteFileDetails}
                title={'Delete File'}
                text={<h4>Are you sure to delete this file named as <b>{deleteFileDetails?.file_name}</b>?</h4>}
                handleClose={handleModalClose}
                handleConfirm={()=>deleteFile(deleteFileDetails.id)}
            />
        </>
    )
}

export default withRouter(FilesTab)

function DropZone({open,handleClose,assetId,fetchFiles}){
    const uploadInput = useRef();
	const scrollRef = useRef();
    const [loading, setLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(false);
	const [uploadedFileInfo, setUploadedFileInfo] = useState({});
	const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    function setDefault (){
        setSelectedFile(null)
        setUploadedFileInfo({})
        setIsFileSelected(false)
        fetchFiles()
        handleClose()
    }

    function handleFileDragOver(event) {
		event.preventDefault();
		if (!isDragging) {
			setIsDragging(true)
		}
	}

	function handleFileDragLeave(event) {
		event.preventDefault();
		if (isDragging) {
			setIsDragging(false)
		}
	}

	function handleFileOnDrop(event) {
		event.preventDefault();
		setIsDragging(false)
		if (event.dataTransfer.files.length) {
			const file = event.dataTransfer.files[0];
            console.log('file-drop: ',file);
			setSelectedFile(file)
			handleFileInput({}, file);
		}
	}
    
    function handleFileInput(event, file = null) {
        if (!file) {
            file = uploadInput.current.files[0];
		}
		if (!file) {
            return;
		}
        // console.log('file: ',file);
		setSelectedFile(file);
		setUploadedFileInfo({ name: file.name, type: file.type, size: file.size });
		setIsFileSelected(true);
	}

    function handleFileUpload(){
        if (!selectedFile) return;
        const fileExtension = uploadedFileInfo.name.split('.').pop();
        let allowedExtensions = ["doc","docx","pdf","csv"]
        const shouldAcceptFileType = allowedExtensions.includes(fileExtension.toLowerCase());

        if(!shouldAcceptFileType){
            toast.error(`.${fileExtension} File not supported`);
            return
        }
        if(uploadedFileInfo.size > 10000000){
            toast.error("File size cannot exceed 10MB!");
            return
        }
        const formData = new FormData();
		formData.append("file", selectedFile);
        setLoading(true)
        axios.post(`/assets/upload-asset-file/${assetId}`,formData, {
			headers: {'Content-Type': 'multipart/form-data'}
        }).then((res)=>{
            setDefault()
            toast.success(res.data.message || 'Successfully uploaded')
        }).catch((err)=>{
            console.log(err)
        }).finally(()=>{
            setLoading(false)
        })
    }

    return(
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">
                Upload Files
            </DialogTitle>
            <DialogContent ref={scrollRef}>
                <div>
                    <div className="text-center" role="button">
                        {!loading && <>
                            <div style={{
                                padding: 80,
                                border: `4px dashed ${isDragging ? '#6B2C57' : '#999'}`
                            }}
                                onClick={() => uploadInput.current.click()}
                                onDragOver={handleFileDragOver}
                                onDragLeave={handleFileDragLeave}
                                onDrop={handleFileOnDrop}
                            >
                                <Icon fontSize="large" color="action" title="Upload CSV">
                                    cloud_upload
                                </Icon>
                                {isFileSelected && <>
                                    <p style={{ marginTop: 5 }}>{uploadedFileInfo.name}</p>
                                    <Button style={{ marginTop: 10 }} onClick={(event) => {
                                        event.stopPropagation();
                                        handleFileUpload();
                                    }} color="primary" variant="contained">
                                        Upload
                                    </Button>
                                </>}
                            </div>
                        </>}
                        {loading && <>
                            <CircularProgress />
                        </>}
                    </div>
                </div>
				<input
					ref={uploadInput}
					className="hidden"
					id="button-file"
					type="file"
					onChange={handleFileInput}
				/>
			</DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}