import useToast from '@fuse/hooks/useToast'
import {
	Accordion, AccordionDetails, AccordionSummary, Button,
	DialogTitle, Divider, Icon,
	IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, TextField, Typography,
	Drawer, Checkbox
} from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { countries } from '@fuse/utils/countryName'
import ListItemText from '@material-ui/core/ListItemText';
import { useCurrentUser } from '@fuse/hooks'
import DepartmentDialog from '@fuse/components/Modal/QuickAddDepartment'
import CustomDatePicker from '@fuse/components/customDatePicker'
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px !important',
			marginRight: '20px !important',
			marginBottom: '20px !important',
			padding: '10px',
			backgroundColor: '#f4f4f4',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		dropdownCss: {
			transform: 'translateY(-50px)'
		},
		drawerWidth: {
		width: 600
	},
	}
))
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			// marginTop:'56px',
// 			// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			height: 150
// 			, width: 250,
// 			transform: "translateY(-48%)"
// 		},
// 	},
// 	anchorOrigin: {
// 		vertical: "bottom",
// 		horizontal: "center"
// 	},
// 	transformOrigin: {
// 		vertical: "bottom",
// 		horizontal: "center"
// 	},
// 	getContentAnchorEl: null
// };

function AddUserDialog(props) {
	const [menuProps, setMenuProps] = useState({
		PaperProps: {
			style: {
				// marginTop:'56px',
				// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				height: 150
				, width: 250,
				transform: "translateY(-48%)"
			},
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "center"
		},
		transformOrigin: {
			vertical: "bottom",
			horizontal: "center"
		},
		getContentAnchorEl: null
	})
	const classes = useStyles()
	const scrollRef = useRef()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const currentUser = useCurrentUser()
	const openState = useSelector((state) => state.usersApp.formViewDialog)
	const { data: editFromState } = useSelector(({ usersApp }) => usersApp.formViewDialog);
	const [loading, setLoading] = useState(false)
	const [showRole, setShowRole] = useState(false)
	const [department, setDepartment] = useState([])
	const [roles, setRoles] = useState([])
	const [companyName, setCompanyName] = useState([]);
	const [userRoles, setUserRoles] = useState([]);
	const [createNewDept, setCreateNewDept] = useState(false)
	const [isSuperUser, setisSuperUser] = useState(false)
	const [formattedNumber, setFormattedNumber] = useState('')
	const role_options = [{ id: 1, label: 'Power user' }, { id: 2, label: 'Power less user' }]
	const [startDate,setStartDate] = useState({
		date: null,
		error: false
	})
	const [endDate,setEndDate] = useState({
		date: null,
		error: false
	})

	const schema = yup.object({
		first_name: yup.string().required('First name is required!'),
		last_name: yup.string().required('Last name is required!'),
		title: yup.string().required('Title is required!'),
		// email: yup.string().email('Enter a valid Email!').required('Email is required!'),
		// login_enabled: yup.string().required('Title is required'),
		// role: yup.object().nullable().when("login_enabled", (login_enabled,schema)=>{
		// 	if(showRole) return schema.required('Role is required')
		// }),
		emp_no: yup.string(),
		address: yup.string(),
		department: yup.object().nullable(),
		// start_date: yup.string(),
		// end_date: yup.string(),
		address_2: yup.string(),
		phone: yup.string().when({
			is: (val) => !!val,
			then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
		}),
	})

	const defaultValues = {
		first_name: '',
		last_name: '',
		title: '',
		email: '',
		login_enabled: false,
		role: null,
		emp_no: '',
		address: '',
		address_2: '',
		city: '',
		state: '',
		zipcode: '',
		country: '',
		department: null,
		// start_date: '',
		// end_date: '',
		phone: ''
	}

	const getUserDetails =  async () => {
		return axios.get("/user/get-details").then((res) => {
			const { data } = res.data;
			if ( data.includes('super_user') || data.includes('support')) {
				setisSuperUser(true)
			}
		}).catch((err) => {
			console.log(err)
		})

		// console.log("currentUser.role",currentUser)
		// if (currentUser.data.id === editFromState?.id || !currentUser.role.includes('Super-User')) {
		// 	return true
		// }
	}

	function loginEnabledCondition(){
		if (currentUser.data.id === editFromState?.id || !isSuperUser) {
			return true
		}
	}

	const onCancel = () => {
		setUserRoles([])
		reset(defaultValues)
		setShowRole(false)
		setFormattedNumber('')
		setStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const closeDialog = () => {
		setUserRoles([])
		setShowRole(false)
		setFormattedNumber('')
		setStartDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEndDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(() => {
		if (editFromState) {
			let roles_ = editFromState.miltiple_role?.map(item => item.name)
			setUserRoles(roles_);
			setValue('first_name', editFromState.first_name)
			setValue('last_name', editFromState.last_name)
			setValue('title', editFromState.title)
			setValue('email', editFromState.email)
			setFormattedNumber(editFromState.original_info.phone ?? '')
			// setValue('phone', editFromState.original_info.phone ?? '')
			setValue('login_enabled', editFromState.login_enabled)
			setValue('role', editFromState.roles_end_user)
			setValue('emp_no', editFromState.original_info.emp_no ?? '')
			setValue('address', editFromState.original_info.address ?? '')
			setValue('address_2', editFromState.original_info.address_2 ?? '')
			setValue('city', editFromState.original_info.city ?? '')
			setValue('state', editFromState.original_info.state ?? '')
			setValue('zipcode', editFromState.original_info.zip ?? '')
			setValue('country', { name: editFromState.original_info.country, code: 'N/A' });
			setValue('department', editFromState.department)
			// setValue('start_date', editFromState.original_info.start_date ? editFromState.original_info.start_date.split('T')[0] : '')
			// setValue('end_date', editFromState.original_info.end_date ? editFromState.original_info.end_date.split('T')[0] : '')
			setStartDate((prev) => {
				return {
					...prev,
					date: editFromState.original_info.start_date
				}
			})
			setEndDate((prev) => {
				return {
					...prev,
					date: editFromState.original_info.end_date
				}
			})
			if (editFromState.login_enabled) setShowRole(true)
		}
	}, [editFromState])

	const { errors } = formState

	async function getDepartment() {
		try {
			const res = await axios.get("/department")
			const { data } = res.data
			setDepartment(data.map((item) => {
				return {
					id: item.id,
					department_name: item.department_name
				}
			}))
		} catch (err) {
			console.log(err)
		}
	}

	async function getRoles() {
		try {
			const res = await axios.get("/roles")
			const { data } = res.data
			let all_roles = [...data.preDefinedRoles, ...data.customRoles]
			setRoles(all_roles.map((item) => {
				return {
					id: item.id,
					display_name: item.display_name
				}
			}))
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		getDepartment()
		getRoles()
		getUserDetails()
	}, [])

	const handleRoleChange = (event) => {
		setUserRoles(event.target.value);
	};

	function getRoleArr(userRoles, roles) {
		let item = [];
		for (let i = 0; i < userRoles.length; i++) {
			item.push(roles.find((item) => {
				if (item.display_name == userRoles[i]) {
					return item
				}
			})?.id)
		}
		if (item.length == 0) return null
		return item
	}

	const manageFormData = (formData) => {
		let payload
		let role_arr = getRoleArr(userRoles, roles)
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}
		if (!editFromState) {
			if (! /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i.test(formData.email)) {
				toast.error("Enter a valid Email!")
				return
			}
			payload = {
				first_name: formData.first_name.trim(),
				last_name: formData.last_name.trim(),
				title: formData.title,
				email: formData.email,
				login_enabled: formData.login_enabled,
				role_id: role_arr ?? null,
				emp_no: formData.emp_no,
				address: formData.address,
				address_2: formData.address_2,
				city: formData.city,
				state: formData.state,
				zipcode: formData.zipcode,
				country: formData.country.name ?? '',
				department_id: formData.department?.id ?? null,
				// start_date: formData.start_date,
				// end_date: formData.end_date,
				start_date: startDate.date ?? '',
				end_date: endDate.date ?? '',
				phone: formattedNumber || '',
			}
		} else {
			payload = {
				user_id: editFromState.id,
				first_name: formData.first_name.trim(),
				last_name: formData.last_name.trim(),
				title: formData.title,
				email: formData.email,
				login_enabled: formData.login_enabled ?? false,
				role_id: role_arr ?? null,
				emp_no: formData.emp_no,
				address: formData.address,
				address_2: formData.address_2,
				city: formData.city,
				state: formData.state,
				zipcode: formData.zipcode,
				country: formData.country?.name ?? '',
				department_id: formData.department?.id ?? null,
				// start_date: formData.start_date,
				// end_date: formData.end_date,
				start_date: startDate.date ?? '',
				end_date: endDate.date ?? '',
				phone: formattedNumber || '',
			}
		}
		axios.post(`/user/${editFromState ? 'update' : 'add'}`, payload).then(response => {
			if (response.status == 201) {
				toast.error(response.data.message)
			} else {
				//onSuccess()
				setUserRoles([])
				reset(defaultValues)
				closeDialog()
				toast.success(response.data.message ?? 'Something went wrong')
				props.getUsers()
			}
		}).catch(err => {
			console.log('212=>', err.response);
			toast.error(err.response.message)
		})
	}

	const handleDepartmentDialogOpen = () => {
		setCreateNewDept(true)
	}

	const errorData = (error) => {
		console.log('363=>', error)
	}

	const handleStatusDialogClose = (new_data = null) => {
		setCreateNewDept(false)

		//Set Quick added STATUS value into status field
		if (new_data) setValue('department', { id: new_data.id, department_name: new_data.department_name })
	}

	const ondivScroll = () => {
		if (scrollRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
			if (scrollTop + clientHeight / 2 >= scrollHeight / 2 || scrollTop >= 120) {
				setMenuProps({
					...menuProps,
					PaperProps: {
						style: {

							height: 150
							, width: 250,
							transform: "translateY(40%)"
						},
					},
					anchorOrigin: {
						vertical: "top",
						horizontal: "center"
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "center"
					},
				})
			}
			if(scrollTop === 0) {
				setMenuProps({
					...menuProps,
					PaperProps: {
						style: {

							height: 150
							, width: 250,
							transform: "translateY(-48%)"
						},
					},
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "center"
					},
					transformOrigin: {
						vertical: "bottom",
						horizontal: "center"
					},
				})
			}
			// if (scrollTop + clientHeight<= scrollHeight ){
			// 	setMenuProps({
					
			// 		PaperProps: {
			// 			style: {

			// 				height: 150
			// 				, width: 250,
			// 				transform: "translateY(-48%)"
			// 			},
			// 		},
			// 		anchorOrigin: {
			// 			vertical: "bottom",
			// 			horizontal: "center"
			// 		},
			// 		transformOrigin: {
			// 			vertical: "bottom",
			// 			horizontal: "center"
			// 		},
			// 	})
			// }			
		}
	}
	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}

	return (
		<div>

			<DepartmentDialog
				open={createNewDept}
				handleCloseDialog={handleStatusDialogClose}
				getDepartment={getDepartment}
			/>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div ref={scrollRef} onScroll={ondivScroll} style={{  height: "100%", overflow: "auto" }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<DialogTitle>{editFromState ? 'Update' : 'Add'} User</DialogTitle>
						<IconButton style={{ marginRight: '15px' }} onClick={onCancel}>
							<Icon>close</Icon>
						</IconButton>
					</div>

					<form onSubmit={handleSubmit(manageFormData, errorData)}>
						{/*-----------Item Information------------*/}
						<div className={classes.subSection}>
							<div style={{ width: '100%' }}>
								<Typography style={{ fontSize: '16px' }}>Required Information *</Typography>
								<Divider style={{ marginBottom: '10px' }} />
							</div>
							<div >
								<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
									<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
										<Controller
											name='first_name'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													variant='outlined'
													label='First Name'
													autoFocus
													fullWidth
													required
													// error={!!errors.first_name}
													// helperText={errors?.first_name?.message} 
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>

									<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
										<Controller
											name='last_name'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													variant='outlined'
													label='Last Name'
													autoFocus
													fullWidth
													required
													// error={!!errors.last_name}
													// helperText={errors?.last_name?.message} 
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>

									<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
										<Controller
											name='title'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													variant='outlined'
													label='Title'
													autoFocus
													fullWidth
													required
													// error={!!errors.title}
													// helperText={errors?.title?.message} 
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>

									<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
										<Controller
											name='email'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													variant='outlined'
													label='Email'
													autoFocus
													fullWidth
													required
													disabled={Boolean(editFromState)}
													// error={!!errors.email}
													// helperText={errors?.email?.message} 
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>

									<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
										<Controller
											name="phone"
											className="mb-16"
											control={control}
											render={({ field }) => (
												<>
													<TextField
														{...field}
														variant='outlined'
														value={formattedNumber}
														label='Phone Number'
														onChange={(e)=>{
															changeNumberFormat(e)
														}}
														fullWidth
														onKeyPress={(event) => {
															if (!/[0-9/-]+/.test(event.key)) {
																event.preventDefault();
															}
														}}
														// required
														FormHelperTextProps={{
															style: {
																margin: 0,
																backgroundColor: '#f4f4f4',
																width: '100%',
																paddingTop: '2px'
															}
														}}
														error={!!errors.phone}
														helperText={errors?.phone?.message}
														style={{ backgroundColor: '#fff' }}
													/>
												</>
											)}
										/>
									</div>

									<div style={{ marginBottom: '10px', minWidth: '179px' }}>
										<Controller
											name="login_enabled"
											className="mb-16"
											control={control}
											render={({ field }) => {
												return (
													<>
														<div className='flex items-center'>
															<Checkbox
																{...field}
																color="primary"
																inputProps={{ 'aria-label': 'secondary checkbox' }}
																checked={field.value}
																onChange={(e, value) => {
																	field.onChange(value)
																	if (e.target.checked == true) setShowRole(true)
																	else setShowRole(false)
																}}
																disabled={loginEnabledCondition()}
															/>
															<Typography>Login Enabled</Typography>
														</div>
													</>
												)
											}}
										/>
									</div>

									{showRole &&
										<div className='testDiv' style={{ marginBottom: '10px', minWidth: '179px',position:"relative" }}>
											<Controller
												name="role"
												className={classes.dropdownCss}
												control={control}
												// style={{transform:"translateY(-50px)"}}
												render={({ field: { ref, ...field }, fieldState: { error } }) => (
													<>
														{/* <InputLabel required id="demo-simple-select-outlined-label" className="mt-1">Select Roles</InputLabel> */}
														<span style={{display:"inline-block",position:"absolute",top:"10px",fontSize:"10px",left:"15px",background:"#f8f8f8",color:"#562459",width:"65px",zIndex:"1223646466"}}>Select Roles*</span>
														<Select
														
															required
															 labelId="demo-simple-select-outlined-label"
															id="company_list_label"
															label="Select Roles"
															multiple
															value={userRoles}
															onChange={handleRoleChange}
															renderValue={(selected) => selected.join(', ')}
															disabled={loginEnabledCondition()}
															MenuProps={menuProps}
															variant="outlined"
															fullWidth
															className="mt-16 mb-16 test"
															style={{background:"#fff"}}
														// style={{transform:"translateY(-50px)"}}
														>
															{roles.map((company) => (
																<MenuItem key={company.id} value={company.display_name} className='test'>
																	{/* <Checkbox checked={companyName.indexOf(company.company_name) > -1} /> */}
																	<ListItemText
																		primary={company.display_name}
																		primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
																	/>
																</MenuItem>
															))}
														</Select>
														{/* <Autocomplete
														{...field}
														style={{ width: '100%' }}
														options={roles}
														disablePortal
														getOptionLabel={option => option.display_name }
														onChange={(event, value) => {
															field.onChange(value)
														}}
														PaperComponent={({ children }) => (
															<Paper style={{ marginTop: '0px' }}>{children}</Paper>
														)}
														renderInput={params => {
															return (
																<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	name="role"
																	label='Select role*'
																	variant='outlined'
																	fullWidth
																	error={!!errors.role}
																	helperText={errors?.role?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {marginLeft : 0}
																	}}
																/>
																</div>
															)
														}}
													/> */}
													</>
												)}
											/>
										</div>
									}
								</div>
							</div>
						</div>
						{/*-----------optional Information--------*/}
						<Accordion className={classes.subSection}>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<div style={{ width: '100%' }}>
									<Typography variant='h6'>Optional Information</Typography>
									<Divider style={{ marginBottom: '10px' }} />
								</div>
							</AccordionSummary>
							<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<Controller
										name='emp_no'
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												style={{ background: 'white' }}
												variant='outlined'
												label='Employee Number'
												autoFocus
												fullWidth
												error={!!errors.emp_no}
												helperText={errors?.emp_no?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<Controller
										name='address'
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												style={{ background: 'white' }}
												variant='outlined'
												label='Address Line 1'
												autoFocus
												fullWidth
												error={!!errors.address}
												helperText={errors?.address?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<Controller
										name='address_2'
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												style={{ background: 'white' }}
												variant='outlined'
												label='Address Line 2'
												autoFocus
												fullWidth
												error={!!errors.address_2}
												helperText={errors?.address_2?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<Controller
										name='city'
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												style={{ background: 'white' }}
												variant='outlined'
												label='City'
												autoFocus
												fullWidth
												error={!!errors.city}
												helperText={errors?.city?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<Controller
										name='state'
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												style={{ background: 'white' }}
												variant='outlined'
												label='State/Province'
												autoFocus
												fullWidth
												error={!!errors.state}
												helperText={errors?.state?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<Controller
										name='zipcode'
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												style={{ background: 'white' }}
												variant='outlined'
												label='Zipcode'
												autoFocus
												fullWidth
												error={!!errors.zipcode}
												helperText={errors?.zipcode?.message}
												FormHelperTextProps={{
													style: {
														margin: 0,
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<Controller
										name="country"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={countries}
													disablePortal
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '56px' }}>{children}</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	style={{ background: 'white' }}
																	label="Country"
																	error={!!errors.country}
																	helperText={errors?.country?.message}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																	inputRef={ref}
																	variant="outlined"
																	fullWidth
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', minWidth: '179px' }}>
									<Controller
										name="department"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={department}
													disablePortal
													getOptionLabel={option => option.department_name ?? ''}
													onChange={(event, value) => {
														field.onChange(value)
													}}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '0px' }}>{children}
															{<Button
																style={{ width: '100%' }}
																variant="outlined"
																color="primary"
																onMouseDown={() => handleDepartmentDialogOpen()}
															>Add Department</Button>}
														</Paper>
													)}
													renderInput={params => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	style={{ background: 'white' }}
																	name="department"
																	label='Department'
																	variant='outlined'
																	fullWidth
																	error={!!errors.department}
																	helperText={errors?.department?.message}
																	inputRef={ref}
																	FormHelperTextProps={{
																		style: {
																			margin: 0,
																			backgroundColor: '#f4f4f4',
																			width: '100%',
																			paddingTop: '2px'
																		}
																	}}
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
									<div className='cstm-date' style={{ marginRight: '10px', width: '100%' }}>
										{/* <Controller
											name='start_date'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													type="date"
													InputLabelProps={{ shrink: true }}
													variant='outlined'
													label='Start Date'
													autoFocus
													fullWidth
													error={!!errors.start_date}
													helperText={errors?.start_date?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/> */}
										{/* <CustomDatePicker
											state={startDate}
											setState={setStartDate}
											required={false}
											label='Start Date'
											maxDate={endDate.date}
											condition='End Date'									
										/> */}
										<CustomDatePickerV2
											state={startDate}
											setState={setStartDate}
											required={false}
											label='Start Date'
											maxDate={endDate.date}
											condition='End Date'									
										/>
									</div>
									<div className='cstm-date' style={{ marginRight: '10px', width: '100%' }}>
										{/* <Controller
											name='end_date'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													type="date"
													InputLabelProps={{ shrink: true }}
													variant='outlined'
													label='End Date'
													autoFocus
													fullWidth
													error={!!errors.end_date}
													helperText={errors?.end_date?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/> */}
										{/* <CustomDatePicker
											state={endDate}
											setState={setEndDate}
											required={false}	
											label='End Date'
											minDate={startDate.date}
											condition='Start Date'											
										/> */}
										<CustomDatePickerV2
											state={endDate}
											setState={setEndDate}
											required={false}	
											label='End Date'
											minDate={startDate.date}
											condition='Start Date'											
										/>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>

						<div style={{ padding: 0, margin: 20, display: 'flex', justifyContent: 'left' }}>
							<Button className={classes.endButtons} style={{ marginRight: '10px' }} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? 'Update' : 'Add'}</Button>
							<Button className={classes.endButtons} variant='outlined' color='primary' onClick={onCancel}>Cancel</Button>
						</div>

					</form>
				</div>
			</Drawer>
		</div>
	)
}

export default AddUserDialog
