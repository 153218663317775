
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

const  AlertModal=(props) =>{
    
    return(
        <Dialog
        className="cstm_dialog_modal small text-center"
        open={props.openAlerModal}
        onClose={props.handleAlertModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='heading_holder flex justify-end items-center w-full'>
            <Button className='transparent_icon_btn' onClick={props.handleAlertModal}>
              <i className="icon ti ti-x" />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="warning_content_holder">
            <div className="exclamation_icon_holder red">
              <i className="ti ti-exclamation-mark" />
            </div>
            <h2 className="heading">Are you sure to discard the {props.module} {props.action} process?</h2>
            <p className="sub_content">You will loss all the process if you proceed</p>
          </div>
        </DialogContent>
        <DialogActions className="action_footer_holder">
          <Button onClick={props.handleAlertModal} className="secondary_btn">No, go back!</Button>
          <Button onClick={props.handleCloseAlertModal} className="primary_btn">
            Yes, Proceed!
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default  AlertModal