import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'

const IntegrationTab = () => {
  return (
    <>
    <div style={{height:"290px"}}>
  <Box className=' mt-20'>
        <Typography variant='h6'  className='text-center mb-10 font-medium'>Integration in Progress</Typography>
        <Box>
          <Typography style={{fontSize:"14px"}}>Our team is diligently working on integrating <b>Subscription</b>. We understand the importance of seamless integration to enhance your subscription management experience. Stay Updated! Be the first to know when the integrations are launched! Leave us your email address, and we'll notify you as soon as the integrations with <b>Subscription</b> is available. You'll receive all the latest updates and be among the first to enjoy the benefits of these integrations.</Typography>
         <Box className='text-center'>
           <Button className='rounded-md mt-10' variant='contained' color='primary'>Notify Me!</Button>
         </Box>
        </Box>
      </Box>
    </div>
    
    </>
  )
}

export default IntegrationTab
