import { useCallback } from 'react';
import { setNotifications } from 'app/store/fuse/notificationSlice';
import { useDispatch, useSelector } from "react-redux";
import axios from "@fuse/utils/axios";

export default function useNotification() {
    const dispatch = useDispatch();

    const {
        unread,
        notifications,
    } = useSelector(({ fuse }) => fuse.notifications);

    const refreshNotifications = useCallback(() => {
        axios.get("/notifications")
            .then((res) => {
                const { data } = res.data;

                dispatch(setNotifications({
                    total: data.total,
                    unread: data.unread,
                    notifications: data.notifications
                }));

            }).catch((err) => console.log(err));
    }, []);

    const updateReadStatusById = useCallback((id , read = false) => {
        axios.put(`/notifications/update-read-status/${id}`, { read: Boolean(read) })
            .then(refreshNotifications)
            .catch((err) => console.log(err));
    }, []);

    const updateReadStatusByType = useCallback((type = "", read = false) => {
        axios.put(`/notifications/update-read-status-of-type`, { type, read: Boolean(read) })
            .then(() => refreshNotifications())
            .catch((err) => console.log(err));
    }, []);

    const deleteNotification = useCallback((id) => {
        axios.delete(`/notifications/${id}`)
            .then(refreshNotifications)
            .catch((err) => console.log(err));
    }, []);


    return {
        refreshNotifications,
        updateReadStatusByType,
        updateReadStatusById,
        deleteNotification,
        unread,
        notifications,
    }
}