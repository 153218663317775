import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Stack, Box, Stepper, Step, StepButton, Button, DialogTitle, Divider, Icon, IconButton, TextField, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress } from '@mui/material'

import Card from '@material-ui/core/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Editor from '@fuse/components/Editor';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'

const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
    }
))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function AddAssetsList(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const handleCloseForm = () => {
        props.close()
    }
    const [age, setAge] = useState('');
    const handleChangeExport = (event) => {
        setAge(event.target.value);
    };
    const [assetNote, setAssetNote] = useState('')
    const [assetNoteText, setAssetNoteText] = useState('')
    function onEditorContentChange(content) {
        setAssetNote(content)
    }
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log('new value', newValue)
        setValue(newValue);
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const [recoveryDate, setRecoveryDate] = useState({
        date: null,
        error: false
    })
    // const tabName = (name, tabValue) => {
    //     return (
    //     <div style={{display:'flex', alignItems:"center"}}>
    //         <span style={{ padding: '10px'}} className={tabValue === value ? 'required_tabs': ''}>{name}</span>
    //          <span style={{ color: '#98A2B3', marginLeft: '7px' , display:tabValue === 2 ? 'none':''}}>
    //          <Icon style={{ fontSize: '1.7rem' }}>
    //          arrow_forward_ios
    //          </Icon>
    //          </span>
    //      </div>
    //     )
    //      }


    const steps = ['Required Details', 'User Details', 'Contract Setup'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        // handleNext();
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    //     setCompleted({});
    // };

    return (
        <div>
            <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                <div className='heading_holder'>
                    <h4 className='heading'>Add Asset</h4>
                    <IconButton onClick={handleCloseForm} style={{padding: '0px'}}>
                        <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                    </IconButton>
                </div>

                <div className='progressbar_holder'>
                    <div style={{width: activeStep === 0 ? '33.33%' : activeStep === 1 ? '66.67%' : activeStep === 2 ? '100%' : '0%'}} className='progress'>
                        &nbsp;
                    </div>
                </div>

                <div className='cstm_wizard_holder'>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} className='stepper_holder'>
                            {steps.map((label, index) => (
                            <Step className='each_step_holder' key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                                </StepButton>
                            </Step>
                            ))}
                        </Stepper>
                        <div className='main_content_holder'>
                            {/* {allStepsCompleted() ? (
                            <>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </>
                            ) : ( */}
                            <>
                                <h4 className='main_heading'>
                                    {activeStep === 0 && <span>Required Details</span>}
                                    {activeStep === 1 && <span>User Details</span>}
                                    {activeStep === 2 && <span>Contract Setup</span>}
                                </h4>
                                <div className='content_holder'>
                                    {activeStep === 0 && 
                                        <>
                                            <Box className='light_blue_holder'>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                    
                                                        <Typography className='status_text'>
                                                            Serial Number
                                                            <span className='required_span'>*</span>
                                                        </Typography>
                                                        <TextField
                                                            // {...field}
                                                            style={{ background: 'white' }}
                                                            className='serial_numberField'
                                                            // value={span.serial_number}
                                                            // onChange={event => {
                                                            // 	handleSpanInput(index, event.target.value, "serial_number")
                                                            // }}
                                                            variant='outlined'
                                                            // label='Serial Number'
                                                            autoFocus
                                                            fullWidth
                                                            required
                                                            // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                            // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    border: '1px solid #F2F4F7'
                                                                }
                                                            }} />
                                                    </Grid>                                                
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Asset Name
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <TextField
                                                                // {...field}
                                                                style={{ background: 'white' }}
                                                                className='serial_numberField'
                                                                variant='outlined'
                                                                autoFocus
                                                                fullWidth
                                                                required
                                                                // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                                // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#f4f4f4',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        border: '1px solid #F2F4F7'
                                                                    }
                                                                }} />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className='status_text'>Asset Tag</Typography>
                                                        <TextField
                                                            // {...field}
                                                            style={{ background: 'white' }}
                                                            className='serial_numberField'
                                                            // onChange={(event) => {
                                                            // 	handleSpanInput(index, event.target.value, "asset_tag")
                                                            // }}
                                                            // value={span.asset_tag}
                                                            variant='outlined'
                                                            // label='Asset Tag'
                                                            fullWidth
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    border: '1px solid #F2F4F7'
                                                                }
                                                            }} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            
                                            <div className='text-right mb-3'>
                                                <Button className='link_type_btn'>
                                                    <Icon>add</Icon>
                                                    <span>Add Anoher</span>
                                                </Button>
                                            </div>

                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Status
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    className='assets_listFiled'
                                                                    value={age}
                                                                >
                                                                    <MenuItem value={10}>CSV</MenuItem>
                                                                    <MenuItem value={20}>Excel</MenuItem>
                                                                    <MenuItem value={30}>PDF</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Model
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                {/* <InputLabel id="demo-simple-select-label">Model</InputLabel> */}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    lassName='assets_listFiled'
                                                                    value={age}
                                                                >
                                                                    <MenuItem value={10}>CSV</MenuItem>
                                                                    <MenuItem value={20}>Excel</MenuItem>
                                                                    <MenuItem value={30}>PDF</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Location
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                {/* <InputLabel id="demo-simple-select-label">Location</InputLabel> */}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    className='assets_listFiled'
                                                                    value={age}
                                                                >
                                                                    <MenuItem value={10}>CSV</MenuItem>
                                                                    <MenuItem value={20}>Excel</MenuItem>
                                                                    <MenuItem value={30}>PDF</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>

                                                    {/* Notes  */}
                                                    <Grid item xs={12}>
                                                        <div style={{ marginBottom: '10px' }}>
                                                            <Typography className='status_text'>Description</Typography>
                                                            <Editor
                                                                editorContent={assetNote}
                                                                onEditorContentChange={onEditorContentChange}
                                                                setAssetNoteText={setAssetNoteText} />
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                {/* Image upload */}
                                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                                    <Typography className='status_text'>Upload Image</Typography>
                                                    <div className='upload_imgBox relative'>
                                                        
                                                        <label htmlFor="upload-photo" style={{ cursor: 'pointer', maxWidth: '200px' }}>
                                                            <span style={{ color: '#667085' }} className='icon_holder'>
                                                                <Icon>
                                                                    photo_camera
                                                                </Icon>
                                                                <span className='file_info_content'>Drag an image here or <span>upload a file</span> Supports only gif, png, jpg, jpeg Max size: 2Mb (rec. 700x430px)</span>
                                                            </span>
                                                        </label>
                                                        <input type="file" name="photo" id="upload-photo" />
                                                    </div>                                                    
                                                </div>
                                            </Box>
                                        </>
                                    }
                                    {activeStep === 1 && 
                                        <>
                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>User Name</Typography>
                                                            <TextField
                                                                // {...field}
                                                                style={{ background: '#FCFCFD' }}
                                                                className='serial_numberField'
                                                                // value={span.serial_number}
                                                                // onChange={event => {
                                                                // 	handleSpanInput(index, event.target.value, "serial_number")
                                                                // }}
                                                                variant='outlined'
                                                                // label='Serial Number'
                                                                autoFocus
                                                                fullWidth
                                                                required
                                                                // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                                // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#FCFCFD',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        border: '1px solid #F2F4F7'
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Phone Number</Typography>
                                                            <TextField
                                                                style={{ background: '#FCFCFD' }}
                                                                // {...field}
                                                                className='serial_numberField'
                                                                // value={span.serial_number}
                                                                // onChange={event => {
                                                                // 	handleSpanInput(index, event.target.value, "serial_number")
                                                                // }}
                                                                variant='outlined'
                                                                // label='Serial Number'
                                                                autoFocus
                                                                fullWidth
                                                                required
                                                                // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                                // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#FCFCFD',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        border: '1px solid #F2F4F7'
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Email Address</Typography>
                                                            <TextField
                                                                // {...field}
                                                                style={{ background: '#FCFCFD' }}
                                                                className='serial_numberField'
                                                                // value={span.serial_number}
                                                                // onChange={event => {
                                                                // 	handleSpanInput(index, event.target.value, "serial_number")
                                                                // }}
                                                                variant='outlined'
                                                                // label='Serial Number'
                                                                autoFocus
                                                                fullWidth
                                                                required
                                                                // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                                // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#FCFCFD',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        border: '1px solid #F2F4F7'
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Deployed Date</Typography>
                                                            {/* <CustomDatePicker
                                                        required={false}
                                                        state={recoveryDate}
                                                        setState={setRecoveryDate}
                                                        label='Recovery Date'
                                                    /> */}
                                                            <CustomDatePickerV2
                                                                className="date_picker"
                                                                style={{ background: '#FCFCFD' }}
                                                                state={recoveryDate}
                                                                setState={setRecoveryDate}
                                                                required={false}
                                                            // label='Recovery Date'
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Recovery Date</Typography>
                                                            {/* <CustomDatePicker
                                                        required={false}
                                                        state={recoveryDate}
                                                        setState={setRecoveryDate}
                                                        label='Recovery Date'
                                                    /> */}
                                                            <CustomDatePickerV2
                                                                style={{ background: '#FCFCFD' }}
                                                                state={recoveryDate}
                                                                setState={setRecoveryDate}
                                                                required={false}
                                                            // label='Recovery Date'
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                    {activeStep === 2 && 
                                        <>
                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Contract Number</Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    className='assets_listFiled'
                                                                    value={age}
                                                                // label="Status"
                                                                // onChange={handleChangeExport}
                                                                >
                                                                    <MenuItem value={10}>CSV</MenuItem>
                                                                    <MenuItem value={20}>Excel</MenuItem>
                                                                    <MenuItem value={30}>PDF</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Purchase Price
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    className='assets_listFiled'
                                                                    value={age}
                                                                // label="Status"
                                                                // onChange={handleChangeExport}
                                                                >
                                                                    <MenuItem value={10}>CSV</MenuItem>
                                                                    <MenuItem value={20}>Excel</MenuItem>
                                                                    <MenuItem value={30}>PDF</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Purchase Date</Typography>
                                                            {/* <CustomDatePicker
                                                        required={false}
                                                        state={recoveryDate}
                                                        setState={setRecoveryDate}
                                                        label='Recovery Date'
                                                    /> */}
                                                            <CustomDatePickerV2
                                                                className="date_picker"
                                                                style={{ background: '#FCFCFD' }}
                                                                state={recoveryDate}
                                                                setState={setRecoveryDate}
                                                                required={false}
                                                            // label='Recovery Date'
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Install Date</Typography>
                                                            {/* <CustomDatePicker
                                                        required={false}
                                                        state={recoveryDate}
                                                        setState={setRecoveryDate}
                                                        label='Recovery Date'
                                                    /> */}
                                                            <CustomDatePickerV2
                                                                style={{ background: '#FCFCFD' }}
                                                                state={recoveryDate}
                                                                setState={setRecoveryDate}
                                                                required={false}
                                                            // label='Recovery Date'
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Warranty End Date</Typography>
                                                            {/* <CustomDatePicker
                                                        required={false}
                                                        state={recoveryDate}
                                                        setState={setRecoveryDate}
                                                        label='Recovery Date'
                                                    /> */}
                                                            <CustomDatePickerV2
                                                                style={{ background: '#FCFCFD' }}
                                                                state={recoveryDate}
                                                                setState={setRecoveryDate}
                                                                required={false}
                                                            // label='Recovery Date'
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Order Number</Typography>
                                                            <TextField
                                                                style={{ background: '#FCFCFD' }}
                                                                // {...field}
                                                                className='serial_numberField'
                                                                // value={span.serial_number}
                                                                // onChange={event => {
                                                                // 	handleSpanInput(index, event.target.value, "serial_number")
                                                                // }}
                                                                variant='outlined'
                                                                // label='Serial Number'
                                                                autoFocus
                                                                fullWidth
                                                                required
                                                                // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                                // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#FCFCFD',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        border: '1px solid #F2F4F7'
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                </div>
                                <div className='btn_holder'>
                                    {activeStep !== 0 && 
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className='back_btn'
                                        >
                                            Back
                                        </Button>
                                    }

                                    {activeStep !== steps.length - 1 &&
                                        <Button onClick={handleNext} className='next_btn'>
                                            Next
                                        </Button>
                                    }
                                    {/* {activeStep !== steps.length &&
                                        (completed[activeStep] ? (
                                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                Step {activeStep + 1} already completed
                                            </Typography>
                                        ) : (
                                            <Button onClick={handleComplete}>
                                                {completedSteps() === totalSteps() - 1
                                                ? 'Proceed'
                                                : 'Complete Step'}
                                            </Button>
                                        ))
                                    } */}
                                    {activeStep === steps.length - 1 &&                                            
                                        <Button onClick={handleComplete} className='next_btn'>                                                
                                            Proceed
                                        </Button>                                            
                                    }
                                </div>
                            </>
                            {/* )} */}
                        </div>
                    </Box>

                </div>
            </Drawer>
        </div >
    )
}
export default AddAssetsList