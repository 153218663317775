import React, { useEffect, useState } from 'react'
import { moduleWiseActivityApiMap } from '@fuse/utils/general';

import axios from "@fuse/utils/axios";
import {
  Tabs, Tab, Box, Typography, TextField, Autocomplete, Breadcrumbs, Link, Checkbox, InputLabel,
  MenuItem, FormControl, Select, Paper
} from '@mui/material'



function TableHeader({ handleModuleChange, handleCompanyId, module = '', handleAuditLogCompanyId, setCompanyId=()=>{} }) {

  const [companyList, setCompanyList ] = useState([])
  const [selectedCompany, setSelectedCompany ] = useState('')
  const [selectedModuleValue, setSelectedModuleValue ] = useState('')

  function formatClientList( clients ){
		return clients.map((client)=>{
			return {
				id: client.company_id,
				name: client.company_name,
      }
    })
	}

  function getAllClients(){
		axios.get(`/super-admin/client-list`).then(res =>{
			setCompanyList(formatClientList(res.data.data.clients))
		}).catch(err => {
			console.log(err)
		})
	}

  useEffect(() => {
    getAllClients()
  },[])
  // const { children, value, index, ...other } = props;
  return (
    <div>
      <div role="presentation" className="cstm_breadcrumbs_holder pb-0">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            Logs
          </Link>
          <span>{module == 'audit_log' ? 'Audit' : 'Activity'}</span>
        </Breadcrumbs>
      </div>
       <Box sx={{ width: '100%', marginTop: '12px', display: 'flex', 
        flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography
              className='main_page_heading'
            >
              {module == 'audit_log' ? 'Audit Logs' : 'Activity Logs'}
            </Typography>

          </div>
          
            
          {<div style={{ display: 'flex', gap: '12px', paddingRight: '2px'}}>
            <div style={{ marginBottom: '10px', width: '100%' }}>
              <label style={{ display: 'inline-block' }} className='form_label_input'>Company Name
              <span className='required_span'>*</span>
              </label>
              <Autocomplete
                    // {...field}
                    className='cstm_autoselect_holder'
                    style={{ width: '206px' }}
                    options={companyList}
                    // disablePortal
                    disableClearable
                    value={selectedCompany}
                    onChange={(event, value) => { 
                      setSelectedCompany(value)
                      handleCompanyId(value)
                    }}

                    getOptionLabel={option => option.name ?? ''}
                    renderInput={params => {
                        return (
                            <div ref={params.InputProps.ref}>
                                <TextField
                                    className='cstm_textarea_input'
                                    {...params}
                                    label=''
                                    variant='outlined'
                                    fullWidth
                                    required
                                    onChange={(event) => { 
                                      if(!event.target.value){
                                        setCompanyId('')
                                        setSelectedCompany('')
                                        handleCompanyId('')
                                      } 
                                    }}
                                    placeholder='Select a company'
                                    FormHelperTextProps={{
                                      style: { marginLeft: 0 }
                                    }}
                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                />
                            </div>
                        )
                    }}
                    PaperComponent={(props) => (
                      <Paper {...props} className='truncated_list_item_holder' />
                    )}
                    clearIcon={null}
                />
            </div>
		      
            {module != 'audit_log' && <div style={{ display: 'flex'}}>
              <div style={{ marginBottom: '10px', width: '100%' }}>
                <label style={{ display: 'inline-block' }} className='form_label_input'>Select Module
                <span className='required_span'>*</span>
                </label>
                <Autocomplete
                      // {...field}
                      className='cstm_autoselect_holder'
                      style={{ width: '206px' }}
                      options={moduleWiseActivityApiMap}
                      value={selectedModuleValue}
                      disableClearable
                      onChange={(event, value) => { 
                        handleModuleChange(value)
                          // setSelectedModuleApi(value?.api) 
                          setSelectedModuleValue(value)
                          // setModule(value?.nameInActivity)
                      }}

                      getOptionLabel={option => option.name ?? ''}
                      renderInput={params => {
                          return (
                              <div ref={params.InputProps.ref}>
                                  <TextField
                                      {...params}
                                      label=''
                                      variant='outlined'
                                      fullWidth
                                      required
                                      onChange={(event) => { 
                                        if(!event.target.value){
                                          setSelectedModuleValue('')
                                          handleModuleChange('')
                                        } 
                                      }}
                                      placeholder='Select a module'
                                      FormHelperTextProps={{
                                          style: { marginLeft: 0 }
                                      }}
                                      style={{ width: '100%', backgroundColor: '#fff' }}
                                  />
                              </div>
                          )
                      }}
                      PaperComponent={(props) => (
                        <Paper {...props} className='truncated_list_item_holder' />
                      )}
                      clearIcon={null}
                  />
              </div>
            </div>}
          </div>}
        
       
       </Box>
      
    </div>
  );
}


export default TableHeader;