import { Button, Icon, IconButton, Modal, TextField } from '@material-ui/core'
import React from 'react'

function ContractTypeQuickAdd({
    contractTypeField,
    modal,
    setNewContractType,
    setContractTypeField,
    addNewContractType,
    newContractType
}) {
  return (
    <Modal
        open={contractTypeField}
        className={modal}
    >
        <div style={{width:700,backgroundColor: 'white',borderRadius:9,display:'flex',alignItems:'center',flexDirection:'column',padding:'20px'}}>
            {/* <h1 style={{display:'flex',alignSelf:'flex-start',paddingLeft:'25px'}}>Create Contract Type</h1> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', width: "100%" }}>
                <h1>Create Contract Type</h1>
                <IconButton onClick={()=>{
                    setNewContractType('')
                    setContractTypeField(false)
                }}>
                    <Icon>close</Icon>
                </IconButton>
            </div>

            {/* <p style={{marginBottom: '10px', width: '100%'}}>Submit the below form to add Contract Type.</p> */}

            <form onSubmit={addNewContractType} className='w-full'>
                <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px', width:'100%', marginBottom: '10px'}}>
                    
                    <TextField
                        required
                        variant='outlined'
                        label="Contract Type"
                        fullWidth
                        value={newContractType}
                        style={{ width: '100%', backgroundColor: '#fff' }}
                        onChange={(e)=>{
                            setNewContractType(e.target.value)
                        }}
                        // error={errorContractType}
                        // helperText={errorContractType ? errorContractType : null}
                        FormHelperTextProps={{
                            style: { 
                                margin : 0, 
                                backgroundColor: 'rgb(239 239 239)',
                                width: '100%',
                                paddingTop: '2px'
                            }
                        }}
                    />
                </div>
                <div style={{display:'flex',gap:10, width:'100%'}}>
                    <Button
                        variant='contained' type='submit' color='primary' size='small' style={{ borderRadius: '4px' }}
                        
                    >Save</Button>
                    <Button 
                        variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                        onClick={()=>{
                            setNewContractType('')
                            setContractTypeField(false)
                        }}
                    >Cancel</Button>
                </div>
            </form>
        </div>
    </Modal>
  )
}

export default ContractTypeQuickAdd
