import _ from '@lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import { setAssetIdForRediection } from '../../assets/store/filterParamsReducer'
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
    const classes = useStyles();
	const table_name = "ASSETSTATUS";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');	
	const [tabState, setTabState] = useState("seats");
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 290);
	const [groupByArr, setGroupByArr] = useState([])
  	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const skipHeader = [
		"id", "host_id", "created_at", "updated_at"
	]
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const query_data = useSelector((state) => state.incidentApp.filterParamsReducer);
	if(query_data){
	}

	function formatString(str){
        if(!str) return ''
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	const onGridReady = (params) =>{
		props.endUsersAccounts.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '') list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			dispatch(setfilterlist({}));
			loadInitialState (params);
		}else{
			getReport(params);
		}
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		// dispatch(setfilterlist(rows));
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "asset status";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.applyColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
        if(!table_name) return;
        axios.get(`/user-view/show-view-order?table_name=${table_name}`)
        .then(results=>{
            const { data } = results.data;
            const order = JSON.parse(data.order);
            params.columnApi.applyColumnState(order);
        })
        .catch(err=>{
            console.log(err);
        })
    }

    const saveView = (rows) => {
        if(!table_name) return;
        const order = JSON.stringify(rows);
        axios.post('/user-view/save-view-order',{
            order:order,
            table_name:table_name
        })
        .then(result=>{
            console.log("view updated");
        })
        .catch(err=>{
            console.log(err);
        })
    }

    const onColumnMoved = (params) => {
        const rows = params.columnApi.getColumnState();
        saveView(rows);
		dispatch(setrowGroupModel(rows))
    }

    function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(report_name == undefined){
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];	
			if(first_row.colId !== 'ag-Grid-AutoColumn'){
				saveView(rows);
				dispatch(setrowGroupModel([]));
			}else{
				dispatch(setrowGroupModel(rows));
			}
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
		}
	}

	function redirectToAssetProfile(params) {
		if(!params.data.asset_id) return
		dispatch(setAssetIdForRediection(params.data.asset_id));
    	router.push("/assets-list");

	}
	const generateHeaderLabel = (header) => {
		if(header == 'problem_no'){
			return 'Problem Number'
		} else if(header == 'serial_no'){
			return 'Serial Number'
		} else if(header == 'event_subject'){
			return 'Problem'
		} else if(header == 'event_status'){
			return 'Status'
		} else if(header == 'event_severity'){
			return 'Severity'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data.query
		);
	},[query_data.query])

	return (	
		<>
		<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= {'Insight log'}
				displayTableName={'Insights Log'}
			/>
			<div
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'11px'
				}}
			>
				<AgGridReact
					ref = { gridRef}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData = {props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }

					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}

					// get filter model
					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					pagination={true}
					defaultColDef={{
						resizable: true
					}}
				>
					{Object.keys(props.endUsersAccounts[0]).map(header => {
						if(!skipHeader.includes(header)){
							return(
								<AgGridColumn 
									key = {header} 
									field = {header}
									headerName = {generateHeaderLabel(header)}  
									sortable = {true} 
									filter = "text"
									flex = {1}
									minWidth={200}
									headerComponentFramework={CustomFilter}
									
									cellRendererFramework={(event) =>{
										if(event.colDef.field === "serial_no"){
											return (<a 
												style={{
													height:'10px',
													width:'10px',
													color: 'black',
													cursor: 'pointer',
													marginTop: '2px',
													borderRadius: '50%',
													display: 'inline-block',
												}} 
												onClick={()=> redirectToAssetProfile(event)} 
												variant="subtitle1"
											>
												{<><span>{event.value}</span></>}
											</a>)
										}else{
											return(<span>{event.value}</span>)
										}
									}}
								></AgGridColumn>
							)
						}							
					})}
				</AgGridReact>
			</div>
		</>
	);
}

export default ListView
