import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, TextField } from '@material-ui/core';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

function CategoryDialog(props) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)

    const schema = yup.object({
        category_name: yup.string().required('Category name is required!'),
    })

    const defaultValues = {
        category_name: '',
        description: ''
    }

    const { 
        control, 
        formState:{errors: categoryFromErrors}, 
        handleSubmit: categoryFormSubmit, 
        reset: categoryFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    function manageCategoryData(formData){
        // console.log('manageCategoryData: ',formData);
        const payload = {
            entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
            category_name: formData.category_name,
            category_type: 'hardware',
            description: formData.description.trim()
        }

        axios.post('/category/add', payload).then(response => {
            // response.data.data.new_category
            if(response.status == 201){
                toast.error(response.data.message)
                return
            } 
            categoryFormReset(defaultValues)

            //Send newly added category value
            props.handleCloseDialog(response.data.data.new_category)
            toast.success('Category Added Successfully.')
            props.refreshList()
            
        }).catch(err => {
            toast.error(err.response.data.message)
        })

    }

    function statusFormErrorData(error){
        console.log('statusFormErrorData: ',error);
    }


    return (
        <Modal
            open={props.open}
            className={classes.modal}
        >
            <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <h1>Add Asset Category</h1>
                    <IconButton onClick={()=>{
                        categoryFormReset(defaultValues)
                        props.handleCloseDialog()
                    }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>

                <form onSubmit={categoryFormSubmit(manageCategoryData, statusFormErrorData)}>
                    <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>
                        
                        <Controller
                            name="category_name"
                            className="mb-16"
                            defaultValue={''}
                            control={control}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        variant='outlined'
                                        label='Category'
                                        required
                                        // fullWidth
                                        // error={!!categoryFromErrors.category_name}
                                        // helperText={categoryFromErrors?.category_name?.message}
                                        FormHelperTextProps={{
                                            style: {marginLeft : 0}
                                        }}
                                        style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                                    />
                                </>
                            )}
                        />
                         <div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                        <Controller
                            name='description' 
                            control={control}
                            render={( { field} ) => (
                                <>
                                    {/* <TextareaAutosize 
                                        {...field}
                                        style={{background: 'white',width: '100%',minHeight: '20%',border: 'solid 2px #c8c5c5',padding: '9px'}}
                                        variant='outlined' 
                                        label='Status description' 
                                        fullWidth
                                        placeholder="Status description"
                                        required
                                    /> */}
                                    <TextField 
                                        {...field}
                                        style={{background: 'white',resize: 'auto'}}
                                        variant='outlined'
                                        label='Description' 
                                        fullWidth
                                        // required
                                        multiline
                                        rows={10}
                                        inputProps={{
                                            style: {
                                            resize: "vertical",
                                            },
                                        }}
                                    />
                                </>
                            )}
                        />
					</div> 
                    </div>
                    <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
                        <Button
                            variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                            type='submit'
                        >Add</Button>
                        <Button 
                            variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                            onClick={()=>{
                                categoryFormReset(defaultValues)
                                props.handleCloseDialog()
                            }}
                        >Cancel</Button>
                    </div>
                </form>
            </div>

        </Modal>
    )
}

export default CategoryDialog
