import useToast from '@fuse/hooks/useToast'
import { Button, 
	DialogTitle, Divider, Icon, IconButton, makeStyles, 
	TextField, Typography, Drawer 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { setFromData } from '../store/formViewDialogReducer';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: 'Inter, sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft:'20px',
					marginRight:'20px',
					marginBottom:'20px',
			padding: '10px', 
			backgroundColor: '#f4f4f4', 
			borderRadius:'4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		drawerWidth: {
			width: 600
		},
	}
))

const contractList = ['ABCDEFG1', 'JDFJEWFU432', '2WGBAIU2G3', 'HBWQIEU3BI3']

function AddDepartmentDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.departmentApp.formViewDialog)
	const { data:editFromState } = useSelector(({departmentApp}) => departmentApp.formViewDialog);
	const [type, setType] = useState()
	const [disableType, setDisableType] = useState(false)
	// let {editData=[]} = props

	//form validation
	const schema = yup.object({
		department_name: yup.string().required('Department name is required!'),
	})

	const defaultValues = {
		department_name: '',
	}

	const onCancel = () => {
		reset(defaultValues)
		setType(null)
		// setAllSpan([{
		//     id: Date.now(),
		//     serial_number: '',
		//     asset_tag: ''
		// }])
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
		// if(props.editCloseForm){
		//   props?.editCloseForm(true)
		// }
		// if(props.closeForm){
		//   props?.closeForm(true)
		// }		
	}

	const closeDialog = () => {
		// editData = []
		dispatch(setFromData(null))
		dispatch(closeFormViewerDialog())
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFromState){
			setDisableType(true)
			setValue('department_name',editFromState.department_name)
		}else{
			setDisableType(false)
		}
	},[editFromState])

	const { errors } = formState

	function editAssetStatus (payload) {
		axios.post('/department/edit', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success('Department updated Successfully.')
				if(props.getAssetStatus){
					props.getAssetStatus()
				}
			}   
		}).catch(err => {
			console.log('212=>',err);
			toast.error('Something went wrong!')
		})
	}

	const manageFormData = (formData) => {
		let payload = {
			entity_external_platform_id:String(Math.trunc(Math.random()*200000)),
			department_name:formData.department_name,
		}
		if(editFromState){
			let payload = {
				department_name:formData.department_name,
				edit_id:Number(editFromState.id)
			}
			editAssetStatus(payload)
		}else{
			axios.post('/department/add', payload).then(response => {
				if(response.status == 201){
					toast.error(response.data.message)
				} else {
					reset(defaultValues)
					closeDialog()
					toast.success('Department added Successfully.')
					console.log(props)
					props.getAssetStatus()
				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})
		}
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	function handleSpanInput(a,b){
		console.log(a)
	}

	function manageTypeSearch(val){
		if(val){
			reset(defaultStatus)
			setType(val)
		}
	}

	//   useEffect(() => {
	//     setValue("allSpan", allSpan)
	//   }, [allSpan])

	return (
		<div>
			<Drawer anchor='right' open={openState.open} classes={{ paper: classes.drawerWidth }}>
				<div 
					style={{ 
						display: 'flex', 
						justifyContent: 'space-between',
						alignItems: 'center' 
					}}
				>  
					<DialogTitle>{ Boolean(editFromState) ? 'Update Department' : 'Add Department' }</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*-----------Item Information----------------------- */}
					
					<div className={classes.subSection}>
						<div style={{ width: '100%' }}>
							<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
							<Divider style={{marginBottom: '10px'}}/>
						</div>
						<div >
							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
								<Controller
									name='department_name' 
									control={control}
									render={( { field} ) => (
										<TextField 
											{...field}
											style={{background: 'white'}}
											variant='outlined'
											label='Department Name' 
											autoFocus
											fullWidth
											required                              
											// error={!!errors.department_name}
											// helperText={errors?.department_name?.message}
											FormHelperTextProps={{
											style: { 
												margin : 0, 
												backgroundColor: '#f4f4f4',
												width: '100%',
												paddingTop: '2px'
											}
											}}
										/>
									)}
								/>
								</div>                             
							</div>
						</div>  
					</div>
					<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button 
							className={classes.endButtons} 
							style={{marginRight: '10px'}} 
							type='submit' 
							variant='contained' 
							color='primary' 
						>
							{editFromState ? 'Update' : 'Add'}
						</Button>  
						<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>
							Cancel
						</Button>
					</div>
				</form>        
			</Drawer>
		</div>
	)
}

export default AddDepartmentDialog
