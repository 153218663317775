import EndUsersAccountsTable from './tables';
import useToast from '@fuse/hooks/useToast'
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import AddCustomerFormDialog from './addCustomerDialog';
import { usePermission } from '@fuse/hooks';
import { setCustomFilterData, setfilterQuery,setSerialNumberForServiceRequest } from '../store/filterParamsReducer';
import { openFormViewerDialog,closeFormViewerDialog } from '../store/formViewDialogReducer';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { Tab, Tabs } from '@material-ui/core';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import { stripHtml } from 'helper/common';


const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

function CardedFullWidth2TabbedSample(props) {
	const router = useRouter();
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [page, setPage] = useState(0);
	const { hasPermission } = usePermission();
	const viewServiceRequestPermission = hasPermission('view-service_request')
	const createServiceRequestPermission = hasPermission('add-service_request')
	const updateServiceRequestPermission = hasPermission('update-service_request')
	const deleteServiceRequestPermission = hasPermission('delete-service_request')
	const [loading, setLoading] = useState(false);
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const [selectedTab, setSelectedTab] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [currentSearch, setCurrentSearch] = useState('_');
	const [serviceRequest, setServiceRequest] = useState([]);
	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
	const serial_number = useSelector(({ServiceRequestsApp})=>ServiceRequestsApp.filterParamsReducer.serial_number)
	const custom_filter_data = useSelector(({ServiceRequestsApp})=>ServiceRequestsApp.filterParamsReducer.custom_filter_data)
	let [totalRecords,setTotalRecords] = useState(0);
	const [ redirectedWith, setRedirectedWith ] = useState(null);


	function getCustomer(){
		let url = '/user/fetch-user-suggestion' ;
		axios.get(url).then((res) => {
			const { users } = res.data.data;
			if(custom_filter_data){
				setRedirectedWith({Status:custom_filter_data})
				dispatch(setCustomFilterData(null))
			}
			setServiceRequest(formatData(users))
			setTotalRecords(users?.length)
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			setLoading(false)
		});
	}

	function formatData(data){
		const users = []
		for( let item of data){
			const formattedObject = {
				id: item?.id,
				first_name: item?.first_name,
				last_name: item?.last_name,
				email: item?.email,
				phone_number: item?.phone?`${item.country_code || ''}${item?.phone}`:'N/A',
				phone: item?.phone,
				country_code: item.country_code,
				status: item?.status || '',
				company_name: item?.user_company?.company_name,
				subdomain: item?.user_company?.subdomain,
				country: item?.country,
				time_zone: item?.user_company?.timezone,
				industry: item?.user_company?.industry || '',
				company_id: item?.user_company?.id
			}
			users.push(formattedObject)
		}
		return users
	}

	function refreshList(){
		getAccessory();
	}
	
	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};

	useEffect(()=>{
		setLoading(true)
		getCustomer()

		if(serial_number){
			dispatch(openFormViewerDialog())
		}

		//call on component unmount
		return(()=>{
			dispatch(setSerialNumberForServiceRequest(null))
			dispatch(closeFormViewerDialog())
		})
	},[]);
	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	return (
		<div>
			<SaveReportDialog tableName={"service request"}/>
		
			{/* <FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg: classes.topBg,
					contentWrapper : classes.contentWrapper
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		currentSearch={currentSearch}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Service TIckets" />
				// 			<Tab key="end_user_tab_2" className="h-64" label="Activities" />
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
							<EndUsersAccountsTable 
								fetchServiceReq={getServiceRequest}
								serviceRequests={serviceRequest}
								totalRecords={totalRecords}
								page={page}
								setPage={setPage}
								rowsPerPage={rowsPerPage}
								setRowsPerPage={setRowsPerPage}
								loading={loading}
								tableName='serviceRequest'
								customFilterData={redirectedWith}
								viewServiceRequestPermission = {viewServiceRequestPermission}
								createServiceRequestPermission = {createServiceRequestPermission}
								updateServiceRequestPermission = {updateServiceRequestPermission}
								deleteServiceRequestPermission = {deleteServiceRequestPermission}
							/>
							{openState.open && <AddServiceRequestFormDialog getServiceRequest = {getServiceRequest}/>}
						</div>
					</>
				}
				innesrScroll
			/> */}
			<div className='px-32'>
				<div className={selectedTab !== 0 ? 'hidden' : ''}>
					<EndUsersAccountsTable 
						fetchServiceReq={getCustomer}
						serviceRequests={serviceRequest}
						totalRecords={totalRecords}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						loading={loading}
						tableName='serviceRequest'
						customFilterData={redirectedWith}
						viewServiceRequestPermission = {viewServiceRequestPermission}
						createServiceRequestPermission = {createServiceRequestPermission}
						updateServiceRequestPermission = {updateServiceRequestPermission}
						deleteServiceRequestPermission = {deleteServiceRequestPermission}
					/>
					{openState.open && <AddCustomerFormDialog getCustomer = {getCustomer}/>}
				</div>
			</div>
		</div>
	)
}

export default withReducer('ServiceRequestsApp', reducer)(CardedFullWidth2TabbedSample);
