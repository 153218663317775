import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { red } from '@material-ui/core/colors';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import useToast from '@fuse/hooks/useToast';
import FusePageCarded from '@fuse/core/FusePageCarded';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const drawerWidth = 400;
const headerHeight = 200;

const useStyles = makeStyles(theme => ({
    '@global': {
        '#fuse-main': {
            height: '100vh'
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100%',
        position: 'relative',
        height: 'auto',
        backgroundColor: "#F3F4F6"
    },
    cardGen: {
        width: '100%',
    },
    topBg: {
        backgroundSize: 'cover',
        pointerEvents: 'none',
        padding: "10px"
    },
    topBgFixed: {
        height: headerHeight,
        backgroundColor: theme.palette.primary.dark,
        backgroundSize: 'cover',
        pointerEvents: 'none'
    },
    contentCardWrapper: {
        flexDirection: 'row',
        width: '100%',
        minWidth: '0',
        margin: '0 auto',        
    },
    contentCard: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
        minHeight: 0,
        overflow: 'hidden',
        borderRadius: '2px 2px 0 0',
    },
    drawerPaper: {
        width: drawerWidth,
        maxWidth: '100%',
        overflow: 'hidden',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            position: 'relative'
        }
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        zIndex: 10,
        background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
            theme.palette.background.paper,
            0.6
        )} 20%,${fade(theme.palette.background.paper, 0.8)})`
    },
    content: {
        display: 'flex',
        minHeight: 0
    },
    avatar: {
        backgroundColor: red[500],
    },
    activeTab: {
        borderBottom: "2px solid",
        borderBottomLeftRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
    },
    formControl: {
    },
    pullRight: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    formControlMinWidth: {
        minWidth: 200,
    },
    buttonSquare: {
        borderRadius: '5px'
    },
    buttonSquarePadded: {
        borderRadius: '5px',
        paddingBottom: "5px",
    },
    card: {
        borderRadius: "5px"
    },
    cardContainer: {
    },
    borderRightLight: {
        borderRight: "1px solid rgba(0,0,0,0.12)"
    },
    buttonSquare: {
        borderRadius: '5px'
    },
    layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	}
}));

const ValidateLineItem = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const router = useRouter();
	let [selectedLineItems, setSelectedLineItems] = useState([]);
	let [comment, setComment] = useState([]);
	let [dialogState, setDialogState] = useState(false);
	let [currentButton, setCurrentButton] = useState('');
    const [errorMessage, setErrorMessage] = useState("All line items successfully validated");
    const [details, setDetails] = useState([{}])
    const toast = useToast(dispatch);

    function toNumberFormat(numberString){
		const number = Number(numberString);
		let fixed = number.toFixed(2);
		let [firstNumber, secondNumber] = fixed.toLocaleString().split(".");
		let [formatedNumber] = number.toLocaleString().split(".");
		const result = `${formatedNumber}.${secondNumber}`;
		return result
	}

    const fetchDetails = (dontCall = false) => {
        const token = `${router.params.h}.${router.params.p}.${router.params.s}`
        axios.get(`/quotes/validate-line-items/temp/${token}`).then(res => {
            const {data} = res.data
            const flatObject = data.map(item => ({
                id: item.id,
                serial_no: item?.asset?.serial_number,
                serial_number: item?.asset?.serial_number,
                model: item?.asset?.model,
                asset_name: item?.asset?.asset_name,
                manufacturer_name: item?.asset?.manufacturer?.manufacturer_name,
                modified_sell_price: toNumberFormat(item.modified_sell_price),
                service_name: item?.service?.service_name,
                service_sku: item?.service?.service_sku,
                address1: item?.shipping?.address1,
                address2: item?.shipping?.address2
            }))
            setDetails(flatObject)
        }).catch(err => {
            if(!dontCall)
            {
                setErrorMessage(err.response.data.data)
            }
            setDetails([])
        })
    }

    const onSelectionChanged = (event) => {
		const ids = event.api.getSelectedNodes()
        let arr = []

        ids.map(id => {
            arr.push(id.data)
        })

		setSelectedLineItems(arr);
	};

    const handleValidate = async () => {
        if(selectedLineItems.length == 0)
        {
            toast.error('Please select line to proceed')
            return false
        }

        const token = router.params.token

        axios.post(`/quotes/confirm-validation/temp/${token}`, {selectedLineItems, comment}).then(res => {
            toast.success(res.data.data)
        }).then(() =>{
            fetchDetails(true)
        }).catch(err => {
            toast.error(err.response.data.data)
        }) 
        openCommentDialog('')
    }

    const handleDeny = async () => {
        if(selectedLineItems.length == 0)
        {
            toast.error('Please select line to proceed')
            return false
        }
        const token = router.params.token
        axios.post(`/quotes/deny-validation/temp/${token}`, {selectedLineItems, comment}).then(res => {
            toast.success(res.data.data)
        }).then(() => {
            fetchDetails(true)
        }).catch(err => {
            toast.error(err.response.data.data)
        })
        openCommentDialog('')
    }

    const openCommentDialog = (button) => {
        setComment('')
        if(button != '')
        {
            setCurrentButton(button)
        }
        setDialogState(!dialogState)
    }

    useEffect(() => {
        fetchDetails()
    }, [])

    function onFilterChanged(event){
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

    return (
        <FusePageCarded
            classes={{
                root: classes.layoutRoot,
            }}
            content = {
                <div>                
                    <Dialog open={dialogState} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true} onBackdropClick={openCommentDialog}>
                        <DialogTitle id="form-dialog-title">Add Note</DialogTitle>
                        <DialogContent style={{width:'100%'}}>
                            <TextField
                                value={comment}
                                fullWidth
                                variant="outlined"
                                onChange={(e)=>setComment(e.target.value)}
                                multiline
                                rowsMax={Infinity}
                                style={{width:'100%'}}
                            />
                        </DialogContent>
                        <DialogActions>
                            {
                                currentButton == 'confirm' ? 
                                <Button variant="contained" color="primary" className={classes.buttonSquarePadded} style={{marginRight:'10px'}} onClick={handleValidate}>Confirm</Button>
                                :
                                <Button variant="contained" color="primary" className={classes.buttonSquarePadded} onClick={handleDeny}>Deny</Button>
                            }
                                <Button variant="contained" color="secondary" className={classes.buttonSquarePadded} onClick={() => openCommentDialog('')}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <div className="ag-theme-alpine" style={{height: 400, width: "100%", padding:"10px"}}>
                        {
                            details.length != 0 ? <>
                            <AgGridReact
                            rowData={details}
                            onSelectionChanged={onSelectionChanged}
                            rowSelection = {'multiple'}
                            // no rows overlay 
        					overlayNoRowsTemplate={'<span>No Result Found</span>'}
		        			// get filter model
				        	onFilterChanged = { onFilterChanged }
                            defaultColDef={{
                                resizable: true
                            }}

                        >
                            <AgGridColumn 
                                headerCheckboxSelection={true}
                                headerCheckboxSelectionFilteredOnly={true}
                                checkboxSelection={true}
                                resizable={true}
                                maxWidth={40}
                            >
                            </AgGridColumn>
                            {Object.keys(details[0]).map(header => {
                                if(header != 'serial_no' && header != 'id')
                                {
                                    return (<AgGridColumn 
                                        field={header}
                                        resizable={true}
                                        key= {header} 
                                        headerName={header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}  
                                        sortable={ true } filter={ true }
                                        floatingFilter={true}
                                        filter="agTextColumnFilter"
                                        >
                                        </AgGridColumn>)
                                }
                            })}
                        </AgGridReact>
                        
                        <div style={{marginTop:'20px', float:'right'}}>
                            <Button variant="contained" color="primary" className={classes.buttonSquarePadded} style={{marginRight:'10px'}} onClick={() => openCommentDialog('confirm')}>Confirm</Button>
                            <Button variant="contained" color="secondary" className={classes.buttonSquarePadded} onClick={() => openCommentDialog('deny')}>Deny</Button>
                        </div>
                        </>
                        : 
                            <div style={{width:"100%", textAlign: "center", top:"30%", position:"absolute"}}>
                                <h2>{errorMessage}</h2>
                            </div>
                        }
                        
                    </div>
                </div>
            }
        />
    )
}

export default ValidateLineItem