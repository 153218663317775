import { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box, Icon } from '@mui/material'
import axios from '@fuse/utils/axios'
import { useRouter } from '@fuse/hooks';
// import { FileDelta, CurrentLocation, Ticket } from 'tabler-icons-react';
import { IconFileDelta, IconCurrentLocation, IconTicket, IconFileLike } from '@tabler/icons-react';

function DashboardCard(props) {
	const router = useRouter();
	const [ assetMetrics, setAssetMetrics ] = useState({
		total_asset_count: 0,
		total_location_count: 0,
		total_asset_with_service_tickets_count: 0,
		total_archived_asset_count: 0
	});

	function handleOnClickRedirection( pathName ){
		router.push(pathName)
	}

	function getAnalytics(){
		axios.get('/dashboard/asset-metrics').then((res)=>{
			const {
				total_asset_count,
				total_location_count,
				total_asset_with_service_tickets_count,
				total_active_asset_count
			} = res.data.data;
			setAssetMetrics({
				...assetMetrics,
				total_asset_count,
				total_location_count,
				total_asset_with_service_tickets_count,
				total_active_asset_count
			})
		}).catch((err)=>{
			console.log('error',err)
		}).finally(()=>{

		})
	}
	useEffect(()=>{
		getAnalytics()
	},[]);

	return (
		<>
			<div className='dashboard_card'>
				<Card onClick={()=>handleOnClickRedirection('/assets-list')} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="100" height="103" viewBox="0 0 100 103" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g opacity="0.04">
										<circle cx="76.7002" cy="26.5" r="76.0307" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7004" cy="26.4999" r="63.8282" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7002" cy="26.5" r="46.9325" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7005" cy="26.5" r="30.0368" stroke="black" strokeWidth="0.93865" />
									</g>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span className='dashboard_cardIcon'>
									{/* <Icon>
										request_quote
									</Icon> */}
									<IconFileDelta size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Total Assets</p>
								<h5>{assetMetrics.total_asset_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
			<div className='dashboard_card'>
				<Card onClick={()=>handleOnClickRedirection('/locations')} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="100" height="103" viewBox="0 0 100 103" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g opacity="0.04">
										<circle cx="76.7002" cy="26.5" r="76.0307" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7004" cy="26.4999" r="63.8282" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7002" cy="26.5" r="46.9325" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7005" cy="26.5" r="30.0368" stroke="black" strokeWidth="0.93865" />
									</g>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span className='location_icon'>
									<IconCurrentLocation size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Assets Locations</p>
								<h5>{assetMetrics.total_location_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
			<div className='dashboard_card'>
				<Card onClick={()=>handleOnClickRedirection('/service-tickets')} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="100" height="103" viewBox="0 0 100 103" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g opacity="0.04">
										<circle cx="76.7002" cy="26.5" r="76.0307" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7004" cy="26.4999" r="63.8282" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7002" cy="26.5" r="46.9325" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7005" cy="26.5" r="30.0368" stroke="black" strokeWidth="0.93865" />
									</g>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span className='ticket_icon'>
									<IconTicket size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Assets With Tickets</p>
								<h5>{assetMetrics.total_asset_with_service_tickets_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
			<div className='dashboard_card'>
				<Card onClick={()=>handleOnClickRedirection('/archived-assets')} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="100" height="103" viewBox="0 0 100 103" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g opacity="0.04">
										<circle cx="76.7002" cy="26.5" r="76.0307" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7004" cy="26.4999" r="63.8282" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7002" cy="26.5" r="46.9325" stroke="black" strokeWidth="0.93865" />
										<circle cx="76.7005" cy="26.5" r="30.0368" stroke="black" strokeWidth="0.93865" />
									</g>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span className='active_assetsIcon'>
									<IconFileLike size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Active Assets</p>
								<h5>{assetMetrics.total_active_asset_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
		</>
	);
}
export default DashboardCard