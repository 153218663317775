import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
// import { Button } from '@material-ui/core';
import { Box, Typography, Grid, Button } from '@mui/material'
import Icon from '@material-ui/core/Icon';
import axios from '@fuse/utils/axios'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InfoCard from '@fuse/components/general/infoCardV2';
import FuseLoading from '@fuse/core/FuseLoading';
import { mapColorBasedOnRandomNumber, mapIconBasedOnRandomNumber } from '../constants';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function ListView(props) {
    const router = useRouter();
    const dispatch = useDispatch();
    const toast = useToast(dispatch);
    const [value, setValue] = useState(0);
    const [assetCountByCategory, setAssetCountByCategory ] = useState([])
    const [assetCountByLocation, setAssetCountByLocation ] = useState([])
    const [assetCountByManufacturer, setAssetCountByManufacturer ] = useState([])
    const [assetCountByModel, setAssetCountByModel ] = useState([])
    const [loading, setLoading] = useState(false);

   const handleChange = (event, newValue) => {
  setValue(newValue);
  const tabContent = document.getElementById(`tab-${newValue}`);

  const elementRect = tabContent.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const desiredOffset = 270;
  const scrollTop = elementRect.top + window.scrollY - desiredOffset;

  window.scrollTo({ top: scrollTop, behavior: 'smooth' });
};

    //Generate a non-consecutive random numbers array
    function generateNonConsecutiveRandomNumbers(min, max, length) {
        let result = [];
        let previousNumber = null;
    
        for (let i = 0; i < length; i++) {
            let randomNumber;
            do {
                randomNumber = (Math.floor(Math.random() * (max - min + 1)) + min);
            } while (randomNumber === previousNumber);
    
            result.push(randomNumber);
            previousNumber = randomNumber;
        }
        return result;
    }

    //Attach colors to cards based on random number array
    function attachColorAndIconToCards(cards) {
        const arrayOfRandomNumbers = generateNonConsecutiveRandomNumbers(1, 4, cards.length);
        return cards.map(( item, index ) => {
            return {
                ...item,
                count: `${Number(item.count).toLocaleString('en-US')} ${Number(item.count) > 1 ? 'Assets' : 'Asset'}`,
                icon: mapIconBasedOnRandomNumber[arrayOfRandomNumbers[index]],
                backgroundColor: mapColorBasedOnRandomNumber[arrayOfRandomNumbers[index]].bgColor,
                iconBgColor: mapColorBasedOnRandomNumber[arrayOfRandomNumbers[index]].iconBgColor
            }
        });
    }

    //Handle redirection
    function handleRedirection( pathName ){
        router.push(pathName)
    }

    //Get Count for each catalog
    function getAnalytics(){
        setLoading(true)
        axios.get('/assets-catalog/counts').then((res)=>{
            const {
                asset_count_by_category_name,
                asset_count_by_location_name,
                asset_count_by_manufacturer_name,
                asset_count_by_model_no
            } = res.data.data;
            setAssetCountByCategory(attachColorAndIconToCards(asset_count_by_category_name))
            setAssetCountByModel(attachColorAndIconToCards(asset_count_by_model_no))
            setAssetCountByManufacturer(attachColorAndIconToCards(asset_count_by_manufacturer_name))
            setAssetCountByLocation(attachColorAndIconToCards(asset_count_by_location_name))
        }).catch((err)=>{
            console.log('error',err)
        }).finally(()=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        getAnalytics()
    },[])

    if( loading ){
        return(
            <FuseLoading/>
        )
    }

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box className='catalog_tabView' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position:'fixed', zIndex:100, marginBottom:'200px',width:'90%',padding:"20px 0", background: '#fcfcfc'}}>
                    <Box sx={{backgroundColor: '#e9ebed', padding: '8px', borderRadius:'8px',}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='category_tabs'>
                            <Tab className="tabs_exampleText" label="Category" />
                            <Tab className="tabs_exampleText" label="Model" />
                            <Tab className="tabs_exampleText" label="Manufacturer" />
                            <Tab className="tabs_exampleText" label="Location" />
                        </Tabs>
                    </Box>
                    <Box>
                        <div>
                            <Button
                                variant='contained'
                                // color='primary'
                                className='primary_maroon_btn'
                                // size='small'
                                style={{ borderRadius: '4px', padding:'9px 10px' }}
                                // type='submit'
                                startIcon={
                                    <Icon style={{marginRight:'4px', display:'flex'}} fontSize="small" title="Add Report">
                                        add
                                    </Icon>
                                }
                            >
                                Add Asset
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Box>
                <div className='tab_scrollContent'>
                    <CustomTabPanel className='custom_tab' id='tab-0'>
                        <div>
                            <div style={{marginBottom:'8px', marginTop:'70px'}} className="flex flex-1 w-full items-center justify-between">
                                <div className="flex items-center">
                                    <Typography className="category_text">Category</Typography>
                                </div>
                                <div>
                                    <Button
                                        variant='contained'
                                        className='primary_maroon_outlined_btn'
                                        size='small'
                                        style={{ borderRadius: '4px', border: '1px solid #8A296D', backgroundColor: '#FCF6FD', color:'#562658', fontSize:'12px', fontWeight:'500', lineHeight:'16px', fontFamily:'"Inter", sans-serif', padding:'8px 10px' }}
                                        onClick={()=> handleRedirection('/categories')}
                                    >
                                        See All
                                    </Button>
                                </div>
                            </div>
                            <div className='thin_horizontal_scrollbar' style={{display:'flex', flexWrap:'nowrap', marginTop:'19px', marginBottom:'20px', gap:'10px', overflowX:'auto'}}>
                            {/* <Grid container spacing={2} style={{marginTop:'12px', marginBottom:'15px'}}> */}
                                {
                                    assetCountByCategory.map((item)=>{
                                        return(
                                            <div style={{ marginBottom: '10px', flex: '0 0 auto'}} className='each_cat_card'>
                                            {/* // <Grid item xs={3} style={{marginBottom: '7px'}}> */}
                                                <InfoCard 
                                                    title={item.category_name}
                                                    value={item.count}
                                                    icon={item.icon}
                                                    backgroundColor={item.backgroundColor}
                                                    iconBgColor={item.iconBgColor}
                                                    handleClick={()=>handleRedirection(`/assets-list?category_id=${item.category_id}`)}
                                                />
                                            {/* </Grid> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* </Grid> */}
                            <hr />
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel className='custom_tab' id='tab-1'>
                        <div>
                            <div style={{marginBottom:'8px', marginTop:'27px'}} className="flex flex-1 w-full items-center justify-between">
                                <div className="flex items-center">
                                    <Typography className="category_text">Model</Typography>
                                </div>
                                <div>
                                    <Button
                                        variant='contained'
                                        className='primary_maroon_outlined_btn'
                                        size='small'
                                        style={{ borderRadius: '4px', border: '1px solid #8A296D', backgroundColor: '#FCF6FD', color:'#562658', fontSize:'12px', fontWeight:'500', lineHeight:'16px', fontFamily:'"Inter", sans-serif', padding:'8px 10px' }}
                                        onClick={()=> handleRedirection('/models')}
                                    >
                                        See All
                                    </Button>
                                </div>
                            </div>
                            <div className='thin_horizontal_scrollbar' style={{display:'flex', flexWrap:'nowrap', marginTop:'19px', marginBottom:'20px',gap:'10px', overflowX:'auto'}}>
                            {/* <Grid container spacing={2} style={{marginTop:'12px', marginBottom:'15px'}}> */}
                                {
                                    assetCountByModel.map((item)=>{
                                        return(
                                            // <Grid item xs={3} style={{ marginBottom: '7px'}}>
                                            <div style={{ marginBottom: '10px', flex: '0 0 auto'}} className='each_cat_card'>
                                                <InfoCard 
                                                    title={item.model_no}
                                                    value={item.count}
                                                    icon={item.icon}
                                                    backgroundColor={item.backgroundColor}
                                                    iconBgColor={item.iconBgColor}
                                                    handleClick={()=>handleRedirection(`/assets-list?model_id=${item.model_id}`)}
                                                />
                                          
                                            {/* </Grid> */}
                                            </div>
                                        )
                                    })
                                }
                            {/* </Grid> */}
                            </div>
                            <hr />
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel className='custom_tab' id='tab-2'>
                        <div>
                            <div style={{marginBottom:'8px', marginTop:'27px'}} className="flex flex-1 w-full items-center justify-between">
                                <div className="flex items-center">
                                    <Typography className="category_text">Manufacturer</Typography>
                                </div>
                                <div>
                                    <Button
                                        variant='contained'
                                        className='primary_maroon_outlined_btn'
                                        size='small'
                                        style={{ borderRadius: '4px', border: '1px solid #8A296D', backgroundColor: '#FCF6FD', color:'#562658', fontSize:'12px', fontWeight:'500', lineHeight:'16px', fontFamily:'"Inter", sans-serif', padding:'8px 10px' }}
                                        onClick={()=> handleRedirection('/manufacturers')}
                                    >
                                        See All
                                    </Button>
                                </div>
                            </div>
                            <div className='thin_horizontal_scrollbar' style={{display:'flex', flexWrap:'nowrap', marginTop:'19px', marginBottom:'20px', gap:'10px', overflowX:'auto'}}>
                            {/* <Grid container spacing={2} style={{ marginTop:'12px'}}> */}
                                {
                                    assetCountByManufacturer.map((item)=>{
                                        return(
                                            <div style={{ marginBottom: '10px', flex: '0 0 auto'}} className='each_cat_card'>
                                                {/* // <Grid item xs={3} style={{marginBottom: '15px', marginTop:'12px'}}> */}
                                                <InfoCard 
                                                    title={item.manufacturer_name}
                                                    value={item.count}
                                                    icon={item.icon}
                                                    backgroundColor={item.backgroundColor}
                                                    iconBgColor={item.iconBgColor}
                                                    handleClick={()=>handleRedirection(`/assets-list?manufacturer_id=${item.manufacturer_id}`)}
                                                />
                                            {/* </Grid> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* </Grid> */}
                            <hr />
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel className='custom_tab' id='tab-3'>
                        <div>
                            <div style={{marginBottom:'8px', marginTop:'27px'}} className="flex flex-1 w-full items-center justify-between">
                                <div className="flex items-center">
                                    <Typography className="category_text">Location</Typography>
                                </div>
                                <div>
                                    <Button
                                        variant='contained'
                                        className='primary_maroon_outlined_btn'
                                        size='small'
                                        style={{ borderRadius: '4px', border: '1px solid #8A296D', backgroundColor: '#FCF6FD', color:'#562658', fontSize:'12px', fontWeight:'500', lineHeight:'16px', fontFamily:'"Inter", sans-serif', padding:'8px 10px' }}
                                        onClick={()=> handleRedirection('/locations')}
                                    >
                                        See All
                                    </Button>
                                </div>
                            </div>
                            <div className='thin_horizontal_scrollbar' style={{display:'flex', flexWrap:'nowrap', marginTop:'19px', marginBottom:'20px', gap:'10px', overflowX:"auto"}}>
                            {/* <Grid container spacing={2} style={{marginTop:'12px'}}> */}
                                {
                                    assetCountByLocation.map((item)=>{
                                        return(
                                            <div style={{ marginBottom: '10px', flex: '0 0 auto'}} className='each_cat_card'>
                                                {/* // <Grid item xs={3} style={{arginBottom: '7px'}}> */}
                                                <InfoCard 
                                                    title={item.location_name}
                                                    value={item.count}
                                                    icon={item.icon}
                                                    backgroundColor={item.backgroundColor}
                                                    iconBgColor={item.iconBgColor}
                                                    handleClick={()=>handleRedirection(`/assets-list?location_id=${item.ship_to_locations_id}`)}
                                                />
                                           
                                            {/* </Grid> */}
                                            </div>
                                        )
                                    })
                                }
                             </div>
                            {/* </Grid> */}
                        </div>
                    </CustomTabPanel>
                </div>
        </div>
    );
}
export default ListView