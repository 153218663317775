const initialState = {
    filter : {},
    rowGroupModel : []
};

const actionTypes = {
    SET_FILTER_LIST : 'SET_SUBSCRIPTION_CATEGORY_FILTER_LIST',
    SET_ROW_GROUP_MODEL : 'SET_ROW_GROUP_MODEL',
    FILTER_SUBCATEGORY_DATA: 'FILTER_SUBCATEGORY_DATA',

}

export function setfilterlist(data){
    return {
        type:actionTypes.SET_FILTER_LIST,
        payload: {
            data
        }
    }
}

export function setrowGroupModel (data){
    return {
        type:actionTypes.SET_ROW_GROUP_MODEL,
        payload: {
            data
        }
    }
}

export function setfilterQuery(query = null){
    return {
        type:actionTypes.FILTER_SUBCATEGORY_DATA,
        payload: {
            query
        }
    }
}

export default function (state = initialState , action){
    switch(action.type){
        case actionTypes.SET_FILTER_LIST : {
            return {
                ...state,
                filter : {...action.payload.data}
            }
        }

        case actionTypes.FILTER_SUBCATEGORY_DATA : {
            return {
                ...state,
                query: action.payload.query
            }
        }
        case actionTypes.SET_ROW_GROUP_MODEL :{
            return {
                ...state,
                rowGroupModel : [...action.payload.data]
            }
        }
        default : return state
    }
}