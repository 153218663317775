import React, { useEffect, useState } from 'react'
import { Grid, Card, Box } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { TextField, Paper } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import { Autocomplete } from '@material-ui/lab'
import axios from '@fuse/utils/axios'
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { countries } from '@fuse/utils/countryName'
import { Avatar, Input, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import CircularProgress from '@mui/material/CircularProgress';
import {CircularProgress} from '@material-ui/core';
import {formatPhoneNumber} from '@fuse/utils/formatPhoneNumber'

import profileImg from "./user-profile-pic.png"


const schema = yup.object({
	mobile: yup.string().when({
		is: (val) => !!val,
		then: yup.string().matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Phone number should be in 000-000-0000 format'),
	}),
	
	// mobile: yup.string().when({
	// 	// is: (val) => !!val,
	// 	then: yup.string().matches(/^.{0,10}$/, 'Phone number should be less than 10 digit'),
	// }),
})

const useStyles = makeStyles((theme) => ({

	large: {
		width: "100%",
		height: "300px",
		borderRadius: 0
	},
}));


const EditUserProfile = ({ classes, refresh }) => {
// const classes = useStyles();
	const [loading, setLoading] = useState(false)
	const [imageName, setImageName] = useState('')
	const [imagePath, setImagePath] = useState('')
	const [imageLocation, setImageLocation] = useState(null)
	const [userDetail, setUserDetail] = useState([]);
	const [role, setRole] = useState([]);
	const [uploadImage, setIsUploadImage] = useState(false);
	const [profileImage, setProfileImage] = useState(
		"https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
	);
	const [formattedNumber, setFormattedNumber] = useState('')

	const dispatch = useDispatch()
	const toast = useToast(dispatch)

	const defaultValues = {
		first_name: '',
		last_name: '',
		email: '',
		mobile: '',
		title: '',
		department: '',
		address_1: '',
		address_2: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		employee_number: '',
		start_date: '',
		end_date: ''
	}

	const methods = useForm({
		mode: 'onChange',
		defaultValues: defaultValues,
		resolver: yupResolver(schema)
	});
	const user = useCurrentUser();


	const { formState: { errors }, reset, watch, control, onChange, formState, handleSubmit, setValue } = methods;
	const form = watch();
	function setData() {
		setValue('first_name', userDetail?.first_name || '')
		setValue('last_name', userDetail?.last_name || '')
		setValue('email', userDetail?.email || '')
		// setValue('mobile', userDetail?.phone || '')
		setFormattedNumber(userDetail?.phone || '')
		setValue('title', userDetail?.title || '')
		setValue('department', userDetail?.department?.department_name || '')
		setValue('address_1', userDetail?.address || '')
		setValue('address_2', userDetail?.address_2 || '')
		setValue('city', userDetail?.city || '')
		setValue('state', userDetail?.state)
		setValue('zip', userDetail?.zip)
		setValue('country', { name: userDetail?.country, code: 'N/A' })
		setValue('employee_number', userDetail?.emp_no)
		setValue('start_date', userDetail.start_date ? userDetail.start_date.split('T')[0] : '')
		setValue('end_date', userDetail.end_date ? userDetail.end_date.split('T')[0] : '')
	}
	function changeNumberFormat(e){
		setFormattedNumber(formatPhoneNumber(e.target.value))
	}
	useEffect(() => {
		// if(userDetail){
		setData()
		// }else{
		// 	reset(defaultValues)
		// }
	}, [userDetail])

	const onChangeImageUpload = (event) => {
		setLoading(true)
		const selectedFile = event.target.files[0]
		if (!selectedFile) return
		if (selectedFile.size > 5000000) {
			toast.error("File size cannot exceed 5Mb!");
			setUploadingFile(true)
			return;
		} else {
			let formData = new FormData()
			formData.append("file", selectedFile)
			axios.post('/end-user/upload-profile-image', formData).then(response => {
				if (response.status === 201) {
					toast.error(response.data.message)
				}
				else {
					toast.success('Profile photo uploaded successfully')
					setImageName(selectedFile.name)
					setImageLocation(response.data.data.imageLocation)
					setIsUploadImage(true)
					setImagePath(response.data.data.location)
					setProfileImage(response.data.data.imageLocation)
				}
			}).catch(err => {
				console.log('136=>', err);
				toast.error('Something went wrong!')
			}).finally(() => {
				setLoading(false)
			})
		}
	}

	const errorData = (error) => {
		console.log('102=>', error)
	}

	const manageFormData = (formData) => {
		// let regx = (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
		// if(!regx.test(formData.mobile)){
		// 	toast.error('Please enter a valid phone no')
		// 	return
		// }
		if (formattedNumber && !/[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(formattedNumber)) {
			toast.error("Enter a valid Phone Number!")
			return
		}

		let payload = {
			first_name: formData.first_name || '',
			last_name: formData.last_name || '',
			phone: formattedNumber || '',
			title: formData.title || '',
			address: formData.address_1 || '',
			address_2: formData.address_2 || '',
			city: formData.city || '',
			state: formData.state || '',
			zipcode: formData.zip || '',
			country: formData.country?.name || '',
			emp_no: formData.employee_number || '',
			start_date: formData.start_date || '',
			end_date: formData.end_date || '',
			photoUrl: imagePath || '',
			department_id: userDetail.department?.id ?? null
		}
		axios.post('end-user/update-user-profile-details', payload).then(response => {
			if (response.status == 201) {
				toast.error(response.data.message)
			} else {
				toast.success('User Profile updated successfully')
				if(uploadImage || (userDetail?.first_name.trim()!=formData.first_name.trim()) || (userDetail?.last_name.trim() !=formData.last_name.trim())){
					location.reload();
				}
				// reset(defaultValues)
				refresh()
				setUserDetail(results.data.data.client)
				setRole(results.data.data.role_location)
				setImageLocation(results.data.data.imageUrl)
				setProfileImage(results.data.data.imageUrl)
				setData()
			}
		}).catch(err => {
			console.log('212=>', err);
			// toast.error('Something went wrong!')
		})

	}

	function backButtonPress() {
		window.history.go(-1);
		return false;
	}

	async function getUserDetails() {
		axios.get(`/end-user/view-client/${user.data.id}`)
			.then(results => {
				reset(defaultValues)
				setImageLocation(results.data.data.imageUrl)
				setUserDetail(results.data.data.client)
				setRole(results.data.data.role_location)
				
				// reset({
				// 	first_name : results.data.data.first_name,
				// 	last_name : results.data.data.last_name,
				// 	title: results.data.data.title,
				// 	company_name:results.data.data.user_company.company_name ,
				// 	email : results.data.data.email,
				// 	phone : results.data.data.phone,
				// 	access_role : results.data.data.roles_end_user?.display_name ?? '',
				// 	emp_no: results.data.data.emp_no ,
				// 	address: results.data.data.address ,
				// 	address_2: results.data.data.address_2 ,
				// 	city: results.data.data.city ,
				// 	state: results.data.data.state ,
				// 	zipcode: results.data.data.zip ,
				// 	country: {name:results.data.data.country,code:'N/A'},
				// 	department: results.data.data.department?.department_name ?? '',
				// })
				// setShowImage({
				// 	url:results.data.data.photoURL
				// })
			})
	}
	useEffect(() => {

		getUserDetails()
	}, [])


	return (
		<div className={classes.root}>


			<Card style={{ padding: "10px 20px" }}>

				<Typography variant='h6' style={{ cursor: "pointer" }} className='flex items-center' ><Icon onClick={backButtonPress} style={{ marginRight: "10px" }}><span class="me-2 material-symbols-outlined">
					keyboard_backspace
				</span></Icon>Edit User Information</Typography>

				<form onSubmit={handleSubmit(manageFormData, errorData)} >
					<Grid container>
						<Grid item lg={3} xl={3}>
							<div className='user_profileImg' >
								{/* <input type="file" accept="image/*" id='user-img' className="hidden" 
						onChange = {(event) => onChangeImageUpload(event)}
						/>
						<label htmlFor="user-img">
							
						{!imageLocation && <svg class="jss564 jss563" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>}
							{imageLocation && <img htmlFor='user-img' style={{width:"90%"}} src={imageLocation} alt="" />}
						</label> */}
						{
							loading ? <>
							
									<Box style={{width:"100%",height:"220px"}} className='flex items-center justify-center '>
										 <Box sx={{ display: 'flex' }}>
											<CircularProgress />
											</Box>
										</Box>
							</> : <><FormControl>
									<input
										id="avatar-input"
										type="file"
										accept="image/*"
										onChange = {(event) => onChangeImageUpload(event)}
										className=""
										style={{ display: "none" }}
									/>
									<InputLabel htmlFor="avatar-input" className='myLabel' style={{ transform: "none",width:"100%" }}>
										{" "}
										<Avatar className='myImg' src={imageLocation || profileImage} />

										<div className='content'>
											<Icon>
													<span style={{fontSize:"24px",marginBottom:"20px"}} class="material-symbols-outlined">
														photo_camera
													</span>
												</Icon>
												<p>Click here to upload</p>
												
										</div>
									</InputLabel>
								</FormControl></>
						}
								

							</div>
						</Grid>
						<Grid item lg={9} xl={9}>
							<Chip label="Personal Information" className='customChip' style={{ margin: "20px 0" }} />
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '20px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="first_name"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='First Name'
													fullWidth
													required
													inputProps={{ readOnly: false }}
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}

												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="last_name"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Last Name'
													fullWidth
													required
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '20px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="email"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Email Address'
													fullWidth
													InputLabelProps={{ shrink: true }}
													inputProps={{ readOnly: false }}
													disabled
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}

												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '20px', width: '100%' }}>
									<Controller
										name="mobile"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													value={formattedNumber}
													label='Phone Number'
													fullWidth
													onChange={(e)=>{
														changeNumberFormat(e)
													}}
													onKeyPress={(event) => {
														if (!/[0-9/-]+/.test(event.key)) {
															event.preventDefault();
														}
													}}
													// required
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													error={!!errors.mobile}
													helperText={errors?.mobile?.message}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>
							
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="title"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Title'
													fullWidth
													required
													inputProps={{ readOnly: false }}
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}

												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="employee_number"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Employee Number'
													disabled
													fullWidth
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item lg={12}>
							<Chip label="Address" style={{ margin: "20px 0" }} />
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '20px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="address_1"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Address Line 1'
													fullWidth
													inputProps={{ readOnly: false }}
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}

												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="address_2"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Address Line 2'
													fullWidth
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>

							<div style={{ display: 'flex', justifyContent: 'center' }}>



								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="city"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='City'
													fullWidth
													inputProps={{ readOnly: false }}
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}

												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="state"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='State/Province'
													fullWidth
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="zip"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Zip Code'
													fullWidth
													inputProps={{ readOnly: false }}
													InputLabelProps={{ shrink: true }}
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}

												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="country"
										className="mb-16"
										control={control}
										render={({ field: { ref, ...field }, fieldState: { error } }) => (
											<>
												<Autocomplete
													{...field}
													style={{ width: '100%' }}
													options={countries}
													disablePortal
													onChange={(event, value) => field.onChange(value)}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop: '0px', marginLeft: '0px', width: '100%' }}>{children}</Paper>
													)}
													getOptionLabel={option => option.name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	style={{ background: 'white' }}
																	{...params}
																	label="Country"

																	inputRef={ref}
																	variant="outlined"
																	fullWidth
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>
							</div>
						</Grid>
					</Grid>

					<Grid container className='update-usrProfile-btn'>
						<Grid item lg={12}>
							<Button variant='contained' type='submit'>Update Details</Button>
						</Grid>
					</Grid>

					<Grid container>

						<Grid item lg={4}>
							<Chip label="Department" style={{ margin: "20px 0" }} />
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="department"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label='Department'
													fullWidth
													inputProps={{ readOnly: false }}
													disabled
													FormHelperTextProps={{
														style: {
															margin: 0,
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													style={{ backgroundColor: '#fff' }}
													InputLabelProps={{ shrink: true }}
												/>
											</>
										)}
									/>
								</div>


							</div>
						</Grid>

						<Grid item lg={8}>
							<Chip label="Hire Date" style={{ margin: "20px 0" }} />
							<Grid container>
								<Grid item lg={12}>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<div style={{ marginBottom: '10px', marginRight: "10px", width: '100%' }}>
											<Controller
												name="start_date"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<TextField
															{...field}
															variant='outlined'
															label="Start Date*"
															type='date'
															InputLabelProps={{ shrink: true }}

															fullWidth
															inputProps={{ readOnly: false }}
															disabled
															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
														// style={{ backgroundColor: '#fff' }}
														// inputProps={{ max: `${endDate}` }}
														// style={{ width: '100%', backgroundColor: '#fff' }}
														/>
													</>
												)}
											/>
										</div>

										<div style={{ marginBottom: '10px', width: '100%' }}>
											<Controller
												name="end_date"
												className="mb-16"
												control={control}
												render={({ field }) => (
													<>
														<TextField
															{...field}
															variant='outlined'
															label="End Date*"
															type='date'
															InputLabelProps={{ shrink: true }}
															disabled
															fullWidth

															FormHelperTextProps={{
																style: {
																	margin: 0,
																	backgroundColor: '#f4f4f4',
																	width: '100%',
																	paddingTop: '2px'
																}
															}}
														// inputProps={{ max: `${endDate}` }}
														// style={{ width: '100%', backgroundColor: '#fff' }}
														/>
													</>
												)}
											/>
										</div>
									</div>
								</Grid>
							</Grid>
						</Grid>

					</Grid>

					{/* <Grid container>
				<Grid item lg={12}>
					<Chip label="Hire Date" style={{margin:"20px 0"}} />
					 <div style={{ display: 'flex', justifyContent: 'center' }}>
								<div style={{ marginBottom: '10px',marginRight:"10px", width: '100%' }}>
									<Controller
										name="start_date"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="Start date*"
													type='date'
													InputLabelProps={{ shrink: true }}
													InputLabelProps={{ shrink: true }}
													fullWidth
													
													FormHelperTextProps={{
														style: { 
															margin : 0, 
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													// inputProps={{ max: `${endDate}` }}
													// style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>

								<div style={{ marginBottom: '10px', width: '100%' }}>
									<Controller
										name="end_date"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<TextField
													{...field}
													variant='outlined'
													label="End date*"
													type='date'
													InputLabelProps={{ shrink: true }}
													InputLabelProps={{ shrink: true }}
													fullWidth
													
													FormHelperTextProps={{
														style: { 
															margin : 0, 
															backgroundColor: '#f4f4f4',
															width: '100%',
															paddingTop: '2px'
														}
													}}
													// inputProps={{ max: `${endDate}` }}
													// style={{ width: '100%', backgroundColor: '#fff' }}
												/>
											</>
										)}
									/>
								</div>
							</div>
							
					 
				</Grid>
			</Grid> */}

					<Grid container>
						<Grid item lg={12}>
							<Chip label="My Locations" style={{ margin: "20px 0" }} />
							<Grid container>

								<Grid item lg={6}>
									<Typography variant='h6' style={{ paddingLeft: "15px" }}>Role</Typography>
									<List>
										{role.map(item => {
											return (
												<>
													<ListItem>
														<ListItemText style={{ background: "#e1e1e1", padding: "10px" }} primary={item.name} />
													</ListItem>
												</>)
										})}
									</List>
								</Grid>
								<Grid item lg={6} style={{ paddingRight: "20px" }}>
									<Typography variant='h6' style={{ paddingLeft: "15px" }}>Location</Typography>
									<List>
										{role.map(item => {
											return (
												<>
													<ListItem>
														<ListItemText style={{ background: "#e1e1e1", padding: "10px" }} primary={item.location_str} />
													</ListItem></>)
										})}
									</List>
								</Grid>
							</Grid>
						</Grid>
					</Grid>


				</form>
			</Card>

		</div>
	)
}

export default EditUserProfile
