import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import {Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import AddSubscriptionCategoryDialog from './addSubscriptionCategoryDialog';
import { usePermission } from '@fuse/hooks';
import ActivitieLogs from '@fuse/components/ActivitieLogs';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
// import SaveReportDialog from './saveReportDialog';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function debounce(func, timeout = 300){
	let timer;
	return (...args) => {
	  clearTimeout(timer);
	  timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setManufacturers] = useState([]);
	const router = useRouter();
	const agents = getAgents(invoices);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	// const [loading1, setLoading1] = useState(false);
	const [logs,setLogs] = useState([]);

	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});

	const { hasPermission } = usePermission()
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();

	const viewSCategoryPermission = hasPermission("view-subscription_category")
	const addSCategoryPermission = hasPermission("add-subscription_category")
	const updateSCategoryPermission = hasPermission('update-subscription_category')
	const deleteSCategoryPermission = hasPermission('delete-subscription_category')

	// function handleViewMenuOpen(event) {
	// 	setAnchorEl(event.currentTarget);
	// 	setViewMenuOpened(true);
	// }

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(invoices){
		const agentSet = new Set();
		for(let i = 0; i < invoices.length ; i++){
			agentSet.add(invoices[i].Agent);
		}
		return Array.from(agentSet);
	}

	// function applyFilters(filters){
	// 	setFilters(filters);
	// 	setManufacturers(invoices.filter(acc=>{
	// 		let flag = true;
	// 		if(filters.agent != '' && filters.agent != acc.Agent){
	// 			flag = false;
	// 		}
	// 		return flag;
	// 	}))
	// }

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	// const handleTabChange = (event, value) => {
	// 	if(value ==1){
	// 		getLogs()
	// 	}
	// 	setSelectedTab(value);
	// };

	// function getLogs(){
	// 	axios.get('/category/sub-activities')
	// 	.then(({data})=>{
	// 		setLogs(formatLogs(data.data.activities));
	// 	})
	// 	.catch(err=>{
	// 		console.log('cannot fetch logs');
	// 	})
	// }

	// function formatLogs(data){
	// 	return data.map((item)=>{
	// 		return {
	// 			id:item.id,
	// 			action_id: `act${item.id}`,
	// 			action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
	// 			category_name: item?.subscription_category?.category_name || item.category_name,
	// 			name: item.user_name,
	// 			action: item.action_name,
	// 			description: item.comment,
	// 			previous_value: item.previous_data,
	// 			current_value: item.current_data,
	// 			field_name: item.action_field_name,
	// 			user_role: item.user_role_name
	// 		}
	// 	})
	// }

	// function toggleView(val){
	// 	setSelectedView(val);
	// }

	function gotoDetailsPage(id){
		router.push(`manufacturers/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	async function getModels(){
		// if(!viewManufacturersPermission){
		// 	return;
		// }
		try {
			const res = await axios.get("/category?category_type=software");
			const { data } = res.data;
			setManufacturers(formatInvoices(data));
		} catch (err) {
			console.log(err);
		}
	}
	
	async function getManufacturers(){
		// if(!viewManufacturersPermission){
		// 	return;
		// }
		try {
			const res = await axios.get("/manufacturers");
			const { data } = res.data;
			setManufacturers(formatInvoices(data));
		} catch (err) {
			console.log(err);
		}
	}

	function handleSearchReset(){
		getManufacturers()
	}

	// function getSearchFilters(){
	// 	setSearchFilters([
	// 		{field: "hardware_sku", label: "SKU"},
	// 		{field: "service_plan", label: "Service plan"},
	// 	])
	// }

	// function getCustomFilters(){
	// 	axios.get('/manufacturers/get-filters')
	// 	.then(res => {
	// 		const { data } = res.data;
	// 		setCustomFilters(data)
	// 	})
	// 	.catch(err => {
	// 		console.log(err);
	// 	})
	// }

	function getSearchSuggetions(query, field){
		axios.get('/manufacturers/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/manufacturers/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			setManufacturers(data)
		})
		.catch(err => {
			console.log(err);
		})
	}
	
	function formatInvoices(data){
		return data.map((item) => {
			return {
				category_id: item.entity_external_platform_id,
				category_name: item.category_name,
				category_type: item.category_type,
				no_of_assets: item.no_of_assets_belongs_to_this_category,
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
				]),
			}
		})
	}

	async function getDynamicAttributes(){
		try {
			const res = await axios.get("/dynamic-attributes/fetch-all");
			const { data } = res.data;

			const platformModuleItem = data.find(({ name }) => name == "invoice_header");

			setPlatformModule(platformModuleItem);
			try {
				const res_1 = await axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`);
				const { data: data_1 } = res_1.data;

				setPlatformModuleAttributes(data_1);
				return data_1;
			} catch (err) {
				toast.error("Unable to fetch dynamic attributes");
				console.log(err);
			}
		} catch (err_1) {
			toast.error("Unable to fetch dynamic attributes");
			setPlatformModule({});
			console.log(err_1);
		}
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			// return getManufacturers();
			return getModels();
		})
	}

	// function getImportList(){
	// 	axios.get('/logs/manufacturer')
	// 	.then(({data})=>{
	// 		setLogs(data.data);
	// 	})
	// 	.catch(err=>{
	// 		console.log('cannot fetch logs');
	// 	})
	// }

	function callToast(success,error,value){
		if(success){
			toast.success(value)
		}
		if(error){
			toast.error(value);
		}
	}

	useEffect(()=>{
		setLoading(true)
		// getManufacturers().finally(() => {
		// 	setLoading(false)
		// });
		getModels().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
	return (<>
			<CsvUpload 
				refresh={refreshList}
				// viewManufacturersPermission = { viewManufacturersPermission }
				// createManufacturersPermission = { createManufacturersPermission }
				// updateManufacturersPermission = { updateManufacturersPermission }
			/>

			<SaveReportDialog tableName={"subscription category"} />

			<FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg:classes.topBg,
				contentCard:classes.contentCard
			}}
			// header={
			// 	<DynamicFieldsHeader 
			// 		handleSearch={handleSearch} 
			// 		searchSuggestions={searchSuggestions} 
			// 		clearSearch={clearFilters}
			// 		searchFilters={searchFilters}
			// 		handleSearchSuggestions={handelSearchSuggestions}
			// 		handleSearchReset={handleSearchReset}
			// 		onSearchChange={(searchValue, fieldValue) => {
			// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
			// 		}}
			// 	/>
			// }
			// contentToolbar={
			// 	<>
			// 		<Tabs
			// 			value={selectedTab}
			// 			onChange={handleTabChange}
			// 			indicatorColor="primary"
			// 			textColor="primary"
			// 			variant="scrollable"
			// 			scrollButtons="off"
			// 			className="w-full h-64"
			// 		>
			// 			<Tab key="end_user_tab_1" className="h-64" label="All Subscription Categories" />
			// 			{viewSCategoryPermission && <Tab key="end_user_tab_2" className="h-64" label="Activities" />}						
			// 		</Tabs>
			// 		<div>
			// 			{/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={e=>handleViewMenuOpen(e)}>
			// 				{selectedView== 0 ? <SvgIcon fontSize="small"><ListView color="action" /></SvgIcon> : <SvgIcon fontSize="small"><GridView color="action" /></SvgIcon>}
			// 			</IconButton> */}
			// 			<Menu
			// 				id="simple-menu"
			// 				anchorEl={anchorEl}
			// 				keepMounted
			// 				open={viewMenuOpened}
			// 				onClose={handleViewMenuClose}
			// 			>
			// 				{	
			// 					views.map((view,index) => {
			// 						return <MenuItem key={`view_${index}`} value={index} onClick={ev => {setSelectedView(ev.target.value);handleViewMenuClose()}}>{view}</MenuItem>
			// 					})
			// 				}
			// 			</Menu>
			// 		</div>
			// 	</>
			// }
			content={
				<>
					<div className={selectedTab !== 0 ? 'hidden' : ''}>
					{
						loading && <FuseLoading />
					}
					{
						!loading && <SalesOrderTable 
						endUsersAccounts={invoices}
						selectedView={selectedView}
						gotoDetailsPage={gotoDetailsPage}
						refresh={refreshList}
						viewSCategoryPermission = {viewSCategoryPermission}
						addSCategoryPermission = {addSCategoryPermission}
						updateSCategoryPermission = {updateSCategoryPermission}
						deleteSCategoryPermission = {deleteSCategoryPermission}
					/>
					}
					</div>
					<AddSubscriptionCategoryDialog getModels = {getModels}/>

					{viewSCategoryPermission && <div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
						{/* <ActivitieLogs logs = {logs} /> */}
						{/* {Boolean(logs.length) ? <ActivityAgGridListing module='subscription_category' logs={logs}/> : <FuseLoading/> } */}
					</div>}
				</>
			}
			innerScroll
		/>
	</>)
	}
}

export default withReducer('subCategoryApp', reducer)(CardedFullWidth2TabbedSample);
