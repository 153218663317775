import _ from "@lodash";
import Icon from "@material-ui/core/Icon";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import useRouter from "@fuse/hooks/useRouter";
import useToast from "@fuse/hooks/useToast";
import { setfilterlist, setrowGroupModel } from "../store/filterParamsReducer";
import axios from "@fuse/utils/axios";
import {
  openFormViewerDialog,
  setFromData,
} from "../store/formViewDialogReducer";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "styles/ag-grid-header-style.css";
import Tooltip from "@material-ui/core/Tooltip";
import GeneralModal from "@fuse/components/Modal/GeneralModal";
import { countries } from "@fuse/utils/countryName";
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';

const useStyles = makeStyles({
  layoutRoot: {},
  head: {
    position: "sticky",
    top: 0,
  },
  root: {
    // minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function ListView(props) {
  const classes = useStyles();
  const table_name = "service_provider";
  const router = useRouter();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  // const rowGroupPanelShow = "always";
  const skipHeader = ["id", "original_info"];
  const [gridApi, setGridApi] = useState(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [providerDetails, setProviderDetails] = useState(null);
  const [logs,setLogs] = useState([]);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
  const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
  const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );
  const frameworkComponents = {
		customFilter: CustomFilter,
	};

  const handleClickOpen = () => {
    setOpenDeleteDialog(true);
  };

  function setHeaderName(header) {
    if (header == "url") {
      return "URL";
    }else if( header === "state" ) {
			return 'State/Province';
		}
    return header
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  }

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteDialogOpen = (data) => {
    handleClickOpen();
    setProviderDetails(data);
  };

  function formatString(str) {
    if (!str) return "";
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  }

  function formatLogs(data) {
    return data.map((item) => {
      return {
        id: item.id,
        action_id: `act${item.id}`,
        action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
        service_provider_name: item?.contract_service_provider?.service_provider_name || item.service_provider_name,
        name: item.user_name,
        action: item.action_name,
        description: item.comment,
        previous_value: item.previous_data,
        current_value: item.current_data,
        field_name: item.action_field_name,
        user_role: item.user_role_name,
        company_name: item?.user_company?.company_name,
      };
    });
  }

  function getLogs() {
    axios
      .get("/service-provider/activities")
      .then(({ data }) => {
        setLogs(formatLogs(data.data.activities));
      })
      .catch((err) => {
        console.log("cannot fetch logs");
      });
  }

  const onGridReady = (params) => {
    setGridApi(params);
    props.endUsersAccounts.forEach((list) => {
      for (let [fieldName, value] of Object.entries(list)) {
        if (value === "") list[fieldName] = `No ${formatString(fieldName)}`;
      }
    });
    if (router.query.report_name == undefined) {
      dispatch(setfilterlist({}));
      // loadInitialState(params);
      dispatch(setrowGroupModel([]));
    } else {
      getReport(params);
    }
  };

  // const onGridSizeChanged = () => {
  //   setWindowHeight(window.innerHeight - 290);
  // };

  function onFilterChanged(event) {
    const rows = event.api.getFilterModel();
    // to show No result found in column filter
    dispatch(setfilterlist(rows));
    saveView("N/A", rows)
    // validation for no-overlay
    const total_rows = event.api.getDisplayedRowCount();
    if (total_rows === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  }

  function getReport(event) {
    const report_name = router.query.report_name;
    const table_name = "service provider";
    axios
      .get(
        `/reports/view-report?table_name=${table_name}&report_name=${report_name}`
      )
      .then((results) => {
        const model = JSON.parse(results.data.data.filter);
        event.api.setFilterModel(model);
        const row_model = JSON.parse(results.data.data.row_model);
        event.columnApi.setColumnState(row_model);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
          const filter = JSON.parse(data.filter);
          params?.columnApi.applyColumnState({ state: order });
          params?.api.setFilterModel(filter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      dispatch(setrowGroupModel(rows));
      saveView(rows);
    }
  }

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])

  function saveView(rows, filter) {
    let payload = {}
    if(rows == 'N/A'){
      payload = {
        filter:  JSON.stringify(filter),
        table_name : table_name
      }
    }else{
      payload = {
        order:  JSON.stringify(rows),
        table_name : table_name
      }
    }
    axios.post('/user-view/save-view-order',payload)
    .then(()=>{
      console.log('view updated')
    })
    .catch(err=>{
      console.log(err);
    })
  }

  const onColumnMoved = (params) => {
    const rows = params.columnApi.getColumnState();
    saveView(rows);
    dispatch(setrowGroupModel(rows));
  };

  function onColumnVisible(params) {
    const rows = params.columnApi.getColumnState();
    const total_no_of_columns = rows.length;
    let total_hidden_columns = 0;
    rows.forEach((row) => {
      const { hide } = row;
      if (hide) {
        ++total_hidden_columns;
      }
    });
    if (total_hidden_columns < total_no_of_columns) {
      saveView(params.columnApi.getColumnState());
    }
  }

  function onColumnRowGroupChanged(event) {
    if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
  }

  function handelEdit(data) {
    dispatch(setFromData(data));
    dispatch(openFormViewerDialog());
  }

  const handleChildClick = (value) => {
		setTabValue(value)
  };

  useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

  useEffect(() => {
    onGridReady();
    return () => {
			dispatch(setQuickFilter(null))
		}
  }, [props.endUsersAccounts]);

  function onCellValueChanged(e) {
    let serviceProvider = e.data.service_provider_name;
    let phone_number = null;
    let address_1 = null;
    let city = null;
    let state = null;
    let zip = null;
    let country = e.data.original_info.country;

    let url = e.data.url.includes("www") ? e.data.url : "www." + e.data.url;
    if (e.colDef.field == "service_provider_name") {
      serviceProvider = e.newValue;
    }
    if (e.colDef.field == "phone_number") {
      phone_number = e.newValue;
    }
    if (e.colDef.field == "address1") {
      address_1 = e.newValue;
    }
    if (e.colDef.field == "city") {
      city = e.newValue;
    }
    if (e.colDef.field == "state") {
      state = e.newValue;
    }
    if (e.colDef.field == "zip") {
      zip = e.newValue;
    }
    if (e.colDef.field == "url") {
      url = e.newValue;
    }
    if (e.colDef.field == "country") {
      country = e.newValue;
    }
    const payload = {
      serviceProvider: serviceProvider,
			phone_number: phone_number ? e.data.phone_number : e.data.original_info.phone_number,
      address1: address_1 ? e.data.address1 : e.data.original_info.address_1,
      city: city ? e.data.city : e.data.original_info.city,
      state: state ? e.data.state : e.data.original_info.state,
      zip: zip ? e.data.zip : e.data.original_info.zip,
      // address1: address_1 || "",
      // city: city || "",
      // state: state || "",
      // zip: zip || "",
      // country: country,
      url: e.data.url.includes("www") ? e.data.url : "www." + e.data.url,
      id: Number(e.data.id),
    };
    if (phone_number && !phone_number.match(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) {
      toast.error("Phone number should be in 000-000-0000 format");
      props.refresh();
      return;
    }
    if (!payload.serviceProvider) {
      toast.error("Service Provider Name is required");
      props.refresh();
      return;
    }
    if (url.indexOf(".") == -1) {
      toast.error("Please enter a valid URL");
      props.refresh();
      return;
    }
    axios
      .put("/service-provider/update", payload)
      .then((response) => {
        if (response.status == 201) {
          toast.error(response.data.message);
          props.refresh();
        } else {
          toast.success("Service Provider updated successfully");
          props.refresh();
        }
      })
      .catch((err) => {
        console.log("212=>", err);
        toast.error("Something went wrong!");
      });
  }

  const onColumnPinned = (params) => {
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      saveView(rows);
    }
  };

  function handleDelete() {
    axios
      .delete(`/service-provider/delete/${providerDetails?.id}`)
      .then((res) => {
        if (res.status === 201) {
          toast.error(res.data.message);
        } else {
          toast.success("Service Provider deleted successfully");
          handleClose();
          props.refresh();
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong!");
      });
  }

  useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])

  return (
    <>
      { <AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList ={ groupByArr }
				tableName={'service_provider'}
				logs={logs}
				displayTableName = {'Service Providers'}
				onClick={handleChildClick}
			/>}
      {/* {(tabValue == 1) && (<ActivityAgGridListing module={'service_provider'} logs={logs} displayTableName = {'Service Providers'}/>)} */}

      {(tabValue == 0) &&
        <div
          className="ag-theme-alpine"
          style={{
            width: "100%",
            height: windowHeight + 105,
            fontSize: "11px",
          }}
        >
          <AgGridReact
            ref={gridRef}
            suppressRowClickSelection={true}
            onGridReady={onGridReady}
            animateRows={true}
            rowData={props.endUsersAccounts}
            rowHeight={30}
            // onGridSizeChanged = { onGridSizeChanged }
            // Set the desired sheet name
            defaultExcelExportParams={{
              sheetName: 'Dalos-Source'
            }}
            defaultColDef={{
              resizable: true
            }}
            // no rows overlay
            overlayNoRowsTemplate={"<span>No Result Found</span>"}
            //row grouping
            showOpenedGroup={true}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}
            rowGroupPanelShow={rowGroupPanelShow}
            // get filter model
            onColumnMoved={onColumnMoved}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onColumnPinned}
            onSortChanged={onSortChanged}
            groupHeaderHeight={1}
            // headerHeight={15}
            floatingFiltersHeight={40}
            onColumnRowGroupChanged={onColumnRowGroupChanged}
            onFilterChanged={onFilterChanged}
            frameworkComponents={frameworkComponents}
            pagination={true}
          >
            {/* <AgGridColumn
              lockPosition={true}
              rowSelection={"single"}
              width={100}
              minWidth={100}
              maxWidth={100}
              suppressSizeToFit={true}
              headerName="Actions"
              pinned="right"
              className="action_field"
              lockPinned={true}
              cellRendererFramework={({ data }) => {
                if (data) {
                  return (
                    <>
                      {props.updateServiceProviderPermission && (
                        <Tooltip id="edit" title="Edit" placement="top">
                          <Icon
                            onClick={() => handelEdit(data)}
                            style={{
                              color: "#1d2939",
                              paddingBottom: "3px",
                              paddingRight: "30px",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                          >
                            edit
                          </Icon>
                        </Tooltip>
                      )}
                      {props.deleteServiceProviderPermission && (
                        <Tooltip id="delete" title="Delete" placement="top">
                          <Icon
                            onClick={() => handleDeleteDialogOpen(data)}
                            style={{
                              color: "#1d2939",
                              paddingBottom: "3px",
                              paddingRight: "30px",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                          >
                            delete
                          </Icon>
                        </Tooltip>
                      )}
                    </>
                  );
                } else {
                  return <></>;
                }
              }}
            ></AgGridColumn> */}
            {Object.keys(props.endUsersAccounts[0]).map((header) => {
              if (!skipHeader.includes(header)) {
                if (header == "country") {
                  return (
                    <AgGridColumn
                      key={header}
                      field={header}
                      suppressSizeToFit={true}
                      suppressRowClickSelection={true}
                      // editable={props.updateServiceProviderPermission}
                      onCellValueChanged={onCellValueChanged}
                      headerName={setHeaderName(header)}
                      sortable={true}
                      filter="text"
                      // floatingFilter={true}
                      flex = {1}
											minWidth={200}
                      headerComponentFramework={CustomFilter}
                      
                      cellEditorParams={{
                        values: countries.map((item) => `${item.name}`),
                      }}
                      // cellEditor="agRichSelectCellEditor"
                    ></AgGridColumn>
                  );
                }
                if (header == "service_provider_name") {
                  return (
                    <AgGridColumn
                      field={header}
                      key={header}
                      headerName={setHeaderName(header)}
                     // editable={props.updateServiceProviderPermission}
                      onCellValueChanged={onCellValueChanged}
                      sortable={true}
                      filter="text"
                      minWidth={250}
                      // floatingFilter={true}
                      flex = {1}
                      headerComponentFramework={CustomFilter}
                      
                    ></AgGridColumn>
                  );
                }
                return (
                  <AgGridColumn
                    field={header}
                    key={header}
                    headerName={setHeaderName(header)}
                    //editable={props.updateServiceProviderPermission}
                    onCellValueChanged={onCellValueChanged}
                    sortable={true}
                    filter="text"
                    // floatingFilter={true}
                    flex = {1}
										minWidth={200}
                    headerComponentFramework={CustomFilter}
                    
                  ></AgGridColumn>
                );
              }
            })}
          </AgGridReact>
        </div>
      }
      <GeneralModal
        open={openDeleteDialog}
        title={"Delete Service Provider"}
        text={
          <h4>
            Are you sure to delete this Service Provider named as{" "}
            <b>{providerDetails?.service_provider_name}</b>?
          </h4>
        }
        handleClose={handleClose}
        handleConfirm={handleDelete}
      />
    </>
  );
}

export default ListView;
