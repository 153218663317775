import useToast from '@fuse/hooks/useToast'
import { Button, CircularProgress, 
	DialogTitle, Divider, Icon,
	IconButton, makeStyles, Paper, TextField, Tooltip, Typography,
	Drawer,TextareaAutosize,Modal 
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { setFromData } from '../store/formViewDialogReducer';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import AddCategoryDialog from '@fuse/components/Modal/QuickAddCategory'
import ManufacturerDialog from '@fuse/components/Modal/QuickAddManufacturer'
import AddModelAttributeDialog from '@fuse/components/Modal/QuickAddModelAttribute'
import { usePermission } from '@fuse/hooks';
import CustomDatePicker from '@fuse/components/customDatePicker'
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
	display: 'flex',
	'& > * + *': {
		marginLeft: theme.spacing(2),
	},
	},
	subSection: {
		marginLeft:'20px !important',
		marginRight:'20px !important',
		marginBottom:'20px !important',
		padding: '10px', 
		backgroundColor: '#f4f4f4', 
		borderRadius:'4px'
	},
	endButtons: {
			borderRadius: '4px'
	},
	modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
	drawerWidth: {
		width: 600
	},
	 datePickerPopup: {
    '& .MuiPickersBasePicker-pickerView': {
      width: '200px', // Set the custom width here
    },
	 customDatePicker: {
    // Set your desired width here, for example:
    width: '200px',
  },
  },
}))

function AddModelDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.modelApp.formViewDialog)
	const { data:editFromState } = useSelector(({modelApp}) => modelApp.formViewDialog);
	const [loading, setLoading] = useState(false)
	const [dynamicAttributesMenu,setDynamicAttributesMenu] = useState([])
	const spanTemp = {
		attribute: null,
		attribute_value: '',
	}
	const [allSpan, setAllSpan] = useState([])
	const [currentCategory, setCurrentCategory] = useState({ id: 0, category_name: '' })
	const [manufacturers, setManufacturers] = useState([])
	const [categoryMenu, setCategoryMenu] = useState([])
	const [imageName, setImageName] = useState('')
	const [imageLocation, setImageLocation] = useState('')
	const [manufacturesSearch, setManufacturesSearch ] = useState({ id: 0, manufacturer_name: '' })
	const [previewImageLink,setPreviewImageLink] = useState('')
	const [openImagePreview,setOpenImagePreview] = useState(false)
	const { hasPermission } = usePermission()
	const createManufacturerPermission = hasPermission('add-manufacture')
	const createCategoryPermission = hasPermission('add-category')
	const [eolDate,setEolDate] = useState({
		date: null,
		error: false
	})
	const [eoslDate,setEoslDate] = useState({
		date: null,
		error: false
	})
	//form validation
	const schema = yup.object({
		model_name: yup.string().required('Model Name is required!'),
		model_no: yup.string().required('Model Number is required!'),
	})

	const defaultValues = {
		model_name: '',
		model_no: '',
		description: '',
		// eol_date: '',
		// eosl_date: '',
	}

	const addNewSpan = () => {
		let newSpan = {
			...spanTemp
		}
		setAllSpan((allSpan) => [...allSpan, newSpan])
	}

	const deleteSpan = (index) => {
		setAllSpan(allSpan.filter(span => allSpan[index] !== span )  )
	}

	const handleSpanInput = (index, value, propName) => {
		setAllSpan((spans) => {
			spans[index][propName] = value
			return [...spans]
		} )
	}

	const onCancel = () => {
		reset(defaultValues)
		setEolDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEoslDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		resetFormDetails()
		setAllSpan([{
		    ...spanTemp
		}])
		setImageName('')
		setImageLocation('')
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		setManufacturesSearch({ id: 0, manufacturer_name: '' })
		setCurrentCategory({ id: 0, category_name: '' })
	}

	/**--------------------Category---------------------------------------*/
	const [openCategoryDialog,setopenCategoryDialog] = useState(false)

	function handleCategoryDialogOpen(){
		setopenCategoryDialog(true)
	}

	function handleCategoryDialogClose(new_category=null){
		setopenCategoryDialog(false)

		//Set Quick added CATEGORY value into category field
		if(new_category)setCurrentCategory({ id: new_category.id, category_name: new_category.category_name })
	}

	/**-----------------Manufacture-------------------------------- */
	const [openManufactureDialog,setOpenManufactureDialog] = useState(false)
	function handleManufactureDialogOpen(){
		setOpenManufactureDialog(true)
	}

	function manufacturerFormReset(new_manufacturer=null){
		setOpenManufactureDialog(false)

		//Set Quick added MANUFACTURER value into manufacturer field
		if(new_manufacturer)setManufacturesSearch({ id: new_manufacturer.id, manufacturer_name: new_manufacturer.manufacturer_name })
	}

	/**---------------Dynamic Attributes------------------------ */
	const [openDynamicAttributeDialog,setOpenDynamicAttributeDialog] = useState(false)
	const [indexOfQuickAddButtonClick,setIndexOfQuickAddButtonClick] = useState(-1)

	function handleDynamicAttributeDialogOpen(index){
		setOpenDynamicAttributeDialog(true)
		setIndexOfQuickAddButtonClick(index)
	}

	function handleDynamicAttributeDialogClose(new_attribute=null){
		setOpenDynamicAttributeDialog(false)

		//Set Quick added Attribute value into Attribute field
		if(new_attribute){
			allSpan[indexOfQuickAddButtonClick] = {
				attribute:{
					attribute_name: new_attribute.attribute_name,
					id: new_attribute.id
				},
				attribute_value: ''
			}
		}
		setIndexOfQuickAddButtonClick(-1)
	}

	/*---------------------------------------------------------- */
	const fetchManufacturerSuggestions = (value = '') => {
		setLoading(true)
		axios.get("/manufacturers").then(response => {
			setLoading(false)
			setManufacturers((AlphabeticalSorting(response.data.data,'manufacturer_name')).filter((item)=>{ return Boolean( item.manufacturer_name)}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	async function fetchAvailableDynamicField(){
		try {
			const res = await axios.get('/model-custom-attributes')
			const { data } = res.data
			setDynamicAttributesMenu(AlphabeticalSorting(data, 'attribute_name'))
		} catch (err) {
			console.log(err)
		}
	}

	const manageManufacturerSearch = (value) => {
		if(!value) return ''

		let currentLocation = manufacturers.filter(item => item.manufacturer_name === value)
		let finalVal = {id:currentLocation[0].id,manufacturer_name:currentLocation[0].manufacturer_name}
		setManufacturesSearch(finalVal)

	}

	const fetchCategorySuggestions = () => {
		axios.get('/category?category_type=hardware').then(response => {
			setCategoryMenu((AlphabeticalSorting(response.data.data,'category_name')).filter((item)=>{ return Boolean( item.category_name)}))
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const onChangeImageUpload = (event) => {
		setLoading(true)
		const selectedFile = event.target.files[0]		
		if(!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
			setLoading(false)
			return;
		} else {
			let formData = new FormData()
			formData.append("file", selectedFile)
			axios.post('/assets/hardware-asset-image-upload', formData ).then(response => {
			if(response.status === 201) {
				toast.error(response.data.message)
			}
			else{
				setImageName(selectedFile.name)
				setImageLocation(response.data.data.imageLocation)
				setPreviewImageLink(response.data.data.imageLink)
			}
			}).catch(err => {
				console.log('136=>',err);
				toast.error('Something went wrong!')
			}).finally(() => {
				setLoading(false)
			})
		}
	}

	const manageCurrentCategory = (value) => {
		if(!value) return ''		
		let currentCategory = categoryMenu.filter((item => item.category_name === value))
		let finalVal = {id:currentCategory[0].id,category_name:currentCategory[0].category_name}
		setCurrentCategory(finalVal)
	}

	const closeDialog = () => {
		setEolDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEoslDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		setImageName('')
		setImageLocation('')
		setAllSpan([{
		  ...spanTemp
		}])
		setManufacturesSearch({ id: 0, manufacturer_name: '' })
		setCurrentCategory({ id: 0, category_name: '' })
	}

	const resetFormDetails = () => {
		setManufacturesSearch({ id: 0, manufacturer_name: '' })
		setCurrentCategory({ id: 0, category_name: '' })
		// setOptionalFields({ 
		// 	contractId: null, orderNumber: null, purchaseCost: null, purchaseDate: null, 
		// 	installDate: null, warrantyEndDate: null, eolDate: null, eoslDate: null 
		// })
	}

	const { control, formState, handleSubmit, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	useEffect(()=>{
		if(editFromState){
			setValue('model_name', editFromState.model_name)
			setValue('model_no',editFromState.model_no)
			setEolDate((prev) => {
				return {
					...prev,
					date: editFromState.eol
				}
			})
			setEoslDate((prev) => {
				return {
					...prev,
					date: editFromState.eosl
				}
			})
			// setValue('eol_date', editFromState.eol ? editFromState.eol.split('T')[0] : null)
			// setValue('eosl_date', editFromState.eosl ? editFromState.eosl.split('T')[0] : null)
			setValue('description',editFromState.original_info.description)
			setImageName(editFromState.original_info.image_name)
			setPreviewImageLink(editFromState?.preview_image_link ?? '')
			setImageLocation(editFromState.image_link)
			setCurrentCategory({
				id: editFromState.category.id, 
				category_name: editFromState.category.category_name 
			})
			setManufacturesSearch({
				id: editFromState.manufacturer.id, 
				manufacturer_name: editFromState.manufacturer.manufacturer_name 
			})

			let span_data = []
			for(let item of editFromState.dynamic_attribute_mapping){
				span_data.push({
					attribute: item.model_custom_attribute,
					attribute_value: item.attribute_value,
					has_related_value_in_assets: item.model_custom_attribute.asset_spec
				})
			}

			setAllSpan([
				...span_data,
				{...spanTemp}
			])
		}
	},[editFromState])

  const { errors } = formState

	const manageFormData = (formData) => {
		// if(imageName === '') {
		// 	toast.error('Can not proceed without uploading model image!')
		// 	return
		// }

		let dynamic_attributes = []
		for(let span of allSpan){
			if(span.attribute){
				// if(span.attribute_value === ''){
				// 	toast.error(`Can not proceed without value of ${span.attribute.attribute_name}`)
				// 	return 
				// }else{
					dynamic_attributes.push({
						attribute_id: span.attribute.id,
						attribute_value: String(span.attribute_value)
					})
				// }
			}
		}

		let payload
		let api_url

		if(!editFromState){
			payload = {
				entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
				model_name:formData.model_name,
				category_id:currentCategory.id,
				manufacturer_id:manufacturesSearch.id,
				model_no: formData.model_no,
				description: formData.description ?? '',
				eol_date: eolDate.date ?? '',
				eosl_date: eoslDate.date ?? '',
				image_link: imageLocation ?? '',
				image_name: imageName
			}
			if(dynamic_attributes.length){
				payload.dynamic_attributes = dynamic_attributes
			}
			api_url = '/models/add'
		}else{
			payload = {
				id: editFromState.id,
				entity_external_platform_id: String(Math.trunc(Math.random()*20000000)),
				model_name: formData.model_name,
				category_id: currentCategory.id,
				manufacturer_id: manufacturesSearch.id,
				model_no: formData.model_no,
				description: formData.description ?? '',
				eol_date: (eolDate.date == 'No Eol') ? '' : eolDate.date ? eolDate.date : '',
				eosl_date: (eoslDate.date == 'No Eosl') ? '' : eoslDate.date ? eoslDate.date : '',
				image_link: imageLocation ?? '',
				image_name: imageName ?? ''
			}
			if(dynamic_attributes.length){
				payload.dynamic_attributes = dynamic_attributes
			}
			api_url = '/models/update'
		}

		axios.post(api_url, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success(response.data.message)
				dispatch(setQuickFilter(null))
				props.getModels()
			}
		}).catch(err => {
			console.log('212=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const errorData = (error) => {
		console.log('363=>',error)
	}

	useEffect(() => {
		fetchManufacturerSuggestions()
		fetchCategorySuggestions()
		// fetchContractSuggestions()
		// fetchModelSuggestions()
		fetchAvailableDynamicField()
		setAllSpan([{
		    ...spanTemp
		}])
	}, [])

	//   useEffect(() => {
	//     setValue("allSpan", allSpan)
	//   }, [allSpan])

	return (
		<div>
			<Modal
				open={openImagePreview}
				// onClose={()=>{
				// 	setOpenImagePreview(false)
				// 	setPreviewImageLink('')
				// }}
				className={classes.modal}
			>
				<div style={{
					height: 500,
					width: 500,
					backgroundColor: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems:'center',
					borderRadius: '13px',
					display: 'flex',
					flexDirection: 'column'
				}}>
					<IconButton style={{left: '203px',bottom: '19px'}} onClick={()=>{setOpenImagePreview(false)}}>
							<Icon>close</Icon>
					</IconButton>
					<img style={{height: '400px',width: '400px'}} src={previewImageLink}></img>
				</div>
			</Modal>

			<AddCategoryDialog
				open={openCategoryDialog}
				handleCloseDialog={handleCategoryDialogClose}
				refreshList={fetchCategorySuggestions}
			/>

			<ManufacturerDialog
				open={openManufactureDialog}
				handleCloseDialog={manufacturerFormReset}
				refreshList={fetchManufacturerSuggestions}
			/>

			<AddModelAttributeDialog
				open={openDynamicAttributeDialog}
				handleCloseDialog={handleDynamicAttributeDialogClose}
				refreshList={fetchAvailableDynamicField}
			/>

			<Drawer anchor='right' open={openState.open} className='hello-drawer' classes={{ paper: classes.drawerWidth }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>  
					<DialogTitle>{ Boolean(editFromState) ? 'Update Model' : 'Add Model'}</DialogTitle>
					<IconButton style={{marginRight: '15px'}} onClick={ onCancel }>
						<Icon>close</Icon>
					</IconButton>
				</div>

				<form onSubmit={ handleSubmit(manageFormData, errorData) }>

				{/*----------------------Item Information------------------------- */}
					
					<div className={classes.subSection}>
						<div style={{ width: '100%' }}>
							<Typography style={{fontSize:'16px'}}>Required Information *</Typography>
							<Divider style={{marginBottom: '10px'}}/>
						</div>

						<div >
							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='model_no' 
										control={control}
										render={( { field} ) => (
											<TextField 
												{...field}
												variant='outlined'
												label='Model Number' 
												autoFocus
												fullWidth
												required
												style={{background: 'white'}}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='model_name' 
										control={control}
										render={( { field} ) => (
											<TextField 
												{...field}
												style={{background: 'white'}}
												variant='outlined'
												label='Model Name' 
												autoFocus
												fullWidth
												required
												// error={!!errors.model_name}
												// helperText={errors?.model_name?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/>
								</div>                             
							</div>

							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="category_id"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<Autocomplete
													style={{ width: '100%' }}
													options={categoryMenu}
													value={currentCategory}
													disablePortal
													onInputChange={(event) => {
														if(event) {
															manageCurrentCategory(event.target.innerText)
														}
													}}
													PaperComponent={({ children }) => (
														<Paper style={{ marginTop:'0px' }}>{children}
															{createCategoryPermission && <Button 
																style={{width:'100%'}} 
																variant="outlined" 
																color="primary" 
																onMouseDown={() => handleCategoryDialogOpen()}
															>
																Add Category
															</Button>}
														</Paper>
													)}
													getOptionLabel={option => option.category_name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
																<TextField
																	{...params}
																	style={{background: 'white'}}
																	label="Category"
																	FormHelperTextProps={{
																		style: {marginLeft : 0}
																	}}
																	variant="outlined"
																	fullWidth
																	required
																/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>  

								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name="manufacturer_id"
										className="mb-16"
										control={control}
										render={({ field }) => (
											<>
												<Autocomplete
													style={{ width: '100%' }}
													options={manufacturers}
													disablePortal
													value={manufacturesSearch}
													onInputChange={(event) => {
														if(event) {
															manageManufacturerSearch(event.target.innerText)
														}
													}}
													PaperComponent={({ children }) => (
													<Paper style={{ marginTop:'0px' }}>{children}
														{createManufacturerPermission && <Button 
																style={{width:'100%'}} 
																variant="outlined" 
																color="primary" 
																onMouseDown={() => handleManufactureDialogOpen()}
														>
															Add Manufacturer
														</Button>}
													</Paper>
													)}
													getOptionLabel={option => option.manufacturer_name ?? ''}
													renderInput={(params) => {
														return (
															<div ref={params.InputProps.ref}>
															<TextField
																{...params}
																style={{background: 'white'}}
																label="Manufacturer"
																error={!!errors.location}
																helperText={errors?.location?.message}
																FormHelperTextProps={{
																	style: {marginLeft : 0}
																}}
																variant="outlined"
																fullWidth
																required
															/>
															</div>
														)
													}}
												/>
											</>
										)}
									/>
								</div>                         
							</div>
							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div className='cstm-date' style={{marginBottom: '10px', marginRight: '10px', width: '100%'}}>
									{/* <Controller
										name='eol_date' 
										control={control}
										render={( { field} ) => (
											<KeyboardDatePicker 
												{...field}
												// className={classes.customDatePicker}
												style={{background: 'white'}}
												disableToolbar
												variant='inline'
												viewPickerClassName={classes.customDatePicker}
												// margin="normal"
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
												// label='EOL' 
												placeholder='MM/DD/YYYY'
												autoFocus
												fullWidth
												format="MM-dd-yyyy"
												InputLabelProps={{ shrink: true }}
												error={!!errors.eol_date}
												helperText={errors?.eol_date?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/> */}
									{/* <CustomDatePicker
										state={eolDate}
										setState={setEolDate}
										required={false}
										label='EOL Date'								
									/> */}
									
									<CustomDatePickerV2 
										state={eolDate}
										setState={setEolDate}
										required={false}
										label='EOL Date'
									/>
									{/* <span style={{position:"absolute",top:-8,left:10,background:"#f8f8f8",color:"rgb(132 132 132)",padding:'0 3px'}}>EOL</span> */}
								</div>
								<div className='cstm-date' style={{marginBottom: '10px', marginRight: '10px', width: '100%'}}>
									{/* <Controller
										name='eosl_date' 
										control={control}
										render={( { field} ) => (
											<KeyboardDatePicker 
												{...field}
												style={{background: 'white'}}
												disableToolbar
												variant='inline'
												// margin="normal"
												KeyboardButtonProps={{
													'aria-label': 'change date',
												}}
												// label='EOSL' 
												autoFocus
												fullWidth
												format="MM-dd-yyyy"
												placeholder='MM/DD/YYYY'
												className={classes.datePickerPopup}
												PopperProps={{
												className: classes.datePickerPopup,
											}}
												InputLabelProps={{ shrink: true }}
												error={!!errors.eosl_date}
												helperText={errors?.eosl_date?.message}
												FormHelperTextProps={{
													style: { 
														margin : 0, 
														backgroundColor: '#f4f4f4',
														width: '100%',
														paddingTop: '2px'
													}
												}}
											/>
										)}
									/> */}
									{/* <CustomDatePicker
										state={eoslDate}
										setState={setEoslDate}
										required={false}	
										label='EOSL Date'											
									/> */}
									<CustomDatePickerV2 
										state={eoslDate}
										setState={setEoslDate}
										required={false}
										label='EOSL Date'
									/>
									{/* <span style={{position:"absolute",top:-8,left:10,background:"#f8f8f8",color:"rgb(132 132 132)",padding:'0 3px'}}>EOSL</span> */}
								</div>                              
							</div>
							<div style={{ display: 'flex', justifyContent:'center' }}>
								<div style={{marginBottom: '10px', marginRight: '10px', width: '100%' }}>
									<Controller
										name='description' 
										control={control}
										render={( { field} ) => (
											<TextareaAutosize 
												{...field}
												style={{background: 'white',width: '100%',minHeight: '20%',border: 'solid 2px #c8c5c5'}}
												variant='outlined' 
												label='Description' 
												fullWidth
												multiline
												placeholder="Description"
												error={!!errors.description}
												helperText={errors?.description?.message}
											/>
										)}
									/>
								</div>                              
							</div>
							<div style={{display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>
								<Typography variant='subtitle1' style={{marginBottom: '10px'}}>Upload Image</Typography>
								<div style={{display:'flex', alignItems: 'center'}}>
									<input
										accept="image/*"
										id="upload"
										type="file"
										onChange = {(event) => onChangeImageUpload(event)}
										hidden
									/>
									<label htmlFor='upload' className={classes.uploadImageButton}>
										Select File
									</label>
									<div style={{marginLeft: '10px'}}>
									{ loading ? 
										<>
											<div className={classes.loader}>
												<CircularProgress />
											</div>
										</> : <a onClick={()=>{
												setOpenImagePreview(true)
											}
										} 
										style={{color: 'black',textDecoration:'none',cursor: 'pointer'}}>{imageName}</a>
									}
									</div>
								</div>
							</div>
							
							<Typography>{"Accepts jpg, jpeg, png, gif. 2Mb max (rec. 700x430px)"}</Typography>

							{ 
								allSpan.map((span, index) => {
									return ( 
										<div style={{ display: 'flex', justifyContent:'center', width:'100%', border:'1px solid #ddd', borderRadius: '5px', padding: '10px', marginTop: '15px' ,marginBottom: '10px' }} key={index}>
											<div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
												<div style={{ display: 'flex', justifyContent:'center', marginBottom: '10px', width:'100%' }}>
													<div style={{ marginRight: '10px', width: '100%' }}>
														<Controller
															name="allSpan"
															className="mb-16"
															control={control}
															render={({ field: { ref, ...field }, fieldState: { error } }) => (
																<>
																	<Autocomplete
																		{...field}
																		style={{ width: '100%' }}
																		options={dynamicAttributesMenu}
																		// disablePortal
																		getOptionLabel={option => option.attribute_name ?? ''}
																		value={span.attribute}
																		disabled={Boolean(span.has_related_value_in_assets)}
																		onChange={(event,value) => {
																			// onChange(event)
																			handleSpanInput(index, value, "attribute")
																		}}
																		getOptionDisabled={option => Boolean(allSpan.find((i)=>i.attribute?.id == option.id))}
																		PaperComponent={({ children }) => (
																			<Paper style={{marginTop: 0,position:'relative',left:'-30px'}}>{children}
																				<Button 
																					style={{width:'100%'}} 
																					variant="outlined" 
																					color="primary" 
																					onMouseDown={() => handleDynamicAttributeDialogOpen(index)}
																				>Add Attribute</Button>
																			</Paper>
																		)}
																		renderInput={params => {
																			return (
																				<div >
																					<TextField
																						{...params}
																						style={{background: 'white'}}
																						label='Attribute'
																						variant='outlined'
																						fullWidth
																						FormHelperTextProps={{
																							style: {marginLeft : 0}
																						}}
																					/>
																				</div>
																			)
																		}}
																	/>
																</>
															)}
														/>
													</div>

													<div style={{ width: '100%' }}>
														<Controller
															name='allSpan' 
															control={control}
															render={( { field: { onChange, ...field } } ) => (
																<TextField 
																	{...field}
																	style={{background: 'white'}}
																	value={span.attribute_value}
																	onChange={event => {
																		handleSpanInput(index, event.target.value, "attribute_value")
																	}}
																	variant='outlined'
																	label='Value' 
																	fullWidth
																/>
															)}
														/>
													</div>
													
													{ 
														(index === 0 ? 
															<Tooltip title='Add More' placement='top'>
																<IconButton onClick={() => addNewSpan()}>
																	<Icon>add_circle_outline</Icon>
																</IconButton>
															</Tooltip>
															:
															<Tooltip title='Remove Span' placement='top'>
																<IconButton disabled={Boolean(span.has_related_value_in_assets)} onClick={() => deleteSpan(index)} >
																	<Icon>cancel_circle</Icon>
																</IconButton>
															</Tooltip>
														)
													}
												</div>
											</div>
										</div>
									)
								})
							} 
						</div>
					</div>
					<div style={{padding:0, margin:20 ,display:'flex', justifyContent:'left'}}>
						<Button className={classes.endButtons} style={{marginRight: '10px'}} type='submit' variant='contained' color='primary' /*disabled={Object.keys(errors).length !== 0}*/>{editFromState ? 'Update' : 'Add'}</Button>
						<Button className={classes.endButtons}  variant='outlined' color='primary' onClick={ onCancel }>Cancel</Button>
					</div>						
				</form>        
			</Drawer>
		</div>
	)
}

export default AddModelDialog
