import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import endUsersAccounts from '../shared/endUsersAccounts';
import EndUsersAccountsTable from '../../tables';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';

import ContractLineItemsTab from './Tabs/ContractLineItems';
import QuotesTab from './Tabs/Quotes';
import EmailTab from './Tabs/Email';
import NotesTab from './Tabs/Notes';
import ChatTab from './Tabs/ChatView';
// import { usePageTitle } from '@fuse/hooks';

const drawerWidth = 400;
const headerHeight = 200;

const views = ['List View', 'Grid View'];

const defaultCounts = { end_users: 0, locations: 0 };

const defaultSearches = { location: '', quote_indicator: '', quote_status: '', revenue: '', action: '' }

const useStyles = makeStyles(theme => ({
    '@global': {
        '#fuse-main': {
            height: '100vh'
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100%',
        position: 'relative',
        // flex: '1 1 auto',
        height: 'auto',
        backgroundColor: theme.palette.background.default
    },
    cardGen: {
        width: '100%',
    },
    topBg: {
        // backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
        // backgroundColor: theme.palette.primary.dark,
        backgroundSize: 'cover',
        pointerEvents: 'none',
        padding: "10px"
    },
    topBgFixed: {
        height: headerHeight,
        backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
        backgroundColor: theme.palette.primary.dark,
        backgroundSize: 'cover',
        pointerEvents: 'none'
    },
    contentCardWrapper: {
        maxWidth: 1400,
        display: 'flex',
        flexDirection: 'row',
        // flex: '1 0 auto',
        width: '100%',
        minWidth: '0',
        margin: '0 auto',
        // [theme.breakpoints.down('sm')]: {
        // 	padding: '16px 16px 0 16px'
        // },
        // [theme.breakpoints.down('xs')]: {
        // 	padding: '12px 12px 0 12px'
        // }
    },
    contentCard: {
        display: 'flex',
        position: 'relative',
        // flex: '1 1 100%',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
        minHeight: 0,
        overflow: 'hidden',
        borderRadius: '2px 2px 0 0',
    },
    drawerPaper: {
        width: drawerWidth,
        maxWidth: '100%',
        overflow: 'hidden',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            position: 'relative'
        }
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        // flex: '1 1 100%',
        zIndex: 10,
        background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
            theme.palette.background.paper,
            0.6
        )} 20%,${fade(theme.palette.background.paper, 0.8)})`
    },
    content: {
        display: 'flex',
        // flex: '1 1 100%',
        minHeight: 0
    },
    avatar: {
        backgroundColor: red[500],
    },
    formControl: {
    },
    pullRight: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    formControlMinWidth: {
        minWidth: 200,
    },
    buttonSquare: {
        borderRadius: '5px'
    },
    card: {
        borderRadius: "12px"
    },
    cardContainer: {
    },
    borderRightLight: {
        borderRight: "1px solid rgba(0,0,0,0.12)"
    },
    buttonSquare: {
        borderRadius: '5px'
    },
    activeTab: {
        borderBottom: "2px solid",
        borderBottomLeftRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
    },
}));

function TabButton(props) {
    const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;

    const isActive = tab == tabState;

    return (
        <Button {...restProps} onClick={() => tabStateChange(tab)} className={restProps.className, isActive ? activeClass : ""}>
            {props.children}
        </Button>
    )
}

function TabContainer(props) {

    const { tabState, tab } = props;

    const shouldRender = tab == tabState;

    return shouldRender ? props.children : <></>
}

function Main(props) {
    const dispatch = useDispatch();
    const [selectedView, setSelectedView] = useState(0);
    const [counts, setCounts] = useState(defaultCounts);
    const [quotes, setQuotes] = useState([]);
    const [companyInitial, setCompanyInitial] = useState('');
    const [searches, setSearches] = useState(defaultSearches);
    const classes = useStyles(props);
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [endUsersAccountsMod, setEndUsersAccounts] = useState([]);
    const [flattenedEndUserAccounts, setFlattenedEndUserAccounts] = useState({});
    const [contractDetails, setContractDetails] = useState(null);

    const [tabState, setTabState] = useState("contract_line_items");

    // const updatePageTitle = usePageTitle(dispatch);

    useEffect(() => {
        // updatePageTitle("Contract Profile");

        const id = router.params.id;

        setLoading(true);

        axios.get(`/end-user-contracts/${id}`)
            .then(res => {
                const { data } = res.data;

                setContractDetails(data)
            })
            .catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            })
    }, []);

    function calculatePricing(lineItem) {
        let { modified_sell_price, cost, msrp, discount } = lineItem;

        let temp_modified_sell_price = (modified_sell_price - (modified_sell_price * (discount / 100))).toFixed(2);

        let profit = Math.max(0, (temp_modified_sell_price - cost).toFixed(2));
        let profit_margin = ((profit / modified_sell_price) * 100).toFixed(2);

        return {
            ...lineItem,
            profit_margin,
            discount,
            profit,
        }
    }

    function clearSearch() {
        setSearches(defaultSearches);
        setQuotes(endUsersAccounts);
    }

    if (loading) {
        return (
            <FuseLoading />
        )
    }

    return (<>
        <div className={clsx(classes.root)}>
            <FuseScrollbars className="flex-grow">
                {contractDetails?.id &&
                    <Grid container spacing={1} style={{ padding: 20 }}>
                        <Grid item lg={3} md={3} sm={3} xs={3} >
                            <Card className={classes.card} style={{ margin: 0, height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div className="mb-16" style={{ display: "flex", alignItems: "center", flexGap: 2 }}>
                                        <Icon className="text-blue-400 mr-6" fontSize="large">description</Icon>
                                        <h2 style={{ fontWeight: "bold" }}>Contract Info</h2>
                                    </div>

                                    <div className="mb-10">
                                        <h4 style={{ fontWeight: "bold" }}>{contractDetails.entity_external_platform_id}</h4>
                                    </div>

                                    <div className="mb-10">
                                        <h6>Service type: {contractDetails.service_type || "Various"}</h6>
                                    </div>

                                    <div className="mb-10">
                                        <h6>Service level: {contractDetails.service_level || "Various"}</h6>
                                    </div>

                                    <div className="mb-10">
                                        <h6>Service plan: {contractDetails.service_plan || "Gold"}</h6>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3} >
                            <Card className={classes.card} style={{ margin: 0, height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div className="mb-16" style={{ display: "flex", alignItems: "center", flexGap: 2 }}>
                                        <Icon className="text-yellow-400 mr-6" fontSize="large">phone</Icon>
                                        <h2 style={{ fontWeight: "bold" }}>Contact Info</h2>
                                    </div>

                                    <div className="mb-20">
                                        <h6>{contractDetails.user_companies_user?.first_name} {contractDetails.user_companies_user?.last_name}</h6>
                                    </div>

                                    {contractDetails.user_companies_user?.email && (
                                        <div className="mb-10" style={{ display: "flex", alignItems: "center", flexGap: 2 }}>
                                            <Icon className="mr-6" fontSize="small">email</Icon>
                                            <h6>{contractDetails.user_companies_user?.email}</h6>
                                        </div>
                                    )}

                                    {contractDetails.user_companies_user?.phone && (
                                        <div style={{ display: "flex", alignItems: "center", flexGap: 2 }}>
                                            <Icon className="mr-6" fontSize="small">phone</Icon>
                                            <h6>{contractDetails.user_companies_user?.phone}</h6>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3} >
                            <Card className={classes.card} style={{ margin: 0, height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div className="mb-16" style={{ display: "flex", alignItems: "center", flexGap: 2 }}>
                                        <Icon className="text-green-400 mr-6" fontSize="large">local_shipping</Icon>
                                        <h2 style={{ fontWeight: "bold" }}>Location</h2>
                                    </div>

                                    <div style={{ display: "flex" }}>
                                        <div><Icon className="mr-6" fontSize="small">location_on_outlined</Icon></div>
                                        <div>
                                            <p>{String([contractDetails.shipping?.location_name, contractDetails.shipping?.address1, contractDetails.shipping?.address2].filter(Boolean))}</p>
                                            <p>{String([contractDetails.shipping?.city, `${contractDetails.shipping?.state ?? 'N/A'}-${contractDetails.shipping?.zip}`, contractDetails.shipping?.country].filter(Boolean))}</p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3} >
                            <Card className={classes.card} style={{ margin: 0, height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div className="mb-16" style={{ display: "flex", alignItems: "center", flexGap: 2 }}>
                                        <Icon className="text-yellow-400 mr-6" fontSize="large">description</Icon>
                                        <h2 style={{ fontWeight: "bold" }}>Billing Address</h2>
                                    </div>

                                    <div style={{ display: "flex" }}>
                                        <div><Icon className="mr-6" fontSize="small">location_on_outlined</Icon></div>
                                        <div>
                                            <p>{String([contractDetails.billing?.location_name, contractDetails.billing?.address1, contractDetails.billing?.address2].filter(Boolean))}</p>
                                            <p>{String([contractDetails.billing?.city ?? 'N/A', `${contractDetails.billing?.state ?? 'N/A'}-${contractDetails.billing?.zip}`, contractDetails.billing?.country].filter(Boolean))}</p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }

                {contractDetails?.id &&
                    <Grid container spacing={1} style={{ padding: 14 }}>
                        <Grid item lg={2} md={2} sm={2} xs={2} >
                            <Card className={classes.card} style={{ height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div style={{ display: "flex", alignItems: "center", flexGap: 2 }} className="mb-20">
                                        <Icon className="text-pink-400 mr-6" fontSize="default">person</Icon>
                                        <h3 style={{ fontWeight: "bold" }}>Agent info</h3>
                                    </div>
                                    <div>
                                        <h4>{contractDetails.client_user?.first_name} {contractDetails.client_user?.last_name}</h4>
                                    </div>

                                    {contractDetails.client_user?.email && (
                                        <div className="mb-10" style={{ display: "flex", alignItems: "center", flexGap: 2 }}>
                                            <Icon className="mr-6" fontSize="small">email</Icon>
                                            <h6>{contractDetails.client_user?.email}</h6>
                                        </div>
                                    )}
                                    
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} >
                            <Card className={classes.card} style={{ height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div style={{ display: "flex", alignItems: "center", flexGap: 2 }} className='mb-20'>
                                        <Icon className="text-blue-400 mr-6" fontSize="default">business</Icon>
                                        <h3 style={{ fontWeight: "bold" }}>Company</h3>
                                    </div>
                                    <div className="mb-20">
                                        <h3>{contractDetails.user_company?.company_name}</h3>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} >
                            <Card className={classes.card} style={{ height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div style={{ display: "flex", alignItems: "center", flexGap: 2 }} className='mb-20'>
                                        <Icon className="text-yellow-400 mr-6" fontSize="default">widgets</Icon>
                                        <h3 style={{ fontWeight: "bold" }}>Assets</h3>
                                    </div>
                                    <div className="mb-20">
                                        <h3>{contractDetails?.contract_line_items?.length}</h3>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} >
                            <Card className={classes.card} style={{ height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div style={{ display: "flex", alignItems: "center", flexGap: 2 }} className='mb-20'>
                                        <Icon className="text-green-400 mr-6" fontSize="default">check_circle</Icon>
                                        <h3 style={{ fontWeight: "bold" }}>Status</h3>
                                    </div>
                                    <div className="mb-20">
                                        <h3>{contractDetails.contract_status}</h3>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} >
                            <Card className={classes.card} style={{ height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div style={{ display: "flex", alignItems: "center", flexGap: 2 }} className='mb-20'>
                                        <Icon className="text-green-400 mr-6" fontSize="default">calendar_today</Icon>
                                        <h3 style={{ fontWeight: "bold" }}>Contract Start Date</h3>
                                    </div>
                                    <div className="mb-20">
                                        <h3>{dateReformatTo_mmm_dd_yyyy(new Date(contractDetails.contract_start_date).toISOString())}</h3>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} >
                            <Card className={classes.card} style={{ height: "100%" }}>
                                <CardContent className={classes.cardContainer}>
                                    <div style={{ display: "flex", alignItems: "center", flexGap: 2 }} className='mb-20'>
                                        <Icon className="text-red-400 mr-6" fontSize="default">calendar_today</Icon>
                                        <h3 style={{ fontWeight: "bold" }}>Contract end date</h3>
                                    </div>
                                    <div className="mb-20">
                                        <h3>{dateReformatTo_mmm_dd_yyyy(new Date(contractDetails.contract_end_date).toISOString())}</h3>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }

                <Card className={classes.card} style={{ margin: 14 }}>
                    <CardActions disableSpacing>
                        <TabButton
                            tab="contract_line_items"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '150px' }}>
                            Contract line items
                        </TabButton>
                        {/* <TabButton
                            tab="quotes"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '120px' }}>
                            Quotes
                        </TabButton> */}
                        <TabButton
                            tab="email"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '120px' }}>
                            Email
                        </TabButton>
                        <TabButton
                            tab="chat"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '150px' }}
                            onClick={e => gotoContacts(e)}>
                            Chat
                        </TabButton>
                        <TabButton
                            tab="notes"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '120px' }}>
                            Notes
                        </TabButton>
                        <TabButton
                            tab="profitability"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '150px' }}>
                            Profitability
                        </TabButton>
                        <TabButton
                            tab="system_information"
                            tabState={tabState}
                            tabStateChange={setTabState}
                            activeClass={classes.activeTab}
                            variant="text"
                            color="primary"
                            className={`w-full ${classes.buttonSquarePadded}`}
                            style={{ width: '150px' }}>
                            System information
                        </TabButton>
                    </CardActions>
                </Card>

                {contractDetails?.id && (
                    <Card className={classes.card} style={{ margin: 14 }}>
                        <CardContent className={classes.cardContainer}>
                            <TabContainer tab="contract_line_items" tabState={tabState}>
                                <ContractLineItemsTab contractId={contractDetails?.entity_external_platform_id} />
                            </TabContainer>
                            <TabContainer tab="quotes" tabState={tabState}>
                                <QuotesTab contractId={contractDetails?.id} />
                            </TabContainer>
                            <TabContainer tab="email" tabState={tabState}>
                                <EmailTab contractId={contractDetails?.id} />
                            </TabContainer>
                            <TabContainer tab="chat" tabState={tabState}>
                                <ChatTab contractId={contractDetails?.id}/>
                            </TabContainer>
                            <TabContainer tab="notes" tabState={tabState}>
                                <NotesTab contractId={contractDetails?.id}  />
                            </TabContainer>
                            <TabContainer tab="profitability" tabState={tabState}>
                                Profitability
                            </TabContainer>
                            <TabContainer tab="system_information" tabState={tabState}>
                                System information
                            </TabContainer>
                        </CardContent>
                    </Card>
                )}
            </FuseScrollbars>
        </div>
    </>);
}

function ArrowButton({ onClick, label }) {
    return (
        <span
            onClick={() => onClick}
            className="arrow-pointer">
            {label}
        </span>
    );
}

export default Main;
