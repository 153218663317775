import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Button, Icon, IconButton, makeStyles, Modal, Paper, TextField } from '@material-ui/core';
import React, { useState,useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab';
import Editor from '@fuse/components/Editor';
import LocationTypeDialog from './QuickAddLocationType';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function LocationDialog(props) {

  const classes = useStyles()
  const dispatch = useDispatch()
  const toast = useToast(dispatch)
  const [ locationNote,setLocationNote ] = useState('')
  const [ locationTypes, setLocationTypes ] = useState([])

  const shippingLocationSchema = yup.object({
    shipAddress: yup.string().required('Location Address is required!'),
    shipLocationName: yup.string().required('Location Name is required!'),
    shipCity: yup.string().required('City is required!'),
    shipState: yup.string().required('State/Province is required!'),
    shipZip: yup.string().required('Zip is required!'),
    // shipCountry: yup.string().required('Country is required!'),
  })

  const shippingLocationDefaultValues = {
    billAddress:'',
    address2: '',
    locationName: '',
    city: '',
    state:'',
    zip:'',
    country: {},
    locationType: null
  }

  const { control: shippingLocationFormControl, 
    formState:{errors: shippingLocationFormErrors}, 
    handleSubmit: shippingLocationFormSubmit, 
    reset: shippingLocationFormReset,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: shippingLocationDefaultValues,
    resolver: yupResolver(shippingLocationSchema)
  });

  function manageShippingFormData(formData){
    // console.log('manageShippingFormData: ',formData);
    const payload = {
      entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
      address: formData.shipAddress,
      address2: formData.address2,
      location: formData.shipLocationName,
      city: formData.shipCity,
      state: formData.shipState,
      zip: formData.shipZip,
      country: formData.shipCountry.name || '',
      note: locationNote,
      location_type_id: formData.locationType.id
    }

    axios.post('/shipping/add', payload).then(response => {
      if(response.status == 201){
        toast.error(response.data.message)
        return
      }

      shippingLocationFormReset(shippingLocationDefaultValues)

      //Send newly added location value
      props.handleCloseLocationDialog(response.data.data.new_shipping_location)
      toast.success('Location added successfully.')
      props.fetchLocationSuggestions()
        
    }).catch(err => {
      toast.error(err.response.data.data)
    })
  }

  function shippingFormErrorData(error){
    // console.log('shippingFormErrorData: ',error);
  }

  function onEditorContentChange (content) {
    setLocationNote(content)
  }

  function getLocationTypes(){
		axios.get("/location-type").then((res) => {
			const { data } = res.data;
			setLocationTypes((()=>{
        return data.map((item)=>{
          return {
            id: item.id,
            name: item.name
          }
        })
      })())
		}).catch((err) => {
			console.log(err)
		})
	}

  const [createNewLocationType,setCreateNewLocationType] = useState(false)

  const handleLocationTypeDialogClose = (new_location_type=null) => {
		setCreateNewLocationType(false)

		//Set Quick added LOCATION TYPE value into field
		if(new_location_type){
      console.log('new_location_type: ', new_location_type);
      setValue('locationType',{id:new_location_type.id,name:new_location_type.name})
    }
	}
  const handleLocationTypeDialogOpen = () => {
		setCreateNewLocationType(true)
	}

  useEffect(()=>{
    getLocationTypes()
  },[])

  return (
    <>
      <Modal
        open={props.createNewShippingLocation}
        className={classes.modal}
      >
        <div style={{width:700, backgroundColor: 'white', borderRadius:9,display:'flex', flexDirection:'column', padding:'20px', height: '100vh', overflow: 'auto'}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
            <h1>Add Location</h1>
            <IconButton onClick={()=>{
              shippingLocationFormReset(shippingLocationDefaultValues)
              props.handleCloseLocationDialog()
            }}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <form onSubmit={shippingLocationFormSubmit(manageShippingFormData, shippingFormErrorData)}>
            <div style={{backgroundColor: 'rgb(239 239 239)', borderRadius:9, padding: '15px'}}>              
              <Controller
                name="shipLocationName"
                className="mb-16"
                defaultValue={''}
                control={shippingLocationFormControl}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Location Name'
                      required
                      // error={!!shippingLocationFormErrors.shipLocationName}
                      // helperText={shippingLocationFormErrors?.shipLocationName?.message}
                      FormHelperTextProps={{
                        style: { 
                          margin : 0, 
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                        }
                      }}
                      style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                    />
                  </>
                )}
              />

              <Controller
                name="shipAddress"
                className="mb-16"
                defaultValue={''}
                control={shippingLocationFormControl}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Address Line 1'
                      required
                      // fullWidth
                      // error={!!shippingLocationFormErrors.shipAddress}
                      // helperText={shippingLocationFormErrors?.shipAddress?.message}
                      FormHelperTextProps={{
                        style: { 
                          margin : 0, 
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                        }
                      }}
                      style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                    />
                  </>
                )}
              />

              <Controller
                name="address2"
                className="mb-16"
                defaultValue={''}
                control={shippingLocationFormControl}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Address Line 2'
                      FormHelperTextProps={{
                        style: {
                          margin : 0, 
                          backgroundColor: '#f4f4f4',
                          width: '100%',
                          paddingTop: '2px'
                        }
                      }}
                      style={{ marginBottom: '10px', width: '100%',background: 'white' }}
                    />
                  </>
                )}
              />

              <div style={{ display:'flex', width: '100%' }}>                  
                <Controller
                  name="shipCity"
                  className="mb-16"
                  defaultValue={''}
                  control={shippingLocationFormControl}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        variant='outlined'
                        label='City'
                        // fullWidth
                        required
                        // error={!!shippingLocationFormErrors.shipCity}
                        // helperText={shippingLocationFormErrors?.shipCity?.message}
                        FormHelperTextProps={{
                          style: { 
                            margin : 0, 
                            backgroundColor: '#f4f4f4',
                            width: '100%',
                            paddingTop: '2px'
                          }
                        }}
                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }}
                      />
                    </>
                  )}
                />

                <Controller
                  name="shipState"
                  className="mb-16"
                  defaultValue={''}
                  control={shippingLocationFormControl}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        variant='outlined'
                        label='State/Province'
                        required
                        // fullWidth
                        // error={!!shippingLocationFormErrors.shipState}
                        // helperText={shippingLocationFormErrors?.shipState?.message}
                        FormHelperTextProps={{
                          style: { 
                            margin : 0, 
                            backgroundColor: '#f4f4f4',
                            width: '100%',
                            paddingTop: '2px'
                          }
                        }}
                        style={{ marginBottom: '10px', width: '100%' ,background: 'white'}}
                      />
                    </>
                  )}
                />
              </div>

              <div style={{ display:'flex', width: '100%' }}>
                <Controller
                  name="shipZip"
                  className="mb-16"
                  defaultValue={''}
                  control={shippingLocationFormControl}
                  render={({ field }) => (
                    <>
                      <TextField
                        {...field}
                        variant='outlined'
                        label='Zip'
                        required
                        // fullWidth
                        // error={!!shippingLocationFormErrors.shipZip}
                        // helperText={shippingLocationFormErrors?.shipZip?.message}
                        FormHelperTextProps={{
                          style: { 
                            margin : 0, 
                            backgroundColor: '#f4f4f4',
                            width: '100%',
                            paddingTop: '2px'
                          }
                        }}
                        style={{ marginBottom: '10px', width: '100%', marginRight: '10px',background: 'white'  }}
                      />
                    </>
                  )}
                />
                <Controller
                  name="shipCountry"
                  className="mb-16"
                  control={shippingLocationFormControl}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <>
                      <Autocomplete
                        {...field}
                        style={{ width: '100%' }}
                        options={countries}
                        // disablePortal
                        onChange={(event, value) => field.onChange(value)}
                        PaperComponent={({ children }) => (
                          <Paper style={{ marginBottom: '20px' }}>{children}</Paper>
                        )}
                        getOptionLabel={option => option.name ?? ''}
                        renderInput={(params) => {
                          return (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                style={{background: 'white'}}
                                {...params}
                                label="Country"
                                required
                                // error={!!shippingLocationFormErrors.shipCountry}
                                // helperText={shippingLocationFormErrors?.shipCountry?.message}
                                FormHelperTextProps={{
                                  style: { 
                                    margin : 0, 
                                    backgroundColor: '#f4f4f4',
                                    width: '100%',
                                    paddingTop: '2px'
                                  }
                                }}
                                inputRef={ref}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          )
                        }}
                      />
                    </>
                  )}
                />
              </div>
              
              <Controller
                name="locationType"
                // className="mb-16"
                control={shippingLocationFormControl}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <>
                    <Autocomplete
                      {...field} 
                      style={{ width: '100%'}}
                      options={locationTypes}
                      // disablePortal
                      onChange={(event, value) => field.onChange(value)}
                      PaperComponent={({ children }) => (
                        <Paper style={{ marginTop:'0px' }}>{children}
                          <Button 
                            style={{width:'100%'}} 
                            variant="outlined" 
                            color="primary" 
                            onMouseDown={() => handleLocationTypeDialogOpen()}
                          >Add Location Type</Button>
                        </Paper>
                      )}
                      getOptionLabel={option => option.name ?? ''}
                      renderInput={(params) => {
                        return (
                          <div ref={params.InputProps.ref}>
                            <TextField
                              name="locationType"
                              style={{background: 'white'}}
                              {...params}
                              label="Location Type"
                              required
                              FormHelperTextProps={{
                                style: { 
                                  margin : 0, 
                                  backgroundColor: '#f4f4f4',
                                  width: '100%',
                                  paddingTop: '2px'
                                }
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        )
                      }}
                    />
                  </>
                )}
              />
              <div style={{ display: 'flex', justifyContent:'center' }}>
                <div style={{marginBottom: '10px',  marginTop:'10px' ,marginRight: '10px', width: '100%' }}>
                  <Editor
                    editorContent={locationNote}
                    onEditorContentChange={onEditorContentChange}
                  />        
                </div>
              </div>
            </div>
            <div style={{display:'flex',gap:'20px',paddingTop:'20px'}}>
              <Button
                variant='contained' color='primary' size='small' style={{ borderRadius: '4px' }}
                type='submit'
              >Add</Button>
              <Button 
                variant='outlined' color='primary' size='small' style={{ borderRadius: '4px' }}
                onClick={()=>{
                  shippingLocationFormReset(shippingLocationDefaultValues)
                  props.handleCloseLocationDialog()
                }}
              >Cancel</Button>
            </div>
          </form>
        </div>
      </Modal>

      <LocationTypeDialog
        open={createNewLocationType}
        handleCloseDialog={handleLocationTypeDialogClose}
        refresh={getLocationTypes}
      />
    </>
  )
}

export default LocationDialog
