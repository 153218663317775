import FusePageCardedOne from '@fuse/core/FusePageCardedOne';
import { React, useState, useRef, useEffect } from "react";
import { Button, Box, Tabs, Tab } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '@fuse/hooks';
import axios from '@fuse/utils/axios';
import UserInfo from "./Tabs/ListView";
import Contract from "./Tabs/Contract";
import Assets from "./Tabs/Assets"
import ServiceTicketList from "./Tabs/serviceTicket";
import useToast from '@fuse/hooks/useToast'
import { useDispatch, useSelector } from 'react-redux';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import AddCustomerFormDialog from './addCustomerDialog';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});


function ListViewDetails(props) {
	const classes = useStyles();
    const activityComponentRef = useRef(null)
    const router = useRouter()
    const id = router.params.id ?? props?.serviceRequestId;
	const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [ clientDetails, setClientDetails ] = useState();
	const openState = useSelector((state) => state.SuperAdminDashboard.formViewDialog)

	const handleChangeTabValue = (event, newValue) => {
		setTabValue(newValue);
	};

    function flattenClientDetails(data){
		return{
			    id: data.clientDetails.id,
				client_name: data.clientDetails.user_company.company_name,
				company_id: data.clientDetails.user_company.id,
				first_name: data.clientDetails.first_name,
				last_name: data.clientDetails.last_name,
				email_address: data.clientDetails.email,
				phone_number: Boolean(data.clientDetails.phone) ? (data?.clientDetails?.country_code) + data.clientDetails.phone : '',
                phone: data.clientDetails.phone,
				status: data.clientDetails.status,
				subdomain: data.clientDetails.user_company.subdomain,
                country_code: data.clientDetails.country_code,
                company_name: data.clientDetails.user_company.company_name,
				time_zone: data.clientDetails.user_company.timezone,
				industry: data.clientDetails.user_company.industry,
				created_date: dateReformatTo_mmm_dd_yyyy(data.clientDetails.created_at),
				updated_date: dateReformatTo_mmm_dd_yyyy(data.clientDetails.updated_at),
				total_assets: data.totalAssetCount.toLocaleString(),
		}
	}

    function fetchClientDetails(){
		axios.get(`/super-admin/get-details?id=${id}`).then((res)=>{
            const flattenData = flattenClientDetails(res.data.data)
			setClientDetails(flattenData)
		}).catch((err)=>console.log(err))
	}

	useEffect(()=>{
		fetchClientDetails()
	},[]);

	function a11yProps(index) {
		return {
		  id: `simple-tab-${index}`,
		  'aria-controls': `simple-tabpanel-${index}`,
		};
	}


	const handleEdit = () => {
		dispatch(setFromData(clientDetails))
		dispatch(openFormViewerDialog())
	}
	
	return (
		<>
        	{openState.open && <AddCustomerFormDialog getCustomer = {fetchClientDetails}/>}
			<FusePageCardedOne
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				content={
					<>
                        <div className='flex'>
                            <div className='py-32 w-full'>
                                <Button className='transparent_icon_btn back_btn' onClick={router.back}>
                                    <i className='ti ti-arrow-left' />
                                </Button>
                                <div className='my-12'>
								<span className={`listing_status_badge_holder ${clientDetails?.status && clientDetails?.status.toLowerCase()}`}>
                                    {clientDetails?.status}
                                </span> 
							</div>
                                <div className='flex flex-wrap items-center mt-10 mb-20'>
                                    <h2 className='main_page_heading'>{clientDetails?.company_name}</h2>
                                    <span className='extra_sub_heading'>
                                        <Button
                                            variant="contained"
                                            className='share_btn'
                                            //style={{ background: '#F2F4F7'}}
                                            onClick={handleEdit}
                                        >
                                            <i className='ti ti-pencil' />
                                        </Button>
                                    </span>
							    </div>
                                <div className='flexbox_holder justify-start'>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Email Address</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{clientDetails?.email_address || 'N/A'}</span>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Phone Number</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{clientDetails?.phone_number || 'N/A'}</span>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Domain Name</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{clientDetails?.subdomain || 'N/A'}</span>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Created Date</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{clientDetails?.created_date || 'N/A'}</span>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Updated Date</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{clientDetails?.updated_date || 'N/A'}</span>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Total Assets</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{clientDetails?.total_assets}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-wrap justify-between items-center mt-20 mb-40'>
                                    <Box className='cstm_tabs_holder mb-8'>
                                        <Tabs value={tabValue} onChange={handleChangeTabValue} aria-label="basic tabs example" className='tabs_main_holder'>
                                            <Tab className='each_tab_holder' label="User Information" {...a11yProps(0)} />
                                            <Tab className='each_tab_holder' label="Asset Information" {...a11yProps(2)} />
                                            <Tab className='each_tab_holder' label="Service Ticket" {...a11yProps(3)} />
                                            <Tab className='each_tab_holder' label="Contract Information" {...a11yProps(4)} />
                                        </Tabs>
                                    </Box>							
                                </div>

                                {tabValue === 0 &&
								    clientDetails && <UserInfo companyId={clientDetails?.company_id}/>
							    }

                                {tabValue === 1 &&
                                    clientDetails && <Assets companyId={clientDetails?.company_id}/>
                                }

                                {tabValue === 2  &&
                                    <ServiceTicketList companyId={clientDetails?.company_id}/>
                                }

                                {tabValue === 3 &&
                                    clientDetails && <Contract companyId={clientDetails?.company_id}/>
                                }
                            </div>
                        </div>
					</>
				}
				innerScroll
			/>
		</>
	);
}

export default ListViewDetails