import _ from '@lodash';
import TablePagination from '@fuse/components/general/TablePagination';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { withRouter } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListView from './listView';
import useRouter from '@fuse/hooks/useRouter';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function EndUsersAccountsTable(props) {
  const classes = useStyles();
	const router = useRouter();

	let endUsersAccountsMod;
	
	if(router.query.report_name){
		endUsersAccountsMod = props.endUsersAccounts.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage);
	}else{
		endUsersAccountsMod = props.endUsersAccounts;
	}

	function handleChangePage(event, value) {
		props.setPage(value);
		if(!router.query.report_name){
			props.fetchAssets(props.rowsPerPage, value, props.filters[props.currentFilter], props.currentSearch);
		}
	}

	function handleChangeRowsPerPage(rowsPerPage) {
		props.setPage(0)
		props.setRowsPerPage(rowsPerPage);
		if(!router.query.report_name){
			props.fetchAssets(rowsPerPage, 0, props.filters[props.currentFilter], props.currentSearch);
		}
	}

	if(props.loading){
		return (<FuseLoading/>)
	}

	// if (props.viewAssetPermission != 'all' && props.viewAssetPermission?.length == 0) {
	// 	return (
	// 		<div>
	// 			<Card>
	// 				<CardContent>You don't have permission to view any Archieved Assets at this time. Please contact your Systems Administrator for access.</CardContent>
	// 			</Card>
	// 		</div>
	// 	)
	// }
	

	if(endUsersAccountsMod.length <= 0){
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
				className="flex flex-1 items-center justify-center h-full"
			>
				<div style={{height: "200px"}}>
					<Typography variant="body2" component="p" color="textSecondary">
						There are currently no assets available to view. Please check back later.
					</Typography>
				</div>
			</motion.div>
		)
	}

	return (
		<div className="w-full flex flex-col">
			<SwitchView 
				selectedView={props.selectedView} 
				endUsersAccounts={endUsersAccountsMod} 
				fetchAssets={props.fetchAssets}
				viewAssetPermission = { props.viewAssetPermission }
				createAssetPermission = { props.createAssetPermission }
				updateAssetPermission = { props.updateAssetPermission }
				deleteAssetPermission = { props.deleteAssetPermission }
			/>

			{/* <div className = "p-10">
				<TablePagination
					className="flex-shrink-0 border-t-1"
					component="div"
					count={props.totalRecords}
					rowsPerPage={'all'}
					page={props.page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div> */}

		</div>
	);

}

function SwitchView({
	endUsersAccounts,
	selectedView,
	viewAssetPermission,
	createAssetPermission,
	updateAssetPermission,
	deleteAssetPermission,fetchAssets
}){

	if(selectedView == 0){
		return(
			<ListView 
				fetchAssets = {fetchAssets}
				endUsersAccounts={endUsersAccounts} 
				viewAssetPermission = { viewAssetPermission }
				createAssetPermission = { createAssetPermission }
				updateAssetPermission = { updateAssetPermission }
				deleteAssetPermission = { deleteAssetPermission }
			/>
		)
	}
}

export default withRouter(EndUsersAccountsTable)
