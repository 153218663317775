import React,{useState,useEffect} from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { useDebounce } from '@fuse/hooks';

export default function TextEditor({
    editorContent,
    onEditorContentChange,
    placeholderText,
    setAssetNoteText = null,
    disabled = false
}) {
    const [ loading, setLoading ] = useState(false)
    const [ content, setContent] = useState('')

    const handleEditorChange = useDebounce((content,editor) => {
        const htmlWithSpaces = content.replace(/&nbsp;|&ensp;|&emsp;|&#8203;|&#8239;|&#8287;|&#12288;|&#160;/g, ' ');
        htmlWithSpaces.replace(/<[^>]*>/g, '');
        if(content === '') onEditorContentChange('')
        else onEditorContentChange(content)
        if(setAssetNoteText){
            const htmlContent = editor.getContent();
            const textContent = new DOMParser().parseFromString(htmlContent, 'text/html').body.textContent;
            setAssetNoteText(textContent)
        }
	}, 150);

    useEffect(()=>{
        setContent(editorContent)
    },[])

    return (
        <Editor
            apiKey="rizevt0is9zmdrbesb6gkhvjtzmi8wpejsr6f8qw0hoh6uj4"
            initialValue={content}
            relative_urls= {true}
            disabled={disabled}
            inputProps={{
                readOnly: disabled
            }}
            onLoadContent={() => {
                setLoading(false);
            }}
            init={{
                height: 250,
                menubar: 'false',
                placeholder: placeholderText ?? 'Type here...',
                plugins: ['link' ,  'autolink ', 'quicklink',
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                ],
                branding: false,
                toolbar:
                'link'+
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',                        
                link_title: false,
                link_quicklink: true,
                default_link_target: '_blank',
                content_style: `  
                body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: ${disabled ? '#C6C6C6' : ''}}
                .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #98A2B3;font-family: "Inter", sans-serif;font-size: 12px;font-style: normal;font-weight: 400; }
                `,
            }}
            onEditorChange={handleEditorChange}
        />
    )
}
