import Page from './index';
import { authRoles } from 'app/auth';

export default {
settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
	},
	auth: authRoles.superAdmin,
	routes: [
		{
			path: '/overview',
			exact: true,
			component: Page.Layout
		},
		{
			path: '/client/detail/:id',
			exact: true,
			component: Page.Details
		},
	]
};